export const Provinces: Array<string> = [
    'Antwerpen',
    'Brussel',
    'Limburg',
    'Oost-Vlaanderen',
    'Vlaams-Brabant',
    'West-Vlaanderen',
]


export const Services = [
    'Dakisolatie (hellend / plat)',
    'Vochtbestrijding',
    'Ramen (beglazing/buitenschrijnwerk',
    'Groendak',
    'Warmtepomp',
    'Gevelisolatie: binnen/buiten/spouw',
    'Zonnepanelen',
    'Renovatie toevoegen',
    'Warmtepompboiler',
    'Asbest',
    'Ventilatie'
]

export const UserTypes = {
    HOUSE_OWNER: [3, 4],
    CONTRACTOR: [2, 5], // 5 means contact person () comes rom cntractor api, 2 means any user comes fm profile api
    IGEMO_USER: [1]
}

export const showWarningAfterDays = 10
export const maxFileSize = 1.024e+9; // bytes
