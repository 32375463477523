export const questionTypesMapping: any = {
    '7': 'radio',
    '1': 'text',
    '2': 'textarea',
    '3': 'checkbox',
    '4': 'boolean',
    '5': 'picture',
    '6': 'plan',
    '8': 'radio_other',
    '9': 'radio_other_notknow'
}

export const questionTypesMappingReverse: any = {
    radio: '7',
    text: '1',
    textarea: '2',
    picture: '5',
    checkbox: '3',
    boolean: '4',
    plan: '6',
    radio_other: '8',
    radio_other_notknow: '9'
}

// export const booleanMapping: any = {
//     true:
// }

export const questionKindMapping: any = {
    '1': 'Algemene informatie',
    '2': 'Specifieke informatie'
}

export const getFormFields:object = {
    hoofddak: [{
        key: 'h_1',
        title: 'Wat voor dak wil je laten isoleren?',
        placeholder: '',
        type: 'multiple',
        questionId: 8,
        options: [{
            label: 'hellend dak binnenzijde',
            value: '4',
        },
        {
            label: 'hellend dak buitenzijde',
            value: '3',
        },
        {
            label: 'plat dak',
            value: '5',
        },
        {
            label: 'zoldervloer',
            value: '6',
        }],
    }, {
        key: 'h_2',
        title: 'Wat is de geschatte oppervlakte van het hoofddak in m2',
        placeholder: '',
        type: 'input',
        questionId: 9
    }, {
        key: 'h_3',
        title: 'Projectomschrijving <br /> Beschrijf in het kort je project en ontvang specifiekere offertes',
        placeholder: '',
        type: 'textarea',
        questionId: 10
    }],

    bijdak: [{
        key: 'h_1',
        title: 'Wat voor dak wil je laten isoleren?',
        placeholder: '',
        type: 'multiple',
        questionId: 9,
        options: [{
            label: 'hellend dak binnenzijde',
            value: '7',
        },
        {
            label: 'hellend dak buitenzijde',
            value: '8',
        },
        {
            label: 'plat dak',
            value: '9',
        },
        {
            label: 'zoldervloer',
            value: '10',
        }],
    }, {
        key: 'h_2',
        title: 'Wat is de geschatte oppervlakte van het hoofddak in m2',
        placeholder: '',
        type: 'input',
        questionId: 12
    }, {
        key: 'h_3',
        title: 'Projectomschrijving <br /> Beschrijf in het kort je project en ontvang specifiekere offertes',
        placeholder: '',
        questionId: 13,
        type: 'textarea',
    }],

    ramen: [{
        key: 'h_1',
        title: 'Wat is de geschatte oppervlakte van het hoofddak in m2',
        questionId: 14,
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_2',
        questionId: 15,
        title: 'Hoeveel stuks / oppervlakte',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_3',
        questionId: 16,
        title: 'Type glas?',
        placeholder: '',
        type: 'multiple',
    }, {
        key: 'h_4',
        questionId: 15,
        title: 'Wat is het gewenst materiaal voor het buitenschrijnwerk?',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'Kunststof (PVC) raam plaatsen',
            value: '1',
        },
        {
            label: 'Houten raam plaatsen',
            value: '2',
        },
        {
            label: 'Aluminium raam plaatsen',
            value: '3',
        }],
    }, {
        key: 'h_5',
        title: 'Projectomschrijving <br /> Beschrijf in het kort je project en ontvang specifiekere offertes',
        placeholder: '',
        type: 'textarea',
    }],

    slaapkamer: [{
        key: 'h_1',
        title: 'Wat is de geschatte oppervlakte van het hoofddak in m2',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_2',
        title: 'Hoeveel stuks / oppervlakte',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_3',
        title: 'Type glas?',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_4',
        title: 'Wat is het gewenst materiaal voor het buitenschrijnwerk?',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'Kunststof (PVC) raam plaatsen',
            value: '1',
        },
        {
            label: 'Houten raam plaatsen',
            value: '2',
        },
        {
            label: 'Aluminium raam plaatsen',
            value: '3',
        }],
    }, {
        key: 'h_5',
        title: 'Projectomschrijving <br /> Beschrijf in het kort je project en ontvang specifiekere offertes',
        placeholder: '',
        type: 'textarea',
    }],

    voorgevel: [{
        key: 'h_1',
        title: 'Is je muur al eens geïsoleerd?',
        placeholder: '',
        type: 'input',
        defaultValue: 'No',
        active: false,
    }, {
        key: 'h_2',
        title: 'Wat is het gewenst materiaal voor het buitenschrijnwerk?',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'Bakstenen',
            value: '1',
        },
        {
            label: 'Beschilderd',
            value: '2',
        },
        {
            label: 'Bepleisterd',
            value: '3',
        }, {
            label: 'anders',
            value: '4',
        }],
    }, {
        key: 'h_3',
        title: 'Wat is de geschatte oppervlakte van de voorgevel in m2',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_4',
        title: 'Projectomschrijving <br /> Beschrijf in het kort je project en ontvang specifiekere offertes',
        placeholder: '',
        type: 'textarea',
    }],

    zijgevels: [{
        key: 'h_1',
        title: 'Is je muur al eens geïsoleerd?',
        placeholder: '',
        type: 'input',
        defaultValue: 'No',
        active: false,
    }, {
        key: 'h_2',
        title: 'Wat is het gewenst materiaal voor het buitenschrijnwerk?',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'Bakstenen',
            value: '1',
        },
        {
            label: 'Beschilderd',
            value: '2',
        },
        {
            label: 'Bepleisterd',
            value: '3',
        }, {
            label: 'anders',
            value: '4',
        }],
    }, {
        key: 'h_3',
        title: 'Wat is de geschatte oppervlakte van de voorgevel in m2',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_4',
        title: 'Projectomschrijving <br /> Beschrijf in het kort je project en ontvang specifiekere offertes',
        placeholder: '',
        type: 'textarea',
    }],

    vloerisolatie: [{
        key: 'h_1',
        title: 'Waar wil je de vloerisolatie laten aanbrengen?',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'Gelijkvloers en/of tussenverdieping',
            value: '1',
        },
        {
            label: 'In de kelder / kruipruimte',
            value: '2',
        },
        {
            label: 'Op de zoldervloer',
            value: '3',
        }],
    }, {
        key: 'd_1',
        title: 'Welk type vloerisolatie wil je laten uitvoeren?',
        placeholder: '',
        type: 'multiple_D',
        options: [{
            label: 'Welk type vloerisolatie wil je laten uitvoeren?',
            value: '1',
        },
        {
            label: 'chapewerken',
            value: '2',
        },
        {
            label: 'plaatsen nieuwe vloer incl. uitbraak huidige vloer',
            value: '3',
        }],

        depKey: 'h_1',
        depValue: '1',
    }, {
        key: 'd_2',
        title: 'Hoe hoog is de kelder / kruipruimte?',
        placeholder: '',
        type: 'multiple_D',
        options: [{
            label: '80 cm of hoger',
            value: '1',
        },
        {
            label: 'lager dan 80cm',
            value: '2',
        }],
        depKey: 'h_1',
        depValue: '2',
    }, {
        key: 'h_2',
        title: 'Geschatte oppervlakte van vloer voor isolatie in m2',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_4',
        title: 'Projectomschrijving <br /> Beschrijf in het kort je project en ontvang specifiekere offertes',
        placeholder: '',
        type: 'textarea',
    }],

    verwarming: [{
        key: 'h_1',
        title: 'Type warmtepomp',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_2',
        title: 'Meer keuzes mogelijk',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'Verwarming en/of koeling van woning/gebouw',
            value: '1',
        },
        {
            label: 'Verwarming van water (sanitair)',
            value: '2',
        },
        {
            label: 'Verwarming van zwembad',
            value: '3',
        },
        {
            label: 'Anders, namelijk',
            value: '4',
        }],
    }, {
        key: 'h_3',
        title: 'Huidige energie',
        placeholder: '',
        type: 'heading',
    }, {
        key: 'h_4',
        title: 'Verwarming:',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_5',
        title: 'Warm water:',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_6',
        title: 'Zonnepanelen:',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_7',
        title: 'Verwarmingselementen:',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_8',
        title: 'Model van uw huidige CV-ketel?',
        placeholder: '',
        type: 'multiple',
        options: [{
                label: 'Combiketel',
                value: '1',
            },
            {
                label: 'CVketel + boiler',
                value: '2',
            },
            {
                label: 'CVketel + geiser',
                value: '3',
            },
            {
                label: 'Weet ik niet',
                value: '4',
            }],
    }, {
        key: 'h_9',
        title: 'Plaats van uw huidige CV-ketel?',
        placeholder: '',
        type: 'multiple',
        options: [{
                label: 'Zolder',
                value: '1',
            },
            {
                label: '(Bij)keuken',
                value: '2',
            },
            {
                label: '1e verdieping',
                value: '3',
            },
            {
                label: 'Gangkast',
                value: '4',
            },
            {
                label: 'Kelder',
                value: '5',
            },
            {
                label: 'Anders, namelijk',
                value: '6',
            },
            {
                label: 'Weet ik niet',
                value: '7',
            }],
    }, {
        key: 'h_10',
        title: 'Merk van uw huidige CV-ketel?',
        placeholder: '',
        type: 'multiple',
        options: [{
                label: 'Agpo',
                value: '1',
            },
            {
                label: 'Atag',
                value: '2',
            },
            {
                label: 'AWB',
                value: '3',
            },
            {
                label: 'Bosch',
                value: '4',
            },
            {
                label: 'Ferroli',
                value: '5',
            },
            {
                label: 'Intergas',
                value: '6',
            },
            {
                label: 'Nefit',
                value: '7',
            },
            {
                label: 'Remeha',
                value: '8',
            },
            {
                label: 'Vaillant',
                value: '9',
            },
            {
                label: 'Anders, namelijk',
                value: '10',
            },
            {
                label: 'Weet ik niet',
                value: '11',
            }],
    }, {
        key: 'h_11',
        title: 'Afstand CV-ketel tot leidingen',
        placeholder: '',
        type: 'multiple',
        options: [{
                label: 'Minder dan 1 meter',
                value: '1',
            },
            {
                label: 'Meer dan 1 meter',
                value: '2',
            },
            {
                label: 'Ik kan geen schatting maken',
                value: '3',
            }],
    }, {
        key: 'h_12',
        title: 'Is er vloerverwarming aanwezig',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_13',
        title: 'Thermostaat',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'Ja, ik wil een nieuwe thermostaat',
            value: '1',
        },
            {
                label: 'Nee, ik wil geen nieuwe thermostaat',
                value: '2',
            },
            {
                label: 'Weet ik niet',
                value: '3',
            }],
    }, {
        key: 'h_8',
        title: 'Projectomschrijving <br /> Beschrijf in het kort je project en ontvang specifiekere offertes',
        placeholder: '',
        type: 'textarea',
    }],

    warmwater: [{
        key: 'h_8',
        title: 'Projectomschrijving <br /> Beschrijf in het kort je project en ontvang specifiekere offertes',
        placeholder: '',
        type: 'textarea',
    }],

    ventilatie: [{
        key: 'h_1',
        title: 'In welk centraal ventilatiesysteem heb je interesse?',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_2',
        title: 'Naar welke dienst ben je op zoek?',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'centraal ventilatiesysteem (nieuwbouw of complete renovatie)',
            value: '1',
        },
            {
                label: 'decentraal ventilatiesysteem (afgewerkte ruimtes, tegen vocht en vuile lucht)',
                value: '2',
            },
            {
                label: 'afzuigventilator plaatsen (badkamer, toilet)',
                value: '3',
            }],
    },  {
        key: 'h_3',
        title: 'Hoeveel ruimtes moeten geventileerd worden?',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: '1 ruimte',
            value: '1',
        },
            {
                label: '2 tot 5 ruimtes',
                value: '2',
            },
            {
                label: 'meer dan 5 ruimtes',
                value: '3',
            }],
    },  {
        key: 'h_4',
        title: 'Welk type ventilator overweegt u te plaatsen?',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'opbouwventilator',
            value: '1',
        },
            {
                label: 'buisventilator',
                value: '2',
            }],
    },  {
        key: 'h54',
        title: 'Welke ruimte(s) moet(en) geventileerd worden?',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'badkamer',
            value: '1',
        },
            {
                label: 'toilet',
                value: '2',
            },
            {
                label: 'keuken',
                value: '3',
            },
            {
                label: 'toilet',
                value: '4',
            },
            {
                label: 'kelder',
                value: '5',
            },
            {
                label: 'andere',
                value: '6',
            }],
    }, {
        key: 'h_1',
        title: 'Projectomschrijving <br /> Beschrijf in het kort je project en ontvang specifiekere offertes',
        placeholder: '',
        type: 'textarea',
    }],

    zonnepanelen: [{
        key: 'h_1',
        title: 'Soort dak',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'Schuin dak',
            value: '1',
        },
            {
                label: 'Plat dak',
                value: '2',
            }],
    }, {
        key: 'h_2',
        title: 'Projectomschrijving <br /> Beschrijf in het kort je project en ontvang specifiekere offertes',
        placeholder: '',
        type: 'textarea',
    }],

    asbest: [{
        key: 'h_1',
        title: 'Selecteer uw opdracht',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'Asbest verwijderen',
            value: '1',
        },
            {
                label: 'Asbest inventariseren (let op: betreft geen asbestattest)',
                value: '2',
            }],
    }, {
        key: 'h_2',
        title: 'Soort aanvraag',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'Particulier',
            value: '1',
        },
            {
                label: 'Zakelijk',
                value: '2',
            }],
    }, {
        key: 'h_3',
        title: 'Type object',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'Woning',
            value: '1',
        },
            {
                label: 'Schuur / Garage',
                value: '2',
            },
            {
                label: 'Bedrijfshal',
                value: '3',
            },
            {
                label: 'Agrarisch',
                value: '4',
            },
            {
                label: 'Fabriek / Industrie',
                value: '5',
            },
            {
                label: 'Kantoor',
                value: '6',
            },
            {
                label: 'Anders, namelijk',
                value: '7',
            }],
    }, {
        key: 'h_4',
        title: 'Plaats asbest (Meer keuzes mogelijk)',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'Dakbedekking',
            value: '1',
        },
            {
                label: 'Plafond',
                value: '2',
            },
            {
                label: 'Vloerbedekking',
                value: '3',
            },
            {
                label: 'Gevelbekleding',
                value: '4',
            },
            {
                label: 'In/bij CV-installatie',
                value: '5',
            },
            {
                label: 'Schoorsteen',
                value: '6',
            },
            {
                label: 'Muren',
                value: '7',
            },
            {
                label: 'Riolering / Afvoerbuizen',
                value: '8',
            },
            {
                label: 'Isolatie',
                value: '9',
            },
            {
                label: 'Brandwering',
                value: '10',
            },
            {
                label: 'Overig, namelijk',
                value: '11',
            }],
    }, {
        key: 'h_8',
        title: 'Projectomschrijving <br /> Beschrijf in het kort je project en ontvang specifiekere offertes',
        placeholder: '',
        type: 'textarea',
    }],

    vocht: [{
        key: 'h_1',
        title: 'Locatie vochtprobleem',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_2',
        title: 'Soort vochtprobleem',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'Waterlek dak',
            value: '1',
        },
            {
                label: 'Waterlek leiding',
                value: '2',
            },
            {
                label: 'Opstijgend vocht',
                value: '3',
            },
            {
                label: 'Condens',
                value: '4',
            },
            {
                label: 'Weet ik niet',
                value: '5',
            }],
    }, {
        key: 'h_3',
        title: 'Aan welke soort oplossing denkt u?',
        placeholder: '',
        type: 'multiple',
        options: [{
            label: 'Injecteren',
            value: '1',
        },
            {
                label: 'Impregneren muren',
                value: '2',
            },
            {
                label: 'Kelderafdichting',
                value: '3',
            },
            {
                label: 'Repareren scheuren muren of vloeren',
                value: '4',
            },
            {
                label: 'Weet ik niet',
                value: '5',
            }],
    }, {
        key: 'h_8',
        title: 'Projectomschrijving <br /> Beschrijf in het kort je project en ontvang specifiekere offertes',
        placeholder: '',
        type: 'textarea',
    }],

    algemene: [{
        key: 'h_1',
        title: 'Afmetingen (indien van toepassing)',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_2',
        title: 'Gewenste uitvoeringsdatum',
        placeholder: '',
        type: 'input',
    },  {
        key: 'h_3',
        title: 'Wens je ecologische materialen te gebruiken?',
        placeholder: '',
        type: 'checkbox',
        options: [{
            label: 'Ja',
            value: '1',
        },
            {
                label: 'Nee',
                value: '2',
            }],
    }, {
        key: 'h_4',
        title: 'Toegankelijkheid van je huis?',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_5',
        title: 'Meer informatie (optioneel)',
        placeholder: '',
        type: 'input',
    }, {
        key: 'h_6',
        title: 'Foto\'s',
        type: 'uploaded_file',
        filename: 'Photo.JPG',
    }, {
        key: 'h_7',
        title: 'Plannen',
        filename: 'Plannen.PDF',
        type: 'uploaded_file',
    }],
};
