import React, {FC, useState} from 'react'
import {
    HeadingsInterface,
    HouseOwnerListingTypeColors,
    UserTypes,
} from '../../models/applicationState';
import { Toggler } from '../../shared/hoc/Toggler';
import Table from '../../shared/Table';
import { TableHeaders } from '../../shared/Table/common';
import ContractorRow from './ContractorRow';
import { formatAddress } from '../../utils/helpers';

interface HomeOwnerListProps {
    tableTitle: String,
    handleClickHandler?: any,
    handleMenuClick?: any,
    toggleWarningPopup?: any,
    tableSubTitle?: String,
    data: any,
    initialStatus?: Boolean,
    listingType: HouseOwnerListingTypeColors,
    userTypes: UserTypes,
    contractorStatus?: Boolean,
    onContractorStatusChange?: (status: Boolean) => void,
    handleToggleProjectDetails?: (cp: Boolean) => void,
    reload?: any,
    idsWithRecords?: any,
    loading?: any,
    handleClickDelete?: any
}

const ContractorList: FC<HomeOwnerListProps> = ({
    handleClickHandler,
    tableTitle,
    tableSubTitle,
    data,
    initialStatus = false,
    listingType,
    userTypes,
    contractorStatus = false,
    onContractorStatusChange,
    handleMenuClick,
    handleToggleProjectDetails,
    toggleWarningPopup,
    reload,
    idsWithRecords, loading, handleClickDelete,
}) => {
    const [showDetails, toggleShowDetails] = useState(false);

    const handleDetailsClick = (item: object) => {
        handleMenuClick(item);
    };

    const openWarningPopup = () => {
        toggleWarningPopup(true);
    };

    const headings: HeadingsInterface = {
        DATUM: "DATUM",
        BEGELEIDING: "BEGELEIDING",
        GROEPSAANKOOP: "GROEPSAANKOOP",
        STATUS: "STATUS",
        DOCUMENTEN: "DOCUMENTEN",
        ...([UserTypes.requetQuote].includes(userTypes) && ({
            ACTIES: "ACTIES",
        })),
    }

    const handleToggleStatus = (cp: any) => {
        toggleShowDetails(!showDetails);
        if (handleToggleProjectDetails) {
            handleToggleProjectDetails(cp);
        }
    };

    return (
        <Table
            mainBurgerClickHandler={handleClickHandler}
            showDetails={showDetails}
            title={tableTitle}
            subtitle={tableSubTitle}
            handleToggleStatus={handleToggleStatus}
            initialStatus={initialStatus}
            listingType={listingType}
            contractorStatus={contractorStatus}
            showContractorStatus={true}
            onContractorStatusChange={onContractorStatusChange}
            handleClickDelete={handleClickDelete}
        >
            <div className='relative w-[full] lg:overflow-visible overflow-x-auto'>
                {data?.simulation.length ? (
                    <>
                        {data?.simulation?.map((simulation: any) => {

                            const offerRequest = simulation?.cpOfferRequest
                            const cpOfferRequestRenovations = offerRequest?.cpOfferRequestRenovations

                            return (
                                <table className='ml-8 mb-8 w-full'>
                                    <Toggler
                                        initialStatus={true}
                                        render={({ toggleStatus, status }) => (
                                            <>
                                                <thead className='mr-4'>
                                                <tr className='w-full'>
                                                    <TableHeaders
                                                        title={formatAddress(simulation?.city, simulation?.street)}
                                                        // headings={idx > 0 ? [] : Object.values(headings)}
                                                        headings={Object.values(headings)}
                                                        showBody={status}
                                                        toggleShowBody={toggleStatus}
                                                    />
                                                </tr>
                                                </thead>
                                                {status ? (
                                                    <tbody className='shadow-md bg-white w-full'>
                                                    {userTypes === UserTypes.contractor && (
                                                        cpOfferRequestRenovations?.map((offerRequestRenovation: any) => (
                                                            offerRequestRenovation.cpOfferRequestRenovationContractors?.map((offerRequestRenovationContractor: any, idx: number) => (
                                                                <ContractorRow
                                                                    key={idx}
                                                                    simulation={simulation}
                                                                    toggleWarningPopup={openWarningPopup}
                                                                    handleDetailsClick={() => handleDetailsClick(offerRequestRenovationContractor)}
                                                                    offerRequestRenovationContractor={offerRequestRenovationContractor}
                                                                    renovation={offerRequestRenovation?.renovation || {}}
                                                                    reload={reload}
                                                                    offerRequestSendAt={offerRequest?.sendAt}
                                                                    offerRequestId={offerRequest.id}
                                                                />
                                                            ))
                                                        ))
                                                    )}
                                                    </tbody>
                                                ) : null}
                                            </>
                                        )}
                                    />
                                </table>
                            )
                        })}
                    </>
                ) : (
                    (idsWithRecords?.includes(data?.id) && !loading) ? (
                        <div className="p-8">
                            Geen data.
                        </div>
                    ) : null
                )}
            </div>
        </Table>
    )
}

export default ContractorList
