import Layout from "../../components/layout";
import { useNavigate } from "react-router-dom";

// asset imports here
import { IoArrowBackCircleOutline } from "react-icons/io5";

type GoodOfferProps = {

}

const GoodOffer = ({}: GoodOfferProps) => {
    const navigate = useNavigate();

    return(
        <Layout>
            <div className='px-4 py-4 md:px-8 lg:px-16 xl:px-32 md:py-8'>
                <div className = "w-full flex items-center mb-[8px]">
                    <div className = "flex items-center cursor-pointer" onClick = {() => navigate(-1)}>
                        <IoArrowBackCircleOutline size = "24px" color = "#4F4C4C" className = "mr-[12px]" />
                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]">terug</p>
                    </div>
                </div>
                <div className = "w-full flex flex-col bg-white py-[32px]">
                    <div className = "w-full flex px-[95px]">
                        <p className = "font-sans font-semibold text-[30px] text-black">What is a good offer ?</p>
                    </div>
                    <div className = "w-full h-[1px] bg-[#F6F6F6] mt-[22px] mb-[16px]" />
                    <div className = "w-full flex flex-col px-[95px]">
                        <div className = "w-full flex flex-col">
                            <p className = "font-sans font-semibold text-[18px] text-black">Titre - H2</p>
                            <p className = "font-sans font-regular text-[14px] text-[#4F4C4C] mt-[16px]">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                        </div>
                        <div className = "w-full flex flex-col mt-[32px]">
                            <p className = "font-sans font-semibold text-[18px] text-black">Titre - H2</p>
                            <p className = "font-sans font-regular text-[16px] text-black mt-[16px]">Titre - H3</p>
                            <p className = "font-sans font-regular text-[14px] text-[#4F4C4C] mt-[16px]">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                            <p className = "font-sans font-regular text-[16px] text-black mt-[16px]">Titre - H3</p>
                            <p className = "font-sans font-regular text-[14px] text-[#4F4C4C] mt-[16px]">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                            <p className = "font-sans font-regular text-[16px] text-black mt-[16px]">Titre - H3</p>
                            <p className = "font-sans font-regular text-[14px] text-[#4F4C4C] mt-[16px]">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default GoodOffer;