import React, { FC, useEffect } from "react"

import { TableTitleInterface } from "../../models/applicationState"
import { Toggler } from "../hoc/Toggler";
import MainTitle from "./MainTitle";
import { Headings } from "./common";


export const Table: FC<TableTitleInterface> = (
    {
        mainBurgerClickHandler = () => { },
        children = null,
        title, subtitle,
        showIcon = true,
        initialStatus = true,
        listingType,
        headings,
        tableClasses,
        tableTitleClasses,
        wrapInTable = false,
        contractorStatus = false,
        showContractorStatus = false,
        onContractorStatusChange,
        hideEllipsis = false,
        handleToggleStatus,
        showDetails,
        handleClickDelete
    }) => {

    return (
        <Toggler
            initialStatus={initialStatus}
            render={({ status, toggleStatus }) => {
                const newS = showDetails ? showDetails : status;
                return (
                    <>
                        {wrapInTable ? (
                            <div className="relative w-full lg:overflow-visible overflow-x-auto">
                                <table className={`w-full ${tableClasses ?? ""}`}>
                                    <thead>
                                    <tr className="w-full">
                                        <th>
                                            <MainTitle
                                                title={title}
                                                listingType={listingType}
                                                onClick={mainBurgerClickHandler}
                                                status={newS}
                                                toggleStatus={handleToggleStatus ? handleToggleStatus : toggleStatus}
                                                subtitle={showDetails ? '' : subtitle}
                                                showIcon={showIcon}
                                                tableTitleClasses={tableTitleClasses}
                                                contractorStatus={contractorStatus}
                                                showContractorStatus={showContractorStatus}
                                                onContractorStatusChange={onContractorStatusChange}
                                                hideEllipsis={hideEllipsis}
                                                handleClickDelete={handleClickDelete}
                                            />{}
                                        </th>
                                        {(newS && showIcon) ? (<Headings headings={headings || []}/>) : null}
                                    </tr>
                                    </thead>
                                    {(newS && showIcon) ? (
                                        <tbody className="w-full">
                                        {children}
                                        </tbody>
                                    ) : null}
                                </table>
                            </div>
                        ) : (
                            <MainTitle
                                title={title}
                                listingType={listingType}
                                onClick={mainBurgerClickHandler}
                                status={newS}
                                toggleStatus={handleToggleStatus ? handleToggleStatus : toggleStatus}
                                subtitle={status ? '' : subtitle}
                                showIcon={showIcon}
                                tableTitleClasses={tableTitleClasses}
                                contractorStatus={contractorStatus}
                                showContractorStatus={showContractorStatus}
                                onContractorStatusChange={onContractorStatusChange}
                                hideEllipsis={hideEllipsis}
                                handleClickDelete={handleClickDelete}

                            />
                        )}
                        {(newS && showIcon && !wrapInTable) ? (
                            children
                        ) : null}
                    </>
                );
            }}
        />
    )
}


export default Table
