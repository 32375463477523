import HttpService from "../constants/HttpService";

class SetupService extends HttpService {
    constructor() {
        super('domain-config');
    }

    getDomainDetails = async (id: string) => {
        const { data } = await this.client.get(`${this.endPoint}/domain/${id}`)
        return data
    }

    createNewDomain = async (payload: any, others: any) => {
        const { data } = await this.client.post(`${this.endPoint}`, payload, {...others})
        return data
    }

    updateDomain = async (id: string, payload: any, others: any) => {
        const { data } = await this.client.put(`${this.endPoint}/${id}`, payload, {...others})
        return data
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SetupService();
