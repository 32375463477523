import axios from "axios";
import { useState, useEffect } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import { contractorData } from "../../../constants/data";
import { GetAPIService } from "../../../context/services";
import { GoReply } from "react-icons/go";

type ContractorReviewProfileType = {
    userId?: any
}

type ReplyBoxProps = {
    reply: ReplyType,
    triggerRefreshState?: () => void
}

type ReplyType = {
    comment: string;
    createdAt: string;
    id: string;
    user: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
    };
};

const parseSQLDate = (date: string) => {
    // parse into day month year format
    const parsedDate = new Date(date);
    const day = parsedDate.getDate();
    const month = parsedDate.getMonth();
    const year = parsedDate.getFullYear();
    return `${day} ${getLiteralMonth(month)} ${year}`;
}

const getLiteralMonth = (month: number) => {
    const months = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];
    return months[month];
}

const ReplyBox = ({ reply, triggerRefreshState = () => {} }: ReplyBoxProps) => {
    const [isFieldEditable, setIsFieldEditable] = useState(false);
    const [replyState, setReplyState] = useState(reply.comment);

    const updateReply = () => {
        const auth = JSON.parse(localStorage.getItem("auth") || "{}");
        if(auth.token) {
            if(replyState) {
                axios.put(process.env.REACT_APP_BACKEND_URL + `ratings/reply/${reply.id}`, {
                    comment: replyState
                }, {
                    headers: {
                        Authorization: `Bearer ${auth.token}`
                    }
                }).then((response) => {
                    triggerRefreshState();
                    setIsFieldEditable(false)
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    }

    const cancelUpdate = () => {
        setIsFieldEditable(false);
        setReplyState(reply.comment);
    }

    return(
        <div className = "w-full flex flex-col bg-[#FAFAFA] rounded-[4px] box-border py-[12px] mt-[8px]">
            <div className = "flex w-full justify-between items-center">
                <div className = "flex items-center">
                    <p className = "font-jakarta font-bold text-[16px] text-[#4F4C4C]">{reply?.user?.firstName} {reply?.user?.lastName}</p>
                </div>
                <div className = "flex items-center">
                    <p className = "font-jakarta font-regular text-[12px] text-[#4F4C4C]">{parseSQLDate(reply.createdAt)}</p>
                </div>
            </div>
            <div className = "w-full flex justify-between items-center mt-[16px]">
                {isFieldEditable ? (
                    <>
                        <div className = "h-[44px] w-full relative mr-[16px]">
                            <div className = "w-full h-full absolute top-0 left-0 opacity-[0.1] primary-border border-[1px]" />
                            <input className = "w-full h-full box-border p-[12px] font-sans font-regular text-[14px] text-[#5F5F5F] bg-transparent relative outline-none" placeholder = "Schrijf hier een reactie" value = {replyState} onChange = {(event) => setReplyState(event.target.value)} />
                        </div>
                    </>
                ): (
                    <div className = "flex flex-col">
                        <p className = "font-semibold font-sans text-[14px] text-[#4F4C4C]/[0.22]">ANTWOORD</p>
                        <p className = "font-sas font-reuglar text-[14px] text-[#4F4C4C] mt-[2px]">{reply?.comment}</p>
                    </div>
                )}
                <div className = "flex">
                    {isFieldEditable ? (
                        <div className = "flex">
                            <button className = "h-[30px] px-[10px] py-[5px] rounded-[5px] bg-[#FB797B] font-sans font-semibold text-[14px] text-white cursor-pointer mr-[16px]" onClick = {cancelUpdate}>Annuleren</button>
                            <button className = "h-[30px] px-[10px] py-[5px] rounded-[5px] primary-main font-sans font-semibold text-[14px] text-white cursor-pointer" onClick = {updateReply}>Bijwerken</button>
                        </div>
                    ) : (
                        <button type = "button" role = "button" className = "font-sans font-bold text-[14px] underline primary-text cursor-pointer" onClick = {() => setIsFieldEditable(!isFieldEditable)}>bewerk</button>
                    )}
                    
                </div>
            </div>
        </div>
    )
}

const ContractorReviewProfile = ({ userId }: ContractorReviewProfileType) => {
    const [ reviewList, setReviewList ] = useState<any[]>([]);
    const [ tooltipHover, setTooltipHover ] = useState(false);
    const [ contractorData, setContractorData ] = useState<any>(null);
    const [ replyState, setReplyState ] = useState<string>("");
    const [ errorMessageState, setErrorMessageState ] = useState<string>("");
    const [ refreshState, setRefreshState ] = useState<boolean>(false);

    useEffect(() => {
        if(contractorData?.id) {
            axios.get(process.env.REACT_APP_BACKEND_URL + `ratings/contractor/${contractorData?.id}`).then(response => {
                setReviewList(response.data.data);
            }).catch(error => console.log(error))
        }
    }, [contractorData])

    useEffect(()=> {
        if (userId) {
            GetAPIService(`contractor/${userId}`).then((res: any) => {
                if (res?.data) {
                    setContractorData(res.data);
                }
            }).catch((err: any) => {
                console.log(err)
            })
        }
    }, [userId, refreshState])

    const getAvgRating = (review: any) => {
        const { price, communication, planning, recommended, aftercare } = review;
        if(aftercare && aftercare > 0) {
            const total = price + communication + planning + recommended + aftercare;
            // return average up to one decimal point
            return (total / 5).toFixed(1);
        }
        else {
            const total = price + communication + planning + recommended;
            // return average up to one decimal point
            return (total / 4).toFixed(1);
        }
       
    }

    const getContractorName = (review: any) => {
        if(review?.user) {
            const userData = review?.user;
            if(userData?.firstName) {
                return `${userData.firstName} ${userData.lastName[0]}.`;
            }
            else {
                return "";
            }
        }
        else return "";
    }

    const submitReply = (review: any) => {
        const auth = JSON.parse(localStorage.getItem("auth") || "{}");
        if(auth?.token ) {
            if(replyState) {
                axios.post(process.env.REACT_APP_BACKEND_URL +`ratings/${review?.id}/reply`, {
                    comment: replyState
                }, {
                    headers: {
                        Authorization: `Bearer ${auth.token}`
                    }
                }).then(response => {
                    setRefreshState(!refreshState);
                    setReplyState("");
                }).catch(error => console.log(error))
            }
            else {
                setErrorMessageState("Antwoord kan niet leeg zijn, vul het antwoordveld in.");
            }
        }
        else {
            setErrorMessageState("Token is niet geldig, log in en probeer het opnieuw.");
        }
    }

    const triggerRefreshState = () => {
        setRefreshState(!refreshState);
    }

    return (
        <div className = "w-full h-full flex justify-center items-center z-50">
            <div className = "w-full bg-white flex flex-col box-border items-center relative">
                <div className = "w-full flex justify-center items-center px-[20px] mt-[20px]">
                    <div className = "flex items-center">
                        <p className = "font-semibold font-sans text-[30px] text-black">Beoordelingen</p>
                        <div className = "flex relative justify-center z-[2]" onMouseOver = {() => setTooltipHover(true)} onMouseLeave = {() => setTooltipHover(false)}>
                            <IoMdInformationCircleOutline size = "24px" className = "ml-[8px] cursor-pointer primary-text" />
                            <div className = "flex w-[400px] h-[150px] absolute top-[100%] primary-main rounded-[4px] shadow-md px-[16px] py-[10px] duration-[300ms] origin-top" style = {{transform: tooltipHover ? "scaleY(1)" : "scaleY(0)", opacity: tooltipHover ? 1 : 0}}>
                                <p className = "font-sans font-regular text-[14px] text-white">Zodra je het werk hebt uitgevoerd en de factuur is betaald, kan de klant jou een beoordeling geven. Met deze beoordeling help je potentiële klanten door jouw werkkwaliteit en service inzichtelijk te maken op basis van eerdere ervaringen.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = "w-full flex justify-center items-center mt-[12px]">
                    <div className = "flex items-center gap-[4px]">
                        <div className = "flex relative">
                            <FaRegStar size = "20px" className = "primary-text" />
                            <FaStar size = "20px" className = "absolute top-0 left-0 primary-text" style = {{display: contractorData?.avgRating >= 1 ? "flex" : "none"}} />
                        </div>
                        <div className = "flex relative">
                            <FaRegStar size = "20px" className = "primary-text" />
                            <FaStar size = "20px" className = "absolute top-0 left-0 primary-text" style = {{display: contractorData?.avgRating >= 2 ? "flex" : "none"}} />
                        </div>
                        <div className = "flex relative">
                            <FaRegStar size = "20px" className = "primary-text" />
                            <FaStar size = "20px" className = "absolute top-0 left-0 primary-text" style = {{display: contractorData?.avgRating >= 3 ? "flex" : "none"}} />
                        </div>
                        <div className = "flex relative">
                            <FaRegStar size = "20px" className = "primary-text" />
                            <FaStar size = "20px" className = "absolute top-0 left-0 primary-text" style = {{display: contractorData?.avgRating >= 4 ? "flex" : "none"}} />
                        </div>
                        <div className = "flex relative">
                            <FaRegStar size = "20px" className = "primary-text" />
                            <FaStar size = "20px" className = "absolute top-0 left-0 primary-text" style = {{display: contractorData?.avgRating >= 5 ? "flex" : "none"}} />
                        </div>
                    </div>
                    <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[16px] mr-[10px]">{contractorData?.avgRating}</p>
                    <p className = "font-sans font-regular text-[14px] text-[#4F4C4C] ml-[16px] mr-[10px]">{reviewList?.length > 0 ? reviewList?.length : 0} review(s)</p>
                </div>
                <div className = "w-full h-[1px] bg-[#E5E5E5] mt-[15px] mb-[15px]" />
                <div className = "flex w-full flex-col box-border px-[32px] py-[15px] gap-y-[10px]">
                    {/* review card */}
                    {reviewList && reviewList.length > 0 && reviewList.map((review, index) => (
                        <>
                            <div className = "w-full flex flex-col bg-[#FAFAFA] rounded-[4px] box-border px-[32px] py-[12px]" key = {index}>
                                <div className = "flex w-full justify-between items-center">
                                    <p className = "font-jakarta font-regular text-[16px] text-[#4F4C4C]"><strong>{getContractorName(review)}</strong> uit <strong>{review?.city?.de}</strong></p>
                                    <p className = "font-jakarta font-regular text-[12px] text-[#4F4C4C]">{parseSQLDate(review.createdAt)}</p>
                                </div>
                                <div className = "flex w-full mt-[16px]">
                                    <FaStar size = "20px" className = "primary-text" />
                                    <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{getAvgRating(review)}</p>
                                </div>
                                <div className = "w-full flex mt-[24px]">
                                    <div className = "flex flex-col mr-[32px]">
                                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">PRIJS</p>
                                        <div className = "flex items-center">
                                            <FaStar size = "20px" className = "primary-text" />
                                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{review?.price}</p>
                                        </div>
                                    </div>
                                    <div className = "flex flex-col mr-[32px]">
                                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">COMMUNICATIE</p>
                                        <div className = "flex items-center">
                                            <FaStar size = "20px" className = "primary-text" />
                                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{review.communication}</p>
                                        </div>
                                    </div>
                                    <div className = "flex flex-col mr-[32px]">
                                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">PLANNING/TIMING</p>
                                        <div className = "flex items-center">
                                            <FaStar size = "20px" className = "primary-text" />
                                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{review?.planning}</p>
                                        </div>
                                    </div>
                                    <div className = "flex flex-col mr-[32px]">
                                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">IK RAAD DEZE AANNEMER AAN</p>
                                        <div className = "flex items-center">
                                            <FaStar size = "20px" className = "primary-text" />
                                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{review?.recommended}</p>
                                        </div>
                                    </div>
                                    <div className = "flex flex-col">
                                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">NAZORG (OPTIONEEL)</p>
                                        <div className = "flex items-center">
                                            <FaStar size = "20px" className = "primary-text" />
                                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{review?.aftercare}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className = "w-full flex flex-col mt-[26px]">
                                    <p className = "font-semibold font-sans text-[14px] text-[#4F4C4C]/[0.22]">EXTRA (OPTIONEEL)</p>
                                    <p className = "font-sas font-reuglar text-[14px] text-[#4F4C4C] mt-[8px]">{review?.comment}</p>
                                </div>
                            </div>
                            <div className = "w-full flex">
                                <div className = "h-[64px] w-[64px] flex justify-center items-center">
                                    <GoReply size = "32px" className = "rotate-[180deg]" color = "#4F4C4C" />
                                </div>
                                <div className = "w-full flex flex-col bg-[#FAFAFA] rounded-[4px] box-border px-[32px] py-[12px]">
                                    {review?.threads && review?.threads.length > 0 && (
                                        <>
                                            <p className = "font-sans font-semibold text-[14px] primary-text mb-[5px]">ANTWOORDEN</p>
                                            {review?.threads.map((thread: any, index: number) => (
                                                <>
                                                    {thread?.replies && thread?.replies?.length > 0 && thread?.replies.map((reply: any, index: number) => (
                                                        <ReplyBox reply = {reply} triggerRefreshState = {triggerRefreshState} />
                                                    ))}
                                                </>
                                            ))}
                                        </>
                                    )}
                                    {/* contractor should only be able to reply a single message  */}
                                    {review?.threads?.length < 1 &&(
                                        <>
                                            <p className = "font-sans font-semibold text-[14px] primary-text mb-[5px]">BEANTWOORDEN</p>
                                            <div className = "h-[44px] w-full relative">
                                                <div className = "w-full h-full absolute top-0 left-0 opacity-[0.1] primary-border border-[1px]" />
                                                <input className = "w-full h-full box-border p-[12px] font-sans font-regular text-[14px] text-[#5F5F5F] bg-transparent relative outline-none" placeholder = "Schrijf hier een reactie" value = {replyState} onChange = {(event) => setReplyState(event.target.value)} />
                                            </div>
                                            <div className = "w-full flex justify-end">
                                                <button className = "h-[30px] px-[10px] primary-main secondary-text font-sans font-semibold text-[14px] rounded-[5px] cursor-pointer mt-[12px]" onClick = {() => submitReply(review)}>Sturen</button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ContractorReviewProfile;
