import { isEmpty } from "lodash";
import React, { FC, useEffect, useState } from "react";
import TreeView from "react-accessible-treeview";

import CheckBoxIcon from "../../form/checkbox";
import {add} from "lodash-es";

interface cardProps {
    formStep: any
    addedIds: any
    rawServices: any
    setAddedIds: any
    servicesList: any
    contractorDetails: any
    notAppicableIds: any
    setNotApplicableIds: any
}

const OfferCompareCard: FC<cardProps> = ({
    formStep,
    addedIds,
    rawServices,
    setAddedIds,
    servicesList,
    contractorDetails,
    notAppicableIds,
    setNotApplicableIds,
}) => {
    const serviceData = rawServices && rawServices.find((f: any) => f.id === servicesList[0].id);

    const [expandedIds, setExpandedIds] = useState<any>([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [noServiceData, setNoServiceData] = useState(serviceData && serviceData?.notApplicable === 1);

    useEffect(() => {
        if (servicesList.length) {
            const isNotApp = notAppicableIds.findIndex((f: any) => f.id === servicesList[0].id);
            const isNotApi = contractorDetails.findIndex((f: any) => f.serviceId === servicesList[0].id);
            const noShow = isNotApp > -1 || isNotApi > -1;

            handleSetExpandedIds();
            if (!noShow) {
                seeIfServiceIsChecked();
            }
            setNoServiceData(noShow);
        }

    }, [formStep]);

    const handleSetExpandedIds = () => {
        if (!isEmpty(servicesList)) {
            const list: any = [];
            servicesList?.map((s: any) => {
                if (s?.children?.length) {
                    list.push(s?.id);
                }
            });
            setExpandedIds([...expandedIds, ...list]);
        }
    };

    const seeIfServiceIsChecked = (isReturn?: any) => {
        if (!isEmpty(contractorDetails)) {
            const list: any = [];
            servicesList?.map((s: any) => {
                if (contractorDetails?.find((c: any) => c?.serviceId === s?.id)) {
                    list.push(s?.id);
                }
            });
            setSelectedIds(list);
            return isReturn ? list : null;
        }
    };

    const handleServiceAdd = (item: any) => {
        const auth = JSON.parse(localStorage.getItem('auth') || '{}');
        const index = addedIds?.findIndex((f: any) => f.serviceId === item.element.id);

        if (index > -1) {
            const updatedIds = [...addedIds];
            updatedIds.splice(index, 1);
            setAddedIds(updatedIds);
        } else {
            setAddedIds([
                ...addedIds,
                {
                    contractorId: auth?.company?.id,
                    serviceId: item.element.id
                }
            ]);
        }
    };

    const setNotApplicable = () => {
        const list: any = [...notAppicableIds];
        const auth = JSON.parse(localStorage.getItem('auth') || '{}');

        setNoServiceData(!noServiceData);
        if (!noServiceData) {
            setSelectedIds([]);
        } else {
            const addList = seeIfServiceIsChecked(true);
            if (addList.length) {
                const index = addList?.findIndex((f: any) => f === servicesList[0].id);
                addList.splice(index, 1);
                setAddedIds(addList);
            }
        }

        const index = list?.findIndex((f: any) => f.serviceId === servicesList[0].id);

        if (index > -1) {
            const updatedIds = [...addedIds];
            updatedIds.splice(index, 1);
            setAddedIds(updatedIds);
        } else {
            setAddedIds([
                ...addedIds,
                {
                    contractorId: auth?.company?.id,
                    serviceId: servicesList[0].id
                }
            ]);
        }
    };

    return (
        <div className="py-4">

            {(servicesList && servicesList.length > 0) ? <>
                <div className="flex mb-4">
                    <div className="mr-4">
                        <CheckBoxIcon
                            onClick={setNotApplicable}
                            variant={
                                noServiceData ? "all" : "none"
                            }
                        />
                    </div>
                    <div>Niet van toepassing</div>
                </div>
                <TreeView
                    data={servicesList}
                    aria-label="Checkbox tree"
                    multiSelect
                    propagateSelect
                    propagateSelectUpwards
                    togglableSelect
                    expandedIds={expandedIds}
                    selectedIds={selectedIds}
                    onNodeSelect={handleServiceAdd}
                    nodeRenderer={({
                        element,
                        isBranch,
                        isExpanded,
                        isSelected,
                        isHalfSelected,
                        getNodeProps,
                        level,
                        handleSelect,
                    }) => {
                        return (
                            <div
                                // {...getNodeProps({ onClick: handleSelect })}
                                style={{ marginLeft: 40 * (level - 1) }}
                                className="flex pb-[5px]"
                            >
                                {/*<div className="col-span-1">
                                {isBranch && <ArrowIcon isOpen={isExpanded} />}
                            </div>*/}
                                {!isBranch && (
                                    <div className="mr-4">
                                        <CheckBoxIcon
                                            onClick={(e: any) => {
                                                if (!noServiceData && level >= 2) {
                                                    handleSelect(e);
                                                }
                                                e.stopPropagation();
                                            }}
                                            variant={
                                                isSelected ? "all" : "none"
                                            }
                                        />
                                    </div>
                                )}

                                <div
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                    }}
                                    style={isBranch ? { fontWeight: 500, padding: '10px 0', textTransform: 'uppercase' } : {}}
                                >
                                    <span>{element.name}</span>
                                </div>
                            </div>
                        );
                    }} />
            </>
                : <h5 className="text-dark text-md font-semibold">Niet van toepassing</h5>}


        </div>
    )
};

export default OfferCompareCard;
