import React, { useEffect,useState } from "react";
import LoginModalComponent from "../../../components/auth/login/loginModalComponent";
import ForgotPasswordComponent from "../../../components/auth/forgotPassword/forgotPasswordComponent";
import RegisterModalComponent from "../../../components/auth/register/registerModalComponent";
import ResetPasswordComponent from "../../../components/auth/resetPassword/resetPasswordComponent";
import CreatePasswordComponent from "../../../components/auth/createPassword/createPasswordComponent";
import { useLocation,useParams } from "react-router-dom";
type LoginModalProps = {
    modalState?: {
        state?: boolean,
        type?: string
    },
    setModalState?: (modalState: {state: boolean, type: string }) => void,
    handleModalClose?: () => void,
    showRegisterButton?: boolean,
    openPasswordResetModal?: boolean,
    openLoginModal?: boolean,
 
}

const LoginModal = ({ modalState = {state: false, type: "" } , handleModalClose = () => {}, setModalState = () => {}, showRegisterButton, openPasswordResetModal = false, openLoginModal = false}: LoginModalProps) => {
    const location = useLocation();
  
    useEffect(() => {
        if (openPasswordResetModal) {
            setModalState({
                state: true,
                type: "resetPassword"
            });
        }
    }, [openPasswordResetModal]);

    useEffect(() => {
        if(openLoginModal) {
            setModalState({
                state: true,
                type: "login"
            });
        }
    }, [openLoginModal])

//for create new password
    useEffect(() => {
        if(location?.search.includes('token') && !location?.pathname.includes('review')) {
            setModalState({
                state: true,
                type: "createPassword"
            });
        }
    }, [])


    const goToRegister = () => {
        setModalState({
            state: true,
            type: "register"
        });
    }

    const goToForgotPassword = () => {
        setModalState({
            state: true,
            type: "forgotPassword"
        });
    }

    const goToLogin = () => {
        setModalState({
            state: true,
            type: "login"
        });
    }

    return (
        <div className = {`w-full h-full fixed top-0 left-0 bg-black/[0.69] flex justify-center items-center z-[50] duration-[400ms] ${modalState?.state ? "opacity-1 scale-100": "opacity-0 scale-0"}`}>
            {modalState.type === "login" ? (
                <LoginModalComponent handleModalClose={handleModalClose} onRegisterClick = {goToRegister} onForgotPasswordClick = {goToForgotPassword} showRegistrationButton = {showRegisterButton} />
            ) : modalState.type === "forgotPassword" ? (
                <ForgotPasswordComponent handleModalClose={handleModalClose} onBackClick = {goToLogin} />
            ) : modalState.type === "register" ? (
                <RegisterModalComponent handleModalClose={handleModalClose} onLoginClick = {goToLogin} />
            ) : modalState.type === "resetPassword" ? (
                <ResetPasswordComponent setModalState = {setModalState} />
            ) : 
            modalState.type === "createPassword" ? (
                <CreatePasswordComponent  handleModalClose={handleModalClose} setModalState = {setModalState} />
            ) :
            
            (
                <>
                </>
            )}
        </div>
)
}

export default LoginModal;