import {isEmpty} from "lodash";
import React, {FC, useEffect, useState} from "react";

import OfferService from "../../../api/Offers";
import OfferCompareCard from "./OfferCompareCard";
import {PrimaryButton} from "../../../shared/buttons/igemo_button";
import {message} from "antd";

interface compStruct {
    servicesList: any
}

const OfferCompare:FC<compStruct> = ({
    servicesList,
}) => {
    const [isLoading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [addedIds, setAddedIds] = useState<any>([]);
    const [notAppicableIds, setNotApplicableIds] = useState<any>([]);
    const [contractorDetails, setContractorDetails] = useState<any>({});

    useEffect(() => {
        fetchContractorDetails();
    }, []);


    const fetchContractorDetails = async () => {
        const auth = JSON.parse(localStorage.getItem('auth') || '{}');
        try {
            setLoading(true)
            const response = await OfferService.getContractorServiceOffers(auth?.company.id);
            const {data} = response;

            setContractorDetails(data);
        } catch (error) {
            setLoading(false)
            console.error(error);
        } finally {
            setLoading(false)
        }
    };

    const updateContractorDetails = async () => {
        const alreadyAddedIds = () => {
            const arrayToRemove:any = [];
            const arrayToAdd:any = [];
            if (addedIds?.length) {
                addedIds.forEach((a: any) => {
                   const index = contractorDetails.findIndex((c: any) => c.serviceId === a.serviceId);
                   if (index > -1) {
                       arrayToRemove.push(a);
                   } else {
                       arrayToAdd.push(a);
                   }
                });
            }
            return {
                arrayToRemove,
                arrayToAdd,
            };
        };

        const items: any = alreadyAddedIds();
        let removeStatus=null, addStatus=null, notStatus=null;

        if (items.arrayToRemove.length) {
            removeStatus = await removeIds(items.arrayToRemove);
        }

        if (items.arrayToAdd.length) {
            addStatus = await addIds(items.arrayToAdd);
        }

        if (notAppicableIds.length) {
            notStatus = await setNoApplicable(notAppicableIds);
        }



        if (removeStatus || addStatus || notStatus) {
            messageApi.open({
                type: 'success',
                content: 'Update van aanbiedingsdetails is gelukt.',
                duration: 3,
            });
            fetchContractorDetails();
        }else if(removeStatus!==null || addStatus !== null || notStatus !== null){
            messageApi.open({
                type: 'error',
                content: 'Kan de aanbiedingsdetails niet updaten.',
                duration: 3,
            });
        }

    };

    const addIds = async (ids: any) => {
        try {
            const response = await OfferService.addServicesForContractor(ids, {});
            if (response?.data?.length) {
                setAddedIds([]);
                return true
            } else {
                return false
            }
        } catch (error) {
            console.error(error);
            return false
        }

    }

    const removeIds = async (ids: any) => {
        try {
            const response = await OfferService.removeServicesForContractor(ids, {});
            if(response.success){
                setAddedIds([]);
                return true
            }
            else return false

        } catch (error) {
            console.error(error);
            return false
        }
    }

    const setNoApplicable = async (ids: any) => {
        try {
            const response = await OfferService.addServicesForContractor(ids, {});
            if(response.success){
                setNotApplicableIds([]);
                return true
            }
            else return false

        } catch (error) {
            console.error(error);
            return false
        }
    }

    return (
        <div className="py-8">
            {contextHolder}

            {isLoading ? (
                <div className="px-16 py-16" style={{ textAlign: 'center' }}>Laden...</div>
            ) : (
                !isEmpty(servicesList?.cpContractorRenovations) ? (
                    <>
                        {servicesList?.cpContractorRenovations?.map((o: any) => {
                            return (
                                <OfferCompareCard
                                    setNotApplicableIds={setNotApplicableIds}
                                    contractorDetails={contractorDetails}
                                    notAppicableIds={notAppicableIds}
                                    setAddedIds={setAddedIds}
                                    addedIds={addedIds}
                                    cardData={o}
                                />
                            );
                        })}
                    </>
                ) : (
                    <div className="px-16 py-16" style={{ textAlign: 'center' }}>Geen data.</div>
                )
            )}

            <div className='text-right'>
                <PrimaryButton
                    content="Opslaan"
                    onClick={updateContractorDetails}
                />
            </div>
        </div>
    )
};

export default OfferCompare;
