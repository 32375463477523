import React, {Dispatch, FC, useEffect, useState} from "react"
import {Col, Row} from "antd";
import {toNumber} from "lodash";

import { Tag } from "../../shared/elements/tag";
import {City, Province} from "../modals/modal/Province";

interface AddOrgCityModalStruct {
    cities: Array<City>
    updateProvincesList?: Array<any>
    updateSelectedProvinceList: (item: any, key: string, value: any) => void
    province: Province
    checkup: boolean
}

const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
];

const FormCityStep: FC<AddOrgCityModalStruct> = ({
    cities,
    province,
    updateProvincesList,
    updateSelectedProvinceList,checkup
}) => {

    const [alphabetSelector, setAlphabetSelector] = useState('A');
    const [filteredCities, setFilteredCities] = useState<Array<City>>([]);

    useEffect(() => {
        filterBasedOnAlphabet('A', cities);
        updateCitiesList();
    }, []);

    useEffect(() => {
        filterBasedOnAlphabet('A', cities);
    }, [checkup]);

    const updateCitiesList = () => {
      if (updateProvincesList?.length && cities?.length) {
          const index = updateProvincesList?.findIndex(p => p.provinceId === province.id);
          if (index > -1) {
              const updateCities = updateProvincesList[index].cities;
              if (updateCities?.length) {
                  updateCities?.forEach((c: any) => {
                      handleCityAdd(c, 'active', true, true);
                      handleCityAdd(c, 'payloadCityId', c?.id, true);
                  });
              }
          }
      }
    };

    const filterBasedOnAlphabet = (alphabet: string, list: Array<City>) => {
        let updatedList = list;
        updatedList = updatedList.filter((l) => l?.label[0].toLowerCase() === alphabet.toLowerCase());
        setFilteredCities(updatedList);
        setAlphabetSelector(alphabet);
    };


    const handleCityAdd = (item: any, key: string, value: any, update: boolean = false) => {
        const updateCities = [...cities];
        let id = update ? item?.municipalityId : item?.id;
        id = toNumber(id);
        const index = updateCities?.findIndex(f => f.id === id);
        if (index > -1) {
            // @ts-ignore
            updateCities[index][key] = value;
            filterBasedOnAlphabet(alphabetSelector, updateCities);
            province.checked = (province.cities.filter((s: any) => s.active).length === province.cities.length);
            updateSelectedProvinceList(province, 'cities', updateCities);
        }
    };

    return (
        <div className="py-2">
            <div style={{ borderBottom: '1px solid #e1e1e1', padding: '0 30px 15px', margin: '0 -30px 15px'}}>
                {alphabets.map((a) => {
                    return (
                        <span
                            style={alphabetSelector === a ? { cursor: 'pointer', fontWeight: 600, borderBottom: '3px solid #0A0A0A', color: '#0A0A0A' } : { color: '#00000040'}}
                            className="px-2 py-4 cursor-pointer"
                            onClick={() => filterBasedOnAlphabet(a, cities)}
                        >
                            {a}
                        </span>
                    )
                })}
            </div>

            <div className="grid grid-cols-3">
                <Row className="col-span-7">
                    {filteredCities?.length ? (
                        <>
                            {filteredCities.map((city: City) => {
                                return (
                                    <Col className="mr-3 mt-2 flex items-center">
                                        <Tag
                                            text={city.label}
                                            onClick={() => handleCityAdd(city, 'active', !city.active)}
                                            active={city.active}
                                        />
                                    </Col>
                                )
                            })}
                        </>
                    ) : <div style={{color: 'gray'}}>No Gemeente</div>
                    }
                </Row>
            </div>
        </div>
    )
}

export default FormCityStep;
