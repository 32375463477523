import React, {Dispatch, FC, SetStateAction, useEffect, useState} from "react";
import {ModalBox} from "../../shared/modals/modal";
import {Email, Input} from "../../shared/forms/input";
import {message,} from "antd";
import { PrimaryButton, } from "../../shared/buttons/igemo_button";
import {GetAPIService,PostAPIService, PutAPIService} from "../../context/services";
import Joi from "joi";


interface AddHouseOwnerModalStruct {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    addresses: Array<{label: string; value: string}>,
    selectedUser: any,
    setSelectedUser: any,
    reload?: boolean,
    setReload?: any,
    readOnly?: boolean,
}

const addUserSchema = Joi.object({
    firstName: Joi.string()
        .required()
        .messages({
            'string.empty': `Voornaam mag niet leeg zijn.`,
            'any.required': `Voornaam is een verplicht veld.`,
        }),
    lastName: Joi.string()
        .required()
        .messages({
            'string.empty': `Achternaam mag niet leeg zijn.`,
            'any.required': `Achternaam is een verplicht veld.`,
        }),
    email: Joi.string()
    .pattern(/^\w+([\w+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    .required()
    .messages({
        'string.empty': `E-mail mag niet leeg zijn`,
        'any.required': `E-mail is een verplicht veld.`,
        'string.pattern.base': `Ongeldig e-mailadres.`
    }),
    phone: Joi.string()
    .pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
    .required()
    .min(6)
    .messages({
        'string.empty': `Telefoonnummer mag niet leeg zijn`,
        'any.required': `Telefoonnummer is een verplicht veld.`,
        'string.pattern.base': `Ongeldig telefoonnummer.`,
        'string.min': `Het telefoonnummer moet een minimale lengte van {#limit} cijfers hebben`,
    }),
    password: Joi.optional(),
    address1: Joi.optional(),
    address2: Joi.optional(),
    companyId: Joi.optional(),
    typeId: Joi.number().required().messages({
        'any.required': `Type is een verplicht veld.`
    })

})

export const AddHouseOwnerModal: FC<AddHouseOwnerModalStruct> = ({
    open,
    setOpen,
    selectedUser,
    setSelectedUser,
    reload,
    setReload,
    readOnly=false,
    addresses=[]
}) => {
    const [houseOwner, setHouseOwner] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        address1: '',
        address2: '',
        companyId: null,
        typeId: 1
    });
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        typeId: '',
        companyId: '',
        password: '',
    });
    const [error, setError] = useState('');
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);



    useEffect(()=>{
        setLoading(true)
        const companies = GetAPIService(`contractor/light`).then(data =>{
            setCompanies(data.data)
            setLoading(false)
        })
    }, [])


    useEffect(()=>{
        console.log(selectedUser)
        if(Object.keys(selectedUser).length > 0) {
            setHouseOwner({
                firstName: selectedUser?.firstName,
                lastName: selectedUser?.lastName,
                email: selectedUser?.email,
                phone: selectedUser?.phone,
                password: '',
                address1: '',
                address2: '',
                companyId:selectedUser?.companyId,
                typeId: selectedUser?.typeId
            })
        }
    }, [selectedUser])
    const handleChange = (event: any) => {

        const { name, value } = event.target;
        try {

            const target = addUserSchema.extract(name);
            const result = target.validate(value)
            if (result.error && isSubmitted) {
                setErrors({ ...errors, [name]: result.error.message })
            }
            else {
                setErrors({ ...errors, [name]: "" })
            }
            setHouseOwner({ ...houseOwner, [name]: value});

        } catch (error: any) {
            if (isSubmitted) {
                setErrors({ ...errors, [name]: error.message });
            }
        }
    }
    const validateForm = () => {
        let valid = true;
        // @ts-ignore
        let {password} = '';
        console.log("validate",houseOwner)
        // if(houseOwner.firstName == ''){
        //     name = 'gelieve naam toe te voegen';
        //     valid = false;
        // }
        // if(houseOwner.lastName == ''){
        //     first_name = 'gelieve voornaam toe te voegen';
        //     valid = false;
        // }
        // if(houseOwner.email == ''){
        //     email = 'gelieve voeg e-mail toe';
        //     valid = false;
        // }
        // if(houseOwner.phone == ''){
        //     phone = 'gelieve telefoonnummer toe te voegen';
        //     valid = false;
        // }
        
        if(houseOwner.password !== '' && houseOwner.password.length < 8){
            password = 'Min. lengte van het wachtwoord is 8 tekens.'
            valid = false
        }

        setErrors({
            ...errors,
            password: password,
        });
        return valid;
    }
    const handleSubmit = () => {
        if(validateForm()){
            setIsSubmitted(true);
            const results = addUserSchema.validate(houseOwner,{abortEarly:false})
            if (results.error) {
                let errObj ={...errors}
                for (const err of results.error.details) {
                    errObj = {...errObj,[err.path[0]]:err.message}
                }
                setErrors(errObj)
                return
            } else {
                
                setLoading(true);
                let user: Promise<any>;
                if(selectedUser?.id){
                    const payload = {
                        firstName: houseOwner.firstName,
                        lastName: houseOwner.lastName,
                        email: houseOwner.email,
                        phone: houseOwner.phone,
                        typeId: houseOwner.typeId,
                        companyId: houseOwner.companyId,
                        url:window.location.href.indexOf("results") > -1 ? window.location.href : null
                    }       
                    user = PutAPIService(`user/update/${selectedUser?.id}`, payload);
                } else {
                    const payload = {
                        firstName: houseOwner.firstName,
                        lastName: houseOwner.lastName,
                        email: houseOwner.email,
                        phone: houseOwner.phone,
                        typeId: houseOwner.typeId,
                        password: houseOwner.password,
                        companyId: houseOwner.companyId,
                        newsletter: 0,
                        url:window.location.href.indexOf("results") > -1 ? window.location.href : null
                    }
                    const endpoint = houseOwner.password === '' || houseOwner.password == null ? 'user/create/passwordless' : 'user/create';
                    user = PostAPIService(endpoint, payload);
                }
                user.then((response) => {
                    if(response.data.success == true){
                        messageApi.open({
                            type: 'success',
                            content: 'Gebruiker succesvol toegevoegd.',
                            duration: 3,
                        }).then(()=>{
                            setLoading(false);
                            setReload(!reload);
                            handleClose();
                        });
                    } else {
                        setLoading(false);
                        messageApi.open({
                            type: 'error',
                            content: 'fout bij toevoegen Gebruiker.',
                            duration: 3,
                        });
                    }
                }).catch(()=>{
                setLoading(false);
                messageApi.open({
                    type: 'error',
                    content: 'Serverfout',
                    duration: 3,
                });
            })}
        }
    }
    const handleClose = () => {
       setSelectedUser({});
       setIsSubmitted(false);
        setHouseOwner({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
            address1: '',
            address2: '',
            companyId:null,
            typeId: 1
        })
        setOpen(false);
    }
    // let i = 0;
    return (
        <ModalBox
            title="Gegevens van de Gebruiker"
            width={'auto'}
            cancelHandler={handleClose}
            extraClasses="lg:w-[80%] w-full"
            open={open}>
            <div className="border-grey-300 border-b-[1px] border-t-[1px]">
                <div className="px-8 py-12 font-semibold">
                {/* <p>
                Gebruiker toevoegen
                </p> */}

                <div className="mt-10 lg:grid lg:grid-cols-6 w-full gap-4">
                <div className={`lg:col-span-2 w-full lg:mb-0 mb-5  relative w-full `}>

                    <p className={`font-semibold text-secondary text-left mb-1`} >Type</p>
                        <select id="Type" 
                                className="lg:col-span-2 w-full lg:mb-0 mb-5 px-1 py-[0.6rem] w-full focus:outline-none border-2 border-gray-100"
                                onChange={handleChange}
                                name="typeId"
                                disabled
                            >
                            <option value="1" selected={Number(selectedUser?.typeId) === 1 ? true:false} >Huiseigenaar</option>
                            {/* <option value="2" selected={selectedUser?.typeId == 2 ? true:false} >Aannemer</option> */}
                            <option value="5" selected={Number(selectedUser?.typeId) === 5 ? true:false} >Aannemer Admin</option>
                            <option value="3" selected={Number(selectedUser?.typeId) === 3 ? true:false} >Igemo</option>
                            {/* <option value="4" selected={selectedUser?.typeId == 4 ? true:false} >Igemo Admin</option> */}
                        </select>
                        <span className="text-error text-xs">
                        {errors.typeId ?
                            errors.typeId
                            :
                            ''
                        }
                        </span>
                    </div>
                    <Input classes="lg:col-span-2 w-full lg:mb-0 mb-5" full={true}
                           label="VOORNAAM" name="firstName" active={true} onChangeHandler={handleChange}
                           defaultValue={selectedUser?.firstName}
                           value={selectedUser?.firstName}
                           outerError={errors.firstName}
                           readOnly={readOnly}
                    />
                    <Input classes="lg:col-span-2 w-ful lg:mb-0 mb-5" full={true}
                           label="ACHTERNAAM" name="lastName" active={true} onChangeHandler={handleChange}
                           defaultValue={selectedUser?.lastName}
                           value={selectedUser?.lastName}
                           outerError={errors.lastName}
                           readOnly={readOnly}
                    />
                    <Email classes="lg:col-span-2 w-full lg:mb-0 mb-5" full={true}
                           label="EMAIL" name="email" active={true} onChangeHandler={handleChange}
                           defaultValue={selectedUser.email}
                           value={selectedUser.email}
                           outerError={errors.email}
                    />
                    <Input classes="lg:col-span-2 w-full lg:mb-0 mb-5" full={true}
                           label="TELEFOONNUMMER" name="phone" active={true} onChangeHandler={handleChange}
                           defaultValue={selectedUser?.phone}
                           value={selectedUser?.phone}
                           outerError={errors.phone}
                           readOnly={readOnly}
                    />
                    {(Number(houseOwner?.typeId) === 2 || Number(houseOwner?.typeId) === 5) && companies && companies.length > 0 &&
                    <>
                    <div className={`lg:col-span-2 w-full lg:mb-0 mb-5  relative w-full `}>
                        <p className={`font-semibold text-secondary text-left mb-1`} >Bedrijf</p>
                        <select id="bedrij" 
                                className="lg:col-span-2 w-full lg:mb-0 mb-5 px-1 py-[0.6rem] w-full focus:outline-none border-2 border-gray-100"
                                onChange={handleChange}
                                name="companyId"
                                disabled={selectedUser?.id}
                            >
                                {companies && companies.length > 0 && companies.map((data : any)=>{
                                
                                return <option value={data.id} selected={selectedUser?.companyId == data.id ? true:false} >
                                    {data.name}
                                </option>
                                })}
                        </select>
                        <span className="text-error text-xs">
                        {errors.companyId ?
                            errors.companyId
                            :
                            ''
                        }
                        </span>
                    </div>
                    </>
                    }
                    {!selectedUser?.id && <Input classes="lg:col-span-3 w-full lg:mb-0 mb-5" full={true}
                           label="WACHTWOORD" subtitle="*Indien u een wachtwoord ingeeft zal er geen email uitgestuurd worden naar de huiseigenaar om zelf een wachtwoord in te stellen"
                           name="password" type="password" active={true} onChangeHandler={handleChange}
                           outerError={errors.password}
                    />}
                    {/*{*/}
                    {/*    selectedUser?.cpSimulations?.map((address: any, index: number) => {*/}
                    {/*        const street = address?.city || {}*/}
                    {/*        const city = address?.street || {}*/}
                    {/*        const add = formatAddress(city, street) || '';*/}
                    {/*        const offerRequest = address?.cpOfferRequest*/}
                    {/*        if (!offerRequest?.send && 2 === UserTypes.houseOwner)return('')*/}
                    {/*        else i++;*/}
                    {/*        return (*/}
                    {/*            <Input classes="lg:col-span-8 w-full lg:mb-0 mb-5" full={true}*/}
                    {/*                   label={`ADRES${i}`} name={`address${i}`} active={true} onChangeHandler={handleChange}*/}
                    {/*                // @ts-ignore*/}
                    {/*                   defaultValue={add}*/}
                    {/*                // @ts-ignore*/}
                    {/*                   outerError={errors[address.value]}*/}
                    {/*            />*/}
                    {/*        )*/}
                    {/*    })*/}
                    {/*}*/}
                    {/*<Input classes="col-span-2" full={true}*/}
                    {/*       label="GEMEENTE" name="muni" active={true} onChangeHandler={() => {*/}
                    {/*}}*/}
                    {/*/>*/}
                    {/*<Input classes="col-span-2" full={true}*/}
                    {/*       label="POSTCODE" name="postcode" active={true} onChangeHandler={() => {*/}
                    {/*}}*/}
                    {/*/>*/}
                </div>
            </div>
            </div>
            {!readOnly && (
                <div className="px-8">
                    <div className="flex justify-end mt-4">
                        <PrimaryButton content="Opslaan" onClick={handleSubmit}/>
                    </div>
                </div>
            )} 
            {contextHolder}
        </ModalBox>
    )
}

