import {Tree} from "react-arborist";
import React, {FC} from "react";
import {isEmpty} from "lodash";
import {Row, Col} from "antd";
import { FiMinusCircle } from "react-icons/fi";

import {ReactComponent as CheckGreen} from "../../assets/icons/check-circle-green.svg";
import {ReactComponent as CheckRed} from "../../assets/icons/x-circle-error.svg";
import services from "../../shared/filter/services";

interface cardProps {
    addedIds: any
    setAddedIds: any
    servicesList: any
    contractorList: any
    isAdministrative: any
}

const OfferCompareCard: FC<cardProps> = ({
    servicesList,
    contractorList,
    isAdministrative,
}) => {
    const contractorCount = contractorList?.length;

    const checkIfItemSelectedForContactor = (item: any) => {
        let checked:any = [];
        if (item?.data?.contractorServices?.length && contractorList?.length) {
            contractorList?.forEach((c: any) => {
                const index = item?.data?.contractorServices?.findIndex((f: any) => f.contractorId === c.contractor.id);
                if (index > -1) {
                    checked?.push(c.contractor.id);
                }
            });
        }
        return checked;
    };

    function Node({ node }: any) {
        const contractorPresent = checkIfItemSelectedForContactor(node);
        const isBaseServiceNA = checkIfItemSelectedForContactor({data: servicesList});

        return (
            <Row>
                <Col style={{ paddingLeft: 15 * (node.level), paddingBottom: 5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', borderBottom: '1px solid #e1e1e1' }} span={15}>{node.data.name}</Col>
                {(contractorCount >= 1 && node.level > 0) && (
                    <Col span={3} style={{ display: 'flex', paddingBottom: 5, justifyContent: 'center', borderBottom: '1px solid #e1e1e1' }}>
                        {isBaseServiceNA?.includes(contractorList[0]?.contractor.id) ? (
                            <FiMinusCircle size = "20px" className = "text-[grey]" />
                        ) : (
                          <>
                              {contractorPresent?.includes(contractorList[0]?.contractor.id)
                                  ? <CheckGreen height={20} width={20}/>
                                  : <CheckRed height={20} width={20}/>
                              }
                          </>
                        )}
                    </Col>
                )}

                {(contractorCount >= 2 && node.level > 0) && (
                    <Col span={3} style={{ display: 'flex', paddingBottom: 5, justifyContent: 'center', borderBottom: '1px solid #e1e1e1' }}>
                        {isBaseServiceNA?.includes(contractorList[1]?.contractor.id) ? (
                            <FiMinusCircle size = "20px" className = "text-[grey]" />
                        ) : (
                            <>
                                {contractorPresent?.includes(contractorList[1]?.contractor.id)
                                    ? <CheckGreen height={20} width={20} />
                                    : <CheckRed height={20} width={20} />
                                }
                            </>
                        )}
                    </Col>
                )}

                {(contractorCount >= 3 && node.level > 0) && (
                    <Col span={3} style={{ display: 'flex', paddingBottom: 5, justifyContent: 'center', borderBottom: '1px solid #e1e1e1' }}>
                        {isBaseServiceNA?.includes(contractorList[2]?.contractor.id) ? (
                            <FiMinusCircle size = "20px" className = "text-[grey]" />
                        ) : (
                            <>
                                {contractorPresent?.includes(contractorList[2]?.contractor.id)
                                    ? <CheckGreen height={20} width={20} />
                                    : <CheckRed height={20} width={20} />
                                }
                            </>
                        )}
                    </Col>
                )}
            </Row>
        );
    };

    const checkIfItemSelectedForAdminContactor = (item: any) => {
        let checked:any = [];
        if (item?.data?.contractorServices?.length && contractorList?.length) {
            contractorList?.forEach((c: any) => {
                const index = item?.data?.contractorServices?.findIndex((f: any) => f.contractorId === c.contractor.id);
                if (index > -1) {
                    checked?.push(c.contractor.id);
                }
            });
        }
        return checked;
    };

    function adminNode({ node }: any) {
        const contractorPresent = checkIfItemSelectedForAdminContactor(node);

        return (
            <Row>
                <Col style={{ paddingLeft: 15 * (node.level), paddingBottom: 5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', borderBottom: '1px solid #e1e1e1' }} span={15}>{node.data.name}</Col>
                {(contractorCount >= 1) && (
                    <Col span={3} style={{ display: 'flex', paddingBottom: 5, justifyContent: 'center', borderBottom: '1px solid #e1e1e1' }}>
                        {contractorPresent?.includes(contractorList[0]?.contractor.id)
                            ? <CheckGreen height={20} width={20}/>
                            : <CheckRed height={20} width={20}/>
                        }
                    </Col>
                )}

                {(contractorCount >= 2) && (
                    <Col span={3} style={{ display: 'flex', paddingBottom: 5, justifyContent: 'center', borderBottom: '1px solid #e1e1e1' }}>
                        {contractorPresent?.includes(contractorList[1]?.contractor.id)
                            ? <CheckGreen height={20} width={20} />
                            : <CheckRed height={20} width={20} />
                        }
                    </Col>
                )}

                {(contractorCount >= 3) && (
                    <Col span={3} style={{ display: 'flex', paddingBottom: 5, justifyContent: 'center', borderBottom: '1px solid #e1e1e1' }}>
                        {contractorPresent?.includes(contractorList[2]?.contractor.id)
                            ? <CheckGreen height={20} width={20} />
                            : <CheckRed height={20} width={20} />
                        }
                    </Col>
                )}
            </Row>
        );
    };


    return (
        <div className="py-4">
            <Row>
                <Col span={15}>
                    &nbsp;
                </Col>
                {contractorCount >= 1 && <Col style={{ textAlign: 'center' }} span={3}><b>{contractorList[0]?.contractor?.name}</b></Col>}
                {contractorCount >= 2 && <Col style={{ textAlign: 'center' }} span={3}><b>{contractorList[1]?.contractor?.name}</b></Col>}
                {contractorCount >= 3 && <Col style={{ textAlign: 'center' }} span={3}><b>{contractorList[2]?.contractor?.name}</b></Col>}
            </Row>

            {!isEmpty(servicesList.children) ? (
                <Tree
                    width="100%"
                    height={isAdministrative ? 615: 1000}
                    indent={24}
                    padding={25}
                    rowHeight={36}
                    overscanCount={4}
                    data={servicesList.children}
                >
                    {isAdministrative ? adminNode : Node}
                </Tree>
            ) : (
                <div className="px-16 py-16" style={{ textAlign: 'center' }}>Geen data.</div>
            )}
        </div>
    )
};

export default OfferCompareCard;
