import React, {FC, useState} from 'react';
// @ts-ignore
// import { Document, Page } from 'react-pdf';

interface documentStruct {
    file: string,
}

const PDFView: FC<documentStruct> = ({ file }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    // @ts-ignore
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <object data={file} type="application/pdf" style={{ width: "100%", height: "100%" }}>
            <embed src={file}  type="application/pdf" />
        </object>
    );
};

export default PDFView;
