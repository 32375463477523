import HttpService from "../constants/HttpService";
import {URLQueryParams} from "../constants/queryParams";

class OrganizationService extends HttpService {
    constructor() {
        super('domain');
    }

    getAllOrganizations = async (searchString?: string) => {
        const paramsObj = {
            ...(searchString && { search: searchString }),
        };
        const queryParams = URLQueryParams.construct(paramsObj);

        let url = this.endPoint;
        if (queryParams) {
            url += `?${queryParams}`;
        }

        const { data } = await this.client.get(url);
        return data;
    }

    getOrganizationDetails = async (id: string) => {
        const { data } = await this.client.get(`${this.endPoint}/show/${id}`)
        return data
    }

    createNewOrganization = async (payload: any, others: any) => {
        const { data } = await this.client.post(`${this.endPoint}`, payload, {...others})
        return data
    }

    updateOrganization = async (id: string, payload: any, others: any) => {
        const { data } = await this.client.put(`${this.endPoint}/${id}`, payload, {...others})
        return data
    }

    addOrganizationProvinces = async (domainId: string, payload: any, others: any) => {
        const { data } = await this.client.post(`${this.endPoint}/province/${domainId}`, payload, {...others})
        return data
    }

    getOrganizationProvinceDetails = async (id: string) => {
        const { data } = await this.client.get(`${this.endPoint}/province/${id}`)
        return data
    }

    getOrganizationAllExcelFile = async () => {
        const { data } = await this.client.get(`${this.endPoint}/download`, { responseType: 'blob' })
        return data
    }

    getOrganizationExcelFileById = async (id: string) => {
        const { data } = await this.client.get(`${this.endPoint}/${id}/download`, { responseType: 'blob' })
        return data
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new OrganizationService();
