import HttpService from "../constants/HttpService";

class DomainService extends HttpService {
    constructor() {
        super('domain');
    }

    // additional services
    getDomainObj = async (subDomain: String) => {
        const response = await this.client.get(`${this.endPoint}/slug/${subDomain}`)
        return response
    }

    getMunicipalityDetails = async (payload: any, others: any) => {
        const { data } = await this.client.post(`${this.endPoint}/address-validate`, payload, {...others})
        return data
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DomainService()
