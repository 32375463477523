import React, {FC, useState} from 'react'
import {
    HeadingsInterface, HouseOwnerListingTypeColors, UserTypes
} from '../../models/applicationState';
import HomeRow from './HomeRow';
import { formatAddress } from '../../utils/helpers';
import Table from '../../shared/Table';
import { Toggler } from '../../shared/hoc/Toggler';
import { TableHeaders } from '../../shared/Table/common';

interface HomeOwnerListProps {
    tableTitle: String,
    toggleWarningPopup?: any,
    data: any,
    initialStatus?: Boolean,
    listingType: HouseOwnerListingTypeColors,
    contractorStatus?: Boolean,
    onContractorStatusChange?: (status: Boolean) => void,
    handleToggleProjectDetails?: (cp: Boolean) => void,
    setSelectedUser?: any,
    idsWithRecords?: any,
    loading?: any,
}

const HomeOwnerList: FC<HomeOwnerListProps> = ({
    idsWithRecords,
    loading,
    tableTitle,
    data,
    initialStatus = false,
    listingType,
    contractorStatus = false,
    onContractorStatusChange,
    toggleWarningPopup,
    handleToggleProjectDetails,
    setSelectedUser = () => { },
}) => {
    const [showDetails, toggleShowDetails] = useState(false);

    const openWarningPopup = () => {
        toggleWarningPopup(true);
    };

    const headings: HeadingsInterface = {
        AANNEMERS: 'AANNEMERS',
        DATUM: "DATUM",
        BEGELEIDING: "BEGELEIDING",
        GROEPSAANKOOP: "GROEPSAANKOOP",
        STATUS: "STATUS",
        DOCUMENTEN: "DOCUMENTEN",
    }

    const handleToggleStatus = (cp: any) => {
        toggleShowDetails(!showDetails);
        if (handleToggleProjectDetails) {
            handleToggleProjectDetails(cp);
        }
    };


    return (
        <Table
            mainBurgerClickHandler={() => setSelectedUser(data)}
            title={tableTitle}
            showDetails={showDetails}
            initialStatus={initialStatus}
            listingType={listingType}
            contractorStatus={contractorStatus}
            onContractorStatusChange={onContractorStatusChange}
            handleToggleStatus={handleToggleStatus}
        >
            <div className='px-8 w-full overflow-visible overflow-x-auto mb-8'>
                {data?.cpSimulations?.length ? (
                    <table className='ml-0 w-full' >
                        {data?.cpSimulations.map((cpSimulation: any) => {
                            const street = cpSimulation?.street || {}
                            const city = cpSimulation?.city || {}
                            const address = formatAddress(city, street) || ''
                            const offerRequest = cpSimulation?.cpOfferRequest
                            const cpOfferRequestRenovations = offerRequest?.cpOfferRequestRenovations

                            // if offer request is not send
                            if (!offerRequest?.send) { return <></> }

                            return (
                                <Toggler
                                    initialStatus={true}
                                    render={({ toggleStatus, status }) => (
                                        <>
                                            <thead className='mr-4 '>
                                            <tr className='w-full'>
                                                <TableHeaders
                                                    title={address}
                                                    headings={Object.values(headings)}
                                                    showBody={status}
                                                    toggleShowBody={toggleStatus}
                                                />
                                            </tr>
                                            </thead>
                                            {status ? (
                                                <tbody className='shadow-md bg-white w-full '>
                                                {cpOfferRequestRenovations?.map((cpOfferRequestRenovation: any) => {
                                                    const title = cpOfferRequestRenovation?.renovation?.title
                                                    const offerRequestId = cpOfferRequestRenovation.offerRequestId
                                                    const renovationId = cpOfferRequestRenovation.renovationId
                                                    const renovation = cpOfferRequestRenovation?.renovation
                                                    const cpOfferRequestRenovationContractors = cpOfferRequestRenovation?.cpOfferRequestRenovationContractors
                                                        ?.filter((contractor: any) => contractor?.selected)

                                                    return cpOfferRequestRenovationContractors?.map((contractor: any, idx: number) => (
                                                        <HomeRow
                                                            key={idx}
                                                            toggleWarningPopup={openWarningPopup}
                                                            item={contractor}
                                                            title={title}
                                                            offerRequestId={offerRequestId}
                                                            renovationId={renovationId}
                                                            renovation={renovation}
                                                            offerRequestSendAt={cpSimulation?.cpOfferRequest?.sendAt}
                                                            simulation={cpSimulation}
                                                        />
                                                    ))
                                                })}
                                                </tbody>
                                            ) : null}
                                        </>
                                    )}
                                />
                            )
                        })}
                    </table>
                ) : (
                    (idsWithRecords?.includes(data?.id) && !loading) ? (
                        <div className="p-8">
                            Geen data.
                        </div>
                    ) : null
                )}

            </div>
        </Table>
    )
}

export default HomeOwnerList
