import { DeleteFilled } from "@ant-design/icons";
import { Select, DatePicker } from "antd";
import { igemoUserFilterOptions } from "./config";
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/nl_BE';

const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';

const OfferRequestDateOption = ({
    item,
    index,
    removeSelectedOption,
    updateOption1Value,
    availableOptions,
}: any) => {

    // select option 1
    const SelectOption1 = ({ value }: any) => {
        return (
            <Select
                showSearch
                placeholder="Datumbereik aanbiedingsaanvraag"
                optionFilterProp="children"
                className="w-full"
                size="middle"
                onChange={(value) => {
                    updateOption1Value(value, index, "option1");
                    updateOption1Value("", index, "option2");
                    updateOption1Value("", index, "option3");
                }}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                    (option?.label ?? "").toString().toLowerCase().includes(input.toLowerCase())
                }
                value={igemoUserFilterOptions?.find((option: any) => option.value === value)?.label}
                options={availableOptions}
            // options={igemoUserFilterOptions.filter((filter: any) => filter.value !== item.option1)}
            />
        );
    };

    // select option 2
    const SelectOption2 = ({ startDate, endDate }: any) => {
        const handleDateChange = (_: any, dateRanges: any) => {
            const startDate = dateRanges[0]
            const endDate = dateRanges[1]
            updateOption1Value(startDate, index, "option2")
            updateOption1Value(endDate, index, "option3")
        }
        startDate = ["", "="].includes(startDate) ? dayjs(new Date().toISOString()) : dayjs(startDate, dateFormat)
        endDate = endDate === "" ? dayjs(new Date().toISOString()) : dayjs(endDate, dateFormat)
        return (
            <RangePicker
                className="w-full"
                format={dateFormat}
                value={[startDate, endDate]}
                onChange={handleDateChange}
                locale={locale}
            />
        )
    };

    return (
        <div className="flex pb-2 w-full pt-2">
            <div className="px-1 flex items-center w-[14.5%]">
                {index === 0 ? (
                    <div className="w-10">
                        <h2 className="pl-1 text-[14px] opacity-[0.8]"></h2>
                    </div>
                ) : (
                    <div className="w-10">
                        <h2 className="w-fit py-1 px-3 text-[14px] opacity-[0.8] border rounded text-center ">
                            EN
                        </h2>
                    </div>
                )}
            </div>
            <div className="w-full pr-1">
                <SelectOption1 value={item?.option1} />
            </div>
            <div className="w-[100%] px-1">
                <SelectOption2 startDate={item?.option2} endDate={item?.option3} />
            </div>
            <div className="pl-1 flex items-center">
                <DeleteFilled
                    className="text-[20px] cursor-pointer text-[gray]"
                    onClick={() => removeSelectedOption(index)}
                />
            </div>
        </div>
    );
};
export default OfferRequestDateOption;
