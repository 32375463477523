import React, {FC} from "react";
import {Row, Col} from "antd";


import {ReactComponent as PdfIcon} from "../../assets/icons/pdf-color.svg";
import {ReactComponent as ImageIcon} from "../../assets/icons/image.svg";
import {logoUrl, uploadsUrl} from "../../shared/Table/common";

interface documentStruct {
    documents: any,
    setOpenPReview: any,
}

const DocumentsTab: FC<documentStruct> = ({
    documents,
    setOpenPReview,
}) => {
    return (
        <>
            {documents?.length ? (
                <Row gutter={6}>
                    {documents?.map((doc: any) => {
                        let docType = doc.file.split('.');
                        docType = docType[docType.length - 1];
                        const iconType = docType === 'pdf'
                            ?  <PdfIcon className='h-24 w-24' title={doc.title} />
                            :  <><img src={uploadsUrl(doc.file)} alt={doc.title} className = "h-[100px] object-fit pr-4" /></>

                        return <>
                        <Col className="py-4 text-center cursor-pointer" onClick={() => setOpenPReview({ ... doc, docType: docType })}>
                            {iconType}
                            <p className="pt-1">{`${doc?.title}`}</p>
                        </Col>
                        </>;
                    })}
                </Row>
            ) : (
                <div className="text-center p-4">Geen documenten gevonden.</div>
            )}
        </>
    )
};

export default DocumentsTab;

