import moment from "moment";
import {Row, Col} from "antd";
import {isEmpty} from "lodash";
import {Modal, Tabs, TabsProps} from "antd";
import React, {Dispatch, FC, SetStateAction, useState, useEffect} from "react";

import DocumentsTab from "../../containers/houseOwner/DocumentsTab";
import ContractorTab from "../../containers/houseOwner/ContractorTab";
import {uploadsUrl} from "./common";
import {GetAPIService, PostAPIService} from "../../context/services";
import {renderFormTabs} from "../../containers/servicesList/renderForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleLeft} from "@fortawesome/free-solid-svg-icons";
import {UnRoundedDwnButton2} from "../buttons/igemo_button";
import {ReactComponent as ImageIcon} from "../../assets/icons/image.svg";
import {ReactComponent as PdfIcon} from "../../assets/icons/pdf-color.svg";
import PDFFile from "../../containers/houseOwner/pdf/File1";
import dwnColorGrey from "../../assets/icons/pdf.svg";
import { ModalBox } from "../modals/modal";
import getCardDescription from "../../components/services/getCardDescription";
import { ServiceAnswerType } from "../../models/applicationState";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface TabsModalStruct {
    allData?: any
    title?: string
    logo?: string
    open: boolean
    selectedItem: any
    offerReqId?: string,
    toggleOpen: Dispatch<SetStateAction<boolean>>,
    tabKey?:any,
    renovationId?: any,
    simulation?: any,
    simulationId?: string,
    renovationKey?: string,
    offerRequestSendAt?: string,
}

const TabsModal: FC<TabsModalStruct> = ({
    open,
    logo,
    title,
    allData,
    toggleOpen,
    offerReqId,
    selectedItem,
    tabKey = 1,
    renovationId,
    simulation,
    simulationId,
    renovationKey,
    offerRequestSendAt,
}) => {

    const [payload, setPayload] = useState<any>({});
    const [stateData, setStateData] = useState<ServiceAnswerType[]>([]);
    const [previewDocument, setOpenPReview] = useState<any>({});
    const [questionsList, setQuestionsList] = useState<any>({});
    const [documentsList, setDocumentsList] = useState<any>([]);
    const [newDocs, setNewDocs] = useState<any>([]);
    const [fotoDocuments, setFotoDocuments] = useState<any>([]);
    const [pdfDocuments, setPDFDocument] = useState<any>([]);
    const [contList, setContList] = useState<any>([]);

    useEffect(() => {
        GetAPIService(`offer/request/information/byid/${offerReqId}`)
            .then((servicesRes: any)=> {
                setQuestionsList(servicesRes?.data?.data?.data);
            }).catch((err)=> {
                console.log('err in services fetching is', err)
            });

        GetAPIService(`simulation/resultan/${simulationId}`)
            .then((servicesRes: any)=> {
                setStateData(servicesRes?.data);
            }).catch((err)=> {
                console.log('err in services fetching is', err)
            });

            let documents : any = []
        if (selectedItem?.length) {
            const listItems: { id: any; newItem: { label: any; certificates: any; }; }[] = [];
            selectedItem?.map((item : any) =>{
                console.log("item",item?.cpOfferRequestRenovationContractorDocuments)
                item?.cpOfferRequestRenovationContractorDocuments?.map((doc:any)=>{
                    if(doc){
                        documents?.push(doc)
                    }
                });

                GetAPIService(`/contractor/${item.contractorId}`).then((res: any) => {
                    const companyData = res?.data
                    if (companyData) {
                        const newItem = {
                            label: companyData?.cpContractorLabels.map((label: any)=> {
                                return uploadsUrl(label?.label?.logo)
                            }),
                            certificates: companyData?.cpContractorCertificats?.map((certificate: any)=> {
                                return uploadsUrl(certificate?.certificat?.logo)
                            }),
                        };
                        listItems.push({ id: item.contractorId, newItem });
                    }
                });
            });
            setContList(listItems);
        }


        if (documents) {
            setDocumentsList(documents);

        } else{
            GetAPIService(`offer/request/document/${offerReqId}`)
            .then((servicesRes: any)=> {
                documents = servicesRes?.data;
                setDocumentsList(servicesRes?.data);
            }).catch((err)=> {
                console.log('err in services fetching is', err)
            });
        }

        setFotoDocuments(documents?.filter((d: { documentTypeId: string; }) => d.documentTypeId === '2'));
        setPDFDocument(documents?.filter((d: { documentTypeId: string; }) => d.documentTypeId === '1'));

    }, []);

    const onChange = (key: string) => {
        console.log(key);
    };

    const handleDownloadPDF = () => {
        // @ts-ignore
        const PDFContent = document.getElementById('tabs-document').innerHTML;
        PostAPIService('/pdf/generate', { pdfDom: JSON.stringify(PDFContent) })
            .then((res) => {
                if (res.data.success === true) {
                    const link = document.createElement('a');
                    const date = moment(new Date()).format("dd mm yyyy")
                    const random = () => Math.floor(Math.random() * (999999 - 1)) + 1;
                    link.download = `Check_je_offerte_[${date}]_${random()}`;

                    link.target = '_blank';

                    link.href = res?.data?.data?.pdf;

                    link.click();
                }
            });
    }

    const downloadToLocal = () => {
        const url = uploadsUrl(previewDocument?.file);
        const link = document.createElement('a');
        const date = moment(new Date()).format("dd mm yyyy")
        const random = () => Math.floor(Math.random() * (999999 - 1)) + 1;
        link.download = `Check_je_offerte_[${date}]_${random()}`;

        link.target = '_blank';

        link.href = url;

        link.click();
    };

    const getFilteredQuestionsByType = (typeId: string) => {
        let questions: any[] = [];

        if (questionsList?.length) {
            questions = questionsList.filter((q: any) => ( q.question.questionKind.id === typeId && q.question.renovationId === renovationId));
        }
        return questions;
    };

    useEffect(()=>{
        const fileds = getFilteredQuestionsByType('1');
        let files: any[] = [];
        fileds?.map((field:any) => {
            const fieldType = field?.question?.questionType?.type;
            if (fieldType === 'picture' || fieldType === 'plan') {
                const type = fieldType == 'picture' ? "Foto's" : "Plannen";
                files = files.concat(field.cpOfferRequestRenovationInformationFiles.map((x: any) => {x.documentType = {type}; return x;}));
            }
        });
        const docs = documentsList.concat(files);
        if(documentsList.length < docs.length){
            setNewDocs(docs)
        }
    }, [questionsList])

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `Algemene informatie`,
            children: <>
                {renderFormTabs(getFilteredQuestionsByType('1'),true, 'algemene', payload, setPayload, true, setOpenPReview)}
            </>,
        },
        {
            key: '2',
            label: `Specifieke informatie`,
            children: renderFormTabs(getFilteredQuestionsByType('2'),true, '', payload, setPayload, true),
        },
        {
            key: '3',
            label: `Aannemers`,
            children: <ContractorTab
                renovationId={renovationId}
                contractors={selectedItem}
                offerReqId={offerReqId}
                contList={contList}
            />,
        },
        {
            key: '4',
            label: `Documenten`,
            children: <DocumentsTab setOpenPReview={setOpenPReview} documents={newDocs} />,
        },
    ];

    return (
        <ModalBox
            open={open}
            width={'auto'}
            cancelHandler={() => toggleOpen(false)}
            extraClasses="xl:w-[70%] w-full text-center"
        >
            <>
                <div style={{ height: 38, marginTop: -58 }}>
                    <Row>
                        <Col md={18} className="flex-grow">
                            <div className="flex mt-0 mb-4" onClick={() => toggleOpen(false)}>
                                <FontAwesomeIcon
                                    icon={faCircleLeft as IconProp}
                                    className="w-5 h-5 ml-1 mt-1 -m cursor-pointer text-white"
                                />
                                <p className="mt-1 ml-2 no-underline font-semibold cursor-pointer text-[#ffffff]">
                                    Terug naar aanvragen
                                </p>
                            </div>
                        </Col>
                        <Col md={6} style={{ textAlign: 'right' }}>
                            <UnRoundedDwnButton2
                                onClick={!isEmpty(previewDocument) ? downloadToLocal : handleDownloadPDF}
                                classes="ml-2 border-white h-[75%]"
                                content="Download"
                                icon={dwnColorGrey}
                            />
                        </Col>
                    </Row>
                </div>
                {!isEmpty(previewDocument) ? (
                    <div className="py-4">
                        <Row className="border-b-[1px] pb-2">
                            <Col md={1}>
                                <div className="pt-2 pl-2" onClick={() => setOpenPReview({})}>
                                    <FontAwesomeIcon
                                        icon={faCircleLeft as IconProp}
                                        className="w-8 h-8 ml-1 mt-1 -m cursor-pointer	"
                                    />
                                </div>
                            </Col>
                            <Col md={22}>
                                <h2 className="text-center text-2xl pt-2 primary-text font-semibold">{previewDocument?.documentType?.type}</h2>
                            </Col>
                        </Row>
                        <Row className="border-b-[1px] pb-2 pt-2">
                            <Col md={3} className="pl-4">
                                <p className="font-semibold">Beschrijving type document</p>
                            </Col>
                            <Col md={21}>
                                <p className="pl-2 text-left">{previewDocument?.description ? previewDocument?.description : previewDocument?.desc}</p>
                            </Col>
                        </Row>

                        <Row className="border-b-[1px] pb-2 pt-2 h-[50vh]">
                            <Col md={24} className="p-8 overflow-scroll h-full">
                                {(previewDocument?.docType === 'png' || previewDocument?.docType === 'jpg' || previewDocument?.docType === 'jpeg') ? (
                                    <div>
                                        <img className="object-contain" src={uploadsUrl(previewDocument?.file)} alt="" height={600} style={{ width: '100%' }} />
                                    </div>
                                ) : (
                                    <object data={uploadsUrl(previewDocument?.file)} type="application/pdf" className="h-full w-full">
                                        <embed src={uploadsUrl(previewDocument?.file)}  type="application/pdf" className="h-full w-full" />
                                    </object>
                                )}
                            </Col>
                        </Row>

                        <Row className="pt-2">
                            <Col md={3} className="pl-4">
                                <p className="font-semibold">Uw document:</p>
                            </Col>
                            <Col md={21}>
                                <Row>
                                    {newDocs?.map((doc: any) => {
                                        let docType = doc.file.split('.');
                                        docType = docType[docType.length - 1];
                                        console.log(doc);
                                        const iconType = docType === 'pdf'
                                            ?  <PdfIcon className='h-24 w-24' title={doc.title} />
                                            :  <><img src={uploadsUrl(doc.file)} alt={doc.title} className = "h-[100px] object-fit pr-4" /></>

                                        return <Col
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                            md={4}
                                            onClick={() => setOpenPReview({ ... doc, docType: docType })}
                                            className="pb-2 text-center cursor-pointer"
                                        >
                                            {iconType}
                                            <p className="pt-1">{`${doc?.title}`}</p>
                                        </Col>;
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    ) : (
                    <>
                        <div className="px-5 md:px-16 pt-4 md:pt-7">
                            <div className="flex">
                                <div className="h-24" >
                                    <img src={logo} alt="House's logo" className = "h-full object-contain -ml-2 md:-ml-6"/>
                                </div>
                                <div className="text-left">
                                    <div className="flex flex-col gap-0">
                                        <h1 className="text-lg sm:text-lg md:text-xl xl:text-xl  2xl:text-2xl font-semibold border-igemo">
                                            {title}
                                        </h1>
                                        <div className="mt-1">
                                            {getCardDescription(renovationKey || '', stateData)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-5 md:px-16 py-4">
                            <Tabs
                                items={items}
                                defaultActiveKey={tabKey}

                                onChange={onChange}
                            />
                        </div>
                    </>
                )}


            </>
            <PDFFile
                renovationId={renovationId}
                docData={{
                    fotoDocuments,
                    pdfDocuments,
                    simulation,
                    logo,
                    title,
                    stateData,
                    allData,
                    selectedItem,
                    questionsList,
                    newDocs,
                    renovationKey,
                    offerRequestSendAt,
                    contList,
                }}
            />
        </ModalBox>

    )
};

export default TabsModal;

