import React, {FC, useEffect, useContext, useState} from "react";
import {GlobalContext} from "../context/globalContext/globalContext";
import {Routes, Route, useNavigate, Navigate,} from "react-router-dom";
import {Sessions} from "../utils/session";
// pages
import Results from "../containers/results";
import NotFound from "../components/404";
import {useCookies} from 'react-cookie'
import Simulations from "../containers/simulation";
import HomeOwner from "../containers/houseOwner";
import ServicesList from "../containers/servicesList";
import OfferRequests from "../containers/offerRequests";
import OfferRequestListings from "../containers/offerRequests/OfferRequestListings";
import RequestQuoteListings from "../containers/requestQuote";
import ContractorListings from "../containers/contractor";
import ServicesOffer from "../containers/servicesOffer";
import ServicesOfferPdf from "../containers/servicesOfferPdf";
import HomepageDefault from "../containers/homepage_original";
import Profile from "../components/auth/profile/profile";
import PDFFiles from "../containers/houseOwner/pdf/PDFFiles";
import Blank from "../components/auth/blank/blank";
import ApartmentPage from "../components/ApartmentPage";
import Form from "../containers/form";
import PrivacyPolicy from "../containers/privacyPolicy";
import PdfGenerator from "../containers/pdfGenerator";
import OrganizationPage from "../components/organizations/OrganizationPage";
import SetupPage from "../components/setup/SetupPage";
import Homepage from "../containers/homepage";
import SuperAdminPage from "../components/superadmin/SuperAdminPage";
import SubmitReviewModal from "../components/submitReviewModal/submitReviewModal";
import GoodOffer from "../containers/goodOffer/goodOffer";

type CookieTypes = {
    userId: string,
    sessionData: {
        id: string,
        start_session: string,
        latest_update: string,
        email: string | null,
        address: string | null,
        news_letter_subscription: boolean | null,
        current_consumption: null,
        future_consumption: null,
        epc_score: null,
        createdAt: string | null,
        updatedAt: string | null,
        deletedAt: string | null
    }
}

interface RoutesInterface {
}

/**
 * routes page to define all pages routes here
 */
const PagesRoutes: FC<RoutesInterface> = () => {
    const navigate = useNavigate();
    const auth = JSON.parse(localStorage.getItem("auth") || "{}");
    const [cookies, setCookies, removeCookie] = useCookies();
    const [ cookieState, setCookieState ] = useState<CookieTypes>({ userId: '', sessionData: { id: '', start_session: '', latest_update: '', email: null, address: null, news_letter_subscription: null, current_consumption: null, future_consumption: null, epc_score: null, createdAt: null, updatedAt: null, deletedAt: null } });
    // global state
    const {dispatch, state}: any = useContext(GlobalContext);
    //useEffect to create new visited user Id which requires at the end for form submission
    useEffect(() => {
        Sessions(cookieState, setCookieState, dispatch);
    }, [dispatch]);

    useEffect(() => {
        console.log("GlobalContext state update", state)

        if (state.redirection) {
            console.log("Go to form", state.session.id)
            // navigate("/v/vraag/1/typewoning?id="+state.session.id)
            window.location.replace("/vraag/typewoning");

        }
    }, [state]);

    return (
        <Routes>
            {/*igemo user / super user*/}
            {auth.typeId === 4 ? 
            <>
                <Route path="/" element={<HomepageDefault />}/>
                <Route path="/apartment" element={<ApartmentPage globals={state}/>}/>
                <Route path="/vraag/:path" element={<Form globals = {state} />} />
                <Route path="/results" element={<Results globals={state} cookies={cookies}/>}/>
                <Route path="/404" element={<NotFound globals={state}/>}/>
                <Route path="/contractors" element={<ContractorListings/>}/>
                <Route path="/house-owner" element={<HomeOwner/>}/>
                <Route path="/homeowner" element={<HomeOwner/>}/>
                <Route path="/setup" element={<SetupPage/>}/>
                <Route path="/my-profile" element={<Profile/>}/>
                <Route path="/pdf/:id" element={<PDFFiles/>}/>
                <Route path="/password/update/:token" element={<Blank/>}/>
                <Route path="/password/reset/:token" element={<Blank/>}/>
                <Route path="/organizations" element={<OrganizationPage/>}/>
                <Route path="/superadmins" element={<SuperAdminPage/>}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                <Route path='*' element={<NotFound globals={state}/>}/>
            </>
            :
            auth.typeId === 3  ?
                <>
                    <Route path="/" element={<HomepageDefault />}/>
                    <Route path="/apartment" element={<ApartmentPage globals={state}/>}/>
                    <Route path="/vraag/:path" element={<Form globals = {state} />} />
                    <Route path="/results" element={<Results globals={state} cookies={cookies}/>}/>
                    <Route path="/404" element={<NotFound globals={state}/>}/>
                    <Route path="/contractors" element={<ContractorListings/>}/>
                    <Route path="/house-owner" element={<HomeOwner/>}/>
                    <Route path="/homeowner" element={<HomeOwner/>}/>
                    <Route path="/setup" element={<SetupPage/>}/>
                    <Route path="/my-profile" element={<Profile/>}/>
                    <Route path="/pdf/:id" element={<PDFFiles/>}/>
                    <Route path="/password/update/:token" element={<Blank/>}/>
                    <Route path="/password/reset/:token" element={<Blank/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path='*' element={<NotFound globals={state}/>}/>
                </>
                :
                // house owner
                auth?.typeId === 1 ?
                    <>
                        <Route path="/" element={<HomepageDefault />} />
                        <Route path="/404" element={<NotFound globals={state}/>}/>
                        <Route path="/simulations" element={<Simulations/>}/>
                        <Route path="/vraag/:path" element={<Form globals = {state}  />} />
                        <Route path="/results" element={<Results globals={state} cookies={cookies}/>}/>
                        <Route path="/offer-request" element={<OfferRequests/>}/>
                        <Route path="/offer-request/:simulationId/details" element={<OfferRequestListings/>}/>
                        <Route path="/services-list/:simulationId" element={<ServicesList/>}/>
                        <Route path="/services/draft/:offerRequestId" element={<ServicesOffer/>}/>
                        <Route path="/my-profile" element={<Profile/>}/>
                        <Route path="/password/update/:token" element={<Blank/>}/>
                        <Route path="/apartment" element={<ApartmentPage globals={state}/>}/>
                        <Route path="/password/reset/:token" element={<Blank/>}/>
                        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                        <Route path ="/good-offer" element={<GoodOffer />} />
                        <Route path='*' element={<NotFound globals={state}/>}/>
                    </>
                    // contractor
                    : [2, 5].includes(auth?.typeId) ?
                    <>
                        <Route path="/" element={<RequestQuoteListings/>}/>
                        <Route path="/404" element={<NotFound globals={state}/>}/>
                        <Route path="/my-profile" element={<Profile/>}/>
                        <Route path="/apartment" element={<ApartmentPage globals={state}/>}/>
                        <Route path="/password/update/:token" element={<Blank/>}/>
                        <Route path="/password/reset/:token" element={<Blank/>}/>
                        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                        <Route path='*' element={<NotFound globals={state}/>}/>
                    </>
                    :
                    <>
                        <Route path="/" element={<HomepageDefault />}/>
                        <Route path="/vraag/:path" element={<Form globals = {state}  />} />
                        <Route path="/results" element={<Results globals={state} cookies={cookies}/>}/>
                        <Route path="/404" element={<NotFound globals={state}/>}/>
                        <Route path="/homepage" element={<HomepageDefault />}/>
                        <Route path="/apartment" element={<ApartmentPage globals={state}/>}/>
                        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                        <Route path="/pdf-generator/:hash" element={<PdfGenerator/>}/>
                        <Route path='*' element={<NotFound globals={state}/>}/>
                    </>
            }
            <Route path ="/good-offer" element={<GoodOffer />} />
            <Route path="/services/pdf" element={<ServicesOfferPdf />}/>
            <Route path="/review/create" element = {<SubmitReviewModal />} />
        </Routes>
    );
};
export default PagesRoutes;
