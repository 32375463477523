import create from 'zustand';

interface CertificateStore {
  certificateOpen: boolean
  setCertificate: (value: boolean) => void
}

const useCertificateStore = create<CertificateStore>((set) => ({
  certificateOpen: false,
  setCertificate: (value: boolean) => set({ certificateOpen: value }),
}));

export default useCertificateStore;
