import React, { FC } from "react";
import { Input } from "../../../shared/forms/input";
import { Popconfirm } from "antd";
import { PrimaryButton, ThirdButton } from "../../../shared/buttons/igemo_button";
import ProfileReview from "./profileReview";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPlusCircle, faCircleXmark, } from '@fortawesome/free-solid-svg-icons'
import { validateEmail } from "../../../utils/validations";



export const MyProfile: FC<{ onFormSubmit: () => void; userInfo: any; updatedInfo: any; handleOnChange: (e: any) => void; handleAssociatedEmailsDelete: (email: String, index: number, id: String) => void; handleAssociatedEmailsAdd: () => void; formUpdated: boolean, activeHomeownerTab: number, enablePadding?: boolean, errors:any }> = ({
    onFormSubmit,
    userInfo,
    updatedInfo,
    handleOnChange,
    handleAssociatedEmailsAdd,
    handleAssociatedEmailsDelete,
    formUpdated,
    activeHomeownerTab,
    enablePadding = true,
    errors
}) => {
    let isInValidEmail = false
    let isDulicateEmail = false
    
    const validateEmailAndCheckDuplicate = (email: string, index: number) => {
       
        if(email==="") return false
        else{
            const inValidEmail = !validateEmail(email)
            let isDuplicate = false;
            updatedInfo?.associatedEmails?.map((item: any, ind: number) => {
                if (ind !== index) {
                    if (item.email === email) {
                        isDuplicate = true
                        isDulicateEmail = true
                    }
                    else  isDulicateEmail = false

                }
            })
             
            
            return inValidEmail || isDuplicate
        }
        
    }
    const EmailCheck = () =>{
         for(let i=0;i<updatedInfo?.associatedEmails?.length;i++) {
            let item =updatedInfo.associatedEmails[i]
                let check = !validateEmail(item.email)
                if(check) { isInValidEmail = true; break;}
            }
    }
    const ErrorOnEmail = (email: string, index: number) => {
        const inValidEmail = !validateEmail(email)
        let isDuplicate = false;
        updatedInfo?.associatedEmails?.map((item: any, ind: number) => {
            if (ind !== index) {
                if (item.email === email) isDuplicate = true
            }
        })
        if (isDuplicate) return "Duplicate Email"
        else if (inValidEmail) return "Invalid Email"
    }
    const checkBtn =()=>{
        EmailCheck()
        if(isDulicateEmail)
            return true
        else if(isInValidEmail)
            return true
        else return !formUpdated
    }
    
    return (
        <div className="w-full flex flex-col">
            {activeHomeownerTab === 0 && (
                <>
                    <div className={`w-full flex flex-col ${enablePadding && "px-16"}`}>
                        <p className="font-sans font-bold text-[18px] text-black">Informatie</p>
                        <div className="mt-[16px] flex flex-wrap gap-x-[26px] gap-y-[12px]">
                            <div className="flex flex-col">
                                <label className="font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22]">ACHTERNAAM</label>
                                <input autoComplete="off" className="h-[32px] w-[234px] border-[1px] border-[#298752]/[0.1] font-sans font-regular text-[14px] text-[#4F4C4C] px-[12px] flex items-center" name="lastName" defaultValue={userInfo.lastName} value={updatedInfo.lastName} onChange={handleOnChange} />
                                <span className="text-error text-xs">{errors.lastName ? errors.lastName : null}</span>
                            </div>
                            <div className="flex flex-col">
                                <label className="font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22]">VOORNAAM</label>
                                <input autoComplete="off" className="h-[32px] w-[234px] border-[1px] border-[#298752]/[0.1] font-sans font-regular text-[14px] text-[#4F4C4C] px-[12px] flex items-center" name="firstName" defaultValue={userInfo.firstName} value={updatedInfo.firstName} onChange={handleOnChange} />
                                <span className="text-error text-xs">{errors.firstName ? errors.firstName : null}</span>
                            </div>
                            <div className="flex flex-col">
                                <label className="font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22]">EMAIL</label>
                                <input autoComplete="off" className="h-[32px] w-[234px] border-[1px] border-[#298752]/[0.1] font-sans font-regular text-[14px] text-[#4F4C4C] px-[12px] flex items-center" name="email" defaultValue={userInfo.email} onChange={handleOnChange} value={updatedInfo.email} disabled />
                                <span className="text-error text-xs">{errors.email ? errors.email : null}</span>
                            </div>
                            <div className="flex flex-col">
                                <label className="font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22]">TELEFOONNUMMER</label>
                                <input autoComplete="off" className="h-[32px] w-[234px] border-[1px] border-[#298752]/[0.1] font-sans font-regular text-[14px] text-[#4F4C4C] px-[12px] flex items-center" name="phone" defaultValue={userInfo.phone} value={updatedInfo.phone} onChange={handleOnChange} />
                                <span className="text-error text-xs">{errors.phone ? errors.phone : null}</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-[1px] bg-[#8B8B8B]/[0.15] my-[15px]" />
                    <div className={`w-full flex flex-col ${enablePadding && "px-16"}`}>
                        <p className="font-sans font-bold text-[18px] text-black">Contact</p>
                        {updatedInfo?.associatedEmails?.map((item: any, index: number) =>
                            <div className="flex flex-col mt-[16px]">
                                <label className="font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22]">EMAIL</label>
                                <div className="flex flex-row">
                                    <input autoComplete="off" className={`h-[32px] w-[234px] border-[1px] border-[#298752]/[0.1] font-sans font-regular text-[14px] text-[#4F4C4C] px-[12px] flex items-center ${validateEmailAndCheckDuplicate(item.email, index) ? 'border-[red]' : ""}`} name={`associatedEmails${index}`} defaultValue={item.email} value={item.email} onChange={handleOnChange} />
                                    {index !== 0 &&
                                        <>
                                        <Popconfirm
                                            placement="right"
                                            title={"Verwijder bijbehorende e-mail"}
                                            description={"Weet u zeker dat u deze e-mail wilt verwijderen?"}
                                            okText="Ja"
                                            cancelText="Nee"
                                            onConfirm={(e: any) => handleAssociatedEmailsDelete(item.email, index, item?.id)}
                                        >
                                            <FontAwesomeIcon icon={faCircleXmark as IconProp} className="w-5 h-5 ml-3 mt-2 mr-2  text-grey cursor-pointer" />
                                        </Popconfirm>
                                        
                                        </>

                                    }
                                    

                                </div>
                                <div className="w-full flex justify-start mt-1 ml-1">
                                        {validateEmailAndCheckDuplicate(item.email, index) && (
                                            <p className="font-Source font-regular text-p3 text-[red]">{ErrorOnEmail(item.email, index)}</p>
                                        )}
                                    </div>

                            </div>
                        )}

                        <div className="flex flex-col mt-[16px]">
                            <button type="button" className="flex items-center" onClick={(e) => {
                                e.preventDefault()
                                handleAssociatedEmailsAdd()
                            }}>
                                <FontAwesomeIcon icon={faPlusCircle as IconProp} className="w-5 h-5 -ml-1 mt-1 mr-2  primary-text cursor-pointer" />
                                <h2 className="primary-text font-bold no-underline hover:underline cursor-pointer">Voeg toe</h2>
                            </button>
                        </div>
                    </div>
                    <div className={`mb-10 mt-20 flex justify-end items-center ${enablePadding && "px-16"}`}>
                        <ThirdButton content="annuleer"
                            onClick={() => {
                            }}
                        />
                        <PrimaryButton
                            disabled={checkBtn()}
                            content="Wijzigingen opslaan"
                            onClick={onFormSubmit}
                        />
                    </div>
                </>
            )}
            {activeHomeownerTab === 1 && (
                <ProfileReview homeownerId={userInfo.id} />
            )}
        </div>
    )
}
