import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Joi from "joi";

// assets & image imports
import { PrimaryButton } from "../../../shared/buttons/igemo_button";
import { SecondaryButton } from "../../../shared/buttons/igemo_button";
import { IoCloseCircleOutline } from "react-icons/io5";
import {validateEmail} from "../../../utils/validations";

type LoginModalProps = {
    showRegistrationButton?: boolean;
    handleModalClose?: () => void;
    onRegisterClick?: () => void;
    onForgotPasswordClick?: () => void;
}

const loginSchema = Joi.object({
    email: Joi.string()
        .pattern(/^\w+([\w+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        .required()
        .messages({
            'string.empty': `E-mail mag niet leeg zijn`,
            'any.required': `E-mail is een verplicht veld.`,
            'string.pattern.base': `Ongeldig e-mailadres.`
        }),
    password: Joi.string().required().messages({
        'string.empty': `Wachtwoord mag niet leeg zijn.`,
        'any.required': `Wachtwoord is een verplicht veld.`,
    })
});
const otpSchema= Joi.string().min(6).max(6)
        .required()
        .messages({
            'string.empty': `Otp mag niet leeg zijn`,
            'any.required': `Otp is een verplicht veld.`,
            'string.min': 'Otp moet minimaal uit 6 cijfers bestaan',
            'string.max': 'Otp staat maximaal 6 cijfers toe'
        });
 
const LoginModalComponent = ({ showRegistrationButton = false, handleModalClose = () => {}, onRegisterClick = () => {}, onForgotPasswordClick = () => {} }: LoginModalProps) => {
    const [ inputState, setInputState ] = useState({
        email: "",
        password: "",
        otp:""
    });
    const [ inputErrorState, setInputErrorState ] = useState({
        email: "",
        password: "",
        otp:""
    });
    const [ apiErrorMessage, setApiErrorMessage ] = useState<string>("")
    const [ otp, seOtp ] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false);

    const navigate = useNavigate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        try {
            let target = null
            if(name==='otp') target= otpSchema
            else target = loginSchema.extract(name);
            const result = target.validate(value)
            if (result.error && isSubmitted) {
                setInputErrorState({ ...inputErrorState, [name]: result.error.message })
            }
            else {
                setInputErrorState({ ...inputErrorState, [name]: "" })
            }
            setInputState({ ...inputState, [name]: value });

        } catch (error: any) {
            if (isSubmitted) {
                setInputErrorState({ ...inputErrorState, [name]: error.message });
            }
        }
    }

    function loginSubmit() {
        setIsSubmitted(true);

        const loginState = {
            email :inputState.email,
            password :inputState.password,
        }

        const results = loginSchema.validate(loginState,{abortEarly:false})
        if (results.error) {
            let errObj ={...inputErrorState}
            for (const err of results.error.details) {
                errObj = {...errObj,[err.path[0]]:err.message}
            }
            setInputErrorState(errObj)
            return
        } else {
            const { email, password } = inputState;
            axios.post(process.env.REACT_APP_BACKEND_URL + "user/login", {
                email, password
            })
            .then((response) => {
                // if login is successful, refresh page
                if (response?.data?.data?.requireOTP === false) {
                    const auth = response?.data?.data?.auth
                    localStorage.setItem("auth", JSON.stringify({...auth, typeId: Number(auth.typeId)}));
                    navigate(0);
                    setApiErrorMessage("")
                    
                } else {
                    seOtp(true)
                }
                setIsSubmitted(false)
            })
            .catch((error) => {
                if(error.response?.status === 401) {
                    setApiErrorMessage("E-mail of wachtwoord is verkeerd.");
                    setIsSubmitted(false)
                }
                if(error.response?.status === 400){
                    setApiErrorMessage("Probeert een inlogpoging vanaf een onjuist domein");
                    setIsSubmitted(false)
                }
            })
        }
    }
    function otpSubmit() {
        setIsSubmitted(true);
        const results = otpSchema.validate(inputState.otp,{abortEarly:false})
        if (results.error) {
            let errObj ={...inputErrorState}
            for (const err of results.error.details) {
                errObj = {...errObj,otp:err.message}
            }
            setInputErrorState(errObj)
            return
        } else {
            const { email, password,otp } = inputState;
            axios.post(process.env.REACT_APP_BACKEND_URL + "user/otp", {
                email, password,otp
            })
            .then((response) => {
                // if login is successful, refresh page
                if (response.status === 200) {
                    const { auth } = response.data.data || {};
                    localStorage.setItem("auth", JSON.stringify({...auth, typeId: Number(auth.typeId)}));
                    navigate(0);
                    setApiErrorMessage("")
                    
                }  
            })
            .catch((error) => {
                
                if(error.response.status === 401) {
                    setApiErrorMessage("Ongeldige OTP opgegeven.");
                    setIsSubmitted(false)
                }
            })
        }
    }
    function closeModal(){
        setIsSubmitted(false)
        handleModalClose()
    }
    return (
        <div className = "w-[934px] md:h-[471px] max-w-[100%] min-h-[471px] max-w-[90%] bg-white flex flex-col md:flex-row">
            <div className = "flex flex-col flex-[3.5] h-full primary-main box-border py-[37px] px-[33px]">
                <div className = "w-full flex justify-end md:hidden">
                    <div className = "cursor-pointer" onClick = {closeModal}>
                        <IoCloseCircleOutline size = "30px" className = "secondary-text" /> 
                    </div>
                </div>
                <p className = "font-Source font-semibold text-4xl secondary-text animate-fade-in-top">Inloggen</p>
                <p className = "font-Source font-regular text-p2Alt secondary-text mt-[21px] animate-fade-in-top">Indien u al een account hebt op deze website.</p>
                {/* <p className = "font-Source font-regular text-p2Alt text-white mt-[25px] animate-fade-in-top">At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p> */}
            </div>
            {otp ?
                        <div className = "flex flex-col flex-[6] h-full justify-between bg-white shrink-0">
                        <div className = "flex flex-col w-full">
                            <div className = "w-full justify-end hidden md:flex">
                                <div className = "cursor-pointer" onClick = {closeModal}>
                                    <IoCloseCircleOutline size = "30px" color = "black" className = "mr-[17px] mt-[9px]" /> 
                                </div>
                            </div>
                            <div className = "w-full flex flex-col box-border px-[35px] animate-fade-in-bottom">
                                <div className = "w-full flex flex-col mt-[18px] md:mb-[0px] mb-[18px]">
                                    <p className = "font-Source font-semibold text-p2 primary-text box-border py-[5px]">Verificatie</p>
                                    <p className = "font-Source primary-text box-border py-[5px]"> Er is een verificatie code naar uw email verzonden.</p>
                                    <div className = {`w-full h-[39px] border-[1px] border-primary/[0.09] flex items-center box-border px-[10px] ${inputErrorState.otp ? 'border-red-400' : 'border-primary/[0.09]'}`}>
                                        <input autoComplete="off" className = "w-full outline-none font-Source font-regular text-p2 text-black" type = "text" name = "otp" value = {inputState.otp} onChange = {handleInputChange} />
                                    </div>
                                    <span className="text-error text-xs">{inputErrorState.otp ? inputErrorState.otp : null}</span>
                                </div>
                            </div>
                        </div>
                        <div className = "w-full flex flex-col">
                            <div className = "w-full flex justify-center mb-[10px]">
                                {apiErrorMessage && (
                                    <p className = "font-Source font-regular text-p2 text-[red]">{apiErrorMessage}</p>
                                )}
                            </div>
                            <div className = "flex w-full justify-between box-border px-[35px] pb-[35px] animate-fade-in-bottom">
                                <PrimaryButton content = "Inloggen" onClick = {otpSubmit} />
                            </div>
                        </div>
                    </div>
            :
            <div className = "flex flex-col flex-[6] h-full justify-between bg-white shrink-0">
            <div className = "flex flex-col w-full">
                <div className = "w-full justify-end hidden md:flex">
                    <div className = "cursor-pointer" onClick = {closeModal}>
                        <IoCloseCircleOutline size = "30px" color = "black" className = "mr-[17px] mt-[9px]" /> 
                    </div>
                </div>
                <div className = "w-full flex flex-col box-border px-[35px] animate-fade-in-bottom">
                    <div className = "w-full flex flex-col mt-[18px] md:mb-[0px] mb-[18px]">
                        <p className = "font-Source font-semibold text-p2 primary-text box-border py-[5px]">Email</p>
                        <div className = {`w-full h-[39px] border-[1px] border-primary/[0.09] flex items-center box-border px-[10px] ${inputErrorState.email ? 'border-red-400' : 'border-primary/[0.09]'}`}>
                            <input autoComplete="off" className = "w-full outline-none font-Source font-regular text-p2 text-black" type = "text" name = "email" value = {inputState.email} onChange = {handleInputChange} />
                        </div>
                        <span className="text-error text-xs">{inputErrorState.email ? inputErrorState.email : null}</span>
                    </div>
                    <div className = "w-full flex flex-col mb-[18px]">
                        <p className = "font-Source font-semibold text-p2 primary-text box-border py-[5px]">Wachtwoord</p>
                        <div className = {`w-full h-[39px] border-[1px] border-primary/[0.09] flex items-center box-border px-[10px] ${inputErrorState.password ? 'border-red-400' : 'border-primary/[0.09]'}`}>
                            <input autoComplete="off" className = "w-full outline-none font-Source font-regular text-p2 text-black" type = "password" name = "password" value = {inputState.password} onChange = {handleInputChange} />
                        </div>
                        <span className="text-error text-xs">{inputErrorState.password ? inputErrorState.password : null}</span>
                    </div>
                    <div className = "w-full flex flex-col">
                        <p className = "font-Source font-semibold text-p2 primary-text box-border py-[5px] underline cursor-pointer" onClick={onForgotPasswordClick}>Wachtwoord vergeten?</p>
                    </div>
                </div>
            </div>
            <div className = "w-full flex flex-col">
                <div className = "w-full flex justify-center mb-[10px]">
                    {apiErrorMessage && (
                        <p className = "font-Source font-regular text-p2 text-[red]">{apiErrorMessage}</p>
                    )}
                </div>
                <div className = "flex w-full justify-between box-border px-[35px] pb-[35px] animate-fade-in-bottom">
                    <div>
                        {showRegistrationButton && (
                            <SecondaryButton content = "Maak een account aan" onClick = {onRegisterClick} />
                        )}
                    </div>
                    <PrimaryButton content = "Inloggen" onClick = {loginSubmit} />
                </div>
            </div>
        </div>
            }

        </div>
    )
}

export default LoginModalComponent;
