import { FC } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

import { HeaderContentInterface, TableInterface } from "../../models/applicationState"

import { ReactComponent as HouseIcon } from "../../assets/icons/Favicon_150x150 (1).svg"
import { ReactComponent as ArrowRightCircleGreenEmpty } from "../../assets/icons/Icon arrow-cercle-right-green-empty.svg"
import { IconProp } from "@fortawesome/fontawesome-svg-core";



interface HeadingsProp {
    headings: String[],
}

export const Headings: FC<HeadingsProp> = ({ headings }) => (
    <>{
        headings?.map((heading, idx) => (
            <th key={idx} className='text-[#cbcbcb] font-semibold text-sm px-3'>{heading}</th>
        ))
    }</>
)

export const HeaderContent: FC<HeaderContentInterface> = ({ type, text, showMenu = true, handleDetailsClick }) => (
    <div className='flex gap-1 align-middle'>
        {showMenu && (
            <FontAwesomeIcon
                icon={faEllipsisVertical as IconProp}
                onClick={handleDetailsClick}
                className="w-5 h-5 mt-1 -m cursor-pointer"
            />
        )}
        <div className="h-6 w-6 mt-1"><HouseIcon height="inherit" width="inherit" /></div>
        <p className="ml-1 mt-1 font-semibold">{text}</p>

    </div>
)

export const TableHeaders: FC<TableInterface> = ({ title, headings, toggleShowBody = () => { }, showBody }) => {

    return (
        <>
            <th className={`px-3 mt-2 py-1 items-center flex flex-row gap-2 bg-light_green rounded-tl-md rounded-tr-md w-min justify-center align-middle ${showBody ? "" : "rounded-md"}`}>
                <div onClick={toggleShowBody} className={showBody ? "rotate-0" : "-rotate-90"}>
                    <div className="h-3 w-4 fill-white">
                        <ArrowRightCircleGreenEmpty height="inherit" width="inherit" />
                    </div>
                </div>
                <p className='text-white font-semibold whitespace-nowrap text-sm'>{title}</p>
            </th>

            <Headings headings={headings || []} />
        </>
    )
}

export const logoUrl = (path: string, sub_dir = 'contractors') => {
    return `${process.env.REACT_APP_STORAGE_URL}/uploads/${sub_dir}/logo/${path}`
}
export const uploadsUrl = (path: any) => {
    return `${process.env.REACT_APP_STORAGE_URL}/${path}`
}
