import {useContext, useEffect, useState} from "react"
import { useNavigate } from "react-router-dom";

import Layout from "../../components/layout";
import { HouseOwnerCard } from "../../shared/cards/houseOwnerCard";
import UserService from "../../api/User"
import { formatAddress, formatDate } from "../../utils/helpers";
import {GlobalContext} from "../../context/globalContext/globalContext";
import {GetAPIService} from "../../context/services";
import Cookies from "js-cookie";

const Simulations = () => {
    const [simulations, setSimulations] = useState<[] | null>(null)
    const [loading, setLoading] = useState<boolean | null>(null)
    const [isError, setIsError] = useState<boolean>(false)
    const { state, dispatch } = useContext(GlobalContext)
    const navigate = useNavigate()

    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    useEffect(() => {
        (async () => {
            localStorage.setItem('simulationInitDone', "false")
            try {
                const userId = auth.uid || '1'
                setLoading(true)
                setIsError(false)
                const { data: { data: { simulations: allSims } } } = await UserService.loadAllSimulations(userId)
                setSimulations(allSims)
                setLoading(false)
            } catch (error) {
                console.error(error)
                setIsError(true)
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    const onClick = async (simulationId: string | number) => {
        // await dispatch({ type: "SET_ACTIVE_SIMULATION", payload: simulationId })
        window.location.href = `/services-list/${simulationId}`
    }

    /// remove access to form

    const localKeys = [
        "localState",
        "energyValues",
        "parameters",
        "pdfData",
        "result",
        "currentPayload",
        "maximumbedragEnergielening",
        "SET_HEAT_LOSS_DATA",
        "simulationInitDone",
        "standardDimensions",
        "newState",
        "energyGasNew",
        "improvedPayload",
        "street",
        "city",
        "address"
        
    ]

    useEffect(() => {
        removeLocalStorageItems().then(() => {
            removeCookies().then(() => {
            // navigate(0);
            console.log("cookie and storage clean")
            })
        })
    }, []);

    const removeLocalStorageItems = async (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
        try {
            console.log("clear all")
            const auth = localStorage.getItem("auth") || "";
            localStorage.clear()
            localStorage.setItem("auth",auth)
            // localKeys.forEach((key) => {
            // localStorage.removeItem(key);
            // });
            resolve();
        } catch (error) {
            reject(error);
        }
        });
    };

    const removeCookies = async (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
        try {
            // Object.keys(Cookies.get()).forEach((cookieName) => {
            //   Cookies.remove(cookieName, { path: '/' });
            // });
            Cookies.remove("sessionData", { path: '/' });
            Cookies.remove("userId", { path: '/' });

            resolve();
        } catch (error) {
            reject(error);
        }
        });
    };

    return (
        <Layout>
            <div className='px-[15px] md:px-16 xl:px-32 py-8'>
                <h2 className='text-xl font-bold'>Simulaties</h2>
                {loading && (<div className="mt-4">Laden..</div>)}
                { isError && <p className="text-error mt-4">Kan simulaties niet laden. Probeer het opnieuw.</p> }
                <div className='flex flex-col md:grid md:grid-cols-3 md:gap-3 lg:grid-cols-4 lg:gap-4 mt-10 w-full'>
                    {simulations?.map((simulation: any) => {
                        const {
                            createdAt,
                            city,
                            street,
                        } = simulation

                        const date = formatDate(createdAt)
                        const address = formatAddress(city, street)
                        return (
                            <HouseOwnerCard
                                key={simulation.id}
                                title={address}
                                subTitle={`Simulatie op: ${date}`}
                                onClick={()=> onClick(simulation.id)}
                                btnText="Bekijk het rapport"
                            />
                        )
                    })}
                </div>
            </div>
        </Layout>
    )
}

export default Simulations
