import React, {Dispatch, FC, SetStateAction} from "react";
import { Link } from "react-router-dom"

import {FileExcelOutlined, FileOutlined} from "@ant-design/icons";

interface cardProps {
    cardData: cardItem
    handleOrgEdit: Dispatch<any>
    downloadExcelFileById: Dispatch<any>
}

type cardItem = {
    adminEmail: string;
    domain: string;
    id: string;
    name: string;
    status: boolean;
    subdomain: string;
}

const OrganizationCard: FC<cardProps> = ({ cardData, handleOrgEdit, downloadExcelFileById }) => {
    return(
        <div className="px-4 py-6 pb-4 bg-white mr-8 mb-8">
            <div className="flex pb-2">
                <div className="text-lg font-semibold">{cardData.name}</div>
                 <div style={{ marginLeft: 'auto' }}>
                     <FileOutlined
                         onClick={() => handleOrgEdit(cardData?.id)}
                         className="text-[20px] text-[#00000073] cursor-hand"
                     />
                     <FileExcelOutlined
                         onClick={() => downloadExcelFileById(cardData?.id)}
                         className="text-[20px] primary-text"
                     />
                 </div>
            </div>
            <a href={`https://${cardData.subdomain}.${cardData.domain}`} target="_blank">
                <p
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    className="card_link pb-2 primary-text text-xs"
                >
                    {`https://${cardData.subdomain}.${cardData.domain}`}
                </p>
            </a>

            <p className="card_status pb-2 text-xs font-semibold">HOOFDADMIN</p>

            <div className="flex">
                <div className="text-xs font-semibold">{cardData.subdomain}</div>
                <div className="text-xs font-semibold" style={{ marginLeft: 'auto' }}>{cardData.adminEmail}</div>
            </div>

        </div>
    )
};

export default OrganizationCard;
