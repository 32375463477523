import React, {Dispatch, FC, SetStateAction, useState} from "react";
import {ModalBox} from "../../../shared/modals/modal";
import {Input} from "../../../shared/forms/input";
import {message} from "antd";
import {PrimaryButton} from "../../../shared/buttons/igemo_button";
import {PostAPIService, PutAPIService} from "../../../context/services";
import Joi from "joi";


interface UpdatePassword {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
}
const setPasswordSchema = Joi.object({
    old_password: Joi.string().min(8).required().messages({
        'string.empty': `Oud Wachtwoord mag niet leeg zijn.`,
        'any.required': `Oud Wachtwoord is een verplicht veld.`,
        'string.min': `Het oud wachtwoord moet een minimale lengte van {#limit} cijfers hebben`,
    }),
    new_password: Joi.string().min(8).required().messages({
        'string.empty': `Nieuw Wachtwoord mag niet leeg zijn.`,
        'any.required': `Nieuw Wachtwoord is een verplicht veld.`,
        'string.min': `Het nieuw wachtwoord moet een minimale lengte van {#limit} cijfers hebben`,
    }),
    confirm_password: Joi.string().min(8).required().messages({
        'string.empty': `Bevestig wachtwoord mag niet leeg zijn.`,
        'any.required': `Bevestig wachtwoord is een verplicht veld.`,
        'string.min': `Het bevestig wachtwoord moet een minimale lengte van {#limit} cijfers hebben`,
    })
});

export const UpdatePassword: FC<UpdatePassword> = ({
                                           open,
                                           setOpen
                                       }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [state, setState] = useState({
        old_password: "",
        new_password: "",
        confirm_password: ""
    });
    const [errors, setErrors] = useState({
        old_password: "",
        new_password: "",
        confirm_password: ""
    });
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const auth = JSON.parse(localStorage.getItem("auth") || "{}");
    
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        try {
            let target = setPasswordSchema.extract(name);
            const result = target.validate(value)
            if (result.error && isSubmitted) {
                setErrors({ ...errors, [name]: result.error.message })
            }else if(result.value && isSubmitted && name==="confirm_password" && value !== state.new_password){
                setErrors({ ...errors, confirm_password: "Wachtwoord en bevestigingswachtwoord komen niet overeen.",  })
            }else if(result.value && isSubmitted && name==="confirm_password" && value === state.new_password){
                setErrors({ ...errors, confirm_password: "", })
            }else if(result.value && isSubmitted && name==="password" && value !== state.confirm_password){
                setErrors({ ...errors, confirm_password: "Wachtwoord en bevestigingswachtwoord komen niet overeen.",new_password:"" })
            }else if(result.value && isSubmitted && name==="new_password" && value === state.confirm_password){
                setErrors({ ...errors, confirm_password: "",new_password:"" })
            }else{
                setErrors({ ...errors, [name]: "" })
            }
            setState({ ...state, [name]: value });
        } catch (error: any) {
            if (isSubmitted) {
                setErrors({ ...errors, [name]: error.message });
            }
        }
    }
    
    const handleSubmit = () => {
        setIsSubmitted(true);
        const results = setPasswordSchema.validate(state,{abortEarly:false})
        if (results.error) {
            let errObj ={...errors}
            for (const err of results.error.details) {
                errObj = {...errObj,[err.path[0]]:err.message}
            }
            setErrors(errObj)
            return
        } else if(state.new_password!==state.confirm_password){
            setErrors({ ...errors, confirm_password: "Wachtwoord en bevestigingswachtwoord komen niet overeen." })
            return
        } else{
            setLoading(true);
            PostAPIService(`user/changepassword`, {password: state.old_password,newPassword:state.new_password})
                .then((response) => {
                    if(response.data.success === true){
                        messageApi.open({
                            type: 'success',
                            content: 'Wachtwoord succesvol bijgewerkt.',
                            duration: 3,
                        });
                        setState({
                            old_password: "",
                            new_password: "",
                            confirm_password: ""
                        })
                        setOpen(false);
                    }else{
                        
                        messageApi.open({
                            type: 'error',
                            content: 'Kan het wachtwoord niet bijwerken',
                            duration: 3,
                        });
                    }
                }).catch((err)=>{
                messageApi.open({
                    type: 'error',
                    content: err?.response?.data?.data?.message? err?.response?.data?.data?.message:'Serverfout',
                    duration: 3,
                });
            }).finally(()=>{
                setLoading(false);
                setIsSubmitted(false)
            })
        }
    }
    return (
        <ModalBox
            width={'auto'}
            extraClasses="lg:w-[30%] w-full"
            title="Mijn Profiel: Wachtwoord wijzigen"
            cancelHandler={() => {setOpen(false);setIsSubmitted(false)}}
            open={open}>
            <div className="border-grey-300 border-b-[1px]">
                <div className="px-8 py-12">
                    <div className="mt-5 flex flex-col grid-cols-4 lg:grid-cols-8 gap-4">
                        <Input
                            unControlled={true}
                            classes="col-span-3"
                            label="Huidig wachtwoord"
                            name="old_password"
                            defaultValue={state.old_password}
                            value={state.old_password}
                            onChangeHandler={handleChange}
                            type="password"
                            full={true}
                            active={true}
                            outerError={errors.old_password}
                        />
                        <Input
                            unControlled={true}
                            classes="col-span-3"
                            label="Nieuw wachtwoord"
                            name="new_password"
                            defaultValue={state.new_password}
                            value={state.new_password}
                            type="password"
                            onChangeHandler={handleChange}
                            full={true}
                            active={true}
                            outerError={errors.new_password}
                        />
                        <Input
                            unControlled={true}
                            classes="col-span-3"
                            label="Bevestig van nieuw wachtwoord"
                            name="confirm_password"
                            type="password"
                            defaultValue={state.confirm_password}
                            value={state.confirm_password}
                            onChangeHandler={handleChange}
                            full={true}
                            active={true}
                            outerError={errors.confirm_password}
                        />
                    </div>
                </div>
            </div>
            <div className="px-8 flex justify-between">
                <div className="flex justify-start items-center mt-4 w-[39%]">
                    <p onClick={()=>{setOpen(false)}} className="ml-2 primary-text no-underline hover:underline cursor-pointer text-[16px]">Terug</p>
                </div>
                <div className="flex justify-end mt-4">
                    <PrimaryButton disabled={loading} content="Opslaan" onClick={handleSubmit}/>
                </div>
            </div>
            {contextHolder}
        </ModalBox>

    )
}
