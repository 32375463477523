import { FC, useState, } from 'react'
import { HouseOwnerListingStatus, cpOfferRequestRenovationContractorsInterface } from '../../models/applicationState'
import CheckTick from '../../shared/Table/CheckTick'
import OfferRequestService from '../../api/OfferRequest'
import { Toggler } from '../../shared/hoc/Toggler'
import { formatDate, formatDateTime } from '../../utils/helpers'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import ContractorDocument from './ContractorDocument'
import OfferReqStatus from '../../shared/Table/OfferReqStatus'
import { showWarningAfterDays } from '../../constants/general'
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg"
import WarningModal from '../../components/modals/warningModal'
import TabsModal from '../../shared/Table/DetailsModal'
import { Space } from 'antd'
import { IconProp } from '@fortawesome/fontawesome-svg-core'


interface ContractorRowProps {
    handleDetailsClick?: any,
    toggleWarningPopup?: any,
    offerRequestRenovationContractor: any,
    renovation?: any,
    reload?: any,
    offerRequestSendAt?: any,
    offerRequestId?: any
    simulation?: any,
}

const ContractorRow: FC<ContractorRowProps> = ({ reload, handleDetailsClick = () => { }, offerRequestRenovationContractor, renovation, offerRequestSendAt,offerRequestId, simulation }) => {
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [tabKey, setTabKey] = useState("1")

    const { mentorship, groupPurchase, id: offerRequestRenovationContractorId } = offerRequestRenovationContractor
    const { logo, title } = renovation

    // for warning icon
    const { statusId } = offerRequestRenovationContractor
    const { statusUpdateAt } = offerRequestRenovationContractor
    let showWarning = false
     
    if (statusUpdateAt && statusId === '1') {
         // Current date (with time)
         const currentDateWithTime = new Date();
         // Set the time part to midnight (00:00:00)
         const currentDate:any = new Date(currentDateWithTime.getFullYear(), currentDateWithTime.getMonth(), currentDateWithTime.getDate());
 
         // Date to subtract (with time)
         const dateToSubtractWithTime = new Date(statusUpdateAt);
         // Set the time part to midnight (00:00:00)
         const dateToSubtract:any = new Date(dateToSubtractWithTime.getFullYear(), dateToSubtractWithTime.getMonth(), dateToSubtractWithTime.getDate());
 
         // Calculate the time difference in days
         const timeDifferenceInMilliseconds = currentDate - dateToSubtract;
         const timeDifferenceInDays = timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24);
         if(timeDifferenceInDays>10){
             showWarning = true;
         }
    }

    const handleMentorship = async () => {
        try {
            const payload = {
                mentorship: !mentorship,
            }
            await OfferRequestService.updateRenovationContractorById(offerRequestRenovationContractorId as string, payload)
            reload()

        } catch (error) {
        }
    }

    const handleGroupPurchase = async () => {
        try {
            const payload = {
                groupPurchase: !groupPurchase,
            }
            await OfferRequestService.updateRenovationContractorById(offerRequestRenovationContractorId as string, payload)
            reload()
        } catch (error) {
        }
    }

    return (
        <>
        <tr className='border-0 border-b relative'>
            <td className='px-5 py-2 text-center items-center w-[20%]'>
                {/* <HeaderContent handleDetailsClick={handleDetailsClick} type="type 1" text={userName} /> */}
                <div className='flex gap-1 align-middle'>
                    <img
                        className="w-10  mt-1"
                        src={logo || "https://img.freepik.com/premium-vector/logo-design-construction-service-architecture_304830-442.jpg?w=2000"}
                        alt=""
                    />
                    <p className="ml-1 mt-1 font-semibold">{title}</p>
                    <FontAwesomeIcon
                        icon={faEllipsisVertical as IconProp}
                        //@ts-ignore
                        onClick={() =>setShowDocumentModal(true)}
                        className="w-5 h-5 mt-1 - cursor-pointer"
                    />
                </div>
            </td>
            <td className='px-5 py-2 text-center items-center w-[15%]'>{formatDate(offerRequestRenovationContractor?.createdAt as string)}</td>
            <td className='px-5 py-2 w-[15%]'>
                <Toggler
                    initialStatus={mentorship === 1}
                    render={({ status, toggleStatus }) => (
                        <CheckTick isChecked={status} className='hover:cursor-pointer' onClick={() => { toggleStatus(); handleMentorship() }} />
                    )}
                />
            </td>
            <td className='px-5 py-2 text-center items-center w-[10%]'>
                <Toggler
                    initialStatus={groupPurchase === 1}
                    render={({ toggleStatus, status }) => (
                        <CheckTick isChecked={status} className='hover:cursor-pointer' onClick={() => { toggleStatus(); handleGroupPurchase() }} />
                    )}
                />
            </td>
            <OfferReqStatus id={offerRequestRenovationContractor?.statusId} className='cursor-pointer w-[25%]' statusUpdateAt={offerRequestRenovationContractor?.statusUpdateAt} />
            <td className='pl-5 py-2 text-center items-center pr-3 w-[20%]'>
                <Space direction='vertical' className='align-middle'>
                    <ContractorDocument 
                        offerRequest={offerRequestRenovationContractor} 
                        offerRequestId={offerRequestId}
                        setShowDocumentModal={setShowDocumentModal}
                        setTabKey={setTabKey}
                    />
                    {offerRequestRenovationContractor?.status?.status === HouseOwnerListingStatus.houseVisitPlanned && (
                        <p className='text-sm'>{formatDateTime(offerRequestRenovationContractor?.visitPlanDateTime)}</p>
                    )}
                </Space>
            </td>
            {showWarning && <td className='absolute top-1/3 -right-16 pr-0 mr-8 cursor-pointer'><InfoIcon onClick={() => setShowWarningModal(true)} className='h-5 w-5' /></td>}
        </tr>
        {showWarningModal && (
            <WarningModal open={showWarningModal} setOpen={setShowWarningModal} />
        )}
               {showDocumentModal && (
                <TabsModal
                    logo={logo || "https://img.freepik.com/premium-vector/logo-design-construction-service-architecture_304830-442.jpg?w=2000"}
                    title={title}
                    open={showDocumentModal}
                    toggleOpen={() => { setShowDocumentModal(!showDocumentModal); setTabKey("1")}}
                    selectedItem={[offerRequestRenovationContractor]}
                    offerReqId={offerRequestId}
                    renovationId={renovation?.id}
                    simulation={simulation}
                    tabKey={tabKey}
                    simulationId={simulation?.id}
                    renovationKey={renovation?.key}
                    offerRequestSendAt={offerRequestSendAt}
                />
            )}
        </>

    )
}

export default ContractorRow
