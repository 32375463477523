import React, { FC, useState, useContext } from 'react'
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';

import HomeOwnerStatus from '../../shared/Table/HomeOwnerStatus'
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg"
import CheckCircle from '../../shared/elements/CheckCircle'
import HomeOwnerAction from '../../shared/Table/HomeOwnerAction'
import OfferRequestService from '../../api/OfferRequest'
import { UploadDocuments } from '../../components/modals/uploadDocuments'
import { UploadOfferte } from '../../components/modals/uploadOfferte'
import { UploadInvoice } from '../../components/modals/uploadInvoice'

import { UpdateHomeOwnerStatus } from '../../components/modals/updateHomeOwnerStatus'
import { GlobalContext } from '../../context/globalContext/globalContext'
import {HouseOwnerListingStatus, HouseOwnerListingStatusColors} from '../../models/applicationState'
import RequestQuoteDocument from './RequestQuoteDocument'
import { formatDate } from '../../utils/helpers'
import WarningModal from '../../components/modals/warningModal'
import { showWarningAfterDays } from '../../constants/general'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import TabsModal from '../../shared/Table/DetailsModal'
import OfferReqStatus from '../../shared/Table/OfferReqStatus'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
// import TabsModal from './DetailsModal';

interface ContractorRowProps {
    item: any,
    handleDetailsClick?: any,
    toggleWarningPopup?: any,
    client?: string,
    address?: string,
    setReload: any,
    offerRequestId?: string,
    renovationId?: string,
    offerRequestSendAt?: any,
    renovaiton?: any,
    archive: boolean,
    title?: any,
    simulationId?: string,
    renovationKey?: string,
    simulation?: any,
}

const RequestQuoteRow: FC<ContractorRowProps> = ({ toggleWarningPopup = () => { }, handleDetailsClick, item, title, offerRequestId, renovationId, address, client, setReload, offerRequestSendAt,archive, renovaiton, simulationId, renovationKey, simulation }) => {
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showOfferteModal, setShowOfferteModal] = useState(false);
    const [showInvoicemodal, setShowInvoiceModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [tabKey, setTabKey] = useState("1")

    const [showWarningModal, setShowWarningModal] = useState(false);
    const { state } = useContext(GlobalContext)

    const date = formatDate(item?.createdAt)
    const contractorId = item.contractorId

    const id = item?.statusId

    const offerAllStatus = state?.offerAllStatus?.filter((status: any) => status?.id === id)[0]
    // @ts-ignore
    const bgColor = HouseOwnerListingStatusColors[offerAllStatus?.status]
    const content = offerAllStatus?.status

    const handleArchiveItem = async (archiveState?: boolean) => {
        try {
            const payload: any = {
                offerRequestId,
                renovationId,
                contractorId,
                archiveContractor: (archiveState !== null && archiveState !== undefined) ? archiveState : true,
            }
            //@ts-ignore
            await OfferRequestService.updateOfferRequestContractor(offerRequestId, payload)
            setReload()
        } catch (error) {

        }
    }

    const handleDeleteItem = async () => {
        try {
            //@ts-ignore
            await OfferRequestService.deleteItem(item.id)
            setReload()
        } catch (error) {

        }
    }

    // for warning icon
    const { statusId } = item
    const { statusUpdateAt } = item
    let showWarning = false

    if (statusUpdateAt && statusId === '1') {
        // const offerRequestSendAtObj = new Date(statusUpdateAt)
        // const currentTime = new Date()
        // const expectedDifference = showWarningAfterDays * 24 * 60 * 60 * 1000
        // const timeDifference = currentTime.getTime() - offerRequestSendAtObj.getTime()
        // showWarning = timeDifference > expectedDifference


        // Current date (with time)
        const currentDateWithTime = new Date();
        // Set the time part to midnight (00:00:00)
        const currentDate:any = new Date(currentDateWithTime.getFullYear(), currentDateWithTime.getMonth(), currentDateWithTime.getDate());

        // Date to subtract (with time)
        const dateToSubtractWithTime = new Date(statusUpdateAt);
        // Set the time part to midnight (00:00:00)
        const dateToSubtract:any = new Date(dateToSubtractWithTime.getFullYear(), dateToSubtractWithTime.getMonth(), dateToSubtractWithTime.getDate());

        // Calculate the time difference in days
        const timeDifferenceInMilliseconds = currentDate - dateToSubtract;
        const timeDifferenceInDays = timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24);
        if(timeDifferenceInDays>10){
            showWarning = true;
        }
    }
    const items: any = !archive ? [
        {
            label:
                <HomeOwnerAction
                    handleArchiveItem={handleArchiveItem}
                    item={item}
                    setShowStatusModal={setShowStatusModal}
                    setShowUploadModal={setShowUploadModal}
                    setShowOfferteModal={setShowOfferteModal}
                    setShowInvoiceModal={setShowInvoiceModal}
                    setReload={setReload}
                />,
            key: '0',
        },
        {
            label: 
            <div className="w-full fles items-center justify-center" onClick={() => handleArchiveItem(!archive)}>
                {archive ? "Archiveren ongedaan maken" : "Archiveren"}
            </div>,
            key: '1',
        },
        {
            label: <div onClick={handleDeleteItem} className="text-[red] w-full">Verwijderen</div>,
            key: '3',
        },
    ] : [
        {
            label:
                <HomeOwnerAction
                    handleArchiveItem={handleArchiveItem}
                    item={item}
                    setShowStatusModal={setShowStatusModal}
                    setShowUploadModal={setShowUploadModal}
                    setShowOfferteModal={setShowOfferteModal}
                    setShowInvoiceModal={setShowInvoiceModal}
                    setReload={setReload}
                />,
            key: '0',
        },
        {
            label: 
            <div className="w-full fles items-center justify-center text-center" onClick={() => handleArchiveItem(!archive)}>
                {archive ? "Archiveren ongedaan maken" : "Archiveren"}
            </div>,
            key: '1',
        },
        {
            label: <div onClick={handleDeleteItem} className="text-[red] w-full">Verwijderen</div>,
            key: '3',
        },
    ]


    return (
        <>
            <tr className='border-0 border-b relative h-full'>
                <td className='px-5 py-2 items-center w-[20%]'>
                    <div className='flex items-center w-full'>
                        <img src={renovaiton?.logo} alt='' className='h-[40px] object-contain' />
                        <p className="ml-1 mt-1 font-semibold">{renovaiton?.title}</p>
                        <FontAwesomeIcon
                            icon={faEllipsisVertical as IconProp}
                            onClick={()=> setShowDetailsModal(true)}
                            className="w-5 h-5 mt-1 cursor-pointer"
                        />

                    </div>
                </td>
                <td className='px-5 py-2 text-center items-center w-[15%]'>{item?.contractor?.name}</td>
                <td className='px-5 py-2 text-center items-center w-[10%]'>{date}</td>
                <td className='px-5 py-2 w-[10%]'><CheckCircle status={item?.mentorship} /></td>
                <td className='px-5 py-2 text-center items-center w-[10%]'><CheckCircle status={item?.groupPurchase} /></td>
                <OfferReqStatus id={item?.status?.id} className='cursor-default w-[20%]' statusUpdateAt={item?.statusUpdateAt}/>
                <td className='w-[10%] h-full px-[10px] py-[10px]'>
                    <RequestQuoteDocument
                        renovaiton={renovaiton}
                        item={item}
                        setShowUploadModal={setShowUploadModal}
                        archive={archive}
                        setShowDocumentModal={setShowDetailsModal}
                        setTabKey={setTabKey}
                    />
                </td>
                <td className='w-[10%] h-full'>
                    <div className = "w-full h-full flex justify-center items-center ">
                        <Dropdown menu={{ items }} trigger={['click']}>
                            <a onClick={(e) => e.preventDefault()}>
                                <FontAwesomeIcon
                                    icon={faEllipsisVertical as IconProp}
                                    className="w-5 h-5 mt-1 cursor-pointer"
                                />
                            </a>
                        </Dropdown>
                    </div>

                </td>
                {showWarning && <td className='absolute top-1/3 -right-16 pr-0 mr-8 cursor-pointer'><InfoIcon onClick={() => setShowWarningModal(true)} className='h-5 w-5' /></td>}
            </tr>
            {showDetailsModal && (
            <TabsModal
                title={renovaiton?.title}
                logo={renovaiton?.logo}
                open={showDetailsModal}
                selectedItem={[item]}
                toggleOpen={() => { setShowDetailsModal(!showDetailsModal); setTabKey("1")}}
                offerReqId={offerRequestId}
                renovationId={renovationId}
                tabKey={tabKey}
                simulationId={simulationId}
                renovationKey={renovationKey}
                simulation={simulation}
                offerRequestSendAt={offerRequestSendAt}
            />
            )}

            {showUploadModal && (
                <UploadDocuments
                    setReload={setReload}
                    open={showUploadModal}
                    client={client}
                    title={renovaiton?.title}
                    address={address}
                    item={item}
                    setOpen={setShowUploadModal}

                />
            )}

             {showOfferteModal && (
                <UploadOfferte
                    setReload={setReload}
                    open={showOfferteModal}
                    client={client}
                    title={title}
                    address={address}
                    item={item}
                    setOpen={setShowOfferteModal}
                    setShowStatusModal={setShowStatusModal}

                />
            )}
            {showInvoicemodal && (
                <UploadInvoice
                    setReload={setReload}
                    open={showInvoicemodal}
                    client={client}
                    title={title}
                    address={address}
                    item={item}
                    setOpen={setShowInvoiceModal}
                    setShowStatusModal={setShowStatusModal}

                />
            )}
            {showStatusModal && (
                <UpdateHomeOwnerStatus
                    setReload={setReload}
                    open={showStatusModal}
                    renovationId={renovationId}
                    offerRequestId={offerRequestId}
                    id={id}
                    nextStatus={parseInt(id) + 1}
                    item={item}
                    title={renovaiton?.title}
                    address={address}
                    color={bgColor}
                    setOpen={setShowStatusModal}
                    status={content}
                />
            )}
            {showWarningModal && (
                <WarningModal open={showWarningModal} setOpen={setShowWarningModal} />
            )}
        </>
    )
}

export default RequestQuoteRow
