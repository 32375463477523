import create from 'zustand';
import { CheckBoxData } from './modal/AddContractorInterface';

export interface CityInterface {
    title: string;
    active: boolean;
    id: number;
    rId: number;
    cityId: number;
}

interface CityStore {
  rendering: boolean;
  cityData: Array<CityInterface>;
  updateCityData: Array<CityInterface>;
  provinceData: Dictionary<{name: string, id: number}[]>;
  updateProvinceData: Dictionary<{name: string, id: number}[]>;
  allData: Array<number>;
  CityChecked: Dictionary<CheckBoxData>;
  updateCityChecked: Dictionary<CheckBoxData>;
  setCityData: (value: Array<CityInterface>) => void;
  setProvinceData: (value: Dictionary<{name: string, id: number}[]>) => void;
  setUpdateCityData: (value: Array<CityInterface>) => void;
  setUpdateProvinceData: (value: Dictionary<{name: string, id: number}[]>) => void;
  setRendering: (value: boolean) => void;
  setCityChecked: (value: Dictionary<CheckBoxData>) => void;
  setUpdateCityChecked: (value: Dictionary<CheckBoxData>) => void;
}

const useCityStore = create<CityStore>((set) => ({
  rendering: false,
  cityData: [],
  provinceData: {},
  updateCityData: [],
  updateProvinceData: {},
  allData: [],
  CityChecked: {},
  updateCityChecked: {},
  setCityData: (value: Array<CityInterface>) => set({ cityData: value }),
  setProvinceData: (value: Dictionary<{name: string, id: number}[]>) => set({ provinceData: value }),
  setAllData: (value: Array<number>) => set({allData: value}),
  setRendering: (value: boolean) => set({rendering: value}),
  setUpdateCityData: (value: Array<CityInterface>) => set({ updateCityData: value }),
  setUpdateProvinceData: (value: Dictionary<{name: string, id: number}[]>) => set({ updateProvinceData: value }),
  setCityChecked: (value: Dictionary<CheckBoxData>) => set({ CityChecked: value }),
  setUpdateCityChecked: (value: Dictionary<CheckBoxData>) => set({ updateCityChecked: value }),
}));

export default useCityStore;
