import { useState, useCallback } from "react"
import ContractorService from "../../api/Contractors"
import { ContractorInterface } from "../../models/applicationState"
import useCityStore from "../../components/modals/CityStore"

const useContractor = () => {
    const [allContractors, setAllContractors] = useState<ContractorInterface[]>([])
    const [loading, setLoading] = useState<null | boolean>(null)
    const [errorMessage, setErrorMessage] = useState<null | boolean | string>(null)
    const { rendering } = useCityStore()

    const updateContractorStatus = useCallback(async (id: String) => {
        try {
            await ContractorService.toggleStatus(id)
        } catch (error) {

        }
    }, [allContractors])
    const deleteContractor = useCallback(async (id: String) => {
        try {
            await ContractorService.delete(id)
        } catch (error) {

        }
    }, [allContractors])
    const loadContractors = useCallback( async() => {
        setLoading(true)
        try {
            const { data } = await ContractorService.getAll()
            setAllContractors(data)
        } catch (error) {
            setErrorMessage("Something went wrong.")
        }
        setLoading(false)
    }, [rendering])

    return {
        allContractors,
        loadContractors,
        loading,
        errorMessage,
        updateContractorStatus,
        deleteContractor
    }
}

export default useContractor
