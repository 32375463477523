import React, { useState, useEffect } from "react";

type ProgressBarProps = {
    color?: string;
    pageData?: {
        category_id?: number,
        category_progress?: number
    }
};

const ProgressBar = ({ color = "#398284", pageData }: ProgressBarProps) => {
    const [ progressBarState, setProgressBarState ] = useState({
        typologyProgress: 0,
        heatingProgress: 0,
        isolationProgress: 0,
        solarProgress: 0
    })

    useEffect(() => {
        if(pageData) {
            const { category_id, category_progress } = pageData;
            if(category_id === 1) {
                setProgressBarState({
                    typologyProgress: category_progress || 0,
                    heatingProgress: 0,
                    isolationProgress: 0,
                    solarProgress: 0
                })
            }
            else if(category_id === 2) {
                setProgressBarState({
                    typologyProgress: 100,
                    heatingProgress: category_progress || 0,
                    isolationProgress: 0,
                    solarProgress: 0
                })
            }
            else if(category_id === 3) {
                setProgressBarState({
                    typologyProgress: 100,
                    heatingProgress: 100,
                    isolationProgress: category_progress || 0,
                    solarProgress: 0
                })
            }
            else if(category_id === 4) {
                setProgressBarState({
                    typologyProgress: 100,
                    heatingProgress: 100,
                    isolationProgress: 100,
                    solarProgress: category_progress || 0
                })
            }
        }
    }, [pageData])

    return(
        <div className = "w-full h-[49px] flex overflow-x-auto">
            <div className = "min-w-[800px] w-full h-full flex">
                <div className = {`h-full flex flex-1 justify-center items-center relative overflow-hidden`}>
                    <div className = "absolute h-full w-full top-0 left-0 primary-main opacity-[0.77]" />
                    <div className = {`w-full h-full absolute top-0 primary-main`} style = {{right: `${100 - progressBarState.typologyProgress}%`}} />
                    <p className = "font-sans font-bold text-[20px] text-white relative">Huis</p>
                </div>
                <div className = "w-[5px]" />
                <div className = {`h-full flex flex-1 justify-center items-center relative overflow-hidden`}>
                    <div className = "absolute h-full w-full top-0 left-0 primary-main opacity-[0.77]" />
                    <div className = {`w-full h-full absolute top-0 primary-main`} style = {{right: `${100 - progressBarState.heatingProgress}%`}} />
                    <p className = "font-sans font-bold text-[20px] text-white relative">Verwarming</p>
                </div>
                <div className = "w-[5px]" />
                <div className = {`h-full flex flex-1 md:flex-[2] justify-center items-center relative overflow-hidden`}>
                    <div className = "absolute h-full w-full top-0 left-0 primary-main opacity-[0.77]" />
                    <div className = {`w-full h-full absolute top-0 primary-main`} style = {{right: `${100 - progressBarState.isolationProgress}%`}} />
                    <p className = "font-sans font-bold text-[20px] text-white relative">Isolatie & Ventilatie</p>
                </div>
                <div className = "w-[5px]" />
                <div className = {`h-full flex flex-1 justify-center items-center relative overflow-hidden`}>
                    <div className = "absolute h-full w-full top-0 left-0 primary-main opacity-[0.77]" />
                    <div className = {`w-full h-full absolute top-0 primary-main`} style = {{right: `${100 - progressBarState.solarProgress}%`}} />
                    <p className = "font-sans font-bold text-[20px] text-white relative">Zonnepanelen & Energie</p>
                </div>
            </div>
        </div>
    )
}

export default ProgressBar;