import React, { FC } from 'react'
import { ReactComponent as PdfIcon } from "../../assets/icons/pdf-color.svg"
import { HomeOwnerDocumentInterface } from '../../models/applicationState'
// import ViewDocument from '../../components/modals/viewDocument';

const HomeOwnerDocument: FC<HomeOwnerDocumentInterface> = ({ item,offerRequestId, setShowDocumentModal, setTabKey }) => {
    let { cpOfferRequestRenovationContractorDocuments: documents = [] } = item

    // filter documents
    // 4 -> Offerte
    // 5 -> Factuur
    // documents = documents?.filter((document: any) => [4, 5]?.includes(document?.documentTypeId))

    const openDocumentPreviewModal = (document: any) => {
        setTabKey("4")
        setShowDocumentModal(true)
    }

    return (<>
        <div className='flex align-middle justify-center gap-2'>
            <>{documents?.map((document: any) => (
                <PdfIcon className='cursor-pointer h-5 w-5' key={document?.id} title={document?.description} onClick={() => openDocumentPreviewModal(document)} />
            ))}
            </>
        </div>
    </>
    )
}

export default HomeOwnerDocument
