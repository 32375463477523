import React, {FC} from "react";
import {Col, Row} from "antd";

import HouseOne from "../../../assets/picture/hp/house.png";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg"

interface documentStruct {}

const Files4: FC<documentStruct> = () => {
    return (
        <div className="mt-4">
            <Row>
                <Col md={24}>
                    <h1 className="text-3xl sm:text-3xl md:text-3xl xl:text-3xl  2xl:text-3xl font-semibold border-hasselt">
                        Maak je huis klaar voor morgen
                    </h1>
                    <h2 className="text-md pt-2">
                        Dit is je persoonlijk plan met verbeteringen voor je huis. Wil je hier graag verder op ingaan? Boek een advies bij de Energiecentrale.
                    </h2>
                </Col>
            </Row>

            <Row className="pt-8">
                <Col md={6} className="pr-2">
                    <Row>
                        <Col md={10}>
                            <img src={HouseOne} alt="House's logo" className = "h-[100px]" />
                        </Col>
                        <Col md={14}>
                            <h1 className="pt-8 text-2xl sm:text-lg md:text-2xl xl:text-2xl  2xl:text-3xl font-semibold border-hasselt">
                                Hoofddak
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24}>
                            <h2 className="text-md pt-2">
                                Een verbetering dringt zich op. Ontdek nu ons adviesvoor het aanpakken vanhoofddak.
                            </h2>
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col md={4}>
                            <div style={{ width: 28, height: 28, borderRadius: 28, background: '#FB7A7C 0% 0% no-repeat padding-box' }} />
                        </Col>
                        <Col md={20}>
                            <h1 className="text-lg font-semibold border-hasselt">
                                Licht geïsoleerd
                            </h1>
                        </Col>
                    </Row>
                </Col>
                <Col md={18} className="text-[#4F4C4C] pl-6" style={{ borderLeft: '1px solid #62B151' }}>
                    <h1 className="text-md sm:text-md md:text-lg xl:text-lg  2xl:text-xl font-semibold border-hasselt">
                        Meer info bij ons advies
                    </h1>
                    <p className="text-md pt-2">
                        Isoleer meteen voldoende, zo zit je goed voor de toekomst. Een goeie dakisolatie heeft een Rwaarde van minimum 4,7 m²k/W, maar liefst 6,3 m²k/W of zelfs 7,9 m²k/W en bestaat uit drie lagen:
                    </p>
                    <p className="text-md pt-4">
                        <li>een winddicht onderdak</li>
                        <li>isolatie</li>
                        <li>een damprem. <strong>Na-isoleren? Misschien goedkoper!</strong></li>
                    </p>

                    <p className="text-md pt-4">
                        De simulatie in Check je huis berekent de prijs voor het plaatsten van nieuwe isolatie waarbij de (eventuele) bestaande isolatie wordt verwijderd. Je hoeft de bestaande isolatie echter niet noodzakelijk te verwijderen. Je kan ook een laag bijplaatsen tot de som van de isolatielagen een voldoende R-waarde heeft.
                    </p>

                    <h1 className="mt-8 text-md sm:text-md md:text-lg xl:text-lg  2xl:text-xl font-semibold border-hasselt">
                        Opgelet
                    </h1>
                    <p className="text-md pt-2">
                        De premiesimulatie in Check je huis is gebaseerd op gemiddelden. Bekijk hier <a href="">meer informatie over de premies</a> .
                    </p>
                </Col>
            </Row>

            <Row className="pt-12">
                <Col md={6} className="pr-2">
                    <Row>
                        <Col md={10}>
                            <img src={HouseOne} alt="House's logo" className = "h-[100px]" />
                        </Col>
                        <Col md={14}>
                            <h1 className="pt-8 text-2xl sm:text-lg md:text-2xl xl:text-2xl  2xl:text-3xl font-semibold border-hasselt">
                                Bijdak
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24}>
                            <h2 className="text-md pt-2">
                                Een verbetering dringt zich op. Ontdek nu ons adviesvoor het aanpakken vanhoofddak.
                            </h2>
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col md={4}>
                            <div style={{ width: 28, height: 28, borderRadius: 28, background: '#FB7A7C 0% 0% no-repeat padding-box' }} />
                        </Col>
                        <Col md={20}>
                            <h1 className="text-lg font-semibold border-hasselt">
                                Niet geïsoleerd
                            </h1>
                        </Col>
                    </Row>
                </Col>

                <Col md={18} className="text-[#4F4C4C] pl-6" style={{ borderLeft: '1px solid #62B151' }}>
                    <Row>
                        <Col md={24} className="w-full px-8 pt-2 pb-8" style={{ borderRadius: 10, background: '#2987520D 0% 0% no-repeat padding-box' }}>
                            <h1 className="text-2xl sm:text-lg md:text-2xl xl:text-2xl  2xl:text-3xl font-semibold border-hasselt">
                                Bijdak
                            </h1>

                            <Row>
                                <Col md={12}>
                                    <h2 className="text-md pt-2">
                                        Oppervlakte te isoleren dak: 53 m²
                                    </h2>

                                    <Row className="pt-4">
                                        <Col md={4}>
                                            <div style={{ width: 28, height: 28, borderRadius: 28, background: '#4FC784 0% 0% no-repeat padding-box' }} />
                                        </Col>
                                        <Col md={20}>
                                            <h1 className="text-lg font-semibold border-hasselt">
                                                Goed geïsoleerd
                                            </h1>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={12}>
                                    <Row>
                                        <Col md={12}>
                                            <p style={{ whiteSpace: 'nowrap' }}>Ik investeer</p>
                                            <p style={{ whiteSpace: 'nowrap' }}>Ik ontvang premies</p>
                                            <p style={{ whiteSpace: 'nowrap' }}>Ik verbruik minder energie</p>
                                            <b style={{ whiteSpace: 'nowrap' }}>Ik bespaar</b>
                                        </Col>
                                        <Col md={12} style={{ textAlign: 'right' }}>
                                            <p style={{ whiteSpace: 'nowrap' }}>€ 3922</p>
                                            <p style={{ whiteSpace: 'nowrap' }}>€ 424</p>
                                            <p style={{ whiteSpace: 'nowrap' }}>7147 kWh / jaar</p>
                                            <b style={{ whiteSpace: 'nowrap' }}>€ 1001 / jaar</b>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mt-6">
                        <Col md={24}>
                            <h1 className="text-md sm:text-md md:text-lg xl:text-lg  2xl:text-xl font-semibold border-hasselt">
                                Meer info bij ons advies
                            </h1>
                            <p className="text-md pt-2">
                                Isoleer meteen voldoende, zo zit je goed voor de toekomst. Een goeie dakisolatie heeft een Rwaarde van minimum 4,7 m²k/W, maar liefst 6,3 m²k/W of zelfs 7,9 m²k/W en bestaat uit drie lagen:
                            </p>
                            <p className="text-md pt-4">
                                <li>een winddicht onderdak</li>
                                <li>isolatie</li>
                                <li>een damprem. <strong>Na-isoleren? Misschien goedkoper!</strong></li>
                            </p>

                            <p className="text-md pt-4">
                                De simulatie in Check je huis berekent de prijs voor het plaatsten van nieuwe isolatie waarbij de (eventuele) bestaande isolatie wordt verwijderd. Je hoeft de bestaande isolatie echter niet noodzakelijk te verwijderen. Je kan ook een laag bijplaatsen tot de som van de isolatielagen een voldoende R-waarde heeft.
                            </p>

                            <h1 className="mt-8 text-md sm:text-md md:text-lg xl:text-lg  2xl:text-xl font-semibold border-hasselt">
                                Opgelet
                            </h1>
                            <p className="text-md pt-2">
                                De premiesimulatie in Check je huis is gebaseerd op gemiddelden. Bekijk hier <a href="">meer informatie over de premies</a> .
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="pt-12">
                <Col md={6} className="pr-2">
                    <Row>
                        <Col md={10}>
                            <img src={HouseOne} alt="House's logo" className = "h-[100px]" />
                        </Col>
                        <Col md={14}>
                            <h1 className="pt-8 text-2xl sm:text-lg md:text-2xl xl:text-2xl  2xl:text-3xl font-semibold border-hasselt">
                                Ramen Leefruimte
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24}>
                            <h2 className="text-md pt-2">
                                Een verbetering dringt zich op. Ontdek nu ons advies voor het aanpakken van ramen leefruimte.
                            </h2>
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col md={4}>
                            <div style={{ width: 28, height: 28, borderRadius: 28, background: '#FB7A7C 0% 0% no-repeat padding-box' }} />
                        </Col>
                        <Col md={20}>
                            <h1 className="text-lg font-semibold border-hasselt">
                                Dubbel glas
                            </h1>
                        </Col>
                    </Row>
                </Col>

                <Col md={18} className="text-[#4F4C4C] pl-6" style={{ borderLeft: '1px solid #62B151' }}>
                    <h1 className="text-2xl sm:text-lg md:text-2xl xl:text-2xl  2xl:text-3xl font-semibold border-hasselt">
                        Bijdak
                    </h1>

                    <h1 className="text-md sm:text-md md:text-lg xl:text-lg  2xl:text-xl font-semibold border-hasselt">
                        Meer info bij ons advies
                    </h1>

                    <p className="text-md pt-4">
                        <b>Renoveer voor de toekomst: ga meteen voor driedubbel glas.</b>
                        <br/>
                        Dit houdt tot drie keer meer warmte binnen dan dubbel glas, een kleine extra investering die veel opbrengt!
                        <br/>
                        <b>Raamroosters?</b> Heb je geen ventilatiesysteem, voorzie dan zeker raamroosters voor voldoende ventilatie.
                        <br/>
                        <br/>
                        <br/>
                        <b>Let ook op je raamkaders</b> Kies voor isolerende kaders en zorg ervoor dat ze goed geplaatst worden. Het heeft immers weinig zin geld uit te geven aan superisolerend glas als de warmte via de kozijnstijlen naar buiten raakt.
                        <br/>
                        <br/>
                        <br/>
                        <b>Last van straatlawaai?</b> Vraag je aannemer om asymmetrisch glas, zo hou je de warmte binnen en het lawaai buiten.
                        <br/>
                        <br/>
                        <br/>
                        <b>Zonnige gevel?</b> Als je hier geen groen kan planten dat voor schaduw zorgt, overweeg dan zonnescreens langs de buitenzijde.
                    </p>

                    <Row style={{ borderRadius: 10, background: '#2987520D 0% 0% no-repeat padding-box' }} className="mt-6">
                        <Col md={2}>
                            <div className="pt-4 pl-6 cursor-pointer">
                                <InfoIcon className='h-10 w-10 border-[#298752]' />
                            </div>
                        </Col>
                        <Col md={22} className="w-full px-8 py-4">
                            <p>
                                Tip: Vervang je je ramen? Dan is het een goed idee om asymmetrisch glas te plaatsen. Dat is niet duurder in aankoop, maar heeft als bijkomend voordeel dat het akoestisch isoleert. Zo hou je de warmte binnen en het verkeerslawaai buiten.                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </div>
    )
};

export default Files4;

