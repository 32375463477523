import React, { useState, useEffect } from "react";
import TitleBar from "../titleBar";
import { useNavigate } from "react-router-dom";
import SubmitBox from "../submitBox";
import InputText from "../inputText";
import Box from "../box";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal";
// image imports here
import abestos from "../../../assets/extra/huis-abestos.png"

type AsbestProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    }
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const Asbest = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: AsbestProps) => {
    const [ boxHover, setBoxHover ] = useState(0);
    const [ activeBox, setActiveBox ] = useState("");
    const [ inputValue, setInputValue ] = useState("");
    const [ hideInput, sethideInput ] = useState(true);
    const [ modalState, setModalState ] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("33");
        const localStateJaar = getLocalStateValue("2");

        if(localStateJaar.answerNo == 4){
            navigate(pageData?.next_url || "")
        }

        if(localState) {
            if(localState?.asbest_input) {
                setActiveBox(localState.answerNo);
                setInputValue(localState?.asbest_input);
                sethideInput(false)
            }
            else {
                setActiveBox(localState.answerNo);
            }
        }
    }, [])

    const goBack = () => {
        navigate(pageData?.prev_url || "")
    }


    const onClick = (answer?: string, answerIndex?: string) => {
        const question = "asbest";

        if(Number(answerIndex )== 0){
            setActiveBox("0");
            sethideInput(false)
        }else{
            sethideInput(true)
            updateAnswer(question || "", answer || "", pageData?.next_url);
            storeAnswer({"answerNo": answerIndex, "asbest": answer,"asbest_input": "",image:abestos}, "33")
        }

    }

    const onInputSubmit = () => {
        const question = "asbest_input";
        updateAnswer(question || "", inputValue || "", pageData?.next_url);
        storeAnswer({"answerNo": "0", "asbest": "Ja","asbest_input": inputValue, image:abestos}, "33");
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {pageData?.back_text} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "w-full flex flex-col mt-[10px] mb-[17px]">
                    <div className = "w-full flex sm:flex-row flex-col border-b-[1px] border-b-[#F0F5F5]">
                        <Box flex = {1} hasRightBorder text = "Ja" hoverValue = {1} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} image = "" answerIndex = "0" isActive = {activeBox === "0"} />
                        <Box flex = {1} text = "Neen" hoverValue = {2} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} image = "" answerIndex = "1" isActive = {activeBox === "1"} />
                        <Box flex = {1} text = "Ik weet het niet" hoverValue = {3} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} image = "" answerIndex = "2" isActive = {activeBox === "2"} />
                    </div>
                </div>
                {
                    !hideInput &&
                    <SubmitBox isButtonActive = {inputValue?.length > 0} onSubmit = {onInputSubmit}>
                    <InputText value = {inputValue} setValue = {setInputValue} title = "Waar bevindt zich asbest in de woning?" description = "" marginLeft = {window.screen.width < 1000 ? "0px" : "138px"} enablePlaceholder = {false} />
                    </SubmitBox>
                }
            
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default Asbest;
