const Reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_SESSION_DATA":
      return {
        ...state,
        session: action.payload,
      };
    case "SET_REDIRECT_FORM":
        return {
          ...state,
          redirection: action.payload,
        };
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };
    case "SET_MODAL":
      return {
        ...state,
        modal: action.payload,
      };
    case "SET_HOMEPAGE_FORM_DATA":
      return {
        ...state,
        homePageForm: action.payload,
      };
    case "SET_ALL_HOUSES_TYPES":
      return {
        ...state,
        allHousesTypes: action.payload,
      };
    case "SET_FORM_STEP_DATA":
      return {
        ...state,
        stepData: { ...state?.stepData, ...action.payload },
      };
    case "SET_STEP_TWO_SELCETEDHOUSE":
      return {
        ...state,
        stepTwoSelectedHouse: action.payload,
      };
    case "SET_VISITED_USER_DATA":
      return {
        ...state,
        visitedUserData: action.payload,
      };
    case 'SET_OFFER_REQUEST_ALL_STATUS':
      return {
        ...state,
        offerAllStatus: action.payload,
      };
    case 'SET_ACTIVE_SIMULATION':
      console.log("changing simulation")
      return {
        ...state,
        simulationId: action.payload,
      };
    case 'SET_SIMULATION_INIT_DONE':
      return {
        ...state,
        simulationInitDone: action.payload,
      }
    case 'SET_HOME_OWNER_LIST':
      return {
        ...state,
        homeOwnerList: action.payload.homeOwnerList,
        total: action.payload.total
      }
    case 'SET_ALL_CONTRACTORS_LIST':
      return {
        ...state,
        allContractorsList: action.payload.allContractorsList,
        total: action.payload.total
      }
    case 'SET_FILTERS_DATA':
      return {
        ...state,
        filtersData: {
          ...state.filtersData,
          [action.payload.key]: action.payload.data
        }
      }
    default:
      return state;
  }
};
export default Reducer;
