import HttpService from "../constants/HttpService";

class PdfService extends HttpService {
    constructor() {
        super('pdf');
    }

    // additional services

    getDom = async (hash: String) => {
        const response = await this.client.get(`${this.endPoint}/get/${hash}`)
        return response
    }

    // // get all contractors-admin
    // getAllAdmin = async () => {
    //     const response = await this.client.get(`${this.endPoint}/admin/`)
    //     return response
    // }


}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PdfService()
