import React, {FC, useState, useEffect, useCallback} from "react";
import {ModalBox} from "../../shared/modals/modal";
import {Email, Input} from "../../shared/forms/input";
import {Tag} from "../../shared/elements/tag";
import { CloseCircleOutlined } from '@ant-design/icons';
import {CheckBox} from "../../shared/elements/Checbox";
import { ModalBoxCheckBox } from "../../shared/elements/ModalBoxCheck";
import {Button, Col, Form, Row, Select, Spin, } from "antd";
import {PrimaryButton} from "../../shared/buttons/igemo_button";
import {GetAPIService, PostAPIService, PostFormAPIService} from "../../context/services";
import { City, Province } from "./modal/Province";
import { CityModalBox } from "./cityModal";
import useCityStore from "./CityStore";
import { ServicesModal } from "./modal/Services";
import { Label } from "./modal/Label";
import { Certificate } from "./modal/Certificate";
import { LabelModalBox } from "./labelModal";
import useLabelStore from "./labelStore";
import useCertificateStore from "./certificateStore";
import { CertificateModalBox } from "./certificateModal";
import {AddressBarModal as AddressBar} from "../../shared/AddressBarModal";
import { AddContractorModalStruct, ServiceShow, CheckBoxData } from "./modal/AddContractorInterface";
import { debounce } from "lodash";
import Joi from "joi";

declare global {
    type Dictionary<T> = { [key: string]: T };
    type ProvinceSelection = { active: boolean; label: string };
    type DropDown = {label: string, value: string}
    type UpdateDictionary<T> = { [key: string]: T };
    type UpdateProvinceSelection = { active: boolean; label: string };
    type UpdateDropDown = {label: string, value: string}
}

const contractorSchema = Joi.object({
    firma: Joi.string()
    .required()
    .messages({
        'string.empty': `Firma mag niet leeg zijn.`,
        'any.required': `Firma is een verplicht veld.`
    }),
    btw: Joi.string()
    .required()
    .messages({
        'string.empty': `BTW Nummer mag niet leeg zijn.`,
        'any.required': `BTW Nummer is een verplicht veld.`
    }),
    emailcontractor: Joi.string()
        .pattern(/^\w+([\w+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        .required()
        .messages({
            'string.empty': `E-mail mag niet leeg zijn`,
            'any.required': `E-mail is een verplicht veld.`,
            'string.pattern.base': `Ongeldig e-mailadres.`
        }),
    phonecontractor: Joi.string()
        .pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
        .required()
        .min(6)
        .messages({
            'string.empty': `Telefoonnummer mag niet leeg zijn`,
            'any.required': `Telefoonnummer is een verplicht veld.`,
            'string.pattern.base': `Ongeldig telefoonnummer.`,
            'string.min': `Het telefoonnummer moet een minimale lengte van {#limit} cijfers hebben`,
        }),
    description: Joi.string()
        .required()
        .messages({
            'string.empty': `Beschrijving mag niet leeg zijn.`,
            'any.required': `Beschrijving is een verplicht veld.`
        }),
    name: Joi.string()
        .required()
        .messages({
            'string.empty': `Achternaam mag niet leeg zijn.`,
            'any.required': `Achternaam is een verplicht veld.`
        }),
    firstname: Joi.string()
        .required()
        .messages({
            'string.empty': `Voornaam mag niet leeg zijn.`,
            'any.required': `Voornaam is een verplicht veld.`
        }),
    emailUser: Joi.string()
        .pattern(/^\w+([\w+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        .required()
        .messages({
            'string.empty': `E-mail mag niet leeg zijn`,
            'any.required': `E-mail is een verplicht veld.`,
            'string.pattern.base': `Ongeldig e-mailadres.`
        }),
    phone: Joi.string()
        .pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
        .required()
        .min(6)
        .messages({
            'string.empty': `Telefoonnummer mag niet leeg zijn`,
            'any.required': `Telefoonnummer is een verplicht veld.`,
            'string.pattern.base': `Ongeldig telefoonnummer.`,
            'string.min': `Het telefoonnummer moet een minimale lengte van {#limit} cijfers hebben`,
        }),
})

export const AddContractorModal: FC<AddContractorModalStruct> =
    ({
        open,
        setOpen,
        setInfoModal
    }) => {

    const [RegionID, setRegionID] = useState<number>()
    const [fileUploadState, setFileUploadState] = useState<File>()
    const [provincesSelection, setProvincesSelection] = useState<Dictionary<ProvinceSelection>>({})
    const [servicesSelection, setServicesSelection] = useState<Dictionary<ServiceShow>>({})
    const [rerender, setRerender] = useState(false) // Bad Temp Solution
    const {
        cityData,
        setCityData,
        provinceData,
        setProvinceData,
        rendering,
        setRendering,
        setCityChecked,
        CityChecked
    } = useCityStore()
    const [allLabel, setAllLabel] = useState<Array<DropDown>>()
    const [selectedValues, setSelectedValues] = useState<Array<number>>([]);
    const [allCertificate, setAllCertificate] = useState<Array<DropDown>>()
    const [selectedValuesCertificate, setSelectedValuesCertificate] = useState<Array<number>>([]);
    const [loading, setLoading] = useState(false);
    const {setLabel} = useLabelStore()
    const {setCertificate} = useCertificateStore()
    const [location, setLocation] = useState<{zip: string, city: string, cityid: string}>()
    const [street, setStreet] = useState<{id: string, street: string}>()
    const [error, setError] = useState({
        name : "",
        firma : "",
        emailUser : "",
        phone : "",
        firstname : "",
        phonecontractor : "",
        emailcontractor : "",
        btw : "",
        description : "",
        city : "",
        street : "",
        service : "",
        allCities : "",
        logo : ""
    })
    const [isErrorContractorApi, setIsErrorContractorApi] = useState(false)
    const [contractorResponse, setContractorResponse] = useState<any>(null)
    const form = Form.useForm()[0]
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {}, [fileUploadState, CityChecked])

    useEffect(() => {
        setSelectedValues([])
        setSelectedValuesCertificate([])
    }, [open])

    useEffect(() => {
        setFileUploadState(undefined)
        form.resetFields()
        setCityData([])
        setProvinceData({})
        const fetchData = async () => {
          try {
            const provinceResponse = await GetAPIService('geo/region/');
            const provinces = provinceResponse.data as [Province];

            const selection: { [key: string]: { active: boolean, label: string } } = {};
            const checkbox: Dictionary<CheckBoxData> = {}
            provinces.forEach((value: Province) => {
              selection[value.nl] = {active: false, label: value.id};
              checkbox[value.id] = {
                id: value.id,
                checked: false
               }
            });
            setCityChecked(checkbox)
            setProvincesSelection(selection);
          } catch (error) {
            // Handle error if API request fails
            console.error('Error fetching provinces:', error);
          }
        };

        fetchData();
      }, [open]);

    useEffect(() => {
        const fetchData = async () => {
            try {
              const provinceResponse = await GetAPIService('contractor/list/label/');
              const provinces = provinceResponse.data as [Label];

              const selection: Array<DropDown> = []
              provinces.forEach((value: Label) => {
                selection.push({value: value.id, label: value.label})
              });

              setAllLabel(selection);
            } catch (error) {
              // Handle error if API request fails
              console.error('Error fetching provinces:', error);
            }
          };

          fetchData();
    }, [rendering, open])


    useEffect(() => {
        const fetchData = async () => {
            try {
              const provinceResponse = await GetAPIService('contractor/list/certificat/');
              const provinces = provinceResponse.data as [Certificate];

              const selection: Array<DropDown> = []
              provinces.forEach((value: Certificate) => {
                selection.push({value: value.id, label: value.certificat})
              });

              setAllCertificate(selection);
            } catch (error) {
              // Handle error if API request fails
              console.error('Error fetching provinces:', error);
            }
          };

          fetchData();
    }, [open, rendering])

      const CityUpdate = useCallback(() => {
        const fetchData = async () => {
          try {
            const selector: Array<DropDown> = [];

            await Promise.all(
              Object.keys(provincesSelection).map(async (province: string) => {
                if (provincesSelection[province].active) {

                  const cityResponse = await GetAPIService(`geo/citypregion/${provincesSelection[province].label}`);
                  const provinces = cityResponse.data.data as [City];

                  provinces.forEach((value) => {
                    const label = value.nl || value.fr || value.de;
                    selector.push({ label, value: label });
                  });
                }
              })
            );

          } catch (error) {
            // Handle error if API request fails
            console.error('Error fetching provinces:', error);
          }
        };


            fetchData();

      }, [provincesSelection, open]);


      useEffect(() => {
        const fetchData = async () => {
          try {
            const provinceResponse = await GetAPIService('renovation');
            const provinces = provinceResponse.data as [ServicesModal];

            const selection: Dictionary<ServiceShow> = {};
            provinces.forEach((value: ServicesModal) => {
              selection[value.id] = {active: false, id: parseInt(value.id), servicename: value.title};
            });

            setServicesSelection(selection);
          } catch (error) {
            // Handle error if API request fails
            console.error('Error fetching provinces:', error);
          }
        };

        fetchData();
      }, [open]);

    const removeDuplicateFromNumberState = (value: React.Dispatch<React.SetStateAction<number[]>>) => {
        value(prevState => {
            const uniqueArray = prevState.filter((value, index, self) => {
              return self.indexOf(value) === index;
            });
            return uniqueArray;
        })
    }
        const [lastKey, setkey] = useState(-1);
    const printTrueKeys = (input: Dictionary<ProvinceSelection>) => {
        const arrayKey: [string | null] = [null]
        for (const key in input) {
          if (input[key].active) {
            arrayKey.push(key)
          }
        }
        return (
            <div className="col-span-12">
                {arrayKey.slice(1).map((key: any) => (
                    <div className="col-span-12 mb-[25px]" key={key}>
                        <span className="font-semibold">{key + ' '}</span>
                        <div className="ml-2" style={{ display: 'inline' }}>
                            <ModalBoxCheckBox
                                onChange={(change) => {
                                    const checkbox = CityChecked
                                    setkey(parseInt(input[key].label));
                                    checkbox[key ? parseInt(input[key].label) : 0] = {
                                        id: key ? input[key].label : '0',
                                        checked: change.target.checked
                                    }

                                    setCityChecked(checkbox)
                                    setRerender(!rerender)
                                }}
                                defaultChecked={key ? CityChecked[input[key].label] ? CityChecked[input[key].label].checked : false : false}
                                name={`all_municipalities_${key ? parseInt(input[key].label) : 0}`}
                                content="Alle gemeenten"
                                status={false}
                            />
                        </div>

                            <CityModalBox
                                RegionID={key ? parseInt(input[key].label) : 0}
                                province={CityChecked}
                                lastKey={lastKey}
                                setLastkey={setkey}
                                setUpdateCityChecked={setCityChecked}
                                rerender={rerender}
                            />
                    </div>
                ))}
            </div>
        )
    }

    const hasCheckedItem = (dictionary: Dictionary<CheckBoxData>): boolean => {
        for (const key in dictionary) {
          if (dictionary[key].checked) {
            return true;
          }
        }
        return false;
    };

    const manualErrorSet = (values: any) => {
        let errorObj ={...error}

        const results = contractorSchema.validate(values,{abortEarly:false})
        if (results.error) {
            for (const err of results.error.details) {
                errorObj = {...errorObj,[err.path[0]]:err.message}
            }
        }

        if(!fileUploadState) errorObj = {...errorObj,logo:"Dit veld is verplicht."}
        else errorObj = {...errorObj,logo:""}

        if(!location?.city) errorObj = {...errorObj,city:"Dit veld is verplicht."}
        else errorObj = {...errorObj,city:""}
        
        if(!street) errorObj = {...errorObj,street:"Dit veld is verplicht."}
        else errorObj = {...errorObj,street:""}
        
        if(Object.values(servicesSelection).filter(item => item.active).length === 0) errorObj = {...errorObj,service:"Dit veld is verplicht."}
        else errorObj = {...errorObj,service:""}
        
        if(!hasCheckedItem(CityChecked) && cityData.length === 0) errorObj = {...errorObj,allCities:"Dit veld is verplicht."}
        else errorObj = {...errorObj,allCities:""}

        // return validity
        setError(errorObj)

        if(Object.values(errorObj).every(value => value === ""))

        return Object.values(errorObj).every(value => value === "");
    }

    const UserApiOnly = async (contractorRes: any, values: any) => {
        let messageShown = true
        const auth = JSON.parse(localStorage.getItem("auth")|| '[]')
        let domainId = '1'
        if(auth?.domain?.length > 0){
            domainId = auth?.domain[0]?.domainId
        }

        PostFormAPIService('user/create/passwordless', {
            "typeId":5,
            "companyId":contractorRes?.data?.message.id,
            "firstName":values?.firstname,
            "lastName":values?.name,
            "email":values?.emailUser,
            "phone":values?.phone,
            "newsletter":0,
            "domainId": domainId,
            'url':window.location.href.indexOf("results") > -1 ? window.location.href : null
        }).then(async (userRes)=> {
            console.log("User created ", userRes);

            if(selectedValues.length > 0) {
                await PostAPIService(`contractor/label/${contractorRes?.data?.message.id}`, {
                    labelId: selectedValues
                }).then((labelAddRes: any)=> {
                }).catch((err)=> {
                    messageShown = true
                    setInfoModal('error', "Er is geen label toegevoegd" + err,messageShown)
                    console.log(err);
                    setLoading(false)
                })
            }

            if(selectedValuesCertificate.length > 0) {
                await PostAPIService(`contractor/certificat/${contractorRes?.data?.message.id}`, {
                    certificatId: selectedValuesCertificate
                }).then((labelAddRes: any)=> {
                }).catch((err)=> {
                    messageShown = true
                    setInfoModal('error', "Er is geen certificaat toegevoegd" + err,messageShown)
                    console.log(err);
                })
            }

            await PostAPIService(`contractor/renovation/${contractorRes?.data?.message.id}`, {
                "renovationId": Object.values(servicesSelection)
                .filter(item => item.active)
                .map(item => item.id)
            }).then((labelUploadRes: any)=> {
            }).catch(error => {
                console.log(error);
            })

            setInfoModal('success', "Aannemer succesvol toegevoegd",messageShown)
            messageShown = false
            setRendering(!rendering)
            setIsErrorContractorApi(false)
            setContractorResponse(null)
            setOpen(false)
            setLoading(false)
            form.resetFields();

        }).catch(err => {
            messageShown = true
            setInfoModal("error", err?.response.data.data.message || "Gebruiker is niet aangemaakt" + err,messageShown)
            
            console.log("User error", err);
        }).finally(() => {
            setLoading(false)
        })
    }

    const onFormSubmit = (values: any) => {
        let messageShown = true
        setIsSubmitted(true)
        const transformedArray = Object.values(
            cityData.reduce((result, { id, cityId }) => {
                if (!result[id]) {
                result[id] = { id, city: [] };
                }
                result[id].city.push(cityId);
                return result;
            }, {} as { [id: number]: { id: number; city: number[] } })
        );

        const checkedIds = Object.keys(CityChecked).reduce((acc: number[], key) => {
            if (CityChecked[key].checked) {
              acc.push(parseInt(CityChecked[key].id));
            }
            return acc;
        }, []);

        let valuesForValidationCheck ={
            name : values.name,
            firma : values.firma,
            emailUser : values.emailUser,
            phone : values.phone,
            firstname : values.firstname,
            phonecontractor : values.phonecontractor,
            emailcontractor : values.emailcontractor,
            btw : values.btw,
            description : values.description
        }

        const isValid = manualErrorSet(valuesForValidationCheck)
        if(isValid){
            const toSendCity = transformedArray.filter(item => !checkedIds.includes(item.id));
            setLoading(true)
            console.log("valeus submitted are ", values)
            const defaultErrorMsg = "Aannemer kan niet worden gemaakt."

            if(values?.name && values?.emailUser && values?.phone && values?.firstname) {
                if(isErrorContractorApi && contractorResponse) {
                    UserApiOnly(contractorResponse, values)
                }
                else if(street && street?.street.length > 0){
                    console.log("street", street);
                            console.log(fileUploadState);
                            const formData = new FormData();
                            if(fileUploadState)
                                formData.append("logo", fileUploadState)
                            formData.append("name", values?.firma)
                            formData.append("contact", values?.phonecontractor || "")
                            formData.append("email", values?.emailcontractor)
                            formData.append("phone", values?.phonecontractor || "")
                            formData.append("vat", values?.btw)
                            formData.append("cityId", location ? location.cityid : '')
                            formData.append("streetId", street.id)
                            formData.append("address1", street.street)
                            formData.append("address2", location ? location.city : '')
                            formData.append("description", values?.description)

                            if(checkedIds.length > 0 || toSendCity.length > 0) {
                                removeDuplicateFromNumberState(setSelectedValues)
                                removeDuplicateFromNumberState(setSelectedValuesCertificate)
                                PostFormAPIService('contractor', formData).then((contractorRes)=> {
                                    console.log('contractore es is ', contractorRes)

                                    UserApiOnly(contractorRes, values)
                                    setContractorResponse(contractorRes)

                                    if(checkedIds.length > 0) {
                                        PostAPIService(`contractor/workplace/${contractorRes?.data?.message.id}`, {
                                            "regionId": checkedIds
                                        }).then((labelUploadRes: any)=> {
                                        }).catch(error => {
                                            console.log(error);
                                        })
                                    }

                                    PostAPIService(`contractor/workplace/${contractorRes?.data?.message.id}`, {
                                        "region": toSendCity
                                    }).then((labelUploadRes: any)=> {
                                    }).catch(error => {
                                        console.log(error);
                                    })
                                }).catch((err)=> {
                                    messageShown = true
                                    setInfoModal("error", err?.response?.data?.message || defaultErrorMsg,messageShown)
                                    setLoading(false)
                                    setIsErrorContractorApi(true)
                                    console.log(err);
                                })
                            } else {
                                messageShown = true
                                setInfoModal("error", "Er is geen gemeente ingevuld",messageShown)
                                setLoading(false)
                            }
                } else {
                    setLoading(false)
                    messageShown = true
                    setInfoModal("error", "Er is geen gemeente geselecteerd",messageShown)
                }
            } else {
                setLoading(false)
                messageShown = true
                setInfoModal('error', "Gelieve contactgegevens in te vullen",messageShown)
            }
        }        
    }

    const debouncedFormSubmit = debounce(onFormSubmit, 1000);

    const handleChange = async (event: any) => {

        const { name, value } = event.target;
        try {

            const target = contractorSchema.extract(name);
            const result = target.validate(value)
            if (result.error && isSubmitted) {
                setError({ ...error, [name]: result.error.message })
            }
            else {
                setError({ ...error, [name]: "" })
            }
           

        } catch (error: any) {
            if (isSubmitted) {
                setError({ ...error, [name]: error.message });
            }
        }

    }

    const handleClose = () => {
        setIsSubmitted(false);
        setOpen(false)
        
    };

    return (
        <ModalBox
            title="Gegevens van de aannemer"
            cancelHandler={() =>  handleClose()}
            open={open}
        >
            <LabelModalBox />
            <CertificateModalBox />
            <Spin spinning={loading}>
                <Form form={form} name="contractor_form"
                    onFinish={debouncedFormSubmit}>
                    <div className="border-grey-300 border-b-[1px] border-t-[1px]">
                        <div className="px-8 pt-5 pb-10 grid grid-cols-8 gap-4">
                            <div className="xl:col-span-2 col-span-12">
                                <div className="flex items-center">
                                    <label htmlFor="fileUploadAdd" className="primary-main secondary-text py-2 px-4 rounded cursor-pointer">
                                        Logo opladen
                                    </label>
                                    <input
                                        id="fileUploadAdd"
                                        type="file"
                                        style={{display: 'none'}}
                                        onChange={(e) => {
                                            let files = e.target.files
                                            if(files)
                                                setFileUploadState(files[0])
                                            console.log(files);
                                        }}
                                    />
                                </div>
                                <p>
                                    {
                                        fileUploadState ?
                                        fileUploadState.name :
                                        error.logo ?
                                        <span className="text-error text-xs my-10">
                                            {error.logo}
                                        </span> : null
                                    }
                                </p>
                            </div>
                            <div className="xl:col-span-10 col-span-12">
                                <div className="grid grid-cols-12 gap-4">
                                    <div className="text-xs font-bold lg:col-span-3 md:col-span-6 col-span-12">
                                        <Input classes="col-span-2" full={true}
                                            label="FIRMA" name="firma" outerError={error.firma} active={true} onChangeHandler={handleChange}
                                        />
                                    </div>
                                    <div className="text-xs font-bold lg:col-span-3 md:col-span-6 col-span-12">
                                        <Input classes="col-span-2" full={true}
                                            label="BTW NUMMER" name="btw" outerError={error.btw} active={true} onChangeHandler={handleChange}
                                        />
                                    </div>
                                    <div className="text-xs font-bold lg:col-span-6 md:col-span-6 col-span-12">
                                        <div className="flex items-center">
                                            <p className="font-semibold text-secondary text-left pb-2 text-xs">
                                            LABEL
                                            </p>
                                        </div>
                                        <div className="grid grid-cols-12 h-10 pt-1">
                                            <button
                                                style={{ width: '100%', height:'100%', position: 'relative', bottom: 6 }}
                                                className="col-span-3 primary-main secondary-text font-semibold rounded mt-2"
                                                onMouseDown={(event) => {
                                                    event.preventDefault();
                                                    setLabel(true);
                                                }}
                                            >
                                            Aanmaken
                                            </button>
                                            <Select
                                                mode="multiple"
                                                notFoundContent = "geen gegevens"
                                                allowClear
                                                size='large'
                                                className="col-span-8 ml-1"
                                                style={{ width: '100%', border: "1px solid #E5E7EB", borderRadius: "4px" }}
                                                placeholder={<span className="text-gray-300 font-medium text-sm" >Selecteer een label</span>}
                                                value={selectedValues.map(String)}
                                                bordered={false}
                                                onChange={(value: string[]) => {
                                                    const label: number[] = []
                                                    value.forEach(data => {
                                                        label.push(parseInt(data))
                                                    })
                                                    setSelectedValues(label)
                                                }}
                                                filterOption={(input: string, option?: { label: string; value: string }) => {
                                                    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
                                                }}
                                                options={allLabel}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-8 grid grid-cols-12 gap-4">
                                    <div className="text-xs font-bold lg:col-span-3 md:col-span-6 col-span-12">
                                        <Email
                                            label="EMAIL" active={true} name="emailcontractor"
                                            outerError={error.emailcontractor }
                                            onChangeHandler={handleChange}
                                            full={true}
                                        />
                                    </div>
                                    <div className="text-xs font-bold lg:col-span-3 md:col-span-6 col-span-12">
                                        <Input
                                            label="TELEFOONNUMMER" active={true} name="phonecontractor"
                                            outerError={error.phonecontractor}
                                            onChangeHandler={handleChange}
                                            full={true}
                                        />
                                    </div>
                                    <div className="text-xs font-bold lg:col-span-6 md:col-span-6 col-span-12">
                                        <div className="flex items-center">
                                            <p className="font-semibold text-secondary text-left pb-2 text-xs">
                                                CERTIFICATEN
                                            </p>
                                        </div>
                                        <div className="grid grid-cols-12 mt-1">
                                            <button style={{width: '100%', height:'100%',position: 'relative', bottom: 8}}
                                            className="col-span-3 primary-main secondary-text font-semibold rounded mt-2"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    setCertificate(true)
                                            }} >Aanmaken</button>
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                notFoundContent = "geen gegevens"
                                                size='large'
                                                bordered={false}
                                                className="col-span-8 ml-1"
                                                style={{ width: '100%', border: "1px solid #E5E7EB", borderRadius: "4px" }}
                                                placeholder={<span className="text-gray-300 font-medium text-sm" >Selecteer een label</span>}
                                                value={selectedValuesCertificate.map(String)}
                                                onChange={(value: string[]) => {
                                                    const certificate: number[] = []
                                                    value.forEach(data => {
                                                        certificate.push(parseInt(data))
                                                    })
                                                    setSelectedValuesCertificate(certificate)
                                                }}
                                                filterOption={(input: string, option?: { label: string; value: string }) => {
                                                    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
                                                }}
                                                options={allCertificate}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-xs font-bold lg:col-span-8 md:col-span-6 col-span-12">
                                        <p className={`font-semibold text-secondary text-left mb-1 text-xs'`} >ADRES</p>
                                        <AddressBar
                                            defaultValueCity="cityContract"
                                            defaualtValueStreet="streetContract"
                                            onCityChange={(value: string, option: { id: string; zip: string })=> {
                                                setLocation({zip: option.zip, city: value, cityid: option.id})
                                                form.setFieldValue('postcode', option.zip)
                                                if(!value && isSubmitted) setError({...error,city:"Dit veld is verplicht."})
                                                else setError({...error,city:""})

                                            }}
                                            onStreetChange={(value: string, option: any)=> {
                                                setStreet({street: value, id: option.id})
                                                if(!value && isSubmitted) setError({...error,street:"Dit veld is verplicht."})
                                                else setError({...error,street:""})
                                            }}
                                            disabled={location ? location.zip.length === 0 : true}
                                            errors={{city:error.city,street:error.street}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-grey-300 border-b-[1px]">
                        <div className="px-8 py-12">
                            <div className="grid lg:grid-cols-8 grid-cols-12">
                                <p className="col-span-2 flex items-center font-semibold">
                                    PROVINCIES
                                </p>
                                <Row className="col-span-12">
                                    {
                                        Object.keys(provincesSelection).map((province: string) => {
                                            return (
                                                <Col className="mr-3 mt-2">
                                                    <Tag
                                                        text={province}
                                                        active={provincesSelection[province].active}
                                                        onClick={()=> {
                                                            setProvincesSelection((prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    [province]: {active: !prevState[province].active, label: prevState[province].label}
                                                                }
                                                            })
                                                            setRerender(!rerender)
                                                            CityUpdate()
                                                        }}
                                                    />
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </div>
                        <div className="px-8 pt-2 pb-10">
                            <div className="grid grid-cols-12">
                                <p className="col-span-12 font-semibold">
                                    GEMEENTE
                                </p>
                                <div className="col-span-12 flex items-center">
                                    <>
                                        {printTrueKeys(provincesSelection)}
                                    </>
                                </div>
                                {
                                    (error.allCities && (!hasCheckedItem(CityChecked) && cityData.length === 0)) ?
                                    <span className="col-span-12 text-error text-xs my-10">
                                 {error.allCities}
                                </span> : null}
                            </div>
                        </div>
                    </div>
                    <div className="border-grey-300 border-b-[1px]">
                        <Input
                            classes="px-8 py-6"
                            name="description"
                            label="BESCHRIJVING"
                            outerError={error.description}
                            onChangeHandler={handleChange}
                            active={true} textArea={true}
                            full={true}
                            labelExtraClasses="text-sm font-semibold"
                        />
                    </div>
                    <div className="border-grey-300 border-b-[1px]">
                        <div className="px-8 py-12">
                            <p className="col-span-2 font-semibold">
                                DIENSTEN
                            </p>
                            <Row>
                                {
                                    Object.keys(servicesSelection).map((service) => {
                                        return (
                                            <Col className="mr-3 mt-2" key={service}>
                                                <Tag
                                                    text={servicesSelection[service].servicename}
                                                    active={servicesSelection[service].active}
                                                    onClick={()=> setServicesSelection((prevState)=> {

                                                        console.log("service click is ", service)
                                                        console.log(servicesSelection);
                                                        return {
                                                            ...prevState,
                                                            [service]: {...prevState[service], active: !prevState[service].active}
                                                        }
                                                    })}
                                                />
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                            <span className="text-error text-xs my-10">
                                {
                                    (error.service && (Object.values(servicesSelection).filter(item => item.active).length === 0))
                                    ? error.service
                                    : null
                                }
                            </span>
                        </div>
                    </div>
                    <div className="border-grey-300 border-b-[1px]">
                        <div className="px-8 py-12">
                            <p className="col-span-2 font-semibold">
                                CONTACTPERSOON
                            </p>
                            <div className="mt-5 grid grid-cols-12 gap-4">
                                <Input classes="xl:col-span-3 md:col-span-6 col-span-12" outerError={error.firstname} name={"firstname"} label={"VOORNAAM"} onChangeHandler={handleChange}
                                    active={true} full={true}/>
                                <Input classes="xl:col-span-3 md:col-span-6 col-span-12" outerError={error.name} name={"name"} label={"ACHTERNAAM"} onChangeHandler={handleChange}
                                    active={true} full={true}/>
                                <Email classes="xl:col-span-3 md:col-span-6 col-span-12" outerError={error.emailUser} name={"emailUser"} label={"EMAIL"} onChangeHandler={handleChange}
                                    active={true} full={true}/>
                                <Input classes="xl:col-span-3 md:col-span-6 col-span-12" outerError={error.phone} name={"phone"} label={"TELEFOONNUMMER"} onChangeHandler={handleChange}
                                    active={true} full={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="border-grey-300 border-b-[1px]">
                    </div>
                    <div className="px-8">
                        <div className="flex justify-end mt-4">
                            <PrimaryButton content="Opslaan" onClick={() => form.submit()}/>
                        </div>
                    </div>
                </Form>
            </Spin>
        </ModalBox>

    )
}
