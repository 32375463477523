import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { PrimaryButton } from "../../shared/buttons/igemo_button";
import { useNavigate } from "react-router-dom";
import { AddressBar } from "../../shared/AddressBar";
import axios from "axios";
import {getLocalNewStateValue, getLocalStateValue} from "../../utils/helpers";
import PdfDom from "../../containers/pdfDom";
type cityType = {
    id: string,
    key: string,
    value: string,
    zip: number
}

type streetType = {
    id: string,
    key: string,
    value: string
}

type SimulationModalProps = {
    cookies?: {
        userId?: string,
        sessionData?: {
            address?: string,
        },
    },
    isModalOpen: boolean,
    setIsModalOpen: (isModalOpen: boolean) => void,
    resultLabelData: any,
    renderUpdate: any
}

const SimulationModal = ({ cookies, isModalOpen, setIsModalOpen = () => { }, resultLabelData, renderUpdate }: SimulationModalProps) => {
    const [cityState, setCityState] = useState<cityType>({
        id: "",
        key: "",
        value: "",
        zip: 0
    });
    const [streetState, setStreetState] = useState<streetType>({
        id: "",
        key: "",
        value: ""
    });
    const [inputErrorState, setInputErrorState] = useState({
        city: false,
        street: false
    })
    const [modalWarningsList, setModalWarningsList] = useState<string[]>([]);
    const [pdfDataUpdate, setPdfDataUpdate] = useState(false);
    const [modalViewState, setModalViewState] = useState<"initial" | "validate" | "api" | "loading" | "success" | "error" | "invalid" | "empty">("initial")
    const [pdfDataHTML, setPdfDataHTML] = useState("");
    const [skip, setSkip] = useState(false);
    const [disableGoToDashboardBtn, setDisableGoToDashboardBtn] = useState(false)
    const [disableSubmitSimBtn, setDisableSubmitSimBtn] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        validateImprovementList();
        const cityId = localStorage.getItem("city")
        const StreetId = localStorage.getItem("street")

        if (modalViewState === "initial" && !isEmpty(cityId) && !isEmpty(StreetId)) {
            simulationSubmit();
        }
    }, [])

    useEffect(() => {
        if (modalViewState === "initial") {
            const cityId = localStorage.getItem("city")
            const StreetId = localStorage.getItem("street")
            const address = localStorage.getItem("address")
            if (cityId && StreetId && address) {
                setModalViewState("validate")
            }
        }
        if (modalViewState === "validate") {
            validateImprovementList(true);
        }
        if (modalViewState === "api") {
            setModalViewState("loading");
            simulationSubmit();
        }
    }, [modalViewState, skip])

    function simulationSubmit() {
        ///
        // const auth = JSON.parse(localStorage.getItem("auth")!)
        const authOrigin = localStorage.getItem("auth");
const auth = authOrigin && authOrigin?.length > 0? JSON.parse(localStorage.getItem("auth")!) : ""
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.token}`,
        };
        setPdfDataUpdate(true);
        let localState: any = localStorage.getItem("localState")
        let newState: any = localStorage.getItem("newState")
        // console.log("==> results",localState,newState)

        if (localState && newState) {
            setDisableSubmitSimBtn(true)
            localState = JSON.parse(localState)
            newState = JSON.parse(newState)
            let results: any = []
            // console.log("==> results",localState,newState)
            let i = 0
            const { userId } = cookies || {};

            for (const data of Object.keys(newState)) {
                // console.log("==>," ,newState[data])

                let key = Object.keys(newState[data])[1]
                // console.log(key,"key in simulaiton modal")
                if(key === "zijgevels"){
                    const key1 = "achter-_en_zijgevels"
                    console.log('Im hereee in condition of zijgivil')
                    console.log(newState[data][key1],'newState[data][key1]')
                    results.push(
                        {
                            key: key,
                            originalAnswer: localState[data].answerNo,
                            originalAnswerText: localState[data][key],
                            newAnswer: newState[data].answerNo,
                            newAnswerText: newState[data][key1],
                        })
                }
                // console.log("==>," ,Object.keys(newState[data]))
                // console.log("==>," ,newState[data][key],key)
                else{
                    results.push(
                        {
                            key: key,
                            originalAnswer: localState[data].answerNo,
                            originalAnswerText: localState[data][key],
                            newAnswer: newState[data].answerNo,
                            newAnswerText: newState[data][key],
                        })
                }

                i++;
            }
            const payload = {
                "sessionId": userId,
                results
            }
            axios.post(process.env.REACT_APP_BACKEND_URL + "session/result/", payload,{headers}).then(response => {
                setModalViewState("success");
            }).catch(error => {
                setModalViewState("error");
            }).finally(() => {
                setDisableSubmitSimBtn(false)
            })
        }
    }
    const goToDashboardWithoutLink = () => {
        navigate("/simulations");

    }
    const goToDashboard = async () => {
        // (async () => {
        const cityId = await localStorage.getItem("city") || cityState
        const StreetId = await localStorage.getItem("street") || streetState
        const address = await localStorage.getItem("address") || (streetState.value + " " + streetState.value)
        if (cityId && StreetId) {
            setDisableGoToDashboardBtn(true)
            const { uid } = await JSON.parse(await localStorage.getItem("auth")!) || null;
            const { userId } = cookies || {};
            const payload = {
                address1: address,
                sessionId: userId,
                streetId: StreetId,
                cityId: cityId
            }
            if (uid && userId) {
                setModalViewState("loading");
                axios.post(process.env.REACT_APP_BACKEND_URL + "user/link/" + uid, payload).then(async response => {
                    // console.log(response, 'response in link API')
                    const simulationId = response?.data?.data.result.id

                    await axios.post(process.env.REACT_APP_BACKEND_URL + 'offer/request/set-default-answers', { simulationId: simulationId })

                    let data = response?.data?.data?.result
                    let pdfHtml = JSON.stringify(document.getElementById('pdf-dom')?.innerHTML);
                    setPdfDataHTML(pdfHtml)
                    //@ts-ignore
                    axios.post(process.env.REACT_APP_BACKEND_URL + 'pdf/generate/link', { pdfDom: pdfHtml, simulationId: data?.id }).then((response) => {
                        setModalViewState("success");
                        localStorage.setItem('simulationId', data?.id)
                        setPdfDataHTML("")
                        setPdfDataUpdate(false);
                        navigate("/simulations");
                        setDisableGoToDashboardBtn(false)
                    }).catch(error => {
                        setModalViewState("error");
                        setDisableGoToDashboardBtn(false)
                    })

                }).catch(error => {
                    console.error(error)
                    setModalViewState("error");
                    setDisableGoToDashboardBtn(false)
                })
            }
        }
        // })
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setSkip(false);
    }

    type CardType = "hoofddak" | "bijdak" | "voorgevel" | "achtergevel" | "ramen_leefruimte" | "ramen_slaapkamers";
    const checkCardStatus = (key: CardType) => {
        if (key === "hoofddak") {
            const localItem = getLocalNewStateValue("12");
            if (localItem[key] === "Goed geïsoleerd" || localItem[key] === "Matig geïsoleerd") {
                return true;
            }
            else {
                return false;
            }
        }
        else if (key === "bijdak") {
            const localItem = getLocalNewStateValue("13");
            if (localItem[key] === "Goed geïsoleerd" || localItem[key] === "Matig geïsoleerd") {
                return true;
            }
            else {
                return false;
            }
        }
        else if (key === "voorgevel") {
            const localItem = getLocalNewStateValue("17");
            if (localItem[key] === "Goed geïsoleerd" || localItem[key] === "Wel geïsoleerd") {
                return true;
            }
            else {
                return false;
            }
        }
        else if (key === "achtergevel") {
            const localItem = getLocalNewStateValue("18");
            if (localItem[key] === "Goed geïsoleerd" || localItem[key] === "Wel geïsoleerd") {
                return true;
            }
            else {
                return false;
            }
        }
        else if (key === "ramen_leefruimte") {
            const localItem = getLocalNewStateValue("15");
            if (localItem[key] === "Driedubbel glas" || localItem[key] === "Hoogrendementsglas") {
                return true;
            }
            else {
                return false;
            }
        }
        else if (key === "ramen_slaapkamers") {
            const localItem = getLocalNewStateValue("16");
            if (localItem[key] === "Driedubbel glas" || localItem[key] === "Hoogrendementsglas") {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    const checkCardStatusOld = (key: CardType) => {
        if (key === "hoofddak") {
            const localItem = getLocalStateValue("12");
            if (localItem[key] === "Goed geïsoleerd" || localItem[key] === "Matig geïsoleerd") {
                return true;
            }
            else {
                return false;
            }
        }
        else if (key === "bijdak") {
            const localItem = getLocalStateValue("13");
            if (localItem[key] === "Goed geïsoleerd" || localItem[key] === "Matig geïsoleerd") {
                return true;
            }
            else {
                return false;
            }
        }
        else if (key === "voorgevel") {
            const localItem = getLocalStateValue("17");
            if (localItem[key] === "Goed geïsoleerd" || localItem[key] === "Wel geïsoleerd") {
                return true;
            }
            else {
                return false;
            }
        }
        else if (key === "achtergevel") {
            const localItem = getLocalStateValue("18");
            if (localItem[key] === "Goed geïsoleerd" || localItem[key] === "Wel geïsoleerd" || localItem[key] === "Geïsoleerd in de spouw") {
                return true;
            }
            else {
                return false;
            }
        }
        else if (key === "ramen_leefruimte") {
            const localItem = getLocalStateValue("15");
            if (localItem[key] === "Driedubbel glas" || localItem[key] === "Hoogrendementsglas") {
                return true;
            }
            else {
                return false;
            }
        }
        else if (key === "ramen_slaapkamers") {
            const localItem = getLocalStateValue("16");
            if (localItem[key] === "Driedubbel glas" || localItem[key] === "Hoogrendementsglas") {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    const validateImprovementList = (autoTriggerApi: boolean = false) => {
        if (!skip) {
            const improvementCardData = JSON.parse(localStorage.getItem("improvedCard") || "{}");
            // convert improvementCardData to an array with keys
            let improvementWarnings = [];
            let otherImprovementsExist = false;
            let otherImprovementsOldExist = false;
            let validations = {
                "hoofddak": false,
                "bijdak": false,
                "voorgevel": false,
                "achter- en zijgevels": false,
                "ramen leefruimte": false,
                "ramen slaapkamers": false
            }
            let alreadyvalidations = {
                "hoofddak": false,
                "bijdak": false,
                "voorgevel": false,
                "achter- en zijgevels": false,
                "ramen leefruimte": false,
                "ramen slaapkamers": false
            }
            if (improvementCardData && Object.keys(improvementCardData).length > 0) {
                Object.keys(improvementCardData).map(key => {
                    const { key: newkey } = improvementCardData[key];
                    if (newkey === "hoofddak") {
                        validations["hoofddak"] = true;
                    }
                    if (newkey === "bijdak") {
                        validations["bijdak"] = true;
                    }
                    if (newkey === "voorgevel") {
                        validations["voorgevel"] = true;
                    }
                    if (newkey === "zijgevels") {
                        validations["achter- en zijgevels"] = true;
                    }
                    if (newkey === "ramen_leefruimte") {
                        validations["ramen leefruimte"] = true;
                    }
                    if (newkey === "ramen_slaapkamers") {
                        validations["ramen slaapkamers"] = true;
                    }
                    // check to see if improvements have been made on other services aside from the priority ones
                    if (newkey !== "hoofddak" && newkey !== "bijdak" && newkey !== "voorgevel" && newkey !== "zijgevels" && newkey !== "ramen_leefruimte" && newkey !== "ramen_slaapkamers") {
                        otherImprovementsExist = true;
                    }
                    // console.log("improvement exist", improvementCardData[key].key)
                });
                Object.keys(improvementCardData).map(key => {
                    const { key: newkey } = improvementCardData[key];
                    if (newkey !== "hoofddak" && checkCardStatusOld("hoofddak")) {
                        alreadyvalidations["hoofddak"] = true;
                    }
                    if (newkey !== "bijdak" && checkCardStatusOld("bijdak")) {
                        alreadyvalidations["bijdak"] = true;
                    }
                    if(newkey !== "bijdak" && !alreadyvalidations["bijdak"]){
                        const localItem = getLocalStateValue("13");
                        if(localItem['bijdak'] == 'Niet van toepassing') {
                            alreadyvalidations["bijdak"] = true;
                        }
                    }
                    if (newkey !== "voorgevel" && checkCardStatusOld("voorgevel")) {
                        alreadyvalidations["voorgevel"] = true;
                    }
                    if (newkey !== "zijgevels" && checkCardStatusOld("achtergevel")) {
                        alreadyvalidations["achter- en zijgevels"] = true;
                    }
                    if(newkey !== "zijgevels" && !alreadyvalidations["achter- en zijgevels"]){
                        const localItem = getLocalStateValue("1");
                        if(localItem['type_woning'] == 'Gesloten bebouwing') {
                            alreadyvalidations["achter- en zijgevels"] = true;
                        }
                    }
                    if (newkey !== "ramen_leefruimte" && checkCardStatusOld("ramen_leefruimte")) {
                        alreadyvalidations["ramen leefruimte"] = true;
                    }
                    if (newkey !== "ramen_slaapkamers" && checkCardStatusOld("ramen_slaapkamers")) {
                        alreadyvalidations["ramen slaapkamers"] = true;
                    }
                    // check to see if improvements have been made on other services aside from the priority ones
                    if (newkey !== "hoofddak" && newkey !== "bijdak" && newkey !== "voorgevel" && newkey !== "zijgevels" && newkey !== "ramen_leefruimte" && newkey !== "ramen_slaapkamers") {
                        otherImprovementsOldExist = true;
                    }
                    // console.log("improvement exist", improvementCardData[key].key)
                });
                console.log('validations', validations);
                // check to see which services have not been improved and are red
                // if (!validations["ramen slaapkamers"] && !validations["ramen leefruimte"]) {
                //     if (!validations["voorgevel"] && !validations["achter- en zijgevels"]) {
                //         if (!validations["bijdak"]) {
                //             if (!validations["hoofddak"]) {
                //                 improvementWarnings.push("hoofddak zou u beter eerst toevoegen aan uw plan.");
                //                 setModalViewState("invalid");
                //             }
                //             else {
                //                     improvementWarnings.push("bijdak zou u beter eerst toevoegen aan uw plan.");
                //                     setModalViewState("invalid");
                //             }
                //         }
                //         else {
                //             if (!validations["hoofddak"]) {
                //                 improvementWarnings.push("hoofddak zou u beter eerst toevoegen aan uw plan.");
                //                 setModalViewState("invalid");
                //             }
                //             else {
                //                     improvementWarnings.push(`${validations["voorgevel"] === false ? "voorgevel" : "achter- en zijgevels"} zou u beter eerst toevoegen aan uw plan.`);
                //                     setModalViewState("invalid");
                //             }
                //         }
                //     }
                //     else {
                //         if (!validations["bijdak"]) {
                //             if (!validations["hoofddak"]) {
                //                 improvementWarnings.push("hoofddak zou u beter eerst toevoegen aan uw plan.");
                //                 setModalViewState("invalid");
                //             }
                //             else {
                //                 improvementWarnings.push("bijdak zou u beter eerst toevoegen aan uw plan.");
                //                 setModalViewState("invalid");
                //             }
                //         }
                //         else {
                //             if (!validations["hoofddak"]) {
                //                 improvementWarnings.push("hoofddak zou u beter eerst toevoegen aan uw plan.");
                //                 setModalViewState("invalid");
                //             }
                //             else {
                //                     improvementWarnings.push(`${validations["ramen slaapkamers"] === false ? "ramen slaapkamers" : "ramen leefruimte"} zou u beter eerst toevoegen aan uw plan.`);
                //                     setModalViewState("invalid");
                //             }
                //         }
                //     }
                // }
                // else {
                //     if (!validations["voorgevel"] && !validations["achter- en zijgevels"]) {
                //         if (!validations["bijdak"]) {
                //             if (!validations["hoofddak"]) {
                //                 improvementWarnings.push("hoofddak zou u beter eerst toevoegen aan uw plan.");
                //                 setModalViewState("invalid");
                //             }
                //             else {
                //                 improvementWarnings.push("bijdak zou u beter eerst toevoegen aan uw plan.");
                //                 setModalViewState("invalid");
                //             }
                //         }
                //         else {
                //             if (!validations["hoofddak"]) {
                //                 improvementWarnings.push("hoofddak zou u beter eerst toevoegen aan uw plan.");
                //                 setModalViewState("invalid");
                //             }
                //             else {
                //                 improvementWarnings.push(`${validations["voorgevel"] === false ? "voorgevel" : "achter- en zijgevels"} zou u beter eerst toevoegen aan uw plan.`);
                //                 setModalViewState("invalid");
                //             }
                //         }
                //     }
                //     else {
                //         if (!validations["bijdak"]) {
                //             if (!validations["hoofddak"]) {
                //                 improvementWarnings.push("hoofddak zou u beter eerst toevoegen aan uw plan.");
                //                 setModalViewState("invalid");
                //             }
                //             else {
                //                 improvementWarnings.push("bijdak zou u beter eerst toevoegen aan uw plan.");
                //                 setModalViewState("invalid");
                //             }
                //         }
                //         else {
                //             if (!validations["hoofddak"]) {
                //                 improvementWarnings.push("hoofddak zou u beter eerst toevoegen aan uw plan.");
                //                 setModalViewState("invalid");
                //             }
                //         }
                //     }
                // }
                console.log(validations, alreadyvalidations)
                if (!validations["hoofddak"] && !alreadyvalidations["hoofddak"]) {
                    improvementWarnings.push("hoofddak zou u beter eerst toevoegen aan uw plan.");
                    setModalViewState("invalid");
                }else if ((validations["hoofddak"] || alreadyvalidations["hoofddak"])
                    && (!validations["bijdak"] && !alreadyvalidations["bijdak"]) &&
                    ((((validations["voorgevel"] || alreadyvalidations["voorgevel"]) && (validations["voorgevel"] || !alreadyvalidations["voorgevel"])) ||
                        ((validations["achter- en zijgevels"] || alreadyvalidations["achter- en zijgevels"]) && (validations["achter- en zijgevels"] || !alreadyvalidations["achter- en zijgevels"])))
                        ||
                        (((validations["ramen leefruimte"] || alreadyvalidations["ramen leefruimte"]) && (validations["ramen leefruimte"] || !alreadyvalidations["ramen leefruimte"]))
                            ||
                            ((validations["ramen slaapkamers"] || alreadyvalidations["ramen slaapkamers"]) && (validations["ramen slaapkamers"] || !alreadyvalidations["ramen slaapkamers"]))
                        ))){
                    improvementWarnings.push("bijdak zou u beter eerst toevoegen aan uw plan.");
                    setModalViewState("invalid");
                }
                else if (
                    ((!validations["voorgevel"] && !alreadyvalidations["voorgevel"]) || (!validations["achter- en zijgevels"] && !alreadyvalidations["achter- en zijgevels"]))
                    && (validations["ramen leefruimte"] && !alreadyvalidations["ramen leefruimte"])
                ){
                    improvementWarnings.push(`${(!validations["voorgevel"] && !alreadyvalidations["voorgevel"]) ? 'voorgevel' :'achter- en zijgevels'} test zou u beter eerst toevoegen aan uw plan.`);
                    setModalViewState("invalid");
                }
                else if (
                    ((!validations["voorgevel"] && !alreadyvalidations["voorgevel"]) || (!validations["achter- en zijgevels"] && !alreadyvalidations["achter- en zijgevels"]))
                    && (validations["ramen slaapkamers"] && !alreadyvalidations["ramen slaapkamers"])
                ){
                    improvementWarnings.push(`${(!validations["voorgevel"] && !alreadyvalidations["voorgevel"]) ? 'voorgevel' :'achter- en zijgevels'} zou u beter eerst toevoegen aan uw plan.`);
                    setModalViewState("invalid");
                }
                else if (
                    (validations["voorgevel"] && validations["achter- en zijgevels"])
                        &&
                    (!validations["ramen leefruimte"] && !alreadyvalidations["ramen leefruimte"]) && (validations["ramen slaapkamers"])
                    ){
                    improvementWarnings.push(`${(!validations["ramen leefruimte"] && !alreadyvalidations["ramen leefruimte"]) ? 'ramen leefruimte' :'ramen slaapkamers'} zou u beter eerst toevoegen aan uw plan.`);
                    setModalViewState("invalid");
                }else if(otherImprovementsExist && (
                    (validations["hoofddak"] || !alreadyvalidations["hoofddak"]) ||
                    (validations["bijdak"] || !alreadyvalidations["bijdak"]) ||
                    (validations["voorgevel"] || !alreadyvalidations["voorgevel"]) ||
                    (validations["achter- en zijgevels"] || !alreadyvalidations["achter- en zijgevels"]) ||
                    (validations["ramen leefruimte"] || !alreadyvalidations["ramen leefruimte"]) ||
                    (validations["ramen slaapkamers"] || !alreadyvalidations["ramen slaapkamers"]))
                ){
                    if((validations["hoofddak"] || alreadyvalidations["hoofddak"]) &&
                        (validations["bijdak"] || alreadyvalidations["bijdak"]) && (validations["voorgevel"] || alreadyvalidations["voorgevel"]) &&
                        (validations["achter- en zijgevels"] || alreadyvalidations["achter- en zijgevels"]) &&
                        (validations["ramen leefruimte"] || alreadyvalidations["ramen leefruimte"]) &&
                        (validations["ramen slaapkamers"] || alreadyvalidations["ramen slaapkamers"])
                    ){

                    }else {
                        improvementWarnings.push(`${
                            (!validations["hoofddak"] && !alreadyvalidations["hoofddak"]) ? 'hoofddak' :
                                (!validations["bijdak"] && !alreadyvalidations["bijdak"]) ? 'bijdak' :
                                    (!validations["voorgevel"] && !alreadyvalidations["voorgevel"]) ? 'voorgevel' :
                                        (!validations["achter- en zijgevels"] && !alreadyvalidations["achter- en zijgevels"]) ? 'achter- en zijgevels' :
                                            (!validations["ramen leefruimte"] && !alreadyvalidations["ramen leefruimte"]) ? 'ramen leefruimte' : 'ramen slaapkamers'
                        } zou u beter eerst toevoegen aan uw plan.`);
                        setModalViewState("invalid");
                    }
                }
            }
            else {
                improvementWarnings.push("hoofddak zou u beter eerst toevoegen aan uw plan.");
                setModalViewState("invalid");
            }
            if (improvementWarnings.length === 0 && autoTriggerApi) {
                setModalViewState("api")
            }
            setModalWarningsList(improvementWarnings);
        }
    }

    const skipCheck = () => {
        const cityId = localStorage.getItem("city")
        const StreetId = localStorage.getItem("street")
        const address = localStorage.getItem("address")
        if (cityId && StreetId && address) {
            setModalViewState("api")
        }
        else {
            setModalViewState("initial");
        }
    }

    const cityId = localStorage.getItem("city")
    const StreetId = localStorage.getItem("street")
    
    return (
        <div className="h-screen w-screen bg-black/[0.3] flex justify-center items-center fixed top-0 left-0 z-[50]" style={{ display: isModalOpen ? "flex" : "none" }}>
            <div className="box-border p-[10px] md:p-[25px] bg-white w-[90%] md:w-[70%] max-w-[90%] flex flex-col justify-center items-center">
                {modalViewState !== "success" && (
                    <div className="w-full flex justify-end">
                        <div className="cursor-pointer" onClick={closeModal}>
                            <AiOutlineCloseCircle size="32px" color="black" />
                        </div>
                    </div>
                )}
                {/* default view with input and button */}
                {isEmpty(cityId) && isEmpty(StreetId) && modalViewState === "initial" && (
                    <div className="w-full flex flex-col justify-center items-center box-border md:px-[50px] animate-fade-in-top">
                        <p className="font-Source text-2xl font-bold text-center">Koppel uw simulatie aan uw account</p>
                        {/* <p className="font-Source font-regular text-p2 text-[#5F5F5F] text-center mt-[33px]">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p> */}
                        <div className="flex flex-col w-[400px] max-w-[90%] my-[25px]">
                            <AddressBar
                                onCityChange={(value: string, options?: any) => { setCityState(options) }}
                                onStreetChange={(value: string, option: any) => { setStreetState(option) }}
                            />
                        </div>
                        <p className="font-Source font-regular text-p2Alt text-[red]">{inputErrorState.city && "Stadsveld is leeg"}{inputErrorState.city && inputErrorState.street && " & "}{inputErrorState.street && "Straat veld is leeg"}</p>
                        <p className="font-Source font-regular text-p2Alt text-[red] mb-[5px]"></p>
                        <PrimaryButton content="Voortgaan" disabled={disableSubmitSimBtn} onClick={simulationSubmit} />
                    </div>
                )}
                {/* loader */}
                {modalViewState === "loading" && (
                    <div className="w-full flex flex-col justify-center items-center box-border px-[50px] animate-fade-in-top">
                        <div className="w-[50px] h-[50px] border-4 primary-border border-solid border-r-white rounded-full animate-spin" />
                    </div>
                )}
                {/* success view */}
                {modalViewState === "success" && (
                    <div className="w-full flex flex-col justify-center items-center box-border px-[50px] animate-fade-in-top">
                        <p className="font-Source text-2xl font-bold text-center">Simulatie gekoppeld aan uw account</p>
                        {/* <p className="font-Source font-regular text-p2 text-[#5F5F5F] text-center mt-[33px] mb-[50px]">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p> */}
                        <PrimaryButton content="Ga naar jouw dashboard" disabled={disableGoToDashboardBtn} onClick={goToDashboard} />
                    </div>
                )}
                {/* error view */}
                {modalViewState === "error" && (
                    <div className="w-full flex flex-col justify-center items-center box-border px-[50px] animate-fade-in-top">
                        <p className="font-Source text-2xl font-bold text-center mb-[25px]">Deze sessie is al gekoppeld aan dit account.</p>
                        <PrimaryButton content="Ga naar jouw dashboard" onClick={goToDashboardWithoutLink} />
                    </div>
                )}
                {/* invalid view */}
                {modalViewState === "invalid" && (
                    <div className="w-full flex flex-col justify-center items-center box-border px-[50px] animate-fade-in-top">
                        <p className="font-Source text-2xl font-bold text-center mb-[15px]">Waarschuwing</p>
                        <p className="font-Source font-regular text-p2 text-[#5F5F5F] text-center mb-[25px]">Tijdens het energetisch renoveren van jou huis, is het belangrijk dat deze in de juiste volgorde gebeurt.</p>
                        <p className="font-Source text-p2Alt font-bold text-center mb-[5px]">De volgende renovatie:</p>
                        {modalWarningsList.map((warning, index) => (
                            <p key={index} className="font-Source font-semibold text-p2Alt text-[#5F5F5F] text-center mb-[15px]">{warning}</p>
                        ))}
                        <p className="font-Source text-p2Alt font-bold text-center mb-[5px]">Ter info: vermijdt lock-ins. Het renoveren van de buitenschil van uw huis dient best al eerste te worden aangepakt. Hieronder de lijst volgens prioriteit:</p>
                        <div className="flex flex-col items-start justify-start">
                            <p className="font-Source text-p2Alt font-regular text-center mb-[5px]">• Hoofddak</p>
                            <p className="font-Source text-p2Alt font-regular text-center mb-[5px]">• Bijdak</p>
                            <p className="font-Source text-p2Alt font-regular text-center mb-[5px]">• Gevels</p>
                            <p className="font-Source text-p2Alt font-regular text-center mb-[20px]">• Ramen </p>
                        </div>
                        <PrimaryButton content="Plan aanpassen" onClick={() => setIsModalOpen(false)} />
                        <p className="font-Source font-regular underline text-p2Alt text-[#5F5F5F] text-center mt-[5px] cursor-pointer" onClick={skipCheck}>Verder gaan</p>
                    </div>
                )}
                {/* empty view for when user improves nothing and decided to move forward */}
                {modalViewState === "empty" && (
                    <div className="w-full flex flex-col justify-center items-center box-border px-[50px] animate-fade-in-top">
                        <p className="font-Source text-2xl font-bold text-center mb-[15px]">Waarschuwing</p>
                        <p className="font-Source font-regular text-p2 text-[#5F5F5F] text-center mb-[25px]">Tijdens het optimaliseren van uw huis is het voor sommige optimalisaties belangrijk dat deze in een bepaalde volgorde gebeuren.</p>
                        <p className="font-Source text-p2Alt font-bold text-center mb-[5px]">Je hebt geen optimalisaties</p>
                        <PrimaryButton content="Annuleren" onClick={() => setIsModalOpen(false)} />
                    </div>
                )}
            </div>
            {pdfDataUpdate === true ?
                <div id="pdf-dom" style={{ display: "none" }}>
                    <PdfDom resultLabelData={resultLabelData} renderUpdate={renderUpdate} />
                </div>
                : <></>
            }
        </div>
    )
}

export default SimulationModal;
