import {uniqBy} from "lodash";
import {Col, Row} from "antd";
import React, {Dispatch, FC, useEffect, useState} from "react"

import FormCityStep from "./FormStep2Cities";
import { Tag } from "../../shared/elements/tag";
import {CheckBox} from "../../shared/elements/Checbox";
import { GetAPIService } from "../../context/services";
import {City, Province} from "../modals/modal/Province";
import {PrimaryButton, SecondaryButton} from "../../shared/buttons/igemo_button";
import OrganizationService from "../../api/Organizations";
import axios from "axios";
import { ModalBoxCheckBox } from "../../shared/elements/ModalBoxCheck";

interface AddOrgModalStruct {
    setProvincesList: Dispatch<any>
    handleClose: Dispatch<boolean>
    setFormStep: Dispatch<string>
    provincesList: Array<any>
    editOrganization: string
    messageApi?: any
}

const FormStep2: FC<AddOrgModalStruct> = ({
    messageApi,
    handleClose,
    setFormStep,
    provincesList,
    editOrganization,
    setProvincesList,
}) => {
    const [updateError, setUpdateError] = useState('');
    const [updateDataFetched, setFetchUpdateData] = useState(false);
    const [updateProvincesList, setUpdateProvincesList] = useState([]);
    const [checkup, setChekup] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            GetAPIService('geo/region/')
            .then((provinceResponse) => {
                const provinces = provinceResponse.data as [Province];

                let updatedProvinces = provinces.map((value: Province) => {
                return {
                    ...value,
                    active: false,
                    id: value.id,
                    checked: false,
                    label: value.nl,
                    cities: [],
                    payloadProvinceId: null,
                };
                });
                updatedProvinces = uniqBy(updatedProvinces, 'id');
                setProvincesList(updatedProvinces);
            })
            .catch((error) => {
                console.error('Error fetching provinces:', error);
            });
        };
        if (!provincesList?.length) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        const fetchDomainDetails = async () => {
            try {
                const response = await OrganizationService.getOrganizationProvinceDetails(editOrganization);
                const {data} = response;
                let p_list:any = [];
                data?.forEach((p: any) => {
                    updateSelectedProvinceList(p, 'active', true, true);
                    updateSelectedProvinceList(p, 'payloadProvinceId', p?.id, true);
                    p_list.push({
                        provinceId: p?.provinceId,
                        cities: p?.municipalities,
                    });
                });
                p_list = uniqBy(p_list, 'provinceId');
                setUpdateProvincesList(p_list);
                setTimeout(() => {
                    updateList();
                }, 200);
            } catch (error) {
                console.error(error);
            } finally {
                // setLoading(false);
            }
        };
        if (editOrganization && provincesList?.length && !updateDataFetched) {
            setFetchUpdateData(true);
            fetchDomainDetails();
        }
    }, [provincesList]);

    const fetchProvinceCities = async (city: any, update: boolean = false) => {
        try {
            const id = update ? city?.provinceId : city.id;
            const cityResponse = await GetAPIService(`geo/citypregion/${id}`);
            const cities = cityResponse.data.data as [City];

            const updatedCities = cities.map((value: City) => {
                return {
                    label: value.nl ? `${value.nl} - ${value.zipCode}` : `${value.fr} - ${value.zipCode}`,
                    id: parseInt(value.id),
                    active: false,
                    payloadCityId: null,
                };
            });
            updateSelectedProvinceList(city, 'cities', updatedCities, update);
        } catch (error) {
            // Handle error if API request fails
            console.error('Error fetching provinces:', error);
        }
    };

    const updateSelectedProvinceList = (item: any, key: string, value: any, update: boolean = false) => {
        const id = update ? item?.provinceId : item.id;
        const index = provincesList?.findIndex(f => f.id === id);
        const updatedValue = [...provincesList];
        console.log(item, key, value);
        if (key === 'active' && value === true) {
            fetchProvinceCities(item, update);
        } else if (key === 'active' && value === false) {
            updatedValue[index].cities = [];
        } else if (key === 'checked' && value === true) {
            setChekup(!checkup);
            updatedValue[index].cities = item.cities.map((c: any) => ({...c, active: true}));
        } else if (key === 'checked' && value === false) {
            setChekup(!checkup);
            updatedValue[index].cities = item.cities.map((c: any) => ({...c, active: false}));
        }
        updatedValue[index][key] = value;
        setProvincesList(updatedValue);
    };

    const checkProvinceCities = () => {
        return provincesList.some(p => p.cities?.length);
    };

    const addProvinceToOrganization = () => {
        const p_list:any[] = [];
        provincesList.forEach((p: Province) => {
            console.log(p)
           if (p.active) {
               const p_cities:any[] = [];
               p.cities.forEach((c: City) => {
                   if (c.active) {
                       p_cities.push({ municipalityId: c?.id, id: c?.payloadCityId });
                   }
               });
               p_list.push({
                   domainId: editOrganization,
                   provinceId: p?.id,
                   id: p?.payloadProvinceId,
                   municipalities: p_cities,
               });
           }
        });
        if (!p_list.some((s) => s?.municipalities?.length === 0)) {
            addProvinces({ provinces: p_list});
        } else {
            messageApi.open({
                type: 'error',
                content: 'Selecteer ten minste één gemeente uit de geselecteerde provincies.',
                duration: 3,
            });
        }
    }

    const addProvinces = async (payload: any) => {
        try {
            setUpdateError('');
            const response = await OrganizationService.addOrganizationProvinces(editOrganization, payload, {});
            const {data} = response;
            console.log(messageApi)
            if (response?.success === true) {
                messageApi.open({
                    type: 'success',
                    content: 'Het bijwerken van provinciegegevens is gelukt.',
                    duration: 3,
                });
                setUpdateError("");
                handleClose(true);
            } else {
                messageApi.open({
                    type: 'error',
                    content: 'Het is niet gelukt de provinciegegevens bij te werken',
                    duration: 3,
                });
                setUpdateError('Er is iets misgegaan tijdens het updaten!');
            }
        } catch (error) {
            console.error(error);
            setUpdateError('Er is iets misgegaan tijdens het updaten!');
        } finally {
            // setLoading(false);
        }
    };
    const updateList = () => {
        let newList = [...provincesList];
        let ischecked = false;
        newList = newList.map((province: Province) => {
            console.log('province', province);
            if(province.cities.length){
                console.log(province.cities[0].active, province.cities.length)
                ischecked = (province.cities.filter((s: any) => s.active).length === province.cities.length)
                console.log('ischecked in if', ischecked);
                province.checked = ischecked && !province.checked ?  ischecked : province.checked
            }
            console.log('ischecked', ischecked);
            return province;
        });
        setProvincesList(newList);
    }
    const getProvinceCitiesList = () => {
        let ischecked=false
        return (
            <div className="col-span-12">
                {provincesList.map((province: Province, index: number) => {
                    //updateList(province, index);
                        //updateList()
                   // ischecked = (province.cities.filter((s: any) => s.active).length === province.cities.length)
                        // @ts-ignore
                      //  province.checked = ischecked && !province.checked ?  ischecked : province.checked;
                    return province?.cities?.length ? (
                        <div className="col-span-12 mb-[25px]" key={province?.id || 'id'}>
                            <span className="font-semibold" style={{ position: 'relative', top: -4 }}>{province?.label + ' '}</span>
                            <div className="ml-2" style={{ display: 'inline' }}>
                                <ModalBoxCheckBox
                                    onChange={()=> updateSelectedProvinceList(province, 'checked', !province?.checked)}
                                    status={province?.checked}
                                    name="all_municipalities"
                                    content="Alle gemeenten"
                                    defaultChecked = {province?.checked}
                                />
                            </div>

                                <FormCityStep
                                    checkup={checkup}
                                    province={province}
                                    cities={province?.cities || []}
                                    updateProvincesList={updateProvincesList}
                                    updateSelectedProvinceList={updateSelectedProvinceList}
                                />

                        </div>
                    ) : null
                }
                )}
            </div>
        )
    }

    const getProvinceCitiesOverview = () => {
        return (
            <div className="col-span-12">
                {provincesList.map((province: Province) => (
                    (province?.cities?.length > 0) ? (
                        <div className="col-span-12 mb-[25px]" key={province?.id || 'id'}>
                            <div className="font-semibold">{province?.label + ' '}</div>
                            <Row>
                                {province.checked ? <p className="text-black mt-2 ml-1">Alle gemeenten zijn geselecteerd</p> : ((province.cities.filter((s: any) => s.active).length === province.cities.length) ? <p className="text-black mt-2 ml-1">Alle gemeenten zijn geselecteerd</p> :
                                    <>
                                        {province.cities.some((s: any) => s.active) ? (
                                            province?.cities.map((city: City) => {
                                                return (
                                                    city?.active && (
                                                        <Col className="mt-2 mr-4 no-lb cursor-pointer primary-main text-white px-3 py-3 text-xs rounded fit-content">
                                                            {city.label}
                                                        </Col>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <p className="text-error">Geen gemeente geselecteerd.</p>
                                        )}
                                    </>
                                )}
                            </Row>
                        </div>
                    ) : null
                ))}
            </div>
        )
    }

    return (
        <>
            <div className="border-grey-300 border-t-[1px] border-b-[1px]">
                {updateError && <div className="px-8 mt-6 text-error">{updateError}</div>}
                <div className="px-8 py-6">
                    <div className={`lg:col-span-2 w-full mb-3  relative w-full  font-semibold text-[#00000040] `}>
                        SELECTEER DE PROVINCIES
                    </div>
                    <Row>
                        <Col span={4}>
                            <p className="col-span-2 mt-2 flex items-center font-semibold text=[#0C3C21]">
                                PROVINCIES
                            </p>
                        </Col>
                        <Col span={20}>
                            <div className="grid lg:grid-cols-8 grid-cols-12">
                                <Row className="col-span-10">
                                    {provincesList.map((province: City) => {
                                        return (
                                            <Col className="mr-3 mt-2">
                                                <Tag
                                                    text={province?.label || ''}
                                                    active={province?.active || false}
                                                    onClick={()=> updateSelectedProvinceList(province, 'active', !province?.active || false)}
                                                />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
                {checkProvinceCities() ? (
                    <div className="px-8 pt-2 pb-10">
                        <div className="grid grid-cols-12">
                            <div className={`lg:col-span-4 w-full mb-3  relative w-full  font-semibold text-[#00000040] `}>
                                STEDEN & GEMEENTEN LIJST
                            </div>
                            <div className="col-span-12 flex items-center">
                                {getProvinceCitiesList()}
                            </div>
                        </div>
                    </div>
                ) : null}


                {checkProvinceCities() ? (
                    <div className="px-8 pt-4 pb-10 border-grey-300 border-t-[1px]">
                        <div className="grid grid-cols-12">
                            <div className={`lg:col-span-4 w-full mb-3  relative w-full  font-semibold text-[#00000040] `}>
                                OVERZICHT VAN DE SELECTIE
                            </div>
                            <div className="col-span-12 flex items-center">
                                {getProvinceCitiesOverview()}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>

            <div className="px-8">
                <div className="flex justify-end mt-4">
                    <SecondaryButton classes="mr-6" content="Annuleer" onClick={() => handleClose(true)}/>
                    <PrimaryButton content="Opslaan" onClick={addProvinceToOrganization}/>
                </div>
            </div>
        </>
    )
}

export default FormStep2;
