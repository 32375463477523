import React, {FC, useEffect, useState} from "react";

import OfferService from "../../api/Offers";
import {isEmpty} from "lodash";
import OfferComparisonCard from "./OfferComparisonCard";

interface documentStruct {
    contractors: [],
    contList: [],
    offerReqId: any,
    renovationId: any,
}

const DocumentsList: FC<documentStruct> = ({
   renovationId,
   contractors,
   contList,
   offerReqId,
}) => {
    const [isLoading, setLoading] = useState(false);
    const [servicesList, setServicesList] = useState<any>({});
    const [adminServicesList, setAdminServicesList] = useState<any>({});
    const auth = JSON.parse(localStorage.getItem("auth") || "{}");
    const [addedIds, setAddedIds] = useState<any>([]);
    const [addedAdminIds, setAddedAdminIds] = useState<any>([]);

    useEffect(() => {
        if (auth.uid) {
            fetchRenovationServicesDetails();
            fetchRenovationServicesDetails2()
        }
    }, [auth?.uid]);

    const fetchRenovationServicesDetails = async () => {
        try {
            setLoading(true)
            const response = await OfferService.getAllServiceRenovationsOffers(offerReqId,renovationId);

            setServicesList(response);
            setLoading(false);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchRenovationServicesDetails2 = async () => {
        try {
            setLoading(true)
            const response = await OfferService.getAllServiceRenovationsForContractor(undefined);

            setAdminServicesList(response);
            setLoading(false);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getSelectedTabData = () => {
        if (!isEmpty(adminServicesList?.servicesGeneralGroup?.services)) {
            return adminServicesList?.servicesGeneralGroup?.services[0];
        }
        return [{ id: '1', name: '', children: []}];
    };

    return (
        <>
            {isLoading ? (
                <div className="px-16 py-16" style={{ textAlign: 'center' }}>Laden...</div>
            ) : (
                !isEmpty(servicesList) ? (
                    <>
                        <OfferComparisonCard
                            adminServicesList={getSelectedTabData()}
                            contractorList={contractors}
                            setAddedIds={setAddedIds}
                            addedIds={addedIds}
                        />

                        <OfferComparisonCard
                            contractorList={contractors}
                            setAddedIds={setAddedIds}
                            addedIds={addedIds}
                            cardData={servicesList}
                        />
                        <div className = "mt-4">
                            <div className="bg-[#FAFAFA] p-3 rounded">
                                <p className="text-[#5F5F5F]">
                                    Deze controlelijst is louter informatief en niet-limitatief. Het kan zijn dan de
                                    werkelijke offerte afwijkt van de controlelijst.
                                    Gelieve de offerte steeds grondig na te lezen over wat inclusief en exclusief is.
                                </p>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="px-16 py-16" style={{ textAlign: 'center' }}>Geen data.</div>
                )
            )}
        </>
    )
};

export default DocumentsList;

