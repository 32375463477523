import React from "react";
import { PrimaryButton } from "../../shared/buttons/igemo_button";
import { AiOutlineCloseCircle } from "react-icons/ai";

type SimulationSubmitModalProps = {
    modalState?: boolean,
    setModalState: (modalState: boolean) => void,
    children?: React.ReactNode,
    hideCloseButton?: boolean
}

const SimulationSubmitModal = ({ modalState = false, setModalState = () => {}, children, hideCloseButton = false }: SimulationSubmitModalProps) => {

    const handleModalClose = () => {
        setModalState(false);
    }

    return (
        <div className = "w-screen h-screen fixed top-0 left-0 bg-black/[0.3] z-50 justify-center items-center animate-fade-in-top" style = {{display: modalState ? "flex" : "none"}}>
            <div className = "w-full flex justify-center px-[16px] md:px-16 lg:px-16 xl:px-32">
                <div className = "md:container md:mx-auto">
                    <div className = "w-full bg-white box-border p-[16px]">
                        <div className = "flex w-full justify-end cursor-pointer" onClick = {handleModalClose}>
                            {!hideCloseButton && <AiOutlineCloseCircle size = "24px" color = "black" />}
                        </div>
                        <div className = "w-full flex flex-col items-center box-border px-[10px] md:px-[100px]">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SimulationSubmitModal;