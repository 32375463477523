import React, { useContext, useState, useEffect, useRef, RefObject, useMemo } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";

import { crossRed, ServiceItem, tickGreen } from "./formCategories";
import Layout from "../../components/layout";
import ListCard from "../../shared/ListCard";
import { RenderForm } from "./renderForm";
import dwnColorGrey from "../../assets/icons/pdf.svg";
import backIcon from "../../assets/icons/arrow-left-circle.svg";
import arrowLeft from "../../assets/icons/arrow-left-circle.svg";
import { PrimaryButton, RequestButton, UnRoundedDwnButton } from "../../shared/buttons/igemo_button";
import { ContractorInfoModal } from "../contractor/contractor_info_modal";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { DeleteAPIService, GetAPIService, PostAPIService, PostFormAPIService, PutAPIService, PutFormAPIService } from "../../context/services";
import SimulationSubmitModal from '../../components/simulationSubmitModal/simulationSubmitModal';
import { getServiceCardValidations } from './serviceCardValidation';
import axios, { AxiosResponse } from 'axios';
import {
    arrangeQuestionAnswers,
    formatQuestionsGeneric,
    formatQuestionsSpecific
} from "../../utils/formatters/serviceListFormatters";
import form from "../form";
import moment from "moment";
import { message } from 'antd';
import { ServiceAnswerType } from '../../models/applicationState';
import getCardDescription from '../../components/services/getCardDescription';
import Spinner from '../../shared/elements/Spinner';
import SubmitReviewModal from '../../components/submitReviewModal/submitReviewModal';
import ReviewListModal from '../../components/reviewListModal/reviewListModal';

type serviceCardValidationType = {
    generic: boolean,
    renovation: boolean,
    contractor: boolean
}[]

interface formType {
    offerRequestId: string,
    formType: string,
    renovationType: string,
    renovationTypeId: string | number,
    buttonType: string,
}


const Simulations = () => {
    const [formView, toggleFormView] = useState<formType>({
        offerRequestId: '',
        renovationType: '',
        renovationTypeId: '',
        formType: '',
        buttonType: '',
    });
    const { simulationId } = useParams<any>();
    const [payload, setPayload] = useState<any>({});
    const [selectedContractorList, setSelectedContractorList] = useState(0);
    const [selectedContractor, setSelectedContractor] = useState<null | string | number>(null);
    const { state, dispatch } = useContext(GlobalContext)
    const [servicesList, setServiceList] = useState<Array<ServiceItem>>([])
    const [questionFields, setQuestionFields] = useState<any>({})
    const [questionAnswers, setQuestionAnswers] = useState<any>({})
    const [contractorData, setContractorData] = useState<any>([])
    const [pageState, setPageState] = useState(false);
    const [offerRequestIdState, setOfferRequestIdState] = useState<any>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [serviceCardValidationList, setServiceCardValidationList] = useState<serviceCardValidationType>([]);
    const [loading, setLoading] = useState(false);
    const [serviceAnswerList, setServiceAnswerList] = useState<ServiceAnswerType[]>([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [disableSaveBtn, setDisableSaveBtn] = useState(false)
    const [savingData, setSavingData] = useState(false)
    const [reviewModalState, setReviewModalState] = useState<any>({});

    const simulationInitDone = localStorage.getItem('simulationInitDone')
    const navigate = useNavigate();
    const formRef = useRef() as RefObject<HTMLDivElement>;

    const handleContractListExpand = (index: number) => {
        setSelectedContractorList((index + 1) === selectedContractorList ? 0 : index + 1);
    }

    useEffect(() => {
        initializeSimulation()
    }, [simulationInitDone, simulationId, pageState])

    const initializeSimulation = () => {
        if (simulationInitDone === "true") {
            GetAPIService(`offer/request/select/${simulationId}`).then((servicesRes: any) => {
                console.log("servicesRes=>", servicesRes)

                formatAndSetServicesList(servicesRes)
                const { data } = servicesRes.data;
                const { cpOfferRequestRenovations } = data.offerRequests;
                setContractorData(cpOfferRequestRenovations);
            }).catch((err) => {
                console.log('err in services fetching is', err)
            })
        } else if (simulationId) {
            GetAPIService(`offer/request/init/${simulationId}`).then((servicesRes: any) => {
                console.log("servicesRes=>", servicesRes)

                localStorage.setItem('simulationInitDone', "true")
                formatAndSetServicesList(servicesRes)
                const { data } = servicesRes.data;
                const { cpOfferRequestRenovations } = data.offerRequests;
                setContractorData(cpOfferRequestRenovations);
            }).catch((err) => {
                console.log('err in services fetching is', err)
            })
        }

    }
    useEffect(() => {
        formatQuestionAnswers()
    }, [questionAnswers])

    const formatQuestionAnswers = () => {
        if (questionAnswers) {
            GetAPIService('renovation/question').then((questionsRes => {
                const questionsData: { generic: any; renovation: any } = questionsRes?.data
                const questionsFormattedData: any = {
                    '1': {},
                    '2': {}
                }
                if (questionsData) {
                    questionsFormattedData['1'] = { ...formatQuestionsGeneric(questionsData?.generic, questionAnswers) }
                    questionsFormattedData['2'] = { ...formatQuestionsSpecific(questionsData?.renovation, questionAnswers) }
                    setQuestionFields(questionsFormattedData)
                }
            })).catch((err: any) => {
                console.log('err is ', err)
            })
        }
    }
    console.log("questoons are ", questionAnswers)
    useEffect(() => {
        if (Object.keys(questionFields).length) {
            const servicesListClone = [...servicesList]
            servicesListClone.forEach((service: any) => {
                service['isGreen'] = questionFields['1'][service?.title].every((item: any) => item.answered)
                service['generalGreen'] = questionFields['1'][service?.title].every((item: any) => item.answered)
                service['renovationGreen'] = questionFields['2'][service?.title].every((item: any) => item.answered)
                service['statusIconThree'] = tickGreen
            })
            setServiceList(servicesListClone)
        }
    }, [questionAnswers, questionFields])

    useEffect(() => {
        if (offerRequestIdState) {
            console.log({ servicesList })
            getServiceCardValidations(offerRequestIdState, setServiceCardValidationList, servicesList)
        }
    }, [offerRequestIdState, servicesList])

    useEffect(() => {
        formRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, [formView])

    useEffect(() => {
        if (simulationId) {
            // const auth = JSON.parse(localStorage.getItem("auth")!)
            const authOrigin = localStorage.getItem("auth");
const auth = authOrigin && authOrigin?.length > 0? JSON.parse(localStorage.getItem("auth")!) : ""
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth?.token}`,
            };
            axios.get(process.env.REACT_APP_BACKEND_URL + "simulation/resultan/" + simulationId, { headers }).then((res) => {
                setServiceAnswerList(res.data)
            }).catch(error => console.log(error))
        }
    }, [])

    const formatAndSetServicesList = (servicesApiRes: any) => {
        const serviceData = servicesApiRes?.data?.data.offerRequests
        const formattedServices: Array<ServiceItem> = []
        if (serviceData) {
            serviceData.cpOfferRequestRenovations.forEach((serviceData: any) => {
                formattedServices.push({
                    id: serviceData.id,
                    offerRequestId: serviceData.offerRequestId,
                    title: serviceData?.renovation?.title,
                    type: serviceData.renovation.key,
                    key: serviceData?.renovation.key,
                    renovationId: serviceData.renovationId,
                    details: serviceData?.renovation?.title,
                    isGreen: true,
                    generalGreen: false,
                    renovationGreen: false,
                    statusIconThree: crossRed
                })
            })
        }
        const localQA: any = {}
        for (const question of serviceData?.cpOfferRequestRenovationInformations) {
            localQA[question?.questionId] = question
        }
        setOfferRequestIdState(serviceData?.id)
        setQuestionAnswers(localQA)
        setServiceList(formattedServices)
    }

    const showApiError = () => {
        messageApi.open({
            type: 'error',
            content: 'Kon informatie niet opslaan.',
            duration: 3,
        })
    }

    const submitAnswers = () => {
        const formattedData = arrangeQuestionAnswers(payload, questionAnswers)
        const promises: Promise<AxiosResponse<any, any>>[] = []
        setDisableSaveBtn(true)
        setSavingData(true)

        // UPDATE
        if (formattedData.update.general.length) {
            let payload: any = { save: [] }
            for (const createOfferQuestion of formattedData.update.general) {
                payload.save.push(createOfferQuestion)
            }
            promises.push(PutAPIService(`offer/request/information/update/${formView.offerRequestId}`, payload))
        }
        for (const uploadPayload of formattedData.update.upload) {
            promises.push(PutFormAPIService(`offer/request/information/upload/add/${formView.offerRequestId}`, uploadPayload))
        }

        for (const deletePayload of formattedData.delete.upload) {
            promises.push(DeleteAPIService(`offer/request/information/upload/remove/${formView.offerRequestId}`, deletePayload))
        }

        // CREATE
        if (formattedData.create.general.length) {
            let payload: any = { save: [] }
            for (const createOfferQuestion of formattedData.create.general) {
                payload.save.push(createOfferQuestion)
            }
            promises.push(PostAPIService(`offer/request/information/save/${formView.offerRequestId}`, payload))
        }
        for (const uploadPayload of formattedData.create.upload) {
            promises.push(PostFormAPIService(`offer/request/information/upload/${formView.offerRequestId}`, uploadPayload))
        }

        let hasErrors = false
        Promise.allSettled(promises).then(async (values: any) => {
            values?.map((value: any) => {
                if(value?.status === 'rejected') hasErrors = true
            })
            setTimeout(()=>{
                window.location.reload()
                setDisableSaveBtn(false)
                setSavingData(false)
            }, 1200)
        })
        .then(()=>{
            // if(hasErrors ) showApiError()
        })
        .catch((err: any) => {
            console.log('err is ', err)
        })
    }

    const handleContractorAdd = (renovationId: number, contractorId: number) => {
        if (offerRequestIdState) {
            axios.put(process.env.REACT_APP_BACKEND_URL + "offer/request/contractor/selection/" + offerRequestIdState, {
                renovationId,
                contractorId
            }).then(response => {
                // run this to fetch simulation data again
                console.log(response.data)
                setPageState(!pageState)
            }).catch(error => console.log(error))
        }
    }

    const modifyRenovationData = (data: any) => {
        if (data?.id) {
            let dataCopy = { ...data };
            delete dataCopy.cpOfferRequestRenovationContractors;
            return dataCopy;
        }
        else {
            return {};
        }
    }

    // extract house owner renvoation(services ids)
    const extractRenovationIds = (data:any) => {
         if(data.length > 0){
             return data.map((renovation:any) => renovation.renovationId)

         }else{
            return []
         }
    }

    const openModal = () => {
        const unfilledServiceList = getUnfilledServices();
        if (unfilledServiceList.length > 0) {
            setIsModalOpen(true)
        }
        else {
            goToDraftPage();
        }
    }

    const goToDraftPage = () => {
        if (offerRequestIdState) {
            navigate("/services/draft/" + offerRequestIdState)
        }
    }

    const isSubmitButtonActive = useMemo(() => {
        console.log("validatiosna re ", serviceCardValidationList)
        for (const service of serviceCardValidationList) {
            if (service?.generic && service?.renovation && service?.contractor) {
                return true
            }
        }
        return false

    }, [serviceCardValidationList])


    const getUnfilledServices = () => {
        if (serviceCardValidationList.length > 0) {
            const unfilledServiceList: string[] = [];
            serviceCardValidationList.forEach((item: any, index: number) => {
                if (!item.generic || !item.renovation || !item.contractor) {
                    unfilledServiceList.push(servicesList[index]?.title)
                }
            })
            return unfilledServiceList;
        }
        else {
            return [];
        }
    }

    const handleDownloadClick = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_BACKEND_URL + `simulation/details/${simulationId}`).then((response) => {
            if (response?.data) {
                const downloadLink = document.createElement('a');
                downloadLink.target = "blank"
                const date = moment(new Date()).format("dd mm yyyy")
                const random = () => Math.floor(Math.random() * (999999 - 1)) + 1;

                downloadLink.href = `${response?.data?.pdf}`;
                downloadLink.setAttribute('download', `Check_je_offerte_[${date}]_${random()}`);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                // downloadLink.parentNode.removeChild(downloadLink);
                // downloadLink.target = '_blank'; // Set target to '_blank' to open in a new tab
                // downloadLink.rel = 'noopener noreferrer';
                // downloadLink.download = `${response?.data?.pdf}`;
                // downloadLink.click();
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }

    return (
        <Layout>
            <div className="container mx-auto px-2 md:px-4 mb-16">
                <Link to="/simulations">
                    <div className="flex mt-4 items-center">
                        <img src={arrowLeft} alt="back" height={18} width={18} />
                        <p className="ml-2 no-underline hover:underline cursor-pointer primary-text">
                            Terug naar simulaties
                        </p>
                    </div>
                </Link>

                <div className="flex flex-col md:flex-row gap-4 relative">
                    <div className="w-full mt-5 px-[10px] md:px-8 py-4 bg-white shadow-xl" style={formView?.formType ? { flexBasis: '100%' } : { flexBasis: '100%' }}>

                        <div className="grid grid-cols-2">
                            <h1 className="text-xl sm:text-xl md:text-2xl xl:text-2xl  2xl:text-3xl font-semibold border-igemo">
                                Offerteaanvraag
                            </h1>

                            <div className="text-right">
                                <UnRoundedDwnButton
                                    classes="ml-2"
                                    onClick={() => { handleDownloadClick() }}
                                    content="Download"
                                    icon={dwnColorGrey}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col md:block pt-4">
                            {servicesList.map((item, idx) => (
                                <ListCard
                                    key={idx}
                                    item={item}
                                    selected={selectedContractorList === idx + 1}
                                    contractorList={contractorData[idx]?.cpOfferRequestRenovationContractors}
                                    renovationData={modifyRenovationData(contractorData[idx])}
                                    listRowClickHandler={(id) => setSelectedContractor(id)}
                                    handleContractorAdd={handleContractorAdd}
                                    generalGreen={serviceCardValidationList[idx]?.generic || false}
                                    renovationGreen={serviceCardValidationList[idx]?.renovation || false}
                                    contractor={serviceCardValidationList[idx]?.contractor}
                                    getCardDescription={(key) => getCardDescription(key, serviceAnswerList)}
                                    renovationIds ={extractRenovationIds(contractorData)}
                                    contractorsData={contractorData}
                                    reviewModalState = {reviewModalState}
                                    setReviewModalState = {setReviewModalState}
                                    buttons={<>
                                        <RequestButton
                                            hollow
                                            classes="w-full"
                                            content="Algemene informatie"
                                            onClick={() => toggleFormView({ offerRequestId: item.offerRequestId, renovationTypeId: item.id, renovationType: item?.title, formType: '1', buttonType: 'Algemene informatie' })}
                                        />
                                        <RequestButton
                                            hollow
                                            classes="w-full mt-1"
                                            content="Specifieke informatie"
                                            onClick={() => toggleFormView({ offerRequestId: item.offerRequestId, renovationTypeId: item.id, renovationType: item?.title, formType: '2', buttonType: 'Specifieke informatie' })}
                                        />
                                        <RequestButton
                                            classes="w-full mt-1"
                                            content="Kies je aannemers"
                                            onClick={() => handleContractListExpand(idx)}
                                        />
                                    </>
                                    }
                                />
                            ))}
                        </div>
                    </div>

                    {formView.formType ? (
                        <div className="flex w-[460px] max-w-[90%] md:w-full max-h-[100%] md:max-w-auto bg-white fixed right-0 top-0 shadow overflow-y-auto h-fit z-[999]" ref={formRef} style={formView?.formType ? { width: '460px' } : { width: '0%' }}>
                            <div className="text-center w-full text-left">

                                <div className="flex text-xl font-semibold py-4" style={{ borderBottom: '1px solid #e1e1e1' }}>
                                    <div className="w-1/12 pl-2 cursor-pointer" onClick={() => toggleFormView({ offerRequestId: '', renovationType: '', renovationTypeId: '', formType: '', buttonType: '' })}>
                                        <img
                                            alt={"icon"}
                                            src={backIcon}
                                            className="w-12 h-8"
                                        />
                                    </div>
                                    <div className="w-11/12">
                                        {formView.buttonType}
                                    </div>
                                </div>

                                <div className='pb-4 px-4'>
                                    <RenderForm payload={payload} setPayload={setPayload} type={formView.renovationType} formFieldsData={questionFields} questionAnswers={questionAnswers} questionKind={formView.formType} serviceAnswerList={serviceAnswerList} />

                                </div>

                                {/*{formView.formType === 'algemene' ? <div className="px-4">*/}
                                <div className="flex justify-end items-center mb-[15px] mr-[15px]">
                                    {savingData && <Spinner className="h-7 w-7 mr-3" />}
                                    <PrimaryButton disabled={Object.keys(payload).length < 1 || disableSaveBtn} content="Opslaan" onClick={submitAnswers} />
                                </div>
                                {/*</div>*/}
                                {/*: null}*/}

                            </div>
                        </div>
                    ) : null}

                </div>

                <div className="w-full flex justify-end mt-[25px]">
                    {isSubmitButtonActive ?
                        (
                            <PrimaryButton content="Vraag je offerte aan" onClick={openModal} />
                        )
                        :
                        (
                            <button type="button" className="px-6 py-2 md:px-9 md:py-3 lg:px-12 lg:py-4 text-white text-xs md:text-sm lg:text-lg font-semibold relative" onClick={openModal} disabled={!isSubmitButtonActive}>
                                <div className = "w-full h-full absolute top-0 left-0 primary-main opacity-[0.3]" />
                                <div className="flex relative">
                                    <div className="flex justify-center items-center flex-grow">Vraag je offerte aan</div>
                                </div>
                            </button>
                        )
                    }
                </div>
            </div>
            <ContractorInfoModal
                title="Gegevens van dev aannemer"
                open={selectedContractor !== null}
                setOpen={setSelectedContractor}
                selectedContractor={selectedContractor}
            />
            <SimulationSubmitModal modalState={isModalOpen} setModalState={setIsModalOpen}>
                <p className="font-Source font-bold text-black text-[30px]">Waarschuwing</p>
                <p className="font-Source font-regular text-[#5F5F5F] text-p2Alt mt-[33px] text-center">Voor volgende diensten is niet alle noodzakelijke informatie ingevuld. Er zal voor deze diensten geen offerte aanvraag voor verzonden kunnen worden.</p>
                <div className="w-full flex justify-center my-[25px]">
                    <div className="flex flex-col">
                        {getUnfilledServices().map((item: string, idx: number) => (
                            <div key={idx} className="flex items-center">
                                <div className="w-[10px] h-[10px] rounded-[15px] bg-[red] mr-[10px] flex justify-center items-center">
                                    <div className="w-[10px] h-[10px] rounded-[10px] bg-[red] animate-ping" />
                                </div>
                                <p className="font-Source font-semibold text-black text-p2Alt text-center">{item}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <PrimaryButton content="Annuleren" onClick={() => setIsModalOpen(false)} />
                <p className="font-Source font-regular text-[#5F5F5F] text-p2Alt mt-[33px] underline cursor-pointer mt-[16px] text-center" onClick={goToDraftPage}>Toch doorgaan</p>
            </SimulationSubmitModal>
            {contextHolder}
            {/* {reviewModalState?.id ? <SubmitReviewModal offerRequestContractorId = {reviewModalState?.id} contractorId = {reviewModalState?.contractor?.id} homeOwnerId = {2} closeModal = {() => setReviewModalState({})} /> : <></>} */}
            {reviewModalState?.id ? <ReviewListModal closeModal = {() => setReviewModalState({})} contractorData = {reviewModalState} /> : <></>}
        </Layout>
    )
}

export default Simulations
