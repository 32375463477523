export const pageTitles = {
    home: 'Gent: Check je huis',
    questionnaire1: 'Type woning | Gent: Check je huis',
    questionnaire2: 'Bouwjaar | Gent: Check je huis',
    questionnaire3: 'Oppervlakte | Gent: Check je huis',
    questionnaire4: 'Bewoner | Gent: Check je huis',
    questionnaire5: 'Bewoners | Gent: Check je huis',
    questionnaire6: 'Verwarming | Gent: Check je huis',
    questionnaire8: 'Verwarmingselementen | Gent: Check je huis',
    questionnaire9: 'Verwarming leefruimtes | Gent: Check je huis',
    questionnaire10: 'Verwarming slaapkamers | Gent: Check je huis',
    questionnaire11: 'Warm water | Gent: Check je huis',
    questionnaire12: 'Hoofddak | Gent: Check je huis',
    questionnaire13: 'Bijdak | Gent: Check je huis',
    questionnaire14: 'Verwarmde ruimtes | Gent: Check je huis',
    questionnaire15: 'Ramen leefruimte | Gent: Check je huis',
    questionnaire16: 'Ramen slaapkamers | Gent: Check je huis',
    questionnaire17: 'Voorgevel | Gent: Check je huis',
    questionnaire18: 'Achtergevel | Gent: Check je huis',
    questionnaire19: 'Zijgevels | Gent: Check je huis',
    questionnaire20: 'Vloerisolatie | Gent: Check je huis',
    questionnaire21: 'Ventilatie | Gent: Check je huis',
    questionnaire22: 'Zonnepanelen | Gent: Check je huis',
    questionnaire23: 'Aantal zonnepanelen | Gent: Check je huis',
    questionnaire24: 'Terugdraaiende teller | Gent: Check je huis',
    questionnaire25: 'Digitale meter | Gent: Check je huis',
    questionnaire26: 'Energieverbruik en -productie | Gent: Check je huis',
    questionnaire27: 'Groendak | Gent: Check je huis',
    questionnaire28: 'Voor- of achtertuin | Gent: Check je huis',
    questionnaire29: 'Regenwaterput | Gent: Check je huis',
    questionnaire30: 'Verharding | Gent: Check je huis',
    questionnaire31: 'Klimaat | Gent: Check je huis',
    result: 'Resultaat | Gent: Check je huis',
  }