import React, { FC, useState } from 'react'
import OfferReqStatus from '../../shared/Table/OfferReqStatus'
import OfferRequestDocument from './OfferReqDocument'
import HouseOwnerAction from '../../shared/Table/OfferReqAction'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { uploadsUrl } from '../../shared/Table/common';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import OfferRequestService from '../../api/OfferRequest'

// asset imports here
import { FaStar } from "react-icons/fa";

interface OfferRequestListingsRowProps {
    item: any,
    title: any,
    offerRequestId?: string,
    renovationId?: string,
    setReload?: any,
    handleMenuClick?: any,
    archive: boolean,
    simulation?: any,
    renovationKey?: any,
    setReviewModalState?: any
}

const OfferRequestListingsRow: FC<OfferRequestListingsRowProps> = ({ handleMenuClick, item, title, offerRequestId, renovationId, setReload, archive, simulation, renovationKey, setReviewModalState }) => {
    const [dropdownState, setDropdownState ] = useState(false);

    const logo = item?.contractor?.logo

    const firstName = item?.contractor?.cpContractorUsers?.length ? item?.contractor?.cpContractorUsers[0]?.user?.firstName : ''
    const lastName = item?.contractor?.cpContractorUsers?.length ? item?.contractor?.cpContractorUsers[0]?.user?.lastName : ''
    const name = [firstName, lastName].join(' ')

    const archiveItem = async () => { 
        const payload = {
            renovationId,
            // @ts-ignore
            contractorId: item?.contractor?.id,
            offerRequestId,
            archiveRequestor: (archive !== null && archive !== undefined) ? !archive : true,
        }
        // @ts-ignore
        const response = await OfferRequestService.updateOfferRequestContractor(offerRequestId, payload)
        setReload()

    }
    
    const calculateRating = () => {
        if(item?.rating?.id) {
            const { price, communication, planning, recommended, aftercare } = item?.rating;
            const total = price + communication + planning + recommended + aftercare;
            // return average up to one decimal point
            return (total / 5).toFixed(1);
        }
        else {
            return 0.0;
        }
    }

    return (
        <tr className='relative pl-8 ml-8 border-0 border-b bg-white shadow-md border-1'>
            <td className='px-5 py-2 text-center items-center w-[15%]'>
                <div className='flex  gap-1 items-center'>
                    <img src={uploadsUrl(logo)} alt='' className='h-6 w-6 mt-1' />
                    <p className="ml-1 mt-1 font-semibold">{item?.contractor?.name}</p>
                </div>
            </td>
            <td className='px-5 py-2 text-center items-center w-[15%]'><p className='font-semibold'>{name}</p></td>
            <td className='px-5 py-2 text-center items-center w-[20%]'><p className=''>{item?.contractor?.email}</p></td>
            <OfferReqStatus id={item?.statusId} className='cursor-default w-[20%]' statusUpdateAt={item?.statusUpdateAt}/>
            <td className='w-[10%] h-full px-[10px] py-[10px]'><OfferRequestDocument item={item} offerRequestId={offerRequestId} /></td>
            <td className='items-center px-2 py-1 w-[20%]'>
                <div className = "w-full flex justify-between items-center">
                    <div className = "w-full items-center">
                        <HouseOwnerAction
                            item={item}
                            offerRequestId={offerRequestId}
                            renovationId={renovationId}
                            setReload={setReload}
                            archive = {archive}
                            title={title}
                        />
                    </div>
                    <div className = "flex relative justify-center" tabIndex={0} onBlur = {() => setDropdownState(false)}>
                        <FontAwesomeIcon
                            icon={faEllipsisVertical as IconProp}
                            onClick = {() => setDropdownState(!dropdownState)}
                            className="w-5 h-5 mt-1 cursor-pointer relative shrink-0"
                        />
                        <div className = "bg-white shadow-lg absolute top-[100%] rounded-[5px] right-0 z-[99] w-max min-w-[110px] duration-[300ms] overflow-hidden"  style = {{maxHeight: dropdownState ? "500px" : "0px", opacity: dropdownState ? 1 : 0}}>
                            <div className = "w-full flex items-center py-[10px] cursor-pointer px-[16px] duration-[300ms] primary-main-hover" onClick={handleMenuClick}>
                                <p className = "font-sans font-semibold text-[14px]">Info</p>
                            </div>
                            <div className = "w-full flex items-center py-[10px] cursor-pointer px-[16px] primary-main-hover" onClick={archiveItem} >
                                <p className = "font-sans font-semibold text-[14px]">{archive ? "Archiveren ongedaan maken" : "Archiveren"}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </td>
        </tr>
    )
}

export default OfferRequestListingsRow
