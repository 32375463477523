
import React, { useState, useEffect, useMemo } from "react";
import { useCookies } from 'react-cookie'
import { toLower } from 'lodash'
import {
    pageStatus, getOppervlakteKey, getOppervlakteId, getModalOptions, getPremImprovment,
    getModalSelectedOption, getSelectedOptFun, getOptionFun, getImprovedPages, isEmptyObj, handleSubmitModal, getQuestionKey, handleRemoveChanges
} from "../../utils/reultHelpers";
import {
    getDimensions, getImprovements, getGroups,
    getResultCalculations, getCalculationOfPage6, getAdviceText
} from "../../utils/resultActions";
import Cookies from 'js-cookie';
type cardProps = {


    // data?:Array<string | number>,
    carddata?: { id: number, title: string, pageNumber: string, editText: string, buttonText: string, forSelectingOption: string, type: string },
    // APIdata?: { id: number, improvementName: string, improvementPeram: string, improvementOptions: Array<any | string | number | object> },
    APIdata?: Array<any | string | number | object>,
    buildingData: any,
    resultSetState: any,
    index?: string,
    pageData?: {
        back_text: string,
        title: string,
        next_url: string,
        prev_url: string
    }
    parameters: object,
    storeImprovementData: (area: number, title: string, answer: string) => void,
    removeImprovementData: (title: string) => void,
    setRenderUpdate: any
    renderUpdate: any
    setHasImprovement: any

}


function getCardDescription(status: any, title: any) {
    switch (status) {
        case 'green':
            return "Dit is al in orde! Een verdere investering weegt momenteel niet op tegen de voordelen."
        case '#008000':
            return "Dit is al in orde! Een verdere investering weegt momenteel niet op tegen de voordelen."
        case 'midgreen':
            return 'Dit is al in orde! Een verdere investering weegt momenteel niet op tegen de voordelen.'
        case 'orange':
            return `Ontdek ons advies voor het verbeteren van ${title}.`
        case '#FFA500':
            return `Ontdek ons advies voor het verbeteren van ${title}.`
        case "red":
            return `Een verbetering dringt zich op. Ontdek nu ons advies voor het aanpakken van ${title}.`
        case "#FF0000":
            return `Een verbetering dringt zich op. Ontdek nu ons advies voor het aanpakken van ${title}.`
        default:
            return ""
    }
}

const Card = ({ carddata,
    APIdata,
    buildingData,
    resultSetState,
    index,
    pageData,
    parameters,
    storeImprovementData = () => { },
    removeImprovementData = () => { },
    setRenderUpdate,
    renderUpdate,
    setHasImprovement

}: cardProps) => {
    const [localState, setLocalState] = useState(localStorage.getItem('localState'))
    const [localImprovements, setLocalImprovements] = useState(JSON.parse(localStorage.getItem('localImprovements')!))
    const [answers, setAnswers] = useState(JSON.parse(`${localState}`))
    const [bewerk, setBewerk] = useState({ option: "", adviceContent: "", color: "" })
    const [autoData, setAutoData] = useState({ answerNo: null, area: null });
    const [hide, setHide] = useState(false)
    const [cookies, setCookies] = useCookies()
    const [showHide, setShowHide] = useState(false);
    const [selectedOpt, setSelectedOpt] = useState("");
    const [status, setStatus] = useState({ color: "", key: null });
    const [valuesData, setValuesData] = useState({})
    const apiData = APIdata?.find(x => Number(x.id) === Number(carddata?.id));
    const pageNo: number = parseInt(`${carddata?.pageNumber}`);
    const [buttonText, setButtonText] = useState('Voeg toe aan mijn plan')
    const [editbuttonText, setEditButtonText] = useState(`${carddata?.buttonText}`)
    const [removebutton, setRemoveButton] = useState(false)
    const [cardDataUpdate, setCardDataUpdate] = useState(false);
    const dropDownOptions = useMemo(
        () => getModalOptions(carddata?.title, answers, pageNo, parameters),
        [],
    )
    const [selectedOption, setSelectedOption] = useState(getModalSelectedOption(carddata?.title, answers, pageNo, parameters))

    const [colorUpdate, setColorUpdate] = useState(false)
    const [state, setState] = useState({
        investCost: 0,
        selectVal: selectedOption,
        premAmount: 0,
        calculations: {
            energyDifference: 0,
            energyRate: 0,
            pagesEnergyGas: 0,
            pagesEnergyElec: 0,
            verkoopElec: 0,
        },
        increaseDecreaseTrigger: false,
        adviceContent: '',
        adviceContentXL: '',
        loading: true,
        areaApiValue: 0,
        area: 0,
        vergronenArea: 0,

    })
    const key = useMemo(
        () => getQuestionKey(pageNo, answers?.[6]?.answerNo),
        [pageNo, answers?.localImprovements],
    )

    console.log('cookies', Cookies.get("localState"));

    const answerKey = useMemo(
        () => carddata?.title.toLowerCase().replace(/\s+/g, '_'),
        [carddata?.title],) || ""

    useEffect(() => {
        console.log('pageNumbers', carddata?.pageNumber);
        console.log('pageNumbers', carddata?.title);
        let statusColor: any = pageStatus(pageNo)?.toString();
        // @ts-ignore
        let colorDot = JSON.parse(localStorage.getItem("improvementcolor"))
        if (colorDot) {
            let status = colorDot.filter((el: any) => { return el.key.toLowerCase() === statusColor.toLowerCase() })
            setStatus(status[0])
        }



    }, [localStorage.getItem("improvementcolor")])

    const opperKey = getOppervlakteKey(answers, buildingData[0])
    const oppervlakteId = getOppervlakteId(carddata?.title, pageNo);
    const page13 = answers?.[13] && answers?.[13].answerNo
    const page6 = Number(answers?.[6] && answers?.[6].answerNo)
    const page7 = Number(answers?.[7] && answers?.[7].answerNo)
    const page31 = Number(answers?.[31] && answers?.[31].answerNo)

    const getEnergyTitle = (answerSix: any, answerSeven: any, type: any) => {
        console.log('pages answers', answerSix, answerSeven, answers);
        if((selectedOption == 2 || state.selectVal == 2) && type == 'Verwarming'){
            return 'energie';
        }else {
            switch (answerSix) {
                case 0:
                    return 'gas'
                case 1:
                    return 'electriciteit'
                case 2:
                    return 'stookolie'
                case 3:
                    switch (answerSeven) {
                        case 1:
                        case 15:
                            return 'pellets'
                        case 2:
                        case 16:
                            return 'hout'
                        default:
                            return ''
                    }
                default:
                    return 'gas'
            }
        }
    }

    useEffect(() => {
        if (opperKey != '') {
            getDimensions(
                () => { },
                opperKey,
                oppervlakteId,
                carddata?.title,
                page13,
                setState,
                resultSetState,
                pageNo,
                buildingData
            )
        }
    }, [buildingData])

    var changingClass;
    // @ts-ignore
    if (status && status?.color !== "") {
        // @ts-ignore
        changingClass = `flex md:flex-row md:justify-center flex-col items-center w-full md:h-[175px] bg-white justify-center box-border px-[10px] shadow-2xl border-l-8 border-[${status?.color}]`
        changingClass = changingClass.toString()
    }
    let metaData, selecting_option
    if (pageNo == 6) {
        metaData = answers?.[7];
        metaData.image = answers?.[6].image;
        var secondKey = Object.keys(metaData)[1]; //fetched the key at second index
        selecting_option = secondKey;
    } else {
        metaData = answers?.[pageNo];
        selecting_option = carddata?.forSelectingOption;

    }
    let selected_option = metaData?.[`${selecting_option}`]

    const imporvmentOptions: Array<any | string | number | object> = apiData?.improvementOptions;

    useEffect(() => {
        setLocalState(localStorage.getItem('localState'))
        setAnswers(JSON.parse(`${localState}`))
        const selectedOptText = getSelectedOptFun(
            dropDownOptions,
            localImprovements ? localImprovements?.[pageNo]?.[key] : selectedOption,
        )
        let metaData, selecting_option
        if (pageNo == 6) {
            metaData = answers?.[7];
            metaData.image = answers?.[6].image;
            var secondKey = Object.keys(metaData)[1]; //fetched the key at second index
            selecting_option = secondKey;
        } else {
            metaData = answers?.[pageNo];
            selecting_option = carddata?.forSelectingOption;

        }
        let selected_option = metaData?.[`${selecting_option}`]

        // the below line is the one that I added
        // selected_option = selectedOptText !== undefined || selectedOptText !== null ? selectedOptText :metaData?.[`${selecting_option}`]
    }, [status, pageNo])

    useEffect(() => {

        let improvedSelection = verwarmingPage ? verwarmingPage : []
        if (status && status?.key) {
            getAdviceText(
                setState,
                status,
                carddata?.title,
                pageNo,
                answers?.[pageNo]?.answerNo,
                improvedSelection,
            )
        }

    }, [status])


    // Increment button handler in improvement modal
    const handleIncrease = () => {
        setState((st) => ({
            ...st,
            area: !st.increaseDecreaseTrigger ? st.areaApiValue + 1 : st.area + 1 <= 1000 ? Number(st.area + 1) : 1000,
            increaseDecreaseTrigger: true,
        }))
    }
    // Increment button handler in improvement modal
    const handleDecrease = () => {
        setState((st) => ({
            ...st,
            area: !st.increaseDecreaseTrigger ? st.areaApiValue - 1 : st.area - 1 >= 0 ? Number(st.area - 1) : 0,
            increaseDecreaseTrigger: true,
        }))
    }

    // area change handler in improvement modal
    const handleChange = (value: any) => {
        setState((st) => ({
            ...st,
            increaseDecreaseTrigger: true,
            area: value >= 0 && value <= 1000 ? Number(value) : st.areaApiValue
        }))
    }


    const handleDropdownChange = (value: any) => {
        setState((st) => ({ ...st, selectVal: value }))
        investmentCost(value)
    }
    const verwarmingPage = useMemo(
        () => !isEmptyObj(answers) ? getCalculationOfPage6(answers, answers?.['7']?.fromImprovement) : {},
        [answers])

    // calculate invest cost
    const investmentCost = (value: any) => {
        let localData = localStorage.getItem('localState');
        let localImprovements = JSON.parse(`${localData}`)!
        const page4 = answers?.[4] && answers?.[4].answerNo

        const selectedOptText = getSelectedOptFun(
            dropDownOptions,
            value ? value : state.selectVal ? state.selectVal : selectedOption,
        )

        const premImprovment = getPremImprovment(
            carddata?.title,
            pageNo,
            selectedOptText,
            localImprovements,
        )
        getImprovements(setState, carddata?.title, selectedOptText, state.area === 0 ? state.areaApiValue : state.area, pageNo, state.vergronenArea)
        getGroups(setState, premImprovment, state.area === 0 ? state.areaApiValue : state.area, state.investCost, page4, pageNo, state.vergronenArea, parameters)

    }
    useEffect(() => {
        investmentCost("")
    }, [state.areaApiValue, state.area])
    useEffect(() => {
        investmentCost(state.selectVal)
    }, [state.area, state.investCost])

    const traverseObjectWithValue = (obj: any, value: any) => {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                if (obj[prop] === value) {
                    return prop
                }
            }
        }
    }


    useEffect(() => {

        // if(showHide == true){

        let values = {
            //@ts-ignore
            [answerKey]: state.selectVal ? state.selectVal.toString() : selectedOption?.toString(),
            oppervlakte: state.area === 0 ? state.areaApiValue.toString() : state.area.toString()
        }

        console.log('pages v', state.selectVal, selectedOption);

        const selectedText = getOptionFun(values, dropDownOptions, answerKey);

        const improvedPages = getImprovedPages(
            values,
            answerKey,
            selectedText,
            {
                localImprovements: answers,
                // updated for having only the cards improvments details

                page6Answer: Number(answers?.[6].answerNo),
                //@ts-ignore
                pageNo

            },
        );
        getResultCalculations(
            answers,
            improvedPages,
            setState,
            carddata?.type,
            carddata?.forSelectingOption,
            state.area === 0 ? state.areaApiValue : state.area,
            cookies?.userId,
            parameters
        )
        loadDataInLocalStorage();
        // }

    }, [
        carddata?.type,
        carddata?.forSelectingOption,
        state.selectVal,
        dropDownOptions,
        pageNo,
        pageNo,
        answers,
        answers,
        carddata?.forSelectingOption,
        state.area,
        cookies?.userId,
        showHide
    ])

    function loadDataInLocalStorage() {
        let existingData = JSON.parse(localStorage?.getItem('pdfData')!);
        if (existingData) {
            const pdfData = {
                title: carddata?.title,
                selectedValue: selected_option,
                pageIndex: pageNo,
                investeer: state?.investCost,
                bespaar: Math.round(Number(state?.calculations?.energyRate)),
                premies: Math.round(state?.premAmount),
                minderEnergie: state?.calculations?.energyDifference,
                pagesEnergyGas: state?.calculations?.pagesEnergyGas,
                verkoopElec: state?.calculations?.verkoopElec,
                pagesEnergyElec: state?.calculations?.pagesEnergyElec,
                status: status?.color,
                leftText: bewerk?.adviceContent ? bewerk?.adviceContent : state.adviceContent,
                advise: state?.adviceContentXL,
                updatedSelected: bewerk.option,
                updatedStatus: bewerk.color
            }
            existingData = { ...existingData, [pageNo]: pdfData };
            localStorage.setItem('pdfData', JSON.stringify(existingData))
        }
        else {
            const pdfData = {
                title: carddata?.title,
                selectedValue: selected_option,
                pageIndex: pageNo,
                investeer: state?.investCost,
                bespaar: Math.round(Number(state?.calculations?.energyRate)),
                premies: Math.round(state?.premAmount),
                minderEnergie: state?.calculations?.energyDifference,
                pagesEnergyGas: state?.calculations?.pagesEnergyGas,
                verkoopElec: state?.calculations?.verkoopElec,
                pagesEnergyElec: state?.calculations?.pagesEnergyElec,
                status: status?.color,
                advise: state?.adviceContentXL,
                updatedSelected: bewerk.option,
                updatedStatus: bewerk.color
            }
            let keyForSaving = { [pageNo]: pdfData };
            localStorage.setItem('pdfData', JSON.stringify(keyForSaving))
        }
    }
    useEffect(() => {
        loadDataInLocalStorage()
    }, [state])

    useEffect(() => {

        const improvedCard = localStorage.getItem("improvedCard");
        if (improvedCard) {
            const improved = JSON.parse(improvedCard)
            if (improved[pageNo]) {
                setHasImprovement(true)
                setState((st) => ({
                    ...st,
                    areaApiValue: improved[pageNo].area
                }))
                setSelectedOption(Number(improved[pageNo].answerNo))
                autoSubmit(improved[pageNo]);
            }
        }

    }, [])

    const autoSubmit = async (data: any) => {
        setAutoData(data)
        const selectedAnswerValue = data[data.key]
        const selectedOptText = getSelectedOptFun(
            dropDownOptions,
            data.answerNo
        )

        let answerValue = selectedOptText;
        const area = data.area;



        let newState: string = localStorage.getItem('newState') || ""
        if (pageNo == 6) {
            let newStateData = JSON.parse(newState)
            newStateData[7].answerNo = data.answerNo
            newStateData[7][getEnergyTitle(page6, page7, null)] = selectedOptText

            await localStorage.setItem('newState', JSON.stringify(newStateData))
        } else if (pageNo == 22) {

            let newStateData = JSON.parse(newState)
            const selectedOptText = getSelectedOptFun(
                dropDownOptions,
                state.selectVal as any - 1
            )
            newStateData[pageNo].answerNo = state.selectVal
            newStateData[pageNo].zonnepanelen = selectedOptText
            // @ts-ignore
            newStateData[pageNo].oppervlakte = area

            await localStorage.setItem('newState', JSON.stringify(newStateData))

        } else {

            let newStateData = JSON.parse(newState)
            newStateData[pageNo].answerNo = data.answerNo
            newStateData[pageNo][answerKey] = selectedOptText

            await localStorage.setItem('newState', JSON.stringify(newStateData))
        }

        newState = await localStorage.getItem('newState') || ""
        let newStateData = JSON.parse(newState)
        let improvedSelection = verwarmingPage ? verwarmingPage : []

        //@ts-ignore
        let statusColor: any
        if (pageNo == 17 || pageNo == 19 || pageNo == 20 || pageNo == 22 || pageNo == 27) {
            statusColor = "green"
        } else {
            //@ts-ignore
            statusColor = pageStatus(pageNo, data.answerNo?.toString());
        }
        // @ts-ignore
        let colorDot = JSON.parse(localStorage.getItem("improvementcolor"))
        let newStatus = colorDot.filter((el: any) => { return el.key.toLowerCase() === statusColor.toLowerCase() })

        setBewerk((st: any) => ({
            ...st,
            option: selectedOptText,
            color: newStatus[0].color
        }))

        getAdviceText(
            setBewerk,
            newStatus[0],
            carddata?.title,
            pageNo,
            data.answerNo,
            improvedSelection,
        )
        loadDataInLocalStorage();
        setEditButtonText(`Bewerk ${carddata?.title}`)
        setButtonText("Wijzigingen opslaan")
        setRemoveButton(true)
        setHide(false);
        setShowHide(false);
        setRenderUpdate(!renderUpdate)

    }

    const onButtonSubmit = () => {
        setHasImprovement(true)
        const selectedAnswerValue = getModalSelectedOption(carddata?.title, answers, pageNo, parameters)
        const selectedOptText = getSelectedOptFun(
            dropDownOptions,
            state.selectVal
        )
        let answerValue = selectedOptText;
        const area = state.area || state.areaApiValue;
        //@ts-ignore
        storeImprovementData(area, carddata?.title || "", answerValue || "")
        const userId = cookies?.userId

        let newState: string = localStorage.getItem('newState') || ""
        
        if (pageNo == 6) {
            
            let newStateData = JSON.parse(newState)
            newStateData[7].answerNo = state.selectVal
            newStateData[7][getEnergyTitle(page6, page7, null)] = selectedOptText
            localStorage.setItem('newState', JSON.stringify(newStateData))
        } else if (pageNo == 22) {
            // Zonnepanelen page
            let newStateData = JSON.parse(newState)
            const selectedOptText = getSelectedOptFun(
                dropDownOptions,
                state.selectVal
            )
            newStateData[pageNo].answerNo = state.selectVal
            newStateData[pageNo].zonnepanelen = selectedOptText
            // @ts-ignore
            newStateData[pageNo].oppervlakte = valuesData.oppervlakte
            localStorage.setItem('newState', JSON.stringify(newStateData))

        } else {
            let newStateData = JSON.parse(newState)
            newStateData[pageNo].answerNo = state.selectVal
            newStateData[pageNo][answerKey] = answerValue
            localStorage.setItem('newState', JSON.stringify(newStateData))
        }
        handleSubmitModal(
            valuesData,
            answerKey,
            selectedOptText,
            area,
            // state.calculations,
            {
                investeer: state?.investCost,
                bespaar: state?.calculations?.energyRate,
                premies: Math.round(state?.premAmount),
                minderEnergie: state?.calculations?.energyDifference,
                pagesEnergyGas: state?.calculations?.pagesEnergyGas,
                verkoopElec: state?.calculations?.verkoopElec,
                pagesEnergyElec: state?.calculations?.pagesEnergyElec,
            },
            state.selectVal,
            parameters,
            {
                userId,
                answers,
                pageNo,
                key,
                setRenderUpdate,
                renderUpdate
            }
        )
        let improvedSelection = verwarmingPage ? verwarmingPage : []
        //@ts-ignore
        let statusColor: any
        if (pageNo == 17 || pageNo == 19 ||  pageNo == 18 || pageNo == 20 || pageNo == 22 || pageNo == 27) {
            statusColor = "green"
        } else {
            //@ts-ignore
            statusColor = pageStatus(pageNo, state.selectVal)?.toString();
        }
        // @ts-ignore
        let colorDot = JSON.parse(localStorage.getItem("improvementcolor"))
        let newStatus = colorDot.filter((el: any) => { return el.key.toLowerCase() === statusColor.toLowerCase() })
        setBewerk((st: any) => ({
            ...st,
            option: selectedOptText,
            color: newStatus[0].color
        }))

        getAdviceText(
            setBewerk,
            newStatus[0],
            carddata?.title,
            pageNo,
            state.selectVal,
            improvedSelection,
        )
        loadDataInLocalStorage();
        setEditButtonText(`Bewerk ${carddata?.title}`)
        setButtonText("Wijzigingen opslaan")
        setRemoveButton(true)
        setHide(false);
        setShowHide(false);
        if(carddata?.title === 'Verwarming'){
            if(answers[11].answerNo == 0){
                localStorage.setItem('verwarming_color',bewerk.color ? bewerk.color : status?.color)
                localStorage.setItem('verwarming_improvement_removed',"No")
                
                setColorUpdate(!colorUpdate)
            }
        }
    }

    const onRemoveButtonSubmit = () => {
        // remove imporvement from DB as well
        removeImprovementData(carddata?.title || "")
        const userId = cookies?.userId
        handleRemoveChanges({
            userId,
            localImprovements,
            answers,
            pageNo,
            key,
            setRenderUpdate,
            renderUpdate
        }
        )

        // Back to Original state for that card on localStorage for bar
        let newState: string = localStorage.getItem('newState') || ""
        let localState: string = localStorage.getItem('localState') || ""
        let localStateData = JSON.parse(localState)
        let newStateData = JSON.parse(newState)
        if (pageNo == 6) {

            newStateData[7].answerNo = localStateData[7].answerNo
            newStateData[7][answerKey] = localStateData[7][answerKey]
            localStorage.setItem('newState', JSON.stringify(newStateData))

        } else {
            newStateData[pageNo].answerNo = localStateData[pageNo].answerNo
            newStateData[pageNo][answerKey] = localStateData[pageNo][answerKey]
            localStorage.setItem('newState', JSON.stringify(newStateData))

        }

        // update 'localImprovements' from local storage
        const improvementLocalStorage: string = localStorage.getItem('localImprovements') || ""
        let improvementLocalStorageData = JSON.parse(improvementLocalStorage)
        try {
            delete improvementLocalStorageData[pageNo]
            localStorage.setItem('localImprovements', JSON.stringify(improvementLocalStorageData))
        } catch (err) {
            console.error(err)
        }


        setBewerk((st: any) => ({
            ...st,
            option: "",
            color: ""
        }))

        getAdviceText(
            setBewerk,
            status,
            carddata?.title,
            pageNo,
            answers?.[6]?.answerNo + 1,
            selected_option,
        )

        setShowHide(false);
        setHide(false);
        setButtonText('Voeg toe aan mijn plan')
        setRemoveButton(false)
        loadDataInLocalStorage();
        if(carddata?.title == "Verwarming" && answers[11].answerNo == 0){
            // localStorage.removeItem("verwarming_color")
            localStorage.setItem('verwarming_color',bewerk.color ? bewerk.color : status?.color)
            localStorage.setItem('verwarming_improvement_removed',"Yes")
            // bewerk.option = "";
            //@ts-ignore
            // status.key = "";
        }
    }
    let removed = localStorage.getItem('verwarming_improvement_removed');
  
    if(carddata?.title === "Verwarming"){
        localStorage.setItem('verwarming_color',bewerk.color ? bewerk.color : status?.color)
    }
    if(carddata?.title == "Warm water" && answers[11].answerNo == 0 ){
       
        let color = localStorage.getItem("verwarming_color")!
        //@ts-ignore
        status.key = color === "#008000" ? "Green" : color === "#FFA500" ? "orange" : "Red";
        //@ts-ignore
        bewerk.option = selected_option
        bewerk.color = color;
        status.color = color;
    }
    // useEffect(()=>{
    //     if(carddata?.title === "Warm water" && answers[11].answerNo === 0){
            
    //         let color = localStorage.getItem("verwarming_color")!
           
    //         //@ts-ignore
    //          status.key =  "#008000" ? "Green" : color === "#FFA500" ? "orange" : "red";
            
    //         bewerk.color = color;
            
    //         status.color = color;
    //     }
    // },[colorUpdate])
    
    if(carddata?.title == "Warm water" && answers[11].answerNo == 0){
        let color = localStorage.getItem("verwarming_color")!
        
        bewerk.option = "";
            //@ts-ignore
        status.key = color === "#008000" ? "Green" : color === "#FFA500" ? "orange" : "Red";
    }
    // if(carddata?.title == "Warm water" && answers[11].answerNo == 0){
    //     getAdviceText(
    //         setBewerk,
    //         status,
    //         carddata?.title,
    //         pageNo,
    //         answers?.[6]?.answerNo + 1,
    //         selected_option,
    //     )
    // }
    console.log(carddata?.title, 'carddata?.title')
    console.log(bewerk, 'bewerk?')
    console.log(state, 'state.')
    console.log(status, 'status')
    return (
        //@ts-ignore
        <div className={((pageNo == 31 && page31 == 1) || (pageNo == 18 && answers[19] !== undefined)) ? "hidden" : "flex flex-2 flex-col box-border w-full "}>
            {metaData ?// @ts-ignore
                <div className="flex md:flex-row flex-col items-center w-full bg-white box-border px-[10px] shadow-2xl border-l-8" style={{ borderColor: bewerk.color ? bewerk.color : status?.color }}>
                    <div className="flex flex-col md:flex-row justify-start w-full">
                        <img className="h-[60px] md:h-[60px] self-center object-contain" src={metaData?.image} alt="" />
                        <div className="flex flex-col box-border p-[10px] items-center md:items-start leading-normal">
                            <p className="font-sans text-center font-bold md:text-start text-black text-[22px]">{carddata?.title}</p>
                            <div className="flex flex-row items-center">
                                <div className="w-[16px] h-[16px] rounded-full" style={{ backgroundColor: bewerk.color ? bewerk.color : status?.color }} />
                                <span className="font-sans font-semibold text-center md:text-start text-[#363538] text-[12px] ml-2.5">{bewerk?.option ? bewerk?.option : selected_option}</span>
                            </div>
                            {/* <p className="font-sans font-regular text-center md:text-start text-[#39474E] text-[12px]" dangerouslySetInnerHTML={{__html: bewerk?.adviceContent ? bewerk?.adviceContent : state.adviceContent}}></p> */}
                            <p className="font-sans font-regular text-center md:text-start text-[#39474E] text-[12px]" dangerouslySetInnerHTML={{ __html: getCardDescription(bewerk.color ? bewerk.color : status?.color, carddata?.title?.toLowerCase()) }}></p>
                            {/* <p className="font-sans font-regular text-center md:text-start text-[#39474E] text-[12px]">{state.adviceContent} -- Een verbetering is nodig! Ontdek nu ons advies voor het aanpakken van betering is nodig! Ontdek nu ons advies voor het aanpakken van {carddata?.title?.toLowerCase()}</p> */}
                        </div>
                    </div>
                    <div className="flex justify-end">

                        {bewerk?.option || status?.key == "Green" ?
                            <button type='button' className="w-[280px] justify-center items-end self-end text-center bg-[#398284] transition ease-in-out hover:-translate-y-1 hover:scale-110 text-black bg-white border-2 border-black hover:bg-black-dark hover:border-black-dark font-semibold px-5 py-3 mb-[10px] md:mb-[0px]" onClick={() => setShowHide(!showHide)}>
                                <p className="font-sans font-bold  text-[12px] ">{status.key == "Green" && !bewerk?.option ? carddata?.buttonText : "Wijzingen opgeslagen"}</p>
                            </button>
                            :
                            <button className="w-[280px] justify-center items-end self-end text-center primary-main transition ease-in-out hover:-translate-y-1 hover:scale-110 text-white primary-main border-2 primary-border hover:primary-main hover:primary-border focus:ring-4 focus:ring-primary-main font-semibold px-5 py-3 mb-[10px] md:mb-[0px]" onClick={() => setShowHide(!showHide)}>
                                <p className="font-sans font-bold text-white text-[12px] ">{carddata?.buttonText}</p>
                            </button>
                        }
                    </div>
                </div>
                : <></>}
                {/* { carddata?.title !== "Bijdak" && carddata?.title !== "Ramen leefruimte" && carddata?.title !== "Ramen slaapkamers" ? */}
            {showHide ?
                <div className="flex md:w-full md:w-full max-w-[100%]">
                   
                    <div className="flex flex-1 flex-col w-full bg-[#fbfbfb] max-w-[100%]">
                    {/* { carddata?.title !== "Bijdak" && carddata?.title !== "Ramen leefruimte" && carddata?.title !== "Ramen slaapkamers" ? */}
                        <div className="container px-[15px] md:px-[15px] py-[10px] w-full flex-col">
                            
                            <p className="font-sans font-bold text-center md:text-start text-[#39474E] text-[18px]" dangerouslySetInnerHTML={{ __html: status?.key === 'Green' || bewerk?.color === "#008000" ? bewerk?.adviceContent !== "" ? bewerk?.adviceContent : state?.adviceContent : state?.adviceContent }}></p>
                            {state?.adviceContentXL ?
                            <a className="font-sans font-bold text-center md:text-start text-[#39474E] text-[16px] underline" href="#" onClick={(event) => {
                                
                                event.preventDefault();
                                setHide(!hide);
                                
                            }}>Meer info bij ons advies</a>
                        :<></>}
                            {hide === true ?
                            
                                // { status?.color !== 'green'  ?
                                //@ts-ignore
                                <p className="font-sans font-regular text-black text-[14px]" dangerouslySetInnerHTML={{ __html: status?.key === 'Green' || bewerk?.color === "#008000" ? state?.adviceContentXL : state?.adviceContentXL}}></p>
                                // <p className="font-sans font-regular text-black text-[14px]" dangerouslySetInnerHTML={{ __html: status?.key === 'Green' || bewerk?.color === "#008000"  || bewerk?.color === "Green" ? state?.adviceContentXL : state?.adviceContentXL }}></p>
                                // :<p className="font-sans font-regular text-black text-[14px]" dangerouslySetInnerHTML={{__html: state?.adviceContentXL}}></p>
                                // }
                                /* <p className="font-sans font-regular text-black text-[14px]">Je huis verliest te veel warmte door je dak. Denk eraan: dakisoliatie is verplicht in elke woning. Isoleer je dak, je zal het onmiddellijk merken op je energiefactuur.</p> */
                                /* <p className="font-sans font-semibold text-[#398284] text-[16px] mt-[5px]"> Meer info bij ons advies</p> */
                                : 
                                <></>
                                }
                        </div>
                        {/* :<></>} */}
                        {status.key !== "Green" &&
                            <>
                            {console.log(status)}
                                <div className="flex md:flex-row flex-col w-full md:mt-10 px-[15px] md:px-[15px]">
                                    <div className="flex h-full flex-col max-w-[100%] md:flex-1 mr-[10px]">
                                        <div className="flex-row">
                                            <label className="font-sans font-semibold text-black text-[14px]">Kies een verbetering</label>
                                            <div className="flex md:flex-row mt-1.5">
                                                <select id="options" className="w-full outline-none border-[2px] border-black mt-2.5 font-sans font-semibold text-[14px] box-border py-[15px] px-[10px]"
                                                    onChange={(e) => handleDropdownChange(e.target.value)}>
                                                    {/* <option selected>{selected_option}</option> */}
                                                    {Object.keys(dropDownOptions[0]).map((option) => (
                                                        <option
                                                            key={`${option
                                                                .toLowerCase()
                                                                .replace(/\s+/g, '_')}`}
                                                            // @ts-ignore
                                                            value={dropDownOptions[0][option]}
                                                            // @ts-ignore
                                                            selected={selectedOption === dropDownOptions[0][option] ? "selected" : undefined}
                                                        >
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {carddata?.type != "extra" && carddata?.type != "hybridwithoutarea" &&
                                            <div className="md:flex-row mt-3.5">
                                                <label className="font-sans font-semibold text-black text-[14px] mt-10">
                                                    {`${carddata?.title}: Oppervlakte ${toLower(carddata?.title)} in m²`}
                                                </label>
                                                <div className="flex flex-row mt-2.5">
                                                    <button type='button' className="flex items-center justify-center bg-white border-2 border-black mr-2.5 box-border py-[10px] px-[20px]" onClick={handleDecrease}>
                                                        <p className="font-sans font-bold text-black text-[14px]" >-</p>
                                                    </button>
                                                    <div className="flex items-center justify-center bg-white border-2 border-black mr-2.5 box-border py-[10px] px-[10px]">
                                                        {/* <p className="font-sans font-bold text-black text-[18px]">dssd</p> */}
                                                        <input autoComplete="off" value={state.increaseDecreaseTrigger ? state.area : state.areaApiValue} type="text" name="oppervlakte" className="font-sans font-bold text-black text-[14px] text-center outline-none w-fit w-[35px]" onChange={(e) => handleChange(e.target.value)}></input>
                                                    </div>
                                                    <button type='button' className="flex items-center justify-center bg-white border-2 border-black box-border py-[10px] px-[20px]" onClick={handleIncrease}>
                                                        <p className="font-sans font-bold text-black text-[14px]">+</p>
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {carddata?.type != "extra" && carddata?.type != "solar" &&
                                        <div className="flex flex-[1.75] h-full md:flex-col flex-row max-w-[100%] md:ml-[10px]">
                                            <div className="bg-[#f0f5f5] w-full h-full flex flex-col px-[15px] mt-[15px] md:mt-[0px]">
                                                <div className="w-full flex justify-between">
                                                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik investeer</p>
                                                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">€{state.investCost}</p>
                                                </div>
                                                <div className="w-full flex justify-between">
                                                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik ontvang premies</p>
                                                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">€ {state.premAmount}</p>
                                                </div>
                                                {(carddata?.title == 'Verwarming' && page6 !== 1) ?
                                                    <>
                                                        <div className="w-full flex justify-between">
                                                        <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik verbruik minder electriciteit</p>
                                                        <p className="font-sans font-semibold text-black text-[16px] mb-2.5">{Math.round(state?.calculations?.pagesEnergyElec)} kWh/jaar</p>
                                                    </div>
                                                        <div className="w-full flex justify-between">

                                                            <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik verbruik minder {getEnergyTitle(page6, page7, carddata?.title)}</p>
                                                            <p className="font-sans font-semibold text-black text-[16px] mb-2.5">{Math.round(state?.calculations?.pagesEnergyGas)} kWh/jaar</p>
                                                        </div>
                                                    </> :
                                                        <div className="w-full flex justify-between">
                                                        <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik verbruik minder energy</p>
                                                        <p className="font-sans font-semibold text-black text-[16px] mb-2.5">{Math.round(state?.calculations?.energyDifference)} kWh/jaar</p>
                                                    </div>
                                                }


                                                {/* } */}
                                                <div className="w-full flex justify-between mt-10">
                                                    <p className="font-sans font-bold text-black text-[16px] mb-2.5">Ik bespaar</p>
                                                    <p className="font-sans font-bold text-black text-[16px] mb-2.5">€ {Math.round(Number(state?.calculations?.energyRate))}</p>
                                                </div>
                                            </div>
                                        </div >
                                    }
                                    {carddata?.type === "solar" &&
                                        <div className="flex flex-[1.75] h-full md:flex-col flex-row max-w-[100%] ml-[10px]">
                                            <div className="bg-[#f0f5f5] w-full h-full flex flex-col px-[15px]">
                                                <div className="w-full flex justify-between">
                                                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik investeer</p>
                                                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">€{state.investCost}</p>
                                                </div>
                                                <div className="w-full flex justify-between">
                                                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik ontvang premies</p>
                                                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">€ {state.premAmount}</p>
                                                </div>
                                                <div className="w-full flex justify-between">
                                                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik verbruik minder energie</p>
                                                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">{Math.round(state?.calculations?.energyDifference)} kWh/jaar</p>
                                                </div>
                                                <div className="w-full flex justify-between">
                                                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik verkoop elektriciteit</p>
                                                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">{Math.round(state?.calculations?.verkoopElec)} kWh/jaar</p>
                                                </div>
                                                <div className="w-full flex justify-between mt-10">
                                                    <p className="font-sans font-bold text-black text-[16px] mb-2.5">Ik bespaar</p>
                                                    <p className="font-sans font-bold text-black text-[16px] mb-2.5">€ {Math.round(Number(state?.calculations?.energyRate))}</p>
                                                </div>
                                            </div>
                                        </div >
                                    }
                                </div>

                                <div className="flex w-full justify-end box-border px-[15px] my-[15px] ">
                                    {/* @ts-ignore */}
                                    <button className="flex items-center justify-center primary-main border-border px-[36px] py-[15px] transition ease-in-out hover:-translate-y-1 hover:scale-110 mr-2.5 disabled:bg-secondary" onClick={onButtonSubmit}>
                                        <p className="font-sans font-bold text-white text-[16px]">{buttonText}</p>
                                    </button>
                                    {removebutton === true ?
                                        <button type='button' className="flex items-center justify-center bg-[#ffffff] border-border px-[36px] py-[15px] transition ease-in-out hover:-translate-y-1 hover:scale-110 ml-2.5" onClick={onRemoveButtonSubmit}>
                                            <p className="font-sans font-bold text-black text-[16px]">Verwijder uit mijn plan</p>
                                        </button> : <></>
                                    }
                                </div>
                            </>
                        }
                        
                    </div>
             
                </div>
                : <></>}
               
        </div>
    )
};
export default Card;
