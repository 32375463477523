import axios, { AxiosResponse } from "axios";

type validationCardType = {
    generic: boolean,
    renovation: boolean,
    contractor: boolean
}[]

export interface ServiceItem {
    id: string | number,
    title: string,
    offerRequestId: string,
    renovationId?: string,
    type: string,
    details: string,
    isGreen?: boolean,
    statusIconThree: any,
}

const generateRenovationIdList = (servicesList?: ServiceItem[]) => {
    let renovationIds: string[] = [];
    servicesList?.forEach((service: ServiceItem) => {
        renovationIds.push(service.renovationId || "")
    })
    return renovationIds;
}

const generateServiceCardValidations = (offerRequestId?: string, servicesList?: ServiceItem[]) => {
    let serviceCardList: Promise<void | AxiosResponse<any, any>>[] = [];
    //const auth = JSON.parse(localStorage.getItem("auth")!)
    const authOrigin = localStorage.getItem("auth");
    const auth = authOrigin && authOrigin?.length > 0? JSON.parse(localStorage.getItem("auth")!) : ""

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`,
    };
    const renovationIdList: string[] = generateRenovationIdList(servicesList);
    console.log(headers,'headers')
    renovationIdList.forEach((renovationId: string, index: number) => {
        serviceCardList.push(axios.post(process.env.REACT_APP_BACKEND_URL + "offer/request/information/check/completion/" + offerRequestId,{
            // headers:headers,
            "renovationId": parseInt(renovationId)
          
        },{headers}).catch(error => console.log(error)))
    })
    return serviceCardList;
}

export const getServiceCardValidations = (offerRequestId?: string, setState?: ([]: validationCardType) => void, servicesList?: ServiceItem[]) => {
    const renovationIdAPIList = generateServiceCardValidations(offerRequestId, servicesList);
    const responseData: any = []
    axios.all(renovationIdAPIList).then(axios.spread((...responses) => {
        responses.forEach((response: any) => {
            responseData.push(response.data.data)
        })
        setState && setState(responseData)
    })).catch(error => console.log(error))
}