import React, { FC } from 'react'
import { ContractorStatusEnum, HouseOwnerListingTypeColors, UserTypes } from '../../models/applicationState'
import { ReactComponent as ArrowRightCircleGreenEmpty } from "../../assets/icons/Icon arrow-cercle-right-green-empty.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import ContractorStatus from '../../components/contractorStatus';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LuChevronRightCircle } from "react-icons/lu";

interface MainTitleProps {
    tableTitleClasses?: String,
    showIcon?: Boolean,
    toggleStatus?: (() => void) | ((cpSimulation: any) => void),
    listingType?: HouseOwnerListingTypeColors,
    status?: Boolean,
    onClick?: React.MouseEventHandler,
    subtitle?: String,
    title: String,
    userType?: UserTypes,
    contractorStatus?: Boolean,
    showContractorStatus?: Boolean,
    onContractorStatusChange?: (status: Boolean) => void,
    hideEllipsis?:boolean,
    handleClickDelete?: any
}

export const MainTitle: FC<MainTitleProps> = ({
    tableTitleClasses,
    showIcon,
    toggleStatus,
    listingType,
    status,
    subtitle,
    onClick,
    title,
    showContractorStatus = false,
    contractorStatus = false,
    onContractorStatusChange,
    hideEllipsis=false, handleClickDelete
}) => {

    const titleTextColor = listingType === HouseOwnerListingTypeColors.Normal ? "secondary" : "white"
    const arrorFillColor = listingType === HouseOwnerListingTypeColors.Normal ? "primary" : "secondary"
    const bgColor = listingType === HouseOwnerListingTypeColors.Archive ? "bg-secondary" : "bg-white"
    return (
        <>
            <div className={`flex flex-row gap-2 items-center mb-2 mt-6 ${tableTitleClasses}`}>
                {showIcon ? (
                    <div onClick={toggleStatus} className={status ? "rotate-0" : "rotate-90"}>
                        <div className={``}>
                            <LuChevronRightCircle size = "24px" className = "primary-text cursor-pointer" />
                        </div>
                    </div>
                ) : null}
                <div onClick={onClick}
                    className={`text-${titleTextColor} font-semibold whitespace-pre-line text-md ${bgColor} shadow-md px-7 py-2`}
                >{title}</div>
                {!hideEllipsis &&
                        <FontAwesomeIcon
                        icon={faEllipsisVertical as IconProp}
                        onClick={onClick}
                        className="w-5 h-5 ml-1 mt-1 -m cursor-pointer"
                    />
                }
                {showContractorStatus && <FontAwesomeIcon
                    icon={faTrashCan as IconProp}
                    onClick={handleClickDelete}
                    className="w-5 h-5 ml-1 mt-1 -m cursor-pointer text-red-600"
                />}
                {showContractorStatus && (
                    <ContractorStatus status={contractorStatus} onChange={onContractorStatusChange} />
                )}
                <p className='whitespace-nowrap text-secondary'>{subtitle}</p>
            </div>
        </>
    )
}


export default MainTitle
