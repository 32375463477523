import React, {FC} from "react";
import {Col, Row} from "antd";

import HouseOne from "../../../assets/picture/hp/house.png";
import ContractorLogo from "../../../assets/contractor/contractor_logo.jpg";
import ContractorLabel from "../../../assets/contractor/contractor_label.jpeg";
import Climate from "../../../assets/climate/climate_thirteen.png";

interface documentStruct {}

const File2: FC<documentStruct> = ({
}) => {
    return (
        <div className="mt-4">
            <Row className="pt-8">
                <Col md={24}>
                    <h1 className="text-lg text-[#298752] font-semibold">
                        Documenten
                    </h1>
                </Col>
            </Row>

            <Row className="pt-4">
                <Col md={24}>
                    <p className="font-semibold text-md pb-4 text-[#4F4C4C]" style={{ opacity: 0.2 }}>
                        alle documenten zijn bijgevoegd
                    </p>
                </Col>
            </Row>

            <Row className="w-full mt-5 px-8 py-4 border" style={{ borderRadius: 10 }}>
                <Col md={4}>
                    <img src={ContractorLogo} alt="House's logo" className = "h-[160px] object-fit" />
                </Col>
                <Col md={20}>
                    <Row>
                        <Col md={8}>
                            <p className="font-semibold text-md pb-2 text-[#4F4C4C]" style={{ opacity: 0.2 }}>FIRMA</p>
                            <p className="text-md pb-2">Construction Frank</p>

                            <p className="font-semibold text-md pb-2 mt-8 text-[#4F4C4C]" style={{ opacity: 0.2 }}>CONTACTPERSOON</p>
                            <p className="text-md pb-2">Tim Franck</p>
                        </Col>

                        <Col md={8}>
                            <p className="font-semibold text-md pb-2 text-[#4F4C4C]" style={{ opacity: 0.2 }}>EMAIL</p>
                            <p className="text-md pb-2">tim@constructionfranck.be</p>

                            <p className="font-semibold text-md pb-2 mt-8 text-[#4F4C4C]" style={{ opacity: 0.2 }}>TELEFOON</p>
                            <p className="text-md pb-2">0032 245 567 789</p>
                        </Col>

                        <Col md={8}>
                            <p className="font-semibold text-md pb-2 text-[#4F4C4C]" style={{ opacity: 0.2 }}>BTW NUMMER</p>
                            <p className="text-md pb-2">BE 89 128370926</p>

                            <Row>
                                <Col md={6}>
                                    <p className="font-semibold text-md pb-2 mt-8 text-[#4F4C4C]" style={{ opacity: 0.2 }}>LABEL</p>
                                    <p className="text-md pb-2">
                                        <img src={ContractorLabel} alt="House's logo" className = "h-[24px] object-fit" />
                                    </p>
                                </Col>
                                <Col md={12}>
                                    <p className="font-semibold text-md pb-2 mt-8 text-[#4F4C4C]" style={{ opacity: 0.2 }}>CERTIFICATEN</p>
                                    <div style={{ borderRadius: 100, background: '#8b8b8b', height: 24, width: 24 }}></div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="w-full mt-5 px-8 py-4" style={{ borderRadius: 10, background: '#2987520D 0% 0% no-repeat padding-box' }}>
                <Col md={4}>
                    <p>NameDocu.009.PDF</p>
                    <img src={Climate} alt="House's logo" className = "h-[160px] object-fit" />
                </Col>
                <Col md={20} className="pt-8">
                    <p className="mb-2 underline">Beschrijving :</p>
                    <p style={{ fontWeight: 300 }}>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    </p>
                </Col>
            </Row>

            <Row className="w-full mt-5 px-8 py-4" style={{ borderRadius: 10, background: '#2987520D 0% 0% no-repeat padding-box' }}>
                <Col md={4}>
                    <p>NameDocu.009.PDF</p>
                    <img src={Climate} alt="House's logo" className = "h-[160px] object-fit" />
                </Col>
                <Col md={20} className="pt-8">
                    <p className="mb-2 underline">Beschrijving :</p>
                    <p style={{ fontWeight: 300 }}>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    </p>
                </Col>
            </Row>

            <Row className="w-full mt-5 px-8 py-4" style={{ borderRadius: 10, background: '#2987520D 0% 0% no-repeat padding-box' }}>
                <Col md={4}>
                    <p>NameDocu.009.PDF</p>
                    <img src={Climate} alt="House's logo" className = "h-[160px] object-fit" />
                </Col>
                <Col md={20} className="pt-8">
                    <p className="mb-2 underline">Beschrijving :</p>
                    <p style={{ fontWeight: 300 }}>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    </p>
                </Col>
            </Row>

        </div>
    )
};

export default File2;

