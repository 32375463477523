


import { useCookies } from 'react-cookie'
import './pdf-dom.css'
type cardProps = {


  // data?:Array<string | number>,
  carddata?: any,
  // APIdata?: { id: number, improvementName: string, improvementPeram: string, improvementOptions: Array<any | string | number | object> },
  // APIdata?:Array<any | string | number | object> ,
  // buildingData:any,
  // resultSetState:any,
  // index?: string,
  // pageData?: {
  //     back_text: string,
  //     title: string,
  //     next_url: string,
  //     prev_url: string
  // }
  // colorDot:object
  // parameters:object,
  // storeImprovementData: (area: number, title: string, answer: string) => void,
  // setRenderUpdate:any
  // renderUpdate:any
}

function getCardDescription(status: any, title: string) {
  switch (status) {
    case 'green':
      return "Dit is al in orde! Een verdere investering weegt momenteel niet op tegen de voordelen."
    case  '#008000':
      return "Dit is al in orde! Een verdere investering weegt momenteel niet op tegen de voordelen."
    case 'midgreen':
      return 'Dit is al in orde! Een verdere investering weegt momenteel niet op tegen de voordelen.'
    case 'orange':
      return `Ontdek ons advies voor het verbeteren van ${title}.`
    case  '#FFA500':
      return `Ontdek ons advies voor het verbeteren van ${title}.`
    case "red":
        return `Een verbetering dringt zich op. Ontdek nu ons advies voor het aanpakken van ${title}.`
    case "#FF0000":
        return `Een verbetering dringt zich op. Ontdek nu ons advies voor het aanpakken van ${title}.`
    default:
      return ""
  }
}
function classNames(classesObj: any) {
  const classes = Object.entries(classesObj).map(([key, value]) =>
    value ? key : undefined,
  )
  return classes.filter((cls) => Boolean(cls)).join(' ')
}
const getEnergyTitle = (answerSix: any, answerSeven: any) => {
  switch (answerSix) {
    case 0:
      return 'gas'
    case 2:
      return 'stookolie'
    case 3:
      switch (answerSeven) {
        case 1:
          return 'pellets'
        case 2:
          return 'hout'
        default:
          return ''
      }
    default:
      return ''
  }
}

export default function Card({ carddata }: cardProps) {
  const status = carddata?.updatedStatus ? carddata?.updatedStatus : carddata?.status
  const statusClasses = classNames({
    status: true,
    green: status === 'green' || status === 'midgreen',
    orange: status === 'orange',
    red: status === 'red',
  })
  let localState = JSON.parse(localStorage.getItem('localState')!)
  let metaData = localState?.[carddata?.pageIndex];
  console.log(carddata?.updatedSelected ,'carddata?.updatedSelected ')
  let verwarmingStatus = localStorage.getItem('verwarming_color')!;
  let verwarming_answer_state = localState[6]?.answerNo === "0" ? true :false;
  console.log(verwarmingStatus ,'verwarmingStatus')
  console.log(verwarming_answer_state ,'verwarming_answer_state')
  console.log(carddata?.title,'carddata?.title')
  console.log(carddata?.selectedValue,'carddata?.selectedValue')
  console.log(carddata,'carddata?')
  let verwarming_color = localStorage.getItem('verwarming_color');
  verwarming_color = verwarming_color === "#008000" || verwarming_color === "Green" ? "Green" : verwarming_color === "#FFA500" ? "Orange" : "Red"
  return (
    <div className="w-full flex">
      <article className="flex flex-1 flex-col overflow-hidden box-border px-[18.5px] border-r-[1px] border-r-[#2987524A]">
        <div className="flex w-full items-center">
          {metaData?.image && (
            <img className="h-[80px] ml-[-30px] object-contain" src={metaData?.image} alt="image" />
          )}
          <p className="font-Source font-semibold text-[30px] text-black">{carddata?.title}</p>
        </div>
        <div className="flex flex-row items-center">
          <div className="w-[16px] h-[16px] rounded-full" style={{ backgroundColor: carddata?.status ? carddata?.status : "red" }} />
          <span className="font-sans font-semibold text-center md:text-start text-[#363538] text-[12px] ml-2.5">{carddata?.selectedValue ? carddata?.selectedValue : carddata?.updatedSelected}</span>
        </div>
        <p>
          {/* <span />
          <span >{carddata?.leftText}</span> */}
        </p>
        <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">{getCardDescription(carddata?.status ? carddata?.status : "red", carddata?.title?.toLowerCase())}</p>
      </article>
     
      <div className="flex flex-[2.5] flex-col overflow-hidden box-border px-[32px]">
        {verwarmingStatus && verwarming_answer_state === true && carddata?.title === 'Warm water' && verwarming_color === "Green" ?
        <article className="w-full box-border px-[27px] py-[15px] bg-[#2987520D] flex relative">
          <div className = "w-full h-full primary-main opacity-[0.1] absolute top-0 left-0" />
        <div className="flex flex-col flex-1 relative">
          <p className="font-Source font-semibold text-[28px] text-black">{carddata?.title}</p>
          <p>

            <span />
           
              <div className="flex flex-row items-center">
                <div className="w-[16px] h-[16px] rounded-full" style={{ backgroundColor: verwarmingStatus }} />
                <span className="font-sans font-semibold text-center md:text-start text-[#363538] text-[12px] ml-2.5">{carddata?.updatedSelected ? carddata?.updatedSelected : carddata?.selectedValue}</span>
              </div>
           
            <span className="font-Source font-semibold text-[18px] text-[#4F4C4C]">{carddata?.bewerk?.adviceContent}</span>
          </p>
        </div>
      
        {/* {carddata?.updatedSelected ? */}
        <div className="flex flex-col flex-1 relative">
          <div className="w-full flex flex-col">
            <div className="flex w-full justify-between">
              <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">Ik investeer</p>
              <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">
                €{' '}
                {Number.isNaN(Math.round(carddata?.investeer))
                  ? 0
                  : Math.round(carddata?.investeer)}
              </p>
            </div>
            <div className="flex w-full justify-between">
              <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">Ik ontvang premies</p>
              <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">
                €{' '}
                {Number.isNaN(Math.round(carddata?.premies))
                  ? 0
                  
                  : Math.round(carddata?.premies)}
              </p>
            </div>
            {carddata?.title === 'Zonnepanelen' ? (
              <div className="w-full flex flex-col">
                <div className="flex w-full justify-between">
                  <p className="font-Source font-regular text-[16px] text-[#4F4C4C]"> Ik verbruik minder energie</p>
                  <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">{Math.round(carddata?.minderEnergie)} kWh / jaar</p>
                </div>
                <div className="flex w-full justify-between">
                  <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">Ik verkoop elektriciteit</p>
                  <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">{Math.round(carddata?.verkoopElectricity)} kWh / jaar</p>
                </div>
              </div>
            ) : carddata?.title === 'Verwarming' &&
              localState?.[6].answerNo !== 1
              //   &&
              //   (optionNo == 6 ||
              //     optionNo == 7 ||
              //     optionNo == 8 ||
              //     optionNo == 9 ||
              //     optionNo == 5)
              ? (
                <div className="w-full flex flex-col">
                  <div className="w-full flex justify-between">
                    <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">Ik verbruik minder elektriciteit</p>
                    <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">{Math.round(carddata?.pagesEnergyElec)} kWh / jaar</p>
                  </div>
                  <div className="w-full flex justify-between">
                    <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">Ik verbruik minder {getEnergyTitle(localState?.[6].answerNo, localState?.[7].answerNo)}</p>
                    <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">{Math.round(carddata?.pagesEnergyGas)} kWh / jaar</p>
                  </div>
                </div>
              ) : carddata?.pageIndex != 30 && carddata?.pageIndex != 29 && (
                <div className="w-full flex justify-between">
                  <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">Ik verbruik minder energie</p>
                  <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">
                    {Number.isNaN(Math.round(carddata?.minderEnergie))
                      ? 0
                      : Math.round(carddata?.minderEnergie)}{' '}
                    kWh / jaar
                  </p>
                </div>
              )}
            {/* <li>
              <p>Ik verbruik minder energie</p>
              <p>{Math.round(minderEnergie[key])} kWh / jaar</p>
            </li> */}
            {carddata?.pageIndex != 30 && carddata?.pageIndex != 29 &&
              <div className="w-full flex justify-between">
                <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">
                  <strong>Ik bespaar</strong>
                </p>
                <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">
                  <strong>
                    €{' '}
                    {Number.isNaN(Math.round(carddata?.bespaar))
                      ? 0
                      : Math.round(carddata?.bespaar)}{' '}
                    / jaar
                  </strong>
                </p>
              </div>
            }
          </div>
        </div>
      
      </article> :
      
       carddata?.updatedSelected ?
        <article className="w-full box-border px-[27px] py-[15px] bg-[#2987520D] flex relative">
          <div className = "w-full h-full primary-main opacity-[0.1] absolute top-0 left-0" />
          <div className="flex flex-col flex-1 relative">
            <p className="font-Source font-semibold text-[28px] text-black">{carddata?.title}</p>
            <p>

              <span />
             
                <div className="flex flex-row items-center">
                  <div className="w-[16px] h-[16px] rounded-full" style={{ backgroundColor: carddata?.updatedStatus ? carddata?.updatedStatus : carddata?.status }} />
                  <span className="font-sans font-semibold text-center md:text-start text-[#363538] text-[12px] ml-2.5">{carddata?.updatedSelected ? carddata?.updatedSelected : carddata?.selectedValue}</span>
                </div>
             
              <span className="font-Source font-semibold text-[18px] text-[#4F4C4C]">{carddata?.bewerk?.adviceContent}</span>
            </p>
          </div>
        
          {/* {carddata?.updatedSelected ? */}
          <div className="flex flex-col flex-1 relative">
            <div className="w-full flex flex-col">
              <div className="flex w-full justify-between">
                <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">Ik investeer</p>
                <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">
                  €{' '}
                  {Number.isNaN(Math.round(carddata?.investeer))
                    ? 0
                    : Math.round(carddata?.investeer)}
                </p>
              </div>
              <div className="flex w-full justify-between">
                <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">Ik ontvang premies</p>
                <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">
                  €{' '}
                  {Number.isNaN(Math.round(carddata?.premies))
                    ? 0
                    
                    : Math.round(carddata?.premies)}
                </p>
              </div>
              {carddata?.title === 'Zonnepanelen' ? (
                <div className="w-full flex flex-col">
                  <div className="flex w-full justify-between">
                    <p className="font-Source font-regular text-[16px] text-[#4F4C4C]"> Ik verbruik minder energie</p>
                    <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">{Math.round(carddata?.minderEnergie)} kWh / jaar</p>
                  </div>
                  <div className="flex w-full justify-between">
                    <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">Ik verkoop elektriciteit</p>
                    <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">{Math.round(carddata?.verkoopElectricity)} kWh / jaar</p>
                  </div>
                </div>
              ) : carddata?.title === 'Verwarming' &&
                localState?.[6].answerNo !== 1
                //   &&
                //   (optionNo == 6 ||
                //     optionNo == 7 ||
                //     optionNo == 8 ||
                //     optionNo == 9 ||
                //     optionNo == 5)
                ? (
                  <div className="w-full flex flex-col">
                    <div className="w-full flex justify-between">
                      <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">Ik verbruik minder elektriciteit</p>
                      <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">{Math.round(carddata?.pagesEnergyElec)} kWh / jaar</p>
                    </div>
                    <div className="w-full flex justify-between">
                      <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">Ik verbruik minder {getEnergyTitle(localState?.[6].answerNo, localState?.[7].answerNo)}</p>
                      <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">{Math.round(carddata?.pagesEnergyGas)} kWh / jaar</p>
                    </div>
                  </div>
                ) : carddata?.pageIndex != 30 && carddata?.pageIndex != 29 && (
                  <div className="w-full flex justify-between">
                    <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">Ik verbruik minder energie</p>
                    <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">
                      {Number.isNaN(Math.round(carddata?.minderEnergie))
                        ? 0
                        : Math.round(carddata?.minderEnergie)}{' '}
                      kWh / jaar
                    </p>
                  </div>
                )}
              {/* <li>
                <p>Ik verbruik minder energie</p>
                <p>{Math.round(minderEnergie[key])} kWh / jaar</p>
              </li> */}
              {carddata?.pageIndex != 30 && carddata?.pageIndex != 29 &&
                <div className="w-full flex justify-between">
                  <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">
                    <strong>Ik bespaar</strong>
                  </p>
                  <p className="font-Source font-regular text-[16px] text-[#4F4C4C]">
                    <strong>
                      €{' '}
                      {Number.isNaN(Math.round(carddata?.bespaar))
                        ? 0
                        : Math.round(carddata?.bespaar)}{' '}
                      / jaar
                    </strong>
                  </p>
                </div>
              }
            </div>
          </div>
        
        </article>
         : <></>
        
      
        }
     
      
        <article>
          <p className="font-Source font-semibold text-[18px] text-[#4F4C4C] mt-[10px]">Meer info bij ons advies</p>
          <p dangerouslySetInnerHTML={{ __html: carddata?.advise }} />
        </article>
      </div>
   

    </div>
  )
}

