import React, {Dispatch, FC, ChangeEvent} from "react";

import {Input} from "../../shared/forms/input";
import {PrimaryButton} from "../../shared/buttons/igemo_button";


interface FormStep1Struct {
    errors: errorStruct
    isLoading: boolean
    editOrganization: string
    organisationValues: valueStruct
    handleSubmitForm: Dispatch<any>
    handleChange: Dispatch<ChangeEvent<object>>
}

interface errorStruct {
    orgName: string
    subDomain: string
    lastName: string
    firstName: string
    adminEmail: string
    phone: string
}

interface valueStruct {
    orgName: string
    subDomain: string
    lastName: string
    firstName: string
    adminEmail: string
    phone: string
}

const FormStep1: FC<FormStep1Struct> = ({
    organisationValues,
    handleSubmitForm,
    handleChange,
    isLoading,
    errors,
    editOrganization
}) => {
    return (
        <>
            <div className="border-grey-300 border-b-[1px] border-t-[1px]">
                <div className="px-8 py-8" style={{ margin: '0 20%' }}>
                    <div className={`lg:col-span-2 w-full mb-3  relative w-full  font-semibold text-[#00000040] `}>
                        Details Organisatie
                    </div>

                    <div className="w-1/3 text-xs w-full mb-5">
                        <p className="font-semibold text-left mb-1 primary-text" dangerouslySetInnerHTML={{ __html: 'NAAM ORGANISATIE' }} />
                        <div className="mt-3 relative bg-clear w-full border-2 border-gray-100">
                            <input
                                autoComplete="off"
                                className="py-2 px-1 focus:outline-none w-full"
                                placeholder="NAAM ORGANISATIE"
                                name="orgName"
                                type="input"
                                onChange={handleChange}
                                defaultValue={organisationValues?.orgName}
                                value={organisationValues?.orgName}
                            />
                        </div>
                        <span className="text-error text-xs">{errors.orgName ? errors.orgName : null}</span>
                    </div>

                    <div className="w-1/3 text-xs w-full mb-5">
                        <p className="font-semibold text-left mb-1 primary-text" dangerouslySetInnerHTML={{ __html: 'DOMEIN NAAM' }} />
                        <div className="mt-3 relative bg-clear w-full border-2 border-gray-100">
                            <input
                                autoComplete="off"
                                className="py-2 px-1 focus:outline-none w-full"
                                placeholder="DOMEIN NAAM"
                                name="subDomain"
                                disabled={editOrganization!==""?true:false}
                                type="input"
                                onChange={handleChange}
                                defaultValue={organisationValues?.subDomain}
                                value={organisationValues?.subDomain}
                            />
                        </div>
                        <span className="text-error text-xs">{errors.subDomain ? errors.subDomain : null}</span>
                    </div>
                </div>
            </div>

            <div className="border-grey-300 border-b-[1px]">
                <div className="px-8 py-8" style={{ margin: '0 20%' }}>
                    <div className={`lg:col-span-2 w-full mb-3  relative w-full  font-semibold text-[#00000040] `}>
                        INFORMATIE HOOFDADMIN
                    </div>


                    <div className="w-1/3 text-xs w-full mb-5">
                        <p className="font-semibold text-left mb-1 primary-text" dangerouslySetInnerHTML={{ __html: 'VOORNAAM' }} />
                        <div className="mt-3 relative bg-clear w-full border-2 border-gray-100">
                            <input
                                autoComplete="off"
                                className="py-2 px-1 focus:outline-none w-full"
                                placeholder="VOORNAAM"
                                name="firstName"
                                type="input"
                                onChange={handleChange}
                                defaultValue={organisationValues?.firstName}
                                value={organisationValues?.firstName}
                            />
                        </div>
                        <span className="text-error text-xs">{errors.firstName ? errors.firstName : null}</span>
                    </div>

                    <div className="w-1/3 text-xs w-full mb-5">
                        <p className="font-semibold text-left mb-1 primary-text" dangerouslySetInnerHTML={{ __html: 'ACHTERNAAM' }} />
                        <div className="mt-3 relative bg-clear w-full border-2 border-gray-100">
                            <input
                                autoComplete="off"
                                className="py-2 px-1 focus:outline-none w-full"
                                placeholder="ACHTERNAAM"
                                name="lastName"
                                type="input"
                                onChange={handleChange}
                                defaultValue={organisationValues?.lastName}
                                value={organisationValues?.lastName}
                            />
                        </div>
                        <span className="text-error text-xs">{errors.lastName ? errors.lastName : null}</span>
                    </div>

                    <div className="w-1/3 text-xs w-full mb-5">
                        <p className="font-semibold text-left mb-1 primary-text" dangerouslySetInnerHTML={{ __html: 'EMAIL' }} />
                        <div className="mt-3 relative bg-clear w-full border-2 border-gray-100">
                            <input
                                autoComplete="off"
                                className="py-2 px-1 focus:outline-none w-full"
                                placeholder="EMAIL"
                                name="adminEmail"
                                type="input"
                                onChange={handleChange}
                                defaultValue={organisationValues?.adminEmail}
                                value={organisationValues?.adminEmail}
                            />
                        </div>
                        <span className="text-error text-xs">{errors.adminEmail ? errors.adminEmail : null}</span>
                    </div>

                    <div className="w-1/3 text-xs w-full mb-5">
                        <p className="font-semibold text-left mb-1 primary-text" dangerouslySetInnerHTML={{ __html: 'TELEFOON' }} />
                        <div className="mt-3 relative bg-clear w-full border-2 border-gray-100">
                            <input
                                autoComplete="off"
                                className="py-2 px-1 focus:outline-none w-full"
                                placeholder="TELEFOON"
                                name="phone"
                                type="input"
                                onChange={handleChange}
                                defaultValue={organisationValues?.phone}
                                value={organisationValues?.phone}
                            />
                        </div>
                        <span className="text-error text-xs">{errors.phone ? errors.phone : null}</span>
                    </div>

                </div>
            </div>
            <div className="px-8">
                <div className="flex justify-end mt-4">
                    <PrimaryButton
                        content={isLoading ? 'Adding' : 'Volgende'}
                        onClick={() => handleSubmitForm(undefined)}
                    />
                </div>
            </div>
        </>
    )
}

export default FormStep1;

