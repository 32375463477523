import React, { FC, useEffect } from 'react'

interface RenderFCProps {
    status: Boolean,
    setStatus: React.Dispatch<React.SetStateAction<boolean | Boolean>>,
    toggleStatus: () => void,
}

interface TogglerInterface {
    render: FC<RenderFCProps>,
    initialStatus?: Boolean,
}

export const Toggler: FC<TogglerInterface> = ({ render, initialStatus: defautStatus = true }) => {
    const [status, setStatus] = React.useState(defautStatus)
    const toggleStatus = () => setStatus(!status)

    return render({ status, setStatus, toggleStatus })
}
