import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "../titleBar";
import Box from "../box";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal";

// image imports
import ClimateThree from "../../../assets/climate/climate_three.png"
import ClimateFour from "../../../assets/climate/climate_four.png"
import ClimateFive from "../../../assets/climate/climate_five.png"
import ClimateSix from "../../../assets/climate/climate_six.png"

type VoorOfachtertuinProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    },
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const VoorOfachtertuin = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: VoorOfachtertuinProps) => {
    const [ hoverState, setHoverState ] = useState(0);
    const [ activeBox, setActiveBox ] = useState("");
    const [ modalState, setModalState ] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("28");
        if (localState) {
            setActiveBox(localState.answerNo);
        }
    }, [])

    const goBack = () => {
        navigate(pageData?.prev_url || "")
    }

    const getImage = (answerIndex?: string) => {
        switch(answerIndex) {
            case "0":
                return ClimateThree;
            case "1":
                return ClimateFour;
            case "2":
                return ClimateFive;
            case "3":
                return ClimateSix;
        }
    }

    const onClick = (answer?: string, answerIndex?: string) => {
        if(answerIndex === "0") {
            const question = "voor-_of_achtertuin";
            updateAnswer(question || "", answer || "", "/vraag/klimaat");
            storeAnswer({"answerNo": answerIndex, "voor-_of_achtertuin": answer, image: getImage(answerIndex)}, "28")
        }
        else {
            const question = "voor-_of_achtertuin";
            updateAnswer(question || "", answer || "", pageData?.next_url);
            storeAnswer({"answerNo": answerIndex, "voor-_of_achtertuin": answer, image: getImage(answerIndex)}, "28")
        }
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {pageData?.back_text} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "flex flex-col w-full mt-[10px] mb-[100px]">
                    <div className = "flex w-full border-b-[1px] border-b-[#F0F5F5] flex-col md:flex-row">
                        <Box flex = {1} hasRightBorder text = "Geen voor- of achtertuin" hoverValue = {1} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {ClimateThree} answerIndex = "0" isActive = {activeBox === "0"} />
                        <Box flex = {1} hasRightBorder text = "Enkel voortuin" hoverValue = {2} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {ClimateFour} answerIndex = "1" isActive = {activeBox === "1"} />
                        <Box flex = {1} hasRightBorder text = "Enkel achtertuin" hoverValue = {3} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {ClimateFive} answerIndex = "2" isActive = {activeBox === "2"} />
                        <Box flex = {1} hasRightBorder text = "Voortuin en achtertuin" hoverValue = {4} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {ClimateSix} answerIndex = "3" isActive = {activeBox === "3"} />
                    </div>
                </div>
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default VoorOfachtertuin;