import React, { useEffect, useState, useContext, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import Layout from "../../components/layout";
import {
  AddButton, UnRoundedDwnButton,
} from "../../shared/buttons/igemo_button";
import {
  HouseOwnerListingTypeColors,
  UserTypes,
} from "../../models/applicationState";
import useContractor from "./useContractor";
import ContractorList from "./ContractorList";
import { AddContractorModal } from "../../components/modals/addContractor";
import { ModalBox } from "../../shared/modals/modal";
import { Space } from "antd";
import filterIcon from "../../assets/icons/filter-results-button.png";
import ContractorService from "../../api/Contractors";
import { GlobalContext } from "../../context/globalContext/globalContext";
import Search from "../../shared/Search";
import axios from "axios";
import OfferRequestService from "../../api/OfferRequest";
import Filter from "../../shared/filter/Filter";
import useCityStore from "../../components/modals/CityStore";
import { UpdateContractorModal } from "../../components/modals/updateContractor";
import dwnColorGrey from "../../assets/icons/pdf.svg";
import moment from "moment";
import {concat, forEach, sortBy, uniqBy} from "lodash";
import {message} from "antd";

const ContractorListings = () => {
  const { updateContractorStatus, deleteContractor } = useContractor();
  const [openContractorForm, setOpenContractorForm] = useState(false);
  const [openUpdateContractorForm, setOpenUpdateContractorForm] = useState(false);
  const [contractorID, setContractorID] = useState<number | null>(null)
  const [infoModelMsg, setInfoModelMsg] = useState("");
  const [infoModalClasses, setInfoModalClasses] = useState("");
  const [loading, setLoading] = useState(false)
  const [isDownloading, setDownloadingExcel] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [reload, setReload] = useState<boolean>(false)
  const [loadArchives, setLoadArchives] = useState(false)
  const { rendering } = useCityStore()
  const [dataOffset, setDataOffset] = useState(0);
  const [idsWithRecords, setIdsWithRecords] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
 

  // modal
  const [showModal, setShowModal] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);
  const [error, setError] = useState<null | String>(null);
  // states for to create new data
  const [selectedFiltersData, setSelectedFiltersData]: any = useState([
    {
      option1: "",
      option2: "=",
      option3: "",
    },
  ]);

  useEffect(() => {
    console.log(state)
  }, [state])

  const setInfoModal = (type:string , msg: string,messageShown:boolean,) => {
    if(messageShown){
      messageApi.open({
        // @ts-ignore
        type: type?type:"info",
        content: msg,
        duration: 3,
    });
    }
  };

  const handleContractorStatusChange = (id: String) => {
    updateContractorStatus(id);
  };

  const onlyCompletedFilters = useMemo(() => (
    selectedFiltersData?.filter((filter: any) => ![filter.option1, filter.option2, filter.option3].includes(""))
  ), [selectedFiltersData])

  useEffect(() => {
    let source = axios.CancelToken.source();
    dispatch({
      type: "SET_ALL_CONTRACTORS_LIST",
      payload: { allContractorsList: [], total: 0 },
    })
    console.log('testing');
    getDataFromApi(0);

    return () => {
      source.cancel("Cancelling request");
    }
  }, [searchQuery, selectedFiltersData, reload]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    getDataFromApi();

    return () => {
      source.cancel("Cancelling request");
    }
  }, [dataOffset, idsWithRecords]);

  useEffect(() => {
    setReload(!reload)
  }, [rendering])

  useEffect(() => {
    // load offer all status
    (async () => {
      if (!state?.offerAllStatus) {
        const data = await OfferRequestService.loadOfferRequestsAllStatus()
        dispatch({ type: "SET_OFFER_REQUEST_ALL_STATUS", payload: data })
      }
    })()
  }, []);


  const getDataFromApi = (updatedOffset? : number) => {
    let source = axios.CancelToken.source();
    setLoading(true)

    console.log('updatedOffset', updatedOffset)

    const payload = {
      query: searchQuery,
      filters: onlyCompletedFilters,
      // @ts-ignore
      offset: updatedOffset >= 0 ? updatedOffset : dataOffset,
     // adminIds: idsWithRecords.join(','),
    }
    setLoadArchives(!loadArchives)
    // @ts-ignore
    if(updatedOffset <= state.total){
      ContractorService.getAllAdmin(payload, { cancelToken: source.token })
          .then(({ data }) => {
            // @ts-ignore
            let initialData = updatedOffset > 0 ? state?.allContractorsList : data.contractors;
            initialData = concat(initialData, data.contractors);
            // initialData = sortBy(initialData, 'id');
            initialData = uniqBy(initialData, 'id');
            // let newInitialData: any = [];

            // forEach(data.contractorSimulations, (f: any) => {
            //   const index = initialData.findIndex((i: any) => i.id === f.contractorId);
            //   if (index > -1 && f.cpSimulations.length) {
            //     initialData[index].simulation = f.cpSimulations;
            //     newInitialData.push(initialData[index]);
            //   }
            // });

            dispatch({
              type: "SET_ALL_CONTRACTORS_LIST",
              payload: { allContractorsList: initialData, total: data.total },
            })
            if (updatedOffset) {
              setDataOffset(updatedOffset);
            }
            setLoading(false)
          })
          .catch(() => {
            setTimeout(() => { setError(null); setLoading(false) }, 2000)
          })
    }else{
      setLoading(false)
    }
  }

  const handleDownloadClick = () => {
    setDownloadingExcel(true);
    axios.get(process.env.REACT_APP_BACKEND_URL + `domain/1/download`)
        .then((response) => {
          if (response?.data) {
            const downloadLink = document.createElement('a');
            const date = moment(new Date()).format("dd mm yyyy")
            const random = () => Math.floor(Math.random() * (999999 - 1)) + 1;

            downloadLink.href = `${response?.data?.pdf}`;
            downloadLink.setAttribute('download', `Check_je_offerte_[${date}]_${random()}`);
            document.body.appendChild(downloadLink);
            downloadLink.click();
          }
          setDownloadingExcel(false);
        }).catch(() => {
          setDownloadingExcel(false);
        });
  }

  const handleToggleProjectDetails = (cpSimulation: { id: string }) => {
    const newIdsToAdd: any = [...idsWithRecords];
    const index = newIdsToAdd.findIndex((f: any) => f === cpSimulation.id)
    if (index < 0) {
      const newIdsToAdd: any = [...idsWithRecords];
      newIdsToAdd.push(cpSimulation.id);
      setIdsWithRecords(newIdsToAdd);
    }
  };

  const callFromScroll = () => {
    if (state?.allContractorsList?.length < state?.total) {
      const updatedOffset = dataOffset + 10;
      if (!loading) {
        getDataFromApi(updatedOffset);
      }
    }
  }

  const deleteContractorId = (contractorId: number) => {
    deleteContractor(contractorId.toString());
    setDataOffset(0);
    setReload(!reload)
  }


  return (
    <Layout>
      {contextHolder}
      <div className="px-4 py-4 md:px-8 lg:px-16 xl:px-32 md:py-8">
        <div className="flex flex-col gap-4 md:flex-row justify-between relative">
          <div className="flex gap-2">
            <h2 className="text-2xl font-semibold">Aannemers</h2>
            <AddButton
              onClick={() => {
                setOpenContractorForm(true);
              }}
              classes="md:ml-2 py-2 md:py-0 !bg-transparent"
              hollow={true}
              content="Aannemer toevoegen"
            />
          </div>
          <div className="flex items-center w-full md:w-5/12 gap-2 md:gap-0">
            <UnRoundedDwnButton
                classes="mr-2 py-2 px-2 pr-4 bg-transparent "
                onClick={() => { handleDownloadClick() }}
                content="Download"
                icon={dwnColorGrey}
            />
            <div className="md:ml-auto md:mr-2 flex">
                <Space>
                  <div
                    className="relative bg-white rounded px-4 py-[9px] flex h-full items-center cursor-pointer"
                    onClick={() => setShowModal(!showModal)}
                  >
                    {onlyCompletedFilters?.length > 0 && (
                      <div className="absolute -top-2 -left-2">
                        <div className="bg-primary text-xs text-white rounded-full border-2 flex p-2.5 relative">
                          <div className="absolute top-[2px] left-[6px] text-[12px]">
                            {onlyCompletedFilters?.length}
                          </div>
                        </div>
                      </div>
                    )}
                    <img src={filterIcon} className="h-3" alt="" />
                    <h2 className="px-3 text-[14px]">Filter</h2>
                  </div>
                </Space>
            </div>
            {/* search input */}
            <div className="w-full">
              <Search setQuery={setSearchQuery} />
            </div>
          </div>
        </div>
        {/* filters  */}
        {showModal && (
          <Filter
            selectedFiltersData={selectedFiltersData}
            setSelectedFiltersData={setSelectedFiltersData}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
        <div className="mt-10">
          {loading && <div className="my-2">Laden...</div>}
          {error && <div className="my-2 color-red-400">{error}</div>}

          <InfiniteScroll
              hasMore={true}
              next={callFromScroll}
              loader={false}
              dataLength={state?.allContractorsList?.length || 0}
              // scrollThreshold={0}
              endMessage={
                  state?.allContractorsList?.length && <p style={{ textAlign: 'center', paddingTop: 25 }}>
                    Alle gegevens zijn geladen.
                  </p>
              }
          >
            {state?.allContractorsList?.map((contractor: any) => (
              <ContractorList
                  idsWithRecords={idsWithRecords}
                  loading={loading}
                key={contractor.id}
                handleClickHandler={(event: any) => {
                  setContractorID(parseInt(contractor.id))
                  setOpenUpdateContractorForm(true)
                }}
                  handleClickDelete={(event: any) => {
                    deleteContractorId(parseInt(contractor.id))
                  }}
                tableTitle={contractor?.name}
                // tableSubTitle={`${contractor?.cpOfferRequestRenovationContractors?.length || 0} Offerte aanvragen`}
                data={contractor}
                listingType={HouseOwnerListingTypeColors.Normal}
                userTypes={UserTypes.contractor}
                contractorStatus={contractor.status}
                onContractorStatusChange={() =>
                  handleContractorStatusChange(contractor.id)
                }
                reload={() => setReload(!reload)}
                handleToggleProjectDetails={() => handleToggleProjectDetails(contractor)}
              />
            ))}
          </InfiniteScroll>
        </div>
        {
          contractorID ?
            <UpdateContractorModal
              open={openUpdateContractorForm}
              setOpen={setOpenUpdateContractorForm}
              setInfoModal={setInfoModal}
              contractorID={contractorID}
            /> : null
        }
        <AddContractorModal
          open={openContractorForm}
          setOpen={setOpenContractorForm}
          setInfoModal={setInfoModal}
        />
      </div>
    </Layout>
  );
};

export default ContractorListings;
