import React, { FC, useState, useEffect, useContext } from 'react'
import { Link, useParams, } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import SubmitReviewModal from '../../components/submitReviewModal/submitReviewModal';

import Layout from '../../components/layout'
import Table from '../../shared/Table';
import { useNavigate } from "react-router-dom";
import { HouseOwnerListingTypeColors } from '../../models/applicationState';
import OfferRequestListingsRow from './OfferRequestListingsRow';
import OfferRequest from "../../api/OfferRequest";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { formatAddress } from '../../utils/helpers';
import { isEmpty } from "lodash";
import TabsModal from "../../shared/Table/DetailsModal";
import {ContractorInfoModal} from "../contractor/contractor_info_modal";
import { Space } from 'antd';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {SecondaryButton} from "../../shared/buttons/igemo_button";

const OfferRequestListings: FC = () => {
    const { simulationId } = useParams()
    const [data, setData] = useState<any[] | null>(null)
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState<null | Boolean>(null)
    const [offerReqId, setOfferReqId] = useState<string>('')
    const [loadingArchvive, setLoadingArchvive] = React.useState(false)
    const [archivesData, setArchivesData] = React.useState<any[] | null>(null)
    const [selectedItem, toggleSelectedItem] = useState<any>({});
    const [selectedSimulation, setSelectedSimulation] = useState<any>(null)
    const [archiveSimulation, setArchiveSimulation] = useState<any>(null)
    const [selectedOfferRequest, setSelectedOfferRequest] = useState<any>(null)
    const [selectedContractor, setSelectedContractor] = useState<null | number>(null);
    const [reviewModalState, setReviewModalState] = useState<any>({});

    const [address, setAddress] = useState<null | string>(null)
    const { state, dispatch } = useContext(GlobalContext)
    const navigate = useNavigate();
    const [goodOffer, setGoodOffer] = useState<any>({});

    useEffect(() => {
        let offer:any = localStorage.getItem("goodOffer");
        offer = JSON.parse(offer)
        setGoodOffer(offer);
    }, [])
    useEffect(() => {
        (async () => {
            if (!simulationId) return
            try {
                setLoading(true)
                const { data } = await OfferRequest.findOneHouseOwner(simulationId)
                console.log("offerRequests==>",data.offerRequests)

                const { simulation, cpOfferRequestRenovations, id } = data.offerRequests
                setSelectedOfferRequest(data.offerRequests)
                setSelectedSimulation(simulation)
                setData(cpOfferRequestRenovations)
                setOfferReqId(id)

                const adrs = formatAddress(simulation?.city, simulation?.street)
                setAddress(adrs)

                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        })();
        loadArchives();

        // load offer all status
        (async () => {
            if (!state?.offerAllStatus) {
                const data = await OfferRequest.loadOfferRequestsAllStatus()
                dispatch({ type: "SET_OFFER_REQUEST_ALL_STATUS", payload: data })
            }
        })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    const loadArchives = async () => {
        if (!simulationId) return
        try {
            setLoadingArchvive(true)
            const { data } = await OfferRequest.archivedHouseOwner(simulationId)
            if(data?.offerRequests) {
                const { simulation, cpOfferRequestRenovations, id } = data?.offerRequests
                setArchiveSimulation(simulation)
                setArchivesData(cpOfferRequestRenovations)

                setLoadingArchvive(false)
            }
            else {
                setArchiveSimulation(null)
                setArchivesData(null)
                setLoadingArchvive(false)
            }
        } catch (error) {
            console.error(error)
            setLoadingArchvive(false)
        }
    }


    const headings = {
        AANNEMER: "AANNEMER",
        AANNEMER_EMAIL: "EMAIL",
        STATUS: "STATUS",
        DOCUMENTEN: "DOCUMENTEN",
        ACTIES: "ACTIES",
    }

    return (
        <Layout>
            <div className='px-4 py-4 md:px-8 lg:px-16 xl:px-32 md:py-8'>
                <Link to="/offer-request">
                    <Space direction='horizontal' className='mb-4'>
                        <FontAwesomeIcon
                            icon={faCircleLeft as IconProp}
                            className="w-5 h-5 -ml-1 mt-1 -m primary-text cursor-pointer	"
                        />
                        <p className="ml-2 primary-text no-underline hover:underline cursor-pointer">
                            Terug naar offerte aanvragen
                        </p>
                    </Space>
                </Link>
                <h2 className='text-xl font-bold mb-4 ml-8'>{address}</h2>
                {loading && <div className="mt-3">Laden...</div>}
                {data?.map((data, idx) => (
                    <Table
                        key={idx}
                        subtitle={`${data?.cpOfferRequestRenovationContractors?.length} AANNEMER(S)`}
                        listingType={HouseOwnerListingTypeColors.Normal}
                        title={data?.renovation?.title}
                        headings={Object.values(headings)}
                        tableTitleClasses="-ml-8"
                        tableClasses="ml-8 mb-12"
                        mainBurgerClickHandler={() => {
                            toggleSelectedItem({data: data?.cpOfferRequestRenovationContractors, title: data?.renovation.title, logo:data?.renovation.logo, renovationId: data.renovationId, renovationKey: data?.renovation?.key, offerRequestSendAt: selectedOfferRequest?.sendAt })
                        }

                        }
                        wrapInTable={true}
                    >
                        <>
                            {data?.cpOfferRequestRenovationContractors?.filter((contractor: any) => contractor?.selected)?.map((item: any) => (
                                <OfferRequestListingsRow
                                    setReload={() => setReload(!reload)}
                                    title={data?.renovation?.title}
                                    item={item}
                                    renovationId={data.renovationId}
                                    offerRequestId={data.offerRequestId}
                                    key={item.id}
                                    simulation={selectedSimulation}
                                    renovationKey={data?.renovation?.key}
                                    handleMenuClick={() => {
                                        setSelectedContractor(item?.contractor.id)
                                        // toggleSelectedItem({data: data?.cpOfferRequestRenovationContractors, title: data?.renovation.title, logo:data?.renovation.logo, renovationId: data.renovationId })
                                    }}
                                    setReviewModalState = {setReviewModalState}
                                    archive={false}
                                />
                            ))}
                            {(data?.cpOfferRequestRenovationContractors?.length > 1) && (
                                <SecondaryButton
                                    style={{ marginTop: 15, width: 'max-content', background: 'none' }}
                                    content="Offerte-onderdelen vergelijken"
                                    onClick={() => {
                                        toggleSelectedItem({
                                            selectedTab: '3',
                                            logo: data?.renovation.logo,
                                            title: data?.renovation.title,
                                            renovationId: data.renovationId,
                                            renovationKey: data?.renovation?.key,
                                            data: data?.cpOfferRequestRenovationContractors,
                                            offerRequestSendAt: selectedOfferRequest?.sendAt,
                                        })
                                    }}
                                />
                            )}
                        </>
                    </Table>
                ))}
                {archivesData?.map((data) => (
                    <Table
                        listingType={HouseOwnerListingTypeColors.Archive}
                        title="ARCHIEF"
                        subtitle={`${data?.cpOfferRequestRenovationContractors?.length} OFFERTE(S)`}
                        headings={Object.values(headings)}
                        tableTitleClasses="-ml-8"
                        tableClasses="ml-8 mb-12"
                        initialStatus={false}
                        mainBurgerClickHandler={() => {
                            toggleSelectedItem({data: data?.cpOfferRequestRenovationContractors, title: data?.renovation.title, logo:data?.renovation.logo, renovationId: data.renovationId, renovationKey: data?.renovation?.key, offerRequestSendAt: selectedOfferRequest?.sendAt })
                        }}
                        wrapInTable={true}
                    >
                        {loadingArchvive && <div className='my-2'>Laden...</div>}
                        {data?.cpOfferRequestRenovationContractors?.filter((contractor: any) => contractor?.selected).map((item: any) => (
                            <OfferRequestListingsRow
                                setReload={() => setReload(!reload)}
                                key={item.id}
                                item={item}
                                offerRequestId={offerReqId}
                                renovationId={data.renovationId}
                                title={''}
                                simulation={archiveSimulation}
                                handleMenuClick={() => {
                                    setSelectedContractor(item?.contractor.id)
                                    // toggleSelectedItem({data: data?.cpOfferRequestRenovationContractors, title: data?.renovation.title, logo:data?.renovation.logo })
                                }}
                                archive={true}
                                renovationKey={data?.renovation?.key}
                            />
                        ))}
                    </Table>
                ))}
            </div>
            <div className = "flex justify-center px-4 py-4 md:px-8 lg:px-16 xl:px-32 md:py-8">
                <div className = "w-full relative flex flex-col justify-center items-center p-[32px] relative">
                    <div className = "w-full h-full absolute top-0 left-0 primary-main opacity-[0.2]" />
                    <p className = "font-sans font-semibold text-[#1C1C1C] text-[18px] relative">{goodOffer?.title}</p>
                    <p className = "font-sans font-regular font-[14px] text-[#1C1C1C] relative [&_a]:text-primary [&_a]:underline" dangerouslySetInnerHTML={{__html: goodOffer?.text}}></p>
            </div>
            </div>
            {
                selectedContractor ?
                    <ContractorInfoModal
                        title="Gegevens van dev aannemer"
                        open={true}
                        setOpen={()=> setSelectedContractor(null)}
                        selectedContractor={selectedContractor}
                    />
                    :
                <>
                    {!isEmpty(selectedItem) ? (
                        <TabsModal
                            /* @ts-ignore*/
                            title={selectedItem?.title}
                            /* @ts-ignore*/
                            logo={selectedItem?.logo}
                            allData={data}
                            offerReqId={offerReqId}
                            toggleOpen={toggleSelectedItem}
                            /* @ts-ignore*/
                            selectedItem={selectedItem.data}
                            /* @ts-ignore*/
                            tabKey={selectedItem?.selectedTab}
                            open={!isEmpty(selectedItem)}
                            renovationId={selectedItem?.renovationId}
                            simulation={selectedSimulation}
                            simulationId={selectedSimulation?.id}
                            renovationKey={selectedItem?.renovationKey}
                            offerRequestSendAt={selectedItem?.offerRequestSendAt}
                        />
                    ) : null}
                </>
            }
            {reviewModalState?.id ? <SubmitReviewModal offerRequestContractorId = {reviewModalState?.id} contractorId = {reviewModalState?.contractor?.id} homeOwnerId = {2} closeModal = {() => setReviewModalState({})} /> : <></>}
        </Layout>
    )
}

export default OfferRequestListings
