import {isEmpty} from "lodash";
import React, {FC, useEffect, useState} from "react";

import OfferService from "../../api/Offers";
import {ReactComponent as CheckRed} from "../../assets/icons/x-circle-error.svg";
import {ReactComponent as CheckGreen} from "../../assets/icons/check-circle-green.svg";

interface offerStruct {
    servicesList: any
    selectedContractor?: any
}

const OfferCompare:FC<offerStruct> = ({
    servicesList,
    selectedContractor,
}) => {
    const [isLoading, setLoading] = useState(false);
    const [selectedIds, setSelectedIds] = useState<any>([]);

    useEffect(() => {
        fetchContractorDetails();
    }, []);

    const seeIfServiceIsChecked = (contractorDetails: any) => {
        if (!isEmpty(contractorDetails)) {
            const list: any = [];
            const auth = JSON.parse(localStorage.getItem('auth') || '{}');

            servicesList?.services[0]?.children?.map((s: any) => {
                if (contractorDetails?.find((c:any) => c?.serviceId === s?.id)) {
                    list.push({
                        contractorId: selectedContractor || auth?.company?.id,
                        serviceId: s?.id
                    });
                }
            });
            setSelectedIds([
                ...selectedIds,
                ...list,
            ]);
        }
    };

    const fetchContractorDetails = async () => {
        const auth = JSON.parse(localStorage.getItem('auth') || '{}');
        try {
            setLoading(true)
            const response = await OfferService.getContractorServiceOffers(selectedContractor || auth.company.id);
            const {data} = response;

            seeIfServiceIsChecked(data);
        } catch (error) {
            setLoading(false)
            console.error(error);
        } finally {
            setLoading(false)
        }
    };

    return (
        <div className="py-2">
            {isLoading ? (
                <div className="px-16 py-16" style={{ textAlign: 'center' }}>Laden...</div>
            ) : (
                <div className="grid grid-cols-12">
                    {!isEmpty(servicesList) ? (
                        servicesList?.services[0]?.children?.map((s: any) => {
                            const isSelected = selectedIds?.filter((e: any) => e.serviceId === s?.id);

                            return (
                                <div className="col-span-6">
                                    <div className="grid grid-cols-12 mb-2">
                                        <div className="col-span-1 text-primary">
                                            {!isEmpty(isSelected)
                                                ? <CheckGreen height={20} width={20} />
                                                : <CheckRed height={20} width={20} />
                                            }
                                        </div>
                                        <div className="col-span-11">{s?.name}</div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="px-16 py-16" style={{ textAlign: 'center' }}>Geen data.</div>
                    )}
                </div>
            )}
        </div>
    )
};

export default OfferCompare;
