import React, {FC, useState, useEffect} from "react";
import {GetAPIService, PostAPIService} from "../context/services";
import { AutoCompleteModalDropDown } from "./autoCompleteModal";


export const AddressBarModal: FC<{
    onCityChange?: (cityId: string, options?: any)=> void;
    onStreetChange?: (street: string, options: any)=> void;
    defaultValueCity: string; 
    defaualtValueStreet: string;
    disabled: boolean;
    errors?:any
}> = ({
    onCityChange = ()=> {},
    onStreetChange = ()=> {},
    defaultValueCity, 
    defaualtValueStreet,        
    disabled,
    errors
})=> {
    const [cityStr, setCityStr] = useState("")
    const [possibleCities, setPossibleCities] = useState([])
    const [streetStr, setStreetStr] = useState("")
    const [possibleStreets, setPossibleStreets] = useState([])
    const [zipCode, setZipCode] = useState("")

    useEffect(()=> {
        if(cityStr.length > 1 ){
            GetAPIService(`geo/cityname/${cityStr}`).then((res: any)=> {
                const citiesData = res?.data?.data

                const cities: any = []
                if (citiesData){
                    citiesData.forEach(
                        (city: { id: string; nl: string; fr: string; zipCodes: { zipCode: string }[] }) => {
                            city.zipCodes.forEach((zipCode) => {
                                cities.push({
                                    id: city.id, value: (city.nl || city.fr) + ", " + zipCode.zipCode, zip: zipCode.zipCode, key: city.id+zipCode.zipCode
                                })
                            })
                        })

                }
                setPossibleCities(cities)
            })
        }
    }, [cityStr])

    // console.log("possible streets s are ", possibleStreets)
    useEffect(()=> {
        PostAPIService('/geo/street', {
            'searchQuery': streetStr,
            'zipCode': zipCode
        }).then((res: any)=> {
            const streetsData = res?.data?.data
            if (streetsData)
                setPossibleStreets(streetsData.map((street: { id: string; nl: string; fr: string; number: string, box: string })=> {return {id: street.id, value: street.box? (street.nl || street.fr) + ' ' + street.number +" / "+ street.box : (street.nl || street.fr) + ' ' + street.number, key: street.id}}))
        })
    }, [streetStr])

    const cityChangeHandler = (city: string, option: { id: string; value: string; zip: string })=> {
        console.log('opton is ', option)
        setZipCode(option.zip)
        onCityChange(option.id, option)
    }

    return (
        <div className="grid grid-cols-12 h-10">
            <div className="w-full col-span-4 md:w-auto  sm:col-span-4 justify-center items-center pt-2">
                <AutoCompleteModalDropDown
                    classes="text-xs"
                    defaultValue={defaultValueCity}
                    // name="city"
                    placeholder="Gemeente, postcode"
                    possibleValues={possibleCities}
                    onChangeHandler={(value: string)=> setCityStr(value)}
                    onSelectHandler={cityChangeHandler}
                    errors={errors.city}
                />
                
            </div>
            {/* <div className="text-xs col-span-3 flex justify-center items-center">
                <input className="pl-2 border-gray-300 border-[1px] h-[32px] rounded" style={{width:"100%"}} value={zipCode} disabled={true}/>
            </div> */}
            <div className="w-full col-span-8 md:w-auto sm:col-span-8 justify-center items-center pt-2 pl-1">
                <AutoCompleteModalDropDown
                    disabled={disabled}
                    classes="text-xs"
                    // name="street"
                    defaultValue={defaualtValueStreet}
                    placeholder="Straat nr"
                    possibleValues={possibleStreets}
                    onChangeHandler={(value: string)=> setStreetStr(value)}
                    onSelectHandler={onStreetChange}
                    errors={errors.street}
                />
                
            </div>
        </div>
    )
}