import React, { FC, useRef, useEffect,useState} from "react";
// layout
import Layout from "../../components/layout";
// import SectionForm from "../../components/PdfGenerator/sectionForm";
// import SectionSteps from "../../components/PdfGenerator/sectionSteps";
// import SectionPros from "../../components/PdfGenerator/sectionPros";
// import SectionH2h from "../../components/PdfGenerator/sectionHome2home";
import igemoLogo from "../../assets/logo/g10.svg";
import PdfService from '../../api/Pdf'
import { useParams } from "react-router-dom";
import '../../styles/pdf.css'

import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/nl'  // without this line it didn't work
moment.locale('nl')


interface PdfGeneratorInterface {}

const PdfGenerator: FC<PdfGeneratorInterface> = () => {

const { hash } = useParams<string>();

  const [data, setData] = useState<string | TrustedHTML>("")

console.log("searchParams",hash)
  
  useEffect(() => {
    (async () => {
      if(hash){
          const resp = await PdfService.getDom(hash)
          const { data } = resp.data
          // console.log("==>",JSON.parse(data.pdfDom))
          setData(JSON.parse(data.pdfDom));
      }
  })()
}, [hash])

  return (
      <>
        <div className="w-full mx-auto p-4" style={{ pageBreakInside: "avoid"}}>
        <div className="flex">
          <div className="grow-0">
            <img src={igemoLogo} className="h-[50px] object-fit cursor-pointer" />
          </div>
          <div className="grow"></div>
          <div className="grow-0 items-center	flex">
            Gemaakt op <Moment format="LL" date={new Date()} className = "ml-[5px]" />
          </div>
        </div>
        </div>
        <div className="pdfContainer" style={{ pageBreakInside: "auto"}} dangerouslySetInnerHTML={{__html:  data }}>
        </div>
        {/* <SectionH2h /> */}
      </>
  );
};

export default PdfGenerator;
