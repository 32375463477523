import {isEmpty} from "lodash";
import React, {FC, useEffect, useState} from "react";

import OfferService from "../../api/Offers";
import OfferCompareCard from "./ContractorOfferCompareCard";

interface offerStruct {
    servicesList: any
    selectedContractor?: any
}

const OfferCompare:FC<offerStruct> = ({
    servicesList,
    selectedContractor,
}) => {
    const [isLoading, setLoading] = useState(false);
    const [contractorDetails, setContractorDetails] = useState<any>({});

    useEffect(() => {
        fetchContractorDetails();
    }, []);


    const fetchContractorDetails = async () => {
        const auth = JSON.parse(localStorage.getItem('auth') || '{}');
        try {
            const response = await OfferService.getContractorServiceOffers(auth.uid);
            const {data} = response;

            setContractorDetails(data);
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    return (
        <div className="px-8 py-8">
            {isLoading ? (
                <div className="px-16 py-16" style={{ textAlign: 'center' }}>Laden...</div>
            ) : (
                !isEmpty(servicesList?.cpContractorRenovations) ? (
                    <>
                        {servicesList?.cpContractorRenovations?.map((o: any) => {
                            return (
                                <OfferCompareCard
                                    cardData={o}
                                    contractorDetails={contractorDetails}
                                    selectedContractor={selectedContractor}
                                />
                            );
                        })}
                    </>
                ) : (
                    <div className="px-16 py-16" style={{ textAlign: 'center' }}>Geen data.</div>
                )
            )}
        </div>
    )
};

export default OfferCompare;
