import { sortBy, concat, forEach, uniqBy } from "lodash";
import { useState, useEffect, useContext, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import Layout from "../../components/layout";
import { AddButton } from "../../shared/buttons/igemo_button";
import {
  HouseOwnerListingTypeColors,
  UserTypes,
} from "../../models/applicationState";
import { AddHouseOwnerModal } from "../../components/modals/addHouseOwner";
import WarningPopup from "./WarningModal";
import UserService from "../../api/User";
import { GlobalContext } from "../../context/globalContext/globalContext";
import OfferRequestService from "../../api/OfferRequest";
import { ModalBox } from "../../shared/modals/modal";
import { Space } from "antd";
import filterIcon from "../../assets/icons/filter-results-button.png";

import Search from "../../shared/Search";
import axios from "axios";
import Filter from "../../shared/filter/Filter";
import HomeOwnerList from "./HomeOwnerList";

// @ts-ignore
const HouseOwner = () => {
  const [openHouseOwnerForm, setOpenHouseOwnerForm] = useState(false);
  const [warningPopup, toggleWarningPopup] = useState(false);
  const [infoModelOpen, setInfoModelOpen] = useState(false);
  const [infoModelMsg, setInfoModelMsg] = useState("");
  const [infoModalClasses, setInfoModalClasses] = useState("");
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedUser, setSelectedUser] = useState({});
  const [reload, setReload] = useState<boolean>(false)
  const [loadArchives, setLoadArchives] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [dataOffset, setDataOffset] = useState(0);
  const [idsWithRecords, setIdsWithRecords] = useState([]);
    // states for to create new data
  const [selectedFiltersData, setSelectedFiltersData]: any = useState([
    {
      option1: "",
      option2: "=",
      option3: "",
    },
  ]);

  const setInfoModal = (state: boolean, msg: string, classes = "") => {
    setInfoModelOpen(state);
    setInfoModelMsg(msg);
    setInfoModalClasses(classes);
    setTimeout(() => {
      setInfoModelOpen(false);
      setInfoModelMsg("");
    }, 5000);
  };

  const [loading, setLoading] = useState(false);

  const { state, dispatch } = useContext(GlobalContext);

  const onlyCompletedFilters = useMemo(() => (
    selectedFiltersData?.filter((filter: any) => ![filter.option1, filter.option2, filter.option3].includes(""))
  ), [selectedFiltersData])

  useEffect(()=>{
    Object.keys(selectedUser).length > 0 && setOpenHouseOwnerForm(true);
  }, [selectedUser])

  useEffect(()=> {
      setSelectedUser({});
  },[])
useEffect(() => {
    let source = axios.CancelToken.source();

    getDataFromApi(0);

    setLoadArchives(!loadArchives)

    return () => {
        source.cancel("Cancel request")
    }

}, [searchQuery, selectedFiltersData, reload]);


  const getDataFromApi = (updatedOffset?: number) => {
      let source = axios.CancelToken.source();

      const payload = {
          query: searchQuery,
          filters: onlyCompletedFilters,
          // @ts-ignore
          offset: updatedOffset >= 0 ? updatedOffset : dataOffset,
          houseOwnerIds: idsWithRecords.join(','),
      };
      setLoading(true);
      console.log( state.total);
      // @ts-ignore
      if(updatedOffset <= state.total) {
          UserService.loadAllHomeOwners(payload, {cancelToken: source.token})
              .then(({data}) => {
                  // @ts-ignore
                  let initialData = updatedOffset > 0 ? state?.homeOwnerList : data.homeowner;
                  initialData = concat(initialData, data.homeowner);
                  // initialData = sortBy(initialData, 'id');
                  initialData = uniqBy(initialData, 'id');


                  // forEach(data.homeownerSimulations, (f: any) => {
                  //     const index = initialData.findIndex((i: any) => i.id === f.id);
                  //    if (index > -1 && f.cpSimulations.length) {
                  //        initialData[index].cpSimulations = f.cpSimulations;
                  //    }
                  // });


                  dispatch({type: "SET_HOME_OWNER_LIST", payload: {homeOwnerList: initialData, total: data.total}});
                  setLoading(false);
                  if (updatedOffset) {
                      setDataOffset(updatedOffset);
                  }
              })
              .catch((error) => {
                  console.log(error)
                  setLoading(false)
              });
      }else{
          setLoading(false);
      }
  };

  const callFromScroll = () => {
      if (state?.homeOwnerList?.length < state?.total) {
          const updatedOffset = dataOffset + 10;
          if (!loading) {
              getDataFromApi(updatedOffset);
          }
      }
  }

    useEffect(() => {
    // load status
    (async () => {
      if (!state?.offerAllStatus) {
        const data = await OfferRequestService.loadOfferRequestsAllStatus();
        dispatch({ type: "SET_OFFER_REQUEST_ALL_STATUS", payload: data });
      }
    })();
  }, [])
  const convertTypeId = (type: Number) =>{
    if(type == 1){ return "Huiseigenaar"}
    if(type == 2){ return "Aannemer"}
    if(type == 5){ return "Aannemer - Admin"}
    if(type == 3){ return "Igemo"}
    if(type == 4){ return "Igemo - Admin"}
  }

    const handleToggleProjectDetails = (cpSimulation: { id: string }) => {
        const newIdsToAdd: any = [...idsWithRecords];
        const index = newIdsToAdd.findIndex((f: any) => f === cpSimulation.id)
        if (index < 0) {
            const newIdsToAdd: any = [...idsWithRecords];
            newIdsToAdd.push(cpSimulation.id);
            setIdsWithRecords(newIdsToAdd);
        }
    };

  return (
    <Layout>
      <div className="px-4 py-4 md:px-8 lg:px-16 xl:px-32 md:py-8">
        <div className="flex flex-col gap-4 md:flex-row justify-between relative">
          <div className="flex gap-2">
            <h2 className="text-2xl font-semibold">Gebruikers</h2>
            <AddButton
              onClick={() => setOpenHouseOwnerForm(true)}
              classes="md:ml-2 py-2 md:py-0 !bg-transparent"
              hollow={true}
              content="Gebruiker toevoegen"
            />
          </div>
          <div className="flex items-center w-full md:w-4/12 gap-2 md:gap-0">
            <div className="md:ml-auto md:mr-2 flex">
              <div>
                <Space>
                  <div
                    className="relative bg-white rounded px-4 py-[9px] flex h-full items-center cursor-pointer"
                    onClick={() => setShowModal(!showModal)}
                  >
                    {onlyCompletedFilters?.length > 0 && (
                      <div className="absolute -top-2 -left-2">
                        <div className="bg-primary text-xs text-white rounded-full border-2 flex p-2.5 relative">
                          <div className="absolute top-[2px] left-[6px] text-[12px]">
                            {onlyCompletedFilters?.length}
                          </div>
                        </div>
                      </div>
                    )}
                    <img src={filterIcon} className="h-3" alt="" />
                    <h2 className="px-3 text-[14px]">Filter</h2>
                  </div>
                </Space>
              </div>
            </div>
            {/* search input */}
            <div className="w-full">
              <Search setQuery={setSearchQuery} />
            </div>
          </div>
        </div>
        {/* filters  */}
        {showModal && (
          <Filter
            selectedFiltersData={selectedFiltersData}
            setSelectedFiltersData={setSelectedFiltersData}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
        <div className="mt-10">
          {loading && <div className="my-2">Laden...</div>}

          <InfiniteScroll
              hasMore={state?.homeOwnerList?.length < state?.total}
              next={callFromScroll}
              loader={false}
              dataLength={state?.homeOwnerList?.length || 0}
              scrollThreshold={0}
              endMessage={
                  state?.homeOwnerList?.length && <p style={{ textAlign: 'center', paddingTop: 25 }}>
                      Alle gegevens zijn geladen.
                  </p>
              }
          >
              {state?.homeOwnerList?.map((homeOwner: any, idx: number) => (
                  <HomeOwnerList
                      idsWithRecords={idsWithRecords}
                      loading={loading}
                      key={idx}
                      tableTitle={`${homeOwner.firstName} ${homeOwner.lastName} | ${convertTypeId(homeOwner.typeId)}`}
                      data={homeOwner}
                      listingType={HouseOwnerListingTypeColors.Normal}
                      toggleWarningPopup={toggleWarningPopup}
                      setSelectedUser={setSelectedUser}
                      handleToggleProjectDetails={() => handleToggleProjectDetails(homeOwner)}
                  />
              ))}
          </InfiniteScroll>

        </div>
      </div>

      <WarningPopup open={warningPopup} toggleOpen={toggleWarningPopup} />
<>
      {openHouseOwnerForm && (<AddHouseOwnerModal
        open={openHouseOwnerForm}
        setOpen={setOpenHouseOwnerForm}
        setSelectedUser={setSelectedUser}
        selectedUser={selectedUser}
        reload={reload}
        setReload={setReload}
        addresses={[
          { label: "ADRES1", value: "address1" },
          { label: "ADRES2", value: "address2" },
        ]}
      />)}
</>
      <ModalBox width={20} title="Info" open={infoModelOpen}>
        <div className={`flex justify-center items-center ${infoModalClasses}`}>
          {infoModelMsg}
        </div>
      </ModalBox>
    </Layout>
  );
};

export default HouseOwner;
