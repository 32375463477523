import { isEmpty } from "lodash";
import React, {useState, FC, useEffect} from "react";

import {
    ReactComponent as ArrowRightCircleGreenEmpty
} from "../../assets/icons/Icon arrow-cercle-right-green-empty.svg";
import OfferCompareStep2 from "./OfferComparisonStep2";

interface cardProps {
    addedIds: any
    cardData?: any
    setAddedIds: any
    contractorList: any
    adminServicesList?: any
}

interface NodeProps {
    node: any
    style: any
    dragHandle: any
}

const OfferCompareCard: FC<cardProps> = ({
    cardData,
    addedIds,
    setAddedIds,
    contractorList,
    adminServicesList,
  }) => {
    useEffect(() => {
        toggleFormTab(cardData?.renovation?.renovationServices[0]?.renovationServicesGroup?.services[0]?.id);
    }, []);
    const [showBody, toggleShowBody] = useState(false);
    const [servicesList, setServicesList] = useState([]);
    const [formStep, setFormStep] = useState(cardData?.renovation?.renovationServices[0]?.renovationServicesGroup?.services[0]?.id);

    const getSelectedTabData = (id: string) => {
        if (!isEmpty(cardData?.renovation?.renovationServices)) {
            const currentData = cardData?.renovation?.renovationServices[0]?.renovationServicesGroup?.services?.find((f: any) => f.id === id);
            const treeData = currentData;
            return treeData;
        }
        return [{ id: '1', name: '', children: []}];
    };

    const toggleFormTab = (id: string) => {
        const data = getSelectedTabData(id);
        setServicesList(data);
        setFormStep(id);
    };

    return (
        <div className="py-4 px-4 w-full bg-[#0000000a] mb-8">
            <div className="flex cursor-pointer" onClick={() => toggleShowBody(!showBody)}>
                <div className="flex-row mt-1 cursor-pointer">
                    <div className="h-3 w-4 primary-fill">
                        <ArrowRightCircleGreenEmpty height={24} width={24} />
                    </div>
                </div>
                <div className='ml-6 font-semibold whitespace-nowrap text-[20px]'>
                    {!isEmpty(adminServicesList)
                        ? adminServicesList?.name
                        : 'Technisch'
                    }
                </div>
            </div>


            {showBody && (
                <div className="px-6">
                    {isEmpty(adminServicesList) && (
                        <div style={{ padding: '0 0 15px', marginTop: 10}}>
                            {cardData?.renovation?.renovationServices[0]?.renovationServicesGroup?.services?.map((s: any) => {
                                return (
                                    <span
                                        className={`px-2 py-2 cursor-pointer ${formStep === s.id ? "primary-text primary-border border-b-[3px]" : "text-[#00000040]"}`}
                                        onClick={() => toggleFormTab(s.id)}
                                    >
                                    {s.name}
                                </span>
                                )
                            })}
                        </div>
                    )}


                    <OfferCompareStep2
                        isAdministrative={!isEmpty(adminServicesList)}
                        servicesList={!isEmpty(adminServicesList) ? adminServicesList : servicesList}
                        contractorList={contractorList}
                        setAddedIds={setAddedIds}
                        addedIds={addedIds}
                    />
                </div>
            )}
        </div>
    )
};

export default OfferCompareCard;
