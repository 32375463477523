import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../../layout";

type BlankProps = {

}

const Blank = ({}: BlankProps) => {
    const { token } = useParams();

    return (
        <Layout openPasswordResetModal = {token ? true : false}>
            <>
            </>
        </Layout>
    )
}

export default Blank;