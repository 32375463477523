// @ts-ignore

import React, {useState, Dispatch, useEffect} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {message, Modal, Upload} from 'antd';
import type { UploadFile, UploadProps } from 'antd';
import {PostAPIService} from "../../context/services";

const getBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

interface uploadProps {
    imageFile: any
    maxCount: number
    handleOnChange: Dispatch<any>
    onlyForPreviewImages?: any[]
}

const UploadImage: React.FC<uploadProps> = ({
    maxCount,
    imageFile,
    handleOnChange,
    onlyForPreviewImages = [],
}) => {

    const [messageApi, contextHolder] = message.useMessage();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>(imageFile || []);

    const uploadImageToApi = async (image: any, isLogos: boolean) => {
        let formData = new FormData();
        if(image?.originFileObj){
            formData.append('file', image?.originFileObj);

            PostAPIService(`media/upload`, formData)
                .then((response) => {
                    if (response.data.success == true) {
                        handleOnChange({ response: response.data.data, isLogos, name: image?.name });
                        messageApi.open({
                            type: 'success',
                            content: 'Image is succesvol geüpload.',
                            duration: 3,
                        });
                    } else {
                        messageApi.open({
                            type: 'success',
                            content: 'Image is failed geüpload.',
                            duration: 3,
                        })
                    }
                });
        }

    };

    const handleImageRemove = (image: any) => {
        handleOnChange({ response: image, isRemove: image?.b_id });
        return true;
    };

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        if (newFileList?.length) {
            newFileList?.forEach((f: { uid: any }) => {
                const index: number = fileList.findIndex((l: any) => l.uid === f.uid);
                if (index === -1) {
                    uploadImageToApi(f, true);
                }
            })
        }
    }

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );
    return (
        <>
            {contextHolder}
            <div className='grid grid-flow-*'>
                {onlyForPreviewImages.length > 0 ? (
                    <Upload 
                        listType="picture-card"
                        fileList={onlyForPreviewImages}
                        disabled
                        onPreview={handlePreview}
                        onRemove={handleImageRemove}
                        className='!w-fit'
                    />
                ): null}
                <Upload
                beforeUpload={(file, fileList) => {
                    // Access file content here and do something with it
                    // console.log(file)

                    // Prevent upload
                    return false
                }}
                    listType="picture-card"
                    fileList={fileList}
                    // disabled
                    
                    onPreview={handlePreview}
                    onChange={handleChange}
                    onRemove={handleImageRemove}
                >
                    {fileList.length >= maxCount ? null : uploadButton}
                </Upload>
            </div>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    );
};

export default UploadImage;
