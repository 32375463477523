import {ModalBox} from "../../shared/modals/modal";
import {Form} from "antd";
import {PrimaryButton} from "../../shared/buttons/igemo_button";
import {Input} from "../../shared/forms/input";
import { PostFormAPIService } from "../../context/services";
import useCertificateStore from "./certificateStore";
import { useEffect, useState } from "react";
import useCityStore from "./CityStore";
import { debounce } from "lodash";


export const CertificateModalBox = () => {

    const {certificateOpen, setCertificate} = useCertificateStore()
    const [certState, setCertState] = useState<File>()
    useEffect(() => {
        console.log(certState);
    }, [certState])
    const { setRendering, rendering } = useCityStore()
    const form = Form.useForm()[0]

    useEffect(() => {
        form.resetFields()
        setCertState(undefined)
    }, [certificateOpen])

    const onFormSubmit = (values: any) => {
        
        const formData = new FormData();
        formData.append('certificat', values.certificate);
        if (certState) {
            formData.append('logo', certState);
        }

        PostFormAPIService('contractor/create/certificat/',formData).then((contractorRes)=> {
            setCertificate(false)
            setRendering(!rendering)
        }).catch(err => {
            console.log(err);
            
            setCertificate(false)
        })

    }

    const debouncedFormSubmit = debounce(onFormSubmit, 1000);

    return (
        <ModalBox
            title="CERTIFICATEN"
            cancelHandler={() => setCertificate(false)}
            open={certificateOpen}
            width={200}
        >
            <Form form={form} name="contractor_form"
                  onFinish={debouncedFormSubmit}>
                <div className="px-8 py-12 w-auto">
                    <div className="grid grid-cols-8">
                        
                        <div className="col-span-8 lg:col-span-8">
                            <Input 
                                classes="col-span-2 w-full" 
                                full={true}
                                label="Certificaat naam" 
                                name="certificate" 
                                active={true} 
                                onChangeHandler={() => {
                                }} 
                            />
                            <div className="flex items-center">
                                <label htmlFor="fileUploadCert" className="primary-main secondary-text py-2 px-4 rounded cursor-pointer mt-[16px]">
                                    Logo opladen
                                </label>
                                <input
                                    autoComplete="off"
                                    id="fileUploadCert"
                                    type="file"
                                    style={{display: 'none'}}
                                    onChange={(e) => {
                                    let files = e.target.files;
                                    if (files) setCertState(files[0]);
                                    console.log(files);
                                    }}
                                />
                            </div>
                            
                        </div>
                        <p>{certState ? certState.name : ''}</p>
                    </div>
                </div>
                <div className="px-8">
                    <div className="flex justify-end mt-4">
                        <PrimaryButton content="Opslaan" onClick={() => form.submit()}/>
                    </div>
                </div>
            </Form>
        </ModalBox>
    )
}