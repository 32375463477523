import React, {FC, useState} from "react";

import {Input} from "../../shared/forms/input";
import {DropDown} from "../../shared/dropdown";
import {questionTypesMapping, questionTypesMappingReverse} from "./selectedTypeFields";
import {UploadFile} from "../../shared/forms/upload";
import {RadioButton} from "../../shared/forms/radio";
import {Col, Row, Modal} from "antd";
import {CheckBox} from "../../shared/elements/Checbox";
import Climate from "../../assets/climate/climate_thirteen.png";
import uploadIcon from "../../assets/icons/pdf.svg";
import crossIcon from "../../assets/icons/x-circle-error.svg";
import {uploadsUrl} from "../../shared/Table/common";
import {ReactComponent as PdfIcon} from "../../assets/icons/pdf-color.svg";
import pdfIconSrc from "../../assets/icons/pdf-color.svg";
import {ReactComponent as InfoGreen} from "../../assets/icons/info-green.svg";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { isArray } from "lodash";
import Joi,{AnySchema} from "joi";
import {DeleteOutlined} from '@ant-design/icons'

interface RenderFormProps {
    formFieldsData?: any,
    questionAnswers?: any,
    questionKind?: any,
    type?: any,
    payload?: any,
    setPayload?: any,
    readOnly?: any,
    serviceAnswerList?: any[],
}

export const RenderForm: FC<RenderFormProps> = ({formFieldsData, questionAnswers, questionKind, type, payload, setPayload, readOnly = false, serviceAnswerList = []}) => {
    // @ts-ignore
    let fields = formFieldsData[questionKind][type];
    const [errors,setErrors] = useState({})
    const [infoModal, setInfoModal] = useState(false);
    let fieldsSchema=Joi.object().keys({
    })

    if(type === 'Voorgevel' && questionKind === '2'){
        // debugger
        // filter form fileds based on the value (from dropdown selected in questionaire)
        const questionIdType1: string | any[] = ['28', '29', '30',]      // dropdown is 'Geïsoleerd in de spouw'
        const questionIdType2: string | any[] = ['24', '25', '26', '27']      // dropdown is 'Wel geïsoleerd'

        let fieldAnswer = serviceAnswerList.filter((answer: any) => answer.key === 'voorgevel')
        if(fieldAnswer.length > 0) {
            const answer = fieldAnswer[0]

            if(answer.newAnswerText === "Wel geïsoleerd"){
                fields = fields.filter((field: any) => questionIdType1.includes(field.questionId))
            } else if (answer.newAnswerText === "Geïsoleerd in de spouw"){
                fields = fields.filter((field: any) => questionIdType2.includes(field.questionId))
            }
        }
    } else if (type === 'Achter- en zijgevels' && questionKind === '2'){
        // filter form fileds based on the value (from dropdown selected in questionaire)
        const questionIdType1: string | any[] = ['35', '36', '37']      // dropdown is 'Geïsoleerd in de spouw'
        const questionIdType2: string | any[] = ['31', '32', '33', '34']      // dropdown is 'Wel geïsoleerd'

        let fieldAnswer = serviceAnswerList.filter((answer: any) => answer.key === 'zijgevels')
        if(fieldAnswer.length > 0) {
            const answer = fieldAnswer[0]

            if(answer.newAnswerText === "Wel geïsoleerd"){
                fields = fields.filter((field: any) => questionIdType1.includes(field.questionId))
            } else if (answer.newAnswerText === "Geïsoleerd in de spouw"){
                fields = fields.filter((field: any) => questionIdType2.includes(field.questionId))
            }
        }
    } else if (type === 'Vloerisolatie' && questionKind === '2') {
        // render questions based on the values selected
        const defaultQuestions = ['38', '41', '42']
        const questionOneId = '38'
        // get current value
        const isAlreadyExistInPayload =  Object.keys(payload).includes(questionOneId)
        const isExistInAnswers = Object.keys(questionAnswers).includes(questionOneId)
        const value = isAlreadyExistInPayload ?
            payload[questionOneId]?.val :
                isExistInAnswers ? questionAnswers[questionOneId]['answerId'] : null
        if(value) {
            const valueOneId = '45'
            const valueTwoId = '46'
            const valueThreeId = '47'
            // when first option is selected
            if(value === valueOneId) {
                const questions = ['38', '39', '41', '42']
                fields = fields.filter((field: any) => questions.includes(field.questionId))
            }
            // when second option is selected
            else if(value === valueTwoId ) {
                const questions = ['38', '40', '41', '42']
                fields = fields.filter((field: any) => questions.includes(field.questionId))
            }
            // when thrid option is selected
            else if (value === valueThreeId) {
                fields = fields.filter((field: any) => defaultQuestions.includes(field.questionId))
            }
        } else {
            fields = fields.filter((field: any) => defaultQuestions.includes(field.questionId))
        }
    } else if ( type === 'Ventilatie' && questionKind === '2' ) {
        // get current value
        const questionId = '61'
        const selectedAnswerPayload = payload[questionId] || null
        const specificAnswerId = '123'
        if(selectedAnswerPayload && selectedAnswerPayload?.val === specificAnswerId) {
            console.log("selectedAnswerPayload:::", selectedAnswerPayload)
            console.log("show text area")
        }
    }

    /*const getDefaultValueOfField = (fieldType: string, questionId: string)=> {
        console.log('field type and question id are ', fieldType, questionId)
        // @ts-ignore
        if (payload.hasOwnProperty(questionId) && (fieldType !== "picture" || fieldType !== "plan")){
            return payload[questionId]?.val
        } else if (!questionAnswers.hasOwnProperty(questionId)){
            return []
        }
        const fieldsKeysMapping: any = {
            'text': 'answer_short',
            'textarea': 'answer_long',
            'radio': 'answerId',
            'boolean': 'answerId',
            'picture': 'cpOfferRequestRenovationInformationFiles',
            'plan': 'cpOfferRequestRenovationInformationFiles'
        }
        return questionAnswers[questionId][fieldsKeysMapping[fieldType]]
    }*/
    const getDefaultValueOfField = (fieldType: string, questionId: string)=> {
        const fieldsKeysMapping: any = {
            'text': 'answer_short',
            'textarea': 'answer_long',
            'radio': 'answerId',
            'boolean': 'answerId',
            'checkbox': 'answer_short',
            'picture': 'cpOfferRequestRenovationInformationFiles',
            'plan': 'cpOfferRequestRenovationInformationFiles'
        }
        if (payload.hasOwnProperty(questionId) && (fieldType == "textarea" || fieldType == "boolean" || fieldType == "radio" || fieldType == "radio_other")){
            return payload[questionId]?.val
        }
        if (fieldType === 'checkbox') {
            let  checkboxValues = ''
            const isAlreadyExistInPayload =  Object.keys(payload).includes(questionId)
            if (isAlreadyExistInPayload) {
                const rawValues = payload[questionId]?.val?.split(' -=- ')[0]
                return rawValues?.split("|").map((val: string) => val.trim())
            }
            if(questionAnswers.hasOwnProperty(questionId)) {
                const rawValues =questionAnswers[questionId][fieldsKeysMapping[fieldType]]
                checkboxValues = rawValues?.split(' -=- ')[0]
                return checkboxValues?.split('|')?.map((val: string) => val.trim()) || []
            } else {
                return []
            }
        }
        if (!questionAnswers.hasOwnProperty(questionId))
            return []
        return questionAnswers[questionId][fieldsKeysMapping[fieldType]]
    }

    const getFieldSchema = (fieldType: string,isRequired:number): AnySchema =>{
        if (fieldType === 'text'
            || fieldType === 'textarea'
            || fieldType === 'checkbox'
            || fieldType === 'boolean'
            || fieldType === 'radio'
            || fieldType === 'radio_other'
        ){
            if(isRequired)
            return Joi.string()
            .required()
            .messages({
                'string.empty': `Veld mag niet leeg zijn.`,
                'any.required': `*Dit is een verplicht veld`,
            })
            else
            return Joi.string().optional().allow("")
        }
        if(fieldType === 'dropdown'){
            if(isRequired)
                return Joi.number().positive()
                .required()
                .messages({
                'number.positive': `Veld mag niet leeg zijn.`,
                'any.required': `*Dit is een verplicht veld`,
                })
            else return Joi.number().optional().allow("")
        }
        if(fieldType === 'picture' || fieldType === 'plan'){
            if(isRequired)
                return Joi.array().min(1)
                .required()
                .messages({
                'array.min': `Selecteer ten minste één bestand om te uploaden`,
                'any.required': `*Dit is een verplicht veld`,
                })
            else return Joi.array().optional()
        }
        return Joi.string()
            .required()
            .messages({
                'string.empty': `Veld mag niet leeg zijn.`,
                'any.required': `*Dit is een verplicht veld`,
        })
    }

    const handleOnChange = (e: any, fieldType: string) => {
        const { name, value } = e.target;
        try {
            const target = fieldsSchema.extract(name);
            const result = target.validate(value)
            if (result.error) {
                setErrors({ ...errors, [name]: result.error.message })
            }
            else {
                setErrors({ ...errors, [name]: "" })
            }
            setPayload({ ...payload, [name]: {
                type: fieldType,
                val: value,
                formType: questionKind,
                renovationType: type
            } });

        } catch (error: any) {
            setErrors({ ...errors, [name]: error.message });
        }

    };
    const handleCheckboxChange = (e: any, fieldType: string, fieldKey: string,fieldId:string) => {
        const { name } = e.target;
        let values = ''
        let valuesArray = getDefaultValueOfField(questionTypesMapping[fieldType], fieldKey)

            if(valuesArray.includes(name)){
               valuesArray = valuesArray.filter((val: string) => val !== name)
            } else {
                valuesArray.push(name)
            }
            values = valuesArray.join(" | ")

        try {
            const target = fieldsSchema.extract(fieldId);
            const result = target.validate(values)
            if (result.error) {
                setErrors({ ...errors, [fieldId]: result.error.message })
            }
            else {
                setErrors({ ...errors, [fieldId]: "" })
            }
            setPayload({ ...payload, [fieldKey]: {
                    type: fieldType,
                    val: values,
                    formType: questionKind,
                    renovationType: type
                } });

        } catch (error: any) {
            setErrors({ ...errors, [fieldId]: error.message });
        }


    };

    const handleOnChangeVentilation = (e: any, fieldType: string) => {
        const { name, value } = e.target;
        // debugger
        const optionId = payload[name].val?.split('||')[0] || payload[name].val
        const updatedValue = optionId + ' || ' + value
        try {
            const target = fieldsSchema.extract(name);
            const result = target.validate(value)
            if (result.error) {
                setErrors({ ...errors, [name]: result.error.message })
            }
            else {
                setErrors({ ...errors, [name]: "" })
            }
            setPayload({ ...payload, [name]: {
                type: fieldType,
                val: updatedValue,
                formType: questionKind,
                renovationType: type
            } });

        } catch (error: any) {
            setErrors({ ...errors, [name]: error.message });
        }

    };

    const handleOnChangeAsbest = (e: any, fieldType: string, checkboxId: string, questionId: string) => {
        const { name, value } = e.target;

        const existingValuesArray = getDefaultValueOfField(questionTypesMapping[fieldType], questionId)
        const lastCheckboxId = existingValuesArray[existingValuesArray.length - 1]?.split(' -=- ')?.map((val: string) => val.trim())[0]

        let finalValue = lastCheckboxId + " -=- " + value

        existingValuesArray.pop()
        existingValuesArray.push(finalValue)

        const updatedValue = existingValuesArray.join(" | ")
        try {
            const target = fieldsSchema.extract(`${questionId}_input`);
            const result = target.validate(value)
            if (result.error) {
                setErrors({ ...errors, [`${questionId}_input`]: result.error.message })
            }
            else {
                setErrors({ ...errors, [`${questionId}_input`]: "" })
            }
            setPayload({ ...payload, [name]: {
                type: fieldType,
                val: updatedValue,
                formType: questionKind,
                renovationType: type
            } });

        } catch (error: any) {
            setErrors({ ...errors, [`${questionId}_input`]: error.message });
        }


    };

    const handleOnChangeSubfield = (e: any, fieldType: string, parentKey: string, index: number) => {
        const { name, value } = e.target;
        console.log(payload)
        const val = payload[parentKey].value;
        val[index].desc = value
        setPayload({ ...payload, [parentKey]: {
                ...payload[parentKey],
                value: val,
                type: '5'
            } });
    };
    const handleOnChangeSubfieldUpdate = (e: any, fieldType: string, parentKey: string, index: number) => {
        const { name, value } = e.target;
        let values = [];
        if(!payload[parentKey] || !payload[parentKey]?.updates){
            values = getDefaultValueOfField(fieldType, parentKey);
        }else{
            values = payload[parentKey].updates
        }
        values[index].desc = value;
        setPayload({ ...payload, [parentKey]: {
                ...payload[parentKey],
                updates: values,
                type: '5'
            } });
    };
    const handleOnChangeFile = (fieldId: string, files:Array<any>, deleted=false, fileId=null) => {
        let values = [];

        try {
            const target = fieldsSchema.extract(fieldId);
            const result = target.validate(files)
            if (result.error) {
                setErrors({ ...errors, [fieldId]: result.error.message })
            }
            else {
                setErrors({ ...errors, [fieldId]: "" })
            }
            let customizedfiles = files.map(file=>{
                if(file.desc) return file
                else return {...file,desc:""}
            })
            setPayload({ ...payload, [fieldId]: {
                deleted,
                fileId,
                value: customizedfiles,
                formType: questionKind,
                renovationType: type,
                type: '5'
            }
        });

        } catch (error: any) {
            setErrors({ ...errors, [fieldId]: error.message });
        }

    };

    const handleOnChangeFileDelete = (fieldId: string, index: number, fieldType: string, questionId: string, deleted=false, fileId=null) => {
        let values = [];
        if(!payload[questionId] || !payload[questionId]?.updates){
            values = getDefaultValueOfField(fieldType, questionId);
        }else{
            values = payload[questionId].updates
        }
        values[index].deletedAt = new Date().toISOString();
        setPayload({ ...payload, [questionId]: {
                ...payload[questionId],
                deleted,
                fileId,
                updates: values,
                type: '5'
            } });
    };

    return fields?.map((field:any) => {
        const fieldType = field?.type;
        const isRequired = field?.required;
        const questionTitle = field?.title;

        if (fieldType === 'heading') {
            return <div>
                <h1>{field.title}</h1>
            </div>
        }

        if (fieldType === 'text' || fieldType === 'textarea') {
            fieldsSchema=fieldsSchema.keys({
                [field.key]: getFieldSchema(fieldType,isRequired)
            })

            return <div className="pt-4">
                <Modal
                    width="80%"
                    open={infoModal}
                    title="Toegankelijkheid van je huis"
                    className="no-modal-buttons"
                    onOk={() => setInfoModal(false)}
                    onCancel={() => setInfoModal(false)}
                >
                    <p className="px-4 py-4">
                        Beschrijf of je woning bijvoorbeeld vrijstaand of een rijwoning is. Vinden de werken plaats op het gelijkvloers of de verdieping? Is er een oprit beschikbaar of moet de straat afgehuurd worden?
                    </p>

                </Modal>
                <span onClick={() => setInfoModal(true)} style={{ position: 'absolute', marginTop: -1, marginLeft: -30, cursor: 'pointer'}}>
                    {questionTitle === 'Toegankelijkheid van je huis?'
                        ? <AiOutlineInfoCircle size = "18px" className='primary-text'/>
                        : null
                    }
                </span>
                <Input
                    full
                    name={field.key}
                    label={questionTitle}
                    active={field.active}
                    placeholder={field.placeholder}
                    onChangeHandler={(event: any) => handleOnChange(event, questionTypesMappingReverse[fieldType])}
                    defaultValue={getDefaultValueOfField(fieldType, field.questionId)}
                    textArea={fieldType === 'textarea'}
                    readOnly={readOnly}
                    isRequired={isRequired}
                    // @ts-ignore
                    outerError={errors[field.key]}
                />
            </div>
        }

        if (fieldType === 'dropdown') {
            fieldsSchema = fieldsSchema.keys({
                [field.key]: getFieldSchema(fieldType, isRequired)
            })
            return <div className="pt-4">
            <DropDown
                    name={field.key}
                    selectedOption={getDefaultValueOfField(fieldType, field.questionId)}
                    options={field.options}
                    onChangeHandler={(event: any)=> handleOnChange(event, questionTypesMappingReverse[fieldType])}
                    isRequired={isRequired}
                    label={questionTitle}
                    // @ts-ignore
                    outerError={errors[field.key]}
                />
            </div>
        }

        if (fieldType === 'checkbox') {
            console.info("FIELD ID: ",field.key)
            fieldsSchema=fieldsSchema.keys({
                [field.key]: getFieldSchema(fieldType,isRequired)
            })
            return <div className="pt-8 pb-2">
                {field.options.length < 3 ? (
                    <Row>
                        <Col md={8} className="pt-1">
                            <p className="font-semibold text-secondary text-left text-xs">
                                {questionTitle}
                                <span className='text-error'>{isRequired===1 ?"*":""}</span>
                            </p>
                        </Col>
                        <Col md={2}>
                            <CheckBox content={field.options[0].label} name={field.options[0].value} readOnly={readOnly}  status onChange={(event) => handleCheckboxChange(event, questionTypesMappingReverse[fieldType], field.options[0].value,field.key)}/>
                        </Col>
                        <Col md={1}>
                            <CheckBox content={field.options[1].label} name={field.options[1].value} readOnly={readOnly}  status onChange={(event) => handleCheckboxChange(event, questionTypesMappingReverse[fieldType], field.options[1].value,field.key)}/>
                        </Col>
                        <p className="text-error text-xs text-start ml-2">
                            {// @ts-ignore
                            errors[field.key] ? errors[field.key]:''}
                        </p>
                    </Row>
                ) : (
                    <div className="flex flex-col items-start gap-2">
                        <p className="font-semibold text-secondary text-left text-sm">
                            {questionTitle}
                            <span className='text-error'>{isRequired===1 ?"*":""}</span>
                        </p>
                        {field.options.map((_field: any) => {
                            const defaultValuesArray = getDefaultValueOfField(fieldType, field.questionId)

                            let  defaultValueInput = ''
                            const isAlreadyExistInPayload =  Object.keys(payload).includes(field.questionId)
                            if (isAlreadyExistInPayload) {
                                defaultValueInput = payload[field.questionId]?.val?.split(' -=- ')
                            } else {
                                defaultValueInput = questionAnswers.hasOwnProperty(field.questionId) ? questionAnswers[field.questionId]['answer_short']?.split(' -=- ') : ''
                            }
                            if((['147'].includes(_field.value) && defaultValuesArray.includes('147'))){
                                fieldsSchema=fieldsSchema.keys({
                                    [`${field.key}_input`]: getFieldSchema(fieldType,1)
                                })
                            }else{
                                fieldsSchema=fieldsSchema.keys({
                                    [`${field.key}_input`]: getFieldSchema(fieldType,0)
                                })
                            }
                            return (
                            <>
                                <CheckBox
                                    key={_field.value}
                                    content={_field.label}
                                    onChange={(event) => handleCheckboxChange(event, questionTypesMappingReverse[fieldType], field.key,field.key)}
                                    name={_field.value}
                                    readOnly={readOnly}
                                    defaultStatus={defaultValuesArray?.includes(_field.value)}
                                />
                                {(['147'].includes(_field.value) && defaultValuesArray.includes('147')) ? (
                                    <>
                                    <Input
                                        full
                                        name={field.key}
                                        onChangeHandler={(event: any)=> handleOnChangeAsbest(event, questionTypesMappingReverse[fieldType], _field.value, field.key)}
                                        defaultValue={defaultValueInput?.length > 1 ? defaultValueInput[1] : '' }
                                        textArea={fieldType === 'textarea'}
                                        readOnly={readOnly}
                                    />
                                    <p className="text-error text-xs text-start ml-2">
                                    {// @ts-ignore
                                    errors[`${field.key}_input`] ? errors[`${field.key}_input`]:''}
                                    </p>
                                    </>

                                ) : null
                                }

                            </>
                        )
                        })}
                        <p className="text-error text-xs text-start ml-2">
                            {// @ts-ignore
                            errors[field.key] ? errors[field.key]:''}
                        </p>
                    </div>
                )}
            </div>
        }

        if (fieldType === 'picture') {
            fieldsSchema=fieldsSchema.keys({
                [field.key]: getFieldSchema(fieldType,isRequired)
            })

            return <div className="pt-4">
                {/*<div className="flex flex-wrap mr-3">*/}
                {/*    {*/}
                {/*        getDefaultValueOfField(fieldType, field.questionId).map((defaultVals: any)=> {*/}
                {/*            console.log('defaul vals are ', defaultVals)*/}
                {/*            return (*/}
                {/*                !payload[field.questionId]?.deleted*/}
                {/*                    ?*/}
                {/*                    <>*/}
                {/*                        <div className="relative">*/}
                {/*                            <img*/}
                {/*                                width="30px"*/}
                {/*                                height="30px"*/}
                {/*                                src={uploadsUrl(defaultVals?.file)}*/}
                {/*                                alt="default"*/}
                {/*                            />*/}
                {/*                        </div>*/}
                {/*                        <Input*/}
                {/*                            full*/}
                {/*                            textArea={true}*/}
                {/*                            defaultValue={defaultVals?.desc}*/}
                {/*                            classes="col-span-6"*/}
                {/*                            name={`picture_description-${defaultVals?.id}`}*/}
                {/*                            onChangeHandler={(event)=> {handleOnChangeSubfield(event, fieldType, field.questionId, defaultVals?.id)}}*/}
                {/*                            active={payload.hasOwnProperty(field.questionId)}*/}
                {/*                        />*/}
                {/*                        <img*/}
                {/*                            width="10px"*/}
                {/*                            height="10px"*/}
                {/*                            className="cursor-pointer absolute top-[-5px] left-[-5px]"*/}
                {/*                            onClick={() => {*/}
                {/*                                handleOnChangeFile(field.questionId, [], true, defaultVals.id)*/}
                {/*                            }}*/}
                {/*                            src={crossIcon}*/}
                {/*                            alt="cross"*/}
                {/*                        />*/}
                {/*                    </>*/}
                {/*                    :*/}
                {/*                    <></>*/}
                {/*            )*/}
                {/*        })*/}
                {/*    }*/}
                {/*</div>*/}
                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-6">
                        <UploadFile
                            onFileChange={(files: Array<any>)=> {handleOnChangeFile(field.questionId, files)}}
                            name="file"
                            image
                            title={questionTitle}
                            // @ts-ignore
                            outerError={errors[field.key]}
                            isRequired={isRequired}
                        />
                    </div>
                    <div className="col-span-6 mt-20">
                        <label>Beschrijving</label>
                    {payload[field.questionId]?.value?.map((file: any, index: number)=>{
                        return(<Input
                            full
                            textArea={true}
                            defaultValue=''
                            classes="col-span-6"
                            name={`picture_description-${index}`}
                            onChangeHandler={(event)=> {handleOnChangeSubfield(event, fieldType, field.questionId, index)}}
                            active={payload.hasOwnProperty(field.questionId)}
                        />)
                    })}
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-4">
                    {
                        getDefaultValueOfField(fieldType, field.questionId)?.map((defaultVals: any, index: number)=> {
                            console.log('defaul vals are ', defaultVals)
                            return (
                                !defaultVals?.deletedAt
                                    ?
                                    <>
                                        <div className="col-span-6">
                                            <div style={{
                                                border: '1px solid #e1e1e1',
                                                borderRadius: 5,
                                                marginTop: 10,
                                                padding: 10,
                                            }}>
                                                <Row>
                                                    <Col span={6} style={{ display: 'flex' }}>
                                                        <img
                                                            style={{ justifyContent: 'center', alignContent: 'center' }}
                                                            width="48px"
                                                            height="48px"
                                                            alt="default"
                                                            src={uploadsUrl(defaultVals?.file)}
                                                            onError={(event: any) => {
                                                                event.target.src = pdfIconSrc;
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col span={16} style={{ display: 'flex' }}>
                                                        <div
                                                            style={{
                                                                justifyContent: 'center',
                                                                alignContent: 'center',
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis',
                                                                padding: '0 8px',
                                                                lineHeight: 1.5714285714285714,
                                                                flex: 'auto',
                                                                transition: 'all 0.3s',
                                                            }}
                                                        >
                                                            {defaultVals?.title}
                                                        </div>
                                                    </Col>
                                                    <Col span={2} style={{ display: 'flex' }}>
                                                        <DeleteOutlined
                                                            style={{ justifyContent: 'center', alignContent: 'center', color:'#848484' }}
                                                            width="1em"
                                                            height="1em"
                                                            className="cursor-pointer mr-2"
                                                            onClick={() => {
                                                                handleOnChangeFileDelete(field.questionId, index,fieldType,field.questionId, true, defaultVals.id)
                                                            }}
                                                            // src={crossIcon}
                                                            alt="cross"
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="col-span-6">
                                            <Input
                                                full
                                                textArea={true}
                                                defaultValue={defaultVals?.desc}
                                                classes="col-span-6"
                                                name={`picture_description-${defaultVals?.id}`}
                                                onChangeHandler={(event)=> {handleOnChangeSubfieldUpdate(event, fieldType, field.questionId, index)}}
                                                active={true}
                                            />
                                        </div>
                                    </>
                                    :
                                    <></>
                            )
                        })
                    }
                </div>
            </div>
        }

        if (fieldType === 'plan') {
            fieldsSchema=fieldsSchema.keys({
                [field.key]: getFieldSchema(fieldType,isRequired)
            })
            return <div className="pt-4">
                {/*<img src={Climate} alt="House's logo" className = "h-[160px] object-fit" />*/}
                {/*<div className="flex flex-wrap mr-3">*/}
                {/*    {*/}
                {/*        getDefaultValueOfField(fieldType, field.questionId).map((defaultVals: any)=> {*/}
                {/*            console.log('defaul vals are ', defaultVals)*/}
                {/*            return (*/}
                {/*                !payload[field.questionId]?.deleted*/}
                {/*                    ?*/}
                {/*                    <div className="relative">*/}
                {/*                        <img*/}
                {/*                            width="30px"*/}
                {/*                            className="cursor-hand"*/}
                {/*                            height="30px"*/}
                {/*                            src={uploadIcon}*/}
                {/*                            onClick={() => window.open(uploadsUrl(defaultVals?.file), '_blank')}*/}
                {/*                            alt="default "*/}
                {/*                        />*/}
                {/*                        <img*/}
                {/*                            width="10px"*/}
                {/*                            height="10px"*/}
                {/*                            className="cursor-pointer absolute top-[-5px] left-[-5px]"*/}
                {/*                            onClick={() => {*/}
                {/*                                handleOnChangeFile(field.questionId, [], true, defaultVals.id)*/}
                {/*                            }}*/}
                {/*                            src={crossIcon}*/}
                {/*                            alt="cross"*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                    :*/}
                {/*                    <></>*/}
                {/*            )*/}
                {/*        })*/}
                {/*    }*/}
                {/*</div>*/}
                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-6">
                        <UploadFile
                            onFileChange={(files: Array<any>)=> {handleOnChangeFile(field.questionId, files)}}
                            name="file"
                            image={false}
                            title={questionTitle}
                            // @ts-ignore
                            outerError={errors[field.key]}
                            isRequired={isRequired}
                        />
                    </div>
                    <div className="col-span-6 mt-20">
                        <label>Beschrijving</label>
                        {payload[field.questionId]?.value?.map((file: any, index: number)=>{
                            return(<Input
                                full
                                textArea={true}
                                defaultValue=''
                                classes="col-span-6"
                                name={`picture_description-${index}`}
                                onChangeHandler={(event)=> {handleOnChangeSubfield(event, fieldType, field.questionId, index)}}
                                active={payload.hasOwnProperty(field.questionId) && !payload[field.questionId].deleted}
                            />)
                        })}
                    </div>
                    {/*<Input
                        full
                        unControlled
                        classes="col-span-6"
                        defaultValue={getDefaultValueOfField(fieldType, field.questionId)[0]?.desc}
                        label="Beschrijving"
                        name="plan_description"
                        onChangeHandler={(event)=> {handleOnChangeSubfield(event, fieldType, field.questionId, 1)}}
                        active={payload.hasOwnProperty(field.questionId) && !payload[field.questionId].deleted}
                    />*/}
                </div>
                <div className="grid grid-cols-12 gap-4">
                    {
                        getDefaultValueOfField(fieldType, field.questionId)?.map((defaultVals: any, index: number)=> {
                            console.log('defaul vals are ', defaultVals)
                            return (
                                !defaultVals?.deletedAt
                                    ?
                                    <>
                                        <div className="col-span-6">
                                            <div style={{
                                                border: '1px solid #e1e1e1',
                                                borderRadius: 5,
                                                marginTop: 10,
                                                padding: 10,
                                            }}>
                                                <Row>
                                                    <Col span={6} style={{ display: 'flex' }}>
                                                        <img
                                                            style={{ justifyContent: 'center', alignContent: 'center' }}
                                                            width="48px"
                                                            height="48px"
                                                            alt="default"
                                                            src={uploadsUrl(defaultVals?.file)}
                                                            onError={(event: any) => {
                                                                event.target.src = pdfIconSrc;
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col span={16} style={{ display: 'flex' }}>
                                                        <div
                                                            style={{
                                                                justifyContent: 'center',
                                                                alignContent: 'center',
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis',
                                                                padding: '0 8px',
                                                                lineHeight: 1.5714285714285714,
                                                                flex: 'auto',
                                                                transition: 'all 0.3s',
                                                            }}
                                                        >
                                                            {defaultVals?.title}
                                                        </div>
                                                    </Col>
                                                    <Col span={2} style={{ display: 'flex' }}>
                                                        <DeleteOutlined 
                                                            style={{ justifyContent: 'center', alignContent: 'center', color:'#848484' }}
                                                            width="1em"
                                                            height="1em"
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                handleOnChangeFileDelete(field.questionId, index,fieldType,field.questionId, true, defaultVals.id)
                                                            }}
                                                            // src={crossIcon}
                                                            alt="cross"
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="col-span-6">
                                            <Input
                                                full
                                                textArea={true}
                                                defaultValue={defaultVals?.desc}
                                                classes="col-span-6"
                                                name={`picture_description-${defaultVals?.id}`}
                                                onChangeHandler={(event)=> {handleOnChangeSubfieldUpdate(event, fieldType, field.questionId, index)}}
                                                active={true}
                                            />
                                        </div>
                                    </>
                                    :
                                    <></>
                            )
                        })
                    }
                </div>
            </div>
        }

        if ((fieldType === 'boolean' || fieldType === 'radio' || (fieldType === 'radio_other' && payload[field.depKey] === field.depValue)) && !['61', '68', '69'].includes(field.questionId)) {
            fieldsSchema=fieldsSchema.keys({
                [field.key]: getFieldSchema(fieldType,isRequired)
            })
            return (
                <div className="pt-4">
                    <div className="font-semibold text-secondary text-left text-sm mb-2">{questionTitle} <span className='text-error'>{isRequired===1 ?"*":""}</span></div>
                    <RadioButton
                        key={field.key}
                        name={field.key}
                        defaultValue={getDefaultValueOfField(fieldType, field.questionId)}
                        // value={getDefaultValueOfField(fieldType, field.questionId)}
                        options={field.options}
                        onChangeHandler={(event: any)=> handleOnChange(event, questionTypesMappingReverse[fieldType])}
                        readonly={false}
                    />
                    <p className="text-error text-xs text-start ml-2">
                            {// @ts-ignore
                            errors[field.key] ? errors[field.key]:''}
                        </p>
                </div>
            )
        }
        // a unique case - for 'Ventilatie' and 'Asbest' renovation
        if ((fieldType === 'boolean' || fieldType === 'radio' || (fieldType === 'radio_other' && payload[field.depKey] === field.depValue)) && ['61', '68', '69'].includes(field.questionId)) {
            let defaultValObj = getDefaultValueOfField('text', field.questionId) || ""
            let defaultValueIdOnly = ""
            let defaultValueOnly = ""
            if(defaultValObj?.length !== 0) {
                defaultValObj = getDefaultValueOfField("text", field.questionId) || ""
                defaultValueIdOnly = defaultValObj?.split("||").map((v: string)=> v.trim())[0]
                defaultValueOnly = defaultValObj?.split("||").map((v: string)=> v.trim())[1] || ""
            }
            const selectedOptionId = payload[field.questionId]?.val?.split("||").map((v: string)=> v?.trim())[0]

            const showByDefault = ['123', '136', '147'].includes(defaultValueIdOnly)
            const showBySelected = ['123', '136', '147'].includes(selectedOptionId)
            let show = selectedOptionId ? showBySelected : showByDefault

            fieldsSchema=fieldsSchema.keys({
                [field.key]: getFieldSchema(fieldType,isRequired)
            })
            if((['61', '68', '69'].includes(field.questionId) && show)){
                fieldsSchema=fieldsSchema.keys({
                    [field.key]: getFieldSchema(fieldType,1)
                })
            }else{
                fieldsSchema=fieldsSchema.keys({
                    [field.key]: getFieldSchema(fieldType,0)
                })
            }

            return (
                <div className="pt-4">
                    <div className="font-semibold text-secondary text-left text-sm mb-2">{questionTitle} <span className='text-error'>{isRequired===1 ?"*":""}</span></div>
                    <RadioButton
                        key={field.key}
                        name={field.key}
                        defaultValue={defaultValueIdOnly}
                        // value={defaultValueIdOnly}
                        options={field.options}
                        onChangeHandler={(event: any)=> handleOnChange(event, questionTypesMappingReverse['text'])}
                    />
                    {['61', '68', '69'].includes(field.questionId) && show ? (
                        <>
                            <Input
                                full
                                name={field.key}
                                onChangeHandler={(event: any)=> handleOnChangeVentilation(event, questionTypesMappingReverse['text'])}
                                defaultValue={defaultValueOnly}
                                textArea={fieldType === 'textarea'}
                                readOnly={readOnly}
                            />
                            <p className="text-error text-xs text-start ml-2">
                                {// @ts-ignore
                                errors[field.key] ? errors[field.key]:''}
                            </p>
                        </>


                    ): <p className="text-error text-xs text-start ml-2">
                    {// @ts-ignore
                    errors[field.key] ? errors[field.key]:''}
                </p>}
                </div>
            )
        }
    });
};

export const renderFormTabs = (formFieldsData: any, isAnswer: boolean, type: string, payload: any, setPayload: any, readOnly: boolean = false, setOpenPReview: any = false) => {
    const fields = formFieldsData;


    const handleOnChange = (e: any, fieldType: string) => {
        const { name, value } = e.target;
        setPayload({ ...payload, [name]: {
                type: fieldType,
                val: value
            } });
    };

    const getDefaultValueOfField = (fieldType: string, questionId: string) => {
        const fieldsKeysMapping: any = {
            'text': 'answer_short',
            'textarea': 'answer_long',
            'radio': 'answerId',
            'boolean': 'answerId',
            'checkbox': 'answer_short',
            'picture': 'cpOfferRequestRenovationInformationFiles',
            'plan': 'cpOfferRequestRenovationInformationFiles'
        }

        if (fieldType === 'checkbox') {
            // find relevent question and its respective answered value
            const answerValue = fields.filter((field: any) => field.questionId === questionId)
            if(answerValue.length > 0) {
                const rawValues = answerValue[0][fieldsKeysMapping[fieldType]]?.split(' -=- ')
                const checkboxValues = rawValues[0]?.split("|").map((val: string) => val.trim())
                const inputValue = rawValues?.length > 0 ? rawValues[1] : ''
                return [checkboxValues, inputValue]
            } else {
                return [[], '']
            }
        } else {
            return []
        }
    }

    return fields?.map((field:any) => {
        const fieldType = field?.question?.questionType?.type;
        const questionTitle = field?.question?.question;
        let answer = field?.answer_long || field?.answer_short;     // either 'answer_long' or 'answer_short'
        let options: any = [];
        if (fieldType === 'boolean' || fieldType === 'checkbox') {
            options = field?.question?.cpRenovationAnswers?.map((a: { id: any; answer: any; }) => {
                return {
                    value: a?.id,
                    label: a?.answer,
                }
            });
            answer = field?.answer?.answer;
        }

        if (fieldType === 'heading') {
            return <div>
                <h1>{field.title}</h1>
            </div>
        }

        if (fieldType === 'text' || fieldType === 'textarea') {
            return <div className="pt-4">
                <Input
                    full
                    name={field.key}
                    label={questionTitle}
                    active={field.active}
                    placeholder={field.placeholder}
                    onChangeHandler={(event: any)=> handleOnChange(event, questionTypesMappingReverse[fieldType])}
                    defaultValue={answer}
                    textArea={fieldType === 'textarea'}
                    readOnly={readOnly}
                />
            </div>
        }

        if (fieldType === 'dropdown') {
            return <div className="pt-4">
                <DropDown
                    name={field.key}
                    selectedOption=''
                    options={field.options}
                    readonly={readOnly}
                    onChangeHandler={(event: any)=> handleOnChange(event, questionTypesMappingReverse[fieldType])}
                />
            </div>
        }

        if (fieldType === 'checkbox' || fieldType === 'boolean') {
            return <div className="pt-8 pb-2">
                {options.length < 3 ? (
                    <Row className="flex-col md:flex-row">
                        <Col md={8} className="pt-1">
                            <p className="font-semibold text-secondary text-left text-xs">
                                {questionTitle}
                            </p>
                        </Col>
                        {options?.map((a: { label: String | JSX.Element | undefined; id: string; }) => {
                            return (
                                <Col md={2}>
                                    <CheckBox content={a.label} name={a.id} defaultStatus={a.label === answer} readOnly={readOnly}/>
                                </Col>
                            );
                        })}
                    </Row>
                    ) : (
                        <div className="flex flex-col items-start gap-2">
                            <p className="font-semibold text-secondary text-left text-sm">
                                {questionTitle}
                            </p>
                            { options.map((_field: any) => {
                                const [defaultValuesArray, defaultValueInput] = getDefaultValueOfField(fieldType, field.questionId)
                                return (
                                <>
                                    <CheckBox
                                        key={_field.value}
                                        content={_field.label}
                                        onChange={() => {}}
                                        name={_field.value}
                                        readOnly={readOnly}
                                        defaultStatus={defaultValuesArray?.includes(_field.value)}
                                    />
                                    {(['147'].includes(_field.value) && defaultValuesArray.includes('147')) ? (
                                        <Input
                                            full
                                            name={field.key}
                                            onChangeHandler={(event: any)=> {}}
                                            defaultValue={defaultValueInput}
                                            textArea={fieldType === 'textarea'}
                                            readOnly={readOnly}
                                            inputContainerClasses="!mt-0"
                                        />
                                    ): null}
                                </>
                                )
                            }) }
                        </div>
                    )}
            </div>
        }

        if (fieldType === 'picture') {
            return<div className="pt-4">
                <p className="font-semibold text-lg mt-8">Foto's</p>
                <div className="flex">
                    {field.cpOfferRequestRenovationInformationFiles.length > 0 ? <>{field.cpOfferRequestRenovationInformationFiles.map((image: any)=>{
                        let docType = image.file.split('.');
                        docType = docType[docType.length - 1];
                        const iconType = docType == 'pdf'
                            ?  <PdfIcon className='h-24 w-24' title={image.title} />
                            :  <><img src={uploadsUrl(image.file)} alt={image.title} className = "h-[100px] object-fit pr-4" /></>

                        return <>
                            <Col className="py-4 text-center cursor-pointer" onClick={() => setOpenPReview({ ... image, docType: docType})} >
                                {iconType}
                                <p className="pt-1">{`${image?.title}`}</p>
                            </Col>
                        </>
                    })}
                    </>:<div className="p-4 text-center w-full">Geen documenten gevonden.</div>}
                </div>
            </div>
        }

        if (fieldType === 'plan') {
            return<div className="pt-4">
                <p className="font-semibold text-lg mt-8">Plannen</p>
                <div className="flex">
                    {field.cpOfferRequestRenovationInformationFiles.length > 0 ? <>{field.cpOfferRequestRenovationInformationFiles.map((image: any)=>{
                        let docType = image.file.split('.');
                        docType = docType[docType.length - 1];
                        const iconType = docType == 'pdf'
                            ?  <PdfIcon className='h-24 w-24' title={image.title} />
                            :  <><img src={uploadsUrl(image.file)} alt={image.title} className = "h-[100px] object-fit pr-4" /></>

                        return <>
                            <Col className="py-4 text-center cursor-pointer" onClick={() => setOpenPReview({ ... image, docType: docType})} >
                                {iconType}
                                <p className="pt-1">{`${image?.title}`}</p>
                            </Col>
                        </>
                    })}
                    </>:<div className="p-4 text-center w-full">Geen documenten gevonden.</div>}
                </div>
            </div>
        }


        if ((fieldType === 'radio' || (fieldType === 'multiple_D' && payload[field.depKey] === field.depValue)) && !['61', '68', '69'].includes(field.questionId)) {
            const mappedOptions = field?.question?.cpRenovationAnswers?.map((opt: { id: string, answer: string }) => {
                return {
                    value: opt?.id,
                    label: opt?.answer,
                };
            });

            const defaultSelected = field?.answer?.id;

            return <div className="pt-4">
                <div className="font-semibold text-secondary text-left text-sm mb-2">{questionTitle}</div>
                <RadioButton
                    name={field.key}
                    options={mappedOptions}
                    defaultValue={defaultSelected}
                    readonly={readOnly}
                    onChangeHandler={(event: any)=> handleOnChange(event, questionTypesMappingReverse[fieldType])}
                />
            </div>
        }
        // a unique case - for 'Ventilatie' renovation only
        if (['61', '68', '69'].includes(field.questionId)) {
            const mappedOptions = field?.question?.cpRenovationAnswers?.map((opt: { id: string, answer: string }) => {
                return {
                    value: opt?.id,
                    label: opt?.answer,
                };
            });

            const defaultSelected = field?.answer_short?.split("||")?.map((val: string) => val?.trim())
            const answerId = isArray(defaultSelected) ? defaultSelected[0] : "119"
            const answerText = defaultSelected?.length > 1 ? defaultSelected[1] : null

            return <div className="pt-4">
                <div className="font-semibold text-secondary text-left text-sm mb-2">{questionTitle}</div>
                <RadioButton
                    name={field.key}
                    options={mappedOptions}
                    defaultValue={answerId}
                    readonly={readOnly}
                    onChangeHandler={(event: any)=> handleOnChange(event, questionTypesMappingReverse[fieldType])}
                />
                {answerText && (
                    <Input
                        full
                        name={field.key}
                        onChangeHandler={(event: any)=> handleOnChange(event, questionTypesMappingReverse[fieldType])}
                        defaultValue={answerText}
                        textArea={fieldType === 'textarea'}
                        readOnly={readOnly}
                    />
                )}
            </div>
        }
    });
};

export const renderFormTabsPDF = (formFieldsData: any, isAnswer: boolean, type: string, payload: any) => {
    return formFieldsData?.map((field:any) => {
        const fieldType = field?.question?.questionType?.type;
        const questionTitle = field?.question?.question;
        let answer = field?.answer_long || field?.answer_short;
        let options = [];
        if (fieldType === 'boolean') {
            options = field?.question?.cpRenovationAnswers?.map((a: { id: any; answer: any; }) => {
                return {
                    value: a?.id,
                    label: a?.answer,
                }
            });
            answer = field?.answer?.answer;
        }

        if (fieldType === 'heading') {
            return <div>
                <h1>{field.title}</h1>
            </div>
        }

        if (fieldType === 'text' || fieldType === 'textarea') {
            return <Row className="pt-4">
                <Col md={24} className="w-full mt-2 px-8 py-4" style={{ borderRadius: 10, background: '#2987520D 0% 0% no-repeat padding-box' }}>
                    <p className="font-semibold text-md pb-4">
                        {questionTitle}
                    </p>
                    <p style={{ fontWeight: 300 }}>
                        {answer}
                    </p>
                </Col>
            </Row>
        }

        if (fieldType === 'dropdown') {
            return <div className="pt-4">
                <DropDown
                    name={field.key}
                    selectedOption=''
                    options={field.options}
                    onChangeHandler={()=> {}}
                />
            </div>
        }


        if (fieldType === 'checkbox' || fieldType === 'boolean') {
                    console.log("questionTitle", questionTitle)
            return <div className="pt-8 pb-2">
                <Row>
                    <Col md={8} className="pt-1">
                        <p className="font-semibold text-secondary text-left text-xs">
                            {questionTitle}
                        </p>
                    </Col>
                    {options && options?.map((a: { label: String | JSX.Element | undefined; id: string; }) => {
                        console.log("a.label === answer", a.label === answer)
                        return (
                            <Col md={2}>
                                <CheckBox content={a.label} name={a.id} defaultStatus={a.label === answer}/>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        }

        if (fieldType === 'picture') {
            return<div className="pt-4">
                <p className="font-semibold text-lg mt-8">Foto's</p>
                <div className="flex">
                    {field.cpOfferRequestRenovationInformationFiles.length > 0 ? <>{field.cpOfferRequestRenovationInformationFiles.map((image: any)=>{
                        let docType = image.file.split('.');
                        docType = docType[docType.length - 1];
                        const iconType = docType == 'pdf'
                            ?  <PdfIcon className='h-24 w-24' title={image.title} />
                            :  <><img src={uploadsUrl(image.file)} alt={image.title} className = "h-[100px] object-fit pr-4" /></>

                        return <>
                            <Col className="py-4 text-center cursor-pointer">
                                {iconType}
                                <p className="pt-1">{`${image?.title}`}</p>
                            </Col>
                        </>
                    })}
                    </>:<div className="p-4 text-center w-full">Geen documenten gevonden.</div>}
                </div>
            </div>
        }

        if (fieldType === 'plan') {
            return<div className="pt-4">
                <p className="font-semibold text-lg mt-8">Plannen</p>
                <div className="flex">
                    {field.cpOfferRequestRenovationInformationFiles.length > 0 ? <>{field.cpOfferRequestRenovationInformationFiles.map((image: any)=>{
                        let docType = image.file.split('.');
                        docType = docType[docType.length - 1];
                        const iconType = docType == 'pdf'
                            ?  <PdfIcon className='h-24 w-24' title={image.title} />
                            :  <><img src={uploadsUrl(image.file)} alt={image.title} className = "h-[100px] object-fit pr-4" /></>

                        return <>
                            <Col className="py-4 text-center cursor-pointer" >
                                {iconType}
                                <p className="pt-1">{`${image?.title}`}</p>
                            </Col>
                        </>
                    })}
                    </>:<div className="p-4 text-center w-full">Geen documenten gevonden.</div>}
                </div>
            </div>
        }

        if (fieldType === 'radio' || (fieldType === 'multiple_D' && payload[field.depKey] === field.depValue)) {
            const mappedOptions = field?.question?.cpRenovationAnswers?.map((opt: { id: string, answer: string }) => {
                return {
                    value: opt?.id,
                    label: opt?.answer,
                };
            });

            const defaultSelected = field?.answer?.id;

            return <div className="pt-4">
                <div className="font-semibold text-secondary text-left text-sm mb-2">{questionTitle}</div>
                <RadioButton
                    name={field.key}
                    options={mappedOptions}
                    defaultValue={defaultSelected}
                    onChangeHandler={()=> {}}
                />
            </div>
        }
    });
};

