import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { PrimaryButton } from "../../shared/buttons/igemo_button";
import { SecondaryButton } from "../../shared/buttons/igemo_button";
import LoginModal from "../../containers/auth/login";

// image imports
import igemo from "../../assets/logo/stronghouse.png"

type HeaderProps = {
    isLoggedIn?: boolean,
    isHouseOwner?: boolean,
    isAdmin?: boolean,
    isSuperadmin?: boolean,
    openPasswordResetModal?: boolean,
    openLoginModal?: boolean,
    setOpenLoginModal?: (value: boolean) => void,
    logo?: string
}

const Header = ({isLoggedIn = false, isHouseOwner = false, isAdmin = false, isSuperadmin = false, openPasswordResetModal = false, openLoginModal = false, setOpenLoginModal = () => {}, logo = ""}: HeaderProps) => {
    const [ loginModalState, setLoginModalState ] = useState({
        state: false,
        type: ""
    });
    // login type determines whether it's house owner or contractor login
    const [ loginType, setLoginType ] = useState<boolean>(true);
    const [ menuDropdownState, setMenuDropdownState ] = useState<boolean>(false)
    const [ logoUrl, setLogoUrl ] = useState<string>("")

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const localLogo = localStorage.getItem("logo");
        if(localLogo) {
            setLogoUrl(localLogo)

            // we run this logic again in case the user switches domain and there is old logo in localStorage
            if (logo) {
                const fullLogo = process.env.REACT_APP_STORAGE_URL +"/" + logo;
                localStorage.setItem("logo", fullLogo);
                setLogoUrl(fullLogo)
            }
        }
        else {
            if (logo) {
                const fullLogo = process.env.REACT_APP_STORAGE_URL +"/" + logo;
                localStorage.setItem("logo", fullLogo);
                setLogoUrl(fullLogo)
            }
        }
    }, [logo])

    const handleHouseownerLoginClick = () => {
        setLoginModalState({
            state: true,
            type: "login"
        });
        setLoginType(true)
    }

    const handleContractorLoginClick = () => {
        setLoginModalState({
            state: true,
            type: "login"
        });
        setLoginType(false)
    }

    const handleModalClose = () => {
        setLoginModalState({
            state: false,
            type: ""
        });
        setOpenLoginModal(false)
    }

    const handleMenuChange = () => {
        setMenuDropdownState(!menuDropdownState)
    }

    const goToHome = () => {
        navigate("/")
        navigate(0)
    }

    return(
        <>
        <div className = "w-[full] bg-white md:h-[90px] box-border py-[16px] md:py-0 flex flex-col justify-center items-center relative z-[10]">
            <div className = "w-full box-border md:px-16 lg:px-16 mac:px-32 flex flex-col md:flex-row justify-between items-center">
                <div className = "flex flex-[0.85] justify-between items-center mb-[10px] md:mb-[0px] relative z-[10]">
                    <img className = "h-[55px] object-fit cursor-pointer hidden md:block" src = {logoUrl || igemo} alt = "igemo logo" onClick={goToHome}/>
                    {!isHouseOwner && !isAdmin && isLoggedIn && (
                        <div className = "md:flex hidden">
                            <p className = {`${location.pathname === '/' ? 'underline text-black' : 'text-grey'} font-Source font-semibold text-p1 text-grey cursor-pointer ml-[15px]`} onClick = {() => navigate("/")}>Offerteaanvragen</p>
                        </div>
                    )}
                    {isHouseOwner && (
                        <div className = "md:flex hidden">
                            <p className = {`font-Source font-semibold text-p1 text-black ${location.pathname === '/simulations' ? 'underline text-black' : 'text-grey'} cursor-pointer`} onClick = {() => navigate("/simulations")}>Simulatie</p>
                            <p className = {`${location.pathname === '/offer-request' ? 'underline text-black' : 'text-grey'} font-Source font-semibold text-p1 text-grey cursor-pointer ml-[15px]`} onClick = {() => navigate("/offer-request")}>Offerteaanvragen</p>
                        </div>
                    )}
                    {isAdmin && (
                        <div className = "md:flex hidden">
                            <NavLink to="/contractors" className={({ isActive})=> `font-Source font-semibold text-p1 cursor-pointer ${isActive? "underline text-black": "text-grey"}` }>
                                Aannemers
                            </NavLink>
                            {/* <p className = {`${location.pathname === '/contractors' ? 'underline text-black' : 'text-grey'} font-Source font-semibold text-p1 cursor-pointer`} onClick = {() => navigate("/contractors")}>Aannemers</p> */}
                            <p className = {`${location.pathname === '/house-owner' ? 'underline text-black' : 'text-grey'} font-Source font-semibold text-p1 cursor-pointer ml-[15px]`} onClick = {() => navigate("/house-owner")}>Gebruikers</p>
                            <p className = {`${location.pathname === '/setup' ? 'underline text-black' : 'text-grey'} font-Source font-semibold text-p1 cursor-pointer ml-[15px]`} onClick = {() => navigate("/setup")}>Setup</p>
                            {isSuperadmin && (
                                <>
                                    <p className = {`${location.pathname === '/superadmins' ? 'underline text-black' : 'text-grey'} font-Source font-semibold text-p1 cursor-pointer ml-[15px]`} onClick = {() => navigate("/superadmins")}>Superadmins</p>
                                    <p className = {`${location.pathname === '/organizations' ? 'underline text-black' : 'text-grey'} font-Source font-semibold text-p1 cursor-pointer ml-[15px]`} onClick = {() => navigate("/organizations")}>Organisaties</p>
                                </>
                            )}
                        </div>
                    )}
                </div>
                {/*<div className = "w-full flex md:hidden justify-between items-center box-border px-[10px]">*/}
                {/*    <img className = "h-[50px] object-fit cursor-pointer" src = {igemo} alt = "igemo logo" onClick={() => navigate("/")}/>*/}
                {/*    <div className = "flex flex-col w-[20px]" onClick = {handleMenuChange}>*/}
                {/*        <div className = "h-[3px] w-full rounded-[1px] bg-[black]" />*/}
                {/*        <div className = "h-[3px] w-full rounded-[1px] bg-[black] my-[5px]" />*/}
                {/*        <div className = "h-[3px] w-full rounded-[1px] bg-[black]" />*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className = "md:flex flex-[1.15] justify-end hidden z-[9] relative">
                    {isLoggedIn ? (
                        <div className = "flex">
                            {isHouseOwner && (
                                <div className = "flex">
                                    <SecondaryButton content = "Nieuwe simulatie" onClick = {goToHome} />
                                    <div className = "ml-[15px]" />
                                </div>
                            )}
                            <PrimaryButton content = "Mijn profiel" onClick={()=> window.location.href = '/my-profile'}/>
                        </div>
                    ) : (
                        <div className = "flex">
                            <SecondaryButton content = "Inloggen als aannemer" onClick = {handleContractorLoginClick} />
                            <div className = "ml-[15px]" />
                            <PrimaryButton content = "Inloggen als huiseigenaar" onClick = {handleHouseownerLoginClick} />
                        </div>
                    )}
                </div>
            </div>
            <LoginModal modalState = {loginModalState} handleModalClose = {handleModalClose} setModalState = {setLoginModalState} showRegisterButton = {loginType} openPasswordResetModal = {openPasswordResetModal} openLoginModal = {openLoginModal} />
        </div>
        <div className = "w-full flex flex-col bg-white box-border px-[10px] border-t-[1px] border-t-[grey]/[0.22] duration-[400ms] overflow-hidden" style = {{maxHeight: menuDropdownState ? "500px" : "0px", opacity: menuDropdownState ? 1 : 0}}>
            {(isHouseOwner || isAdmin) && (
                <div className = "w-full flex flex-col">
                    <div className = "h-[40px] w-full flex justify-center items-center border-b-[1px] border-b-[grey]/[0.22]">
                        <p className = "font-Source font-semibold text-p2Alt text-black" onClick = {() => isHouseOwner ? navigate("/simulations") : navigate("/contractors")}>{isHouseOwner ? "Simulatie" : "Aannemer"}</p>
                    </div>
                    <div className = "h-[40px] w-full flex justify-center items-center border-b-[1px] border-b-[grey]/[0.22]">
                        <p className = "font-Source font-semibold text-p2Alt text-black" onClick = {() => isHouseOwner ? navigate("/offer-request") : navigate("/house-owner")}>{isHouseOwner ? "Offerteaanvragen" : "Gebruikers"}</p>
                    </div>
                </div>
            )}
            {!isLoggedIn ? (
                <div className = "w-full flex flex-col">
                    <div className = "h-[40px] w-full flex justify-center items-center border-b-[1px] border-b-[grey]/[0.22]" onClick = {handleHouseownerLoginClick}>
                        <p className = "font-Source font-semibold text-p2Alt text-black">Inloggen als huiseigenaar</p>
                    </div>
                    <div className = "h-[40px] w-full flex justify-center items-center border-b-[1px] border-b-[grey]/[0.22]" onClick = {handleContractorLoginClick}>
                        <p className = "font-Source font-semibold text-p2Alt text-black">Inloggen als aannemer</p>
                    </div>
                </div>
            ) : (
                <div className = "w-full flex flex-col">
                    {isHouseOwner && (
                        <div className = "h-[40px] w-full flex justify-center items-center border-b-[1px] border-b-[grey]/[0.22]" onClick = {goToHome}>
                            <p className = "font-Source font-semibold text-p2Alt text-black">Nieuwe simulatie</p>
                        </div>
                    )}
                    <div className = "h-[40px] w-full flex justify-center items-center" onClick = {() => navigate("/my-profile")}>
                        <p className = "font-Source font-semibold text-p2Alt text-black">Mijn Profiel</p>
                    </div>
                </div>
            )}
        </div>
        </>
    )
}


export default Header;
