import {FC, useEffect, useState} from "react"
import {ModalBox} from "../../shared/modals/modal";
import {Col, Row} from "antd";
import { GetAPIService } from "../../context/services";
import { City } from "./modal/Province";
import { Tag } from "../../shared/elements/tag";
import useCityStore from "./CityStore";
import { PrimaryButton } from "../../shared/buttons/igemo_button";

interface AddContractorModalStruct {
    RegionID: number,
    province?: any,
    rerender?: boolean,
    lastKey?: number,
    setUpdateCityChecked: any,
    setLastkey: any
}

interface CityInterface {
    title: string;
    active: boolean;
    id: number;
    rId: number;
    cityId: number;
}

const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
];

export const CityModalBox: FC<AddContractorModalStruct> = ({
    RegionID,
    province, rerender,lastKey, setUpdateCityChecked, setLastkey
}) => {

    const [alphabetSelector, setAlphabetSelector] = useState('A');
    const [filteredCities, setFilteredCities] = useState<Array<CityInterface>>([])
    const [citySelector, setCitySelector] = useState<Array<CityInterface>>([])
    const { cityData, setCityData, provinceData, setProvinceData, CityChecked } = useCityStore()

    useEffect(() => {
         console.log('cityData', cityData)
        // console.log('provinceData', provinceData)
        // console.log('CityChecked', CityChecked)
    }, [cityData, setCityData, provinceData, setProvinceData, CityChecked])

    useEffect(() => {
        const fetchData = async () => {
          try {
            const cityResponse = await GetAPIService(`geo/citypregion/${RegionID}`);
            const cities = cityResponse.data.data as [City];

            const selection: Array<CityInterface> = [];
            cities.forEach((value: City) => {
                selection.push({title: value.nl ? `${value.nl} - ${value.zipCode}` : `${value.fr} - ${value.zipCode}`, id: RegionID, active: false, rId: RegionID, cityId: parseInt(value.id)})
            });
            if(cityData?.length > 0 && RegionID) {
                const city = structuredClone(cityData)
                let selectionCopy = structuredClone(selection)
                city.forEach((data) => {
                    const citySelectorMatch = selectionCopy.findIndex(value => value.cityId == data.cityId)
                    if (citySelectorMatch !== -1) {
                        selectionCopy[citySelectorMatch] = {...selectionCopy[citySelectorMatch], active: data.active}
                    }
                })
                setCitySelector(selectionCopy)
                filterBasedOnAlphabet(alphabetSelector, selectionCopy);
            }
            else {
                setCitySelector(selection);
                filterBasedOnAlphabet(alphabetSelector, selection);
            }
          } catch (error) {
            // Handle error if API request fails
            console.error('Error fetching provinces:', error);
          }
        };

        fetchData();
    }, [RegionID]);

    useEffect(() => {
        if(cityData?.length > 0) {
            const cityDataCopy = structuredClone(cityData)
            const provinceTemp = structuredClone(provinceData)
            const stringName: Array<{ name: string, id: number }> = []
            cityDataCopy.forEach(data => {
                if (RegionID === data.id)
                    stringName.push({id: data.cityId, name: data.title})
            })
            provinceTemp[RegionID] = stringName

            setProvinceData(provinceTemp)
        }
    }, [cityData])


    const filterBasedOnAlphabet = (alphabet: string, list: Array<CityInterface> = citySelector) => {
        let updatedList = list;
        updatedList = updatedList.filter((l) => l.title[0].toLowerCase() === alphabet.toLowerCase());
        setFilteredCities(updatedList);
        setAlphabetSelector(alphabet);
    };

    useEffect(()=>{
        const newCities = [...citySelector];
        let cities = cityData;
        // @ts-ignore
        if(lastKey > -1){
            newCities.map((city) => {
                const indexC = cities?.findIndex(x => x.cityId == city.cityId);
                // @ts-ignore
                if(city.rId == province[lastKey].id){
                    // @ts-ignore
                    city.active = province[lastKey].checked;
                    cities[indexC] = city;
                    if (lastKey != null) {
                        city.rId = lastKey
                    }
                    if(indexC > -1){
                        // @ts-ignore
                        cities[indexC] = city;
                    }else{
                        cities.push(city)
                    }
                    //setCityData(cities)
                }
            });
            setLastkey(-1);
            setCityData(cities);
            setCitySelector(newCities);
            filterBasedOnAlphabet('a');
        }
    }, [rerender])

    const setSelectedCity = (city: any) => {
        const newCities = [...filteredCities];
        let cities = [...cityData];
        const active = !city.active;
        const index = newCities.findIndex(x => x.cityId == city.cityId);
        if(index > -1){
            const indexC = cities.findIndex(x => x.cityId == city.cityId);
            if(indexC > -1){
                console.log(!cities[indexC].active)
                cities[indexC].active = active;
            }else{
                cities.push({
                    title: city.title,
                    active: active,
                    id: RegionID,
                    rId: RegionID,
                    cityId: city.cityId
                })
            }
            newCities[index].active = active;
            setCityData(cities)
            setFilteredCities(newCities);
            const newProvinces = province;
            if(citySelector.filter((s: any) => s.rId == RegionID).length === citySelector.filter((s: any) => s.active && s.rId == RegionID).length){
                newProvinces[RegionID].checked = true;
                setUpdateCityChecked(newProvinces)
            }else{
                newProvinces[RegionID].checked = false;
                setUpdateCityChecked(newProvinces)
            }
        }
    }
    return (
        <div className="py-2">
            <div style={{ borderBottom: '1px solid #e1e1e1', padding: '0 30px 15px', margin: '0 -30px 15px'}}>
                {alphabets.map((a) => {
                    return (
                        <span
                            className={`px-2 py-4 cursor-pointer ${alphabetSelector === a ? 'border-b-[3px] primary-border primary-text' : 'text-[#00000040]'} `}
                            onClick={() => filterBasedOnAlphabet(a)}
                        >
                            {a}
                        </span>
                    )
                })}
            </div>

            <div className="grid grid-cols-3">
                <Row className="col-span-7">
                    {filteredCities?.length ? (
                            <>
                                {filteredCities.map((province: CityInterface) => {
                                    return (
                                        <Col className="mr-3 mt-2 flex items-center">
                                            <Tag
                                                text={province.title}
                                                onClick={() => setSelectedCity(province)}
                                                // onClick={() => {
                                                //     setCitySelector((prevState) => {
                                                //         const value = prevState;
                                                //         const index = value.findIndex(data => data.title === province.title)
                                                //         value[index] = {...value[index], active: !value[index].active}
                                                //
                                                //         if (citySelector[citySelector.findIndex(value => value.id === province.id)].active || citySelector.findIndex(data => data.title === province.title) === -1) {
                                                //             let city = structuredClone(cityData)
                                                //             if (city.findIndex(cities => cities.title === province.title) === -1) {
                                                //                 CityChecked[String(RegionID)] = {
                                                //                     id: String(RegionID),
                                                //                     checked: false
                                                //                 }
                                                //                 city.push({
                                                //                     title: province.title,
                                                //                     active: true,
                                                //                     id: RegionID,
                                                //                     cityId: province.id
                                                //                 })
                                                //             }
                                                //             else {
                                                //                 const cityIndex = city.findIndex(cities => cities.cityId === province.id)
                                                //                 city[cityIndex] = {...city[cityIndex], active: true}
                                                //             }
                                                //             setCityData(city)
                                                //         }
                                                //         else {
                                                //             // check if the city is already in the list
                                                //             let city = structuredClone(cityData)
                                                //             const cityIndex = city.findIndex(cities => cities.cityId === province.id)
                                                //             city[cityIndex] = {...city[cityIndex], active: false}
                                                //             setCityData(city)
                                                //         }
                                                //         return value
                                                //     })
                                                // }}
                                                active={province.active}/>
                                        </Col>
                                    )
                                })}
                            </>
                        ) : <div style={{color: 'gray'}}>No Gemeente</div>
                    }
                </Row>
            </div>
        </div>
    )
}
