import {Col, Row} from "antd";
import React, {FC} from "react";

import {epc} from "../../../components/result/epc";
import HouseOne from "../../../assets/picture/hp/house.png";
import HouseFive from "../../../assets/house_types/woning_five.png";
import pigBackground from "../../../assets/icons/pig-background.svg";

import './pdf.css';

interface documentStruct {}

const Files4: FC<documentStruct> = () => {
    return (
        <div className="mt-4">

            <Row>
                <Col md={6} className="pr-2">
                    <Row>
                        <Col md={10}>
                            <img src={HouseOne} alt="House's logo" className = "h-[100px]" />
                        </Col>
                        <Col md={14}>
                            <h1 className="pt-8 text-2xl sm:text-lg md:text-2xl xl:text-2xl  2xl:text-3xl font-semibold border-hasselt">
                                Hoofddak
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24}>
                            <h2 className="text-md pt-2">
                                Een verbetering dringt zich op. Ontdek nu ons adviesvoor het aanpakken vanhoofddak.
                            </h2>
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col md={4}>
                            <div style={{ width: 28, height: 28, borderRadius: 28, background: '#FB7A7C 0% 0% no-repeat padding-box' }} />
                        </Col>
                        <Col md={20}>
                            <h1 className="text-lg font-semibold border-hasselt">
                                Licht geïsoleerd
                            </h1>
                        </Col>
                    </Row>
                </Col>
                <Col md={18} className="text-[#4F4C4C] pl-6" style={{ borderLeft: '1px solid #62B151' }}>
                    <h1 className="text-md sm:text-md md:text-lg xl:text-lg  2xl:text-xl font-semibold border-hasselt">
                        Meer info bij ons advies
                    </h1>
                    <p className="text-md pt-2">
                        Isoleer meteen voldoende, zo zit je goed voor de toekomst. Een goeie dakisolatie heeft een Rwaarde van minimum 4,7 m²k/W, maar liefst 6,3 m²k/W of zelfs 7,9 m²k/W en bestaat uit drie lagen:
                    </p>
                    <p className="text-md pt-4">
                        <li>een winddicht onderdak</li>
                        <li>isolatie</li>
                        <li>een damprem. <strong>Na-isoleren? Misschien goedkoper!</strong></li>
                    </p>

                    <p className="text-md pt-4">
                        De simulatie in Check je huis berekent de prijs voor het plaatsten van nieuwe isolatie waarbij de (eventuele) bestaande isolatie wordt verwijderd. Je hoeft de bestaande isolatie echter niet noodzakelijk te verwijderen. Je kan ook een laag bijplaatsen tot de som van de isolatielagen een voldoende R-waarde heeft.
                    </p>

                    <h1 className="mt-8 text-md sm:text-md md:text-lg xl:text-lg  2xl:text-xl font-semibold border-hasselt">
                        Opgelet
                    </h1>
                    <p className="text-md pt-2">
                        De premiesimulatie in Check je huis is gebaseerd op gemiddelden. Bekijk hier <a href="">meer informatie over de premies</a> .
                    </p>
                </Col>
            </Row>

            <Row className="pt-12">
                <Col md={24} className="w-full px-16 pt-8 pb-16" style={{ borderRadius: 10, background: '#298752 0% 0% no-repeat padding-box', backgroundImage: `url(${pigBackground})`, backgroundPosition: 'right', color: 'white' }}>
                    <Row>
                        <Col md={10} className="py-16 px-16 text-center">
                            <h2 className="text-3xl sm:text-3xl md:text-3xl xl:text-3xl  2xl:text-3xl pt-2 font-semibold">
                                Overzicht van je besparing
                            </h2>
                        </Col>

                        <Col md={14} className="w-full">
                            <Row>
                                <Col md={12} className="w-full text-2xl sm:text-lg md:text-2xl xl:text-2xl  2xl:text-2xl">
                                    <p style={{ whiteSpace: 'nowrap' }} className="pt-2">Ik investeer</p>
                                    <p style={{ whiteSpace: 'nowrap' }} className="pt-2">Ik ontvang premies</p>
                                    <p style={{ whiteSpace: 'nowrap' }} className="pt-2">Ik verbruik minder energie</p>
                                    <p style={{ whiteSpace: 'nowrap' }} className="pt-2 font-semibold">Ik bespaar</p>
                                </Col>
                                <Col md={12} style={{ textAlign: 'right' }} className="w-full text-2xl sm:text-lg md:text-2xl xl:text-2xl  2xl:text-2xl">
                                    <p style={{ whiteSpace: 'nowrap' }} className="pt-2">€ 3922</p>
                                    <p style={{ whiteSpace: 'nowrap' }} className="pt-2">€ 424</p>
                                    <p style={{ whiteSpace: 'nowrap' }} className="pt-2">7147 kWh / jaar</p>
                                    <p style={{ whiteSpace: 'nowrap' }} className="pt-2 font-semibold">€ 1001 / jaar</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mt-16">
                <Col md={24}>
                    <div className="epc-container">
                        <div id="bar" className="text-center text-lg pt-2 pb-4">
                            <h2>
                                <span>
                                    Je huis heeft indicatief gnergielabel D, met deze verbeteringen zou je opschuiven naar label B
                                </span>
                            </h2>
                        </div>
                        <div className="result-img" style={{ display: 'flex', justifyContent: 'center' }}>
                            {epc}
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="mt-6">
                <Col md={24} className="w-full">
                    <h1 className="pt-8 text-2xl sm:text-lg md:text-2xl xl:text-2xl  2xl:text-3xl font-semibold border-hasselt text-center">
                        Ik bespaar €5150 /jaar
                    </h1>
                    <br/>
                    <p className="pt-4 text-[#4F4C4C]">
                        Less meer: <a href="https://apps.energiesparen.be/test-uw-epc" className="underline text-[#298752] cursor-pointer">https://apps.energiesparen.be/test-uw-epc</a>
                    </p>
                </Col>
            </Row>

            <Row className="mt-12" style={{ borderRadius: 10, background: '#F4F9F6 0% 0% no-repeat padding-box' }}>
                <Col md={7} className="w-full px-4 pt-2 pb-4 pl-8">
                    <img src={HouseFive} alt="House's logo" className = "h-[225px]" />
                </Col>
                <Col md={10} className="w-full px-16 pt-8 pb-16">
                    <h1 className="pt-8 text-2xl sm:text-lg md:text-2xl xl:text-2xl  2xl:text-3xl font-semibold border-hasselt text-center">
                        Boek een advies bij de Energiecentrale
                    </h1>
                </Col>
                <Col md={7} className="w-full px-16 pt-8 pb-16 text-lg">
                    <p className="font-semibold pt-4">
                        De Energiecentrale
                    </p>
                    <p className="pt-2">
                        09 266 52 00
                    </p>
                    <p className="pt-2">
                        energiecentrale@stad.gent
                    </p>
                    <p className="pt-2">
                        www.energiecentrale.gent
                    </p>
                </Col>
            </Row>

        </div>
    )
};

export default Files4;

