import HttpService from "../constants/HttpService";

class SimulationService extends HttpService {
    constructor() {
        super('simulation');
    }

    // // get all contractors-admin
    listAddresses = async () => {
        const response = await this.client.get(`${this.endPoint}/list-addresses/`)
        return response
    }


}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SimulationService()
