
import React, { useState, useEffect, useMemo } from "react";

import { getObjValuesSum,getOppervlakteKey,getOppervlakteId } from "../../utils/reultHelpers";
import { getCalculationsBar } from "../../utils/resultActions";
import { epc, epcMobile } from './epc';

import './bar.css'
import {AddEmail} from "../modals/addEmail";
import { round } from "lodash";
import PdfDom from "../../containers/pdfDom";
import Spinner from "../../shared/elements/Spinner";
type BarProps = {
  resultLabelData: any,
  renderUpdate: any,
  initBar: any
 
}

const Bar = ({ resultLabelData, renderUpdate,initBar}: BarProps) => {
  let bespaarValue = JSON.parse(localStorage.getItem('Bespaar')!)
  const [result, setResult] = useState<any>({})
  const [openEmailForm, setOpenEmailForm] = useState(false)
  const [pdfDataUpdate, setPdfDataUpdate] = useState(false);
  const [pdfDataHTML, setPdfDataHTML] = useState("");
  const [localState, setLocalState] = useState();
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false)
  const [newState, setNewState] = useState("");
  const [epcVal, setEpcVal] = useState<string | undefined>(undefined)

  let standardDimensions =  JSON.parse(`${localStorage.getItem('standardDimensions')}`)
  const epcSpans = [
    { className: 'F', minValue: 500,maxValue:10000 },
    { className: 'E', minValue: 400,maxValue:499 },
    { className: 'D', minValue: 300,maxValue:399 },
    { className: 'C', minValue: 200,maxValue:299 },
    { className: 'B', minValue: 100,maxValue:199 },
    { className: 'A', minValue: 0,maxValue:99 },
    { className: 'A+', minValue: -100,maxValue:-10000 },
  ]
  const [actualEpc, setActual] = useState({value:0,label:""})
  const [newEpc, setNew] = useState({value:0,label:""})

  useEffect(() => {
    let localState =  JSON.parse(`${localStorage.getItem('localState')}`)
    let newState =  JSON.parse(`${localStorage.getItem('newState')}`)
    setLocalState(localState)
    setNewState(newState)
    console.log("====>",newState)
    if(localState && newState){
      getCalculationsBar(
        localState,
        newState,
        setResult
      )
    }
  }, [renderUpdate,initBar])
  
  useEffect(() => {
    if(result != undefined && Object.keys(result).length > 0 ){

      const opperKey = getOppervlakteKey(localState,standardDimensions[0])
      const area = standardDimensions[1][opperKey];
      let actualData = round(result.current.energyUseGas + result.current.energyUseElec +  +result.current.energyUseZonnePannelen)
      let newData = round(result?.newMeasures.adjustedScaledGas + result.newMeasures.adjustedScaledElec + result.newMeasures.adjustedScaledZonnePannelen)
      let actualEpcValue = round(actualData / area)
      let newEpcValue = round(newData / area)


      let actualLabel = epcSpans.filter(data => actualEpcValue >= data.minValue  && actualEpcValue <=  data.maxValue )
      let newLabel = epcSpans.filter(data => newEpcValue >= data.minValue  && newEpcValue <=  data.maxValue )
      setActual({value:actualEpcValue,label:actualLabel[0].className})
      setNew({value:newEpcValue,label:newLabel[0].className})
    }
  }, [result])

  function stickToValidPercentage(value: number) {
    if (value < 0) {
      return 0
    }
    if (value > 100) {
      return 100
    }
    return value
  }

  const bespaarVal = useMemo(() => getObjValuesSum(bespaarValue), [
    bespaarValue
  ])

  function getEPCLabels(category: any, value: any, catActual: any, valueActual: any, status: any) {

    if (catActual == '' && valueActual == '') {
      return (
        <>
          {epcSpans.map((span, index) => {
            let epcCal = span.className === category ? value - span.minValue : 0;      
            const right = stickToValidPercentage(epcCal)      
            const className = span.className === category ? 'active col-span-1' : 'inactive col-span-1'
            return (
              <span className={className} key={`${value}-${index.toString()}`}>
                <span style={{ right: `${right}%` }} className="span-value">
                </span>
              </span>
            )
          })}
        </>
      )
    }
    if (status == 'sameLabel') {
      return (
        <>
          {epcSpans.map((span, index) => {
            const right = stickToValidPercentage(span.className === category ? value - span.minValue : 0)
            const right1 = stickToValidPercentage( span.className === catActual ? valueActual - span.minValue : 0)
            const className = span.className === category ? 'active col-span-1' : 'inactive col-span-1'
            const spanClassName = span.className === category ? 'span-value' : 'span-value-update'
            const spanClassName1 = span.className === catActual ? 'span-value-update ' : 'span-value'
            return (
              <span className={className} key={`${value}-${index.toString()}`} >
                {right1 > 0 ? (
                  <>
                    <span
                      style={{ right: `${right1}%` }}
                      className={spanClassName1}
                    />
                    <span
                      style={{ right: `${right}%` }}
                      className={spanClassName}
                    />
                  </>
                ) : (
                  <span
                    style={{ right: `${right}%` }}
                    className={spanClassName}
                  />
                )}
              </span>
            )
          })}
        </>
      )
    }
    return (
      <>
        {epcSpans.map((span, index) => {
          const right = stickToValidPercentage(
            span.className === category
              ? value - span.minValue
              : span.className === catActual
                ? valueActual - span.minValue
                : 0,
          )
          const className =
            span.className === category
              ? 'active col-span-1'
              : span.className === catActual
                ? 'active col-span-1'
                : 'inactive col-span-1'
          const spanClassName =
            span.className === category
              ? 'span-value'
              : span.className === catActual
                ? 'span-value-update'
                : 'span-value'

          return (
            <span className={className} key={`${value}-${index.toString()}`}>
              <span style={{ right: `${right}%` }} className={spanClassName}></span>
            </span>
          )
        })}
      </>
    )
  }
  useEffect(()=>{
},[pdfDataUpdate])
  useEffect(()=>{
  
},[pdfDataHTML,pdfDataUpdate])
const onHandleClick = ()=>{
  
  
  setPdfDataUpdate(true); 
  setIsSendingEmail(true)
  setTimeout(() => {
    // Code to execute after the delay
    let pdfHtml = JSON.stringify(document.getElementById('pdf-dom')?.innerHTML);
    setPdfDataHTML(pdfHtml)
    setOpenEmailForm(true); 
    setIsSendingEmail(false)
    console.log('Delayed code executed!');
  }, 5000);
}
  return (
//@ts-ignore
initBar && Object.keys(resultLabelData).length > 0? (
    <div className="bg-white w-full h-full flex items-center justify-center">
      <div className=" w-full mx-auto flex-col justify-center">
        <div className="flex flex-col w-full justify-center">
          <div className="w-full flex-col flex-col overflow-hidden">
            
              <div className="flex w-full justify-center">
                <div className="heat-loss-wrapper w-full md:w-auto box-border pt-[16px]">
                {/* {actualEpcLabel === heatLossData.label ? ( */}
                  { actualEpc.value !== newEpc.value? (
                      actualEpc.label !== newEpc.label  ? (
                      <h4
                        className="font-Source font-bold text-[14px] md:text-[21px] text-black text-center"
                        dangerouslySetInnerHTML={{
                        __html: `${`${resultLabelData?.epcCard?.epc_img_title} ${actualEpc.label}, ${resultLabelData?.epcCard?.epc_img_title_mid_text}  ${newEpc.label} ${resultLabelData?.epcCard?.epc_img_title_last_text}`}`,
                        }}
                      />
                    ) : (
                      <h4
                        className="font-Source font-bold text-[14px] md:text-[21px] text-black text-center"
                        dangerouslySetInnerHTML={{
                          __html: `${`${resultLabelData?.epcCard?.epc_img_title} ${actualEpc.label}, ${resultLabelData?.epcCard?.epc_img_title_current_text} ${newEpc.label} `} `,
                        }}
                      />
                    )
                  ) : (
                    <h4
                      className="font-Source font-bold text-[14px] md:text-[21px] text-black text-center"
                      dangerouslySetInnerHTML={{
                        __html: `${resultLabelData?.epcCard?.epc_img_title} ${actualEpc.label}`,
                      }}
                    />
                  )}
                  <div className = "w-full overflow-x-hidden">
                    <div className="epc-container w-[1000px] md:w-auto">
                      <div className="epc-label grid grid-cols-7 gap-1">
                        {
                        
                        actualEpc.value !== newEpc.value ?
                    
                        actualEpc.label !== newEpc.label ? 
                          getEPCLabels(
                            newEpc.label,
                            newEpc.value,
                            actualEpc.label,
                            actualEpc.value,
                            '',
                          )
                          : getEPCLabels(
                            newEpc.label,
                            newEpc.value,
                            actualEpc.label,
                            actualEpc.value,
                            'sameLabel',
                          )
                          : getEPCLabels(
                            actualEpc.label,
                            actualEpc.value,
                            '',
                            '',
                            '',
                          )}
                      </div>
                      <div className="result-img">
                        {epc}
                        {/* {epcMobile} */}
                      </div>
                    </div>
                  </div>

                  <hr />
                  {bespaarVal > 0 ? (
                    <strong
                      style={{ float: 'left' }}
                      dangerouslySetInnerHTML={{
                        __html: `${resultLabelData?.epcCard?.epc_left_calculation_text
                          } ${resultLabelData?.epcCard?.epc_calculation_currency
                          } ${Math.round(bespaarVal)} / ${resultLabelData?.epcCard?.epc_left_calculation_unit
                          }`,
                      }}
                    />
                  ) : null}
                  <br style={{ clear: 'both' }} />
                </div>
              </div>
            
            <div className='flex w-full items-center justify-end mb-[25px]'>
              { isSendingEmail ? <Spinner className="w-10 h-10" /> : null }
              <button type='button' disabled={isSendingEmail} onClick={() => {onHandleClick()}} className="md:h-[60px] w-[300px] mt-[3.5] ml-5 flex items-center justify-center bg-white border-4 border-[#494949] transition ease-in-out hover:-translate-y-1 hover:scale-110 text-gray-900 bg-white border-2 border-black hover:bg-black hover:text-white focus:ring-4 focus:ring-igemo-accent font-semibold px-5 py-3 mr-8">
                <p className="font-Source font-bold text-[18px] text-[#494949]">Ontvang je plan via e-mail</p>
              </button>
            </div>
            {pdfDataUpdate === true ? 
      <div id ="pdf-dom" style={{display:"none"}}> 
            {/* //  <div id ="pdf-dom" >  */}
            <PdfDom 
              resultLabelData={resultLabelData} renderUpdate={renderUpdate}
            />
        
            </div>
            :<></>
            }
          </div>
          {/* {epcMobile} */}{/* {epcMobile} */}
          <AddEmail open={openEmailForm} setOpen={setOpenEmailForm} pdfHtml={pdfDataHTML} setPdfDataUpdate={setPdfDataUpdate} setPdfDataHTML= {setPdfDataHTML}/>
        </div>
      </div>
     
    </div>
    ) : null

  )
};
export default Bar;