import HttpService from "../constants/HttpService";

class UserService extends HttpService {
    constructor() {
        super('user/');
    }

    // additional services

    loadAllSimulations = async (id: String) => {
        const response = await this.client.get(`${this.endPoint}simulation/${id}`)
        return response
    }

    loadAllHomeOwners = async (payload: any, others: any) => {
        const { data } = await this.client.post(`${this.endPoint}list/homeowner`, payload, {...others})
        return data
    }

    loadAllHomeOwnersByUserId = async (id: string, payload: any, others: any) => {
        const { data } = await this.client.post(`${this.endPoint}list/select-homeowner/${id}`, payload, others)
        return data
    }

    loadAllArchivedHomeOwnersByUserId = async (id: string, payload: any, others: any) => {
        const { data } = await this.client.post(`${this.endPoint}list/archived-homeowner/${id}`, payload, others)
        return data
    }

    allHomeowner = async () => {
        const { data } = await this.client.get(`${this.endPoint}all-homeowner`)
        return data
    }


}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserService()
