import { FC, useContext } from "react"
import { HouseOwnerListingStatusColors } from "../../models/applicationState"
import { GlobalContext } from "../../context/globalContext/globalContext"
import { formatDate } from "../../utils/helpers"

interface HouseOwnerStatusProps {
    id: String | undefined,
    className?: string,
    statusUpdateAt?: string | null,
}

const OfferReqStatus: FC<HouseOwnerStatusProps> = ({ id, className, statusUpdateAt}) => {
    const { state } = useContext(GlobalContext)
    const offerAllStatus = state?.offerAllStatus?.filter((status: any) => status?.id === id)[0]

    if (offerAllStatus?.status) {
        // @ts-ignore
        const bgColor = HouseOwnerListingStatusColors[offerAllStatus?.status]
        const content = offerAllStatus?.status

        return <td className={className} style={{ background: bgColor, }}>
            <div
                className={`w-full h-full px-9 py-2 text-center text-white flex flex-col`}>
                    <div className="font-semibold">{content}</div>
                    {statusUpdateAt && <div className="text-sm">{formatDate(statusUpdateAt)}</div> }
                </div>
            {/*@ts-ignore*/}
        </td>
    } else {
        return <td></td>
    }
}

export default OfferReqStatus
