import React, {FC, useContext, useState} from 'react'
import {HouseOwnerListingStatus, HouseOwnerListingStatusColors} from '../../models/applicationState'
import { TableButton } from '../buttons/igemo_button'
import ArchiveBtn from './ArchiveBtn'
import { GlobalContext } from '../../context/globalContext/globalContext'
import {UpdateHomeOwnerStatus} from "../../components/modals/updateHomeOwnerStatus";
import OfferRequestService from '../../api/OfferRequest'
import { formatDateTime } from '../../utils/helpers'
import { Space, message } from 'antd'

interface HouseOwnerActionProps {
    item?: any,
    title?: string,
    offerRequestId?: string,
    renovationId?: string,
    setReload: any,
    archive?: boolean
}

const HouseOwnerAction: FC<HouseOwnerActionProps> = ({ item, title,offerRequestId, renovationId,setReload, archive}) => {
    const { state } = useContext(GlobalContext)
    const [showStatusModal, setshowStatusModal] = useState(false);
    const [nextStatus, setNextStatus] = useState(4);
    // @ts-ignore
    const id = item?.statusId
    const offerAllStatus = state?.offerAllStatus?.filter((status: any) => status?.id === id)[0]
    // @ts-ignore
    const bgColor = HouseOwnerListingStatusColors[offerAllStatus?.status]
    const content = offerAllStatus?.status

    const archiveItem = async () => { 
        const payload = {
            renovationId,
            // @ts-ignore
            contractorId: item?.contractor?.id,
            offerRequestId,
            archiveRequestor: (archive !== null && archive !== undefined) ? !archive : true,
        }
        // @ts-ignore
        const response = await OfferRequestService.updateOfferRequestContractor(offerRequestId, payload)
        setReload()

    }

    if (offerAllStatus?.status) {
        return (
            <>
                {offerAllStatus?.status === HouseOwnerListingStatus.offerUploaded && (
                    <div className="flex gap-2">
                        <TableButton
                            content='Offerte aanvaarden'
                            classes='w-full'
                            onClick={()=>{
                                setshowStatusModal(true) 
                                setNextStatus(5)
                            }}
                        />

                        <TableButton
                        content='Offerte Niet toegekend'
                        classes='w-full'
                        onClick={()=>{
                            setshowStatusModal(true)
                            setNextStatus(4)
                        }}
                        />
                    </div>
                
                )}
                {/* <div className="flex justify-center">
                    <ArchiveBtn onClick={archiveItem} content = {archive ? "Archiveren ongedaan maken" : "Archiveren"} />
                </div> */}
                {offerAllStatus?.status === HouseOwnerListingStatus.houseVisitPlanned && (
                    <p className="text-center">{formatDateTime(item?.visitPlanDateTime)}</p>
                )}
                {showStatusModal && <UpdateHomeOwnerStatus setReload={setReload} open={showStatusModal} renovationId={renovationId} offerRequestId={offerRequestId} id={id} nextStatus={nextStatus} item={item} title={title} color={bgColor} setOpen={setshowStatusModal} status={content}/>}
            </>
        )
    } else {
        return <div></div>
    }

}

export default HouseOwnerAction
