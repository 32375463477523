import React, {Dispatch, FC, SetStateAction, useEffect, useState} from "react";
import {ModalBox} from "../../shared/modals/modal";
import {Tag} from "../../shared/elements/tag";
import {GetAPIService} from "../../context/services";
import {uploadsUrl} from "../../shared/Table/common";
import OfferCompare from "./ContractorOfferCompare";
import ContractorOfferList from "./ContractorOfferList";
import OfferService from "../../api/Offers";
import {isEmpty} from "lodash";
import { FaRegStar, FaStar  } from "react-icons/fa";
import ReviewListModal from "../../components/reviewListModal/reviewListModal";


interface contractorInfoModalStruct {
    selectedContractor: null | string | number,
    title: string,
    open: boolean,
    setOpen: Dispatch<SetStateAction<number | string | null>>
}

const defaultModalInfo = {
    companyName: '',
    email: '',
    btwNumber: '',
    contactPerson: '',
    phone: '',
    label: [],
    certificates: [],
    description: '',
    services: []
}

export const ContractorInfoModal: FC<contractorInfoModalStruct> = ({
   selectedContractor,
   open,
   setOpen
}) => {
    const [contractorInfo, setContractorInfo] = useState<any>({...defaultModalInfo});
    const [formStep, setFormStep] = useState('1');
    const [isLoading, setLoading] = useState(false);
    const [servicesList, setServicesList] = useState<any>({});
    const [contractorAPIData, setContractorAPIData] = useState<any>({});
    const [reviewModalState, setReviewModalState] = useState<any>(null); 

    useEffect(() => {
        if (selectedContractor) {
            setContractorInfo({...defaultModalInfo})
            GetAPIService(`contractor/${selectedContractor}`).then((res: any) => {
                const companyData = res?.data
                if (companyData) {
                    setContractorInfo((prevState: any) => {
                        return {
                            ...prevState,
                            avgRating: companyData.avgRating,
                            companyName: companyData.name,
                            email: companyData?.email,
                            btwNumber: companyData.vat,
                            logo: companyData.logo,
                            contactPerson: getContactPerson(companyData?.cpContractorUsers),
                            phone: getPhone(companyData?.cpContractorUsers),
                            label: companyData?.cpContractorLabels.map((label: any)=> {
                                return uploadsUrl(label?.label?.logo)
                            }),
                            certificates: companyData?.cpContractorCertificats?.map((certificate: any)=> {
                                return uploadsUrl(certificate?.certificat?.logo)
                            }),
                            description: companyData.description,
                            ratings: companyData.ratings
                        }
                    })
                    setContractorAPIData(res.data)
                }
            })
        }
    }, [selectedContractor])

    useEffect(() => {
        if (selectedContractor) {
            GetAPIService(`contractor/renovation/${selectedContractor}`).then((res: any) => {
                if (res?.data)
                    setContractorInfo((prevState: any) => {
                        return {
                            ...prevState,
                            services: res?.data?.cpContractorRenovations.map((renovation: any) => renovation.renovation.title)
                        }
                    })
                else {
                    console.log("no data for company")
                }
            }).catch((err: any) => {
                console.log("Err: ", err)
            })

            fetchRenovationServicesDetails();
        }
    }, [selectedContractor])

    const fetchRenovationServicesDetails = async () => {
       if(selectedContractor){
            try {
                setLoading(true)
                const response = await OfferService.getAllServiceRenovationsForContractor(selectedContractor);

                setServicesList(response);
                setLoading(false);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const getContactPerson = (companyUsers: any)=> {
        if (!companyUsers?.length)
            return ''
        return companyUsers[0]?.user?.firstName + ' ' + companyUsers[0]?.user?.lastName
    }

    const getEmail = (companyUsers: any)=> {
        if (!companyUsers?.length)
            return ''
        return companyUsers[0]?.user?.email
    }

    const getPhone = (companyUsers: any)=> {
        if (!companyUsers?.length)
            return ''
        return companyUsers[0]?.user?.phone
    }

    const openReviewModal = () => {
        setReviewModalState({
            contractor: contractorAPIData,
        })
    }

    return (
        <ModalBox title="Gegevens van de aannemer" open={open} cancelHandler={() => setOpen(null)}>
            {
                Object.keys(contractorInfo).length ?
                    <div>
                        <div style={{padding: '0 25px 8px', marginTop: 10}}>
                            <span
                                className={`px-2 py-2 cursor-pointer ${formStep === '1' ? "primary-text primary-border border-b-[3px] border-[transparent]" : "text-[#00000040] border-none"}}`}
                                onClick={() => setFormStep('1')}
                            >
                                Bedrijf
                            </span>

                            <span
                                className={`px-2 py-2 cursor-pointer ml-4 ${formStep === '2' ? "primary-text primary-border border-b-[3px] border-[transparent]" : "text-[#00000040] border-none"}}`}
                                onClick={() => {
                                    setFormStep('2');
                                    fetchRenovationServicesDetails();
                                }}
                            >
                                Diensten & hun Technisch Offerte-onderdelen
                            </span>
                        </div>

                        {(formStep === '1') && (
                            <div className="px-12 pt-12 border-gray-100 border-t-[1px] text-xs font-semibold">
                                <div className="grid grid-cols-12">
                                    <div className="col-span-12 md:col-span-4 lg:col-span-2">
                                        <span className="text-gray-300">
                                            LOGO
                                        </span>
                                        <img
                                            className="w-32"
                                            src={uploadsUrl(contractorInfo?.logo) || "https://img.freepik.com/premium-vector/logo-design-construction-service-architecture_304830-442.jpg?w=2000"}
                                            alt="logo"
                                        />
                                    </div>
                                    <div className="col-span-12 md:col-span-8 lg:col-span-10 text-gray-300">
                                        <div className="grid grid-cols-12 gap-4">
                                            <div className="grid col-span-6 lg:col-span-4">
                                                <span>
                                                    FIRMA
                                                </span>
                                                <span className="mt-2 text-gray-700 font-medium">
                                                    {contractorInfo.companyName}
                                                </span>
                                            </div>
                                            <div className="grid col-span-6 lg:col-span-4">
                                                <span>
                                                    EMAIL
                                                </span>
                                                <span className="mt-2 text-gray-700 font-medium">
                                                    {contractorInfo.email}
                                                </span>
                                            </div>
                                            <div className="grid col-span-6 lg:col-span-4">
                                                <span>
                                                    BTW NUMMER
                                                </span>
                                                <span className="mt-2 text-gray-700 font-medium">
                                                    {contractorInfo.btwNumber}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="mt-10 grid grid-cols-12 gap-4">
                                            <div className="grid col-span-6 lg:col-span-4">
                                        <span>
                                            CONTACTPERSOON
                                        </span>
                                                <span className="mt-2 text-gray-700 font-medium">
                                        {contractorInfo.contactPerson}
                                        </span>
                                            </div>
                                            <div className="grid col-span-6 lg:col-span-4">
                                        <span>
                                            TELEFOON
                                        </span>
                                                <span className="mt-2 text-gray-700 font-medium">
                                            {contractorInfo.phone}
                                        </span>
                                            </div>
                                            <div className="grid col-span-6 lg:col-span-4">
                                        <span>
                                            LABEL
                                        </span>
                                                <span className="inline-flex">
                                        {
                                            contractorInfo.label.map((label: string) => {
                                                return (
                                                    <img
                                                        width="20px"
                                                        height="20px"
                                                        src={label}
                                                        alt="label"
                                                        key={label}
                                                    />
                                                )
                                            })
                                        }
                                        </span>
                                            </div>
                                            <div className="grid col-span-6 lg:col-span-4">
                                        <span className="text-gray-300">
                                            CERTIFICATEN
                                        </span>
                                                <span className="inline-flex">
                                        {
                                            contractorInfo.certificates.map((certificate: string) => {
                                                return (
                                                    <img
                                                        width="20px"
                                                        height="20px"
                                                        src={certificate}
                                                        alt="label"
                                                        key={certificate}
                                                    />
                                                )
                                            })
                                        }
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex md:flex-row flex-col mt-10">
                                    <div className="md:w-[20%]">
                                        <p className="text-gray-300">
                                            BESCHRIJVING
                                        </p>
                                        <p className="mt-2 text-gray-500 font-medium">
                                            {contractorInfo.description}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-gray-300">
                                            DIENSTEN
                                        </p>
                                        <div className="mt-2 flex flex-wrap">
                                            {
                                                contractorInfo.services.map((service: string) => {
                                                    return (
                                                        <div className="mr-3 mb-3 text-gray-500 font-medium">
                                                            <Tag onClick={() => {
                                                            }} active={false} text={service}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <p className="text-gray-300">BEOORDELINGEN</p>
                                    <div className="flex my-3 flex-row gap-1 items-center cursor-pointer" onClick = {openReviewModal}>
                                        <div className="flex items-center gap-[4px]">
                                            <div className="flex relative">
                                                <FaRegStar size="20px" className = "primary-text"/>
                                                <FaStar size="20px" className="absolute top-0 left-0 primary-text"
                                                        style={{display: contractorInfo?.avgRating >= 1 ? "flex" : "none"}}/>
                                            </div>
                                            <div className="flex relative">
                                                <FaRegStar size="20px" className = "primary-text"/>
                                                <FaStar size="20px" className="absolute top-0 left-0 primary-text"
                                                        style={{display: contractorInfo?.avgRating >= 2 ? "flex" : "none"}}/>
                                            </div>
                                            <div className="flex relative">
                                                <FaRegStar size="20px" className = "primary-text"/>
                                                <FaStar size="20px" className="absolute top-0 left-0 primary-text"
                                                        style={{display: contractorInfo?.avgRating >= 3 ? "flex" : "none"}}/>
                                            </div>
                                            <div className="flex relative">
                                                <FaRegStar size="20px" className = "primary-text"/>
                                                <FaStar size="20px" className="absolute top-0 left-0 primary-text"
                                                        style={{display: contractorInfo?.avgRating >= 4 ? "flex" : "none"}}/>
                                            </div>
                                            <div className="flex relative">
                                                <FaRegStar size="20px" className = "primary-text"/>
                                                <FaStar size="20px" className="absolute top-0 left-0 primary-text"
                                                        style={{display: contractorInfo?.avgRating >= 5 ? "flex" : "none"}}/>
                                            </div>
                                        </div>
                                        <p className="font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[2px] mr-[2px]">{contractorInfo?.avgRating}</p>
                                        <p className="font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[2px] mr-[2px]">{contractorInfo?.ratings?.length ?? 0} review(s)</p>
                                    </div>
                                </div>

                                {!isEmpty(servicesList?.servicesGeneralGroup) && (
                                    <div className="mt-4" style={{ marginBottom: 40 }}>
                                        <span className="text-gray-300">
                                            ADMINISTRATIEVE OFFERTE-ONDERDELEN
                                        </span>
                                        <ContractorOfferList
                                            selectedContractor={selectedContractor}
                                            servicesList={servicesList?.servicesGeneralGroup}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {formStep === '2' && (
                            !isEmpty(servicesList?.dataByContractorId) && (
                                <OfferCompare
                                    selectedContractor={selectedContractor}
                                    servicesList={servicesList?.dataByContractorId[0]}
                                />
                            )
                        )}

                        <div className="px-8" style={{ marginTop: -30 }}>
                            <div className="bg-[#FAFAFA] p-3 rounded">
                                <p className="text-[#5F5F5F]">
                                    Deze controlelijst is louter informatief en niet-limitatief. Het kan zijn dan de
                                    werkelijke offerte afwijkt van de controlelijst.
                                    Gelieve de offerte steeds grondig na te lezen over wat inclusief en exclusief is.
                                </p>
                            </div>
                        </div>
                    </div>
                    :
                    <span>
                        Laden...
                    </span>
            }
            {reviewModalState?.contractor && (
                <ReviewListModal closeModal = {() => setReviewModalState(null)} contractorData={reviewModalState} />
            )}
        </ModalBox>
    )
}
