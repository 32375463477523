import { FC, useState } from 'react'
import {
    HeadingsInterface, HouseOwnerListingTypeColors, UserTypes
} from '../../models/applicationState';
import Table from "../../shared/Table/index";
import { Toggler } from '../../shared/hoc/Toggler';
import RequestQuoteRow from './RequestQuoteRow';
import { TableHeaders } from '../../shared/Table/common';
import { formatAddress } from '../../utils/helpers';

interface HomeOwnerListProps {
    tableTitle: String,
    handleMenuClick?: any,
    toggleWarningPopup?: any,
    tableSubTitle?: String,
    data: any,
    initialStatus?: Boolean,
    listingType: HouseOwnerListingTypeColors,
    contractorStatus?: Boolean,
    onContractorStatusChange?: (status: Boolean) => void,
    handleToggleProjectDetails: (cpSimulation: any) => void,
    setReload?: any,
    setSelectedUser?: any,
    archive: boolean
}

const HomeOwnerList: FC<HomeOwnerListProps> = ({
    tableTitle,
    tableSubTitle,
    data,
    initialStatus = false,
    listingType,
    contractorStatus = false,
    onContractorStatusChange,
    handleMenuClick = () => { },
    toggleWarningPopup,
    setReload,
    setSelectedUser,
    archive,
    handleToggleProjectDetails,
}) => {
    const [showDetails, toggleShowDetails] = useState(false);

    const handleDetailsClick = (item: object) => {
        console.log("item::->", item)
        // @ts-ignore
        handleMenuClick(item);
    };

    const openWarningPopup = () => {
        toggleWarningPopup(true);
    };

    const headings: HeadingsInterface = {
        AANNEMERS: 'AANNEMERS',
        DATUM: "DATUM",
        BEGELEIDING: "BEGELEIDING",
        GROEPSAANKOOP: "GROEPSAANKOOP",
        STATUS: "STATUS",
        DOCUMENTEN: "DOCUMENTEN",
        ACTIES: "ACTIES",
    };

    const handleToggleStatus = (cp: any) => {
        toggleShowDetails(!showDetails);
        handleToggleProjectDetails(cp);
    };

    return (
        <Table
            mainBurgerClickHandler={() => setSelectedUser(data)}
            title={tableTitle}
            subtitle={tableSubTitle}
            initialStatus={initialStatus}
            showDetails={showDetails}
            listingType={listingType}
            contractorStatus={contractorStatus}
            onContractorStatusChange={onContractorStatusChange}
            hideEllipsis={false}
            handleToggleStatus={handleToggleStatus}
        >
            <div className='px-8 w-full overflow-visible overflow-x-auto mb-8'>
                <table className='ml-0 h-full' >
                    {data?.cpSimulations.map((cpSimulation: any) => {
                        const street = cpSimulation?.street || {}
                        const city = cpSimulation?.city || {}
                        const address = formatAddress(city, street) || ''
                        const offerRequest = cpSimulation?.cpOfferRequest
                        const cpOfferRequestRenovations = offerRequest?.cpOfferRequestRenovations

                        return (
                            <Toggler
                                initialStatus={true}
                                render={({ toggleStatus, status }) => (
                                    <>
                                        <thead className='mr-4 '>
                                        <tr className='w-full'>
                                            <TableHeaders
                                                title={address}
                                                headings={Object.values(headings)}
                                                showBody={status}
                                                toggleShowBody={toggleStatus}
                                            />
                                        </tr>
                                        </thead>
                                        {status ? (
                                            <tbody className='shadow-md bg-white w-full '>
                                            {cpOfferRequestRenovations.map((cpOfferRequestRenovation: any, idx: number) => {
                                                const cpOfferRequestRenovationContractors = cpOfferRequestRenovation
                                                    ?.cpOfferRequestRenovationContractors
                                                    ?.filter((contractor: any) => contractor?.selected)

                                                return cpOfferRequestRenovationContractors?.map((contractor: any, idx: number) => (
                                                    <RequestQuoteRow
                                                        setReload={setReload}
                                                        key={idx}
                                                        address={address}
                                                        renovationId={cpOfferRequestRenovation.renovationId}
                                                        offerRequestId={cpOfferRequestRenovation.offerRequestId}
                                                        // @ts-ignore
                                                        client={tableTitle}
                                                        toggleWarningPopup={openWarningPopup}
                                                        handleDetailsClick={() => handleDetailsClick(cpOfferRequestRenovation)}
                                                        item={contractor}
                                                        offerRequestSendAt={cpSimulation?.cpOfferRequest?.sendAt}
                                                        renovaiton={cpOfferRequestRenovation?.renovation}
                                                        archive={archive}
                                                        simulationId={cpSimulation?.id}
                                                        renovationKey={cpOfferRequestRenovation?.renovation?.key}
                                                        simulation={cpSimulation}
                                                    />
                                                ))
                                            })}
                                            </tbody>
                                        ) : null}
                                    </>
                                )}
                            />
                        )
                    })}
                </table>
            </div>
        </Table>
    )
}

export default HomeOwnerList
