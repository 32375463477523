import React, { FC } from 'react'
import { ContractorStatusEnum } from '../../models/applicationState'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { Toggler } from '../../shared/hoc/Toggler'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface ContractorStatusProps {
    status: Boolean,
    onChange?: (status: Boolean) => void,
}

const ContractorStatus: FC<ContractorStatusProps> = ({ status: contractorStatus, onChange = () => { } }) => {

    const config = {
        [ContractorStatusEnum.true]: {
            color: "primary",
            content: "ACTIEF",
        },
        [ContractorStatusEnum.false]: {
            color: "home_visit_state",
            content: "INACTIEF",
        },
    }
    // if (!contractorStatus) return <></>
    console.log(contractorStatus)

    return (
        <Toggler
            initialStatus={contractorStatus}
            render={({ status, toggleStatus }) => {
                const s = status ? ContractorStatusEnum.true : ContractorStatusEnum.false
                const color = config[s].color

                return (
                    <div onClick={() => { onChange(status); toggleStatus(); }} className={`relative px-3 py-4 hover:cursor-pointer border-2 flex gap-2 items-center justify-center rounded-md h-8 ${config[s].content === "ACTIEF" ? "primary-border primary-text" : `border-${color} text-${color}`}`}>
                        <div className ={ `w-full h-full absolute top-0 left-0 opacity-[0.1] ${config[s].content === "ACTIEF" ? "primary-main" : `bg-${color}`}`} />
                        <FontAwesomeIcon
                            icon={faCircle as IconProp}
                            className='h-2 relative'
                        />
                        <p className={`font-semibold relative ${config[s].content === "ACTIEF" ? "primary-text" : `text-${color}`}`}>{config[s].content}</p>
                    </div>
                )
            }}
        />
    )
}

export default ContractorStatus
