export const igemoUserFilterOptions = [
    
    // {
    //     label: "Type Gebruiker",
    //     value: "type"
    // },
    {
        label: "Specifieke Aannemer",
        value: "contractor"
    },
    {
        label: "Specifieke Huiseigenaar",
        value: "houseOwner"
    },
    {
        label: "Dienst",
        value: "services"
    },
    {
        label: "Offerte Status",
        value: "status"
    },
    {
        label: "Offerte Adres",
        value: "address"
    },
    {
        label: "Datum van offerte aanvraag",
        value: "offerRequestDate"
    },
]

export const contractorFilterOptions = [
    {
        label: "Huiseigenaar",
        value: "houseOwner"
    },
    {
        label: "Status",
        value: "status"
    },

    {
        label: "Dienst",
        value: "services"
    },
    {
        label: "Datum van offerte aanvraag",
        value: "offerRequestDate"
    },
]

export const booleanOptions = [
    {
        value: "=",
        label: "Is gelijk aan",
    },
    {
        value: "!=",
        label: "Is niet gelijk aan",
    },
]