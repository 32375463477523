import {questionTypesMapping, questionTypesMappingReverse} from "../../containers/servicesList/selectedTypeFields";


export const arrangeQuestionAnswers = (payload: any, questionAnswers: any) => {
    const arrangedQuestions: any = {
        create: {
            upload: [],
            general: []
        },
        update: {
            upload: [],
            general: []
        },
        delete: {
            upload: []
        }
    }
    for (const answeredQuestionId of Object.keys(payload)) {
        const questionInPayload = payload[answeredQuestionId]
        if (questionAnswers.hasOwnProperty(answeredQuestionId)) {
            if (questionInPayload.type === '5') {
                if (questionInPayload?.fileId && questionInPayload?.deleted) {
                    arrangedQuestions.delete.upload.push(formatUploadFileDeleted(answeredQuestionId,
                        questionInPayload?.fileId))
                }
                else {
                    arrangedQuestions.update.upload.push(formatUploadQuestion(answeredQuestionId, questionInPayload))
                }
            } else {
                arrangedQuestions.update.general.push(formatGeneralQuestion(answeredQuestionId, questionInPayload))
            }
        } else {
            if (questionInPayload.type === '5') {
                arrangedQuestions.create.upload.push(formatUploadQuestion(answeredQuestionId, questionInPayload))
            } else {
                arrangedQuestions.create.general.push(formatGeneralQuestion(answeredQuestionId, questionInPayload))
            }
        }
    }
    return arrangedQuestions
}

export const formatGeneralQuestion = (questionId: string, valueObj: { type: string; val: string }) => {
    const formattedQuestion: any = {
        "questionId": questionId,
        "answerId": null,
        "answer_short": null,
        "answer_long": null
    }
    if (valueObj.type === questionTypesMappingReverse.text) {
        formattedQuestion.answer_short = valueObj.val
    } else if (valueObj.type === questionTypesMappingReverse.textarea) {
        formattedQuestion.answer_long = valueObj.val
    } else if ([questionTypesMappingReverse.radio, questionTypesMappingReverse.boolean].includes(valueObj.type)) {
        formattedQuestion.answerId = valueObj.val
    } else if (questionTypesMappingReverse.checkbox === valueObj.type) {
        formattedQuestion.answer_short = valueObj.val
    }
    return formattedQuestion
}

export const formatUploadQuestion = (questionId: string, questionInPayload: { plan_description: string;
    picture_description: string; updates: any, value: Array<{ originFileObj: any; name: string, desc: string }> }) => {
    const formData = new FormData()
    if(questionInPayload?.value?.length > 0){
        for (const file of questionInPayload.value) {
            formData.append('file', file.originFileObj)
            formData.append('description', file.desc);
        }
    }
    if(questionInPayload?.updates?.length > 0){
        for (const file of questionInPayload.updates) {
            // @ts-ignore
            formData.append('updates', JSON.stringify(file))
        }
    }
    formData.append('questionId', questionId)
    return formData
}

export const formatUploadFileDeleted = (questionId: string, fileId: string) => {
    return {
        questionId,
        fileId
    }
}

export const formatQuestionsSpecific = (renovationsList: any, questionAnswers: any) => {
    const formattedQuestionsPerCategory: any = {}
    for (const renovationInfo of renovationsList) {
        for (const question of renovationInfo.cpRenovationQuestions) {
            if (question?.questionKindId === "2") {
                const questionObj: any = {
                    key: question.id,
                    title: question?.question,
                    placeholder: '',
                    type: questionTypesMapping[question.questionTypeId],
                    questionId: question.id,
                    answered: questionAnswers.hasOwnProperty(question.id),
                    required:question.required
                }
                if (question.cpRenovationAnswers) {
                    questionObj['options'] = question.cpRenovationAnswers.map((option: any) => {
                        return {
                            label: option.answer,
                            value: option.id
                        }
                    })
                }
                if (formattedQuestionsPerCategory.hasOwnProperty(renovationInfo.title))
                    formattedQuestionsPerCategory[renovationInfo.title].push(questionObj)
                else
                    formattedQuestionsPerCategory[renovationInfo.title] = [questionObj]
            }
        }
    }
    return formattedQuestionsPerCategory
}

export const formatQuestionsGeneric = (questionsList: any, questionAnswers: any) => {
    console.log('question list is ', questionsList)
    const formattedQuestionsPerCategory: any = {}
    for (const question of questionsList) {
        if (question?.renovation) {
            const questionObj: any = {
                key: question.id,
                title: question?.question,
                placeholder: '',
                type: questionTypesMapping[question.questionTypeId],
                questionId: question.id,
                answered: questionAnswers.hasOwnProperty(question.id),
                required:question.required
            }
            if (question.cpRenovationAnswers) {
                questionObj['options'] = question.cpRenovationAnswers.map((option: any) => {
                    return {
                        label: option.answer,
                        value: option.id
                    }
                })
            }
            if (formattedQuestionsPerCategory.hasOwnProperty(question?.renovation?.title))
                formattedQuestionsPerCategory[question?.renovation?.title].push(questionObj)
            else
                formattedQuestionsPerCategory[question?.renovation?.title] = [questionObj]
        }
    }
    // for (const renovationInfo of renovationsList) {
    //     for (const question of questionsList) {
    //         const questionObj: any = {
    //             key: question.id,
    //             title: question?.question,
    //             placeholder: '',
    //             type: questionTypesMapping[question.questionTypeId],
    //             questionId: question.id,
    //             answered: questionAnswers.hasOwnProperty(question.id)
    //         }
    //         if (question.cpRenovationAnswers) {
    //             questionObj['options'] = question.cpRenovationAnswers.map((option: any) => {
    //                 return {
    //                     label: option.answer,
    //                     value: option.id
    //                 }
    //             })
    //         }
    //         if (formattedQuestionsPerCategory.hasOwnProperty(renovationInfo.title))
    //             formattedQuestionsPerCategory[renovationInfo.title].push(questionObj)
    //         else
    //             formattedQuestionsPerCategory[renovationInfo.title] = [questionObj]
    //     }
    // }
    return formattedQuestionsPerCategory
}
