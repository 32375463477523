import { useMemo } from "react";
import { DeleteFilled, CloseOutlined } from "@ant-design/icons";
import OutsideClickHandler from "react-outside-click-handler";
import { Select } from "antd";
//
import { PlusOutlined } from "@ant-design/icons";
import StatusOption from "./status";
import TypeOption from "./type";
import { contractorFilterOptions, igemoUserFilterOptions } from "./config";
import AddressOption from "./address";
import ServicesOption from "./services";
import ContractorsOption from "./contractor";
import HouseOwnerOptions from "./houseOwner";
import OfferRequestDateOption from "./offerRequestDate";

const Filter = ({
    setShowModal,
    showModal,
    selectedFiltersData,
    setSelectedFiltersData,
    user = "admin",

}: any) => {

    // delete record
    const removeSelectedOption = (index: any) => {
        const remainingRecod = selectedFiltersData?.filter(
            (item: any, key: any) => key !== index
        );
        setSelectedFiltersData(remainingRecod);
    };

    //  update option 1 value
    const updateOption1Value = (value: any, index: any, option: any) => {
        let selectedIndexRecord = [...selectedFiltersData];
        selectedIndexRecord[index][option] = value;
        setSelectedFiltersData([...selectedIndexRecord]);
    };

    const selectedFiltersArray = useMemo(() => {
        const arr: any[] = []
        selectedFiltersData?.forEach((filter: any) => {
            arr.push(filter.option1)
        })
        return arr
    }, [selectedFiltersData])

    const availableOptions = useMemo(() => (
        (user === "admin" ? igemoUserFilterOptions : contractorFilterOptions)
            .filter((filter: any) => !selectedFiltersArray.includes(filter.value))
    ), [selectedFiltersArray])

    const OPTION_RENDERS: any = {
        "type": {
            render: (item: any, index: any) => (
                <TypeOption
                    item={item}
                    index={index}
                    removeSelectedOption={removeSelectedOption}
                    //   this method is for to change first selected option
                    updateOption1Value={updateOption1Value}
                    availableOptions={availableOptions}
                />
            )
        },
        "status": {
            render: (item: any, index: any) => (
                <StatusOption
                    item={item}
                    index={index}
                    removeSelectedOption={removeSelectedOption}
                    //   this method is for to change first selected option
                    updateOption1Value={updateOption1Value}
                    availableOptions={availableOptions}
                />
            )
        },
        "services": {
            render: (item: any, index: any) => (
                <ServicesOption
                    item={item}
                    index={index}
                    removeSelectedOption={removeSelectedOption}
                    //   this method is for to change first selected option
                    updateOption1Value={updateOption1Value}
                    availableOptions={availableOptions}
                />
            )
        },
        "address": {
            render: (item: any, index: any) => (
                <AddressOption
                    item={item}
                    index={index}
                    removeSelectedOption={removeSelectedOption}
                    //   this method is for to change first selected option
                    updateOption1Value={updateOption1Value}
                    availableOptions={availableOptions}
                />
            )
        },
        "contractor": {
            render: (item: any, index: any) => (
                <ContractorsOption
                    item={item}
                    index={index}
                    removeSelectedOption={removeSelectedOption}
                    //   this method is for to change first selected option
                    updateOption1Value={updateOption1Value}
                    availableOptions={availableOptions}
                />
            )
        },
        "houseOwner": {
            render: (item: any, index: any) => (
                <HouseOwnerOptions
                    item={item}
                    index={index}
                    removeSelectedOption={removeSelectedOption}
                    //   this method is for to change first selected option
                    updateOption1Value={updateOption1Value}
                    availableOptions={availableOptions}
                />
            )
        },
        "offerRequestDate": {
            render: (item: any, index: any) => (
                <OfferRequestDateOption
                    item={item}
                    index={index}
                    removeSelectedOption={removeSelectedOption}
                    //   this method is for to change first selected option
                    updateOption1Value={updateOption1Value}
                    availableOptions={availableOptions}
                />
            )
        },
    }

    //  handler to return components on basis of selected options
    const selectedOptionsHandler = (item: any, index: any) => {
        return ["", null, undefined].includes(item?.option1) ? null : (OPTION_RENDERS[item?.option1]?.render(item, index) || "")
    };

    return (
        <>
            {/* filter dropdown */}
            <div className="relative">
                <OutsideClickHandler
                    onOutsideClick={() => {
                        // setShowModal(!showModal);
                    }}
                >
                    <div className="w-full md:w-2/3 absolute bg-white shadow-xl rounded-lg mt-2 pt-2 pb-4 px-2 right-0 z-20">
                        <div className="flex justify-end pt-1">
                            <CloseOutlined
                                className="cursor-pointer text-[gray] text-[15px]"
                                onClick={() => setShowModal(!showModal)}
                            />
                        </div>
                        <div className="flex justify-start">
                            <h2 className="px-2 font-[600] text-[20px]">Filter</h2>
                        </div>
                        {/* row 1 */}
                        {selectedFiltersData?.length > 0 &&
                            selectedFiltersData?.map((item: any, index: any) => (
                                <>
                                    {/* already selected options */}
                                    {item?.option1 !== "" ? (
                                        selectedOptionsHandler(item, index)
                                    ) : (
                                        <>
                                            <div className="flex pb-2 w-full pt-2">
                                                <div className="px-1 flex items-center w-[40%]">
                                                    <div className="w-20">
                                                        {index === 0 ? (
                                                            <h2 className="text-[14px] opacity-[0.8]">
                                                                
                                                            </h2>
                                                        ) : (
                                                            <h2 className="w-fit py-1 px-3 text-[14px] opacity-[0.8] border rounded text-center ">
                                                                EN
                                                            </h2>
                                                        )}
                                                    </div>
                                                    <div className=" w-full">
                                                        <Select
                                                            showSearch
                                                            placeholder="Selecteer Filter"
                                                            optionFilterProp="children"
                                                            className="w-full"
                                                            size="middle"
                                                            onChange={(value) => {
                                                                updateOption1Value(value, index, "option1");
                                                            }}
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            options={availableOptions}
                                                        // options={igemoUserFilterOptions}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="pl-1 flex items-center justify-end w-[60%]">
                                                    <DeleteFilled
                                                        className="text-[20px] cursor-pointer text-[gray]"
                                                        onClick={() => removeSelectedOption(index)}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            ))}
                        {/* add filter button */}
                        <div className="pt-3">
                            <button
                                onClick={() => {
                                    //   setSelectedOption("newOption");
                                    setSelectedFiltersData([
                                        ...selectedFiltersData,
                                        {
                                            option1: "",
                                            option2: "=",
                                            option3: "",
                                        },
                                    ]);
                                }}
                                className="flex items-center px-2 border-0 shadow-none text-[14px]"
                            >
                                <PlusOutlined className="" />
                                <span className="px-1">Filter toevoegen</span>
                            </button>
                        </div>
                    </div >
                </OutsideClickHandler>
            </div>
        </>
    );
};

export default Filter;
