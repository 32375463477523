import { useState, useEffect } from 'react';

type themeType = {
  backgroundColor: string,
  titleColor: string,
  textColor: string,
  primaryColor: string,
  secondaryColor: string
}

const useTheme = () => {
  const [theme, setTheme] = useState<themeType>(() => {
    const storedTheme = localStorage.getItem('theme');
    return storedTheme ? JSON.parse(storedTheme) : {};
  });

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(JSON.parse(storedTheme));
    }
  }, []);
  return theme as themeType;
};

export default useTheme;