import React, { useState, useEffect } from "react";
import TitleBar from "../titleBar";
import { useNavigate } from "react-router-dom";
import SubmitBox from "../submitBox";
import InputSelect from "../inputSelect";
import Box from "../box";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal"; 
// image imports here
import moist from "../../../assets/extra/huis-moist.png"

type VochtProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    }
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const Vocht = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: VochtProps) => {
    const [ boxHover, setBoxHover ] = useState(0);
    const [ activeBox, setActiveBox ] = useState("");
    const [ inputValue, setInputValue ] = useState("0");
    const [ hideInput, sethideInput ] = useState(true);
    const [ modalState, setModalState ] = useState(false);

    const localStateJaar = getLocalStateValue("2");

    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("34");
        const localStateJaar = getLocalStateValue("2");

        if(localState) {
            if(localState?.vocht_input) {
                setActiveBox(localState.answerNo);
                setInputValue(localState?.vocht_input);
                sethideInput(false)
            }
            else {
                setActiveBox(localState.answerNo);
            }
        }
    }, [])

    const goBack = () => {
        if(localStateJaar.answerNo == 4){
            navigate("/vraag/energieverbruiken-productie")
        }else{
            navigate(pageData?.prev_url || "")
        }    }


    const onClick = (answer?: string, answerIndex?: string) => {
        const question = "vocht";

        if(Number(answerIndex )== 0){
            setActiveBox("0");
            sethideInput(false)
        }else{
            sethideInput(true)
            updateAnswer(question || "", answer || "", pageData?.next_url);
            storeAnswer({"answerNo": answerIndex, "vocht": answer,"vocht_input": "",image:moist}, "34")
        }

    }

    const onInputSubmit = () => {
        const question = "vocht_input";
        updateAnswer(question || "", inputValue || "", pageData?.next_url);
        const valuesPair = "Ja";
        storeAnswer({"answerNo": "0", "vocht": valuesPair, "vocht_input": inputValue,image:moist}, "34");
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {localStateJaar.answerNo == 4 ? "Energieverbruik en -productie" : pageData?.back_text}  infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "w-full flex flex-col mt-[10px] mb-[17px]">
                    <div className = "w-full flex sm:flex-row flex-col border-b-[1px] border-b-[#F0F5F5]">
                        <Box flex = {1} hasRightBorder text = "Ja" hoverValue = {1} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} image = "" answerIndex = "0" isActive = {activeBox === "0"} />
                        <Box flex = {1} text = "Neen" hoverValue = {2} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} image = "" answerIndex = "1" isActive = {activeBox === "1"} />
                        <Box flex = {1} text = "Ik weet het niet" hoverValue = {3} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} image = "" answerIndex = "2" isActive = {activeBox === "2"} />
                    </div>
                </div>
                {
                    !hideInput &&
                    <SubmitBox isButtonActive = {inputValue?.length > 0} onSubmit = {onInputSubmit}>
                    <InputSelect option={["Dak","Badkamer","Leefruimte","Kelder","Anders"]} value = {inputValue} setValue = {setInputValue} title = "Waar zijn de vochtproblemen in de woning?" description = "" marginLeft = {window.screen.width < 1000 ? "0px" : "138px"} />
                    </SubmitBox>
                }
            
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default Vocht;
