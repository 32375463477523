import React, {Dispatch, FC, SetStateAction, useEffect, useState} from "react";
import {ModalBox} from "../../shared/modals/modal";
import {Input} from "../../shared/forms/input";
import {PrimaryButton} from "../../shared/buttons/igemo_button";
import {PostAPIService} from "../../context/services";
import {DropDown} from "../../shared/dropdown";
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { message, Upload } from 'antd';
import { maxFileSize } from "../../constants/general";

const { Dragger } = Upload;

interface UploadOfferte {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>,
    item: object,
    address?: string,
    client?: string,
    title?: string,
    setReload?: any,
    setShowStatusModal?:any
}

export const UploadOfferte: FC<UploadOfferte> = ({
                                           open,
                                           setOpen, item, client, address, title, setReload,setShowStatusModal
                                       }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<UploadFile<any>[]>([]);
    const [payload, setPayload] = useState({
        type_id: '',
        description: ''
    });
    const [erros, setErrors] = useState({
        file: ''
    });
    const props: UploadProps = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        fileList: file,
        customRequest: ({onSuccess}) => {
            return onSuccess ? onSuccess("Ok") : onSuccess
        },
        onChange(info) {
            if(info.file.size && info.file.size >= maxFileSize) {
                message.error(`De maximale bestandsgrootte is ${maxFileSize /1000_000} MB`, 3);
            } else {
                setFile(info.fileList)
            }
        }
    };
    const handelInput = (event: any) => {
        const {name, value} = event.target;
        setPayload((st: any) => ({
            ...st,
            [name]: value
        }));
    }
    const validateForm = (doc: string, file: any[]) => {
    let fileError = '';
        let valid = true;

        if(file.length == 0){
            valid = false;
            fileError = 'Please select file';
        }
        setErrors({
            file: fileError
        });
        return valid
    }
    const handleSubmit = () => {
        if(validateForm("4", file)){
            setError('');
            setLoading(true);
            let formData = new FormData();
            formData.append('typeId',"4");
            formData.append('description', payload.description);
            // @ts-ignore
            formData.append('file', file[0]?.originFileObj);
            // @ts-ignore
            formData.append('title', file[0]?.originFileObj.name);
            // @ts-ignore
            formData.append('offerRequestRenovationContractorId', item?.id);
            PostAPIService(`offer/request/document/upload`, formData)
                .then((response) => {
                    if(response.data.success == true){
                        messageApi.open({
                            type: 'success',
                            content: 'Document is succesvol geüpload.',
                            duration: 3,
                        }).then(()=>{
                            setOpen(false);
                            setLoading(false);
                            setShowStatusModal(true)
                        });
                    }else{
                        setLoading(false);
                        messageApi.open({
                            type: 'error',
                            content: 'Fout bij het uploaden van het document. Probeer het later opnieuw.',
                            duration: 3,
                        });
                    }
                })
        }
    }
    return (
        <>
        <ModalBox
            title="Offerte opladen"
            width={'auto'}
            cancelHandler={() => setOpen(false)}
            extraClasses="xl:w-[60%] w-full text-center"
            open={open}>
            <div className="border-grey-300 border-t-[1px] flex justify-center">
                <p className="p-4 lg:w-[70%] w-full text-center text-gray-500">
                    Hieronder kan u een document met bij horende informatie opladen & opslaan voor de klant {client} met dienst {title} voor {address}.
                </p>
            </div>
            <div className="border-grey-300 border-b-[1px]">
                <div className="px-8 py-12 flex justify-between sm:flex-row flex-col m-5">
                    <div className="md:w-[45%] w-full grid-cols-4 lg:grid-cols-8 gap-4">
                            <Input
                                classes="mt-3 lg:mt-5"
                                height="sm:h-[180px] h-[50px]"
                                name="description"
                                label="Document beschrijving"
                                onChangeHandler={(event) => handelInput(event)}
                                active={true} textArea={true}
                                full={true}
                                placeholder="Schrijf hier kort waarover het document gaat"
                                labelExtraClasses="text-sm font-semibold"
                            />
                    </div>
                    <div className="md:w-[50%] w-full grid-cols-4 lg:grid-cols-8 gap-4 fill-primary">
                        <Dragger {...props}>
                            <div className="flex justify-center items-center w-full">
                                <p className="flex justify-center items-center w-[135px] h-[135px] mb-10 mt-10 primary-main border-radius-icon">
                                    <UploadOutlined className="text-white text-[56px]" />
                                </p>
                            </div>
                            <p className="ant-upload-text"></p>
                            <p className="ant-upload-hint text-primary">
                                <span className="underline primary-text">
                                    klik om een bestand te importeren of versleep uw document
                                </span>
                            </p>
                        </Dragger>
                        <span className="text-error text-xs">{erros.file}</span>
                    </div>
                </div>
            </div>
            <div className="px-8">
                <div className="flex justify-end mt-4">
                    <PrimaryButton disabled={loading} content="Opslaan" onClick={handleSubmit}/>
                </div>
            </div>
        </ModalBox>
            {contextHolder}
        </>
    )
}
