import React, {FC, useContext, useState} from "react";

import {ContractorProp, HouseOwnerListingStatusColors} from "../../models/applicationState";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import {GlobalContext} from "../../context/globalContext/globalContext";
import {uploadsUrl} from "../../shared/Table/common";
import {ContractorInfoModal} from "../contractor/contractor_info_modal";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import OfferComparison from "./OfferComparison";

interface documentStruct {
    contractors: [],
    contList: [],
    offerReqId: any,
    renovationId: any,
}

const DocumentsList: FC<documentStruct> = ({
   contractors,
   contList,
   offerReqId,
   renovationId,
}) => {
    const { state } = useContext(GlobalContext);
    const [selectedContractor, setSelectedContractor] = useState<null | string>(null)
    console.log("contractors",contractors)
    const auth = JSON.parse(localStorage.getItem("auth") || "{}");

    return (
        <>
            {contractors ? (
                <>
                    <table className="w-full overflow-auto block">
                        <tbody className='bg-white w-full inline-table'>
                        {contractors?.map((con:ContractorProp, idx: number) => {
                            console.log("contractors",contractors)
                            const offerAllStatus = state?.offerAllStatus?.filter((status: any) => status?.id === con.statusId)[0];
                            // @ts-ignore
                            const bgColor = HouseOwnerListingStatusColors[offerAllStatus?.status];
                            // @ts-ignore
                            const conData = contList.find(f => f.id === con.contractor.id);


                            return (
                                <>
                                    <tr>
                                        <th ></th>
                                        <th colSpan={8} style={{ position: 'relative' }} className="pt-[40px]">
                                            <div style={{ position: 'absolute', top: 13, right: 0, backgroundColor: bgColor}} className={`px-3 py-1 items-center flex flex-row gap-2 rounded-tl-md rounded-tr-md w-min justify-center align-middle`}>
                                                <p className='text-white font-semibold whitespace-nowrap text-sm'>{offerAllStatus?.status}</p>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr key={idx} className='border'>
                                        <td className='px-5 py-2 text-center items-center'>
                                            <img src={uploadsUrl(con?.contractor?.logo)} alt="House's logo" width={80} height={80}/>
                                        </td>
                                        <td className='px-5 py-2 text-center items-center font-semibold'>
                                            {con?.contractor?.name}
                                        </td>
                                        <td className='px-5 py-2 text-center items-center'>
                                            {`${con?.contractor?.cpContractorUsers && con?.contractor?.cpContractorUsers[0] ? con?.contractor?.cpContractorUsers[0]?.user?.firstName : ''} ${con?.contractor?.cpContractorUsers[0] ? con?.contractor?.cpContractorUsers[0]?.user?.lastName : ''} `}
                                        </td>
                                        <td className='px-5 py-2 text-center items-center'>
                                            {`${con?.contractor?.cpContractorUsers && con?.contractor?.cpContractorUsers[0] ? con?.contractor?.cpContractorUsers[0]?.user?.email : ''}`}
                                        </td>
                                        <td className='px-5 py-2 text-center items-center'>
                                            {`${con?.contractor?.cpContractorUsers && con?.contractor?.cpContractorUsers[0] ? con?.contractor?.cpContractorUsers[0]?.user?.phone : ''}`}
                                        </td>
                                        <td className='px-5 py-2 text-center items-center'>
                                            <div className = "">
                                                {                            // @ts-ignore
                                                    conData?.newItem?.label?.map((label: string)=> {
                                                        return (
                                                            <img
                                                                width={30}
                                                                height={30}
                                                                style={{ minWidth: 30 }}
                                                                src={label}
                                                                alt="label"
                                                                key={label}
                                                            />
                                                        )
                                                    })}
                                            </div>
                                        </td>
                                        <td className='px-5 py-2 text-center items-center'>
                                            <div className = "">
                                                {                            // @ts-ignore
                                                    conData?.newItem?.certificates?.map((certificate: string)=> {
                                                        return (
                                                            <img
                                                                width={30}
                                                                height={30}
                                                                style={{ minWidth: 30 }}
                                                                src={certificate}
                                                                alt="label"
                                                                key={certificate}
                                                            />
                                                        )
                                                    })}
                                            </div>
                                        </td>
                                        <td className='px-5 py-2 text-center items-center'>
                                            <div className='flex gap-2 align-middle'>
                                                <FontAwesomeIcon
                                                    onClick={() => {setSelectedContractor(con.contractor.id)}}
                                                    icon={faEllipsisVertical as IconProp}
                                                    className="w-5 h-5 ml-4 mr-auto mt-1 -m cursor-pointer"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            );
                        })}
                        </tbody>
                    </table>

                    {auth.typeId === 1 && (
                        <div className="pt-[20px] text-left">
                            <OfferComparison
                                renovationId={renovationId}
                                contractors={contractors}
                                offerReqId={offerReqId}
                                contList={contList}
                            />
                        </div>
                    )}
                </>
            ) : (
                <div className="text-center p-4">No contractors found.</div>
            )}
            <ContractorInfoModal
                title="Gegevens van dev aannemer"
                open={selectedContractor !== null}
                setOpen={()=> setSelectedContractor(null)}
                selectedContractor={selectedContractor}
            />

        </>
    )
};

export default DocumentsList;

