import React, {FC} from 'react';
import {Modal} from 'antd';


interface ModalStruct {
    title?: string,
    open: boolean,
    width?: any,
    cancelHandler?: (event: React.MouseEvent<HTMLElement>) => void,
    submitHandler?: (event: React.MouseEvent<HTMLElement>) => void,
    extraClasses?: string,
    children: any
}

export const ModalBox: FC<ModalStruct> = ({
                                              title = "",
                                              open = false,
                                              cancelHandler = null,
                                              submitHandler = null,
                                                width = 80,
                                                extraClasses="",
                                              children
                                          }) => {

    const handleOk = (e: React.MouseEvent<HTMLElement>) => {
        if (submitHandler) {
            submitHandler(e)
        }
    };

    const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
        if (cancelHandler)
            cancelHandler(e)
    };

    return (
        <Modal
            width={`${width}%`}
            title={title}
            open={open}
            className={`no-modal-buttons ${extraClasses ? extraClasses : ''}`}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            {children}
        </Modal>
    );
};
