import { useEffect, useState } from "react";
import axios from "axios";

// asset imports here
import { IoMdInformationCircleOutline } from "react-icons/io"
import { IoCloseCircleOutline } from "react-icons/io5"
import { FaRegStar, FaStar  } from "react-icons/fa";
import { GoReply } from "react-icons/go";

type reviewListModalProps = {
    closeModal?: () => void,
    contractorData?: any
}

type ReplyType = {
    comment: string;
    createdAt: string;
    id: string;
    user: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
    };
};

type ReplyBoxProps = {
    reply: ReplyType
}

const parseSQLDate = (date: string) => {
    // parse into day month year format
    const parsedDate = new Date(date);
    const day = parsedDate.getDate();
    const month = parsedDate.getMonth();
    const year = parsedDate.getFullYear();
    return `${day} ${getLiteralMonth(month)} ${year}`;
}

const getLiteralMonth = (month: number) => {
    const months = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];
    return months[month];
}

const ReplyBox = ({ reply }: ReplyBoxProps) => {
    const [isFieldEditable, setIsFieldEditable] = useState(false);
    const [replyState, setReplyState] = useState(reply.comment);

    const cancelUpdate = () => {
        setIsFieldEditable(false);
        setReplyState(reply.comment);
    }

    return(
        <div className = "w-full flex flex-col bg-[#FAFAFA] rounded-[4px] box-border py-[12px] mt-[8px]">
            <div className = "flex w-full justify-between items-center">
                <div className = "flex items-center">
                    <p className = "font-jakarta font-bold text-[16px] text-[#4F4C4C]">{reply?.user?.firstName} {reply?.user?.lastName}</p>
                </div>
                <div className = "flex items-center">
                    <p className = "font-jakarta font-regular text-[12px] text-[#4F4C4C]">{parseSQLDate(reply.createdAt)}</p>
                </div>
            </div>
            <div className = "w-full flex justify-between items-center mt-[16px]">
                {isFieldEditable ? (
                    <>
                        <div className = "h-[44px] w-full relative mr-[16px]">
                            <div className = "w-full h-full absolute top-0 left-0 opacity-[0.1] primary-border border-[1px]" />
                            <input className = "w-full h-full box-border p-[12px] font-sans font-regular text-[14px] text-[#5F5F5F] bg-transparent relative outline-none" placeholder = "Schrijf hier een reactie" value = {replyState} onChange = {(event) => setReplyState(event.target.value)} />
                        </div>
                    </>
                ): (
                    <div className = "flex flex-col">
                        <p className = "font-semibold font-sans text-[14px] text-[#4F4C4C]/[0.22]">ANTWOORD</p>
                        <p className = "font-sas font-reuglar text-[14px] text-[#4F4C4C] mt-[2px]">{reply?.comment}</p>
                    </div>
                )}
            </div>
        </div>
    )
}

const ReviewListModal = ({ closeModal = () => {}, contractorData }: reviewListModalProps) => {
    const [ reviewList, setReviewList ] = useState<any[]>([]);
    const [ tooltipHover, setTooltipHover ] = useState(false);

    useEffect(() => {
        if(contractorData?.contractor?.id) {
            axios.get(process.env.REACT_APP_BACKEND_URL + `ratings/contractor/${contractorData?.contractor?.id}`).then(response => {
                setReviewList(response.data.data);
            }).catch(error => console.log(error))
        }
    }, [contractorData])

    const getAvgRating = (review: any) => {
        const { price, communication, planning, recommended, aftercare } = review;
        if(aftercare && aftercare > 0) {
            const total = price + communication + planning + recommended + aftercare;
            // return average up to one decimal point
            return (total / 5).toFixed(1);
        }
        else {
            const total = price + communication + planning + recommended;
            // return average up to one decimal point
            return (total / 4).toFixed(1);
        }
       
    }

    const getUsername = (review: any) => {
        if(review?.user) {
            const userData = review?.user;
            if(userData.firstName) {
                return `${userData.firstName} ${userData.lastName[0]}.`;
            }
            else {
                return "";
            }
        }
        else return "";
    }
    
    return(
        <div className = "w-full h-screen fixed top-0 left-0 flex justify-center items-center pt-[122px] pb-[32px] z-50">
            <div className = "w-full h-full absolute top-0 left-0 bg-black/[0.2]" onClick = {closeModal} />
            <div className = "w-[940px] bg-white flex flex-col box-border items-center relative h-fit max-h-full overflow-y-auto">
                <div className = "w-full flex justify-between items-center px-[20px] mt-[20px]">
                    <div />
                    <div className = "flex items-center">
                        <p className = "font-semibold font-sans text-[30px] text-black">Beoordelingen</p>
                        <div className = "flex relative justify-center z-[2]" onMouseOver = {() => setTooltipHover(true)} onMouseLeave = {() => setTooltipHover(false)}>
                            <IoMdInformationCircleOutline size = "24px" className = "ml-[8px] cursor-pointer primary-text" />
                            <div className = "flex w-[400px] h-[150px] absolute top-[100%] primary-main rounded-[4px] shadow-md px-[16px] py-[10px] duration-[300ms] origin-top" style = {{transform: tooltipHover ? "scaleY(1)" : "scaleY(0)", opacity: tooltipHover ? 1 : 0}}>
                                <p className = "font-sans font-regular text-[14px] text-white">Je kan de aannemer een beoordeling geven wanneer het werk is uitgevoerd en de factuur betaald. Met je beoordeling help je andere klanten hoe jouw ervaring met de aannemer was.</p>
                            </div>
                        </div>
                    </div>
                    <button type = "button" className = "cursor-pointer" onClick = {closeModal}>
                        <IoCloseCircleOutline size = "30px" color = "#000" />
                    </button>
                </div>
                <div className = "w-full flex justify-center items-center mt-[12px]">
                    <div className = "flex items-center gap-[4px]">
                        <div className = "flex relative">
                            <FaRegStar size = "20px" className = "primary-text" />
                            <FaStar size = "20px" className = "absolute top-0 left-0 primary-text" style = {{display: contractorData?.contractor?.avgRating >= 1 ? "flex" : "none"}} />
                        </div>
                        <div className = "flex relative">
                            <FaRegStar size = "20px" className = "primary-text" />
                            <FaStar size = "20px" className = "absolute top-0 left-0 primary-text" style = {{display: contractorData?.contractor?.avgRating >= 2 ? "flex" : "none"}} />
                        </div>
                        <div className = "flex relative">
                            <FaRegStar size = "20px" className = "primary-text" />
                            <FaStar size = "20px" className = "absolute top-0 left-0 primary-text" style = {{display: contractorData?.contractor?.avgRating >= 3 ? "flex" : "none"}} />
                        </div>
                        <div className = "flex relative">
                            <FaRegStar size = "20px" className = "primary-text" />
                            <FaStar size = "20px" className = "absolute top-0 left-0 primary-text" style = {{display: contractorData?.contractor?.avgRating >= 4 ? "flex" : "none"}} />
                        </div>
                        <div className = "flex relative">
                            <FaRegStar size = "20px" className = "primary-text" />
                            <FaStar size = "20px" className = "absolute top-0 left-0 primary-text" style = {{display: contractorData?.contractor?.avgRating >= 5 ? "flex" : "none"}} />
                        </div>
                    </div>
                    <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[16px] mr-[10px]">{contractorData?.contractor?.avgRating}</p>
                    <p className = "font-sans font-regular text-[14px] text-[#4F4C4C] ml-[16px] mr-[10px]">{reviewList?.length > 0 ? reviewList?.length : 0} review(s)</p>
                </div>
                <div className = "w-full h-[1px] bg-[#E5E5E5] mt-[15px] mb-[15px]" />
                <div className = "flex w-full flex-col box-border px-[32px] py-[15px] gap-y-[10px]">
                    {/* review card */}
                    {reviewList && reviewList.length > 0 && reviewList.map((review, index) => (
                        <>
                            <div className = "w-full flex flex-col bg-[#FAFAFA] rounded-[4px] box-border px-[32px] py-[12px]" key = {index}>
                                <div className = "flex w-full justify-between items-center">
                                    <p className = "font-jakarta font-regular text-[16px] text-[#4F4C4C]"><strong>{getUsername(review)}</strong> uit <strong>{review?.city?.nl}</strong></p>
                                    <p className = "font-jakarta font-regular text-[12px] text-[#4F4C4C]">{parseSQLDate(review.createdAt)}</p>
                                </div>
                                <div className = "flex w-full mt-[16px]">
                                    <FaStar size = "20px" className = "primary-text" />
                                    <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{getAvgRating(review)}</p>
                                </div>
                                <div className = "w-full flex mt-[24px]">
                                    <div className = "flex flex-col mr-[32px]">
                                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">PRIJS</p>
                                        <div className = "flex items-center">
                                            <FaStar size = "20px" className = "primary-text" />
                                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{review?.price}</p>
                                        </div>
                                    </div>
                                    <div className = "flex flex-col mr-[32px]">
                                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">COMMUNICATIE</p>
                                        <div className = "flex items-center">
                                            <FaStar size = "20px" className = "primary-text" />
                                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{review.communication}</p>
                                        </div>
                                    </div>
                                    <div className = "flex flex-col mr-[32px]">
                                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">PLANNING/TIMING</p>
                                        <div className = "flex items-center">
                                            <FaStar size = "20px" className = "primary-text" />
                                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{review?.planning}</p>
                                        </div>
                                    </div>
                                    <div className = "flex flex-col mr-[32px]">
                                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">IK RAAD DEZE AANNEMER AAN</p>
                                        <div className = "flex items-center">
                                            <FaStar size = "20px" className = "primary-text" />
                                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{review?.recommended}</p>
                                        </div>
                                    </div>
                                    <div className = "flex flex-col">
                                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">NAZORG (OPTIONEEL)</p>
                                        <div className = "flex items-center">
                                            <FaStar size = "20px" className = "primary-text" />
                                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{review?.aftercare}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className = "w-full flex flex-col mt-[26px]">
                                    <p className = "font-semibold font-sans text-[14px] text-[#4F4C4C]/[0.22]">EXTRA (OPTIONEEL)</p>
                                    <p className = "font-sas font-reuglar text-[14px] text-[#4F4C4C] mt-[8px]">{review?.comment}</p>
                                </div>
                            </div>
                            {review?.threads && review?.threads.length > 0 && (
                                <div className = "w-full flex">
                                    <div className = "h-[64px] w-[64px] flex justify-center items-center">
                                        <GoReply size = "32px" className = "rotate-[180deg]" color = "#4F4C4C" />
                                    </div>
                                    <div className = "w-full flex flex-col bg-[#FAFAFA] rounded-[4px] box-border px-[32px] py-[12px]">
                                    <p className = "font-sans font-semibold text-[14px] primary-text mb-[5px]">ANTWOORDEN</p>
                                        {review?.threads.map((thread: any, index: number) => (
                                            <>
                                                {thread?.replies && thread?.replies?.length > 0 && thread?.replies.map((reply: any, index: number) => (
                                                    <ReplyBox reply = {reply} />
                                                ))}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ReviewListModal
