import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CardExpanded } from "../../shared/listCardExpanded";
import { PrimaryButton } from "../../shared/buttons/igemo_button";
import axios from "axios";
import Logo from "../../assets/logo/g10.svg"
// image imports
import HouseOne from "../../assets/picture/hp/house.png";
import getCardDescription from "../../components/services/getCardDescription";

type OfferRequestDataType = {
    cpOfferRequestRenovations: Array<any>,
    simulationId: string
}

type ServiceAnswerType = {
    createdAt: string,
    deletedAt: string | null,
    id: string,
    key: string,
    newAnswer: number,
    newAnswerText: string,
    originalAnswer: number,
    originalAnswerText: string,
    sesssionId: string,
    updatedAt: string | null,
}

type contractorCertificateType = {
    certificate: {
        certificat: string,
        id: string,
        logo: string | null,
    },
    contractorId: string,
    certificatId: string,
    id: string
}

type contractorLabelsType = {
    id: string,
    labelId: string,
    contractorId: string,
    label: {
        id: string,
        label: string,
        logo: string | null
    }
}

type contractorListType = {
    archiveContractor: number,
    archiveRequestor: number,
    contractor: {
        address1: string | null,
        address2: string | null,
        cityId: string,
        contract: string | null,
        createdAt: string | null,
        deletedAt: string | null,
        description: string | null,
        email: string,
        id: string,
        logo: string,
        name: string,
        phone: string,
        status: string | null,
        updatedAt: string | null,
        streetId: string | null,
        users: UserList,
        cpContractorCertificats?: contractorCertificateType[],
        cpContractorLabels?: contractorLabelsType[]
    },
    contractorId: string,
    createdAt: string,
    deletedAt: string | null,
    groupPurchase: number,
    id: string,
    mentorship: number,
    offerRequestRenovationId: string,
    selected: null,
    statusId: string,
    updatedAt: string | null
}

type UserList = [
    {
        firstName: string,
        lastName: string
    }
]

type ServicesOfferPdfProps = {

}

const ServicesOfferPdf = ({ }: ServicesOfferPdfProps) => {
    const [offerRequestData, setOfferRequestData] = useState<OfferRequestDataType>({
        cpOfferRequestRenovations: [],
        simulationId: ""
    });
    const [selectedContractor, setSelectedContractor] = useState<null | string | number>(null);
    const [serviceAnswerList, setServiceAnswerList] = useState<ServiceAnswerType[]>([]);
    const [contractorList, setContractorList] = useState([]);
    const navigate = useNavigate();
    const DUTCH_MONTHS = [
        'januari',
        'februari',
        'maart',
        'april',
        'mei',
        'juni',
        'juli',
        'augustus',
        'september',
        'oktober',
        'november',
        'december',
    ]

    useEffect(() => {
        const urlQuery = new URLSearchParams(window.location.search);
        const offerRequestId = urlQuery.get("oId");
        const contractorId = urlQuery.get("cId");
        if (offerRequestId) {
            // const auth = JSON.parse(localStorage.getItem("auth")!)
            const authOrigin = localStorage.getItem("auth");
const auth = authOrigin && authOrigin?.length > 0? JSON.parse(localStorage.getItem("auth")!) : ""
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth?.token}`,
            };
            axios.get(process.env.REACT_APP_BACKEND_URL + "offer/request/overview/" + offerRequestId, { headers }).then((res: any) => {
                const { data } = res.data.data;
                // check if data is an array with an object stored at 0 index
                if (Array.isArray(data)) {
                    if (contractorId) {
                        let dataCopy = JSON.parse(JSON.stringify(data[0]));
                        dataCopy.cpOfferRequestRenovations.forEach((renovation: any, renovationIndex: number) => {
                            renovation.cpOfferRequestRenovationContractors.forEach((contractor: any, contractorIndex: number) => {
                                if (contractor.contractorId !== contractorId) {
                                    delete dataCopy.cpOfferRequestRenovations[renovationIndex].cpOfferRequestRenovationContractors[contractorIndex];
                                }
                            });
                        });
                        setOfferRequestData(dataCopy);
                    }
                    else {
                        // if there is no contractorId, show all the data
                        setOfferRequestData(data[0]);
                    }
                }
            }).catch(error => console.log(error))
        }
    }, [])

    useEffect(() => {
        if (offerRequestData?.simulationId) {
            // const auth = JSON.parse(localStorage.getItem("auth")!)
            const authOrigin = localStorage.getItem("auth");
const auth = authOrigin && authOrigin?.length > 0? JSON.parse(localStorage.getItem("auth")!) : ""
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth?.token}`,
            };
            axios.get(process.env.REACT_APP_BACKEND_URL + "simulation/resultan/" + offerRequestData?.simulationId, { headers }).then((res) => {
                setServiceAnswerList(res.data)
            }).catch(error => console.log(error))


        }
    }, [offerRequestData])

    const isContractorListEmpty = (item: any) => {
        if (item && item?.id) {
            const hasEmptyIndex = item?.cpOfferRequestRenovationContractors.every((renovation: any) => renovation === undefined);
            return !hasEmptyIndex;
        }
        else return false;
    }

    const date = new Date()
    return (
        <div className="flex w-full flex-col">
            <div className="flex flew-col w-full justify-between items-center flew-row h-[80px]">
                <img className="h-[50px] object-contain" alt="logo" src={Logo} />
                <p className="font-Source font-semibold text-[19px] text-black">{`${date.getDate()} ${DUTCH_MONTHS[date.getMonth()]} ${date.getFullYear()}`}</p>
            </div>
            <div className="w-full flex flew-col justify-center bg-white">
                <div className="w-full flex flex-col md:px-16 lg:px-16 mac:px-32">
                    <div className="w-full bg-white flex flex-col items-center box-border py-[18px]">
                        <div className="w-full flex justify-center">
                            <p className="font-Source font-semibold text-3xl text-black text-center md:text-start">Overzicht van de offerteaanvragen</p>
                        </div>
                        <div className="w-[95%] max-w-[100%] h-[1px] bg-[#707070]/[0.1] mt-[15px]" />
                        <div className="w-full flex flex-col">
                            {offerRequestData?.cpOfferRequestRenovations?.map((item: any, index: number) => (
                                <div className="w-full flex flex-col border-b-[1px] border-b-[#F6F6F6] box-border md:px-[52px] mt-[15px]" key={item.id}>
                                    <div className="w-full flex items-center">
                                        <img className="h-[86px] object-fit shrink-0 mr-[40px]" alt="logo" src={item?.renovation?.logo || HouseOne} />
                                        <div className="flex flex-col">
                                            <div className="flex items-center">
                                                <p className="font-Source font-semibold text-2xl text-black">{item?.renovation?.title}</p>
                                            </div>
                                            <div className="flex flex-col">
                                                {getCardDescription(item?.renovation?.key || "", serviceAnswerList)}
                                            </div>
                                        </div>
                                    </div>
                                    {isContractorListEmpty(item) && (
                                        <div className="flex w-full my-[15px]">
                                            <p className="font-Source font-semibold text-p1 text-black">Lijst van aannemers</p>
                                        </div>
                                    )}
                                    <div className="flex flex-col w-full mb-[35px] overflow-x-auto">
                                        {item?.cpOfferRequestRenovationContractors?.map((contractor: contractorListType, contractorIndex: number) => (
                                            <CardExpanded contractorData={contractor} key={parseInt(contractor.id)} isClickable={false} onClickHandler={(id) => setSelectedContractor(id)} hideDots={true} />
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesOfferPdf;
