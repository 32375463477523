import Routes from "./routes";
import { GlobalProvider } from "./context/globalContext/globalContext";
import { useEffect } from "react";
import axios from "axios";

// Google Analytics tracking code

// import ReactGA from "react-ga";
// ReactGA.initialize("");
// ReactGA.pageview(window.location.pathname + window.location.search);


const App = () => {
  // storage link https://igm-storage-develop.s3.fr-par.scw.cloud/
  useEffect(() => {
    const domainObj = JSON.parse(localStorage.getItem("domainObj") || "{}");
    const favicon = localStorage.getItem("favicon");
    if(favicon) {
      const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = favicon;
      document.getElementsByTagName('head')[0].appendChild(link);

      // we fetch API again regardless in the case where user switched domains and a favicon is already in the localStorage
      if(domainObj?.id) {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/domain-config/domain/${domainObj.id}`).then((response: any) => {
          const faviconUrl = process.env.REACT_APP_STORAGE_URL +"/" + response.data.data.data[0].favicon;
          localStorage.setItem("favicon", faviconUrl);
          const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          link.href = process.env.REACT_APP_STORAGE_URL +"/" + response.data.data.data[0].favicon;
          document.getElementsByTagName('head')[0].appendChild(link);
        }).catch(error => console.log(error))
      }
    }
    else {
      if(domainObj?.id) {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/domain-config/domain/${domainObj.id}`).then((response: any) => {
          const faviconUrl = process.env.REACT_APP_STORAGE_URL +"/" + response.data.data.data[0].favicon;
          localStorage.setItem("favicon", faviconUrl);
          const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          link.href = process.env.REACT_APP_STORAGE_URL +"/" + response.data.data.data[0].favicon;
          document.getElementsByTagName('head')[0].appendChild(link);
        }).catch(error => console.log(error))
      }
    }
  }, [])

  return (
    <GlobalProvider>
      <Routes />
    </GlobalProvider>
  );
};

export default App;
