import React, {ChangeEvent, Dispatch, FC} from "react";

import {ModalBox} from "../../shared/modals/modal";
import {PrimaryButton} from "../../shared/buttons/igemo_button";


interface FormStruct {
    isLoading: boolean
    errors: errorStruct
    adminValues: valueStruct
    handleClose: Dispatch<any>
    handleSubmitForm: Dispatch<any>
    handleChange: Dispatch<ChangeEvent<object>>
    editAdminId: string
}

interface errorStruct {
    lastName: string
    firstName: string
    email: string
}

interface valueStruct {
    lastName: string
    firstName: string
    email: string
}


const SuperAdminForm: FC<FormStruct> = ({
    isLoading,
    errors,
    adminValues,
    handleClose,
    handleChange,
    handleSubmitForm,
    editAdminId
}) => {
    return(
        <ModalBox
            title={editAdminId?"Bewerk superadmin toevoegen":"Nieuwe superadmin toevoegen"}
            width={'auto'}
            cancelHandler={() => handleClose(false)}
            extraClasses="lg:w-[80%] w-full"
            open={true}
        >
            <div className="border-grey-300 border-b-[1px] border-t-[1px]">
                <div className="px-8 py-8" style={{ margin: '0 20%' }}>
                    <div className="w-1/3 text-xs w-full mb-5">
                        <p className="text-left mb-1 font-sans font-semibold text-[14px] primary-text" dangerouslySetInnerHTML={{ __html: 'VOORNAAM' }} />
                        <div className="mt-3 relative bg-clear w-full border-2 border-gray-100">
                            <input
                                autoComplete="off"
                                className="py-2 px-1 focus:outline-none w-full"
                                placeholder="VOORNAAM"
                                name="firstName"
                                type="input"
                                onChange={handleChange}
                                defaultValue={adminValues.firstName}
                                value={adminValues.firstName}
                            />
                        </div>
                        <span className="text-error text-xs">{errors.firstName ? errors.firstName : null}</span>
                    </div>

                    <div className="w-1/3 text-xs w-full mb-5">
                        <p className="font-sans font-semibold text-[14px] primary-text text-left mb-1" dangerouslySetInnerHTML={{ __html: 'ACHTERNAAM' }} />
                        <div className="mt-3 relative bg-clear w-full border-2 border-gray-100">
                            <input
                                autoComplete="off"
                                className="py-2 px-1 focus:outline-none w-full"
                                placeholder="ACHTERNAAM"
                                name="lastName"
                                type="input"
                                onChange={handleChange}
                                defaultValue={adminValues.lastName}
                                value={adminValues.lastName}
                            />
                        </div>
                        <span className="text-error text-xs">{errors.lastName ? errors.lastName : null}</span>
                    </div>

                    <div className="w-1/3 text-xs w-full mb-5">
                        <p className="font-sans font-semibold text-[14px] primary-text text-left mb-1" dangerouslySetInnerHTML={{ __html: 'EMAIL' }} />
                        <div className="mt-3 relative bg-clear w-full border-2 border-gray-100">
                            <input
                                autoComplete="off"
                                className="py-2 px-1 focus:outline-none w-full"
                                placeholder="EMAIL"
                                disabled={editAdminId!==""?true:false}
                                name="email"
                                type="input"
                                onChange={handleChange}
                                defaultValue={adminValues.email}
                                value={adminValues.email}
                                
                            />
                        </div>
                        <span className="text-error text-xs">{errors.email ? errors.email : null}</span>
                    </div>
                </div>
            </div>

            <div className="px-8">
                <div className="flex justify-end mt-4">
                    <PrimaryButton
                        disabled={isLoading}
                        content={isLoading ? 'Indienen' : 'Opslaan'}
                        onClick={() => handleSubmitForm(undefined)}
                    />
                </div>
            </div>
        </ModalBox>
    )
};

export default SuperAdminForm;
