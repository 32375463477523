import type { MenuProps } from 'antd';
import React, {useEffect, useState} from "react";
import { message, Dropdown, Popconfirm } from 'antd';

import Layout from "../layout";
import SuperAdminForm from "./AdminForm";
import SuperAdminService from "../../api/SuperAdmin";
import {PrimaryButtonSmall} from "../../shared/buttons/igemo_button";
import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Joi from 'joi';


interface adminStruct {
    firstName: string;
    lastName: string;
    email: string;
    id: string;
}
const superAdminSchema = Joi.object({
    firstName: Joi.string()
        .required()
        .messages({
            'string.empty': `Voornaam mag niet leeg zijn.`,
            'any.required': `Voornaam is een verplicht veld.`,
        }),
    lastName: Joi.string()
        .required()
        .messages({
            'string.empty': `Achternaam mag niet leeg zijn.`,
            'any.required': `Achternaam is een verplicht veld.`,
        }),
    email: Joi.string()
        .pattern(/^\w+([\w+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        .required()
        .messages({
            'string.empty': `E-mail mag niet leeg zijn`,
            'any.required': `E-mail is een verplicht veld.`,
            'string.pattern.base': `Ongeldig e-mailadres.`
        }),
});

const SuperAdmins = () => {
    const [isLoading, setLoading] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const [adminsList, setAdminsList] = useState([]);
    const [adminForm, toggleAdminForm] = useState(false);
    const [editAdminId, toggleEditAdminId] = useState('');
    const [messageApi, contextHolder] = message.useMessage();
    const [adminPayload, setAdminPayload] = useState({
        firstName: "",
        lastName: "",
        email: "",
    });
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        getAdminsList();
    }, []);

    const getAdminsList = async () => {
        try {
            setLoading(true)
            const response = await SuperAdminService.getAdminList();
            const {data} = response;
            setAdminsList(data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getAdminDetails = async () => {
        try {
            const response = await SuperAdminService.getAdminDetails(editAdminId);
            const {data} = response;
            setAdminPayload({
                firstName: data?.firstName,
                lastName: data?.lastName,
                email: data?.email,
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handlePayloadChange = (event:any) => {
        const {name, value} = event.target;

        try {

            const target = superAdminSchema.extract(name);
            const result = target.validate(value)
            if (result.error  && isSubmitted) {
                setErrors({ ...errors, [name]: result.error.message })
            }
            else {
                setErrors({ ...errors, [name]: "" })
            }
            let updatedPayload = {...adminPayload};
            updatedPayload = { ...updatedPayload, [name]: value };
            setAdminPayload(updatedPayload);

        } catch (error: any) {
            if (isSubmitted) {
                setErrors({ ...errors, [name]: error.message });
            }
        }
    };

    const handleClose = () => {
        setIsSubmitted(false);
        setAdminPayload({
            lastName: '',
            firstName: '',
            email: ''
        });
        setErrors({
            lastName: '',
            firstName: '',
            email: ''
        });
        toggleAdminForm(false);
        toggleEditAdminId('');
    };

    const handleMenuClick: MenuProps['onClick'] = (e: { key: string }) => {
        if (e.key === '0') {
            toggleAdminForm(true);
            getAdminDetails();
        }
    };

    const handleSubmitForm = () => {
        setIsSubmitted(true);
        const results = superAdminSchema.validate(adminPayload, {abortEarly:false})
        if (results.error) {
            let errObj ={...errors}
            for (const err of results.error.details) {
                errObj = {...errObj,[err.path[0]]:err.message}
            }
            setErrors(errObj)
            return
        } else {
            handleUpdate();
        }
    };

    const deleteAdmin = async () => {
        try {
            const auth = JSON.parse(localStorage.getItem('auth') || '{}');
            const isCurrentAdmin = auth?.uid == editAdminId;
            const response = await SuperAdminService.deleteAdmin(editAdminId);
            if (response.success === true) {
                getAdminsList();
                handleClose();
                if(isCurrentAdmin) {
                    localStorage.removeItem('auth')
                    localStorage.removeItem('simulationInitDone')
                    window.location.href = "/"
                }
                messageApi.open({
                    type: 'success',
                    content: `Delete is succesvol.`,
                    duration: 3,
                });
            } else if (response.success === false) {
                messageApi.open({
                    type: 'error',
                    content: `Kan de hoofdbeheerder niet verwijderen`,
                    duration: 3,
                });
                setUpdating(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleUpdate = async () => {
        setUpdating(true);

        const updatedPayload = {...adminPayload};
        const host = window.location.host
        const url = host === 'localhost' ? 'http://localhost:3000' : 'https://' + host

        const payload = {
            "firstName": updatedPayload?.firstName,
            "lastName": updatedPayload?.lastName,
            "email": updatedPayload?.email,
            "url": url,
        }
        const response: any = await editAdminId
            ? SuperAdminService.updateAdmin(editAdminId, payload, {})
            : SuperAdminService.createNewAdmin(payload, {});

        response
            .then((res: any) => {
                getAdminsList();
                setUpdating(false);
                handleClose();
                messageApi.open({
                    type: 'success',
                    content: `${editAdminId ? 'Update' : 'Add'} is succesvol.`,
                    duration: 3,
                });
            })
            .catch(() => {
                messageApi.open({
                    type: 'error',
                    content: `${editAdminId ? 'Het updaten van de hoofdbeheerder is mislukt' : 'Kan de hoofdbeheerder niet toevoegen'} `,
                    duration: 3,
                });
                setUpdating(false);
            })
    };

    const items: MenuProps['items'] = [{
        label: 'Bewerken',
        key: '0',
    },
        {
            label: <Popconfirm
                okText="Ja"
                cancelText="Nee"
                onConfirm={deleteAdmin}
                title="Verwijderen hoofdbeheerder"
                onCancel={() => toggleEditAdminId('')}
                description="Weet u zeker dat u deze hoofdbeheerder wilt verwijderen?"
            >
                <p className="text-error">Verwijderen</p>
            </Popconfirm>,
            key: '1',
        }];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return(
        <>
            {contextHolder}
            {adminForm && (
                <SuperAdminForm
                    handleSubmitForm={handleSubmitForm}
                    handleChange={handlePayloadChange}
                    adminValues={adminPayload}
                    handleClose={handleClose}
                    isLoading={isUpdating}
                    errors={errors}
                    editAdminId={editAdminId}
                />
            )}
            <Layout backgroundColor="#ffffff">
                <div className="md:mx-16 lg:mx-16 mac:mx-32 mt-8 bg-white mb-16 pb-8">
                    <div className="flex px-12 py-6 pb-[15px]">
                        <h1 className="text-[24px] font-semibold mr-5">
                            Superadmin Lijst
                        </h1>

                        <div className="mt-1">
                            <PrimaryButtonSmall
                                classes="ml-6"
                                disabled={isLoading}
                                content="+ Superadmin toevoegen"
                                onClick={() => {
                                    toggleAdminForm(true);
                                    toggleEditAdminId('');
                                }}
                            />
                        </div>
                    </div>

                    <div className="px-12 py-6 pb-[8px]">
                        {isLoading ? (
                            <div className="px-16 py-16" style={{ textAlign: 'center' }}>Laden...</div>
                        ) : (
                            adminsList?.length ? (
                                adminsList?.map((admin: adminStruct) => {
                                    return (
                                        <div className="px-4 py-2 border flex mb-2">
                                            <div className="flex flex-1 min-w-[125px] shrink-0 font-sans font-regular text-[14px] text-black">{admin.firstName}</div>
                                            <div className="flex flex-1 min-w-[125px] shrink-0 font-sans font-regular text-[14px] text-black">{admin.lastName}</div>
                                            <div className="flex flex-1 min-w-[200px] shrink-0 font-sans font-regular text-[14px] text-[#8B8B8B]">{admin.email}</div>
                                            <div className = "flex flex-1" />
                                            <div className = "flex flex-1" />
                                            <div className = "flex flex-1" />
                                            <div className = "flex flex-1" />
                                            <div className="flex" style={{ marginLeft: 'auto' }}>
                                                <div className="ml-6">
                                                    <Dropdown menu={menuProps} trigger={['click']}>
                                                        <a onClick={(e) => {
                                                            e.preventDefault();
                                                            toggleEditAdminId(admin.id);
                                                        }}>
                                                            <FontAwesomeIcon
                                                                icon={faEllipsisVertical as IconProp}
                                                                className="w-5 h-5 ml-4 mr-auto mt-1 -m cursor-pointer"
                                                            />
                                                        </a>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                <div className="px-16 py-16" style={{ textAlign: 'center' }}>Geen data.</div>
                            ))}
                    </div>

                </div>
            </Layout>
        </>
    )
};

export default SuperAdmins;
