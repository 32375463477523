import { DeleteFilled } from "@ant-design/icons";
import { Select } from "antd";
import { booleanOptions, igemoUserFilterOptions } from "./config";
import { useContext, useEffect, useMemo } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import RenovationService from "../../api/Renovation"

const ServicesOption = ({
  item,
  index,
  removeSelectedOption,
  updateOption1Value,
  availableOptions,
}: any) => {

  // select option 1
  const SelectOption1 = ({ value }: any) => {
    return (
      <Select
        showSearch
        placeholder="Selecteer Dienst"
        optionFilterProp="children"
        className="w-full"
        size="middle"
        onChange={(value) => {
          updateOption1Value(value, index, "option1");
          updateOption1Value("", index, "option2");
          updateOption1Value("", index, "option3");
        }}
        // onSearch={onSearch}
        filterOption={(input, option) =>
          (option?.label ?? "").toString().toLowerCase().includes(input.toLowerCase())
        }
        value={igemoUserFilterOptions?.find((option: any) => option.value === value)?.label}
        options={availableOptions}
      />
    );
  };

  // select option 2
  const SelectOption2 = ({ value }: any) => {
    return (
      <Select
        showSearch
        placeholder="Is"
        optionFilterProp="children"
        className="w-full"
        size="middle"
        onChange={(value) => {
          updateOption1Value(value, index, "option2");
        }}
        // onSearch={onSearch}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        value={booleanOptions?.find((option: any) => option.value === value)?.label}
        options={booleanOptions}
      />
    );
  };

  // select option 3
  const SelectOption3 = ({ value }: any) => {
    const {
      state: { filtersData: { services, address, contractor, houseOwner } },
      dispatch,
    } = useContext(GlobalContext)

    useEffect(() => {
      if (services) return
      (async () => {
        const { data } = await RenovationService.getAll()
        dispatch({
          type: 'SET_FILTERS_DATA',
          payload: {
            key: 'services',
            data: data
          }
        })
      })();
    }, [])

    const options = useMemo(() => (
      (services ?? [])?.map((cont: any) => ({ ...cont, value: cont.id, label: cont.title }))
    ), [services])

    return (
      <Select
        showSearch
        placeholder="Selecteer Dienst"
        optionFilterProp="children"
        className="w-full"
        size="middle"
        loading={!services}
        onChange={(value) => {
          updateOption1Value(value, index, "option3");
        }}
        // onSearch={onSearch}
        filterOption={(input, option) =>
          (option?.label ?? "").toString().toLowerCase().includes(input.toLowerCase())
        }
        value={options?.find((option: any) => option.value === value)?.label}
        options={options}
      />
    );
  };
  return (
    <div className="flex pb-2 w-full pt-2">
      <div className="px-1 flex items-center w-[40%]">
        {index === 0 ? (
          <div className="w-20">
            <h2 className="pl-1 text-[14px] opacity-[0.8]"></h2>
          </div>
        ) : (
          <div className="w-20">
            <h2 className="w-fit py-1 px-3 text-[14px] opacity-[0.8] border rounded text-center ">
              EN
            </h2>
          </div>
        )}
        <div className="w-full">
          <SelectOption1 value={item?.option1} />
        </div>
      </div>
      <div className="w-[20%] px-1">
        <SelectOption2 value={item?.option2} />
      </div>
      <div className="w-[50%] px-1">
        <SelectOption3 value={item?.option3} />
      </div>
      <div className="pl-1 flex items-center">
        <DeleteFilled
          className="text-[20px] cursor-pointer text-[gray]"
          onClick={() => removeSelectedOption(index)}
        />
      </div>
    </div>
  );
};
export default ServicesOption;
