import React, { useState } from "react";
import axios from "axios"
import Joi from "joi";

// assets & image imports
import { PrimaryButton } from "../../../shared/buttons/igemo_button";
import { IoCloseCircleOutline } from "react-icons/io5";
import ArrowLeft from "../../../assets/icons/arrow-left-circle.svg";

type ForgotPasswordProps = {
    showRegistrationButton?: boolean;
    handleModalClose?: () => void;
    onBackClick?: () => void;
}

const emailSchema = Joi.string()
        .pattern(/^\w+([\w+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        .required()
        .messages({
            'string.empty': `E-mail mag niet leeg zijn`,
            'any.required': `E-mail is een verplicht veld.`,
            'string.pattern.base': `Ongeldig e-mailadres.`
        });

const ForgotPasswordComponent = ({ showRegistrationButton = false, handleModalClose = () => {}, onBackClick = () => {} }: ForgotPasswordProps) => {
    const [ inputState, setInputState ] = useState({
        email: ""
    });
    const [ inputErrorState, setInputErrorState ] = useState({
        email: ""
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [ forgotPasswordState, setForgotPasswordState ] = useState<"initial" | "loading" | "success" | "error">("initial");
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        try {
            
            const result = emailSchema.validate(value)
            if (result.error && isSubmitted) {
                setInputErrorState({ ...inputErrorState, [name]: result.error.message })
            }
            else {
                setInputErrorState({ ...inputErrorState, [name]: "" })
            }
            setInputState({ ...inputState, [name]: value });

        } catch (error: any) {
            if (isSubmitted) {
                setInputErrorState({ ...inputErrorState, [name]: error.message });
            }
        }
    }

    function forgotPasswordSubmit() {
        setIsSubmitted(true);
        const results = emailSchema.validate(inputState.email,{abortEarly:false})
        if (results.error) {
            let errObj ={...inputErrorState}
            for (const err of results.error.details) {
                errObj = {...errObj,email:err.message}
            }
            setInputErrorState(errObj)
            return
        } else  {
            const { email } = inputState;
            setForgotPasswordState("loading");
            axios.post(process.env.REACT_APP_BACKEND_URL + "/user/password/reset/", {
                email 
            }).then(response => {
                setForgotPasswordState("success");
            }).catch(error => {
                console.log(error)
                setForgotPasswordState("error");
            }).finally(()=>{
                setIsSubmitted(false)
            })
        }   
    }
    function closeModal(){
        setIsSubmitted(false)
        handleModalClose()
    }
    
    return (
        <div className = "w-[934px] md:h-[471px] max-w-[90%] min-h-[471px] bg-white flex flex-col md:flex-row">
            <div className = "flex flex-col w-full flex-[3.5] h-full primary-main box-border py-[37px] px-[15px] md:px-[33px]">
                <div className = "w-full flex justify-end md:hidden">
                    <div className = "cursor-pointer" onClick = {closeModal}>
                        <IoCloseCircleOutline size = "30px" color = "white" /> 
                    </div>
                </div>
                <p className = "font-Source w-full font-semibold md:text-4xl text-2xl secondary-text md:leading-[55px] leading-[25px] animate-fade-in-top">Nieuw<br></br>wachtwoord<br></br>aanvragen</p>
                <p className = "font-Source w-full font-regular text-p2Alt secondary-text mt-[21px] animate-fade-in-top">Geef hier het e-mailadres in dat is gekoppeld aan uw account.</p>
                {/*<p className = "font-Source font-regular text-p2Alt text-white mt-[25px] animate-fade-in-top">At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p>*/}
            </div>
            <div className = "flex flex-col flex-[6] h-full justify-between bg-white shrink-0 w-full">
                <div className = "flex flex-col h-full w-full">
                    <div className = "w-full justify-end hidden md:flex">
                        <div className = "cursor-pointer" onClick = {handleModalClose}>
                            <IoCloseCircleOutline size = "30px" color = "black" className = "mr-[17px] mt-[9px]" /> 
                        </div>
                    </div>
                    {/* defaut page with input field and button */}
                    {forgotPasswordState === "initial" && (
                        <div className = "w-full flex flex-col box-border md:px-[35px] px-[15px] animate-fade-in-bottom">
                            <div className = "w-full flex flex-col mb-[18px]">
                                <p className = "font-Source font-semibold text-p2 primary-text box-border py-[5px] mt-[18px] md:mt-[18px]">Email</p>
                                <div className = {`w-full h-[39px] border-[1px] flex items-center box-border px-[10px] ${inputErrorState.email ? 'border-[red]' : 'border-primary/[0.09]'}`}>
                                    <input autoComplete="off" className = "w-full outline-none font-Source font-regular text-p2 text-black" type = "text" name = "email" value = {inputState.email} onChange = {handleInputChange} />
                                </div>
                                <span className="text-error text-xs">{inputErrorState.email ? inputErrorState.email : null}</span>
                            </div>
                            <div className = "w-full flex justify-center">
                                <PrimaryButton content = "Aanvragen" onClick = {forgotPasswordSubmit} />
                            </div>
                            <div className = "w-full flex mt-[36px]">
                                <div className = "flex items-center cursor-pointer" onClick = {onBackClick}>
                                    <img className = "h-[17px w-[17px] object-fit mr-[12px]" src = {ArrowLeft} alt = "arrow-left" />
                                    <p className = "font-Source font-semibold text-p2 text-black">Terug naar de inlogpagina</p>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* loader */}
                    {forgotPasswordState === "loading" && (
                        <div className = "w-full h-full flex items-center justify-center animate-fade-in-top">
                            <div className = "w-[50px] h-[50px] border-4 primary-border border-solid border-r-white rounded-full animate-spin" />
                        </div>
                    )}
                    {/* success message */}
                    {forgotPasswordState === "success" && (
                        <div className = "w-full h-full flex flex-col items-center justify-center animate-fade-in-top box-border px-[15px]">
                            <p className = "font-Source font-semibold text-3xl primary-text">E-mail succesvol verzonden!</p>
                            <p className = "font-Source font-semibold text-p2 text-black mt-[10px] text-center">Er is een email verstuurd naar het opgegeven emailadres. Volg de instructies in de email om je wachtwoord te wijzigen.</p>
                        </div>
                    )}
                    {/* Fallback message */}
                    {forgotPasswordState === "error" && (
                        <div className = "w-full h-full flex flex-col items-center justify-center animate-fade-in-top box-border px-[15px]">
                            <p className = "font-Source font-semibold text-3xl primary-text">E-mail succesvol verzonden!</p>
                            <p className = "font-Source font-semibold text-p2 text-black mt-[10px] text-center">Er is een email verstuurd naar het opgegeven emailadres. Volg de instructies in de email om je wachtwoord te wijzigen.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordComponent;
