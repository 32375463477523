import React, {FC, useState} from 'react';
import uploadIcon from "../../assets/icons/upload.svg";
import imageIcon from "../../assets/icons/image.svg";
import type {UploadProps} from 'antd';
import {message, Upload, Form, UploadFile as uploadFile} from 'antd';
import { maxFileSize } from '../../constants/general';


const {Dragger} = Upload;


interface UploadFileStruct {
    title?: string,
    image: boolean,
    name: string,
    showDescription?: boolean,
    onFileChange?: (event: any) => void,
    mini?: boolean,
    multiple?: boolean,
    defaultFile?:uploadFile<any>[],
    outerError?: string,
    isRequired?:number
}

export const UploadFile: FC<UploadFileStruct> = ({
    title = undefined,
    image = false,
    name,
    onFileChange = (event) => {
    },
    showDescription = false,
    mini = false,
    multiple = true,
    defaultFile = undefined,
    outerError,
    isRequired

}) => {
    const [fileList, setFileList] = useState<uploadFile<any>[]>([])
    const props: UploadProps = {
        name: 'file',
        multiple: multiple,
        defaultFileList: defaultFile,
        fileList: fileList,
        listType: "picture",
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        beforeUpload() {
            /* update state here */
            return false;
        },
        onChange(info) {
            const {status} = info.file;
            
            if(info.file.size && info.file.size >= maxFileSize) {
                message.error(`De maximale bestandsgrootte is ${maxFileSize /1000_000} MB`, 3);
            } else {
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
                onFileChange(info.fileList)
                setFileList(info.fileList)
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    return (
        <div className={`${mini ? 'w-32 h-full' : 'w-full h-1/2 xl:h-full'} border-primary-200 border-dashed`}>
            {
                title &&
                <p className="font-bold text-xs text-secondary mb-3">
                    {title}
                    <span className='text-error'>{isRequired===1 ?"*":""}</span>
                </p>
            }
            <Form.Item name={name}>
                <Dragger {...props}>
                    <p className="flex justify-center">
                        <div
                            className={`flex justify-center items-center rounded-full ${mini ? 'w-3 h-3' : 'w-6 h-6 md:w-12 md:h-12'} primary-main bg-opacity-25`}>
                            <img
                                className={`${mini ? 'w-1 h-1' : 'w-3 h-3 md:w-5 md:h-5 invert'}`}
                                src={image ? imageIcon : uploadIcon}
                                alt="image"
                            />
                        </div>
                    </p>
                    {showDescription &&
                        <p className="text-xs font-semibold primary-text">
                            klik om een bestand te importeren of versleep uw document
                        </p>
                    }
                </Dragger>
                <p className="text-error text-xs text-start ml-2">
                    {outerError ? outerError:''}
                </p>
            </Form.Item>
        </div>
    )
}
