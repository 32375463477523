import { FC, useState } from "react"
import { ReactComponent as PdfIcon } from "../../assets/icons/pdf-color.svg"
import ViewDocument from "../../components/modals/viewDocument";

interface HouseOwnerDocumentProps {
    item: any,
    offerRequestId: any
}

const OfferRequestDocument: FC<HouseOwnerDocumentProps> = ({ item,offerRequestId }) => {
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<any>(null)

    let { cpOfferRequestRenovationContractorDocuments: documents = [] } = item
    // filter documents
    // 4 -> Offerte
    // 5 -> Factuur
    // documents = documents?.filter((document: any) => [4, 5]?.includes(document?.documentTypeId))

    const { STATUS: status } = item

    const handlePreviewDocument = (document: any) => {
        setShowDocumentModal(true)
        setSelectedDocument(document)
    }

    return (<>
        <div className='w-full flex flex-wrap gap-2'>
            {   documents?.map((document: any) => (
                    <PdfIcon className='h-5 w-5 cursor-pointer' key={document?.id} onClick={() => handlePreviewDocument(document)} />
                ))
            }
        </div>
        {showDocumentModal && (
            <ViewDocument
                open={showDocumentModal}
                setOpen={setShowDocumentModal}
                documentsList={documents}
                selected={selectedDocument}
                // toggleOpen={() => setShowDocumentModal(!showDocumentModal)}
                // selectedItem={[item]}
                // offerReqId={offerRequestId}
                // tabKey="4"
            />
        )}
    </>
    )
}
export default OfferRequestDocument
