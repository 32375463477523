import React, {FC} from "react";
import greyDownload from "../../assets/icons/pdf.svg";
import colorDownload from "../../assets/icons/pdf-color.svg";
import plus from "../../assets/icons/plus.png";
import filterIcon from "../../assets/icons/filter-results-button.png";
import { HiOutlinePlus } from "react-icons/hi2";


interface IgemoInterface {
    icon?: string,
    content: string,
    classes?: string,
    iconClasses?: string,
    disabled?: boolean
    defaultClasses?: string,
    onClick: (event: React.MouseEvent<HTMLElement>)=> void
    style?: object
}

export const IgemoButton: FC<IgemoInterface> = ({
                                                    icon = null,
                                                    content = '',
                                                    disabled = false,
                                                    classes = "",
                                                    defaultClasses = "px-6 py-2 md:px-9 md:py-3 lg:px-12 lg:py-4",
                                                    iconClasses = "w-3 h-6 mr-2",
                                                    onClick = () => {
                                                    },
                                                    style,
                                                }) => {
    return (
        <button
            className={`${defaultClasses} ${classes}`}
            onClick={onClick}
            disabled={disabled}
            style={style}
        >
            <div className="flex">
                {icon && (
                    <HiOutlinePlus className = {iconClasses} />
                )}
                <div className="flex justify-center items-center flex-grow">
                    {content}
                </div>
            </div>
        </button>
    )
}


export const BasicButton = ({
    onClick=()=> {},
    content="",
    classes=""
                            })=> {
    return (
        <IgemoButton
            classes={`${classes} bg-primary text-white text-md md:text-xl lg:text-3xl`}
            content={content}
            onClick={onClick}
        />
    )
}

export const PrimaryButton = ({
    onClick=()=> {},
    content="",
    disabled = false,
    classes=""
                            })=> {
    return (
        <IgemoButton
            classes={`${disabled ? 'bg-gray-400' : 'primary-main'} text-white text-xs md:text-sm lg:text-lg font-semibold ${classes}`}
            content={content}
            onClick={onClick}
            disabled={disabled}
        />
    )
}

export const PrimaryButtonSmall = ({
    onClick=()=> {},
    content="",
    disabled = false,
    classes=""
                            })=> {
    return (
        <IgemoButton
            defaultClasses="px-6 py-2"
            classes={`${disabled ? 'bg-gray-400' : 'primary-main'} secondary-text text-sm font-semibold ${classes}`}
            content={content}
            onClick={onClick}
            disabled={disabled}
        />
    )
}

export const SecondaryButton = ({
    onClick=()=> {},
    content="",
    classes="",
    style = {},
                            })=> {
    return (
        <IgemoButton
            defaultClasses="px-6 py-1.5 md:px-9 md:py-2.5 lg:px-12 lg:py-3.5"
            classes={`${classes} text-xs md:text-sm lg:text-lg font-semibold border-2 primary-border bg-white primary-text`}
            content={content}
            onClick={onClick}
            style={style}
        />
    )
}

export const SecondaryButtonSmall = ({
    onClick=()=> {},
    content="",
    classes="",
    disabled= false,
})=> {
    return (
        <IgemoButton
            defaultClasses="px-6 py-2"
            classes={`${classes} text-sm font-semibold border-[1px] primary-border primary-text`}
            content={content}
            onClick={onClick}
            disabled={disabled}
        />
    )
}

export const ThirdButton = ({
    onClick=()=> {},
    content="",
    classes=""
                            })=> {
    return (
        <IgemoButton
            classes={`${classes} text-xs md:text-sm lg:text-lg border-0 primary-text underline`}
            content={content}
            onClick={onClick}
        />
    )
}

export const CarteButton = ({
    onClick=()=> {},
    content="",
    classes=""
                            })=> {
    return (
        <IgemoButton
            defaultClasses="px-10 py-2 md:px-15 md:py-3.5 lg:px-24 lg:py-5"
            classes={`${classes} text-xs text-white bg-primary`}
            content={content}
            onClick={onClick}
        />
    )
}

export const TableButton: FC<IgemoInterface> = ({
    onClick=()=> {},
    content="",
    icon=undefined,
    classes=""
                            })=> {
    return (
        <IgemoButton
            defaultClasses="px-3 py-1 lg:px-5 lg:py-2"
            classes={`${classes} text-xs text-white rounded primary-main`}
            content={content}
            icon={icon}
            onClick={onClick}
        />
    )
}

export const TableButtonWithIcon: FC<IgemoInterface> = ({
    onClick=()=> {},
    content="",
    icon=undefined,
    classes=""
                            })=> {
    return (
        <IgemoButton
            defaultClasses="px-2 py-0.5 lg:px-4 lg:py-1"
            classes={`${classes} text-xs text-white rounded bg-primary`}
            iconClasses="w-2 h-4 lg:w-3 lg:h-6 mr-2"
            content={content}
            icon={icon}
            onClick={onClick}
        />
    )
}

export const FilterButtonWithIcon: FC<IgemoInterface> = ({
    onClick=()=> {},
    content="",
    classes=""
                            })=> {
    return (
        <IgemoButton
            defaultClasses="px-4 py-2"
            classes={`${classes} text-xs text-secondary bg-white bg-white bg-primary text-xs rounded`}
            content={content}
            iconClasses={"w-4 mr-2"}
            icon={filterIcon}
            onClick={onClick}
        />
    )
}

export const UnRoundedDwnButton = ({
    onClick=()=> {},
    icon=greyDownload,
    classes="",
    content=""
                            })=> {
    return (
        <IgemoButton
            defaultClasses="px-2 py-0.25 lg:px-4 lg:py-0.5"
            classes={`${classes} text-xs primary-text primary-border bg-white border-2 bg-primary`}
            iconClasses="w-2 h-4 lg:w-3 lg:h-6 mr-2"
            icon={icon}
            onClick={onClick}
            content={content}
        />
    )
}

export const UnRoundedDwnButton2 = ({
    onClick=()=> {},
    icon=greyDownload,
    classes="",
    content=""
                            })=> {
    return (
        <IgemoButton
            defaultClasses="px-2 py-0.25 lg:px-4 lg:py-0.5"
            classes={`${classes} text-xs border-white border-2 border-white text-[#000] bg-[#fff] rounded`}
            iconClasses="w-2 h-4 lg:w-3 lg:h-6 mr-2"
            icon={icon}
            onClick={onClick}
            content={content}
        />
    )
}

export const RequestButton = ({
    hollow=false,
    onClick=()=> {},
    icon=undefined,
    classes="",
    content=""
                            })=> {
    return (
        <IgemoButton
            defaultClasses={`px-3 ${hollow ? 'py-0.5' : 'py-1'} lg:px-5 ${hollow ? 'lg:py-1.5' : 'lg:py-2'}`}
            classes={`${classes} text-xs ${hollow ? 'primary-text bg-white primary-border border-2 bg-primary' : 'primary-main secondary-text font-semibold'}`}
            icon={icon}
            onClick={onClick}
            content={content}
        />
    )
}

export const AddButton = ({
    hollow=false,
    onClick=()=> {},
    icon=plus,
    classes="",
    content=""
                            })=> {
    return (
        <IgemoButton
            defaultClasses={`px-3 ${hollow ? 'py-0.5' : 'py-1'} lg:px-5 ${hollow ? 'lg:py-1.5' : 'lg:py-2'}`}
            classes={`text-xs ${hollow ? 'primary-text primary-border bg-white border-2' : 'primary-main secondary-text font-semibold '} ${classes}`}
            icon={icon}
            iconClasses="w-[20px] h-[20px] primary-text mr-2"
            onClick={onClick}
            content={content}
        />
    )
}
