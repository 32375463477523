import HttpService from "../constants/HttpService";

class RenovationService extends HttpService {
    constructor() {
        super('renovation');
    }

    // // get all contractors-admin
    // getAllAdmin = async () => {
    //     const response = await this.client.get(`${this.endPoint}/admin/`)
    //     return response
    // }


}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RenovationService()
