import {Modal} from "antd";
import {Link} from "react-router-dom";
import React, {Dispatch, FC, SetStateAction} from "react";

import {PrimaryButton} from "../../shared/buttons/igemo_button";

interface ModalStruct {
    open: boolean
    toggleOpen: Dispatch<SetStateAction<boolean>>
}

const WarningPopup: FC<ModalStruct> = ({
    open,
    toggleOpen,
}) => {
    return (
        <Modal
            width="80%"
            open={open}
            title="Waarschuwing"
            className="no-modal-buttons"
            onOk={() => toggleOpen(false)}
            onCancel={() => toggleOpen(false)}
        >
            <div className="px-16 pt-8 text-center text-gray-500">
                <p>Tijdens het optimaliseren van uw huis is het voor sommige optimalisaties belangrijk dat deze in een bepaalde volgorde gebeuren.</p>
                <p>U hebt de optimalisatie 'hoofddak' niet toegevoegd aan u plan het is aangeraden deze verbetering uit te voeren alvorens u andere zaken optimaliseert.</p>
            </div>

            <div className="pt-4 mb-4 text-center">
                <PrimaryButton content = "Mijn profiel" />
            </div>

            <div className="mb-4 text-center">
                <Link to="/">
                    <p className="ml-2 text-igemo underline hover:underline cursor-pointer ">
                        terug naar de resultatenpagina
                    </p>
                </Link>
            </div>
        </Modal>

    )
};

export default WarningPopup;

