import { FC, useState } from 'react'
import HomeOwnerDocument from './HomeOwnerDocument'
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg"
import CheckTick from '../../shared/Table/CheckTick'
import OfferReqStatus from '../../shared/Table/OfferReqStatus'
import OfferRequestService from '../../api/OfferRequest'
import { Toggler } from '../../shared/hoc/Toggler'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { formatDate, formatDateTime } from '../../utils/helpers'
import { showWarningAfterDays } from '../../constants/general'
import WarningModal from '../../components/modals/warningModal'
import TabsModal from '../../shared/Table/DetailsModal'
import { HouseOwnerListingStatus } from '../../models/applicationState'
import { Space } from 'antd'
import { IconProp } from '@fortawesome/fontawesome-svg-core'


interface HomeContractorRowProps {
    item: any,
    toggleWarningPopup?: any,
    title: string,
    offerRequestId: any,
    renovationId: any,
    renovation?: any,
    offerRequestSendAt?: any,
    simulation?: any,
}

const HomeContractorRow: FC<HomeContractorRowProps> = ({ toggleWarningPopup, item, title, offerRequestId, renovationId, renovation, offerRequestSendAt, simulation }) => {
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showDocumentModal, setShowDocumentModal] = useState<boolean>(false)
    const [tabKey, setTabKey] = useState("1")

    const date = formatDate(item?.createdAt)

    // for warning icon
    const { statusId } = item
    const { statusUpdateAt } = item
    let showWarning = false

    if (statusUpdateAt && statusId === '1') {
         // Current date (with time)
         const currentDateWithTime = new Date();
         // Set the time part to midnight (00:00:00)
         const currentDate:any = new Date(currentDateWithTime.getFullYear(), currentDateWithTime.getMonth(), currentDateWithTime.getDate());
 
         // Date to subtract (with time)
         const dateToSubtractWithTime = new Date(statusUpdateAt);
         // Set the time part to midnight (00:00:00)
         const dateToSubtract:any = new Date(dateToSubtractWithTime.getFullYear(), dateToSubtractWithTime.getMonth(), dateToSubtractWithTime.getDate());
 
         // Calculate the time difference in days
         const timeDifferenceInMilliseconds = currentDate - dateToSubtract;
         const timeDifferenceInDays = timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24);
         if(timeDifferenceInDays>10){
             showWarning = true;
         }
    }
    const contractorId = item.contractorId

    const handleMentorship = async () => {
        try {
            const payload = {
                mentorship: !item.mentorship,
                offerRequestId,
                renovationId,
                contractorId
            }
            await OfferRequestService.updateOfferRequestContractor(offerRequestId, payload)
        } catch (error) {
        }
    }
    const handleGroupPurchase = async () => {
        try {
            const payload = {
                groupPurchase: !item.groupPurchase,
                offerRequestId,
                renovationId,
                contractorId
            }
            await OfferRequestService.updateOfferRequestContractor(offerRequestId, payload)
        } catch (error) {
        }
    }

    return (<>
        <tr className='border-0 border-b relative'>
            <td className='px-5 py-2 text-center items-center'>
                <div className='flex gap-1 align-middle'>
                    <img src={renovation?.logo} alt='' className='h-8 mt-1 object-contain'/>
                    <p className="mt-1 ml-1 font-semibold">{renovation?.title}</p>
                    <FontAwesomeIcon
                        icon={faEllipsisVertical as IconProp}
                        onClick={()=>setShowDocumentModal(true)}
                        className="w-5 h-5 mt-1  cursor-pointer"
                    />
                </div>
            </td>
            <td className='px-5 py-2 text-center items-center'>{item?.contractor?.name}</td>
            <td className='px-5 py-2 text-center items-center'>{date}</td>
            <td className='px-5 py-2'>
                <Toggler
                    initialStatus={item?.mentorship}
                    render={({ toggleStatus, status }) => (
                        <CheckTick isChecked={status} onClick={() => { toggleStatus(); handleMentorship() }} className='hover:cursor-pointer' />
                    )}
                />
            </td>
            <td className='px-5 py-2 text-center items-center'>
                <Toggler
                    initialStatus={item?.groupPurchase}
                    render={({ status, toggleStatus }) => (
                        <CheckTick isChecked={status} onClick={() => { toggleStatus(); handleGroupPurchase() }} className="hover:cursor-pointer" />
                    )}
                />
            </td>
            <OfferReqStatus id={item?.status?.id} className='cursor-pointer w-[20%]' statusUpdateAt={item?.statusUpdateAt} />
            <td className='pl-5 py-2 text-center items-center pr-2'>
                <Space direction='vertical'>
                    <HomeOwnerDocument 
                        item={item} 
                        offerRequestId={offerRequestId} 
                        setShowDocumentModal={setShowDocumentModal}
                        setTabKey={setTabKey}
                    />
                    {item?.status?.status === HouseOwnerListingStatus.houseVisitPlanned && (
                        <p className='text-sm'>{formatDateTime(item?.visitPlanDateTime)}</p>
                        )}
                </Space>
            </td>
            {showWarning && <td className='absolute top-1/3 -right-16 pr-0 mr-7 cursor-pointer'><InfoIcon onClick={() => setShowWarningModal(true)} className='h-5 w-5' /></td>}
        </tr>
        {showDocumentModal && (
            <TabsModal
                title={renovation?.title}
                logo={renovation?.logo}
                open={showDocumentModal}
                selectedItem={[item]}
                toggleOpen={() => {setShowDocumentModal(!showDocumentModal); setTabKey("1")}}
                offerReqId={offerRequestId}
                renovationId={renovationId}
                tabKey={tabKey}
                simulation={simulation}
                simulationId={simulation?.id}
                renovationKey={renovation?.key}
                offerRequestSendAt={offerRequestSendAt}
            />
        )}
        {showWarningModal && (
            <WarningModal open={showWarningModal} setOpen={setShowWarningModal} />
        )}
        </>
    )
}

export default HomeContractorRow
