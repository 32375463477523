import React, { useState, FC } from "react";
import {flattenTree} from "react-accessible-treeview";

import {
    ReactComponent as ArrowRightCircleGreenEmpty
} from "../../../assets/icons/Icon arrow-cercle-right-green-empty.svg";
import OfferCompareStep2 from "./OfferCompareStep2";

interface cardProps {
    addedIds: any
    cardData: any
    setAddedIds: any
    contractorDetails: any
    notAppicableIds: any
    setNotApplicableIds: any
}


const OfferCompareCard: FC<cardProps> = ({
    cardData,
    addedIds,
    setAddedIds,
    contractorDetails,
    notAppicableIds,
    setNotApplicableIds,
  }) => {
    const [showBody, toggleShowBody] = useState(false);
    const [formStep, setFormStep] = useState(cardData?.renovation?.renovationServices[0]?.renovationServicesGroup?.services[0]?.id);

    const getSelectedTabData = () => {
      const currentData = cardData?.renovation?.renovationServices[0]?.renovationServicesGroup?.services?.find((f: any) => f.id === formStep);
      const treeData = currentData? flattenTree(currentData):[];
      return treeData;
    };

    return (
        <div className="py-4 px-4 w-full bg-[#0000000a] mb-8">
            <div className="flex cursor-pointer" onClick={() => toggleShowBody(!showBody)}>
                <div className="flex-row mt-1 cursor-pointer">
                    <div className="h-3 w-4 primary-fill">
                        <ArrowRightCircleGreenEmpty height={24} width={24} />
                    </div>
                </div>
                <div className='ml-6 font-semibold whitespace-nowrap text-[20px]'>{cardData.renovation.title}</div>
            </div>
            {showBody && (
                <div className="px-6">
                    <div style={{ padding: '0 0 15px', marginTop: 10}}>
                        {cardData?.renovation?.renovationServices[0]?.renovationServicesGroup?.services?.map((s: any) => {
                            return (
                                <span
                                    className={`px-2 py-2 cursor-pointer ${formStep === s.id ? "primary-text primary-border border-b-[3px]" : "text-[#00000040]"}`}
                                    onClick={() => setFormStep(s.id)}
                                >
                                    {s.name}
                                </span>
                            )
                        })}
                    </div>

                    <OfferCompareStep2
                        rawServices={cardData?.renovation?.renovationServices[0]?.renovationServicesGroup?.services}
                        setNotApplicableIds={setNotApplicableIds}
                        contractorDetails={contractorDetails}
                        servicesList={getSelectedTabData()}
                        notAppicableIds={notAppicableIds}
                        setAddedIds={setAddedIds}
                        addedIds={addedIds}
                        formStep={formStep}
                    />
                </div>
            )}
        </div>
    )
};

export default OfferCompareCard;
