import React, { useEffect, useState, useContext, useMemo } from 'react'
import InfiniteScroll from "react-infinite-scroll-component";

import Layout from '../../components/layout'
import RequestQuoteList from './RequestQuoteList';
import { HouseOwnerListingTypeColors } from '../../models/applicationState';
import UserService from "../../api/User";
import { GlobalContext } from "../../context/globalContext/globalContext";
import OfferRequestService from "../../api/OfferRequest";
import Search from '../../shared/Search';
import { Space } from 'antd';
import filterIcon from "../../assets/icons/filter-results-button.png";
import axios from 'axios';
import Filter from '../../shared/filter/Filter';
import { AddHouseOwnerModal } from '../../components/modals/addHouseOwner';
import {concat, forEach, sortBy, uniqBy} from "lodash";
import { LuChevronRightCircle } from 'react-icons/lu';

const RequestQuoteListings = () => {
    const [homeOwnerList, setHomeOwnerList] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingArchived, setLoadingArchived] = useState(false)
    const [homeOwnerListArchived, setHomeOwnerListArchived] = useState([])
    const [searchQuery, setSearchQuery] = useState("")
    const [reload, setReload] = useState<boolean>(false)
    const [showModal, setShowModal] = useState(false);
    const [reloadArchive, setReloadArchive] = useState(false)
    const [openHouseOwnerForm, setOpenHouseOwnerForm] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [dataOffset, setDataOffset] = useState(0);
    const [idsWithRecords, setIdsWithRecords] = useState([]);
    const [selectedFiltersData, setSelectedFiltersData]: any = useState([
        {
            option1: "",
            option2: "=",
            option3: "",
        },
    ]);
    const [archiveState, setArchiveState] = useState(false)

    const { state, dispatch } = useContext(GlobalContext)
    const auth = JSON.parse(localStorage.getItem("auth") || "{}");

    const onlyCompletedFilters = useMemo(() => (
        selectedFiltersData?.filter((filter: any) => ![filter.option1, filter.option2, filter.option3].includes(""))
    ), [selectedFiltersData])

    useEffect(() => {
        let source = axios.CancelToken.source();

        getDataFromApi(0);

        return () => {
            source.cancel("Cancel request")
        }

    }, [searchQuery, selectedFiltersData, reload, dataOffset]);

    useEffect(() => {
        // load status
        (async () => {
            if (!state?.offerAllStatus) {
                const data = await OfferRequestService.loadOfferRequestsAllStatus()
                dispatch({ type: "SET_OFFER_REQUEST_ALL_STATUS", payload: data })
            }
        })();
    }, [])

    useEffect(() => {
        let source = axios.CancelToken.source();

        const userId = auth.uid
        const payload = {
            query: searchQuery,
            filters: onlyCompletedFilters,
            offset: dataOffset,
            houseOwnerIds: idsWithRecords.join(','),
        }

        setLoadingArchived(true)
        UserService.loadAllArchivedHomeOwnersByUserId(userId, payload, { cancelToken: source.token })
            .then(({ data }) => {
                setHomeOwnerListArchived(data.homeowner)
                setLoadingArchived(false)
            })
            .catch((error) => {
                console.log(error)
            })

    }, [reloadArchive])

    useEffect(() => {
        Object.keys(selectedUser).length > 0 && setOpenHouseOwnerForm(true);
    }, [selectedUser]);

    useEffect(() => {
        setSelectedUser({});
    }, []);

    const handleToggleProjectDetails = (cpSimulation: { id: string }) => {
        const newIdsToAdd: any = [...idsWithRecords];
        const index = newIdsToAdd.findIndex((f: any) => f === cpSimulation.id)
        if (index < 0) {
            const newIdsToAdd: any = [...idsWithRecords];
            newIdsToAdd.push(cpSimulation.id);
            setIdsWithRecords(newIdsToAdd);
        }
    };

    const getDataFromApi = (updatedOffset?: number) => {
        let source = axios.CancelToken.source();
        console.log(updatedOffset);
        const userId = auth.uid
        const payload = {
            query: searchQuery,
            filters: onlyCompletedFilters,
            // @ts-ignore
            offset: updatedOffset >= 0 ? updatedOffset : dataOffset,
            houseOwnerIds: idsWithRecords.join(','),
        }

        setLoading(true)
        // @ts-ignore
        if(updatedOffset <= state.total) {
            UserService.loadAllHomeOwnersByUserId(userId, payload, {cancelToken: source.token})
                .then(({data}) => {
                    // @ts-ignore
                    let initialData = updatedOffset > 0 ? state?.homeOwnerList : data.homeowner;
                    initialData = concat(initialData, data.homeowner);
                    // initialData = sortBy(initialData, 'id');
                    initialData = uniqBy(initialData, 'id');
                    // const newInitialData: any = [];


                    // forEach(data.homeownerSimulations, (f: any) => {
                    //     const index = initialData.findIndex((i: any) => i.id === f.id);
                    //     if (index > -1 && f.cpSimulations.length) {
                    //         initialData[index].cpSimulations = f.cpSimulations;
                    //         if(onlyCompletedFilters.length > 0 && f.cpSimulations.length > 0 && searchQuery.length > 0){
                    //             newInitialData.push(initialData[index]);
                    //         }else if(onlyCompletedFilters.length > 0 && f.cpSimulations.length > 0){
                    //             newInitialData.push(initialData[index]);
                    //         }
                    //     }
                    // });

                    if (updatedOffset) {
                        setDataOffset(updatedOffset);
                    }
                    setHomeOwnerList(initialData)
                    setLoading(false)
                    // load Archived
                    setReloadArchive(!reloadArchive)
                })
                .catch((error) => {
                    console.log(error)
                    setLoading(false)
                })
        }else{
            setLoading(false)
        }
    };

    const callFromScroll = () => {
        if (state?.homeOwnerList?.length < state?.total) {
            const updatedOffset = dataOffset + 10;
            if (!loading) {
                getDataFromApi(updatedOffset);
            }
        }
    }

    return (
        <Layout>
            <div className='px-4 py-4 md:px-8 lg:px-16 xl:px-32 md:py-8'>
                <div className="flex flex-col gap-4 md:flex-row justify-between relative">
                    <h2 className='text-2xl font-semibold'>Offerte Aanvragen</h2>
                    {/* <div className='h-7 mt-1.5 hover:cursor-pointer' onClick={hanleMainMenuIconClick} >
                        <MenuVerticalIcon height="inherit" width="inherit" />
                    </div> */}
                    <div className="flex items-center w-full md:w-4/12 gap-2 md:gap-0">
                        <div className="md:ml-auto md:mr-2 flex">
                            <Space>
                                <div
                                    className="relative bg-white rounded px-4 py-[9px] flex h-full items-center cursor-pointer"
                                    onClick={() => setShowModal(!showModal)}
                                >
                                    {onlyCompletedFilters?.length > 0 && (
                                        <div className="absolute -top-2 -left-2">
                                            <div className="bg-primary text-xs text-white rounded-full border-2 flex p-2.5 relative">
                                                <div className="absolute top-[2px] left-[6px] text-[12px]">
                                                    {onlyCompletedFilters?.length}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <img src={filterIcon} className="h-3" alt="" />
                                    <h2 className="px-3 text-[14px]">Filter</h2>
                                </div>
                            </Space>
                        </div>
                        {/* search input */}
                        <div className="w-full">
                            <Search setQuery={setSearchQuery} />
                        </div>
                    </div>
                </div>
                {/* filters  */}
                {showModal && (
                    <Filter
                        selectedFiltersData={selectedFiltersData}
                        setSelectedFiltersData={setSelectedFiltersData}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        user="contractor"
                    />
                )}
                <div className='mt-10 relative'>
                    {loading ? <div className='my-2'>Laden...</div> : null}
                    {homeOwnerList?.length ? (
                        <InfiniteScroll
                            hasMore={homeOwnerList?.length < state?.total}
                            next={callFromScroll}
                            loader={false}
                            dataLength={homeOwnerList?.length || 0}
                            scrollThreshold={0}
                            endMessage={
                                homeOwnerList?.length ? <p style={{ textAlign: 'center', paddingTop: 25 }}>
                                    Alle gegevens zijn geladen.
                                </p> : null
                            }
                        >
                            {homeOwnerList?.map((homeOwner: any, idx: number) => (
                                <RequestQuoteList
                                    setReload={() => setReload(!reload)}
                                    key={idx}
                                    tableTitle={`${homeOwner.firstName} ${homeOwner.lastName}`}
                                    data={homeOwner}
                                    listingType={HouseOwnerListingTypeColors.Normal}
                                    archive={false}
                                    setSelectedUser={setSelectedUser}
                                    handleToggleProjectDetails={() => handleToggleProjectDetails(homeOwner)}
                                />
                            ))}
                        </InfiniteScroll>
                    ) : (
                        <div>Geen offerte aanvragen gevonden.</div>
                    )}
                </div>
                <div className='mt-10 relative'>
                    {loadingArchived ? <div className='my-2'>Laden..</div> : null}
                    {homeOwnerListArchived?.length ? (
                        <div className = "flex items-center gap-2" onClick = {() => setArchiveState(!archiveState)}>
                            <LuChevronRightCircle size = "24px" className = {`primary-text cursor-pointer ${archiveState ? "rotate-0" : "rotate-90"}`} />
                            <div className = "text-white font-semibold whitespace-pre-line text-md bg-secondary w-fit shadow-md px-7 py-2 cursor-pointer">ARCHIEF</div>
                        </div>
                    ) : <></>}
                    <div className = "px-8 w-full">
                        {(homeOwnerListArchived?.length && archiveState) ? (
                            homeOwnerListArchived?.map((homeOwner: any, idx: number) => (
                                <RequestQuoteList
                                    setReload={() => setReload(!reload)}
                                    key={idx}
                                    tableTitle={`${homeOwner.firstName} ${homeOwner.lastName}`}
                                    data={homeOwner}
                                    initialStatus={false}
                                    listingType={HouseOwnerListingTypeColors.Archive}
                                    archive={true}
                                    setSelectedUser={setSelectedUser}
                                    handleToggleProjectDetails={() => handleToggleProjectDetails(homeOwner)}
                                />
                            ))
                        ) : (
                            // <div className="mt-4">No archived request quotes data found.</div>
                            <></>
                        )}
                    </div>
                </div>
                {openHouseOwnerForm ? (
                    <AddHouseOwnerModal
                        open={openHouseOwnerForm}
                        setOpen={setOpenHouseOwnerForm}
                        setSelectedUser={setSelectedUser}
                        selectedUser={selectedUser}
                        reload={reload}
                        setReload={setReload}
                        readOnly={true}
                        addresses={[
                            { label: "ADRES1", value: "address1" },
                            { label: "ADRES2", value: "address2" },
                        ]}
                    />
                ) : null}
            </div>
        </Layout>
    )
}

export default RequestQuoteListings
