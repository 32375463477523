import React, {FC} from "react";
import {useParams} from "react-router-dom";

import File1 from "./File1";
import File2 from "./File2";
import File3 from "./File3";
import File4 from "./File4";
import File5 from "./File5";
import Layout from "../../../components/layout";

interface documentStruct {}

interface ParamTypes {
    id: string
}

const PDFFiles: FC<documentStruct> = ({
}) => {
    // @ts-ignore
    const { id } = useParams<ParamTypes>();

    return (
        <Layout>
            <div className="container mx-auto px-4 mb-16 pt-4">
                {id === '1' && (
                    <File1 />
                )}

                {id === '2' && (
                    <File2 />

                )}

                {id === '3' && (
                    <File3 />
                )}

                {id === '4' && (
                    <File4 />
                )}

                {id === '5' && (
                    <File5 />
                )}

            </div>
        </Layout>
    )
};

export default PDFFiles;

