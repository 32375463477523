import React, { FC } from 'react'
import CheckCircle from '../elements/CheckCircle'
import { Toggler } from '../hoc/Toggler'

interface CheckTickProps {
    isChecked: Boolean,
    onClick?: React.MouseEventHandler,
    className?: string,
}

const CheckTick: FC<CheckTickProps> = ({ isChecked, onClick = () => { }, className }) => {

    return (
        <CheckCircle status={isChecked} onClick={onClick} className={className} />
    )
}

export default CheckTick