import React, { useState } from "react";
import TermsAndCondition from "./termsAndConditions";
import CookiePolicy from "./cookiePolicy";

type PolicyProps = {};

const Policy = () => {
  const [activeTabs, setActiveTab] = useState("terms");
  return (
    <div className="bg-white w-full flex items-center justify-center">
      <div className="w-full">
        {/* header */}
        <div className="flex justify-center w-full">
          <div className="flex justify-around w-10/12 py-6 px-4">
            <div className="">
              <h2
                className={`font-semibold px-6 cursor-pointer ${
                  activeTabs === "terms" ? "primary-text" : "text-[#c9c9d0]"
                }`}
                onClick={() => setActiveTab("terms")}
              >
                Terms & Conditions
              </h2>
            </div>
            <div className="">
              <h2
                className={`font-semibold px-6 cursor-pointer ${
                  activeTabs === "cookie" ? "primary-text" : "text-[#c9c9d0]"
                }`}
                onClick={() => setActiveTab("cookie")}
              >
                Cookie Policy
              </h2>
            </div>
          </div>
        </div>
        {/* body */}
        <div className="py-6 px-6">
          {/* title */}
          <div className="flex items-center">
            {/* <div className="w-[5px] h-[22px] bg-primary"></div> */}
            <h2 className="text-xl md:text-2xl xl:text-2xl  2xl:text-4xl font-bold border-l-8	primary-border pl-2">
              {activeTabs === "terms" ? "Terms & Conditions" : "Cookie policy"}
            </h2>
          </div>
          {/* content */}
          {activeTabs === "terms" ? <TermsAndCondition /> : <CookiePolicy />}
        </div>
      </div>
    </div>
  );
};
export default Policy;
