import HttpService from "../constants/HttpService";
import { UpdateOfferRequestContractor } from "../models/applicationState";

class OfferRequestService extends HttpService {
    constructor() {
        super('offer/request');
    }

    // additional services

    ladOfferRequest = async (id: String) => {
        const { data } = await this.client.get(`${this.endPoint}/list/${id}`)
        return data
    }

    findOne = async (id: String) => {
        const { data } = await this.client.get(`${this.endPoint}/select-contractors/${id}`)
        return data
    }

    findOneHouseOwner = async (id: String) => {
        const { data } = await this.client.get(`${this.endPoint}/select-houseowner/${id}`)
        return data
    }
    archivedFindOne = async (id: String) => {
        const { data } = await this.client.get(`${this.endPoint}/archived-contractors/${id}`)
        return data
    }
    archivedHouseOwner = async (id: String) => {
        const { data } = await this.client.get(`${this.endPoint}/archived-houseowner/${id}`)
        return data
    }

    loadOfferRequestsAllStatus = async () => {
        const { data } = await this.client.get(`${this.endPoint}/status`)
        return data
    }

    updateOfferRequestContractor = async (offerId: string, payload: UpdateOfferRequestContractor) => {
        // update paylaod to add frontend URL
        const _payload = {
            ...payload,
            url: window.location.origin
        }
        const { data } = await this.client.put(`${this.endPoint}/contractor/update/${offerId}`, _payload)
        return data
    }

    updateRenovationContractorById = async (offerRequestRenovationContractorId: string, payload: UpdateOfferRequestContractor) => {

        const { data } = await this.client.put(`${this.endPoint}/contractor/update-offerRequestRenoContractor/${offerRequestRenovationContractorId}`, payload)
        return data
    }
    deleteItem = async (id: String) => {
        const response = this.client.delete(`${this.endPoint}/offer-request-renovation-contractor/${id}`)
        return response
    }




}

// eslint-disable-next-line import/no-anonymous-default-export
export default new OfferRequestService()
