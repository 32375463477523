import React, {FC} from 'react';
import {Select} from 'antd';


interface dropDown {
    onChangeHandler: (event: React.ChangeEventHandler) => void,
    options: Array<object>
    selectedOption: string | number
    name?: string
    style?: object,
    readonly?: boolean,
    isRequired?: number,
    full?: boolean,
    label?: string,
    outerError?: string
}

export const DropDown: FC<dropDown> = ({
    onChangeHandler=()=> {},
    options,
    selectedOption,
    name,
    style,
    readonly=false,
    isRequired=0,
    full=false,
    label,
    outerError
}) => {
    return (
        <div className={` text-xs ${full ? 'w-full' : null}`}>
            {label? (
                <p className={`font-semibold ${label === 'Email' ? 'text-primary' : 'text-secondary'} text-left mb-1 text-xs`}>{label} <span className='text-error'>{isRequired===1 ?"*":""}</span></p>
            ): null}
            <Select
    // @ts-ignore
            defaultValue={selectedOption}
            notFoundContent = "geen gegevens"
            style={{ ...style }}
            onChange={onChangeHandler}
            options={options}
            name={name}
            {...(readonly?{value: selectedOption}: {})}
            />
        <p className="text-error text-xs text-start ml-2">
            {outerError ?
                outerError
                :
                ''
            }
            </p>
        </div>
        
    )
}
