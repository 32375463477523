import React, { MouseEventHandler } from "react"
import contractorTab from "../containers/houseOwner/ContractorTab";
import { COLORS } from "../constants/ui";

export interface HeaderContentInterface {
    type: any,
    text: String,
    showMenu?: Boolean,
    handleMenuClick?: MouseEventHandler,
    handleDetailsClick?: any,
}

export interface CheckBoxInterface {
    status?: boolean | undefined,
    disabled?: boolean | undefined,
    name: string,
    content?: String | JSX.Element | undefined
    onChange?: React.ChangeEventHandler<HTMLInputElement>,
    readOnly?: boolean,
    defaultStatus?: boolean,
}

export interface CheckBoxInterfaceModal {
    status: Boolean,
    name: string,
    defaultChecked: boolean,
    content?: String | JSX.Element | undefined
    onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export enum StatusTypes {
    "Offerte aangevraagd" = "Offerte aangevraagd",
    "Huisbezoek ingepland" = "Huisbezoek gepland",
    "Aangevraagd" = "Offerte aangevraagd",
    "Niet toegekend" = "Niet toegekend",
}

export interface HomeOwnerDocumentInterface {
    offerRequest?: cpOfferRequestRenovationContractorsInterface,
    client?: string,
    address?: string,
    title?: string,
    setReload?: any
    setShowUploadModal?: any,
    item?: any,
    renovaiton?: any,
    archive?:boolean,
    offerRequestId?:any,
    setTabKey: any,
    setShowDocumentModal: any,
}

export enum HouseOwnerListingTypeColors {
    "Normal" = "white",
    "Heating" = "primary",
    "Archive" = "secondary",
}

export interface TableTitleInterface {
    title: String,
    mainBurgerClickHandler: any,
    children?: JSX.Element[] | JSX.Element | undefined,
    subtitle?: String,
    showIcon?: Boolean,
    initialStatus?: Boolean,
    listingType: HouseOwnerListingTypeColors,
    headings?: String[],
    tableClasses?: String,
    tableTitleClasses?: String,
    wrapInTable?: Boolean,
    contractorStatus?: Boolean,
    showContractorStatus?: Boolean,
    onContractorStatusChange?: (status: Boolean) => void,
    asyncCallBack?: () => Promise<void>,
    handleToggleStatus?: (cpSimulation: any) => void,
    hideEllipsis?:boolean,
    showDetails?:boolean,
    handleClickDelete?: any
}

export interface HeadingsInterface {
    DATUM: String,
    BEGELEIDING: String,
    GROEPSAANKOOP: String,
    STATUS: String,
    DOCUMENTEN: String,
    ACTIES?: String,
    AANNEMERS?: String,
}

export interface TableInterface {
    title: String | JSX.Element,
    headings?: String[] | undefined,
    toggleShowBody?: React.MouseEventHandler,
    showBody?: Boolean | undefined | null
}

export interface CertificateProp {
    image: string,
}

export interface ContractorProp {
    statusId: string,
    image: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    contractor: ContProp,
    certificates: CertificateProp[],
}

export interface usersProp {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
}
export interface ContractorUsersProp {
    contractorId: string,
    userId: string,
    id: string,
    user: usersProp,
}

export interface ContProp {
    status: number,
    id: string;
    description: number,
    logo: string,
    name: number,
    email: number,
    phone: number,
    vat: string,
    users: usersProp[],
    cpContractorUsers:ContractorUsersProp[],
    cpContractorCertificats: certProps[],
    cpContractorLabels: lableProps[],
}

export interface certProps {
    certificat: certProps2
}

export interface certProps2 {
    logo: any
}

export interface lableProps {
    label: lableProps2
}

export interface lableProps2 {
    logo: any
}



export interface TableData {
    TYPE: String,
    HEAD: String,
    DATUM: String,
    BEGELEIDING: Boolean,
    GROEPSAANKOOP: Boolean,
    STATUS: HouseOwnerListingStatus,
    DOCUMENTEN: String,
    documents?: string[],
    contractors?: ContractorProp[],
}

export interface TableResponse {
    title: String,
    data: TableData[],
}

export interface StatusProps {
    status: HouseOwnerListingStatus,
}

export interface AddressItem {
    address: String,
    creationDate: String
    description?: String | undefined
}
export interface CardProps {
    item: AddressItem,
    onClick: React.MouseEventHandler<HTMLButtonElement>,
    btnText: String,
}

export type SimulationAddress = Array<AddressItem>

// TODO: update with offer requests
export enum HouseOwnerListingStatus {
    "offerRequested" = "Offerte aangevraagd",
    "houseVisitPlanned" = "Huisbezoek ingepland",
    "offerUploaded" = "Offerte opgeladen",
    "notAssigned" = "Niet toegekend",
    "offerAccepted" = "Offerte aanvaard",
    "billAdded" = "Factuur toegevoegd",
}

export const HouseOwnerListingStatusColors = {
    "Offerte aangevraagd": COLORS.offer_requested_state,
    "Huisbezoek ingepland": COLORS.home_visit_state,
    "Offerte opgeladen": COLORS.quote_received_state,
    "Niet toegekend": COLORS.un_assigned_state,
    "Offerte aanvaard": COLORS.offer_accepted_state,
    "Factuur toegevoegd": COLORS.bill_added_state,
}

export interface OfferRequestRow {
    HEAD: String,
    CONTRACTOR: String,
    CONTRACTOR_EMAIL: String,
    STATUS: HouseOwnerListingStatus,
    DOCUMENTEN: string,
}

export interface OfferRequestListingsResponse {
    title: String,
    data: OfferRequestRow[],
}

export enum UserTypes {
    "contractor",
    "requetQuote",
    "houseOwner",
}

export enum ContractorStatusEnum {
    true = "active",
    false = "inactive",
}

export interface ContractorInterface {
    "id": string,
    "logo": string,
    "name": string,
    "contact": null | string,
    "email": string,
    "phone": string,
    "vat": string,
    "cityId": string | null,
    "streetId": string | null,
    "address1": string | null,
    "address2": string | null,
    "description": string | null,
    "status": boolean,
    "createdAt": Date | string | null,
    "updatedAt": Date | string | null,
    "deletedAt": Date | string | null,
    "city": string | null,
    "street": string | null,
    "users": UserInterface[],
    "cpContractorWorkplaces": [],
    "cpOfferRequestRenovationContractors": cpOfferRequestRenovationContractorsInterface[],
    "cpContractorCertificats": [],
    "cpContractorLabels": [],
    "cpContractorRenovations": []
}

export interface UserInterface {
    "id": String,
    "typeId": String,
    "companyId": String,
    "firstName": String,
    "lastName": String,
    "email": String,
    "password": String,
    "phone": String,
    "newsletter": String,
    "createdAt": String,
    "updatedAt": String,
    "deletedAt": String
}

export interface cpOfferRequestRenovationContractorsInterface {
    "id": String,
    "offerRequestRenovationId": String,
    "contractorId": String,
    "statusId": String,
    "groupPurchase": number,
    "mentorship": number,
    "archiveContractor": number,
    "archiveRequestor": number,
    "selected": String,
    "createdAt": String,
    "updatedAt": String,
    "deletedAt": String,
    "offerRequestRenovation": any
}

export interface City {
    "id": string,
    "regionId": string,
    "en": null | string,
    "de": null | string,
    "nl": null | string,
    "fr": null | string,
    "zipCode": number,
    "createdAt": string,
    "updatedAt": null | string,
    "deletedAt": null | string,
}
export interface Street {
    "id": string | null,
    "number": string | null,
    "box": number | null,
    "numberbox": null | number,
    "en": string | null,
    "de": string | null,
    "nl": string | null,
    "fr": string | null,
    "zipCode": number,
    "createdAt": string | null,
    "updatedAt": string | null,
    "deletedAt": string | null
}

export interface UpdateOfferRequestContractor {
    "renovationId"?: number |any,
    "contractorId"?: number |any,
    "groupPurchase"?: boolean,
    "mentorship"?: boolean,
    "archiveRequestor"?: boolean,
    "archiveContractor"?: boolean,
    "statusId"?: number |any,
    "visitPlanDateTime"?: string,
}

export type ServiceAnswerType = {
    createdAt: string,
    deletedAt: string | null,
    id: string,
    key: string,
    newAnswer: number,
    newAnswerText: string,
    originalAnswer: number,
    originalAnswerText: string,
    sesssionId: string,
    updatedAt: string | null,
}
