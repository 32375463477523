import { FC } from "react"
import {Form} from "antd";
import { CheckBoxInterface } from "../../models/applicationState"

export const CheckBox: FC<CheckBoxInterface> = ({ disabled, content, status, name, onChange, readOnly, defaultStatus }) => (
    <label className="inline-flex items-center">
        <Form.Item name={name}>
            <input disabled={disabled} name={name} value={name} {...(readOnly ? {checked: defaultStatus} : {defaultChecked: defaultStatus})} onChange={onChange} type="checkbox" className="w-4 h-4 rounded-full text-light_green" />
        </Form.Item>
        <span className="ml-2 mt-[-5px] text-secondary text-left text-sm">{content}</span>
    </label>
)
