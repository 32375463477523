
import React, { useState, useEffect, useMemo } from "react";

import { epc, epcMobile } from '../../components/result/epc';
import { getObjValuesSum,getOppervlakteKey,getOppervlakteId } from "../../utils/reultHelpers";
import { getCalculationsBar } from "../../utils/resultActions";
import './bar.css'
import {AddEmail} from "../../components/modals/addEmail";
type BarProps = {
  resultLabelData: any,
 renderUpdate:any
  setPdfDataUpdate:any
  pdfHtml:any
}

const EpcBar = ({ resultLabelData, renderUpdate, setPdfDataUpdate,pdfHtml }: BarProps) => {
  // const {
  //     result: { heatLossData, actualEpcLabel, ...overviewValues },
  //   } = useSelector((state:any) => state)
  let bespaarValue = JSON.parse(localStorage.getItem('Bespaar')!)
  // const [heatLossData, settHeatLossData] = useState<any>({});
  const [result, setResult] = useState<any>({})
  const [actualEpcLabel, setActualEpcLabel] = useState("")
  //@ts-ignore
  const [overviewValues, setOverviewValues] = useState({ bespaar: 0 })
  //@ts-ignore
  // const [overviewValues, setOverviewValues] = useState({ bespaar: 0 })
  const [epcVal, setEpcVal] = useState<string | undefined>(undefined)
  let localState =  JSON.parse(`${localStorage.getItem('localState')}`)
  let newState =  JSON.parse(`${localStorage.getItem('newState')}`)
  let standardDimensions =  JSON.parse(`${localStorage.getItem('standardDimensions')}`)

  const [actualEpc, setActual] = useState({value:0,label:"F"})
  const [newEpc, setNew] = useState({value:0,label:"F"})

  useEffect(() => {

    getCalculationsBar(
      localState,
      newState,
      setResult
    )


  }, [renderUpdate])
  useEffect(() => {

    console.log("bar result ==>",Object.keys(result).length)
    
    if(result != undefined && Object.keys(result).length > 0 ){
      const opperKey = getOppervlakteKey(localState,standardDimensions[0])
      const area = standardDimensions[1][opperKey];
      let actualData = (result.current.energyUseGas + result.current.energyUseElec +  +result.current.energyUseZonnePannelen)
      let newData = (result?.newMeasures.adjustedScaledGas + result.newMeasures.adjustedScaledElec + result.newMeasures.adjustedScaledZonnePannelen)

      let actualEpcValue = actualData / area
      let newEpcValue = newData / area
      // const oppervlakteId = getOppervlakteId(carddata?.title,pageNo);
      console.log("bar result ==>",area, actualData,newData)

      console.log("actualEpcValue ==>",actualEpcValue)
      console.log("newEpcValue ==>",newEpcValue)

        const epcSpans = [
    { className: 'F', minValue: 500,maxValue:10000 },
    { className: 'E', minValue: 400,maxValue:499 },
    { className: 'D', minValue: 300,maxValue:399 },
    { className: 'C', minValue: 200,maxValue:299 },
    { className: 'B', minValue: 100,maxValue:199 },
    { className: 'A', minValue: 0,maxValue:99 },
    { className: 'A+', minValue: -100,maxValue:-10000 },
    ]


    let actualLabel = epcSpans.filter(data => actualEpcValue >= data.minValue  && actualEpcValue <=  data.maxValue )
    let newLabel = epcSpans.filter(data => newEpcValue >= data.minValue  && newEpcValue <=  data.maxValue )

    setActual({value:actualEpcValue,label:actualLabel[0].className})
    setNew({value:newEpcValue,label:newLabel[0].className})
      // getEPCLabels(
      //   'D',
      //   actualEpcValue,
      //   'D',
      //   newEpcValue,
      //   'sameLabel',
      // )

    }

  }, [result])
  // useEffect(() => {
  //   if (buildingData.length > 0) {
  //     let heatLoss: any = localStorage.getItem('SET_HEAT_LOSS_DATA');
  //     let actual: any = localStorage.getItem('SET_ACTUAL_EPC_LABEL');
  //     heatLoss = JSON.parse(heatLoss)
  //     if (heatLoss) {
  //       settHeatLossData(heatLoss);
  //       setEpcVal(heatLoss.primairEnergieverbruikPerM2);
  //     }
  //     if (actual) {
  //       setActualEpcLabel(actual)
  //     }
  //   }

  // }, [buildingData, heatLossDataUpdate])
  // const [state, setState] = useState({
  //     buildingData: [],
  //   })
  //   const {
  //     infoCard,
  //     adviceCard,
  //     emailCard,
  //     savingCard,
  //     improvementCard,
  //     improvementModal,
  //     restartCard,
  //     disclaimerCard,
  //   } = useMemo(() => resultLabelData, [resultLabelData])
  // useEffect(() => {
  //   getCall()
  //   localStorage.setItem('split', JSON.stringify(false))
  //   localStorage.setItem('pagesEnergyElec',JSON.stringify(0))
  //   localStorage.setItem('pagesEnergyGas',JSON.stringify(0))
  // }, [])

  // useEffect(() => {
  //   if(state.buildingData.length > 0 ){

  //   }
  // }, [state])
  // const getCall = () => {
  //     axios.get(`${process.env.REACT_APP_BACKEND_URL}/dimensions`).then((response) => {
  //       setState((st) => ({
  //         ...st,
  //         buildingData: response.data,
  //       }))
  //     })
  //   }

  function stickToValidPercentage(value: number) {
    if (value < 0) {
      return 0
    }
    if (value > 100) {
      return 100
    }
    return value
  }
  // const epcSpans = [
  //   { className: 'F', minValue: 500 },
  //   { className: 'E', minValue: 400 },
  //   { className: 'D', minValue: 300 },
  //   { className: 'C', minValue: 200 },
  //   { className: 'B', minValue: 100 },
  //   { className: 'A', minValue: 0 },
  //   { className: 'A+', minValue: -100 },
  // ]
  const epcSpans = [
    { className: 'F', minValue: 500, right: "90%" },
    { className: 'E', minValue: 400, right: "75%" },
    { className: 'D', minValue: 300, right: "60%" },
    { className: 'C', minValue: 200, right: "45%" },
    { className: 'B', minValue: 100, right: "30%" },
    { className: 'A', minValue: 0, right: "15%" },
    { className: 'A+', minValue: -100, right: "0%" },
  ]

  // useEffect(() => {
  //   if (state.buildingData.length > 0)
  //       setHeatLossData(
  //         state.buildingData,
  //         true,
  //         // settHeatLossData
  //       );

  // }, [state.buildingData])


  const bespaarVal = useMemo(() => getObjValuesSum(bespaarValue), [
    bespaarValue
  ])
  // setOverviewValues(bespar :bespaarVal)


  // useEffect(() => {
  //   if (heatLossData?.primairEnergieverbruikPerM2) {
  //     setEpcVal(heatLossData.primairEnergieverbruikPerM2)
  //   } else {
  //     setEpcVal("")
  //   }
  // }, [actualEpcLabel])




  function getEPCLabels(category: any, value: any, catActual: any, valueActual: any, status: any) {
    console.log("getEPCLabels",status)

    if (catActual == '' && valueActual == '') {
      return (
        <>
          {epcSpans.map((span, index) => {
            // console.log(span,'span')
            let epcCal = span.className === category ? value - span.minValue : 0;      
            const right = stickToValidPercentage(epcCal)      
            const className = span.className === category ? 'active col-span-1' : 'inactive col-span-1'
            return (
              <span className={className} key={`${value}-${index.toString()}`} style = {{right: span.right}}>
                <span style={{ right: `${right}%` }} className="span-value">
                </span>
              </span>
            )
          })}
        </>
      )
    }
    if (status == 'sameLabel') {
      return (
        <>
          {epcSpans.map((span, index) => {

            const right = stickToValidPercentage(
              span.className === category ? value - span.minValue : 0,
            )
            const right1 = stickToValidPercentage(
              span.className === catActual ? valueActual - span.minValue : 0,
            )
            const className = span.className === category ? 'active col-span-1' : 'inactive col-span-1'
            const spanClassName =
              span.className === category ? 'span-value' : 'span-value-update'

            const spanClassName1 =
              span.className === catActual ? 'span-value-update ' : 'span-value'

              
            return (
              <span className={className} key={`${value}-${index.toString()}`} >
                {right1 > 0 ? (
                  <>
                    <span
                      style={{ right: `${right1}%` }}
                      className={spanClassName1}
                    />
                    <span
                      style={{ right: `${right}%` }}
                      className={spanClassName}
                    />
                  </>
                ) : (
                  <span
                    style={{ right: `${right}%` }}
                    className={spanClassName}
                  />
                )}
              </span>
            )
          })}
        </>
      )
    }
    return (
      <>
        {epcSpans.map((span, index) => {
          const right = stickToValidPercentage(
            span.className === category
              ? value - span.minValue
              : span.className === catActual
                ? valueActual - span.minValue
                : 0,
          )
          const className =
            span.className === category
              ? 'active col-span-1'
              : span.className === catActual
                ? 'active col-span-1'
                : 'inactive col-span-1'
          const spanClassName =
            span.className === category
              ? 'span-value'
              : span.className === catActual
                ? 'span-value-update'
                : 'span-value'

          return (
            <span className={className} key={`${value}-${index.toString()}`}>
              <span style={{ right: `${right}%` }} className={spanClassName}>
                {/* <strong>{`${value} kWh/m²`}</strong> */}
              </span>
            </span>
          )
        })}
      </>
    )
  }

  return (
//@ts-ignore
    <div className="bg-white w-full h-full flex items-center justify-center">
      <div className=" w-full mx-auto flex-col justify-center">
        <div className="flex flex-col w-full justify-center">
          <div className="w-full flex-col flex-col overflow-hidden">
            {/* {heatLossData.kleurenbalk ? ( */}
             <div className="flex w-full justify-center">
             <div className="heat-loss-wrapper w-full md:w-auto box-border pt-[16px]">
             {/* {actualEpcLabel === heatLossData.label ? ( */}
               { actualEpc.value !== newEpc.value? (

                   actualEpc.label !== newEpc.label  ? (

                   <h4
                     className="font-Source font-bold text-[14px] md:text-[21px] text-black text-center"
                     dangerouslySetInnerHTML={{
                     __html: `${`${resultLabelData?.epcCard?.epc_img_title} ${actualEpc.label}, ${resultLabelData?.epcCard?.epc_img_title_mid_text}  ${newEpc.label} ${resultLabelData?.epcCard?.epc_img_title_last_text}`}`,
                     }}
                   />
                 ) : (
                   <h4
                     className="font-Source font-bold text-[14px] md:text-[21px] text-black text-center"
                     dangerouslySetInnerHTML={{
                       __html: `${`${resultLabelData?.epcCard?.epc_img_title} ${actualEpc.label}, ${resultLabelData?.epcCard?.epc_img_title_current_text} ${newEpc.label} `} `,
                     }}
                   />
                 )
               ) : (
                 <h4
                   className="font-Source font-bold text-[14px] md:text-[21px] text-black text-center"
                   dangerouslySetInnerHTML={{
                     __html: `${resultLabelData?.epcCard?.epc_img_title} ${actualEpc.label}`,
                   }}
                 />
               )}
               <div className = "w-full overflow-x-auto">
                 <div className="epc-container w-[1000px] md:w-auto">
                   <div className="epc-label grid grid-cols-7 gap-1">
                     {
                     
                     actualEpc.value !== newEpc.value ?
                 
                     actualEpc.label !== newEpc.label ? 
                       getEPCLabels(
                         newEpc.label,
                         newEpc.value,
                         actualEpc.label,
                         actualEpc.value,
                         '',
                       )
                       : getEPCLabels(
                         newEpc.label,
                         newEpc.value,
                         actualEpc.label,
                         actualEpc.value,
                         'sameLabel',
                       )
                       : getEPCLabels(
                         actualEpc.label,
                         actualEpc.value,
                         '',
                         '',
                         '',
                       )}
                   </div>
                   <div className="result-img">
                     {epc}
                     {/* {epcMobile} */}
                   </div>
                 </div>
               </div>

               <hr />
               {bespaarVal > 0 ? (
                 <strong
                   style={{ float: 'left' }}
                   dangerouslySetInnerHTML={{
                     __html: `${resultLabelData?.epcCard?.epc_left_calculation_text
                       } ${resultLabelData?.epcCard?.epc_calculation_currency
                       } ${Math.round(bespaarVal)} / ${resultLabelData?.epcCard?.epc_left_calculation_unit
                       }`,
                   }}
                 />
               ) : null}
               <br style={{ clear: 'both' }} />
             </div>
           </div>
          
            {/* <div className='flex w-full justify-end mb-[25px]'>
              <button type='button' onClick={() => {setOpenEmailForm(true); setPdfDataUpdate(true)}} className="md:h-[60px] w-[300px] mt-[3.5] ml-5 flex items-center justify-center bg-white border-4 border-[#494949] transition ease-in-out hover:-translate-y-1 hover:scale-110 text-gray-900 bg-white border-2 border-black hover:bg-black hover:text-white focus:ring-4 focus:ring-igemo-accent font-semibold px-5 py-3 mr-8">
                <p className="font-Source font-bold text-[18px] text-[#494949]">Ontvang je plan via e-mail</p>
              </button>
            </div> */}
          </div>
          {/* {epcMobile} */}{/* {epcMobile} */}
          {/* <AddEmail open={openEmailForm} setOpen={setOpenEmailForm} pdfHtml={pdfHtml}/> */}
        </div>
      </div>
    </div>

  )
};
export default EpcBar;