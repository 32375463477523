import HttpService from "../constants/HttpService";

class SuperAdminService extends HttpService {
    constructor() {
        super('super-admin');
    }

    getAdminList = async () => {
        const { data } = await this.client.get(`${this.endPoint}`)
        return data
    }

    getAdminDetails = async (id: string) => {
        const { data } = await this.client.get(`${this.endPoint}/show/${id}`)
        return data
    }

    createNewAdmin = async (payload: any, others: any) => {
        const { data } = await this.client.post(`${this.endPoint}`, payload, {...others})
        return data
    }

    updateAdmin = async (id: string, payload: any, others: any) => {
        const { data } = await this.client.put(`${this.endPoint}/${id}`, payload, {...others})
        return data
    }

    deleteAdmin = async (id: string) => {
        const { data } = await this.client.delete(`${this.endPoint}/delete/${id}`)
        return data
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SuperAdminService();
