const getQueryParams = params => Object.keys(params).map(key => `${key}=${params[key]}`).join('&');

export const URLQueryParams = {
    construct: (params) => {
        return getQueryParams(params);
    },
    appendToQueryString: (queryString, params) => {
        return queryString ? `${queryString}&${getQueryParams(params)}` : getQueryParams(params);
    },
};
