import { FC, Dispatch, SetStateAction, useState, useEffect } from 'react'
import { ModalBox } from '../../shared/modals/modal'
import type { DatePickerProps, TimePickerProps } from 'antd';
import { DatePicker, Space, TimePicker } from 'antd';
import { PrimaryButton } from '../../shared/buttons/igemo_button';
import 'dayjs/locale/nl-be';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/nl_BE';
import { formatTime } from '../../utils/helpers';


interface SelectDate {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>,
    onDateSelected: any,
    loading?: boolean,
    initialDateTime?: any,
}

const SelectDateModal: FC<SelectDate> = ({
    open,
    setOpen,
    onDateSelected,
    loading = false,
    initialDateTime = null,
}) => {
    const [date, setDate] = useState<any>(null)
    const [time, setTime] = useState<any>(null)
    const [dateAntd, setDateAntd] = useState<dayjs.Dayjs | null>(null)
    const [timeAntd, setTimeAntd] = useState<dayjs.Dayjs | null>(null)

    const onChangeDate: DatePickerProps['onChange'] = (_date, dateString) => {
        setDate(dateString)
        setDateAntd(_date)
    };
    const onChangeTime: TimePickerProps['onChange'] = (_date, timeString) => {
        setTime(timeString)
        setTimeAntd(_date)
    };

    const handleSubmit = () => {
        onDateSelected(date, time)
    }

    useEffect(() => {

        const dateTime = dayjs(initialDateTime, { locale: 'nl_BE' })
        setDateAntd(dateTime)
        setTimeAntd(dateTime)
        
        const dateOnlyStr = new Date(initialDateTime)?.toISOString()?.split('T')[0]
        setDate(dateOnlyStr)

        const timeOnlyStr = formatTime(initialDateTime) + ':00'
        setTime(timeOnlyStr)
        
    }, [initialDateTime])
    const disabledHours = () => {
        const hours = [];
        const currentHour = new Date().getHours();

        for (let i = 0; i <= currentHour; i++) {
            hours.push(i);
        }

        return hours;
    };

    return (
        <ModalBox
            title='Selecteer een datum'
            width={'auto'}
            cancelHandler={() => setOpen(false)}
            extraClasses="lg:w-[50%] md:w-[80%] w-full text-center"
            open={open}
        >
            <div className='my-4'>
                <Space className='flex-col sm:flex-row'>
                    <TimePicker
                        onChange={onChangeTime} value={timeAntd?.isValid() ? timeAntd : null} size='large' locale={locale} showSecond={false}
                        //disabledHours={disabledHours}
                    />
                    <DatePicker
                        onChange={onChangeDate} value={dateAntd?.isValid() ? dateAntd : null} size='large' locale={locale}
                        minDate={dayjs(new Date())}
                    />
                    {/* <DatePicker onChange={onChangeDate} value={dateAntd} defaultValue={dayjs(initialDateTime, { locale: 'nl_BE' })} size='large' locale={locale} /> */}
                </Space>
            </div>
            <div className="px-8 flex justify-between">
                <div className="flex justify-start items-center mt-4 w-[39%]">
                    <p onClick={() => { setOpen(false) }} className="ml-2 primary-text no-underline hover:underline cursor-pointer text-[18px]">Annuleer</p>
                </div>
                <div className="flex mt-4 w-[61%] justify-end">
                    <PrimaryButton disabled={loading || !dateAntd?.isValid() || !timeAntd?.isValid()} content="Bevestigen" onClick={handleSubmit} />
                </div>
            </div>

        </ModalBox>
    )
}

export default SelectDateModal
