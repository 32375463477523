import { isEmpty } from "lodash";
import { Tabs, TabsProps, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { CompanyInfo } from "./companyInfo";
import { ContactPersonInfo } from "./contactPersonInfo";
import Layout from "../../layout";
import {
    PrimaryButton,
    SecondaryButton,
    TableButtonWithIcon,
    ThirdButton
} from "../../../shared/buttons/igemo_button";
import upload from "../../../assets/icons/upload.svg";
import { GetAPIService, PutAPIService } from "../../../context/services";
import { UserTypes } from "../../../constants/general";
import { MyProfile } from "./my_profile";
import { UpdatePassword } from "./UpdatePassword";
import ContractorStatus from "../../contractorStatus";
import useContractor from "../../../containers/contractor/useContractor";
import OfferCompare from "./OfferCompare";
import OfferService from "../../../api/Offers";
import OrganizationService from "../../../api/Organizations";
import { validateEmail } from "../../../utils/validations";
import Joi from "joi";
import ContractorReviewProfile from "./contractorReviewProfile";

const profileSchema = Joi.object({
    firstName: Joi.string()
        .required()
        .messages({
            'string.empty': `Voornaam mag niet leeg zijn.`,
            'any.required': `Voornaam is een verplicht veld.`,
        }),
    lastName: Joi.string()
        .required()
        .messages({
            'string.empty': `Achternaam mag niet leeg zijn.`,
            'any.required': `Achternaam is een verplicht veld.`,
        }),
    email: Joi.string()
        .pattern(/^\w+([\w+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        .required()
        .messages({
            'string.empty': `E-mail mag niet leeg zijn`,
            'any.required': `E-mail is een verplicht veld.`,
            'string.pattern.base': `Ongeldig e-mailadres.`
        }),
    phone: Joi.string()
        .pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
        .required()
        .min(6)
        .messages({
            'string.empty': `Telefoonnummer mag niet leeg zijn`,
            'any.required': `Telefoonnummer is een verplicht veld.`,
            'string.pattern.base': `Ongeldig telefoonnummer.`,
            'string.min': `Het telefoonnummer moet een minimale lengte van {#limit} cijfers hebben`,
        }),
        associatedEmails: Joi.array().optional(),
        removeAssociatedEmailIds: Joi.array().optional(),
})

export const Profile = () => {
    const { updateContractorStatus } = useContractor();
    const auth = JSON.parse(localStorage.getItem("auth") || "{}");
    const [userInfo, setUserInfo] = useState<any>({})
    const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
    const [updatedUserInfo, setUpdatedUserInfo] = useState<any>({
            lastName:'',
            firstName: '',
            email: '',
            phone: '',
            removeAssociatedEmailIds: [],
            associatedEmails: []
    })
    const [errors,setErrors]= useState({
        lastName:'',
        firstName: '',
        email: '',
        phone: '',
        removeAssociatedEmailIds: '',
        associatedEmails: ''
    })
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [contactPersonInfo, setContactPersonInfo] = useState<any>({})
    const [activeTab, setActiveTab] = useState<any>(1)
    const [isLoading, setLoading] = useState(false);
    const [servicesList, setServicesList] = useState<any>({});
    const [activeHomeownerTab, setActiveHomeownerTab] = useState<number>(0);
    const [messageApi, contextHolder] = message.useMessage();
    const [refreshState, setRefreshState] = useState(false);

    useEffect(() => {
        if (auth.uid) {
            setUserInfoHandler();
            fetchRenovationServicesDetails();
        }
    }, [auth?.uid, refreshState])

    useEffect(() => {
        setUpdatedUserInfo({
            'lastName': userInfo.lastName,
            'firstName': userInfo.firstName,
            'email': userInfo.email,
            'phone': userInfo.phone,
            'removeAssociatedEmailIds': [],
            'associatedEmails': userInfo?.associatedEmails?.map((item: any)=>{
                return {id:item.id,email: item.email };
            })
        })
    }, [userInfo]);

    const fetchRenovationServicesDetails = async () => {
        try {
            setLoading(true)
            const response = await OfferService.getAllServiceRenovationsForContractor(auth?.company?.id || '6');

            setServicesList(response);
            setLoading(false);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const logoutHandler = () => {
        localStorage.removeItem('auth')
        localStorage.removeItem('simulationInitDone')
        window.location.href = "/"
    }

    const handleContractorStatusChange = (id: String) => {
        console.log(userInfo);
        updateContractorStatus(id);
    };

    const handleOnChange = (e: any) => {
        const { name, value } = e.target;
        const regex = /\bassociatedEmails/;
        if(regex.test(name)){
            let index =name.split("associatedEmails")[1]
            let existingEmails = [...updatedUserInfo.associatedEmails]
            existingEmails[index]['email'] = value
            setUpdatedUserInfo({...updatedUserInfo,associatedEmails:existingEmails})
        } else {
            try {

                const target = profileSchema.extract(name);
                const result = target.validate(value)
                if (result.error && isSubmitted) {
                    setErrors({ ...errors, [name]: result.error.message })
                }
                else {
                    setErrors({ ...errors, [name]: "" })
                }
                setUpdatedUserInfo({ ...updatedUserInfo, [name]: value });
    
            } catch (error: any) {
                if (isSubmitted) {
                    setErrors({ ...errors, [name]: error.message });
                }
            }
        }
        
    };
    const handleAssociatedEmailsAdd = ()=>{
        let existingEmails = [...updatedUserInfo.associatedEmails]
        existingEmails.push({email:""})
        setUpdatedUserInfo({...updatedUserInfo,associatedEmails:existingEmails})
    }
    const handleAssociatedEmailsDelete = (value: String,index:number, id : String)=>{
        let existingEmails = [...updatedUserInfo.associatedEmails]
        existingEmails.splice(index,1)
        if(id){
            let removeAssociatedEmailIds = [...updatedUserInfo.removeAssociatedEmailIds]
            removeAssociatedEmailIds.push(id)
            setUpdatedUserInfo({...updatedUserInfo,associatedEmails:existingEmails,
                removeAssociatedEmailIds:removeAssociatedEmailIds
            })

        }else{
            setUpdatedUserInfo({...updatedUserInfo,associatedEmails:existingEmails})
        }
    }

    const onFormSubmit = () => {
        setIsSubmitted(true);

        const results = profileSchema.validate(updatedUserInfo,{abortEarly:false})
        if (results.error) {
            let errObj ={...errors}
            for (const err of results.error.details) {
                errObj = {...errObj,[err.path[0]]:err.message}
            }
            setErrors(errObj)
            return
        } else {
            PutAPIService(`/user/update/${userInfo.id}`, {
                ...updatedUserInfo
            }).then((res: any) => {
                if (res?.status === 200) {
                    messageApi.open({
                        type: 'success',
                        content: 'Profiel succesvol bijgewerkt.',
                        duration: 3,
                    });
                    setUserInfoHandler()
                    setIsSubmitted(false)
                } else {
                    messageApi.open({
                        type: 'error',
                        content: 'Kan het profiel niet bijwerken',
                        duration: 3,
                    });
                }
            })
        }

        
    }

    const setUserInfoHandler = () => {

        GetAPIService(`user/profile/${auth.uid}`,).then((res) => {
            const info = res?.data?.data?.roles || []
            if (info?.length) {
                let data = { 
                    ...info[0],
                    company : info[0]?.cpContractorUsers[0]?.contractor
                }
                setUserInfo(data)
            }
        })
    }

    const refreshData = () => {
        setRefreshState(!refreshState);
    }

    const formUpdated = useMemo(() => {
        return Object.keys(updatedUserInfo).filter((key: string) => updatedUserInfo[key] !== userInfo[key]).length > 0
    }, [userInfo, updatedUserInfo])

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `Bedrijf`,
            children: !isEmpty(servicesList?.servicesGeneralGroup) && <CompanyInfo
                servicesList={servicesList?.servicesGeneralGroup}
                userId={userInfo?.company?.id}
                setContactPersonInfo={setContactPersonInfo}
                refreshData={refreshData}
            />,
        },
        {
            key: '2',
            label: `Diensten & hun Technisch Offerte-onderdelen`,
            children: !isEmpty(servicesList?.dataByContractorId) && <OfferCompare servicesList={servicesList?.dataByContractorId[0]} />,
        },
        // {
        //     key: '3',
        //     label: `Contactpersoon`,
        //     children: <ContactPersonInfo userInfo={contactPersonInfo} />,
        // },
        {
            key: '3',
            label: `Contactpersoon`,
            children: <MyProfile onFormSubmit={onFormSubmit} formUpdated={formUpdated} updatedInfo={updatedUserInfo} handleOnChange={handleOnChange} handleAssociatedEmailsAdd={handleAssociatedEmailsAdd} handleAssociatedEmailsDelete={handleAssociatedEmailsDelete} userInfo={userInfo} activeHomeownerTab = {activeHomeownerTab} enablePadding = {false} errors={errors} />,
        },
        {
            key: '4',
            label: 'Beoordelingen',
            children: <ContractorReviewProfile userId = {userInfo?.company?.id} />
        }
    ];

    const handleTabChange = (event: any) => {
        setActiveTab(event)
    }

    function download(data: any) {
        const url = URL.createObjectURL(data);
        const a = document.createElement('a');
        a.download = 'CJO statistieken.xlsx';
        a.href = url;
        a.target = '_self';

        a.click();

        setTimeout(function () {
            a.remove();
            URL.revokeObjectURL(url);
        }, 100);
    }

    const downloadExcelFileById = async () => {
        try {
            let domain:any = localStorage.getItem('domainObj');
            domain = JSON.parse(domain);
            const response = await OrganizationService.getOrganizationExcelFileById(domain?.id || 1);
            download(response);
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    return (
        <Layout>
            <div className = "w-full box-border md:px-16 lg:px-16 mac:px-32 mt-[42px]">
                {contextHolder}
                <div className="py-4 bg-[white]">
                    {isLoading ? (
                        <div className="px-16 py-16" style={{ textAlign: 'center' }}>Laden...</div>
                    ) : (
                        <>
                            <div className="flex w-full px-16">
                                <h1 className="text-[24px] font-semibold mr-5">
                                    Mijn Profiel
                                </h1>
                                {([2, 5].includes(auth?.typeId) && userInfo.company) &&
                                    <ContractorStatus status={userInfo.company.status} onChange={() =>
                                        handleContractorStatusChange(userInfo?.company?.id)
                                    } />
                                }
                            </div>

                            {([3].includes(auth?.typeId)) && (<div className = "flex justify-between items-center mt-[22px] px-16" style={{ marginTop: -35 }}>
                                <div className = "flex items-center"></div>
                                <button
                                    type = "button"
                                    role = "button"
                                    className = "text-sm font-semibold border-[1px] primary-border primary-text px-6 py-2 flex font-sans font-regular text-[14px] text-[#8B8B8B] items-center"
                                    onClick={downloadExcelFileById}
                                >
                                    Download Rapport
                                </button>
                            </div>)}

                            <div className = "flex justify-between items-center mt-[22px] px-16">
                                <div className = "flex items-center">
                                    {auth.typeId === 1 && (
                                        <>
                                            <div className = "flex h-[30px] cursor-pointer relative mr-[50px]" onClick = {() => setActiveHomeownerTab(0)}>
                                                <p className = {`font-sans font-semibold text-[14px] duration-[300ms] ${activeHomeownerTab === 0 ? "primary-text" : "text-[#ccc]"}`}>Informatie</p>
                                                <div className = {`w-full h-[3px] primary-main absolute bottom-0 left-0 duration-[300ms]`} style = {{opacity: activeHomeownerTab === 0 ? 1 : 0}} />
                                            </div>
                                            <div className = "flex h-[30px] cursor-pointer relative" onClick = {() => setActiveHomeownerTab(1)}>
                                                <p className = {`font-sans font-semibold text-[14px] duration-[300ms] ${activeHomeownerTab === 1 ? "primary-text" : "text-[#ccc]"}`} >Beoordelingen</p>
                                                <div className = "w-full h-[3px] primary-main absolute bottom-0 left-0 duration-[300ms]" style = {{opacity: activeHomeownerTab === 1 ? 1 : 0}} />
                                            </div>
                                        </>
                                    )}
                                </div>
                                <button type = "button" role = "button" className = "flex font-sans font-regular text-[14px] text-[#8B8B8B] items-center" onClick={logoutHandler}>
                                    Uitloggen
                                    <img
                                        src={upload}
                                        alt="Uitloggen"
                                        className="rotate-90 ml-[7px]"
                                        width={10}
                                        height={10}
                                    />
                                </button>
                            </div>

                            <div className = "w-full h-[1px] bg-[#8B8B8B]/[0.15] my-[15px]" />
                            <div className="grid grid-cols-12">
                                <div className="col-span-12">
                                    {
                                        auth.typeId === 5 ?
                                            <div className="col-span-10 px-16">
                                                <Tabs
                                                    
                                                    items={items}
                                                    defaultActiveKey="1"
                                                    onChange={handleTabChange}
                                                />
                                            </div>
                                            :
                                            <MyProfile
                                                onFormSubmit={onFormSubmit}
                                                formUpdated={formUpdated}
                                                handleOnChange={handleOnChange}
                                                userInfo={userInfo}
                                                updatedInfo={updatedUserInfo}
                                                handleAssociatedEmailsAdd={handleAssociatedEmailsAdd}
                                                handleAssociatedEmailsDelete={handleAssociatedEmailsDelete}
                                                activeHomeownerTab={activeHomeownerTab}
                                                errors={errors}
                                            />
                                    }
                                </div>
                                <span className="col-span-2 text-xs mt-4 text-gray-400 underline">
                        </span>
                            </div>
                            {/*{*/}
                            {/*    !UserTypes.CONTRACTOR.includes(auth.typeId) &&*/}
                            {
                                (auth.typeId !== 5 || activeTab === "3") && <div className="pt-[30px] border-t-[1px] border-gray-300">
                                    {/*<ThirdButton*/}
                                    {/*    content="mijn account verwijderen"*/}
                                    {/*    onClick={() => {*/}
                                    {/*    }}*/}
                                    {/*    classes="text-red-700 text-xs"*/}
                                    {/*/>*/}
                                    <SecondaryButton
                                        classes="ml-20"
                                        content="Verander uw wachtwoord"
                                        onClick={() => setOpenUpdatePassword(true)}
                                    />
                                </div>}
                            {/*}*/}

                            <UpdatePassword open={openUpdatePassword} setOpen={setOpenUpdatePassword} />
                        </>
                    )}
                </div>
            </div>
        </Layout>
    )
};

export default Profile;
