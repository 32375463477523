import { FC } from 'react'
import { ReactComponent as PdfIcon } from "../../assets/icons/pdf-color.svg"
import { HouseOwnerListingStatus } from '../../models/applicationState'
import { formatDate } from '../../utils/helpers';
// import ViewDocument from '../../components/modals/viewDocument';
// import TabsModal from '../../shared/Table/DetailsModal';

interface ContractorDocumentInterface {
    offerRequest: any,
    offerRequestId: any,
    setShowDocumentModal: any,
    setTabKey: any,
}

const ContractorDocument: FC<ContractorDocumentInterface> = ({ offerRequest, setShowDocumentModal, setTabKey}) => {

    const handleClickOnDateTime = () => {
        // open panel to set data + time
    }
    let { cpOfferRequestRenovationContractorDocuments: documents = [], id } = offerRequest
    // filter documents
    // 4 -> Offerte
    // 5 -> Factuur
    // documents = documents?.filter((document: any) => [4, 5]?.includes(document?.documentTypeId))

    const handlePreviewDocument = (document: any) => {
        setTabKey("4")
        setShowDocumentModal(true)
    }

    return (
        <div className='flex align-middle justify-center gap-2'>
            {offerRequest.statusId === HouseOwnerListingStatus.houseVisitPlanned && (
                <p className='hover:cursor-pointer' onClick={handleClickOnDateTime}>{formatDate(offerRequest?.visitPlanDateTime) || ''}</p>
            )}
            {
                documents?.map((document: any) => (
                    <PdfIcon className='h-5 w-5 cursor-pointer' key={document?.id} onClick={() => handlePreviewDocument(document)} />
                ))
            }
        </div>
    )
}

export default ContractorDocument
