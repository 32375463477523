import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {COLORS} from "../../constants/ui";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ServiceItem {
    id: string|number,
    title: string,
    key: string,
    offerRequestId: string,
    renovationId?: string,
    type: string,
    details: string,
    isGreen?: boolean,
    generalGreen: any,
    renovationGreen: any,
    statusIconThree: any,
}

type ServicesList = Array<ServiceItem>

const icon1 = <FontAwesomeIcon
    color="#F94144"
    icon={faCircleXmark as IconProp}
    className="w-5 h-5 ml-1 mt-1 -m text-igemo cursor-pointer	"
/>;
const icon2 = <FontAwesomeIcon
    color="#F94144"
    icon={faCircleXmark as IconProp}
    className="w-5 h-5 ml-1 mt-1 -m text-igemo cursor-pointer	"
/>;
const icon3 = <FontAwesomeIcon
    color="#F94144"
    icon={faCircleXmark as IconProp}
    className="w-5 h-5 ml-1 mt-1 -m text-igemo cursor-pointer	"
/>;

export const tickGreen = <FontAwesomeIcon
    style={{color: COLORS.primary}}
    icon={faCircleCheck as IconProp}
    className="w-5 h-5 ml-1 mt-1 -m text-igemo cursor-pointer	"
/>;

export const crossRed = <FontAwesomeIcon
    style={{color: COLORS.error}}
    icon={faCircleXmark as IconProp}
    className="w-5 h-5 ml-1 mt-1 -m text-igemo cursor-pointer	"
/>;

export const formsList: any = [
    {
        id: 1,
        title: 'Hoofddak',
        type: 'hoofddak',
        details: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum',
        statusIconOne: icon1,
        statusIconTwo: icon2,
        statusIconThree: icon3,
    },
    {
        id: 2,
        title: 'Bijdak',
        type: 'bijdak',
        details: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum',
        statusIconOne: icon1,
        statusIconTwo: icon2,
        statusIconThree: icon3,
    },
    {
        id: 3,
        title: 'Ramen leefruimte',
        type: 'ramen',
        details: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum',
        statusIconOne: icon1,
        statusIconTwo: icon2,
        statusIconThree: icon3,
        isGreen: true,
    },
    {
        id: 4,
        title: 'Ramen slaapkamer(s)',
        type: 'slaapkamer',
        details: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum',
        statusIconOne: icon1,
        statusIconTwo: icon2,
        statusIconThree: icon3,
    },
    {
        id: 5,
        title: 'Voorgevel',
        type: 'voorgevel',
        details: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum',
        statusIconOne: icon1,
        statusIconTwo: icon2,
        statusIconThree: icon3,
    },
    {
        id: 6,
        title: 'Achter- en zijgevels',
        type: 'zijgevels',
        details: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum',
        statusIconOne: icon1,
        statusIconTwo: icon2,
        statusIconThree: icon3,
    },
    {
        id: 7,
        title: 'Vloerisolatie',
        type: 'vloerisolatie',
        details: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum',
        statusIconOne: icon1,
        statusIconTwo: icon2,
        statusIconThree: icon3,
        isGreen: true,
    },
    {
        id: 8,
        title: 'Verwarming',
        type: 'verwarming',
        details: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum',
        statusIconOne: icon1,
        statusIconTwo: icon2,
        statusIconThree: icon3,
    },
    {
        id: 9,
        title: 'Warm Water',
        type: 'warmwater',
        details: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum',
        statusIconOne: icon1,
        statusIconTwo: icon2,
        statusIconThree: icon3,
    },
    {
        id: 10,
        title: 'Ventilatie',
        type: 'ventilatie',
        details: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum',
        statusIconOne: icon1,
        statusIconTwo: icon2,
        statusIconThree: icon3,
        isGreen: true,
    },
    {
        id: 11,
        title: 'Zonnepanelen',
        type: 'zonnepanelen',
        details: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum',
        statusIconOne: icon1,
        statusIconTwo: icon2,
        statusIconThree: icon3,
    },
    {
        id: 12,
        title: 'Asbest',
        type: 'asbest',
        details: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum',
        statusIconOne: icon1,
        statusIconTwo: icon2,
        statusIconThree: icon3,
    },
    {
        id: 13,
        title: 'Vocht?',
        type: 'vocht',
        details: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum,\n Lorem Ipsum Lorem Ipsum Lorem Ipsum',
        statusIconOne: icon1,
        statusIconTwo: icon2,
        statusIconThree: icon3,
    },
];
