import {useEffect, useState} from "react"
import {ModalBox} from "../../shared/modals/modal";
import {Form, UploadFile} from "antd";
import {PrimaryButton} from "../../shared/buttons/igemo_button";
// import {UploadFile} from "../../shared/forms/upload";
import {Input} from "../../shared/forms/input";
import { PostFormAPIService } from "../../context/services";
import useLabelStore from "./labelStore";
import useCityStore from "./CityStore";
import { debounce } from "lodash";


export const LabelModalBox = () => {

    const {labelOpen, setLabel} = useLabelStore()
    const [certState, setCertState] = useState<File>()

    useEffect(() => {
        console.log(certState);
    }, [certState])

    const { setRendering, rendering } = useCityStore()

    const form = Form.useForm()[0]
    
    useEffect(() => {
        form.resetFields()
        setCertState(undefined)
    }, [labelOpen])


    const onFormSubmit = (values: any) => {
        
        const formData = new FormData();
        formData.append('label', values.label);
        
        if (certState) {
            formData.append('logo', certState);
        }
        
        PostFormAPIService('contractor/create/label', formData).then((contractorRes)=> {
            setLabel(false)
            setRendering(!rendering)
        }).catch(err => {
            console.log(err);
            
            setLabel(false)
        })
    }

    const debouncedFormSubmit = debounce(onFormSubmit, 1000);

    return (
        <ModalBox
            title="LABEL"
            cancelHandler={() => setLabel(false)}
            open={labelOpen}
            width={200}
        >
            <Form form={form} name="contractor_form"
                  onFinish={debouncedFormSubmit}>
                <div className="px-8 py-12">
                    <div className="grid grid-cols-8">
                        
                        <div className="col-span-8 md:col-span-8">
                            <Input 
                                classes="w-full" 
                                full={true}
                                label="Label naam" 
                                name="label" 
                                active={true} 
                                onChangeHandler={() => {
                                }} 
                            />
                            <div className="flex items-center pt-1 align-middle">
                                <label htmlFor="fileUploadLabel" className="text-center primary-main secondary-text py-2 px-4 rounded cursor-pointer mt-[16px]">
                                    Logo opladen
                                </label>
                                <input
                                    id="fileUploadLabel"
                                    type="file"
                                    style={{display: 'none'}}
                                    onChange={(e) => {
                                        let files = e.target.files;
                                        if (files) setCertState(files[0]);
                                        if(files)
                                            console.log(files[0].name);
                                        
                                    }}
                                />
                            </div>
                            <p>{certState ? certState.name : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="px-8">
                    <div className="flex justify-end mt-4">
                        <PrimaryButton content="Opslaan" onClick={() => form.submit()}/>
                    </div>
                </div>
            </Form>
        </ModalBox>
    )
}