import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import { useParams, useNavigate } from "react-router-dom";
import { CardExpanded } from "../../shared/listCardExpanded";
import { servicesOfferData } from "../../constants/data";
import { PrimaryButton } from "../../shared/buttons/igemo_button";
import axios from "axios";

// image imports
import ArrowLeft from "../../assets/icons/arrow-left-circle.svg";
import InfoGreen from "../../assets/icons/info-green.svg";
import SimulationSubmitModal from "../../components/simulationSubmitModal/simulationSubmitModal";
import HouseOne from "../../assets/picture/hp/house.png";
import { ContractorInfoModal } from "../contractor/contractor_info_modal";
import getCardDescription from "../../components/services/getCardDescription";

type OfferRequestDataType = {
    cpOfferRequestRenovations: Array<any>,
    simulationId: string
}

type ServiceAnswerType = {
    createdAt: string,
    deletedAt: string | null,
    id: string,
    key: string,
    newAnswer: number,
    newAnswerText: string,
    originalAnswer: number,
    originalAnswerText: string,
    sesssionId: string,
    updatedAt: string | null,
}

type contractorCertificateType = {
    certificate: {
        certificat: string,
        id: string,
        logo: string | null,
    },
    contractorId: string,
    certificatId: string,
    id: string
}

type contractorLabelsType = {
    id: string,
    labelId: string,
    contractorId: string,
    label: {
        id: string,
        label: string,
        logo: string | null
    }
}

type contractorListType = {
    archiveContractor: number,
    archiveRequestor: number,
    contractor: {
        address1: string | null,
        address2: string | null,
        cityId: string,
        contract: string | null,
        createdAt: string | null,
        deletedAt: string | null,
        description: string | null,
        email: string,
        id: string,
        logo: string,
        name: string,
        phone: string,
        status: string | null,
        updatedAt: string | null,
        streetId: string | null,
        users: UserList,
        cpContractorCertificats?: contractorCertificateType[],
        cpContractorLabels?: contractorLabelsType[]
    },
    contractorId: string,
    createdAt: string,
    deletedAt: string | null,
    groupPurchase: number,
    id: string,
    mentorship: number,
    offerRequestRenovationId: string,
    selected: null,
    statusId: string,
    updatedAt: string | null
}

type UserList = [
    {
        firstName: string,
        lastName: string
    }
]

type ServicesOfferProps = {

}

const ServicesOffer = ({}: ServicesOfferProps) => {
    const [ offerRequestData, setOfferRequestData ] = useState<OfferRequestDataType>({
        cpOfferRequestRenovations: [],
        simulationId: ""
    });
    const [ selectedContractor, setSelectedContractor ] = useState<null | string | number>(null);
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ serviceAnswerList, setServiceAnswerList ] = useState<ServiceAnswerType[]>([]);
    const [ isOfferRequestSent, setIsOfferRequestSent ] = useState<boolean>(false);

    const navigate = useNavigate();
    const { offerRequestId } = useParams<any>();

    useEffect(() => {
        if(offerRequestId) {
            axios.get(process.env.REACT_APP_BACKEND_URL + "offer/request/overview/" + offerRequestId).then((res: any) => {
                const { data } = res.data.data;
                // check if data is an array with an object stored at 0 index
                if(Array.isArray(data)) {
                    console.log(data[0])
                    setOfferRequestData(data[0]);
                }
            }).catch(error => console.log(error))
        }
    }, [offerRequestId])

    useEffect(() => {
        if(offerRequestData.simulationId) {
            // const auth = JSON.parse(localStorage.getItem("auth")!)
            const authOrigin = localStorage.getItem("auth");
const auth = authOrigin && authOrigin?.length > 0? JSON.parse(localStorage.getItem("auth")!) : ""
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth?.token}`,
            };
            axios.get(process.env.REACT_APP_BACKEND_URL + "simulation/resultan/" + offerRequestData.simulationId, { headers }).then((res) => {
                setServiceAnswerList(res.data)
            }).catch(error => console.log(error))
        }
    }, [offerRequestData])

    const sendOffer = () => {
        if(offerRequestId) {
            setIsOfferRequestSent(true);
            // const auth = JSON.parse(localStorage.getItem("auth")!)
            const authOrigin = localStorage.getItem("auth");
const auth = authOrigin && authOrigin?.length > 0? JSON.parse(localStorage.getItem("auth")!) : ""
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth?.token}`,
            };
            axios.post(process.env.REACT_APP_BACKEND_URL + "offer/request/send/" + offerRequestId, {} , { headers }).then((res: any) => {
                setIsOfferRequestSent(false);
            }).catch(error => console.log(error))
        }
    }

    const goBack = () => {
        navigate(-1);
    }

    const showModalHandler = () => {
        setShowModal(true);
        sendOffer();
    }

    const goToDashboard = () => {
        navigate("/offer-request");
    }

    return (
        <Layout>
            <div className = "w-full flex justify-center bg-[#F9F9F9]">
                <div className = "w-full flex flex-col md:px-16 lg:px-16 mac:px-32">
                    <div className = "w-full flex items-center cursor-pointer box-border py-[18px]" onClick = {goBack}>
                        <img className = "h-[16px] object-fit" src = {ArrowLeft} alt = "arrow left"/>
                        <p className = "font-Source font-semibold text-p2 text-black ml-[10px]">Ga terug naar aanbod</p>
                    </div>
                    <div className = "w-full bg-white flex flex-col items-center box-border py-[18px]">
                        <div className = "w-full flex justify-center">
                            <p className = "font-Source font-semibold text-3xl text-black text-center md:text-start">Overzicht van de offerteaanvragen</p>
                        </div>
                        <div className = "w-[95%] max-w-[100%] h-[1px] bg-[#707070]/[0.1] mt-[15px]" />
                        <div className = "w-full flex flex-col">
                            {offerRequestData?.cpOfferRequestRenovations?.map((item: any, index: number) => (
                                <div className = "w-full flex flex-col border-b-[1px] border-b-[#F6F6F6] box-border md:px-[52px] mt-[15px]" key = {item.id}>
                                    <div className = "w-full flex items-center">
                                        <img className = "h-[86px] object-fit shrink-0 mr-[40px]" alt = "logo" src = {item?.renovation?.logo || HouseOne} />
                                        <div className = "flex flex-col">
                                            <div className = "flex items-center">
                                                <p className = "font-Source font-semibold text-2xl text-black">{item?.renovation?.title}</p>
                                            </div>
                                            <div className = "flex flex-col">
                                                {getCardDescription(item?.renovation?.key || "", serviceAnswerList)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className = "flex w-full my-[15px]">
                                        <p className = "font-Source font-semibold text-p1 text-black">Lijst van aannemers</p>
                                    </div>
                                    <div className = "flex flex-col w-full mb-[35px] overflow-x-auto">
                                        {item?.cpOfferRequestRenovationContractors?.map((contractor: contractorListType, contractorIndex: number) => (
                                            <CardExpanded contractorData = {contractor} key = {parseInt(contractor.id)} isClickable = {false} onClickHandler={(id)=> setSelectedContractor(id)} />
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className = "w-full flex justify-end my-[25px]">
                        <PrimaryButton content = "Vraag je offerte aan" onClick = {showModalHandler} />
                    </div>
                </div>
            </div>
            <ContractorInfoModal
                title="Gegevens van dev aannemer"
                open={selectedContractor !== null}
                setOpen={setSelectedContractor}
                selectedContractor={selectedContractor}
            />
            <SimulationSubmitModal modalState = {showModal} setModalState = {setShowModal} hideCloseButton>
                <p className = "font-Source font-bold text-black text-[30px]">Uw offerteaanvraag is verzonden</p>
                {/* <p className = "font-Source font-regular text-[#5F5F5F] text-p2Alt mt-[33px] text-center">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p> */}
                <div className = "flex w-full justify-center mt-[50px]">
                    {!isOfferRequestSent ? (
                        <PrimaryButton content = "Ga naar jouw dashboard" onClick={goToDashboard} />
                    ) : (<div className="w-full flex flex-col justify-center items-center box-border px-[50px] animate-fade-in-top">
                        <div className="w-[50px] h-[50px] border-4 primary-border border-solid border-r-white rounded-full animate-spin" />
                    </div>)
                    }
                </div>
                {/* <p className = "font-Source font-regular text-[#5F5F5F] text-p2Alt mt-[33px] underline cursor-pointer mt-[16px] text-center">Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p> */}
            </SimulationSubmitModal>
        </ Layout>
    )
}

export default ServicesOffer;
