import { FC, Dispatch, SetStateAction, useState, useEffect } from 'react'
import { ModalBox } from '../../shared/modals/modal'
import { isEmpty } from 'lodash'
import { Col, Row } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { logoUrl, uploadsUrl } from '../../shared/Table/common'
// import { ReactComponent as ImageIcon } from "../../assets/icons/image.svg";
import { ReactComponent as PdfIcon } from "../../assets/icons/pdf-color.svg";
import DocumentPreview from "../../containers/houseOwner/DocumentPreview";
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface ViewDocumentInterface {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
    documentsList: any[],
    selected: any,
}

const ViewDocument: FC<ViewDocumentInterface> = ({
    open,
    setOpen,
    documentsList,
    selected,
}) => {
    const [previewDocument, setOpenPReview] = useState<any>();
    useEffect(() => {
        if (!isEmpty(selected)) {
            let docType = selected.file.split('.');
            selected.docType = docType[docType.length - 1];
            setOpenPReview(selected)
        }

    }, [selected])


    return (
        <ModalBox
            width='auto'
            cancelHandler={() => setOpen(false)}
            open={open}
            extraClasses='lg:w[-80%] md:w-[80%] w-full text-center'
        >
            <div className="py-4">
                <Row className="border-b-[1px] pb-2">
                    <Col md={1}>
                        <div className="pt-2 pl-2" onClick={() => setOpen(false)}>
                            <FontAwesomeIcon
                                icon={faCircleLeft as IconProp}
                                className="w-8 h-8 ml-1 mt-1 -m cursor-pointer	"
                            />
                        </div>
                    </Col>
                    <Col md={22}>
                        <h2 className="text-center text-2xl pt-2 primary-text font-semibold">{previewDocument?.title}</h2>
                    </Col>
                </Row>
                <Row className="border-b-[1px] pb-2 pt-2">
                    <Col md={3} className="pl-4">
                        <p className="font-semibold">Beschrijving type document</p>
                    </Col>
                    <Col md={21} className="text-left">
                        <p className="pl-2">{previewDocument?.description}</p>
                    </Col>
                </Row>

                <Row className="border-b-[1px] pb-2 pt-2 h-[50vh]">
                    <Col md={24} className="p-8 overflow-scroll h-full">

                        {(previewDocument?.docType === 'png' || previewDocument?.docType === 'jpeg' || previewDocument?.docType === 'jpg') ? (
                            <div
                                style={{
                                    minHeight: 500,
                                    minWidth: '100%',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                    backgroundImage: `url(${uploadsUrl(previewDocument?.file)})`,
                                }}
                            />
                        ) : (
                            <DocumentPreview file={uploadsUrl(previewDocument?.file)} />
                        )}
                    </Col>
                </Row>

                <Row className="pt-2">
                    <Col md={3} className="pl-4">
                        <p className="font-semibold">Uw document:</p>
                    </Col>
                    <Col md={21}>
                        <Row>
                            {documentsList?.map((doc: any) => {
                                let docType = doc.file.split('.');
                                docType = docType[docType.length - 1];
                                // const iconType = docType === 'pdf'
                                //     ? <PdfIcon className='h-10 w-10' title={doc.title} />
                                //     : <ImageIcon className='h-10 w-10' title={doc.title} />

                                return <Col
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                    md={2}
                                    onClick={() => setOpenPReview({ ...doc, docType: docType })}
                                    className="pb-2 text-center cursor-pointer"
                                >
                                    <PdfIcon className='h-10 w-10' title={doc.title} />
                                    <p className="pt-1">{`${doc?.title}.${doc?.documentType?.type || ""}`}</p>
                                </Col>;
                            })}
                        </Row>
                    </Col>
                </Row>
            </div>

        </ModalBox>
    )
}

export default ViewDocument
