import React, { useState } from "react";
import axios from "axios";
import { useNavigate,useLocation } from "react-router-dom";
import Joi from "joi";

// assets & image imports
import { PrimaryButton } from "../../../shared/buttons/igemo_button";
import { IoCloseCircleOutline } from "react-icons/io5";

type ResetPasswordProps = {
    handleModalClose?: () => void;
    setModalState?: (modalState: {state: boolean, type: string }) => void; 
}

const setPasswordSchema = Joi.object({
    password: Joi.string().min(8).required().messages({
        'string.empty': `Wachtwoord mag niet leeg zijn.`,
        'any.required': `Wachtwoord is een verplicht veld.`,
        'string.min': `Het wachtwoord moet een minimale lengte van {#limit} cijfers hebben`,
    }),
    passwordConfirmation: Joi.string().min(8).required().messages({
        'string.empty': `Bevestig wachtwoord mag niet leeg zijn.`,
        'any.required': `Bevestig wachtwoord is een verplicht veld.`,
        'string.min': `Het bevestig wachtwoord moet een minimale lengte van {#limit} cijfers hebben`,
    })
});


const CreatePasswordComponent = ({ handleModalClose = () => {}, setModalState = () => {}}:ResetPasswordProps) => {
    const location = useLocation();
    const [ inputState, setInputState ] = useState({
        password: "",
        passwordConfirmation: ""
    });
    const [ inputErrorState, setInputErrorState ] = useState({
        password: "",
        passwordConfirmation: ""
    });
    const [ apiErrorMessage, setApiErrorMessage ] = useState<string>("")
    const [ resetPasswordState, setResetPasswordState ] = useState<"initial" | "loading" | "success" | "error">("initial");
    const [isSubmitted, setIsSubmitted] = useState(false);

    const navigate = useNavigate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        try {
            let target = setPasswordSchema.extract(name);
            const result = target.validate(value)
            if (result.error && isSubmitted) {
                setInputErrorState({ ...inputErrorState, [name]: result.error.message })
            }else if(result.value && isSubmitted && name==="passwordConfirmation" && value !== inputState.password){
                setInputErrorState({ ...inputErrorState, passwordConfirmation: "Wachtwoord en bevestigingswachtwoord komen niet overeen.", })
            }else if(result.value && isSubmitted && name==="passwordConfirmation" && value === inputState.password){
                setInputErrorState({ ...inputErrorState, passwordConfirmation: "", })
            }else if(result.value && isSubmitted && name==="password" && value !== inputState.passwordConfirmation){
                setInputErrorState({ ...inputErrorState, passwordConfirmation: "Wachtwoord en bevestigingswachtwoord komen niet overeen.",password:"" })
            }else if(result.value && isSubmitted && name==="password" && value === inputState.passwordConfirmation){
                setInputErrorState({ ...inputErrorState, passwordConfirmation: "",password:"" })
            }else{
                setInputErrorState({ ...inputErrorState, [name]: "" })
            }
            setInputState({ ...inputState, [name]: value });
        } catch (error: any) {
            if (isSubmitted) {
                setInputErrorState({ ...inputErrorState, [name]: error.message });
            }
        }
    }

    function resetPasswordSubmit() {
        setIsSubmitted(true);
        const results = setPasswordSchema.validate(inputState,{abortEarly:false})
        if (results.error) {
            let errObj ={...inputErrorState}
            for (const err of results.error.details) {
                errObj = {...errObj,[err.path[0]]:err.message}
            }
            setInputErrorState(errObj)
            return
        } else if(inputState.password!==inputState.passwordConfirmation){
            setInputErrorState({ ...inputErrorState, passwordConfirmation: "Wachtwoord en bevestigingswachtwoord komen niet overeen." })
            return
        } else{
            const token = location?.search?.split("setCreatePasswordModal")[0]?.replace('?token=','');
            // const resetType = window.location.pathname.split("/")[2];
            const url = "user/password/update/";
            if (token) {
                setResetPasswordState("loading");
                axios.post(process.env.REACT_APP_BACKEND_URL + url + token, {
                    password: inputState.password,
                }).then(response => {
                    setResetPasswordState("success");
                }).catch(error => {
                    if(error.response.status === 401) {
                        setApiErrorMessage("Ongeldige link");
                    }
                    setResetPasswordState("initial")
                }).finally(()=>{
                    setIsSubmitted(false)
                })
            } else setIsSubmitted(false)
        }
    }

    const continueToLogin = () => {
        navigate("/")
        setModalState({state: true, type: "login"});
    }
    
    return (
        <div className = "w-[934px] md:h-[471px] min-h-[471px] max-w-[90%] bg-white flex flex-col md:flex-row">
            <div className = "flex flex-col flex-[3.5] h-full primary-main box-border py-[37px] px-[33px]">
                <p className = "font-Source font-semibold text-4xl secondary-text leading-[55px]">Nieuw<br></br>wachtwoord</p>
                <p className = "font-Source font-regular text-p2Alt secondary-text mt-[21px]">Geef hier uw nieuw wachtwoord in.</p>
                {/* <p className = "font-Source font-regular text-p2Alt text-white mt-[25px]">At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p> */}
            </div>
            <div className = "flex flex-col flex-[6] h-full justify-between bg-white shrink-0">
                <div className = "flex flex-col w-full h-full">
                    <div className = "w-full flex justify-end">
                        <div>
                            <IoCloseCircleOutline size = "30px" color = "black" className = "mr-[17px] mt-[9px] opacity-0" /> 
                        </div>
                    </div>
                    {/* default view with inputs and button */}
                    {resetPasswordState === "initial" && (
                        <div className = "w-full flex flex-col box-border px-[35px] animate-fade-in-bottom">
                            <div className = "w-full flex flex-col mb-[18px]">
                                <p className = "font-Source font-semibold text-p2 primary-text box-border py-[5px]">Nieuw wachtwoord</p>
                                <div className = {`w-full h-[39px] border-[1px] flex items-center box-border px-[10px] ${inputErrorState.password ? 'border-[red]' : 'border-primary/[0.09]'}`}>
                                    <input autoComplete="off" className = "w-full outline-none font-Source font-regular text-p2 text-black" type = "password" name = "password" value = {inputState.password} onChange = {handleInputChange} />
                                </div>
                                <span className="text-error text-xs">{inputErrorState.password ? inputErrorState.password : null}</span>
                            </div>
                            <div className = "w-full flex flex-col mb-[69px]">
                                <p className = "font-Source font-semibold text-p2 primary-text box-border py-[5px]">Bevestiging van nieuw wachtwoord</p>
                                <div className = {`w-full h-[39px] border-[1px] flex items-center box-border px-[10px] ${inputErrorState.passwordConfirmation ? 'border-[red]' : 'border-primary/[0.09]'}`}>
                                    <input autoComplete="off" className = "w-full outline-none font-Source font-regular text-p2 text-black" type = "password" name = "passwordConfirmation" value = {inputState.passwordConfirmation} onChange = {handleInputChange}  />
                                </div>
                                <span className="text-error text-xs">{inputErrorState.passwordConfirmation ? inputErrorState.passwordConfirmation : null}</span>
                            </div>
                            <div className = "w-full flex flex-col items-center mb-[20px] md:mb-[0px]">
                                <p className = "font-Source font-regular text-p2 text-[red] mb-[15px]">{apiErrorMessage}</p>
                                <PrimaryButton content = "Inloggen" onClick = {resetPasswordSubmit} />
                            </div>
                        </div>
                    )}
                    {/* loader */}
                    {resetPasswordState === "loading" && (
                        <div className = "w-full h-full flex items-center justify-center animate-fade-in-top">
                            <div className = "w-[50px] h-[50px] border-4 primary-border border-solid border-r-white rounded-full animate-spin" />
                        </div>
                    )}
                    {/* success message */}
                    {resetPasswordState === "success" && (
                        <div className = "w-full h-full flex flex-col items-center justify-center animate-fade-in-top box-border px-[15px]">
                            <p className = "font-Source font-semibold text-3xl primary-text">Wachtwoord reset!</p>
                            <p className = "font-Source font-semibold text-p2 text-black mt-[10px] mb-[10px] text-center">U kunt nu inloggen met uw nieuwe wachtwoord.</p>
                            <PrimaryButton content = "Inloggen" onClick = {continueToLogin} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CreatePasswordComponent;