import { FC } from 'react'
import { ReactComponent as PdfIcon } from "../../assets/icons/pdf-color.svg"
import { HomeOwnerDocumentInterface } from '../../models/applicationState'
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";
// import ViewDocument from '../../components/modals/viewDocument';


const RequestQuoteDocument: FC<HomeOwnerDocumentInterface> = ({ renovaiton, setShowUploadModal, item,archive, setShowDocumentModal, setTabKey }) => {
    let { cpOfferRequestRenovationContractorDocuments: documents = [] } = item

    // filter documents
    // 4 -> Offerte
    // 5 -> Factuur
    // documents = documents?.filter((document: any) => [4, 5]?.includes(document?.documentTypeId))

    const openDocumentPreviewModal = (document: any) => {
        setTabKey("4")
        setShowDocumentModal(true)
    }

    return (
        <div className='w-full flex flex-wrap gap-2'>
            <>{documents?.map((document: any) => (
                <PdfIcon className='cursor-pointer h-5 w-5 shrink-0' key={document?.id} title={document?.description} onClick={() => openDocumentPreviewModal(document)}  />
            ))}
            </>
            <div className="h-4 w-4 fill-primary hover:cursor-pointer">
                {!archive &&
                    <UploadIcon onClick={() => { setShowUploadModal(true) }} height="inherit" width="iherit" />
                }
            </div>
        </div>
    )
}

export default RequestQuoteDocument
