import React, { Dispatch, FC, SetStateAction } from 'react'
import { ModalBox } from '../../shared/modals/modal'
import { showWarningAfterDays } from '../../constants/general'

interface Warning {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>,
}


const WarningModal: FC<Warning> = ({ open, setOpen }) => {
    return (
        <ModalBox
            title='Waarschuwing'
            width='auto'
            cancelHandler={() => setOpen(false)}
            open={open}
            extraClasses='lg:w[-50%] md:w-[50%] w-full text-center'
        >
            <p className='mb-4'>De offerte aanvraag is meer dan {showWarningAfterDays} dagen geleden gebeurd</p>
        </ModalBox>
    )
}

export default WarningModal