import React, {FC} from "react";
import {FaCheckSquare, FaMinusSquare, FaSquare} from "react-icons/fa";

import "./form.css";

interface checkBoxProps {
    variant: any
    onClick: any
}

const CheckBoxIcon: FC<checkBoxProps> = ({ variant, ...rest }) => {
    switch (variant) {
        case "all":
            return <FaCheckSquare {...rest} className = "primary-text" style={{fontSize: 24, borderRadius: 5, cursor: 'pointer' }} />;
        case "none":
            return <FaSquare {...rest} style={{ color: '#f5f5f5', fontSize: 24, border: '2px solid #e1e1e1', borderRadius: 5, cursor: 'pointer' }} />;
        case "some":
            return <FaMinusSquare {...rest} style={{ color: 'green', fontSize: 24, borderRadius: 5, cursor: 'pointer' }} />;
        default:
            return null;
    }
};

export default CheckBoxIcon;
