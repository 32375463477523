import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from "react";
import {ModalBox} from "../../shared/modals/modal";
import {message} from "antd";
import {PrimaryButton} from "../../shared/buttons/igemo_button";
import {ArrowRightOutlined, ArrowDownOutlined} from "@ant-design/icons";
import {GlobalContext} from "../../context/globalContext/globalContext";
import {HouseOwnerListingStatusColors} from "../../models/applicationState";
import {PutAPIService} from "../../context/services";


interface AddEmail {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>,
    status?: string,
    nextStatus?: number,
    color?: string,
    id?: number,
    item?: object,
    title?: string,
    offerRequestId?: string,
    renovationId?: string,
    setReload: any,
    address?: string
}

export const UpdateHomeOwnerStatus: FC<AddEmail> = ({
                                           open,
                                           setOpen,
                                                        status,
                                                        color,
                                                        id,
                                                        item,
                                                        title,
                                                        offerRequestId,
                                                        renovationId,
                                                        nextStatus,
                                                        setReload,
    address
                                       }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [colorBg, setColorBg] = useState('');
    const [content, setContent] = useState('');
    const [contractor, setContractor] = useState({name: '', id: ''});
    const { state } = useContext(GlobalContext)
    useEffect(()=>{
        // @ts-ignore
        const offerAllStatus = state?.offerAllStatus?.filter((status: any) => status?.id == parseInt(nextStatus))[0];
        // @ts-ignore
        const bgColor2 = HouseOwnerListingStatusColors[offerAllStatus?.status]
        const content2 = offerAllStatus?.status
        setColorBg(bgColor2);
        setContent(content2);
        // @ts-ignore
        setContractor(item?.contractor)
        console.log(address)
    }, [id, item])
    const handleSubmit = () => {
        setLoading(true);
        // @ts-ignore
        PutAPIService(`offer/request/contractor/update/${offerRequestId}`,{statusId: parseInt(nextStatus), renovationId: renovationId, contractorId: contractor.id})
            .then((response) => {
                if(response.data.success == true){
                    messageApi.open({
                        type: 'success',
                        content: 'Status succesvol bijgewerkt.',
                        duration: 3,
                    }).then(()=>{
                        setLoading(false);
                        setReload();
                        setOpen(false);
                    });
                }else{
                    setLoading(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Fout bij bijwerken van status, probeer het later opnieuw.',
                        duration: 3,
                    });
                }
            })
    }
    const getStatusText = (status: string | undefined) => {
        if(status == 'Offerte aangevraagd'){
            return `U hebt zojuist een bezoekmoment geselecteerd voor ${title} op het adres ${address}. Wenst u de status ${status} aan te passen naar ${content}?`
        }else if(status == 'Huisbezoek ingepland'){
            return `U hebt zojuist op Offerte opladen geklikt voor ${title} op het adres ${address}. Wenst u de status ${status} aan te passen naar ${content}?`
        }else if(content == 'Offerte aanvaard'){
            return `U hebt zojuist op Offerte aanvaarden geklikt voor de offerte van ${contractor?.name} voor ${title}. Wenst u de status ${status} aan te passen naar ${content}?`;
        }else if(content == 'Niet toegekend'){
            return `U hebt zojuist op Offerte Niet toegekend geklikt voor de offerte van ${contractor?.name} voor ${title}. Wenst u de status ${status} aan te passen naar ${content}?`
        }else if(status == 'Offerte aanvaard'){
            return `U hebt zojuist op Factuur opladen geklikt voor ${title} op het adres ${address}. Wenst u de status ${status} aan te passen naar ${content}?`
        }else{
            return `U hebt zonet op ${status} geklikt voor de ${contractor?.name} met ${title} . Wenst u de status ${status} aan te passen naar ${content}?`
        }
    }
    return (
        <ModalBox
            title="De verandering van status bevestigen?"
            width={'auto'}
            cancelHandler={() => setOpen(false)}
            extraClasses="lg:w-[50%] md:w-[80%] w-full text-center"
            open={open}>
            <div className="border-grey-300 border-t-[1px] flex justify-center">
                <p className="p-4 lg:w-[70%] w-full text-center text-gray-500">
                    {/*U hebt zonet op {status} geklikt voor de {contractor?.name} met {title} . Wenst u de status {status} aan te passen naar {content}?*/}
                    {getStatusText(status)}
                </p>
            </div>
            <div className="border-grey-300 border-b-[1px] flex justify-center items-center">
                <div className="px-8 py-12 w-full lg:w-[80%] lg:pt-20 lg:pb-20">
                    <div className="mt-5 mb-5 flex flex-col lg:flex-row justify-between items-center w-full lg:w-auto grid-cols-4 lg:grid-cols-8 gap-4">
                        <div className="flex justify-end">
                            <div
                                className={`w-full h-full px-9 py-4 text-white text-xs md:text-sm lg:text-lg font-semibold cursor-pointer`}
                                style={{ background: color}}>{status}</div>
                        </div>
                        <div className="flex justify-end">
                            <p className="flex justify-center items-center border-radius-icon p-1 border-[3px] border-[black]">
                                <ArrowRightOutlined className="text-[25px] hidden lg:block font-bold"/>
                                <ArrowDownOutlined className="text-[25px] lg:hidden block font-bold"/>
                            </p>
                        </div>
                        <div className="flex justify-end">
                            <div
                                className={`w-full h-full px-9 py-4 text-white text-xs md:text-sm lg:text-lg font-semibold cursor-pointer`}
                                style={{ background: colorBg}}>{content}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-8 flex justify-between">
                <div className="flex justify-start items-center mt-4 w-[39%]">
                    <p onClick={()=>{setOpen(false)}} className="ml-2 primary-text no-underline hover:underline cursor-pointer text-[18px]">Annuleer</p>
                </div>
                <div className="flex mt-4 w-[61%] lg:justify-start justify-end">
                    <PrimaryButton disabled={loading} content="Bevestigen" onClick={handleSubmit}/>
                </div>
            </div>
            {contextHolder}
        </ModalBox>

    )
}
