import React, { FC, useContext, useState } from 'react'
import { HouseOwnerListingStatus, HouseOwnerListingStatusColors } from '../../models/applicationState'
import { TableButton } from '../buttons/igemo_button'
import ArchiveBtn from './ArchiveBtn'
import { GlobalContext } from '../../context/globalContext/globalContext'
import { UpdateHomeOwnerStatus } from "../../components/modals/updateHomeOwnerStatus";
import SelectDateModal from '../../components/modals/selectDateModal'
import OfferRequestService from '../../api/OfferRequest'
import { Space, message } from 'antd'
import { formatDateTime } from '../../utils/helpers'

interface HomeOwnerActionProps {
    item: any,
    setReload?: any,
    offerRequestId?: string,
    renovationId?: string,
    title?: string,
    handleArchiveItem: any,
    setShowStatusModal?: any,
    setShowUploadModal?: any,
    setShowOfferteModal?: any,
    setShowInvoiceModal?: any
}

const HomeOwnerAction: FC<HomeOwnerActionProps> = ({ item, handleArchiveItem, setShowStatusModal, setShowUploadModal, setReload, setShowOfferteModal, setShowInvoiceModal }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { state } = useContext(GlobalContext)

    const [selectDateModal, setSelectDateModal] = useState<boolean>(false)
    const [loadingDate, setLoadingDate] = useState<boolean>(false)
    const [updateDateOnly, setUpdateDateOnly] = useState<boolean>(false)

    const id = item?.statusId
    const offerAllStatus = state?.offerAllStatus?.filter((status: any) => status?.id === id)[0]

    const hanldeDateSubmit = async (selectedDate: any, selectedTime: any) => {
        // api to set date
        try {
            setLoadingDate(true)
            // combine date and time to single object
            const dateTime = new Date(`${selectedDate}T${selectedTime}`).toISOString().slice(0, 19).replace('T', ' ')
            const payload = {
                visitPlanDateTime: dateTime,
            }
            const id = item.id
            await OfferRequestService.updateRenovationContractorById(id, payload)
            message.open({
                type: 'success',
                content: 'Datum toegevoegd.',
                duration: 3,
            })
            setSelectDateModal(false)
            if (!updateDateOnly) {
                setShowStatusModal(true)
            } else {
                setReload()
            }

        } catch (error) {
            console.error(error)
            message.open({
                type: 'error',
                content: 'kon geen datum toevoegen.',
                duration: 3,
            })

        } finally {
            setLoadingDate(false)
            setUpdateDateOnly(false)
        }
    }

    return (
        offerAllStatus?.status ? (
            <div className='w-full'>
                {offerAllStatus?.status === HouseOwnerListingStatus.offerRequested && (
                    <div
                        className='w-full'
                        onClick={() => { setSelectDateModal(true) }}
                    >Bezoek inplannen</div>
                )}
                {
                    // (offerAllStatus?.status === HouseOwnerListingStatus.offerUploaded || offerAllStatus?.status === HouseOwnerListingStatus.houseVisitPlanned)
                    (offerAllStatus?.status === HouseOwnerListingStatus.houseVisitPlanned)
                    && (
                        <Space direction='horizontal'>
                            {item?.visitPlanDateTime && (
                                <div
                                    className='cursor-pointer underline text-sm'
                                    onClick={() => { setSelectDateModal(true); setUpdateDateOnly(true); }}
                                >
                                    {formatDateTime(item?.visitPlanDateTime)}
                                </div>
                            )}
                            <div
                                onClick={() => { setShowOfferteModal(true) }}
                            >Offerte opladen</div>
                        </Space>
                    )
                }
                {offerAllStatus?.status === HouseOwnerListingStatus.offerAccepted && (
                    <div
                        onClick={() => { setShowInvoiceModal(true) }}
                    >Factuur opladen</div>
                )}
                {selectDateModal && (
                    <SelectDateModal
                        open={selectDateModal}
                        loading={loadingDate}
                        onDateSelected={hanldeDateSubmit}
                        setOpen={setSelectDateModal}
                        initialDateTime={item?.visitPlanDateTime}
                    />
                )}
                {contextHolder}
            </div>
        ) : (<div></div>)
    )

}
export default HomeOwnerAction
