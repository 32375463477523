import {isEmpty} from "lodash";
import React, {FC, useEffect, useState} from "react";

import CheckBoxIcon from "../../form/checkbox";
import OfferService from "../../../api/Offers";
import {PrimaryButton} from "../../../shared/buttons/igemo_button";
import {message} from "antd";


interface offerStruct {
    servicesList: any,
    refreshData: () => void
}

const CompanyInfoOffer:FC<offerStruct> = ({
    servicesList,
    refreshData
}) => {
    const [isLoading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedIds, setSelectedIds] = useState<any>([]);
    const [addedIds, setAddedIds] = useState<any>([]);
    const [contractorDetails, setContractorDetails] = useState<any>({});

    useEffect(() => {
        fetchContractorDetails();
    }, []);

    const seeIfServiceIsChecked = (contractorDetails: any) => {
        if (!isEmpty(contractorDetails)) {
            const list: any = [];
            const auth = JSON.parse(localStorage.getItem('auth') || '{}');

            servicesList?.services[0]?.children?.map((s: any) => {
                if (contractorDetails?.find((c:any) => c?.serviceId === s?.id)) {
                    list.push({
                        contractorId: auth?.company?.id,
                        serviceId: s?.id
                    });
                }
            });
            setSelectedIds([
                ...selectedIds,
                ...list,
            ]);
        }
    };

    const fetchContractorDetails = async () => {
        const auth = JSON.parse(localStorage.getItem('auth') || '{}');
        try {
            setLoading(true)
            const response = await OfferService.getContractorServiceOffers(auth?.company?.id || '6');
            const {data} = response;

            seeIfServiceIsChecked(data);
            setContractorDetails(data);
        } catch (error) {
            setLoading(false)
            console.error(error);
        } finally {
            setLoading(false)
        }
    };

    const handleServiceAdd = (item: any) => {  
        const auth = JSON.parse(localStorage.getItem('auth') || '{}');
        const index = selectedIds?.findIndex((f:any) => f.serviceId === item.id);
        const indexAdded = addedIds?.findIndex((f:any) => f.serviceId === item.id);

        if (index > -1) {
            const updatedIds = [...selectedIds];
            updatedIds.splice(index, 1);
            setSelectedIds(updatedIds);
        } else {
            setSelectedIds([
                ...selectedIds,
                {
                    contractorId: auth?.company?.id,
                    serviceId: item.id
                }
            ]);
        }

        if (indexAdded > -1) {
            const updatedIds = [...addedIds];
            updatedIds.splice(index, 1);
            setAddedIds(updatedIds);
        } else {
            setAddedIds([
                ...addedIds,
                {
                    contractorId: auth?.company?.id,
                    serviceId: item.id
                }
            ]);
        }
    };

    const updateContractorDetails = async() => {
        const alreadyAddedIds = () => {
            const arrayToRemove:any = [];
            const arrayToAdd:any = [];
            if (addedIds?.length) {
                addedIds.forEach((a: any) => {
                    const index = contractorDetails.findIndex((c: any) => c.serviceId === a.serviceId);
                    if (index > -1) {
                        arrayToRemove.push(a);
                    } else {
                        arrayToAdd.push(a);
                    }
                });
            }
            return {
                arrayToRemove,
                arrayToAdd,
            };
        };

        const items: any = alreadyAddedIds();
        let removeStatus=null,addStatus=null;

        if (items.arrayToRemove.length) {
            removeStatus = await removeIds(items.arrayToRemove);
        }

        if (items.arrayToAdd.length) {
            addStatus = await addIds(items.arrayToAdd);
        }
        if(removeStatus || addStatus){
            messageApi.open({
                type: 'success',
                content: 'Update van aanbiedingsdetails is gelukt.',
                duration: 3,
            });
            refreshData();
        }else if(removeStatus!==null || addStatus !== null){
            messageApi.open({
                type: 'error',
                content: 'Kan de aanbiedingsdetails niet updaten.',
                duration: 3,
            });
        }
    };

    const addIds = async (ids: any) => {
        try {
            const response = await OfferService.addServicesForContractor(ids, {});
            if (response?.data?.length) {
                setSelectedIds([]);
                return true
            } else {
                return false
            }
        } catch (error) {
            console.error(error);
            return false
        } finally {
        }
    }

    const removeIds = async (ids: any) => {
        try {
            const response = await OfferService.removeServicesForContractor(ids, {});
            if(response.success){
                setSelectedIds([]);
                return true;
            }else return false
            
            
        } catch (error) {
            console.error(error);
            return false
        } 
    }

    return (
        <div className="w-full border-t-[1px] border-t-[grey]/[0.22] mt-12">
            {contextHolder}
            <div>
                <p className="text-gray-300 mt-[15px]">
                    ADMINISTRATIEVE OFFERTE-ONDERDELEN
                </p>
            </div>
            {isLoading ? (
                <div className="px-16 py-16" style={{ textAlign: 'center' }}>Laden...</div>
            ) : (
                <div className="py-4 grid grid-cols-2 gap-y-3">
                    {!isEmpty(servicesList) ? (
                        <>
                            {servicesList?.services[0]?.children?.map((s: any) => {
                                const isSelected = selectedIds?.filter((e: any) => e.serviceId === s?.id);
                                return (
                                    <div className="grid grid-cols-12">
                                        <div className="col-span-1">
                                            <CheckBoxIcon
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    handleServiceAdd(s);
                                                }}
                                                variant={
                                                    !isEmpty(isSelected) ? "all" : "none"
                                                }
                                            />
                                        </div>
                                        <div className="col-span-11">
                                            {s?.name}
                                        </div>
                                    </div>
                                )
                            })}

                            <div className='mt-8'>
                                <PrimaryButton
                                    content="Opslaan"
                                    onClick={updateContractorDetails}
                                />
                            </div>
                        </>
                    ) : (
                        <div className="px-16 py-16" style={{ textAlign: 'center' }}>Geen data.</div>
                    )}
                </div>
            )}
        </div>
    );
}

export default CompanyInfoOffer;
