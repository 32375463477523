import HttpService from "../constants/HttpService";

class DomainConfigService extends HttpService {
    constructor() {
        super('domain-config');
    }

    // additional services
    getDomainObj = async (domainId: String) => {
        const response = await this.client.get(`${this.endPoint}/domain/${domainId}`)
        return response
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DomainConfigService()
