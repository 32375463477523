import React, {Dispatch, FC, SetStateAction, useEffect, useState, useRef} from "react";
import {ModalBox} from "../../shared/modals/modal";
import {Email, Input} from "../../shared/forms/input";
import {message} from "antd";
import {PrimaryButton} from "../../shared/buttons/igemo_button";
import {validateEmail} from "../../utils/validations";
import {PostAPIService} from "../../context/services";
import {useCookies} from "react-cookie";
import { renderToString } from 'react-dom/server';
import pdfDom from '../../containers/pdfDom/index';
import Spinner from "../../shared/elements/Spinner";
interface AddEmail {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    pdfHtml:any
    setPdfDataUpdate:any
    setPdfDataHTML:any
}

export const AddEmail: FC<AddEmail> = ({open,setOpen,pdfHtml,setPdfDataUpdate,setPdfDataHTML}) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [cookies, setCookies] = useCookies();
    const [sessionId, setSessionId] = useState('');
    console.log(pdfHtml,'pdfHtml in add email')
    useEffect(()=>{
        setSessionId(cookies.sessionData.id)
    }, [cookies])
    const getEmail = (event: any) => {
        setEmail(event.target.value);
    }
    const handleSubmit = async () => {
        if(validateEmail(email)){
            setError('');
            setLoading(true);
            await PostAPIService('pdf/generate',{pdfDom:pdfHtml,email}).then((response)=>{
                if(response.data.success === true){
                    // messageApi.open({
                    //                 type: 'success',
                    //                 content: 'Email succesvol verzonden.',
                    //                 duration: 10,
                    //             });
                    
                                let pdf = response?.data?.data?.pdfName
                                PostAPIService(`pdf/form-result/send/`, {pdfName:pdf , email})
                                .then((res:any) => {
                                    if(res.data.success == true){
                                    messageApi.open({
                                        type: 'success',
                                        content: 'Email succesvol verzonden.',
                                        duration: 3,
                                    });
                                setLoading(false);
                                        setOpen(false);
                                                   setPdfDataUpdate(false) 
                                                   setPdfDataHTML("")  }else{
                    setLoading(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Fout bij verzenden van e-mail, probeer het later opnieuw.',
                        duration: 3,
                    });
                }
            })
                                        
                }
                       else{
                    setLoading(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Fout bij verzenden van e-mail, probeer het later opnieuw.',
                        duration: 3,
                    });
                }
            })
            // renderToString(pdfDom)
            console.log(pdfHtml,'pdfHtml in addemail')
            // PostAPIService(`pdf/form-result/send/${sessionId}`, {email})
            //     .then((response) => {
            //     if(response.data.success == true){
            //         messageApi.open({
            //             type: 'success',
            //             content: 'Email succesvol verzonden.',
            //             duration: 10,
            //         });
            //         setLoading(false);
            //         setOpen(false);
            //     }else{
            //         setLoading(false);
            //         messageApi.open({
            //             type: 'error',
            //             content: 'Fout bij verzenden van e-mail, probeer het later opnieuw.',
            //             duration: 10,
            //         });
            //     }
            // })
        }else{
            setError('E-mailformaat ongeldig');
        }
    }
    return (
        <ModalBox
            width={'auto'}
            extraClasses="lg:w-[30%] w-full"
            title="Ontvang je plan via e-mail"
            cancelHandler={() => setOpen(false)}
            open={open}>
            <div className="border-grey-300 border-b-[1px]">
                <div className="px-8 py-12">
                    <div className="mt-5 flex grid-cols-4 lg:grid-cols-8 gap-4">
                        <Email classes="col-span-2" name={"email"} label={"EMAIL"} outerError={error} onChangeHandler={(event) => {getEmail(event)}}
                               active={true} full={true}/>
                    </div>
                </div>
            </div>
            <div className="px-8">
                <div className="flex justify-end items-center gap-4 mt-4">
                    {loading ? <Spinner className="w-7 h-7" /> : null }
                    <PrimaryButton disabled={loading} content="Verstuur" onClick={handleSubmit}/>
                </div>
            </div>
            {contextHolder}
        </ModalBox>

    )
}
