import React, {FC, useState, useEffect} from "react";
import {Tag} from "../../../shared/elements/tag";
import {GetAPIService} from "../../../context/services";
import {logoUrl, uploadsUrl} from "../../../shared/Table/common";
import CompanyInfoOffer from "./companyInfoOffer";


interface infoStruct {
    servicesList: any,
    userId: string;
    setContactPersonInfo:(data: any)=> void;
    refreshData: () => void;
}

export const CompanyInfo: FC<infoStruct> = ({
    servicesList,
    userId,
    setContactPersonInfo,
    refreshData
}) => {
    const auth = JSON.parse(localStorage.getItem("auth") || "{}");
    const [companyInfo, setCompanyInfo] = useState<any>({})
    const [loading, setLoading] = useState<any>(false)
    const [companyWorkPlacesInfo, setCompanyWorkPlacesInfo] = useState<any>({})
    const [data, setData] = useState<any>({
        logo: '',
        name: '',
        btwNumber: '',
        labels: [],
        certificates: [],
        email: "",
        phone: '',
        address: '',
        muni: '',
        postcode: '',
        provinces: [],
        muniServices: [],
        description: '',
        services: []
    })

    useEffect(()=> {
        if (userId) {
            setLoading(true)
            GetAPIService(`contractor/${userId}`).then((res: any) => {
                if (res?.data) {
                    setCompanyInfo(res?.data)
                     if (res?.data?.cpContractorUsers?.length > 0) {
                         setContactPersonInfo(res?.data?.cpContractorUsers[0].user)
                     }
                     setLoading(false)
                }
            }).catch((err: any) => {
                console.log(err)
            })
        }
    }, [userId])

    useEffect(()=> {
        if (userId) {
            GetAPIService(`contractor/city/${userId}`).then((res: any) => {
                console.log('data new is ', res)
                if (res?.data?.length)
                    setData((prevState: any) => {
                        return {
                            ...prevState,
                            provinces: res?.data[0]?.cpContractorWorkplaces.map((workPlace: any) => {
                                return {
                                    allCities: workPlace.full_region,
                                    cities: workPlace.cpContractorWorkplaceCities.map((cityObj: any) => {
                                        const newCity = cityObj?.city?.nl || cityObj?.city?.fr;
                                        return {
                                            city: newCity ? newCity.replace('\n', '') : '',
                                            // zip: cityObj?.city?.zipCode
                                        }
                                    }),
                                    region: workPlace?.region?.nl || workPlace?.region?.fr
                                }
                            })
                        }
                    })
                else {
                    console.log("no data for company")
                }
            }).catch((err: any) => {
                console.log("Err: ", err)
            })
            GetAPIService(`contractor/renovation/${userId}`).then((res: any) => {
                console.log('data new rens is ', res)
                if (res?.data)
                    setData((prevState: any) => {
                        return {
                            ...prevState,
                            services: res?.data?.cpContractorRenovations.map((renovation: any) => renovation.renovation.title)
                        }
                    })
                else {
                    console.log("no data for company")
                }
            }).catch((err: any) => {
                console.log("Err: ", err)
            })
        }
    }, [userId])

    useEffect(() => {
        if (Object.keys(companyInfo).length) {
            setData({
                ...data,
                logo: companyInfo?.logo,
                name: companyInfo?.name,
                btwNumber: companyInfo?.vat,
                labels: companyInfo?.cpContractorLabels?.map((labelObj: any) => {
                    return {
                        logo: labelObj?.label?.logo,
                        label: labelObj?.label?.label
                    }
                }),
                certificates: companyInfo?.cpContractorCertificats?.map((certiObj: any) => {
                    return {
                        logo: certiObj?.certificat?.logo,
                        label: certiObj?.certificat?.certificat
                    }
                }),
                email: companyInfo?.email,
                phone: companyInfo?.phone,
                address: companyInfo?.street?.nl || companyInfo?.street?.fr,
                muni: companyInfo?.city?.nl || companyInfo?.city?.fr,
                postcode: companyInfo?.street?.zipCode,
                description: companyInfo?.description,
                // services: []
            })
        }
    }, [companyInfo])

    return (
        <>
        {
            loading ?
                <span>
                    Loading ....
                </span>
                :
                <>
                    <div className="pt-12 text-xs font-semibold">
                        <div className="grid grid-cols-12">
                            <div className="col-span-12 md:col-span-4 lg:col-span-2">
                            <span className="ml-8 text-gray-300">
                                LOGO
                            </span>
                                <img
                                    className="w-32 mt-1"
                                    src={uploadsUrl(data?.logo) || "https://img.freepik.com/premium-vector/logo-design-construction-service-architecture_304830-442.jpg?w=2000"}
                                    alt="logo"
                                />
                            </div>
                            <div className="col-span-12 md:col-span-8 lg:col-span-10 text-gray-300">
                                <div className="grid grid-cols-12 gap-4">
                                    <div className="grid col-span-6 lg:col-span-3">
                                    <span>
                                        FIRMA
                                    </span>
                                        <span className="mt-1 text-gray-700 font-medium">
                                        {data.name}
                                    </span>
                                    </div>
                                    <div className="grid col-span-6 lg:col-span-3">
                                    <span>
                                        BTW NUMMER
                                    </span>
                                        <span className="mt-1 text-gray-700 font-medium">
                                        {data.btwNumber}
                                    </span>
                                    </div>
                                    <div className="grid col-span-6 lg:col-span-3">
                                    <span>
                                        LABEL
                                    </span>
                                        <span className="mt-1 text-gray-700 font-medium flex">
                                        {
                                            data?.labels.map((label: any) => {
                                                return (
                                                    label.logo &&
                                                    <img width={20} height={20} src={uploadsUrl(label.logo)}
                                                         alt={label.label}/>
                                                )
                                            })
                                        }
                                    </span>
                                    </div>
                                    <div className="grid col-span-6 lg:col-span-3">
                                    <span>
                                        CERTIFICATEN
                                    </span>
                                        <span className="mt-1 flex text-gray-700 font-medium">
                                        {
                                            data?.certificates.map((certificate: any) => {
                                                console.log(certificate)
                                                return (
                                                    certificate.logo &&
                                                    <img width={20} height={20} src={uploadsUrl(certificate.logo)}
                                                         alt={certificate.label}/>
                                                )
                                            })
                                        }
                                    </span>
                                    </div>
                                </div>
                                <div className="mt-10 grid grid-cols-12 gap-4">
                                    <div className="grid col-span-6 lg:col-span-3">
                                    <span>
                                        EMAIL
                                    </span>
                                        <span className="mt-1 text-gray-700 font-medium">
                                        {data.email}
                                    </span>
                                    </div>
                                    <div className="grid col-span-6 lg:col-span-3">
                                    <span>
                                        TELEFOONNUMMER
                                    </span>
                                        <span className="mt-1 text-gray-700 font-medium">
                                        {data.phone}
                                    </span>
                                    </div>
                                    <div className="grid col-span-6 lg:col-span-3">
                                    <span>
                                        ADRES
                                    </span>
                                        <span className="mt-1 text-gray-700 font-medium">
                                    {data.address}
                                </span>

                                    </div>
                                    <div className="grid col-span-4 lg:col-span-2">
                                    <span className="text-gray-300">
                                        GEMEENTE
                                    </span>
                                        <span className="mt-1 text-gray-700 font-medium">
                                    {data.muni}
                                </span>
                                    </div>
                                    <div className="grid col-span-2 lg:col-span-1">
                                    <span className="text-gray-300">
                                        POSTCODE
                                    </span>
                                        <span className="mt-1 text-gray-700 font-medium">
                                    {data.postcode}
                                </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border-gray-300 mt-10 border-t[1px] mb-12">
                            <div className="grid md:grid-cols-12 col-span-12 w-full">
                                <div className="col-span-2">
                            <span>
                                REGIO
                            </span>
                                </div>
                                <div className="col-span-10">
                                    <div>
                                        {
                                            data.provinces.map((province: any) => {
                                                return (
                                                    <div className="flex mr-3 mb-2 text-gray-500 font-medium">
                                                        <Tag onClick={() => {
                                                        }} active={false} text={province.region}/>
                                                        <div
                                                            className="ml-3 flex justify-center flex-wrap items-center text-xs text-gray-400 underline">
                                                            {province.allCities ?
                                                                <span>
                                                            Alle gemeente
                                                        </span>
                                                                :
                                                                <>
                                                                    {
                                                                        province.cities.map((city: any) => {
                                                                            return (
                                                                                <span className="mr-2"
                                                                                      key={city.city}>
                                                                            {city.zip}
                                                                                    <span className="ml-1">
                                                                                {city.city}
                                                                            </span>
                                                                        </span>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid lg:grid-cols-12 col-span-12 gap-4">
                            <div className="col-span-2">
                                <p className="text-gray-300">
                                    BESCHRIJVING
                                </p>
                                <p className="mt-1 text-gray-500 font-medium">
                                    {data?.description}
                                </p>
                            </div>
                            <div className="col-span-10 flex flex-col flex-wrap">
                                <p className="text-gray-300">
                                    DIENSTEN
                                </p>
                                <div className="mt-1 w-full flex flex-wrap gap-y-3">
                                    {
                                        data.services.map((service: any) => {
                                            return (
                                                <div className="mr-3 text-gray-500 font-medium">
                                                    <Tag onClick={() => {
                                                    }} active={false} text={service}/>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <CompanyInfoOffer servicesList={servicesList} refreshData = {refreshData} />
                </>
        }
        </>
    )
}
