import { AutoComplete, Form } from 'antd';
import React, { useState } from 'react';


export const AutoCompleteModalDropDown: React.FC<{
    disabled?: boolean;
    placeholder: string;
    defaultValue: string;
    selectedValue?: string;
    possibleValues: Array<{ id: string; value: string }>;
    onChangeHandler: (text: string) => void;
    onSelectHandler: (text: string, options: any) => void;
    classes?: string;
    errors?:string
}> = ({
    disabled,
    placeholder,
    possibleValues,
    onChangeHandler,
    onSelectHandler,
    defaultValue,
    classes="",
    errors
                 }) => {

  return (
    <>
     <Form.Item className={classes} name={defaultValue}>
      <AutoComplete
        disabled={disabled}
        options={possibleValues}
        style={{ width: "100%", border: "1px solid #E5E7EB", borderRadius: "4px" }}
        onSearch={(text: string)=> onChangeHandler(text)}
        onSelect={onSelectHandler}
        size='large'
        placeholder={<span className=" text-gray-300 font-medium text-sm" >{placeholder}</span>}
        bordered={false}
      />
      
    </Form.Item>
      <br />
      <span className="text-error text-xs mt-1 ml-2">
        {errors}
      </span>
      <br />
    </>
  );
};
