import HttpService from "../constants/HttpService";
import {URLQueryParams} from "../constants/queryParams";

class OffersService extends HttpService {
    constructor() {
        super('services');
    }

    getAllServiceOffers = async () => {
        const { data } = await this.client.get(`${this.endPoint}`);
        return data;
    }

    getAllServiceRenovationsForContractor = async (id: any) => {
        const { data } = await this.client.get(`${this.endPoint}/renovations?contractorId=${id}`);
        return data;
    }

    getAllServiceRenovationsOffers = async (offerReqId: string, renovationId: string) => {
        const { data } = await this.client.get(`${this.endPoint}/offer-request/${offerReqId}?renovationId=${renovationId}`);
        return data;
    }

    getContractorServiceOffers = async (id: string) => {
        const { data } = await this.client.get(`${this.endPoint}/contractor/${id}`);
        return data;
    }

    addServicesForContractor = async (payload: any, others: any) => {
        const { data } = await this.client.post(`${this.endPoint}/contractor`, payload, {...others})
        return data
    }

    removeServicesForContractor = async (payload: any, others: any) => {
        const { data } = await this.client.post(`${this.endPoint}/remove-contractor-services`, payload, {...others})
        return data
    }

    setNotApplicableForContractor = async (payload: any, others: any) => {
        const { data } = await this.client.put(`${this.endPoint}/update`, payload, {...others})
        return data
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new OffersService();
