import React, { useState, useEffect } from 'react'
import ListCardExpanded from './listCardExpanded';
import HouseOne from "../assets/picture/hp/house.png";
import { AiFillCloseCircle } from 'react-icons/ai';
import { crossRed, ServiceItem, tickGreen } from "../containers/servicesList/formCategories";
import {logoUrl, uploadsUrl} from "./Table/common";

type UserList = [
    {
        firstName: string,
        lastName: string
    }
]

type contractorCertificateType = {
    certificate: {
        certificat: string,
        id: string,
        logo: string | null,
    },
    contractorId: string,
    certificatId: string,
    id: string
}

type contractorLabelsType = {
    id: string,
    labelId: string,
    contractorId: string,
    label: {
        id: string,
        label: string,
        logo: string | null
    }
}

type contractorRenovationType = {
    id: string,
    renovationId: string,
    contractorId: string
}

type contractorListType = {
    archiveContractor: number,
    archiveRequestor: number,
    contractor: {
        address1: string | null,
        address2: string | null,
        cityId: string,
        contract: string | null,
        createdAt: string | null,
        deletedAt: string | null,
        description: string | null,
        email: string,
        id: string,
        logo: string,
        name: string,
        phone: string,
        status: string | null,
        updatedAt: string | null,
        streetId: string | null,
        users: UserList,
        cpContractorCertificats?: contractorCertificateType[],
        cpContractorLabels?: contractorLabelsType[],
        cpContractorRenovations?: contractorRenovationType[],
    },
    contractorId: string,
    createdAt: string,
    deletedAt: string | null,
    groupPurchase: number,
    id: string,
    mentorship: number,
    offerRequestRenovationId: string,
    selected: null,
    statusId: string,
    updatedAt: string | null
}

type renovationDataType = {
    createdAt: string,
    deletedAt: string | null,
    id: string,
    offerRequestId: string,
    renovation: {
        createdAt: string,
        deletedAt: string | null,
        id: string,
        key: string,
        logo: string,
        title: string,
        updatedAt: string | null
    },
    renovationId: string,
    updatedAt: string | null
}

interface ListItem {
    title: String,
    key: string
    details: String,
    isGreen?: boolean,
    offerRequestId?: string,
}

interface CardProps {
    item: ListItem,
    generalGreen?: boolean,
    renovationGreen?: boolean,
    contractor?: boolean,
    buttons: any,
    selected: boolean,
    listRowClickHandler: (rowId: string | number)=> void,
    contractorList?: [contractorListType],
    handleContractorAdd?: (renovationId: number, contractorId: number) => void,
    renovationData: renovationDataType,
    getCardDescription: (key: string) => React.ReactNode,
    renovationIds?:string[],
    contractorsData?:any[],
    reviewModalState?: any,
    setReviewModalState?: (data: any) => void;
}

type ButtonCardProps = {
    title: string,
    logo?: string,
    onClick?: () => void,
    renovationId?: string,
    contractorId?: string,
    handleContractorAdd?: (renovationId: number, contractorId: number) => void
}

const ButtonCard = ({ title, logo, onClick = () => {}, renovationId, contractorId, handleContractorAdd = () => {} }: ButtonCardProps ) => {

    const handleContractUnselect = () => {
        if(contractorId) {
            handleContractorAdd(parseInt(renovationId || "0"), parseInt(contractorId || "0"));
        }
    }

    return(
        <div className = "h-[36px] min-w-[150px] bg-white shadow box-border px-[6px] flex justify-between items-center relative mr-[10px] mt-[10px] md:mt-[0px]">
            <div className = "flex items-center">
                <img className = "h-[23px] w-[23px] object-fit rounded-[23px] overflow-hidden" src = {logo} />
                <p className = "font-Source font-regular text-[12px] text-[#5F5F5F] ml-[5px]">{title}</p>
            </div>
            <div className = "flex flex-col cursor-pointer ml-[10px]" onClick={onClick}>
                <div className = "h-[4px] w-[4px] bg-black rounded-[5px]" />
                <div className = "h-[4px] w-[4px] bg-black rounded-[5px] my-[3px]" />
                <div className = "h-[4px] w-[4px] bg-black rounded-[5px]" />
            </div>
            <button type = "button" className = "cursor-pointer absolute top-[-7px] right-[-7px]" onClick={handleContractUnselect}>
                <AiFillCloseCircle size = "15px" color = "#4F4C4C" className = "opacity-[0.5]" />
            </button>
        </div>
    )
}

const ListCard = ({ item, buttons, selected, listRowClickHandler, contractorList, handleContractorAdd = () => {}, renovationData, generalGreen = false, renovationGreen = false, contractor = false, getCardDescription,renovationIds,contractorsData, reviewModalState, setReviewModalState = () => {}}: CardProps) => {
    const [ selectedContractors, setSelectedContractors ] = useState<contractorListType[]>([]);

    useEffect(() => {
        if(contractorList && contractorList.length > 0) {
            let selectedContractors: contractorListType[] = [];
            contractorList.forEach((contractor: contractorListType) => {
                if(contractor.selected === 1) {
                    selectedContractors.push(contractor);
                }
            })
            setSelectedContractors(selectedContractors);
            console.log(item)
        }
    }, [contractorList])

    const isGreen = () => {
        if(generalGreen && renovationGreen && contractor) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <div className="flex flex-col mb-2 mr-1 mt-[15px] md:mt-[0px]" style={{ boxShadow: '0px 0px 10px #0000000F' }}>
            <div className = "w-full md:h-[122px] flex flex-col md:flex-row relative">
                <div className = "hidden md:block" style={{ width: 54 }} >
                    <div className={`text-[#ffffff] text-center px-4 ${isGreen() ? "primary-main" : "bg-[#dcdbdb]"}`} style={{
                        transform: 'rotate(180deg)',
                        fontSize: 12, height: '100%',
                        writingMode: 'vertical-lr',
                        textOrientation: 'sideways',
                        textTransform: 'uppercase',
                    }}>
                        Offerteanvraag
                    </div>
                </div>
                <div className = "w-full md:hidden flex bg-primary box-border justify-center items-center box-border py-[10px] uppercase" style = {{backgroundColor: isGreen() ? 'rgb(41 135 82 / var(--tw-bg-opacity))': '#dcdbdb'}}>
                    <p className = "font-Source font-semibold text-white text-[12px]">Offerteanvraag</p>
                </div>
                <div className = "hidden md:block" style={{ width: 150, minWidth: 150 }} >
                    <img src={renovationData.renovation.logo} alt="House's logo" className = "h-full object-contain" height={140}/>
                </div>
                <div className = "w-full flex justify-between md:hidden">
                    <div className = "flex w-full items-center flex-wrap box-border px-[10px]" style={{ justifyContent: 'center' }}>
                        {selectedContractors && selectedContractors?.length > 0 && selectedContractors.map((contractor: contractorListType) => (
                            <ButtonCard onClick={()=> listRowClickHandler(contractor.contractor.id)} title = {contractor.contractor.name} logo = {uploadsUrl(contractor.contractor.logo)} renovationId = {renovationData.renovationId} contractorId = {contractor.contractorId} handleContractorAdd = {handleContractorAdd} />
                        ))}
                    </div>
                </div>
                <div className = "w-full flex justify-between items-center md:hidden">
                    <img src={renovationData.renovation.logo} alt="House's logo" className = "h-full object-contain" height={140}/>
                </div>
                <div className="px-1 pt-4 w-full hidden md:block">
                    <div className = "w-full flex justify-between items-center">
                        <h1 className="pb-2 text-lg sm:text-lg md:text-xl xl:text-xl  2xl:text-2xl font-semibold border-igemo">
                            {item.title}
                        </h1>
                        <div className = "flex items-center">
                            {selectedContractors && selectedContractors?.length > 0 && selectedContractors.map((contractor: contractorListType) => (
                                <ButtonCard onClick={()=> listRowClickHandler(contractor.contractor.id)} title = {contractor.contractor.name} logo = {uploadsUrl(contractor.contractor.logo)} renovationId = {renovationData.renovationId} contractorId = {contractor.contractorId} handleContractorAdd = {handleContractorAdd} />
                            ))}
                        </div>
                    </div>

                    <div className = "flex flex-col">
                        {getCardDescription(item.key.toLowerCase())}
                    </div>
                </div>
                <div className = "w-full flex md:hidden">
                    <div className = "flex flex-col w-full box-border px-[10px]">
                        <h1 className="pb-2 text-lg sm:text-lg md:text-xl xl:text-xl  2xl:text-2xl font-semibold border-igemo text-center">{item.title}</h1>
                        <div className = "flex w-full justify-center items-center">
                            <div className = "flex flex-col pb-[10px] text-center">
                                {getCardDescription(item.key.toLowerCase())}
                            </div>
                        </div>
                        <div className = "flex w-full justify-center items-center">
                            <div className = "flex flex-col">
                                <div className="md:hidden flex md:justify-end">
                                    <div style={{ width: 36 }} className="sm:pr-2">
                                        <div className="text-center">
                                            {generalGreen ? tickGreen : crossRed}
                                        </div>
                                        <div className="text-center">
                                            {renovationGreen ? tickGreen : crossRed}
                                        </div>
                                        <div className="text-center">
                                            {contractor ? tickGreen : crossRed}
                                        </div>
                                    </div>
                                    <div className="sm:pr-2 md:pr-2">
                                        {buttons}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden md:flex md:justify-end">
                    <div style={{ width: 36 }} className="sm:pr-2">
                        <div className="text-center mt-3">
                            {generalGreen ? tickGreen : crossRed}
                        </div>
                        <div className="text-center mt-2">
                            {renovationGreen ? tickGreen : crossRed}
                        </div>
                        <div className="text-center mt-2">
                            {contractor ? tickGreen : crossRed}
                        </div>
                    </div>
                    <div style={{ width: 175, minWidth: 175 }} className="pt-2 sm:pr-2 md:pr-2">
                        {buttons}
                    </div>
                </div>
            </div>
            <ListCardExpanded isOpen = {selected} rowClickHandler={listRowClickHandler} contractorData = {contractorList} handleContractorAdd = {handleContractorAdd} renovationId = {renovationData?.renovationId} isClickable renovationIds={renovationIds} contractorsData={contractorsData} reviewModalState = {reviewModalState} setReviewModalState = {setReviewModalState} />
        </div>
    )
}

export default ListCard;
