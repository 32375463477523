import React, { useState,useEffect } from "react";
import { useCookies } from 'react-cookie'
import { getOverviewValues,getObjValuesSum,getLoan } from "../../utils/reultHelpers";
import Pieces from "../../assets/pieces.png";
type priceCalculationProps = {

}







const Overview = ({  }: priceCalculationProps) => {

    const [cookies, setCookies] = useCookies()
    const [co2Value, setCO2Value] = useState(0)
    const [minderEnergie, setMinderEnergieValue] = useState(0)
    const [gas, setGasValue] = useState(0)
    const [electricity, setElectricityValue] = useState(0)
    const [bespaar, setBespaarValue] = useState(0)
    const [loan, setLoan] = useState(0)
    const results:any =  localStorage.getItem('result')
    const values:any = JSON.parse(results);
    const minderEnergieZonnepanelen = values?.minderEnergie?.zonnepanelen || 0
    const invester = JSON.parse(localStorage.getItem('Invester')!)
    const bespar = JSON.parse(localStorage.getItem('Bespaar')!)
    // console.log(bespar,'bespar');
    const premies = JSON.parse(localStorage.getItem('Premies')!)
    const minder = JSON.parse(localStorage.getItem('MinderEnergie')!)
    const gasLocal = JSON.parse(localStorage.getItem('PagesEnergyGas')!)
    // console.log(gasLocal,'gasLocal');
    const electricLocal = JSON.parse(localStorage.getItem('PagesEnergyElec')!)
    const co2 = JSON.parse(localStorage.getItem('Co2')!)
    const investeerVal = getObjValuesSum(invester !== null && invester !== undefined ? invester : 0)
    const bespaarVal = getObjValuesSum(bespar !== null && bespar !== undefined ? bespar : 0)
    //   console.log(bespaarVal,'bespaarVal');
    const premiesVal = getObjValuesSum(premies !== null && premies !== undefined ? premies : 0)
    const minderEnergieVal = getObjValuesSum(minder !== null && minder !== undefined ? minder : 0)
    const gasVal = getObjValuesSum(gasLocal !== null && gasLocal !== undefined ? gasLocal : 0)
    const electricityVal = getObjValuesSum(electricLocal !== null && electricLocal !== undefined ? electricLocal : 0)
    const co2Val = getObjValuesSum(co2 !== null && co2 !== undefined ? co2 : 0)
    const localAnswers:any =  localStorage.getItem('localState')
    const localState = JSON.parse(localAnswers)
    const localImprovements = JSON.parse(localAnswers)
    useEffect(() => {
        getOverviewValues(
            localState,
            localImprovements,
            setMinderEnergieValue,
            setCO2Value,
            setBespaarValue,
            setElectricityValue,
            setGasValue,
            cookies?.userId,
        //   values,
        )
    }, [localImprovements,invester,bespar,premies,minder,gasLocal])


    useEffect(() => {
        const maximumbedragEnergielening = Number(localStorage.getItem('maximumbedragEnergielening'))

        const amount = Number(investeerVal) > maximumbedragEnergielening ? maximumbedragEnergielening : Number(investeerVal);

     
          (async () => {
            const loanAmount =await getLoan(amount)
            setLoan(loanAmount)
        })();

        
    }, [investeerVal])


    return (
//@ts-ignore
        <div className="w-full min-h-[314px] flex row box-border px-[50px] primary-main rounded-[10px]">
            <div className="w-full flex flex-1 items-center">
                <p className="font-sans font-semibold text-white text-[28px]">Overzicht van je besparing</p>
            </div>
            <div className="flex flex-col justify-center flex-[1.5] box-border">
                <div className="w-full flex justify-between">
                    <p className="font-sans font-regular text-white text-[22px]">Ik investeer</p>
                    <p className="font-sans font-regular text-white text-[22px]">€ {Math.round(investeerVal? investeerVal : 0)}</p>
                </div>
                {/* <div className="w-full flex justify-between ">
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik betaal met de energielening *</p>
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">€ {Number.isNaN(Math.round(loan)) ? 0 :Math.round(loan)} / maand</p>
                </div> */}
                <div className="w-full flex justify-between my-[10px]">
                    <p className="font-sans font-regular text-white text-[22px]">Ik ontvang premies</p>
                    <p className="font-sans font-regular text-white text-[22px]">€ {Math.round(premiesVal ? premiesVal : 0)}</p>
                </div>
                <div className="w-full flex justify-between">
                    <p className="font-sans font-regular text-white text-[22px]">Ik verbruik minder elektriciteit</p>
                    <p className="font-sans font-regular text-white text-[22px]">{Math.round(electricityVal ? electricityVal: 0) +
                      Math.round(minderEnergieZonnepanelen)}{' '} kWh/jaar </p>
                </div>
                {/* <div className="w-full flex justify-between ">
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik verbruik minder gas</p>
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">{Math.round(gasVal ? gasVal : 0)} kWh/jaar</p>
                </div>
                <div className="w-full flex justify-between ">
                    <p className="font-sans font-semibold text-black text-[16px] mb-5">Ik bespaar CO2</p>
                    <p className="font-sans font-semibold text-black text-[16px] mb-5">{Math.round(co2Val? co2Val :0)} kg</p>
                </div> */}
                <div className="w-full flex justify-between mt-[10px]">
                    <p className="font-sans font-regular text-white text-[22px]">Ik bespaar</p>
                    <p className="font-sans font-regular text-white text-[22px]">€ {Math.round(bespaarVal) ? bespaarVal : 0} / jaar</p>
                </div>
                {/* <div className="w-full flex justify-between ">
                    <p className="font-sans font-regular text-black text-[12px] mt-5">(*) Mijn VerbouwLening: Maximum €60.000, looptijd 20 jaar (maximaal 25), rentevoet 0%. Let op, geld lenen kost ook geld.</p>
                </div> */}
            </div>
        </div>


    )
};
export default Overview;