import { FC } from "react"
import {Form} from "antd";
import { CheckBoxInterfaceModal } from "../../models/applicationState"

export const ModalBoxCheckBox: FC<CheckBoxInterfaceModal> = ({ status, content, name, onChange, defaultChecked }) => (
    <label className="inline-flex items-center mt-[6px]">
        <Form.Item name={name}>
            <input checked={defaultChecked} name={name} onChange={onChange} type="checkbox" className="w-[20px] h-[20px] rounded-full text-light_green" />
        </Form.Item>
        <span className="ml-2 mb-[6px]">{content}</span>
    </label>
)
