import {isEmpty} from "lodash";
import React, {FC, useContext, useState, useEffect} from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { useLocation } from "react-router-dom";
import Lottie from "lottie-react";
import AnimationHouse1 from "../../assets/lottie/AnimationHouse1.json";
import AnimationHouse2 from "../../assets/lottie/AnimationHouse2.json";
import AnimationHouse3 from "../../assets/lottie/AnimationHouse3.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleRight } from '@fortawesome/free-solid-svg-icons'

import { SessionsUpdate } from "../../utils/session";
// pages
import { useCookies } from 'react-cookie'
import {AddressBar} from "../../shared/AddressBar";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import SetupService from "../../api/Setup";
import DomainService from "../../api/Domain";
import useTheme from "../../context/globalContext/useTheme";

interface RoutesInterface {}

/**
 * routes page to define all pages routes here
 */


interface SectionFormInterface {}

interface orgInterface {
  domain: domainInterface
  title: string
  text: string
}

interface domainInterface {
  partners: partnerInterface[]
}

interface partnerInterface {
  logo: string
}

const SectionForm: FC<SectionFormInterface> = () => {
  const [selectedStreet, setSelectedStreet] = useState<{id?: string; name?: string}>({id: undefined, name: undefined})
  const [selectedCity, setSelectedCity] = useState<{id?: string; name?: string}>({id: undefined, name: undefined})
  const [selectedZip, setSelectedZip] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const location = useLocation();
  // global state
  const { state,dispatch }: any = useContext(GlobalContext);
  const [cookies, setCookies] = useCookies();
  const [isLoading, setLoading] = useState(false);
  const [showAreaMessage, setAreaMessage] = useState(false);
  const [organizationData, setOrganizationData] = useState<orgInterface>();

  // theme values
  const theme = useTheme();

  useEffect(()=> {
    if (selectedCity.id)
      localStorage.setItem('city', selectedCity.id)
  }, [selectedCity])

  useEffect(()=> {
    if (selectedStreet.id)
      localStorage.setItem('street', selectedStreet.id)
  }, [selectedStreet])

  const handleSubmit = (event:any)  => {
    event.preventDefault();
    const adress = event?.target[0]?.value;
    const adress2 = event?.target[1]?.value;
    if(adress && adress2){
      localStorage.setItem('address', `${selectedStreet.name} , ${selectedZip} ${selectedCity.name}`)
      SessionsUpdate(state.session.id,{
        address:`${selectedStreet.name}, ${selectedZip} ${selectedCity.name}`,
        cityId: selectedCity.id,
        streetId: selectedStreet.id,
        zip: selectedZip
      }, cookies, setCookies, dispatch)
      setErrorMessage(null)
    }
    else if(adress && !adress2) {
      setErrorMessage("Gelieve een straat in te vullen")
    }
    else if(adress2 && !adress) {
      setErrorMessage("Gelieve een gemeente in te vullen")
    }
    else{
      SessionsUpdate(state.session.id,{address:null}, cookies, setCookies, dispatch)
      setErrorMessage(null)
    }
  }

  useEffect(() => {
    fetchOrganizationData();
  }, []);

  const fetchOrganizationData = async () => {
    try {
      setLoading(true)
      const domain = JSON.parse(localStorage.getItem('domainObj') || '{}');
      const response = await SetupService.getDomainDetails(domain?.id || '1');
      const {data} = response?.data;
      setOrganizationData(data[0]);
      setLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMunicipalityData = async (event:any) => {
    event.preventDefault();
    try {
      setLoading(true)
      setAreaMessage(false)
      const domain = JSON.parse(localStorage.getItem('domainObj') || '{}');
      console.log(domain)
      const response = await DomainService.getMunicipalityDetails({
        domainId: domain?.id || "1",
        municipalityId: selectedCity?.id,
      }, {});

      if (response?.outOfAreaService === true) {
        setAreaMessage(true)
        return;
      } else if (response?.outOfAreaService === false) {
        setAreaMessage(false)
        handleSubmit(event);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
      <section className="container  mx-auto  p-8 h-full ">
        <div className="md:flex">
          <div className="hidden md:flex md:w-1/4 items-center self-center	justify-items-center text-center	">
            <Lottie animationData={AnimationHouse1} loop={true} autoplay={true} className="" />
          </div>
          <div className="flex-auto md:w-2/4">
            <div className="flex items-center justify-center w-full ">
              <div className="w-full max-w-3xl mt-5 p-8 bg-white rounded-lg shadow-xl sm:p-8 md:p-12">
                <h1
                    className="text-xl md:text-2xl xl:text-2xl  2xl:text-3xl font-bold border-l-8	primary-border pl-2"
                    dangerouslySetInnerHTML={{ __html: organizationData?.title || '' }}
                />
                <p
                    className="mt-4 md:text-xs xl:text-sm 2xl:text-base text-gray-900"
                    dangerouslySetInnerHTML={{ __html: organizationData?.text || '' }}
                />
                  <form className="mt-8">
                    <p  className="mt-4 md:text-xs xl:text-sm  2xl:text-base text-gray-900 font-semibold">Vul hieronder je adres in of maak de simulatie zonder je adres</p>
                    <div className=" w-full	lg:flex lg:space-x-4 mt-4">
                      <div className="md:w-12/12 lg:w-8/12  xl:w-8/12 2xl:w-9/12">
                        <AddressBar
                            onCityChange={(value: string, option: { id: string; zip: string })=> {
                              setSelectedCity({
                                name: value.split(',')[0],
                                id: option.id
                              })
                              setSelectedZip(option.zip)
                            }}
                            onStreetChange={(value: string, option: any)=> {
                              setSelectedStreet({
                                name: value,
                                id: option.id
                              })
                            }}
                        />
                        {/*<input type="text" name="streetName" id="adress" className="col-span-6 bg-gray-50 border border-gray-300 text-gray-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5 h-12" placeholder="vb. Herkenrodesingel 2" required />*/}
                        {/*<input type="text" name="zipCode" id="zip" className="col-span-3 bg-gray-50 border border-gray-300 text-gray-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5 h-12" placeholder="3500" required />*/}
                        {/*<input type="text" name="municipality" id="city" className="col-span-3 bg-gray-50 border border-gray-300 text-gray-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5 h-12" placeholder="Hasselt" required />*/}
                      </div>
                      <div className="md:w-12/12 lg:w-4/12 xl:w-4/12 2xl:w-3/12">
                        <button
                            onClick={(e) => fetchMunicipalityData(e)}
                            disabled={isEmpty(selectedCity?.id) || isEmpty(selectedStreet?.id)}
                            className="w-full lg:w-full secondary-text primary-main font-medium rounded-lg text-sm px-5 py-2.5 text-center content-end h-12 self-center cursor-pointer">
                          Check mijn huis
                        </button>
                      </div>
                    </div>
                    <div className = "flex w-full justify-center">
                      <p className = "font-Source font-regular text-red-500 text-p2Alt">{errorMessage}</p>
                    </div>
                  </form>

                  {showAreaMessage && (
                    <div className="flex mt-4">
                      <div className="px-4 py-2" style={{ background: '#ff000024', color: '#ff8282', border: '1px solid #df828229', borderRadius: 5, fontSize: 'small', width: '100%' }}>
                        Locatie is niet beschikbaar en valt buiten het servicegebied.
                      </div>
                    </div>
                  )}

                  <div className="flex mt-4">
                    <button type = "button" className = "flex" onClick={handleSubmit}>
                      <h2 className="primary-text font-bold no-underline hover:underline cursor-pointer">Ga verder zonder adres </h2>
                      <FontAwesomeIcon icon={faCircleRight as IconProp} className="w-5 h-5 ml-1 mt-1 -m primary-text cursor-pointer" />
                    </button>
                  </div>
              </div>
            </div>
          </div>
          <div className="hidden md:block flex-auto md:w-1/4 items-center self-center	justify-items-center ">
            <Lottie animationData={AnimationHouse2} loop={true} autoplay={true} className="max-w-[15rem]" />
            <Lottie animationData={AnimationHouse3} loop={true} autoplay={true} className="max-w-[10rem] float-right 	" />
          </div>
        </div>
      </section>
  );
};

export default SectionForm;
