import React, {FC} from "react";


interface TagStruct {
    active: boolean
    text: string
    onClick: ()=> void
}

export const Tag: FC<TagStruct> = ({
    active=false,
    text='',
    onClick=()=> {}
                    })=> {
    return (
        <div className={`no-lb cursor-pointer ${active ? 'text-white' : ''}
        px-3 py-3 text-xs rounded fit-content relative
        `}
             onClick={onClick}
        >
            <div className = {`w-full h-full absolute top-0 left-0 ${active ? 'primary-main' : 'primary-main opacity-[0.1]'} rounded`} />
            <div className = "relative">
                {text}
            </div>
        </div>
    )
}