export const COLORS = {
    primary: '#298752',
    secondary: '#4F4C4C',
    quote_requested_state: '#70B4ED',
    home_visit_state: '#EDC465',
    offer_accepted_state: '#5EC59D',
    offer_requested_state: '#7fb2e8',
    bill_added_state: '#8e61dd',
    un_assigned_state: '#E45F77',
    quote_received_state: '#366FD5',
    error: '#FB797B',
    success: '#62DC97',
    in_active: '#F6F6F6',
    grey: '#8B8B8B',
    dark: '#1D1D1C',
    clear: '#F9F9F9',
    modal: '#000D06',
    blank: '#f0f5f5',
    light_green: "#4fce86",
}