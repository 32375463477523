import {isEmpty} from "lodash";
import TreeView from "react-accessible-treeview";
import React, {FC, useEffect, useState} from "react";

import {ReactComponent as CheckGreen} from "../../assets/icons/check-circle-green.svg";
import {ReactComponent as CheckRed} from "../../assets/icons/x-circle-error.svg";
import OfferService from "../../api/Offers";
import { FiMinusCircle } from "react-icons/fi";

interface cardProps {
    formStep: any
    servicesList: any
    contractorDetails: any
    selectedContractor?: any
}

const OfferCompareCard: FC<cardProps> = ({
    formStep,
    servicesList,
    contractorDetails,
    selectedContractor,
}) => {
    const [expandedIds, setExpandedIds] = useState<any>([]);
    const [isLoading, setLoading] = useState(false);
    const [selectedIds, setSelectedIds] = useState<any>([]);

    useEffect(() => {
        handleSetExpandedIds();
        fetchContractorDetails();
    }, [formStep]);

    const fetchContractorDetails = async () => {
        const auth = JSON.parse(localStorage.getItem('auth') || '{}');
        try {
            setLoading(true)
            const response = await OfferService.getContractorServiceOffers(selectedContractor || auth.company.id);
            const {data} = response;

            seeIfServiceIsChecked(data);
        } catch (error) {
            setLoading(false)
            console.error(error);
        } finally {
            setLoading(false)
        }
    };

    const handleSetExpandedIds = () => {
        if (!isEmpty(servicesList)) {
            const list: any = [];
            servicesList?.map((s: any) => {
                if (s?.children?.length) {
                    list.push(s?.id);
                }
            });
            setExpandedIds([ ...expandedIds, ...list]);
        }
    };

    const seeIfServiceIsChecked = (contractorDetails: any) => {
        if (!isEmpty(contractorDetails)) {
            const list: any = [];
            servicesList?.map((s: any) => {
                if (contractorDetails?.find((c:any) => c?.serviceId === s?.id)) {
                    list.push(s?.id);
                }
            });
            setSelectedIds(list);
        }
    };

    return (
        <div className="py-4">
            <TreeView
                data={servicesList}
                aria-label="Checkbox tree"
                multiSelect
                propagateSelect
                propagateSelectUpwards
                togglableSelect
                expandedIds={expandedIds}
                selectedIds={selectedIds}
                nodeRenderer={({
                   element,
                   isBranch,
                   getNodeProps,
                   level,
                   isSelected,
                   handleSelect,
               }) => {
                    return (
                        <div
                            {...getNodeProps({ onClick: () => {} })}
                            style={{ marginLeft: 40 * (level - 1) }}
                            className="flex pb-[5px]"
                        >
                            {level >= 2 && (
                                <span className="mr-2">
                                    {selectedIds.includes(servicesList[0].id) ? (
                                        <FiMinusCircle size = "20px" className = "text-[grey]" />
                                    ) : (
                                        <>
                                            {isSelected
                                                ? <CheckGreen height={20} width={20} />
                                                : <CheckRed height={20} width={20} />}
                                        </>
                                    )}
                                </span>
                            )}

                            <div style={isBranch ? { fontWeight: 500, padding: '10px 0', textTransform: 'uppercase' } : {}}>
                                <span>{element.name}</span>
                            </div>
                        </div>
                    );
                }}
            />

        </div>
    )
};

export default OfferCompareCard;
