import React, {FC, useState, useEffect} from "react";
import {GetAPIService, PostAPIService} from "../context/services";
import {AutoCompleteDropDown} from "./autoComplete";


export const AddressBar: FC<{onCityChange?: (cityId: string, options?: any)=> void;
    onStreetChange?: (street: string, options: any)=> void
}> = ({
    onCityChange = ()=> {},
    onStreetChange = ()=> {}
                           })=> {
    const [cityStr, setCityStr] = useState("")
    const [possibleCities, setPossibleCities] = useState([])
    const [streetStr, setStreetStr] = useState<null | string>(null)
    const [possibleStreets, setPossibleStreets] = useState([])
    const [zipCode, setZipCode] = useState("")

    useEffect(()=> {
        if(cityStr.length > 1 ){
            GetAPIService(`geo/cityname/${cityStr}`).then((res: any)=> {
                const citiesData = res?.data?.data

                const cities: any = []
                if (citiesData){
                    citiesData.forEach(
                        (city: { id: string; nl: string; fr: string; zipCodes: { zipCode: string }[] }) => {
                            city.zipCodes.forEach((zipCode) => {
                                cities.push({
                                    id: city.id, value: (city.nl || city.fr) + ", " + zipCode.zipCode, zip: zipCode.zipCode, key: city.id+zipCode.zipCode
                                })
                            })
                        })

                }
                setPossibleCities(cities)
            })
        }
    }, [cityStr])

    // console.log("possible streets s are ", possibleStreets)
    useEffect(()=> {
        if(streetStr === null) return
        if(streetStr.length < 3) {
            setPossibleStreets([])
        } else {
            PostAPIService('/geo/street', {
                'searchQuery': streetStr,
                'zipCode': zipCode
            }).then((res: any)=> {
                const streetsData = res?.data?.data
                if (streetsData)
                    setPossibleStreets(streetsData.map((street: { id: string; nl: string; fr: string; number: string, box: string })=> {return {id: street.id, value: street.box? (street.nl || street.fr) + ' ' + street.number +" / "+ street.box : (street.nl || street.fr) + ' ' + street.number, key: street.id}}))
            })
        }
    }, [streetStr])

    const cityChangeHandler = (city: string, option: { id: string; value: string; zip: string })=> {
        console.log('opton is ', option)
        setZipCode(option.zip)
        onCityChange(option.id, option)
    }

    return (
        <div className="flex md:grid md:grid-cols-12 sm:gap-1 gap-4 h-full">
            <div className="w-full md:w-auto md:col-span-4 h-full flex justify-center items-center">
                <AutoCompleteDropDown
                    classes="text-xs"
                    placeholder="Gemeente, postcode"
                    possibleValues={possibleCities}
                    onChangeHandler={(value: string)=> setCityStr(value)}
                    onSelectHandler={cityChangeHandler}
                />
            </div>
            {/* <div className="text-xs col-span-3 flex justify-center items-center">
                <input className="pl-2 border-gray-300 border-[1px] h-[32px] rounded" style={{width:"100%"}} value={zipCode} disabled={true}/>
            </div> */}
            <div className="w-full md:w-auto md:col-span-8 flex justify-center items-center">
                <AutoCompleteDropDown
                    disabled={zipCode.length === 0}
                    classes="text-xs"
                    placeholder="Straat nr"
                    possibleValues={possibleStreets}
                    onChangeHandler={(value: string)=> setStreetStr(value)}
                    onSelectHandler={onStreetChange}
                />
            </div>
        </div>
    )
}