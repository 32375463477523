import React, {FC, useState} from 'react';
import { Form } from 'antd';
import {validateEmail} from "../../utils/validations";


interface InputStruct {
    name: string,
    label?: string,
    unControlled?: boolean,
    height?: string,
    onChangeHandler: (event: React.ChangeEvent<HTMLElement>)=> void,
    active?: boolean,
    outerError?: string
    icon?: string
    placeholder?: string
    defaultValue?: string
    full?: boolean
    textArea?: boolean
    classes?: string
    labelExtraClasses?: string,
    inputContainerClasses?: string,
    type?: string,
    subtitle?: string
    value?: string,
    readOnly?: boolean
    isRequired?: number
}
export const Input: FC<InputStruct> = ({
    name,
    unControlled=false,
    label,
    full=false,
    onChangeHandler=()=> {},
    active=true,
    height ='',
    outerError=undefined,
    icon=undefined,
    placeholder=undefined,
    defaultValue=undefined,
    textArea=false,
    value='',
    classes="",
    labelExtraClasses="",
    readOnly=false,
    inputContainerClasses="", type='text', subtitle = null,
    isRequired
    })=> {

    return (
        <div className={`${classes} ${icon ? 'w-72' : 'w-1/3'} text-xs ${full ? 'w-full' : null}`}>
            {label? (
                <p className={`font-semibold ${label === 'Email' ? 'text-primary' : 'text-secondary'} text-left mb-1 ${labelExtraClasses ? labelExtraClasses : 'text-xs'}`}>{label} <span className='text-error'>{isRequired===1 ?"*":""}</span></p>
            ): null}
            {subtitle? (
                <p className={`font-normal text-secondary text-left mb-1 ${labelExtraClasses ? labelExtraClasses : 'text-xs'}`} dangerouslySetInnerHTML={{ __html: subtitle }} />
            ): null}
            {textArea ?
                unControlled ?
                        <textarea
                            name={name}
                            disabled={!active}
                            placeholder={placeholder}
                            onChange={onChangeHandler}
                            defaultValue={defaultValue}
                            className={`mt-3 px-1 py-2 w-full focus:outline-none border-2 border-gray-100 ${height}`}
                            readOnly={readOnly}
                        />
                    :
                    <Form.Item name={name}>
                        <textarea
                            name={name}
                            disabled={!active}
                            placeholder={placeholder}
                            onChange={onChangeHandler}
                            defaultValue={defaultValue}
                            value={value}
                            readOnly={readOnly}
                            className={`mt-3 px-1 py-2 w-full focus:outline-none border-2 border-gray-100 ${height}`}
                        />
                    </Form.Item>
                :
                <div className={`${inputContainerClasses} mt-3 relative bg-clear w-full border-2 border-gray-100`}>
                    {
                        unControlled
                            ?
                            <input
                                autoComplete="off"
                                name={name}
                                disabled={!active}
                                className="py-2 px-1 focus:outline-none w-full"
                                type={type}
                                placeholder={placeholder}
                                onChange={onChangeHandler}
                                defaultValue={defaultValue || value}
                                readOnly={readOnly}
                            />
                            :
                            <Form.Item name={name}>
                                <input
                                    autoComplete="off"
                                    name={name}
                                    disabled={!active}
                                    className="py-2 px-1 focus:outline-none w-full"
                                    type={type}
                                    placeholder={placeholder}
                                    onChange={onChangeHandler}
                                    defaultValue={defaultValue}
                                    value={value}
                                    readOnly={readOnly}
                                />
                            </Form.Item>
                    }
                    {icon &&
                        <img
                            className="pointer absolute right-2 top-1 w-4 h-4"
                            src={icon}
                            alt="eye"
                        />
                    }
                </div>
            }
            <p className="text-error text-xs text-start ml-2">
            {outerError ?
                outerError
                :
                ''
            }
            </p>
        </div>
    )
}

export const Email: FC<InputStruct> = ({
    label,
    name,
    onChangeHandler=()=> {},
    active=true,
    full=true,
    outerError=undefined,
    classes="",
    readOnly=false,
    defaultValue='',
    value=''
                      })=> {
    const [errors, setErrors] = useState<Array<string>>([])

    const changeHandler = (event: any)=> {
        // if (!validateEmail(event.target.value))
        //     setErrors(['E-mailformaat ongeldig'])
        // else
        //     setErrors([])
        onChangeHandler(event)
    }

    return (
        <Input
            name={name}
            label={label}
            onChangeHandler={changeHandler}
            active={active}
            full={full}
            classes={classes}
            defaultValue={defaultValue}
            value={value}
            readOnly={readOnly}
            outerError={errors.length ? errors[0] : outerError ? outerError : undefined}
        />
    )
}
