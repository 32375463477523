import { useEffect, useState } from "react";
import axios from "axios";
import { uploadsUrl } from "../../../shared/Table/common";
import SubmitReviewModalProfile from "./submitReviewModalProfile";

// asset imports here
import { FaStar } from "react-icons/fa"
import { GoReply } from "react-icons/go";
import { IoIosArrowDropup } from "react-icons/io";

type ProfileReviewProps = {
    homeownerId: number | string,
}

type SubmittedReviewProps = {
    reviewData: any,
    setEditReview: (value: any) => void,
    triggerRefreshState?: () => void
}

type UnsubmittedReviewProps = {
    reviewData: [],
    selectReview: any,
    setSelectReview: any,
}

type ReplyBoxProps = {
    reply: ReplyType,
    triggerRefreshState?: () => void
}

type ReplyType = {
    comment: string;
    createdAt: string;
    id: string;
    user: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
    };
};



const parseSQLDate = (date: string) => {
    // parse into day month year format
    const parsedDate = new Date(date);
    const day = parsedDate.getDate();
    const month = parsedDate.getMonth();
    const year = parsedDate.getFullYear();
    return `${day} ${getLiteralMonth(month)} ${year}`;
}

const getLiteralMonth = (month: number) => {
    const months = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];
    return months[month];
}

const ReplyBox = ({ reply, triggerRefreshState = () => {} }: ReplyBoxProps) => {
    const [isFieldEditable, setIsFieldEditable] = useState(false);
    const [replyState, setReplyState] = useState(reply.comment);

    const updateReply = () => {
        const auth = JSON.parse(localStorage.getItem("auth") || "{}");
        if(auth.token) {
            if(replyState) {
                axios.put(process.env.REACT_APP_BACKEND_URL + `ratings/reply/${reply.id}`, {
                    comment: replyState
                }, {
                    headers: {
                        Authorization: `Bearer ${auth.token}`
                    }
                }).then((response) => {
                    triggerRefreshState();
                    setIsFieldEditable(false)
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    }

    const cancelUpdate = () => {
        setIsFieldEditable(false);
        setReplyState(reply.comment);
    }

    return(
        <div className = "w-full flex flex-col bg-[#FAFAFA] rounded-[4px] box-border py-[12px] mt-[8px]">
            <div className = "flex w-full justify-between items-center">
                <div className = "flex items-center">
                    <p className = "font-jakarta font-bold text-[16px] text-[#4F4C4C]">{reply?.user?.firstName} {reply?.user?.lastName}</p>
                </div>
                <div className = "flex items-center">
                    <p className = "font-jakarta font-regular text-[12px] text-[#4F4C4C]">{parseSQLDate(reply.createdAt)}</p>
                </div>
            </div>
            <div className = "w-full flex items-center justify-between mt-[16px]">
                {isFieldEditable ? (
                    <>
                        <div className = "h-[44px] w-full relative mr-[16px]">
                            <div className = "w-full h-full absolute top-0 left-0 opacity-[0.1] primary-border border-[1px]" />
                            <input className = "w-full h-full box-border p-[12px] font-sans font-regular text-[14px] text-[#5F5F5F] bg-transparent relative outline-none" placeholder = "Schrijf hier een reactie" value = {replyState} onChange = {(event) => setReplyState(event.target.value)} />
                        </div>
                    </>
                ): (
                    <div className = "flex flex-col">
                        <p className = "font-semibold font-sans text-[14px] text-[#4F4C4C]/[0.22]">ANTWOORD</p>
                        <p className = "font-sans font-regular text-[14px] text-[#4F4C4C] mt-[2px]">{reply?.comment}</p>
                    </div>
                )}
                <div className = "flex">
                    {isFieldEditable ? (
                        <div className = "flex">
                            <button className = "h-[30px] px-[10px] py-[5px] rounded-[5px] bg-[#FB797B] font-sans font-semibold text-[14px] text-white cursor-pointer mr-[16px]" onClick = {cancelUpdate}>Annuleren</button>
                            <button className = "h-[30px] px-[10px] py-[5px] rounded-[5px] primary-main font-sans font-semibold text-[14px] text-white cursor-pointer" onClick = {updateReply}>Bijwerken</button>
                        </div>
                    ) : (
                        <>
                            {/* user cannot reply at the moment so updating functionality is redundant
                            <button type = "button" role = "button" className = "font-sans font-bold text-[14px] underline primary-text cursor-pointer" onClick = {() => setIsFieldEditable(!isFieldEditable)}>bewerk</button> */}
                        </>
                    )}
                    
                </div>
            </div>
        </div>
    )
}

const UnsubmittedReview = ({ reviewData, selectReview, setSelectReview }: UnsubmittedReviewProps) => {

    const onClick = (review: any) => {
        const selectedReview = {
            contractorId: review?.cpOfferRequest?.cpOfferRequestRenovations[0]?.cpOfferRequestRenovationContractors[0]?.contractor?.id,
            offerRequestContractorId: review?.cpOfferRequest?.cpOfferRequestRenovations[0]?.cpOfferRequestRenovationContractors[0]?.id,
            homeownerId: review?.userId,
        }
        setSelectReview(selectedReview);
    }

    return(
        <div className = "w-full flex flex-col bg-[#FAFAFA] rounded-[4px] box-border px-[32px] py-[12px]">
            {reviewData?.map((review: any, index: number) => (
                <div className = "flex w-full justify-between items-center" key = {index}>
                    <div className = "flex items-center">
                        <img className = "h-[30px] w-[30px] object-contain rounded-[15px] overflow-hidden bg-[grey]" src = {uploadsUrl(review?.cpOfferRequest?.cpOfferRequestRenovations[0]?.cpOfferRequestRenovationContractors[0]?.contractor?.logo)} />
                        <p className = "font-sora font-semibold text-[14px] text-black ml-[30px]">{review?.cpOfferRequest?.cpOfferRequestRenovations[0]?.cpOfferRequestRenovationContractors[0]?.contractor?.name}</p>
                        <p className = "font-sora font-semibold text-[14px] text-black ml-[30px]">{review?.cpOfferRequest?.cpOfferRequestRenovations[0]?.cpOfferRequestRenovationContractors[0]?.contractor?.cpContractorUsers[0]?.user?.firstName} {review?.cpOfferRequest?.cpOfferRequestRenovations[0]?.cpOfferRequestRenovationContractors[0]?.contractor?.cpContractorUsers[0]?.user?.lastName}</p>
                        <p className = "font-sora font-semibold text-[14px] text-black ml-[30px]">{review?.cpOfferRequest?.cpOfferRequestRenovations[0]?.cpOfferRequestRenovationContractors[0]?.contractor?.cpContractorUsers[0]?.user?.email}</p>
                        <p className = "font-sora font-semibold text-[14px] text-[#ccc] ml-[30px] capitalize">{review?.cpOfferRequest?.cpOfferRequestRenovations[0]?.renovation?.title}</p>
                    </div>
                    {/* <p className = "font-jakarta font-regular text-[16px] text-[#4F4C4C]"><strong>{contractorData?.contractor?.name}.</strong> uit <strong>Gent</strong></p> */}
                    <div className = "flex items-center">
                        <button className = "h-[30px] px-[10px] py-[5px] rounded-[5px] primary-main font-sans font-semibold text-[14px] text-white cursor-pointer" onClick = {() => onClick(review)}>Start review</button>
                    </div>
                </div>
            ))}
        </div>
    )
}

const SubmittedReview = ({ reviewData, setEditReview = () => {}, triggerRefreshState = () => {} }: SubmittedReviewProps) => {
    const [ expandedState, setExpandedState ] = useState(false);
    const [ replyState, setReplyState ] = useState("");
    const [ errorMessageState, setErrorMessageState ] = useState("");

    const getAvgRating = (review: any) => {
        const { price, communication, planning, recommended, aftercare } = review;
        if(aftercare && aftercare > 0) {
            const total = price + communication + planning + recommended + aftercare;
            // return average up to one decimal point
            return (total / 5).toFixed(1);
        }
        else {
            const total = price + communication + planning + recommended;
            // return average up to one decimal point
            return (total / 4).toFixed(1);
        }
    }

    const submitReply = () => {
        const auth = JSON.parse(localStorage.getItem("auth") || "{}");
        if(auth?.token ) {
            if(replyState) {
                axios.post(process.env.REACT_APP_BACKEND_URL +`ratings/${reviewData?.id}/reply`, {
                    comment: replyState
                }, {
                    headers: {
                        Authorization: `Bearer ${auth.token}`
                    }
                }).then(response => {
                    console.log(response)
                }).catch(error => console.log(error))
            }
            else {
                setErrorMessageState("Antwoord kan niet leeg zijn, vul het antwoordveld in.");
            }
        }
        else {
            setErrorMessageState("Token is niet geldig, log in en probeer het opnieuw.");
        }
    }

    return(
        <div className = "w-full flex flex-col duration-[300ms] overflow-hidden" style = {{maxHeight: expandedState ? "5000px" : "100px"}}>
            <div className = "w-full flex flex-col bg-[#FAFAFA] rounded-[4px] box-border px-[32px] py-[12px]">
                <div className = "flex w-full justify-between items-center">
                    <div className = "flex items-center">
                        <img className = "h-[30px] w-[30px] object-contain rounded-[15px] overflow-hidden bg-[grey]" src = {uploadsUrl(reviewData?.contractor?.logo)} />
                        <p className = "font-sora font-semibold text-[14px] text-black ml-[30px]">{reviewData?.contractor?.name}</p>
                        <p className = "font-sora font-semibold text-[14px] text-black ml-[30px]">{reviewData?.contractor?.cpContractorUsers[0]?.user?.firstName} {reviewData?.contractor?.cpContractorUsers[0]?.user?.lastName}</p>
                        <p className = "font-sora font-semibold text-[14px] text-black ml-[30px]">{reviewData?.contractor?.cpContractorUsers[0]?.user?.email}</p>
                        <p className = "font-sora font-semibold text-[14px] text-[#ccc] ml-[30px] capitalize">{reviewData?.cpOfferRequestRenovationContractor?.offerRequestRenovation?.renovation?.key}</p>
                    </div>
                    <div className = "flex items-center">
                        <p className = "font-jakarta font-regular text-[12px] text-[#4F4C4C]">{parseSQLDate(reviewData.createdAt)}</p>
                        <IoIosArrowDropup size = "24px" className = "ml-[15px] cursor-pointer duration-[300ms] primary-text" onClick = {() => setExpandedState(!expandedState)} style = {{transform: expandedState ? "rotate(0deg)" : "rotate(180deg"}} />
                    </div>
                </div>
                <div className = "flex w-full mt-[16px]">
                    <FaStar size = "20px" className = "primary-text" />
                    <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{getAvgRating(reviewData)}</p>
                </div>
                <div className = "w-full flex mt-[24px]">
                    <div className = "flex flex-col mr-[32px]">
                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">PRIJS</p>
                        <div className = "flex items-center">
                            <FaStar size = "20px" className = "primary-text" />
                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{reviewData?.price}</p>
                        </div>
                    </div>
                    <div className = "flex flex-col mr-[32px]">
                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">COMMUNICATIE</p>
                        <div className = "flex items-center">
                            <FaStar size = "20px" className = "primary-text" />
                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{reviewData.communication}</p>
                        </div>
                    </div>
                    <div className = "flex flex-col mr-[32px]">
                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">PLANNING/TIMING</p>
                        <div className = "flex items-center">
                            <FaStar size = "20px" className = "primary-text" />
                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{reviewData?.planning}</p>
                        </div>
                    </div>
                    <div className = "flex flex-col mr-[32px]">
                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">IK RAAD DEZE AANNEMER AAN</p>
                        <div className = "flex items-center">
                            <FaStar size = "20px" className = "primary-text" />
                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{reviewData?.recommended}</p>
                        </div>
                    </div>
                    <div className = "flex flex-col">
                        <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C]/[0.22] mb-[8px]">NAZORG (OPTIONEEL)</p>
                        <div className = "flex items-center">
                            <FaStar size = "20px" className = "primary-text" />
                            <p className = "font-sans font-semibold text-[16px] text-[#4F4C4C] ml-[8px]">{reviewData?.aftercare}</p>
                        </div>
                    </div>
                </div>
                <div className = "w-full flex justify-between items-center mt-[26px]">
                    <div className = "flex flex-col">
                        <p className = "font-semibold font-sans text-[14px] text-[#4F4C4C]/[0.22]">EXTRA (OPTIONEEL)</p>
                        <p className = "font-sans font-regular text-[14px] text-[#4F4C4C] mt-[8px]">{reviewData?.comment}</p>
                    </div>
                    <button type = "button" role = "button" className = "font-sans font-bold text-[14px] underline primary-text cursor-pointer" onClick = {() => {setEditReview(reviewData)}}>bewerk</button>
                </div>
            </div>
            <div className = "w-full flex">
                {reviewData?.threads && reviewData?.threads.length > 0 && (
                    <>
                    <div className = "h-[64px] w-[64px] flex justify-center items-center">
                    <GoReply size = "32px" className = "rotate-[180deg]" color = "#4F4C4C" />
                </div>
                <div className = "w-full flex flex-col bg-[#FAFAFA] rounded-[4px] box-border px-[32px] py-[12px] mt-[10px]">
                        <>
                            <p className = "font-sans font-semibold text-[14px] primary-text mb-[5px]">ANTWOORDEN</p>
                            {reviewData?.threads.map((thread: any, index: number) => (
                                <>
                                    {thread?.replies && thread?.replies?.length > 0 && thread?.replies.map((reply: any, index: number) => (
                                        <ReplyBox reply = {reply} triggerRefreshState = {triggerRefreshState} />
                                    ))}
                                </>
                            ))}
                            {/* user cannot reply at the moment so functionality is disabled 
                            <p className = "font-sans font-semibold text-[14px] primary-text mb-[5px]">BEANTWOORDEN</p>
                            <div className = "w-full flex flex-col bg-[#FAFAFA] rounded-[4px] box-border py-[12px]">
                                <div className = "h-[44px] w-full relative">
                                    <div className = "w-full h-full absolute top-0 left-0 opacity-[0.1] primary-border border-[1px]" />
                                    <input className = "w-full h-full box-border p-[12px] font-sans font-regular text-[14px] text-[#5F5F5F] bg-transparent relative outline-none" placeholder = "Schrijf hier een reactie" value = {replyState} onChange = {(event) => setReplyState(event.target.value)} />
                                </div>
                                <div className = "w-full flex justify-end">
                                    <button className = "h-[30px] px-[10px] primary-main secondary-text font-sans font-semibold text-[14px] rounded-[5px] cursor-pointer mt-[8px]" onClick = {submitReply}>Sturen</button>
                                </div>
                            </div> */}
                        </>
                </div>
                    </>)}
            </div>
        </div>
    )
}

const ProfileReview = ({ homeownerId }: ProfileReviewProps) => {
    const [ reviews, setReviews ] = useState<any>({});
    const [ selectReview, setSelectReview ] = useState<any>(null);
    const [ editReview, setEditReview ] = useState<any>(null)
    const [ refreshState, setRefreshState ] = useState(false);

    useEffect(() => {
        if(homeownerId) {
            axios.get(process.env.REACT_APP_BACKEND_URL + "ratings/home-owner/" + homeownerId).then(response => {
                console.log(response.data);
                setReviews(response.data);
            }).catch(error => console.log(error))
        }
    }, [homeownerId, refreshState])

    const triggerRefreshState = () => {
        setRefreshState(!refreshState)
    }

    return(
        <div className = "w-full flex flex-col px-16 gap-[10px]">
            {reviews?.submittedReviews?.map((review: any, index: number) => (
                <SubmittedReview reviewData = {review} key = {index} setEditReview = {setEditReview} triggerRefreshState = {triggerRefreshState}  />
            ))}
            {reviews?.reviewsNeedSubmission && reviews?.reviewsNeedSubmission.length > 0 && (
                <UnsubmittedReview reviewData = {reviews?.reviewsNeedSubmission} selectReview = {selectReview} setSelectReview = {setSelectReview} />
            )}
            {(selectReview || editReview) && <SubmitReviewModalProfile closeModal = {() => {setSelectReview(null); setEditReview(null)}} editReview = {editReview} contractorId = {selectReview?.contractorId} homeOwnerId = {selectReview?.homeownerId} offerRequestContractorId = {selectReview?.offerRequestContractorId} />}
        </div>
    )
}

export default ProfileReview;
