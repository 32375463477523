import React, { FC } from 'react';
import {Radio, RadioChangeEvent, Space} from 'antd';


interface RadioButtonStruct {
    onChangeHandler: (event: RadioChangeEvent)=> void,
    options: Array<{value: string|number; label: string|number}>
    defaultValue: string|number|null
    name?: string,
    readonly?: boolean,
    value?: any,
}

export const RadioButton: FC<RadioButtonStruct> = ({
    onChangeHandler=()=> {},
    options,
    defaultValue,
    name,
    readonly=false,
    value=null,
                            }) => {
    return (
        // <Radio.Group onChange={onChangeHandler} defaultValue={defaultValue} {...(readonly?{value: defaultValue}: {})} name={name} className="w-full text-left">
        <Radio.Group onChange={onChangeHandler} defaultValue={defaultValue} {...(value?{value: value}: {})} {...(readonly?{value: defaultValue}: {})} name={name} className="w-full text-left">
            <Space direction="vertical">
                {
                    options.map(option=> (<Radio key={option.value} value={option.value}>{option.label}</Radio>))
                }
            </Space>
        </Radio.Group>
    )
}
