import React, { FC } from "react"
import { ReactComponent as CheckCircleIcon } from "../../assets/icons/check-circle.svg"

interface CheckCircleProps {
    status: Boolean,
    onClick?: React.MouseEventHandler,
    className?: String,
}

const CheckCircle: FC<CheckCircleProps> = ({ status, onClick, className, ...others }) => {
    return (
        status ? (
            <CheckCircleIcon {...others} onClick={onClick} fill='#62dc97' className={'h-5 w-5 ml-auto mr-auto ' + className as string} />
        ) : (
            <CheckCircleIcon {...others} onClick={onClick} fill='#e8e8e8' className={'h-5 w-5 ml-auto mr-auto ' + className as string} />
        )
    )
}

export default CheckCircle
