import HttpService from "../constants/HttpService";

class ContractorService extends HttpService {
    constructor() {
        super('contractor');
    }

    // additional services

    toggleStatus = async (id: String) => {
        const response = await this.client.put(`${this.endPoint}/status/${id}`)
        return response
    }

    delete = async (id: String) => {
        const response = await this.client.delete(`${this.endPoint}/${id}`)
        return response
    }

    // get all contractors-admin
    getAllAdmin = async (payload: any, others: any) => {
        const response = await this.client.post(`${this.endPoint}/admin/`, payload, {...others})
        return response
    }

    // get all contractors-admin
    listContractors = async () => {
        const response = await this.client.get(`${this.endPoint}/list-contractors/`)
        return response
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ContractorService()
