import React, { FC } from "react"

interface CardProps {
    title: String,
    subTitle: String,
    subSubTitle?: String,
    onClick: React.MouseEventHandler,
    btnText: String,
    description?: String,
}

export const HouseOwnerCard: FC<CardProps> = ({ title, subTitle, description, onClick, btnText, subSubTitle }) => {

    return (
        <div className='bg-white shadow-sm hover:shadow-lg cursor-default p-4 flex flex-col gap-2 mt-[15px] md:mt-[0px]'>
            <h6 className='primary-text font-bold text-lg mb-2 flex-1'>{title}</h6>
            {subTitle && <p className='text-secondary font-semibold text-sm'>{subTitle}</p>}
            {subSubTitle && <p className='text-secondary font-semibold text-sm'>{subSubTitle}</p>}
            {description && <p className='text-grey text-sm'>{description}</p>}
            <button className='primary-main py-3 text-white text-sm hover:bg-opacity-80' onClick={onClick}>{btnText}</button>
        </div>
    )
}
