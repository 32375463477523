import { useEffect, useState, useContext } from 'react'
import Layout from '../../components/layout'
import { HouseOwnerCard } from '../../shared/cards/houseOwnerCard'
import OfferRequestService from '../../api/OfferRequest'
import { formatAddress, formatDate } from '../../utils/helpers'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '../../context/globalContext/globalContext'

const OfferRequests = () => {
    const [offerRequestsList, setOfferRequestsList] = useState<[] | null>(null)
    const [loading, setLoading] = useState<null | boolean>(null)
    const [isError, setIsError] = useState<boolean>(false)

    const navigate = useNavigate()
    const { state, dispatch } = useContext(GlobalContext)

    const auth = JSON.parse(localStorage.getItem("auth") || "{}");

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                setIsError(false)
                const userId = auth.uid
                const response = await OfferRequestService.ladOfferRequest(userId)
                const { data } = response
                setOfferRequestsList(data)
                setLoading(false)
            } catch (error) {
                console.error(error)
                setIsError(true)
            } finally {
                setLoading(false)
            }
        })();

        // load offer all status
        (async () => {
            if (!state?.offerAllStatus) {
                const data = await OfferRequestService.loadOfferRequestsAllStatus()
                dispatch({ type: "SET_OFFER_REQUEST_ALL_STATUS", payload: data })
            }
        })()
    }, [])

    const onClick = (simulation: any) => {
        const id = simulation.id
        navigate(`${id}/details`)
    }

    return (
        <Layout>
            <div className='px-4 py-4 md:px-8 lg:px-16 xl:px-32 md:py-8'>
                <h2 className='text-xl font-bold'>Offerte Aanvragen</h2>
                {loading && (<div className="mt-4">Laden..</div>)}
                { isError && <p className="text-error mt-4">Kan aanbiedingsverzoeken niet laden. Probeer het opnieuw.</p> }
                <div className='flex flex-col md:grid md:grid-cols-3 md:gap-3 lg:grid-cols-4 lg:gap-4 mt-10 w-full'>
                    {offerRequestsList?.map((item, idx) => {
                        const { simulation, sendAt, createdAt }: { simulation: any, createdAt: any, sendAt: any } = item
                        const sentAtDate = formatDate(sendAt)
                        const createdAtDate = formatDate(createdAt)
                        const address = formatAddress(simulation?.city, simulation?.street)
                        return (
                            <HouseOwnerCard
                                key={idx}
                                title={address}
                                subTitle={`Simulatie op: ${createdAtDate ? createdAtDate : ''}`}
                                subSubTitle={`Aanvraag op: ${sendAt ? sentAtDate : ''}`}
                                onClick={() => onClick(simulation)}
                                btnText="Bekijk aanvraag status"
                            />
                        )
                    })}
                </div>
            </div>
        </Layout>
    )
}

export default OfferRequests