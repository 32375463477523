import React, { useState, useEffect }  from "react";

// image imports
import LogoPlaceholder from "../assets/contractor/contractor_logo.jpg"
import LabelPlaceholder from "../assets/contractor/contractor_label.jpeg"
import {  uploadsUrl } from './Table/common'
import axios from "axios";
import { FaStar } from "react-icons/fa";
// define props

type UserList = [
    {
        firstName: string,
        lastName: string
    }
]

type contractorCertificateType = {
    certificate: {
        certificat: string,
        id: string,
        logo: string | null,
    },
    contractorId: string,
    certificatId: string,
    id: string
}

type contractorLabelsType = {
    id: string,
    labelId: string,
    contractorId: string,
    label: {
        id: string,
        label: string,
        logo: string | null
    }
}

type contractorRenovationType = {
    id: string,
    renovationId: string,
    contractorId: string
}

type contractorListType = {
    archiveContractor: number,
    archiveRequestor: number,
    contractor: {
        address1: string | null,
        address2: string | null,
        cityId: string,
        contract: string | null,
        createdAt: string | null,
        deletedAt: string | null,
        description: string | null,
        email: string,
        id: string,
        logo: string,
        name: string,
        phone: string,
        status: string | null,
        updatedAt: string | null,
        streetId: string | null,
        users: UserList,
        cpContractorCertificats?: contractorCertificateType[],
        cpContractorLabels?: contractorLabelsType[],
        cpContractorRenovations?: contractorRenovationType[],
    },
    contractorId: string,
    createdAt: string,
    deletedAt: string | null,
    groupPurchase: number,
    id: string,
    mentorship: number,
    offerRequestRenovationId: string,
    selected: null,
    statusId: string,
    updatedAt: string | null
}

type ListCardExpandedProps = {
    isOpen?: boolean,
    rowClickHandler?: (rowId: number|string)=> void,
    contractorData?: [contractorListType],
    handleContractorAdd?: (renovationId: number, contractorId: number) => void,
    renovationId: string,
    isClickable?: boolean,
    renovationIds?:string[],
    contractorsData?:any[],
    reviewModalState?: any,
    setReviewModalState?: (data: any) => void;
}

type CardExpandedProps = {
    contractorData?: contractorListType,
    onClickHandler?: (rowId: number|string)=> void,
    key?: number,
    handleContractorAdd?: (renovationId: number, contractorId: number) => void,
    renovationId?: string,
    isClickable?: boolean,
    isDotsClickable?: boolean,
    hideDots?: boolean,
    renovationIds?:string[],
    contractorsData?:any,
    setReviewModalState?: (data: any) => void;
}

export const CardExpanded = ({ contractorData, onClickHandler = () => {}, key, handleContractorAdd = () => {}, renovationId, isClickable = true, isDotsClickable = true, hideDots = false,renovationIds, contractorsData, setReviewModalState = () => {} }: CardExpandedProps) => {
    //@ts-ignore
    const { id, name, description, email, phone = "", logo, avgRating } = contractorData?.contractor || {};
    const [render,setRender] = useState(false)
    const [users,setUsers] = useState<any[]>([])



 useEffect(()=>{
    // debugger
    axios.get(process.env.REACT_APP_BACKEND_URL + "contractor/"+ contractorData?.contractor?.id).then((res)=>{
        let data = res.data;
        console.log(data,'data')
        setUsers (data.cpContractorUsers)
        setRender(true)
    }).catch(error => console.log(error)
    )
 },[])

    const getFirstUserFromUserList = () => {
        if(users && users.length > 0) {
            const { firstName, lastName } = users[0]?.user;
            return (
                <div className = "flex flex-[1.5] shrink-0 flex-wrap">
                    <p className = "font-Source font-regular text-p2Alt text-black">{firstName} {lastName}</p>
                    {/*<p className = "font-Source font-regular text-p2Alt text-black"> {lastName}</p>*/}
                </div>
            );
        }
        return "";
    }

    const onLogoClick = () => {
        if (!contractorData?.selected) {
            const { contractorId } = contractorData || {};
            const { cpContractorRenovations } = contractorData?.contractor || {};
            // const conractorRenovationIds = cpContractorRenovations?.map(renoavation => renoavation.renovationId);

            if (renovationId && contractorId) {
                handleContractorAdd(parseInt(renovationId || "0"), parseInt(contractorId || "0"));
            }
        }
    }

    return(
        <div className = "cursor-pointer w-full h-auto py-[10px] flex items-center flex box-border mt-[5px] shrink-0 shadow-[0px_0px_3px_#0000001A] min-w-[600px] md:min-w-auto" key = {key} style = {{pointerEvents: isClickable ? "all" : "none"}} onClick = {onLogoClick}>
            <div className = "flex flex-1 justify-center shrink-0">
                <div className = "h-[37px] w-[37px] bg-white rounded-[10px] overflow-hidden">
                    <img className = "h-full w-full object-contain scale-[1.5]" alt = "icon" src = {uploadsUrl(logo)} />
                </div>
            </div>
            <div className = "flex flex-[1.5] shrink-0">
                <p className = "font-Source font-regular text-p2Alt text-black">{name}</p>
            </div>
            {getFirstUserFromUserList()}
            <div className = "flex flex-[2] shrink-0">
                <p className = "font-Source font-regular text-p2Alt text-[#8B8B8B]">{email}</p>
            </div>
            <div className = "flex flex-[1.5] shrink-0">
                <p className = "font-Source font-regular text-p2Alt text-[#5F5F5F]">{phone}</p>
            </div>
            <div className = "flex flex-[1] shrink-0">
                {parseInt(avgRating) > 0 && (
                    <div className = "flex items-center" onClick = {() => setReviewModalState(contractorData)}>
                        <FaStar size = "16px" className = "mr-[8px] primary-text" />
                        <p className='text-[#969696] font-sans font-semibold text-[16px] underline'>{avgRating || 0}</p>
                    </div>
                )}
            </div>
            <div className = "flex flex-[1.35] flex-wrap shrink-0 gap-y-[10px]">
                {contractorData?.contractor?.cpContractorCertificats?.map((certificate, index) => (
                    //@ts-ignore
                    <img className = "h-[29px] w-[29px] rounded-[15px] overflow-hidden mr-[5px]" src = {uploadsUrl(certificate.certificat.logo)} alt = "cert" />
                ))}
            </div>
            <div className = "flex flex-[1.35] justify-between items-center shrink-0">
                <div className = "flex flex-1 items-center flex-wrap gap-y-[10px]">
                    {contractorData?.contractor?.cpContractorLabels?.map((label, index) => (
                        <div className = "h-[37px] w-[37px] rounded-[10px] overflow-hidden mr-[5px]" key = {index}>
                            <img className = "h-full w-full object-contain scale-[1]" alt = "icon" src = {uploadsUrl(label.label?.logo)} />
                        </div>
                    ))}
                </div>
                <div className = "flex flex-col justify-center cursor-pointer mr-[10px] shrink-0" style = {{pointerEvents: isDotsClickable ? "all" : "none", display: hideDots ? "none" : "flex"}} onClick={()=> onClickHandler(id || -1)}>
                    <div className = "h-[5px] w-[5px] rounded-[5px] bg-black" />
                    <div className = "h-[5px] w-[5px] rounded-[5px] bg-black mt-[3px]" />
                    <div className = "h-[5px] w-[5px] rounded-[5px] bg-black mt-[3px]" />
                </div>
            </div>
        </div>
    )
}


const ListCardExpanded = ({ isOpen = true, rowClickHandler, contractorData, handleContractorAdd = () => {}, renovationId, isClickable = true,renovationIds,contractorsData, reviewModalState, setReviewModalState = () => {} }: ListCardExpandedProps) => {

    return(
        <div className = "w-full h-[400px] bg-white box-border px-[15px] flex flex-col items-center overflow-hidden duration-300 ease-in-out" style = {{maxHeight: isOpen ? "400px" : "0px", opacity: isOpen ? 1 : 0, pointerEvents: isClickable ? "all" : "none"}}>
            <p className = "font-Source font-semibold text-2xl text-black mt-[20px]">Lijst van aannemers</p>
            <p className = "font-Source font-regular text-p2 text-black mt-[10px] text-center">Selecteer uit onderstaande lijst een aannemer waar je voor deze dienst een offerte van wenst te ontvangen. (Er kunnen max. 3 aannemers geselecteerd worden.)</p>
            <div className = "flex flex-col w-full overflow-x-auto">
                <div className = "w-full box-border px-[15px] mt-[25px] min-w-[900px]">
                    <div className = "flex w-full items-center">
                        <div className = "flex flex-1 justify-center shrink-0">
                            <p className = "font-Source font-semibold text-p2Alt text-[#4F4C4C]/[0.22]">LOGO</p>
                        </div>
                        <div className = "flex flex-[1.5] shrink-0">
                            <p className = "font-Source font-semibold text-p2Alt text-[#4F4C4C]/[0.22]">FIRMA</p>
                        </div>
                        <div className = "flex flex-[1.5] shrink-0">
                            <p className = "font-Source font-semibold text-p2Alt text-[#4F4C4C]/[0.22]">CONTACTPERSOON</p>
                        </div>
                        <div className = "flex flex-[2] shrink-0">
                            <p className = "font-Source font-semibold text-p2Alt text-[#4F4C4C]/[0.22]">EMAIL</p>
                        </div>
                        <div className = "flex flex-[1.5] shrink-0">
                            <p className = "font-Source font-semibold text-p2Alt text-[#4F4C4C]/[0.22]">TELEFOON</p>
                        </div>
                        <div className = "flex flex-[1] shrink-0">
                            <p className = "font-Source font-semibold text-p2Alt text-[#4F4C4C]/[0.22]">BEOORDELING</p>
                        </div>
                        <div className = "flex flex-[1.35] shrink-0">
                            <p className = "font-Source font-semibold text-p2Alt text-[#4F4C4C]/[0.22]">CERIFICATEN</p>
                        </div>
                        <div className = "flex flex-[1.35] shrink-0">
                            <p className = "font-Source font-semibold text-p2Alt text-[#4F4C4C]/[0.22]">LABEL</p>
                        </div>
                    </div>
                </div>
                <div className = "h-[235px] overflow-y-auto flex flex-col w-full items-center mt-[10px] border-[1px] border-[#F6F6F6] box-border px-[15px] py-[10px] min-w-[900px]">
                    {contractorData && contractorData.length > 0 && contractorData.map((contractor) => (
                        <CardExpanded contractorData = {contractor} onClickHandler={rowClickHandler} handleContractorAdd = {handleContractorAdd} renovationId = {renovationId} renovationIds={renovationIds} contractorsData={contractorsData} setReviewModalState = {setReviewModalState} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ListCardExpanded;
