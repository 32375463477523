import axios, { AxiosInstance } from "axios";

const IS_DEBUG = false

export default class HttpService {
    public client: AxiosInstance;
    
    constructor(public endPoint: string) {
        const BASE_URL_PRODUCTION = process.env.REACT_APP_BACKEND_URL
        const BASE_URL_LOCAL = IS_DEBUG? process.env.REACT_APP_BACKEND_URL_LOCAL : process.env.REACT_APP_BACKEND_URL
        // const auth = JSON.parse(localStorage.getItem("auth")!)
        const authOrigin = localStorage.getItem("auth");
const auth = authOrigin && authOrigin?.length > 0? JSON.parse(localStorage.getItem("auth")!) : ""
        // const headers = {
        //   'Content-Type': 'application/json',
        //   Authorization: `Bearer ${auth?.token}`,
        // };
        // path from base URL to REST end point
        this.endPoint = endPoint

        const baseURL = process.env.NODE_ENV === "production" ? BASE_URL_PRODUCTION : BASE_URL_LOCAL

        this.client = axios.create({
            baseURL: baseURL,
            responseType: 'json' as const,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth?.token}`,
            },
        });

        this.client.interceptors.request.use((config: any) => {
            // TODO: enable while setting authentication by auth-dev
            
            // const token = localStorage.getItem("token");
            // if (token) {
            //     config.headers["Authorization"] = `Bearer ${token}`;
            // }
            return config;
        });

    }

    getAll = async () => {
        const response = await this.client.get(this.endPoint)
        return response
    }
    getOneById = async (id: String) => {
        const response = await this.client.get(`${this.endPoint}/${id}`)
        return response
    }
    create = async (payload: any) => {
        const response = await this.client.post(this.endPoint, payload)
        return response
    }
    update = async (payload: any) => {
        const response = this.client.put(this.endPoint, payload)
        return response
    }
    delete = async (id: String) => {
        const response = this.client.delete(`${this.endPoint}/${id}`)
        return response
    }
}
