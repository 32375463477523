import { AutoComplete } from 'antd';
import React, { useState } from 'react';


export const AutoCompleteDropDown: React.FC<{
    disabled?: boolean;
    placeholder: string;
    selectedValue?: string;
    possibleValues: Array<{ id: string; value: string }>;
    onChangeHandler: (text: string) => void;
    onSelectHandler: (text: string, options: any) => void;
    classes?: string;
}> = ({
    disabled,
                                             placeholder,
                                             possibleValues,
                                             onChangeHandler,
                                             onSelectHandler,
    classes=""
                 }) => {

  return (
    <>
      <AutoComplete
          disabled={disabled}
          className={classes}
        options={possibleValues}
        style={{ width: "100%" }}
        onSearch={(text: string)=> onChangeHandler(text)}
        onSelect={onSelectHandler}
        placeholder={placeholder}
      />
      <br />
      <br />
    </>
  );
};
