import React, {useEffect, useState} from "react";
import {isEmpty} from "lodash";

import Layout from "../layout";
import Search from "../../shared/Search";
import OrganizationCard from "./OrganizationCard";
import OrganisationForm from "./OrganisationForm";
import OrganizationService from "../../api/Organizations";
import {SecondaryButtonSmall, PrimaryButtonSmall} from "../../shared/buttons/igemo_button";
import {message} from "antd";


interface orgCard {
    adminEmail: string;
    domain: string;
    id: string;
    name: string;
    status: boolean;
    subdomain: string;
}

const OrganizationPage = () => {
    const [isLoading, setLoading] = useState(false);
    const [isDownloadAll, setDownloadAll] = useState(false);
    const [isDownloadSingle, setDownloadSingle] = useState(false);
    const [search, setSearch] = useState('');
    const [organisationForm, toggleOrganisationForm] = useState(false);
    const [editOrganization, toggleEditOrganization] = useState('');
    const [organizationsList, setOrganizationsList] = useState([]);
    const [debouncedTerm, setDebouncedTerm] = useState(search);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        const timer = setTimeout(() => setSearch(debouncedTerm), 500);
        return () => clearTimeout(timer);
    }, [debouncedTerm]);

    useEffect(() => {
        if (search === '') {
            fetchOrganizations();
        } else {
            fetchOrganizations(search);
        }
    }, [search]);

    const fetchOrganizations = async (search?: string) => {
        try {
            setLoading(true)
            const response = await OrganizationService.getAllOrganizations(search);
            const {data} = response;
            setOrganizationsList(data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    function download(data: any) {
        const url = URL.createObjectURL(data);
        const a = document.createElement('a');
        a.download = 'CJO statistieken.xlsx';
        a.href = url;
        a.target = '_self';

        a.click();

        setTimeout(function () {
            a.remove();
            URL.revokeObjectURL(url);
        }, 100);
    }

    const downloadExcelFiles = async () => {
        try {
            setDownloadAll(true);
            const response = await OrganizationService.getOrganizationAllExcelFile();
            download(response);
        } catch (error) {
            setDownloadAll(false);
            console.error(error);
        } finally {
            setDownloadAll(false);
        }
    };

    const downloadExcelFileById = async (id: string) => {
        try {
            setDownloadSingle(true);
            const response = await OrganizationService.getOrganizationExcelFileById(id);
            download(response);
        } catch (error) {
            setDownloadSingle(false);
            console.error(error);
        } finally {
            setDownloadSingle(false);
        }
    };

    const handleOrgEdit = (orgId: string) => {
      toggleEditOrganization(orgId);
      toggleOrganisationForm(true);
    };

    return(
        <Layout backgroundColor="#f9f9f9">
            {contextHolder}
            <div className="px-16 py-4">
                <div className="flex">
                    <h1 className="text-[24px] font-semibold mr-5">
                        Organisaties
                    </h1>

                    <div className="mt-1">
                        <PrimaryButtonSmall
                            classes="ml-6"
                            disabled={isLoading}
                            content="+ Organisatie toevoegen"
                            onClick={() => toggleOrganisationForm(true)}
                        />
                    </div>

                    <div className="flex" style={{ marginLeft: 'auto' }}>
                        <div>
                            <SecondaryButtonSmall
                                classes="ml-20"
                                disabled={isDownloadAll}
                                onClick={downloadExcelFiles}
                                content={isDownloadAll ? 'Downloading...': 'Download'}
                            />
                        </div>

                        <div className="ml-6">
                            <Search border="border border-[#e1e1e1]" setQuery={value =>  setDebouncedTerm(value)} timer={1} />
                        </div>
                    </div>
                </div>

                {organisationForm && (
                    <OrganisationForm
                        search={search}
                        open={organisationForm}
                        messageApi={messageApi}
                        setOpen={toggleOrganisationForm}
                        editOrganization={editOrganization}
                        fetchOrganizations={fetchOrganizations}
                        toggleEditOrganization={toggleEditOrganization}
                    />
                )}

                {isLoading ? (
                    <div className="px-16 py-16" style={{ textAlign: 'center' }}>Laden...</div>
                ) : (
                    organizationsList?.length ? (
                        <div className="mt-12 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                            {organizationsList?.map((card: orgCard) => {
                                return (
                                    <OrganizationCard
                                        downloadExcelFileById={downloadExcelFileById}
                                        handleOrgEdit={handleOrgEdit}
                                        cardData={card}
                                    />
                                )
                            })}
                        </div>
                    ) : (
                        <div className="px-16 py-16" style={{ textAlign: 'center' }}>Geen data.</div>
                    )
                )}

            </div>
        </Layout>

    )
};

OrganizationPage.styles = {
    container: {
        marginTop: '12%',
    },
};

export default OrganizationPage;
