import { isEmpty, uniqBy } from "lodash";
import React, {FC, useContext} from "react";
import {Col, Row} from "antd";
import moment from "moment";

import {ReactComponent as PdfIcon} from "../../../assets/icons/pdf-color.svg";
import {uploadsUrl} from "../../../shared/Table/common";
import {renderFormTabsPDF} from "../../servicesList/renderForm";
import {ContractorProp, HouseOwnerListingStatusColors} from "../../../models/applicationState";
import {formatAddress, formatDate} from "../../../utils/helpers";
import {GlobalContext} from "../../../context/globalContext/globalContext";

interface documentStruct {
    docData?: any,
    renovationId?: any,
}

const File1: FC<documentStruct> = ({
    docData,
    renovationId,
}) => {
    const { state } = useContext(GlobalContext);

    const getFilteredQuestionsByType = (typeId: string) => {
        let questions = [];
        const questionsList = docData?.questionsList;

        if (questionsList?.length) {
            questions = questionsList.filter((q: any) => q.question.questionKind.id === typeId && q.question.renovationId === renovationId);
        }

        return uniqBy(questions, 'question.question');
    };

    const contractorsList = docData?.selectedItem;
    const documentsList = docData?.newDocs;
    const releventAnswer = docData?.stateData?.filter((answer: any) => answer?.key === docData?.renovationKey)

    const getAddress = () => {
        let address = '';

        if (!isEmpty(docData?.simulation)) {
            address = formatAddress(docData?.simulation?.city, docData?.simulation?.street);
        }

        return address;
    }

    return (
        <div className="mt-4 text-left" style={{ display: 'none' }} id="tabs-document">
            <Row>
                <Col md={24}>
                    <h1 className="text-xl sm:text-xl md:text-2xl xl:text-2xl  2xl:text-3xl font-semibold border-hasselt">
                        Project Informatie
                    </h1>

                </Col>
            </Row>

            <div className="py-8">
                <div className="flex items-center">
                    <div className="h-24" >
                        <img src={docData?.logo} alt="House's logo" className = "h-full object-contain -ml-2"/>
                    </div>
                    <div className="text-left">
                        <div className="flex flex-col gap-0">
                            <h1 className="text-lg sm:text-lg md:text-xl xl:text-xl  2xl:text-2xl font-semibold border-igemo">
                                {docData?.title}
                            </h1>
                            <div className = "flex flex-col mt-1">
                                {releventAnswer?.length ? (
                                    <>
                                        <h1 className="text-md border-igemo">
                                            <span className="font-normal">{releventAnswer?.length && releventAnswer[0]?.originalAnswerText}</span>
                                        </h1>
                                        <h1 className="text-md border-igemo">
                                            <span className="font-normal">{releventAnswer[0]?.newAnswerText}</span>
                                        </h1>
                                    </>): null}
                                <h1 className="text-md border-igemo">
                                    <span className="font-semibold">Voor:</span> {getAddress()}
                                </h1>
                                <h1 className="text-md border-igemo">
                                    <span className="font-semibold">Offerte aangevraagd op:</span> {formatDate(docData?.offerRequestSendAt)}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Row>
                <Col md={24}>
                    <h1 className="text-lg text-[#298752] font-semibold">
                        Algemene informatie
                    </h1>
                </Col>
            </Row>

            {renderFormTabsPDF(getFilteredQuestionsByType('1'),true, '', {})}

            {/*<Row gutter={6}>*/}
            {/*    <Col md={24}>*/}
            {/*        <p className="font-semibold text-lg mt-8">Foto's</p>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*{docData?.fotoDocuments?.length ? (*/}
            {/*    <Row gutter={6}>*/}
            {/*        {docData?.fotoDocuments?.map((doc: any) => {*/}
            {/*            let docType = doc.file.split('.');*/}
            {/*            docType = docType[docType.length - 1];*/}
            {/*            const iconType = docType === 'pdf'*/}
            {/*                ?  <PdfIcon className='h-24 w-24' title={doc.title} />*/}
            {/*                :  <ImageIcon className='h-24 w-24' title={doc.title} />*/}

            {/*            return <>*/}
            {/*                <Col md={4} className="py-4 text-center cursor-pointer">*/}
            {/*                    <PdfIcon className='h-24 w-24' title={doc.title} />*/}
            {/*                    <p className="pt-1">{`${doc?.title}`}</p>*/}
            {/*                </Col>*/}
            {/*            </>;*/}
            {/*        })}*/}
            {/*    </Row>*/}
            {/*) : (*/}
            {/*    <div className="p-4">Geen documenten gevonden.</div>*/}
            {/*)}*/}

            {/*<Row gutter={6}>*/}
            {/*    <Col md={24}>*/}
            {/*        <p className="font-semibold text-lg mt-8">Plannen</p>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*{docData?.pdfDocuments?.length ? (*/}
            {/*    <Row gutter={6}>*/}
            {/*        {docData?.pdfDocuments?.map((doc: any) => {*/}
            {/*            let docType = doc.file.split('.');*/}
            {/*            docType = docType[docType.length - 1];*/}
            {/*            const iconType = docType === 'pdf'*/}
            {/*                ?  <PdfIcon className='h-24 w-24' title={doc.title} />*/}
            {/*                :  <ImageIcon className='h-24 w-24' title={doc.title} />*/}

            {/*            return <>*/}
            {/*                <Col md={4} className="py-4 text-center cursor-pointer">*/}
            {/*                    <PdfIcon className='h-24 w-24' title={doc.title} />*/}
            {/*                    <p className="pt-1">{`${doc?.title}`}</p>*/}
            {/*                </Col>*/}
            {/*            </>;*/}
            {/*        })}*/}
            {/*    </Row>*/}
            {/*) : (*/}
            {/*    <div className="p-4">Geen documenten gevonden.</div>*/}
            {/*)}*/}

            <Row className="pt-8">
                <Col md={24}>
                    <h1 className="text-lg text-[#298752] font-semibold">
                        Specifieke informatie
                    </h1>
                </Col>
            </Row>

            {renderFormTabsPDF(getFilteredQuestionsByType('2'),true, '', {})}


            <Row className="pt-8">
                <Col md={24}>
                    <h1 className="text-lg text-[#298752] font-semibold">
                        Aanemers
                    </h1>
                </Col>
            </Row>

            {contractorsList
                ? contractorsList?.map((con:ContractorProp, idx: number) => {
                const offerAllStatus = state?.offerAllStatus?.filter((status: any) => status?.id === con.statusId)[0];
                // @ts-ignore
                const bgColor = HouseOwnerListingStatusColors[offerAllStatus?.status];
                const conData = docData?.contList?.find((f: { id: string; }) => f.id === con.contractor.id);

                return (
                    <>
                        <Row style={{ position: 'relative' }} className="pt-[40px]">
                                <Col md={24}>
                                    <div style={{ position: 'absolute', top: 33, right: 20, backgroundColor: bgColor}} className={`px-3 py-1 items-center flex flex-row gap-2 rounded-tl-md rounded-tr-md w-min justify-center align-middle`}>
                                        <p className='text-white font-semibold whitespace-nowrap text-sm'>{offerAllStatus?.status}</p>
                                    </div>
                                </Col>
                        </Row>
                        <Row className="w-full mt-5 px-8 py-4 border" style={{ borderRadius: 10 }}>
                            <Col md={4}>
                                <img src={uploadsUrl(con?.contractor?.logo)} alt="House's logo" className = "h-[160px] object-fit" />
                            </Col>
                            <Col md={20}>
                                <Row>
                                    <Col md={8}>
                                        <p className="font-semibold text-md pb-2 text-[#4F4C4C]" style={{ opacity: 0.2 }}>FIRMA</p>
                                        <p className="text-md pb-2">
                                            {con?.contractor?.name}
                                        </p>

                                        <p className="font-semibold text-md pb-2 mt-8 text-[#4F4C4C]" style={{ opacity: 0.2 }}>CONTACTPERSOON</p>
                                        <p className="text-md pb-2">
                                            {`${con?.contractor?.cpContractorUsers && con?.contractor?.cpContractorUsers[0] ? con?.contractor?.cpContractorUsers[0]?.user?.firstName : ''} ${con?.contractor?.cpContractorUsers[0] ? con?.contractor?.cpContractorUsers[0]?.user?.lastName : ''} `}
                                        </p>
                                    </Col>

                                    <Col md={8}>
                                        <p className="font-semibold text-md pb-2 text-[#4F4C4C]" style={{ opacity: 0.2 }}>EMAIL</p>
                                        <p className="text-md pb-2">
                                            {`${con?.contractor?.cpContractorUsers && con?.contractor?.cpContractorUsers[0] ? con?.contractor?.cpContractorUsers[0]?.user?.email : ''}`}
                                        </p>

                                        <p className="font-semibold text-md pb-2 mt-8 text-[#4F4C4C]" style={{ opacity: 0.2 }}>TELEFOON</p>
                                        <p className="text-md pb-2">
                                            {`${con?.contractor?.cpContractorUsers && con?.contractor?.cpContractorUsers[0] ? con?.contractor?.cpContractorUsers[0]?.user?.phone : ''}`}
                                        </p>
                                    </Col>

                                    <Col md={8}>
                                        <p className="font-semibold text-md pb-2 text-[#4F4C4C]" style={{ opacity: 0.2 }}>BTW NUMMER</p>
                                        <p className="text-md pb-2"> {con?.contractor?.vat}</p>

                                        <Row>
                                            <Col md={6}>
                                                <p className="font-semibold text-md pb-2 mt-8 text-[#4F4C4C]" style={{ opacity: 0.2 }}>LABEL</p>
                                                <p className="text-md pb-2">
                                                    <div className = "flex flex-[1.35] shrink-0">
                                                        {                            // @ts-ignore
                                                            conData?.newItem?.label?.map((label: string)=> {
                                                                return (
                                                                    <img
                                                                        width={30}
                                                                        height={30}
                                                                        style={{ minWidth: 30 }}
                                                                        src={label}
                                                                        alt="label"
                                                                        key={label}
                                                                    />
                                                                )
                                                            })}
                                                    </div>
                                                </p>
                                            </Col>
                                            <Col md={12}>
                                                <p className="font-semibold text-md pb-2 mt-8 text-[#4F4C4C]" style={{ opacity: 0.2 }}>CERTIFICATEN</p>
                                                <div className = "flex flex-[1.35] shrink-0">
                                                    {                            // @ts-ignore
                                                        conData?.newItem?.certificates?.map((certificate: string)=> {
                                                            return (
                                                                <img
                                                                    width={30}
                                                                    height={30}
                                                                    style={{ minWidth: 30 }}
                                                                    src={certificate}
                                                                    alt="label"
                                                                    key={certificate}
                                                                />
                                                            )
                                                        })}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </>
                )
            }): (
                <div className="p-4">Geen documenten gevonden.</div>
            )}

            <Row className="pt-8">
                <Col md={24}>
                    <h1 className="text-lg text-[#298752] font-semibold">
                        Documenten
                    </h1>
                </Col>
            </Row>

            {documentsList?.length
                ? documentsList?.map((doc: any) => {
                let docType = doc.file.split('.');
                docType = docType[docType.length - 1];
                    const iconType = docType === 'pdf'
                        ?  <PdfIcon className='h-24 w-24' title={doc.title} />
                        :  <><img src={uploadsUrl(doc.file)} alt={doc.title} className = "h-[100px] object-fit pr-4" /></>

                return (
                    <Row className="w-full mt-5 px-8 py-4" style={{ borderRadius: 10, background: '#2987520D 0% 0% no-repeat padding-box' }}>
                        <Col md={4}>
                            <p>{`${doc?.title}.${doc?.documentType?.type}`}</p>
                            {iconType}
                        </Col>
                        <Col md={20} className="pt-8">
                            <p className="mb-2 underline">Beschrijving :</p>
                            <p style={{ fontWeight: 300 }}>
                                {doc?.description ? doc?.description : doc?.desc}
                            </p>
                        </Col>
                    </Row>
                );
            }) : (
                <div className="p-4">Geen documenten gevonden.</div>
            )}
        </div>
    )
};

export default File1;

