import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import { ModalBox } from "../../shared/modals/modal";
import Organizations from "../../api/Organizations";
import OrganizationService from "../../api/Organizations";
import Joi from "joi";


interface AddHouseOwnerModalStruct {
    open: boolean
    search: string
    messageApi: any
    editOrganization: string
    fetchOrganizations: Dispatch<any>
    setOpen: Dispatch<SetStateAction<boolean>>
    toggleEditOrganization: Dispatch<SetStateAction<string>>
}
const organizationSchema = Joi.object({
    orgName: Joi.string()
        .min(3)
        .required()
        .messages({
            'string.empty': `Naam organisatie mag niet leeg zijn.`,
            'string.min': `Naam organisatie moet een minimale lengte hebben van {#limit}`,
            'any.required': `Naam organisatie is een verplicht veld.`
        }),
    subDomain: Joi.string()
        .alphanum()
        .min(3)
        .required()
        .messages({
            'string.empty': `Domeinnaam mag niet leeg zijn.`,
            'string.min': `Domeinnaam moet een minimale lengte hebben van {#limit}`,
            'any.required': `Domeinnaam is een verplicht veld.`
        }),
    firstName: Joi.string()
        .required()
        .messages({
            'string.empty': `Voornaam mag niet leeg zijn.`,
            'any.required': `Voornaam is een verplicht veld.`,
        }),
    lastName: Joi.string()
        .required()
        .messages({
            'string.empty': `Achternaam mag niet leeg zijn.`,
            'any.required': `Achternaam is een verplicht veld.`,
        }),
    adminEmail: Joi.string()
        .pattern(/^\w+([\w+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        .required()
        .messages({
            'string.empty': `E-mail mag niet leeg zijn`,
            'any.required': `E-mail is een verplicht veld.`,
            'string.pattern.base': `Ongeldig e-mailadres.`
        }),
    phone: Joi.string()
        .pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
        .required()
        .min(6)
        .messages({
            'string.empty': `Telefoonnummer mag niet leeg zijn`,
            'any.required': `Telefoonnummer is een verplicht veld.`,
            'string.pattern.base': `Ongeldig telefoonnummer.`,
            'string.min': `Het telefoonnummer moet een minimale lengte van {#limit} cijfers hebben`,
        }),
});
const OrganisationForm: FC<AddHouseOwnerModalStruct> = ({
    open,
    search,
    setOpen,
    messageApi,
    editOrganization,
    fetchOrganizations,
    toggleEditOrganization,
}) => {
    const [organisationValues, setOrganisationValues] = useState({
        orgName: '',
        subDomain: '',
        lastName: '',
        firstName: '',
        adminEmail: '',
        phone: '',
    });
    const [errors, setErrors] = useState({
        orgName: '',
        subDomain: '',
        lastName: '',
        firstName: '',
        adminEmail: '',
        phone: '',
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formStep, setFormStep] = useState('1');
    const [isLoading, setLoading] = useState(false);
    const [provincesList, setProvincesList] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true)
            if(editOrganization!==""){
                const response = await OrganizationService.getOrganizationDetails(editOrganization);
                const { data } = response;
                setOrganisationValues({
                    orgName: data?.name,
                    subDomain: data?.subdomain,
                    lastName: data?.users[0]?.users?.lastName,
                    firstName: data?.users[0]?.users?.firstName,
                    adminEmail: data?.adminEmail,
                    phone: data?.users[0]?.users?.phone,
                });
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = async (event: any) => {

        const { name, value } = event.target;
        try {

            const target = organizationSchema.extract(name);
            const result = target.validate(value)
            if (result.error && isSubmitted) {
                setErrors({ ...errors, [name]: result.error.message })
            }
            else {
                setErrors({ ...errors, [name]: "" })
            }
            setOrganisationValues({ ...organisationValues, [name]: value });

        } catch (error: any) {
            if (isSubmitted) {
                setErrors({ ...errors, [name]: error.message });
            }
        }

    }

    const handleClose = (closeForm?: boolean, domainId?: string) => {
        setIsSubmitted(false);
        setOrganisationValues({
            orgName: '',
            subDomain: '',
            lastName: '',
            firstName: '',
            adminEmail: '',
            phone: '',
        })
        if (closeForm) {
            setOpen(false);
            toggleEditOrganization('');
            setFormStep('');
        } else {
            toggleEditOrganization(domainId || '');
            setFormStep('2');
        }
    };

    const handleSubmitForm = () => {
        setIsSubmitted(true);

        const results = organizationSchema.validate(organisationValues,{abortEarly:false})
        if (results.error) {
            let errObj ={...errors}
            for (const err of results.error.details) {
                errObj = {...errObj,[err.path[0]]:err.message}
            }
            setErrors(errObj)
            return
        } else {
            addOrganization();
        }
    };

    const addOrganization = async () => {
        const { orgName, subDomain, firstName, lastName, adminEmail, phone } = organisationValues;

        const payload = {
            "name": orgName,
            "subdomain": subDomain,
            "firstName": firstName,
            "lastName": lastName,
            "adminEmail": adminEmail,
            "phone": phone,
            "status": true,
        };

        try {
            setLoading(true)
            const response = await editOrganization
                ? Organizations.updateOrganization(editOrganization, payload, {})
                : Organizations.createNewOrganization(payload, {});

            response.then((res) => {
                if (res?.success === true) {
                    messageApi.open({
                        type: 'success',
                        content: editOrganization?'Organisatie succesvol bijgewerkt':'Organisatie succesvol toegevoegd.',
                        duration: 3,
                    });
                    fetchOrganizations(search);
                    setLoading(false);
                    handleClose(!!editOrganization, res?.data?.id);
                } else {
                    messageApi.open({
                        type: 'error',
                        content: editOrganization?'Kan de organisatie niet updaten':'Kan de organisatie niet toevoegen',
                        duration: 3,
                    });
                }
            })
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ModalBox
            title={editOrganization?"Bewerk Organisatie": "Nieuwe Organisatie toevoegen"}
            width={'auto'}
            cancelHandler={() => handleClose(true)}
            extraClasses="lg:w-[80%] w-full"
            open={open}
        >
            {editOrganization ? (
                <div style={{ padding: '0 30px 15px', marginTop: 10 }}>
                    <span
                        className={`px-2 py-4 cursor-pointer ${formStep === '1' ? 'primary-border border-b-[3px] primary-text ' : 'text-[#00000040]'}`}
                        onClick={() => setFormStep('1')}
                    >
                        Hoofdadmin & organisatie informatie
                    </span>

                    <span
                        className={`px-2 py-4 cursor-pointer ${formStep === '2' ? 'primary-border border-b-[3px] primary-text ' : 'text-[#00000040]'}`}
                        onClick={() => setFormStep('2')}
                    >
                        Steden en gemeenten
                    </span>
                </div>
            ) : null}

            {formStep === '1' && (
                <FormStep1
                    organisationValues={organisationValues}
                    handleSubmitForm={handleSubmitForm}
                    handleChange={handleChange}
                    isLoading={isLoading}
                    errors={errors}
                    editOrganization={editOrganization}
                />
            )}
            {formStep === '2' && (
                <FormStep2
                    messageApi={messageApi}
                    handleClose={handleClose}
                    setFormStep={setFormStep}
                    provincesList={provincesList}
                    editOrganization={editOrganization}
                    setProvincesList={setProvincesList}
                />
            )}
        </ModalBox>
    )
}

export default OrganisationForm;

