import React, { useState } from "react";
import axios from "axios";
import Joi from "joi";

// assets & image imports
import { PrimaryButton } from "../../../shared/buttons/igemo_button";
import { SecondaryButton } from "../../../shared/buttons/igemo_button";
import { IoCloseCircleOutline } from "react-icons/io5";

type RegisterModalProps = {
    handleModalClose?: () => void;
    onLoginClick?: () => void;
}

const registerSchema = Joi.object({
    firstName: Joi.string().required().messages({
        'string.empty': `Voornaam mag niet leeg zijn.`,
        'any.required': `Voornaam is een verplicht veld.`,
    }),
    lastName: Joi.string().required().messages({
        'string.empty': `Achternaam mag niet leeg zijn.`,
        'any.required': `Achternaam is een verplicht veld.`,
    }),
    email: Joi.string()
        .pattern(/^\w+([\w+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        .required()
        .messages({
            'string.empty': `E-mail mag niet leeg zijn`,
            'any.required': `E-mail is een verplicht veld.`,
            'string.pattern.base': `Ongeldig e-mailadres.`
        }),
    phone: Joi.string()
        .pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
        .required()
        .min(6)
        .messages({
            'string.empty': `Telefoonnummer mag niet leeg zijn`,
            'any.required': `Telefoonnummer is een verplicht veld.`,
            'string.pattern.base': `Ongeldig telefoonnummer.`,
            'string.min': `Het telefoonnummer moet een minimale lengte van {#limit} cijfers hebben`,
        }),
    typeId: Joi.number().required().messages({
        'any.required': `typeId is een verplicht veld.`,
    })
});

const RegisterModalComponent = ({ handleModalClose = () => {}, onLoginClick = () => {} }: RegisterModalProps) => {
    const [ inputState, setInputState ] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        // address: "",
        // city: "",
        // postalCode: "",
        typeId: 1  
    });
    const [ inputErrorState, setInputErrorState ] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
    });
    const [ apiErrorMessage, setApiErrorMessage ] = useState<string>("");
    const [ registrationState, setRegistrationState ] = useState<string>("idle");
    const [isSubmitted, setIsSubmitted] = useState(false);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        try {
            let target = registerSchema.extract(name);
            const result = target.validate(value)
            if (result.error && isSubmitted) {
                setInputErrorState({ ...inputErrorState, [name]: result.error.message })
            }
            else {
                setInputErrorState({ ...inputErrorState, [name]: "" })
            }
            setInputState({ ...inputState, [name]: value });

        } catch (error: any) {
            if (isSubmitted) {
                setInputErrorState({ ...inputErrorState, [name]: error.message });
            }
        }
    }

    function registerSubmit() {
        setIsSubmitted(true);
        const results = registerSchema.validate(inputState,{abortEarly:false})
        if (results.error) {
            let errObj ={...inputErrorState}
            for (const err of results.error.details) {
                errObj = {...errObj,[err.path[0]]:err.message}
            }
            setInputErrorState(errObj)
            return
        } else {
            setRegistrationState("loading")
            const auth = JSON.parse(localStorage.getItem("auth")|| '[]')
            let domainId = '1'
            if(auth?.domain?.length > 0){
                domainId = auth?.domain[0]?.domainId
            }
            const { firstName, lastName, email, phone,
                //  address, city, postalCode, 
                 typeId } = inputState;
            axios.post(process.env.REACT_APP_BACKEND_URL + "user/create/passwordless", {
                firstName,
                lastName,
                email,
                phone,
                typeId,
                newsletter: 0,
                domainId: domainId,
                // url:window.location.href.indexOf("results") > -1 ? window.location.href : null
            }).then(response => {
                setRegistrationState("success")
                console.log(response);
            }).catch(error => {
                if(error.response.status === 401) {
                    // we assume it is related to email, since it is the only unique field
                    setRegistrationState("error")
                    setApiErrorMessage("Er bestaat al een account met dit emailadres.");
                }
                if(error.response.status === 409) {
                    setRegistrationState("error")
                    setApiErrorMessage("Gebruiker bestaat al.");
                }
                else if(error.response.status === 422) {
                    setRegistrationState("error")
                    setApiErrorMessage("Er bestaat al een account met dit emailadres.");
                }
                else if(error.response.status === 500) {
                    setRegistrationState("error")
                    setApiErrorMessage("Er bestaat al een account met dit emailadres.");
                }
            }).finally(()=>{
                setIsSubmitted(false)
            });
        }
    }

    const resetRegistrationState = () => {
        setRegistrationState("idle");
    }

    const closeModal=()=>{
        setIsSubmitted(false)
        resetRegistrationState()
        handleModalClose()
    }
    
    return (
        <div className = "w-[934px] md:h-[471px] max-w-[90%] min-h-[471px] max-h-[100%] bg-white flex flex-col md:flex-row overflow-y-auto">
            <div className = "flex flex-col flex-[3.5] h-full primary-main box-border py-[37px] px-[33px]">
                <div className = "w-full flex justify-end md:hidden">
                    <div className = "cursor-pointer" onClick = {closeModal}>
                        <IoCloseCircleOutline size = "30px" color = "white" /> 
                    </div>
                </div>
                <p className = "font-Source font-semibold text-4xl secondary-text animate-fade-in-top">Registreer</p>
                <p className = "font-Source font-regular text-p2Alt secondary-text mt-[21px] animate-fade-in-top">Indien u nog geen account hebt op deze website.</p>
                {/* <p className = "font-Source font-regular text-p2Alt text-white mt-[25px] animate-fade-in-top">At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p> */}
            </div>
            <div className = "flex flex-col flex-[6] h-full justify-between bg-white shrink-0">
                <div className = "flex flex-col w-full h-full">
                    <div className = "w-full justify-end hidden md:flex">
                        <div className = "cursor-pointer" onClick = {handleModalClose}>
                            <IoCloseCircleOutline size = "30px" color = "black" className = "mr-[17px] mt-[9px]" /> 
                        </div>
                    </div>
                    {/* default page with input fields */}
                    {registrationState === "idle" && (
                        <div className = "w-full flex flex-col box-border px-[35px] animate-fade-in-bottom">
                            <div className = "w-full flex mb-[15px] mt-[18px] md:mt-[0px] flex-col md:flex-row">
                                <div className = "w-full flex flex-1 flex-col mr-[10px]">
                                    <p className = "font-Source font-semibold text-p2 primary-text box-border py-[5px]">Voornaam</p>
                                    <div className = {`w-full h-[39px] border-[1px] flex items-center box-border px-[10px] ${inputErrorState.firstName ? 'border-[red]' : 'border-primary/[0.09]'}`}>
                                        <input autoComplete="off" className = "w-full outline-none font-Source font-regular text-p2 text-black" type = "text" name = "firstName" value = {inputState.firstName} onChange = {handleInputChange} />
                                    </div>
                                    <span className="text-error text-xs">{inputErrorState.firstName ? inputErrorState.firstName : null}</span>
                                </div>
                                <div className = "w-full flex flex-1 flex-col md:ml-[10px]">
                                    <p className = "font-Source font-semibold text-p2 primary-text box-border py-[5px]">Achternaam</p>
                                    <div className = {`w-full h-[39px] border-[1px] flex items-center box-border px-[10px] ${inputErrorState.lastName ? 'border-[red]' : 'border-primary/[0.09]'}`}>
                                        <input autoComplete="off" className = "w-full outline-none font-Source font-regular text-p2 text-black" type = "text" name = "lastName" value = {inputState.lastName} onChange = {handleInputChange} />
                                    </div> 
                                    <span className="text-error text-xs">{inputErrorState.lastName ? inputErrorState.lastName : null}</span>
                                </div>
                            </div>
                            <div className = "w-full flex mb-[15px] flex-col md:flex-row">
                                <div className = "w-full flex flex-1 flex-col mr-[10px]">
                                    <p className = "font-Source font-semibold text-p2 primary-text box-border py-[5px]">Email</p>
                                    <div className = {`w-full h-[39px] border-[1px] flex items-center box-border px-[10px] ${inputErrorState.email ? 'border-[red]' : 'border-primary/[0.09]'}`}>
                                        <input autoComplete="off" className = "w-full outline-none font-Source font-regular text-p2 text-black" type = "text" name = "email" value = {inputState.email} onChange = {handleInputChange} />
                                    </div>
                                    <span className="text-error text-xs">{inputErrorState.email ? inputErrorState.email : null}</span>
                                </div>
                                <div className = "w-full flex flex-1 flex-col md:ml-[10px]">
                                    <p className = "font-Source font-semibold text-p2 primary-text box-border py-[5px]">Telefoonnummer</p>
                                    <div className = {`w-full h-[39px] border-[1px] flex items-center box-border px-[10px] ${inputErrorState.phone ? 'border-[red]' : 'border-primary/[0.09]'}`}>
                                        <input autoComplete="off" className = "w-full outline-none font-Source font-regular text-p2 text-black" type = "text" name = "phone" value = {inputState.phone} onChange = {handleInputChange} />
                                    </div> 
                                    <span className="text-error text-xs">{inputErrorState.phone ? inputErrorState.phone : null}</span>
                                </div>
                            </div>
                            <div className = "w-full flex justify-end">
                                <PrimaryButton content = "Registreer" onClick = {registerSubmit} />
                            </div>
                            <div className = "w-full h-[1px] primary-main my-[14px]" />
                            <div className = "w-full flex flex-col items-center mb-[18px] md:mb-[0px]">
                                <p className = "font-Source font-regular text-p2Alt text-[#5F5F5F] mb-[9px] ">Ik heb reeds een account</p>
                                <SecondaryButton content = "Login" onClick = {onLoginClick} />   
                            </div>
                        </div>
                    )}
                    {/* loader */}
                    {registrationState === "loading" && (
                        <div className = "w-full h-full flex items-center justify-center animate-fade-in-top">
                            <div className = "w-[50px] h-[50px] border-4 primary-border border-solid border-r-white rounded-full animate-spin" />
                        </div>
                    )}
                    {/* success */}
                    {registrationState === "success" && (
                        <div className = "w-full h-full flex flex-col items-center justify-center animate-fade-in-top box-border px-[15px]">
                            <p className = "font-Source font-semibold text-3xl primary-text">Registratie succesvol!</p>
                            <p className = "font-Source font-regular text-p2 text-black text-center my-[15px]">Bevestigingsmail verzonden, als u geen bevestigingsmail vindt, controleer dan uw map met spam of ongewenste e-mail</p>
                            <PrimaryButton content = "Volgende" onClick = {onLoginClick} />
                        </div>
                    )}   
                    {/* error */}
                    {registrationState === "error" && (
                        <div className = "w-full h-full flex flex-col items-center justify-center animate-fade-in-top box-border px-[15px]">
                            <p className = "font-Source font-semibold text-3xl primary-text">Registratie mislukt!</p>
                            <p className = "font-Source font-regular text-p2 text-black text-center my-[15px]">{apiErrorMessage}</p>
                            <PrimaryButton content = "Terug" onClick = {resetRegistrationState} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default RegisterModalComponent;
