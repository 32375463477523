import {isEmpty} from "lodash";
import React, { useState, FC } from "react";
import {flattenTree} from "react-accessible-treeview";

import {
    ReactComponent as ArrowRightCircleGreenEmpty
} from "../../assets/icons/Icon arrow-cercle-right-green-empty.svg";
import {ReactComponent as Check} from "../../assets/icons/check-circle-green.svg";

import ContractorOfferStep2 from "./ContractorOfferStep2";

interface cardProps {
    cardData: any
    contractorDetails: any
    selectedContractor?: any
}


const OfferCompareCard: FC<cardProps> = ({
    cardData,
    contractorDetails,
    selectedContractor,
  }) => {
    const [showBody, toggleShowBody] = useState(false);
    const [formStep, setFormStep] = useState(cardData?.renovation?.renovationServices[0]?.renovationServicesGroup?.services[0]?.id);

    const getSelectedTabData = () => {
        const currentData = cardData?.renovation?.renovationServices[0]?.renovationServicesGroup?.services?.find((f: any) => f.id === formStep);
        const treeData = flattenTree(currentData);
        return treeData;
    };

    return (
        cardData?.renovation?.renovationServices[0]?.renovationServicesGroup?.services ? (
            <div className="py-4 px-4 w-full bg-[#0000000a] mb-8">
                <div className="flex cursor-pointer" onClick={() => toggleShowBody(!showBody)}>
                    <div className="flex-row mt-1 cursor-pointer">
                        <div className="h-3 w-4 primary-fill">
                            <ArrowRightCircleGreenEmpty height={24} width={24} className = "primary-text" />
                        </div>
                    </div>
                    <div
                        className='ml-6 font-semibold whitespace-nowrap text-[20px]'>{cardData.renovation.title}</div>
                </div>
                {showBody && (
                    <div className="px-6">
                        <div style={{padding: '0 0 15px', marginTop: 10}}>
                            {cardData?.renovation?.renovationServices[0]?.renovationServicesGroup?.services?.map((s: any) => {
                                return (
                                    <span
                                        className={`px-2 py-2 cursor-pointer ${formStep === s.id ? "primary-text primary-border border-b-[3px]" : "text-[#00000040]"}`}
                                        onClick={() => setFormStep(s.id)}
                                    >
                                {s.name}
                            </span>
                                )
                            })}
                        </div>

                        <ContractorOfferStep2
                            selectedContractor={selectedContractor}
                            contractorDetails={contractorDetails}
                            servicesList={getSelectedTabData()}
                            formStep={formStep}
                        />
                    </div>
                )}
            </div>
        ) : null
    );
};

export default OfferCompareCard;
