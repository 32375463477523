import { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../layout";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

// asset imports here
import { CiCircleCheck } from "react-icons/ci";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaRegStar, FaStar  } from "react-icons/fa";
import { uploadsUrl } from "../../shared/Table/common";

type submitReviewModalProps = {
    offerRequestContractorId?: number,
    homeOwnerId?: number,
    contractorId?: number,
    closeModal?: () => void;
}

type starPickerProps = {
    objKey?: string,
    modifyRating?: (value: number, key: string) => void
}

type ratingCollectionType = {
    price: number;
    communication: number;
    planning: number;
    recommended: number;
    aftercare: number;
}

const StarPicker = ({objKey = "", modifyRating = () => {}}: starPickerProps) => {
    const [ rating, setRating ] = useState<number>(0);
    const [ selectedRating, setSelectedRating ] = useState<number>(0);

    const onStarClick = (rating: number) => {
        if(selectedRating === rating) {
            setSelectedRating(0)
            modifyRating(0, objKey)
        }
        else {
            setSelectedRating(rating)
            modifyRating(rating, objKey)
        }
    }

    return(
        <div className = "flex items-center">
            <div className = "flex mr-[4px] relative" onMouseOver = {() => setRating(1)} onMouseLeave = {() => setRating(0)} onClick = {() => onStarClick(1)}>
                <FaRegStar size = "20px" className = "duration-[300ms] cursor-pointer primary-text" />
                <FaStar size = "20px" className = "absolute top-0 left-0 duration-[300ms] cursor-pointer primary-text" style = {{opacity: (rating >= 1 || selectedRating >= 1) ? 1 : 0}} />
            </div>
            <div className = "flex mr-[4px] relative" onMouseOver = {() => setRating(2)} onMouseLeave = {() => setRating(0)} onClick = {() => onStarClick(2)}>
                <FaRegStar size = "20px" className = "duration-[300ms] cursor-pointer primary-text" />
                <FaStar size = "20px" className = "absolute top-0 left-0 duration-[300ms] cursor-pointer primary-text" style = {{opacity: (rating >= 2 || selectedRating >= 2) ? 1 : 0}} />
            </div>
            <div className = "flex mr-[4px] relative" onMouseOver = {() => setRating(3)} onMouseLeave = {() => setRating(0)} onClick = {() => onStarClick(3)}>
                <FaRegStar size = "20px" className = "duration-[300ms] cursor-pointer primary-text" />
                <FaStar size = "20px" className = "absolute top-0 left-0 duration-[300ms] cursor-pointer primary-text" style = {{opacity: (rating >= 3 || selectedRating >= 3) ? 1 : 0}} />
            </div>
            <div className = "flex mr-[4px] relative" onMouseOver = {() => setRating(4)} onMouseLeave = {() => setRating(0)} onClick = {() => onStarClick(4)}>
                <FaRegStar size = "20px" className = "duration-[300ms] cursor-pointer primary-text" />
                <FaStar size = "20px" className = "absolute top-0 left-0 duration-[300ms] cursor-pointer primary-text" style = {{opacity: (rating >= 4 || selectedRating >= 4) ? 1 : 0}} />
            </div>
            <div className = "flex mr-[4px] relative" onMouseOver = {() => setRating(5)} onMouseLeave = {() => setRating(0)} onClick = {() => onStarClick(5)}>
                <FaRegStar size = "20px" className = "duration-[300ms] cursor-pointer primary-text" />
                <FaStar size = "20px" className = "absolute top-0 left-0 duration-[300ms] cursor-pointer primary-text" style = {{opacity: (rating >= 5 || selectedRating >= 5) ? 1 : 0}} />
            </div>
        </div>
    )
} 

const SubmitReviewModal = ({ homeOwnerId = 0, contractorId = 0, offerRequestContractorId = 0, closeModal = () => {}}: submitReviewModalProps) => {
    const [ ratingCollection, setRatingCollection ] = useState<ratingCollectionType>({
        price: 0,
        communication: 0,
        planning: 0,
        recommended: 0,
        aftercare: 0
    })
    const [ comment, setComment ] = useState<string>("")
    const [ decodedJWT, setDecodedJWT ] = useState<any>({})
    const [ contractorData, setContractorData ] = useState<any>({})
    const [ errorMessage, setErrorMessage ] = useState("")
    const [ tooltipHover, setTooltipHover ] = useState(false);
    const [ pagePhase, setPagePhase ] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        // extract token from url query using URLSearchParams
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if(token) {
            const decoded: any = jwtDecode(token);
            console.log(decoded)
            if(decoded) {
                setDecodedJWT(decoded.data);
            }
        }
    }, [])

    useEffect(() => {
        if(decodedJWT.contractorId) {
            axios.get(process.env.REACT_APP_BACKEND_URL + `contractor/details/${decodedJWT.contractorId}`).then((response: any) => {
                console.log(response.data)
                setContractorData(response.data);
            }).catch(error => console.log(error))
        }
    }, [decodedJWT])

    useEffect(() => {
        if(pagePhase === 1) {
            setTimeout(() => {
                closeModal();
                navigate(`/`)
            }, 3000)
        }
    }, [pagePhase])

    const modifyRating = (value: number, key: string) => {
        setRatingCollection({
            ...ratingCollection,
            [key]: value
        })
    }

    const onCommentChange = (e: any) => {
        setComment(e.target.value)
    }

    const submitReview = () => {
        // first validate the reviewCollection to see if all the ratings are given
        const { price, communication, planning, recommended, aftercare } = ratingCollection;
        if(price === 0 || communication === 0 || planning === 0 || recommended === 0) {
            // show error messsage
            setErrorMessage("Vul de verplichte velden in ter beoordeling")
            return
        }
        else {
            if(decodedJWT?.contractorId && decodedJWT?.offerRequestRenovationContractorId && decodedJWT?.homeOwnerId) {
                axios.post(process.env.REACT_APP_BACKEND_URL + `ratings/${decodedJWT?.offerRequestRenovationContractorId}`, {
                    homeOwnerId: decodedJWT?.homeOwnerId,
                    contractorId: decodedJWT?.contractorId,
                    price,
                    communication,
                    planning,
                    recommended,
                    aftercare,
                    comment
                }).then((response: any) => {
                   setPagePhase(1);
                }).catch(error => {
                    console.log(error)
                })
            }
            else {
                // show error message
                setErrorMessage("Er lijkt een probleem te zijn met het token")
                return
            }
        }
    }

    const getContractorFirstUser = () => {
        if(contractorData.cpContractorUsers) {
            const { firstName, lastName } = contractorData.cpContractorUsers[0].user;
            return `${firstName} ${lastName}`;
        }
        else {
            return "";
        }
    }

    return( 
        <Layout>
            <div className = "w-full h-full min-h-[calc(100vh-254px)] bg-[#f0f5f5] flex justify-center items-center z-50">
                <div className = "w-[940px] bg-white flex flex-col box-border items-center">
                    <div className = "w-full flex justify-between items-center px-[20px] mt-[20px]">
                        <div />
                        <div className = "flex items-center">
                            <p className = "font-semibold font-sans text-[30px] text-black">Beoordeling</p>
                            <div className = "flex relative justify-center z-[2]" onMouseOver = {() => setTooltipHover(true)} onMouseLeave = {() => setTooltipHover(false)}>
                                <IoMdInformationCircleOutline size = "24px" className = "ml-[8px] cursor-pointer primary-text" />
                                <div className = "flex w-[400px] h-[150px] absolute top-[100%] primary-main rounded-[4px] shadow-md px-[16px] py-[10px] duration-[300ms] origin-top" style = {{transform: tooltipHover ? "scaleY(1)" : "scaleY(0)", opacity: tooltipHover ? 1 : 0}}>
                                    <p className = "font-sans font-regular text-[14px] text-white">Je kan de aannemer een beoordeling geven wanneer het werk is uitgevoerd en de factuur betaald. Met je beoordeling help je andere klanten hoe jouw ervaring met de aannemer was.</p>
                                </div>
                            </div>
                        </div>
                        <div />
                    </div>
                    <div className = "w-full flex justify-center mt-[12px]">
                        <p className = "font-sans font-regular text-[14px] text-[#4F4C4C]">Hier kan u indien gewenst nog extra informatie toevoegen.</p>
                    </div>
                    <div className = "w-full h-[1px] bg-[#E5E5E5] mt-[15px] mb-[15px]" />
                    <div className = "w-full flex justify-center items-center gap-[20px] mb-[15px]">
                        <img className = "w-[20px] h-[20px] rounded-[10px] overflow-hidden object-contain" src = {uploadsUrl(contractorData?.logo)} alt = "contractor profile" />
                        <p className = "font-sans font-regular text-[14px] text-black">{contractorData?.name}</p>
                        <p className = "font-sans font-regular text-[14px] text-black">{getContractorFirstUser()}</p>
                        <p className = "font-sans font-regular text-[14px] text-black">{contractorData?.email}</p>
                        {/* <p className = "font-sans font-regular text-[14px] text-black">{contractorData?.email}</p> */}
                        <div className = "flex items-center gap-[10px]">
                            <FaStar size = "25px" className = "primary-text" />
                            <p className = "font-sans font-bold text-[14px] text-black">{contractorData?.avgRating}</p>
                        </div>
                    </div>
                    <div className = "w-full flex flex-col">
                        <div className = "w-full flex px-[95px]">
                            <div className = "flex flex-col w-full ">
                                <div className = "flex flex-col">
                                    <p className = "font-sans font-semibold text-[14px] primary-text">PRIJS</p>
                                    <div className = "mt-[8px] flex">
                                        <StarPicker objKey = "price" modifyRating = {modifyRating} />
                                    </div>
                                </div>
                                <div className = "flex flex-col mt-[24px]">
                                    <p className = "font-sans font-semibold text-[14px] primary-text">COMMUNICATIE</p>
                                    <div className = "mt-[8px] flex">
                                        <StarPicker objKey = "communication" modifyRating = {modifyRating} />
                                    </div>
                                </div>
                                <div className = "flex flex-col mt-[24px]">
                                    <p className = "font-sans font-semibold text-[14px] primary-text">PLANNING/TIMING</p>
                                    <div className = "mt-[8px] flex">
                                        <StarPicker objKey = "planning" modifyRating = {modifyRating} />
                                    </div>
                                </div>
                                <div className = "flex flex-col mt-[24px]">
                                    <p className = "font-sans font-semibold text-[14px] primary-text">IK RAAD DEZE AANNEMER AAN</p>
                                    <div className = "mt-[8px] flex">
                                        <StarPicker objKey = "recommended" modifyRating = {modifyRating} />
                                    </div>
                                </div>
                                <div className = "flex flex-col mt-[24px]">
                                    <p className = "font-sans font-semibold text-[14px] primary-text">NAZORG (OPTIONEEL)</p>
                                    <div className = "mt-[8px] flex">
                                        <StarPicker objKey = "aftercare" modifyRating = {modifyRating} />
                                    </div>
                                </div>
                            </div>
                            <div className = "flex flex-col w-[510px] shrink-0">
                                <p className = "font-sans font-semibold text-[14px] text-[#4F4C4C] shrink-0 mb-[9px]">EXTRA (OPTIONEEL)</p>
                                <textarea className = "w-full h-full border-[1px] border-[#298752]/0.1 p-[15px] font-sans font-semibold text-[14px] resize-none" placeholder = "" value = {comment} onChange = {onCommentChange} />
                            </div>
                        </div>
                        <div className = "w-full flex items-center justify-between items-center mt-[28px] px-[95px] mb-[24px]">
                            <div>
                                {errorMessage && (
                                    <div className = "rounded-[5px] bg-[red]/[0.2] flex justify-center items-center p-[10px] fade-in-top">
                                        <p className = "font-sans font-regular text-[12px] text-[red]">{errorMessage}</p>
                                    </div>
                                )}
                            </div>
                            <div className = "relative flex items-center">
                                <button className = "px-[35px] py-[17px] cursor-pointer font-sans font-semibold text-[18px] flex justify-center items-center primary-main secondary-text ml-[50px] duration-[300ms] z-[1]" style = {{opacity: pagePhase === 1 ? 0 : 1, transform: pagePhase === 1 ? "translateY(-10px)": "translateY(0px)", pointerEvents: pagePhase === 1 ? "none" : "all"}} onClick = {submitReview}>Opslaan</button>
                                <div className = "flex items-center absolute right-0 duration-[300ms]" style = {{opacity: pagePhase === 0 ? 0 : 1, transform: pagePhase === 0 ? "translateY(10px)" : "translateY(0px)"}}>
                                    <CiCircleCheck size = "32px" className = "primary-text mr-[10px]" />
                                    <p className = "font-sans font-semibold text-[18px] priamry-text">SUCCES</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default SubmitReviewModal;
