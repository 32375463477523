import React from 'react'
import { ServiceAnswerType } from '../../models/applicationState'

const CardDescription = ({ originalAnswerText, newAnswerText }: { originalAnswerText: string | undefined, newAnswerText: string | undefined }) => {
    return (
        <div className="flex flex-col">
            <p className="font-Source font-regular text-p2Alt text-black">{originalAnswerText}</p>
            <p className="font-Source font-regular text-p2Alt text-black">{newAnswerText}</p>
        </div>
    )
}

const getCardDescription = (key: string, serviceAnswerList: ServiceAnswerType[]) => {
    // some keys has some type errors
    key = (key === 'warm_water') ? 'warmwater' : key
    key = (key === "achtergevel") ? "zijgevels" : key
    // key = (key === 'asbest') ? 'asbest_input' : key
    const cardData = serviceAnswerList.find((item: ServiceAnswerType) => item?.key === key)
    if ((key === "asbest" || key === 'vocht') && !cardData?.id) {
        const key2 = key + '_input'
        const cardData2 = serviceAnswerList.find((item: ServiceAnswerType) => item?.key === key2)
        const keyMapping: any = {
            '1': 'Ja',
            '2': 'Neen',
            '3': 'Ik weet het niet',
            '': ''
        }

        return <CardDescription newAnswerText={keyMapping[cardData2?.newAnswer || '']} originalAnswerText={keyMapping[cardData2?.originalAnswer || '']} />
    }
    else if (cardData?.id) {
        // to hanlde '||' in some keys 'origionalAnswerText' like 'vocht'
        const newAnswerText = cardData?.newAnswerText?.split("||").map((text: string) => text.trim())[0]
        const originalAnswerText = cardData?.originalAnswerText?.split("||").map((text: string) => text.trim())[0]
        return <CardDescription newAnswerText={newAnswerText} originalAnswerText={originalAnswerText} />
    }
}

export default getCardDescription