import React, { useRef, useEffect, useState, useMemo } from 'react'

import cardJson from "../../utils/cardsData.json"
import Card from "./card";
import Overview from "./overview";
import Bar from "./epcBar";
import Logo from "../../assets/logo/g10.svg"
import './pdf-dom.css'
// import './result.scss'
import { useCookies } from 'react-cookie'
const API_Route = 'https://backend.checkjeofferte.be/api/v1';
type pdfProps = {
  resultLabelData: any,
  renderUpdate:any
}
const PdfDom = ({resultLabelData,renderUpdate }: pdfProps) => {
  const [cookies, setCookies] = useCookies()
let cardMetaData:any[] = JSON.parse(localStorage.getItem('pdfData')!)
const localImprovements = JSON.parse(localStorage.getItem('localState')!)
const DUTCH_MONTHS = [
        'januari',
        'februari',
        'maart',
        'april',
        'mei',
        'juni',
        'juli',
        'augustus',
        'september',
        'oktober',
        'november',
        'december',
      ]
      

  // EPC labels helper
  const date = new Date()
  return (
       //@ts-ignore
    <div id="pdf-dom" className="container pdf-container">
      <header className="header">
      
      </header>
      <main className="detail-layout content-container pdf-content-container">
        <div className = "w-full flex flex-col md:px-16 xl:px-32 box-border py-[80px]">
          {/* <div className = "flex w-full justify-between items-center">
            <img className = "h-[50px] object-contain" alt = "logo" src = {Logo}/>
            <p className = "font-Source font-semibold text-[19px] text-black">{`${date.getDate()} ${DUTCH_MONTHS[date.getMonth()]} ${date.getFullYear()}`}</p>
          </div> */}
          <p className = "font-Source font-semibold text-[50px] text-black mt-[20px]">Maak je huis klaar voor morgen</p>
          <p className = "font-Source font-regular text-[24px] text-black">Dit is je persoonlijk plan met verbeteringen voor je huis. Wil je hier graag verder op ingaan? Boek een advies bij de Energiecentrale.</p>
        </div>
        <hr />
        <div className="w-full">
          {cardJson.map((item:any, index:any) => {
            const valid = Object.keys(
              localImprovements?.[item.pageNumber] || [],
            ).length
            return valid ? (
              <div
                className="w-full mt-[38px]"
                style={{ breakInside: 'avoid' }}
              >
                <div
                  key={item.title}
                  id={item.title}
                  className={`w-full ${
                    cardJson.length - 1 === index && 'no-after'
                  }`}
                >
                  <div className="card-content w-full box-border md:px-16 xl:px-32">
                    <Card carddata = {cardMetaData?.[item.pageNumber]}/>
                  </div>
                </div>
              </div>
            ) : null
          })}
        </div>
        <div className = "my-[35px] w-full flex flex-col box-border md:px-16 lg:px-16 xl:px-32" style={{ breakInside: 'avoid' }}>
          <Overview />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div style={{ width: '100%', marginTop: '0' }}>
              <Bar resultLabelData={resultLabelData} renderUpdate={renderUpdate} setPdfDataUpdate = {false} pdfHtml ={""}/>
                {/* {epc} */}
              </div>
            </div>
        </div>
      </main>
    </div>
  )
}
export default PdfDom
