import HouseType from "../../components/form/pageComponents/houseType";
import HouseAge from "../../components/form/pageComponents/houseAge";
import HouseArea from "../../components/form/pageComponents/houseArea";
import Bewoner from "../../components/form/pageComponents/bewoner";
import Bewoners from "../../components/form/pageComponents/bewoners";
import Verwarming from "../../components/form/pageComponents/verwarming";
import Elektriciteit from "../../components/form/pageComponents/elektriciteit";
import Gas from "../../components/form/pageComponents/gas";
import Stookolie from "../../components/form/pageComponents/stookolie";
import Eananderebron from "../../components/form/pageComponents/eananderebron";
import Verwarmingselementen from "../../components/form/pageComponents/verwarmingselementen";
import Verwarmingleefruimtes from "../../components/form/pageComponents/verwarmingleefruimtes";
import Verwarmingslaapkamers from "../../components/form/pageComponents/verwarmingslaapkamers";
import Warmwater from "../../components/form/pageComponents/warmwater";
import Hoofddak from "../../components/form/pageComponents/hoofddak";
import Bijdak from "../../components/form/pageComponents/bijdak";
import Ramenleefruimte from "../../components/form/pageComponents/ramenleefruimte";
import Ramenslaapkamers from "../../components/form/pageComponents/ramenslaapkamers";
import Voorgevel from "../../components/form/pageComponents/voorgevel";
import Achtergevel from "../../components/form/pageComponents/achtergevel";
import Zijgevels from "../../components/form/pageComponents/zijgevels";
import Vloerisolatie from "../../components/form/pageComponents/vloerisolatie";
import Ventilatie from "../../components/form/pageComponents/ventilatie";
import Zonnepanelen from "../../components/form/pageComponents/zonnepanelen";
import HouseSolar from "../../components/form/pageComponents/houseSolar";
import Digitalemeter from "../../components/form/pageComponents/digitalemeter";
import HouseEnergy from "../../components/form/pageComponents/houseEnergy";
import Groendak from "../../components/form/pageComponents/groendak";
import VoorOfachtertuin from "../../components/form/pageComponents/voorOfachtertuin";
import Regenwaterput from "../../components/form/pageComponents/regenwaterput";
import Verharding from "../../components/form/pageComponents/verharding";
import Klimaat from "../../components/form/pageComponents/klimaat";
import Kelder from "../../components/form/pageComponents/kelder";
import Asbest from "../../components/form/pageComponents/asbest";
import Vocht from "../../components/form/pageComponents/vocht";

const pageData = [
    {
        id: 1,
        title: "Ik woon in een",
        description: "",
        category: "Typology",
        category_id: 1,
        category_progress: 20,
        prev_url: "/",
        url: "typewoning",
        next_url: "/vraag/bouwjaar",
        component: <HouseType />,
        back_text: "Vorige",
        modal_key: "page_1_modal_"
    },
    {
        id: 2,
        title: "Mijn huis is gebouwd",
        description: "",
        category: "Typology",
        category_id: 1,
        category_progress: 30,
        prev_url: "/vraag/typewoning",
        url: "bouwjaar",
        next_url: "/vraag/oppervlakte",
        component: <HouseAge />,
        back_text: "Type woning",
        modal_key: "page_2_modal_"
    },
    {
        id: 3,
        title: "De bewoonbare oppervlakte van mijn huis is",
        description: "",
        category: "Typology",
        category_id: 1,
        category_progress: 60,
        prev_url: "/vraag/bouwjaar",
        url: "oppervlakte",
        next_url: "/vraag/bewoner",
        component: <HouseArea />,
        back_text: "Bouwjaar",
        modal_key: "page_3_modal_"
    },
    {
        id: 4,
        title: "Ik ben",
        description: "",
        category: "Typology",
        category_id: 1,
        category_progress: 80,
        prev_url: "/vraag/oppervlakte",
        url: "bewoner",
        next_url: "/vraag/bewoners",
        component: <Bewoner />,
        back_text: "Oppervlakte",
        modal_key: "page_4_modal_"
    },
    {
        id: 5,
        title: "Ik woon hier in totaal met",
        description: "Deeltijds inwonende kinderen? Duid hier het maximaal aantal bewoners aan.",
        category: "Typology",
        category_id: 1,
        category_progress: 100,
        prev_url: "/vraag/bewoner",
        url: "bewoners",
        next_url: "/vraag/verwarming",
        component: <Bewoners />,
        back_text: "Bewoner",
        modal_key: "page_5_modal_"
    },
    {
        id: 6,
        title: "Ik verwarm met",
        description: "De voorgeselecteerde verwarming is gebaseerd op het bouwjaar en type woning. Klopt dit niet? Pas dan hier aan.",
        category: "Verwarming",
        category_id: 2,
        category_progress: 16,
        prev_url: "/vraag/bewoners",
        url: "verwarming",
        next_url: "/vraag/elektriciteit",
        component: <Verwarming />,
        back_text: "Bewoners",
        modal_key: "page_6_modal_"
    },
    {
        id: 7,
        title: "Voor verwarming heb ik",
        description: "De geselecteerde verwarming is gebaseerd op het bouwjaar en type woning. Klopt dit niet? Pas dan hier aan.",
        category: "Verwarming",
        category_id: 2,
        category_progress: 32,
        prev_url: "/vraag/verwarming",
        url: "elektriciteit",
        next_url: "/vraag/verwarmingselementen",
        component: <Elektriciteit />,
        back_text: "Verwarming",
        modal_key: "page_7_elektriciteit_modal_"
    },
    {
        id: 7,
        title: "Voor verwarming heb ik",
        description: "De geselecteerde verwarming is gebaseerd op het bouwjaar en type woning. Klopt dit niet? Pas dan hier aan.",
        category: "Verwarming",
        category_id: 2,
        category_progress: 32,
        prev_url: "/vraag/verwarming",
        url: "gas",
        next_url: "/vraag/verwarmingselementen",
        component: <Gas />,
        back_text: "Verwarming",
        modal_key: "page_7_gas_modal_"
    },
    {
        id: 7,
        title: "Voor verwarming heb ik",
        description: "De geselecteerde verwarming is gebaseerd op het bouwjaar en type woning. Klopt dit niet? Pas dan hier aan.",
        category: "Verwarming",
        category_id: 2,
        category_progress: 32,
        prev_url: "/vraag/verwarming",
        url: "stookolie",
        next_url: "/vraag/verwarmingselementen",
        component: <Stookolie />,
        back_text: "Verwarming",
        modal_key: "page_7_stookolie_modal_"
    },
    {
        id: 7,
        title: "Voor verwarming heb ik",
        description: "De geselecteerde verwarming is gebaseerd op het bouwjaar en type woning. Klopt dit niet? Pas dan hier aan.",
        category: "Verwarming",
        category_id: 2,
        category_progress: 32,
        prev_url: "/vraag/verwarming",
        url: "eenanderebron",
        next_url: "/vraag/verwarmingselementen",
        component: <Eananderebron />,
        back_text: "Verwarming",
        modal_key: "page_7_een_andere_bron_modal_"
    },
    {
        id: 8,
        title: "Mijn huis wordt verwarmd met",
        description: "Duid aan wat in je huis het voornaamste verwarmingselement is.",
        category: "Verwarming",
        category_id: 2,
        category_progress: 48,
        prev_url: "/vraag/elektriciteit",
        url: "verwarmingselementen",
        next_url: "/vraag/verwarmingleefruimtes",
        component: <Verwarmingselementen />,
        back_text: "Verwarming",
        modal_key: "page_8_modal_"
    },
    {
        id: 9,
        title: "Ik verwarm de leefruimtes",
        description: "duid hier aan wanneer je je verwarming in de leefruimtes op een doorsnee weekdag aanzet",
        category: "Verwarming",
        category_id: 2,
        category_progress: 64,
        prev_url: "/vraag/verwarmingselementen",
        url: "verwarmingleefruimtes",
        next_url: "/vraag/verwarmingslaapkamers",
        component: <Verwarmingleefruimtes />,
        back_text: "Verwarmingselementen",
        modal_key: "page_9_modal_"
    },
    {
        id: 10,
        title: "Ik verwarm de slaapkamers",
        description: "duid hier aan wanneer je je verwarming in de slaapkamers op een doorsnee weekdag aanzet",
        category: "Verwarming",
        category_id: 2,
        category_progress: 80,
        prev_url: "/vraag/verwarmingleefruimtes",
        url: "verwarmingslaapkamers",
        next_url: "/vraag/warmwater",
        component: <Verwarmingslaapkamers />,
        back_text: "Verwarming leefruimtes",
        modal_key: "page_10_modal_"
    },
    {
        id: 11,
        title: "Voor warm water heb ik",
        description: "Duid aan wat in je huis de voornaamste bron van warm water is.",
        category: "Verwarming",
        category_id: 2,
        category_progress: 100,
        prev_url: "/vraag/verwarmingslaapkamers",
        url: "warmwater",
        next_url: "/vraag/hoofddak",
        component: <Warmwater />,
        back_text: "Verwarming slaapkamers",
        modal_key: "page_11_modal_"
    },
    {
        id: 12,
        title: "Het hoofddak van mijn huis is",
        description: "",
        category: "Isolatie",
        category_id: 3,
        category_progress: 10,
        prev_url: "/vraag/warmwater",
        url: "hoofddak",
        next_url: "/vraag/bijdak",
        component: <Hoofddak />,
        back_text: "Warm water",
        modal_key: "page_12_modal_"
    },
    {
        id: 13,
        title: "Het bijdak van mijn huis is",
        description: "",
        category: "Isolatie",
        category_id: 3,
        category_progress: 20,
        prev_url: "/vraag/hoofddak",
        url: "bijdak",
        next_url: "/vraag/ramenleefruimte",
        component: <Bijdak />,
        back_text: "Hoofddak",
        modal_key: "page_13_modal_"
    },
    {
        id: 15,
        title: "In de ramen van mijn leefruimte heb ik",
        description: "De geselecteerde ramen zijn gebaseerd op het bouwjaar en type woning. Klopt dit niet? Pas dan hier aan.",
        category: "Isolatie",
        category_id: 3,
        category_progress: 40,
        prev_url: "/vraag/bijdak",
        url: "ramenleefruimte",
        next_url: "/vraag/ramenslaapkamers",
        component: <Ramenleefruimte />,
        back_text: "Bijdak",
        modal_key: "page_15_modal_"
    },
    {
        id: 16,
        title: "In de ramen van mijn slaapkamers heb ik",
        description: "De geselecteerde ramen zijn gebaseerd op het bouwjaar en type woning. Klopt dit niet? Pas dan hier aan.",
        category: "Isolatie",
        category_id: 3,
        category_progress: 50,
        prev_url: "/vraag/ramenleefruimte",
        url: "ramenslaapkamers",
        next_url: "/vraag/voorgevel",
        component: <Ramenslaapkamers />,
        back_text: "Ramen leefruimte",
        modal_key: "page_16_modal_"
    },
    {
        id: 17,
        title: "De voorgevel van mijn huis is",
        description: "De geselecteerde gevelisolatie is gebaseerd op het bouwjaar en type woning. Klopt dit niet? Pas dan hier aan.",
        category: "Isolatie",
        category_id: 3,
        category_progress: 60,
        prev_url: "/vraag/ramenslaapkamers",
        url: "voorgevel",
        next_url: "/vraag/achtergevel",
        component: <Voorgevel />,
        back_text: "ramen slaapkamers",
        modal_key: "page_17_modal_"
    },
    {
        id: 18,
        title: "De achtergevel van mijn huis is",
        description: "De geselecteerde gevelisolatie is gebaseerd op het bouwjaar en type woning. Klopt dit niet? Pas dan hier aan.",
        category: "Isolatie",
        category_id: 3,
        category_progress: 70,
        prev_url: "/vraag/voorgevel",
        url: "achtergevel",
        next_url: "/vraag/zijgevels",
        component: <Achtergevel />,
        back_text: "Voorgevel",
        modal_key: "page_18_modal_"
    },
    {
        id: 19,
        title: "De zijgevels van mijn huis zijn",
        description: "De geselecteerde gevelisolatie is gebaseerd op het bouwjaar en type woning. Klopt dit niet? Pas dan hier aan.",
        category: "Isolatie",
        category_id: 3,
        category_progress: 80,
        prev_url: "/vraag/achtergevel",
        url: "zijgevels",
        next_url: "/vraag/vloerisolatie",
        component: <Zijgevels />,
        back_text: "Achtergevel",
        modal_key: "page_19_modal_"
    },
    {
        id: 20,
        title: "De vloerisolatie van mijn huis is",
        description: "De geselecteerde vloerisolatie is gebaseerd op het bouwjaar en type woning. Klopt dit niet? Pas dan hier aan.",
        category: "Isolatie",
        category_id: 3,
        category_progress: 90,
        prev_url: "/vraag/zijgevels",
        url: "vloerisolatie",
        next_url: "/vraag/kelder",
        component: <Vloerisolatie />,
        back_text: "Zijgevels",
        modal_key: "page_20_modal_"
    },
    {
        id: 21,
        title: "De ventilatie in mijn huis is",
        description: "De geselecteerde ventilatie is gebaseerd op het bouwjaar en type woning. Klopt dit niet? Pas dan hier aan.",
        category: "Isolatie",
        category_id: 3,
        category_progress: 100,
        prev_url: "/vraag/kelder",
        url: "ventilatie",
        next_url: "/vraag/zonnepanelen",
        component: <Ventilatie />,
        back_text: "Kelder",
        modal_key: "page_21_modal_"
    },
    {
        id: 22,
        title: "Mijn huis heeft",
        description: "",
        category: "Solar",
        category_id: 4,
        category_progress: 10,
        prev_url: "/vraag/ventilatie",
        url: "zonnepanelen",
        next_url: "/vraag/aantalzonnepanelen",
        component: <Zonnepanelen />,
        back_text: "Ventilatie",
        modal_key: "page_22_modal_"
    },
    {
        id: 23,
        title: "Het aantal zonnepanelen is",
        description: "",
        category: "Solar",
        category_id: 4,
        category_progress: 20,
        prev_url: "/vraag/zonnepanelen",
        url: "aantalzonnepanelen",
        next_url: "/vraag/digitalemeter",
        component: <HouseSolar />,
        back_text: "Zonnepanelen",
        modal_key: "page_23_modal_"
    },
    {
        id: 25,
        title: "Ik heb",
        description: "",
        category: "Solar",
        category_id: 4,
        category_progress: 30,
        prev_url: "/vraag/aantalzonnepanelen",
        url: "digitalemeter",
        next_url: "/vraag/energieverbruiken-productie",
        component: <Digitalemeter />,
        back_text: "Aantal zonnepanelen",
        modal_key: "page_25_modal_"
    },
    {
        id: 26,
        title: "Mijn energieverbruik en -productie is",
        description: "Het ingevulde energieverbruik is gebaseerd op gemiddelde voor jouw type woning, bouwjaar en aantal bewoners. Ligt je verbruik hoger of lager? Pas dan hier aan.",
        category: "Solar",
        category_id: 4,
        category_progress: 40,
        prev_url: "/vraag/digitalemeter",
        url: "energieverbruiken-productie",
        next_url: "/vraag/asbest",
        component: <HouseEnergy />,
        back_text: "Digitale meter",
        modal_key: "page_26_modal_"
    },
    {
        id: 27,
        title: "Mijn huis heeft",
        description: "",
        category: "Solar",
        category_id: 4,
        category_progress: 60,
        prev_url: "/vraag/vocht",
        url: "groendak",
        next_url: "/vraag/voor-ofachtertuin",
        component: <Groendak />,
        back_text: "Vocht",
        modal_key: "page_27_modal_"
    },
    {
        id: 28,
        title: "Ik heb",
        description: "",
        category: "Solar",
        category_id: 4,
        category_progress: 70,
        prev_url: "/vraag/groendak",
        url: "voor-ofachtertuin",
        next_url: "/vraag/regenwaterput",
        component: <VoorOfachtertuin />,
        back_text: "Groendak",
        modal_key: "page_28_modal_"
    },
    {
        id: 29,
        title: "Ik heb",
        description: "",
        category: "Solar",
        category_id: 4,
        category_progress: 80,
        prev_url: "/vraag/voor-ofachtertuin",
        url: "regenwaterput",
        next_url: "/vraag/verharding",
        component: <Regenwaterput />,
        back_text: "Voor- of achtertuin",
        modal_key: "page_29_modal_"
    },
    {
        id: 30,
        title: "Is je voor- of achtertuin (deels) verhard?",
        description: "Tegels eruit en planten erin! Verhard zo weinig mogelijk. Dit beschermt je tegen wateroverlast en droogte.",
        category: "Solar",
        category_id: 4,
        category_progress: 90,
        prev_url: "/vraag/regenwaterput",
        url: "verharding",
        next_url: "/vraag/klimaat",
        component: <Verharding />,
        back_text: "Regenwaterput",
        modal_key: "page_30_modal_"
    },
    {
        id: 31,
        title: "Wordt je huis te warm in de zomer?",
        description: "",
        category: "Solar",
        category_id: 4,
        category_progress: 100,
        prev_url: "/vraag/verharding",
        url: "klimaat",
        next_url: "/results",
        component: <Klimaat />,
        back_text: "Verharding",
        modal_key: "page_31_modal_"
    },
    {
        id: 32,
        title: "Mijn huis heeft?",
        description: "",
        category: "Isolatie",
        category_id: 3,
        category_progress: 100,
        prev_url: "/vraag/vloerisolatie",
        url: "kelder",
        next_url: "/vraag/ventilatie",
        component: <Kelder />,
        back_text: "Vloerisolatie",
        modal_key: "page_32_modal_"
    },
    {
        id: 33,
        title: "Is er asbest in de woning?",
        description: `Meer informatie: <a href="https://ovam.vlaanderen.be/asbest-in-uw-woning" target="_blank">https://ovam.vlaanderen.be/asbest-in-uw-woning</a>`,
        category: "Solar",
        category_id: 4,
        category_progress: 50,
        prev_url: "/vraag/energieverbruiken-productie",
        url: "asbest",
        next_url: "/vraag/vocht",
        component: <Asbest />,
        back_text: "Energieverbruik en -productie",
        modal_key: "page_33_modal_"
    },
    {
        id: 34,
        title: "Zijn er vochtproblemen in de woning?",
        description: "",
        category: "Solar",
        category_id: 4,
        category_progress: 55,
        prev_url: "/vraag/asbest",
        url: "vocht",
        next_url: "/vraag/groendak",
        component: <Vocht />,
        back_text: "Asbest",
        modal_key: "page_34_modal_"
    }
]

export default pageData;
