import { Input } from 'antd'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { SearchOutlined } from "@ant-design/icons";

interface SearchInterface {
    setQuery: Dispatch<SetStateAction<string>>
    border?: string
    timer?: number
}

const Search: FC<SearchInterface> = ({ timer, setQuery, border = 'border-none' }) => {
    const handleValueChange = (ev: any) => {
        const value = ev.target.value as string

        // set query for 2 seconds to call api
        const delayDebounceFn = setTimeout(() => {
            setQuery(value)
        }, (timer || 3000))

        return () => clearTimeout(delayDebounceFn)

    }

    return (
        <Input
            autoComplete="off"
            onChange={handleValueChange}
            placeholder="Zoeken"
            className={`py-[9px] ${border}`}
            suffix={<SearchOutlined className="text-[18px]" />}
        />
    )
}

export default Search
